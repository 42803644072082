export default [
{ value: null, text:"Seleccionar..."},
{ value: "A000",	text: "COLERA DEBIDO A VIBRIO CHOLERAE O1, BIOTIPO CHOLERAE"},
{ value: "A001",	text: "COLERA DEBIDO A VIBRIO CHOLERAE O1, BIOTIPO EL TOR"},
{ value: "A009",	text: "COLERA NO ESPECIFICADO"},
{ value: "A010",	text: "FIEBRE TIFOIDEA"},
{ value: "A011",	text: "FIEBRE PARATIFOIDEA A"},
{ value: "A012",	text: "FIEBRE PARATIFOIDEA B"},
{ value: "A013",	text: "FIEBRE PARATIFOIDEA C"},
{ value: "A014",	text: "FIEBRE PARATIFOIDEA, NO ESPECIFICADA"},
{ value: "A020",	text: "ENTERITIS DEBIDA A SALMONELLA"},
{ value: "A021",	text: "SEPTICEMIA DEBIDA A SALMONELLA"},
{ value: "A022",	text: "INFECCIONES LOCALIZADAS DEBIDA A SALMONELLA"},
{ value: "A028",	text: "OTRAS INFECCIONES ESPECIFICADAS COMO DEBIDAS A SALMONELLA"},
{ value: "A029",	text: "INFECCIÓN DEBIDA A SALMONELLA NO ESPECIFICADA"},
{ value: "A030",	text: "SHIGELOSIS DEBIDA A SHIGELLA DYSENTERIAE"},
{ value: "A031",	text: "SHIGELOSIS DEBIDA A SHIGELLA FLEXNERI"},
{ value: "A032",	text: "SHIGELOSIS DEBIDA A SHIGELLA BOYDII"},
{ value: "A033",	text: "SHIGELOSIS DEBIDA A SHIGELLA SONNEI"},
{ value: "A038",	text: "OTRAS SHIGELOSIS"},
{ value: "A039",	text: "SHIGELOSIS DE TIPO NO ESPECIFICADO"},
{ value: "A040",	text: "INFECCION DEBIDA A ESCHERICHIA COLI ENTEROPATOGENA"},
{ value: "A041",	text: "INFECCION DEBIDA A ESCHERICHIA COLI ENTEROTOXIGENA"},
{ value: "A042",	text: "INFECCION DEBIDA A ESCHERICHIA COLI ENTEROINVASIVA"},
{ value: "A043",	text: "INFECCION DEBIDA A ESCHERICHIA COLI ENTEROHEMORRAGICA"},
{ value: "A044",	text: "OTRAS INFECCIONES INTESTINALES DEBIDAS A ESCHERICHIA COLI"},
{ value: "A045",	text: "ENTERITIS DEBIDA A CAMPYLOBACTER"},
{ value: "A046",	text: "ENTERITIS DEBIDA A YERSINIA ENTEROCOLITICA"},
{ value: "A047",	text: "ENTEROCOLITIS DEBIDA A CLOSTRIDIUM DIFFICILE"},
{ value: "A048",	text: "OTRAS INFECCIONES INTESTINALES BACTERIANAS ESPECIFICADAS"},
{ value: "A049",	text: "INFECCION INTESTINAL BACTERIANA, NO ESPECIFICADA"},
{ value: "A050",	text: "INTOXICACION ALIMENTARIA ESTAFILOCOCICA"},
{ value: "A051",	text: "BOTULISMO"},
{ value: "A052",	text: "INTOXICACION ALIMENTARIA DEBIDA A CLOSTRIDIUM PERFRINGENS [CLOSTRIDIUM WELCHII]"},
{ value: "A053",	text: "INTOXICACION ALIMENTARIA DEBIDA A VIBRIO PARAHAEMOLYTICUS"},
{ value: "A054",	text: "INTOXICACION ALIMENTARIA DEBIDA A BACILLUS CEREUS"},
{ value: "A058",	text: "OTRAS INTOXICACIONES ALIMENTARIAS DEBIDAS A BACTERIAS ESPECIFICADAS"},
{ value: "A059",	text: "INTOXICACION ALIMENTARIA BACTERIANA, NO ESPECIFICADA"},
{ value: "A060",	text: "DISENTERIA AMEBIANA AGUDA"},
{ value: "A061",	text: "AMEBIASIS INTESTINAL CRONICA"},
{ value: "A062",	text: "COLITIS AMEBIANA NO DISENTERICA"},
{ value: "A063",	text: "AMEBOMA INTESTINAL"},
{ value: "A064",	text: "ABSCESO AMEBIANO DEL HIGADO"},
{ value: "A065",	text: "ABSCESO AMEBIANO DEL PULMON (J99.8*)"},
{ value: "A066",	text: "ABSCESO AMEBIANO DEL CEREBRO (G07*)"},
{ value: "A067",	text: "AMEBIASIS CUTANEA"},
{ value: "A068",	text: "INFECCION AMEBIANA DE OTRAS LOCALIZACIONES"},
{ value: "A069",	text: "AMEBIASIS, NO ESPECIFICADA"},
{ value: "A070",	text: "BALANTIDIASIS"},
{ value: "A071",	text: "GIARDIASIS [LAMBLIASIS]"},
{ value: "A072",	text: "CRIPTOSPORIDIOSIS"},
{ value: "A073",	text: "ISOSPORIASIS"},
{ value: "A078",	text: "OTRAS ENFERMEDADES INTESTINALES ESPECIFICADAS DEBIDAS A PROTOZOARIOS"},
{ value: "A079",	text: "ENFERMEDAD INTESTINAL DEBIDA A PROTOZOARIOS, NO ESPECIFICADA"},
{ value: "A080",	text: "ENTERITIS DEBIDA A ROTAVIRUS"},
{ value: "A081",	text: "GASTROENTEROPATIA AGUDA DEBIDA AL AGENTE DE NORWALK"},
{ value: "A082",	text: "ENTERITIS DEBIDA A ADENOVIRUS"},
{ value: "A083",	text: "OTRAS ENTERITIS VIRALES"},
{ value: "A084",	text: "INFECCION INTESTINAL VIRAL, SIN OTRA ESPECIFICACION"},
{ value: "A085",	text: "OTRAS INFECCIONES INTESTINALES ESPECIFICADAS"},
{ value: "A09X",	text: "DIARREA Y GASTROENTERITIS DE PRESUNTO ORIGEN INFECCIOSO"},
{ value: "A150",	text: "TUBERCULOSIS DEL PULMON, CONFIRMADA POR HALLAZGO MICROSCOPICO DEL BACILO TUBERCULOSO EN ESPUTO, CON O SIN CULTIVO"},
{ value: "A151",	text: "TUBERCULOSIS DEL PULMON, CONFIRMADA UNICAMENTE POR CULTIVO"},
{ value: "A152",	text: "TUBERCULOSIS DEL PULMON, CONFIRMADA HISTOLOGICAMENTE"},
{ value: "A153",	text: "TUBERCULOSIS DEL PULMON , CONFIRMADA POR MEDIOS NO ESPECIFICADOS"},
{ value: "A154",	text: "TUBERCULOSIS DE GANGLIOS LINFATICOS INTRATORACICOS, CONFIRMADA BACTERIOLOGICA E HISTOLOGICAMENTE"},
{ value: "A155",	text: "TUBERCULOSIS DE LARINGE, TRAQUEA Y BRONQUIOS, CONFIRMADA BACTERIOLOGICA E HISTOLOGICAMENTE"},
{ value: "A156",	text: "PLEURESIA TUBERCULOSA, CONFIRMADA BACTERIOLOGICA E HISTOLOGICAMENTE"},
{ value: "A157",	text: "TUBERCULOSIS RESPIRATORIA PRIMARIA, CONFIRMADA BACTERIOLOGICA E HISTOLOGICAMENTE"},
{ value: "A158",	text: "OTRAS TUBERCULOSIS RESPIRATORIAS, CONFIRMADAS BACTERIOLOGICA E HISTOLOGICAMENTE"},
{ value: "A159",	text: "TUBERCULOSIS RESPIRATORIA NO ESPECIFICADA, CONFIRMADA BACTERIOLOGICAMENTE E HISTOLOGICAMENTE"},
{ value: "A160",	text: "TUBERCULOSIS DEL PULMON, CON EXAMEN BACTERIOLOGICO E HISTOLOGICO NEGATIVOS"},
{ value: "A161",	text: "TUBERCULOSIS DEL PULMON, SIN EXAMEN BACTERIOLOGICO E HISTOLOGICO"},
{ value: "A162",	text: "TUBERCULOSIS DE PULMON, SIN MENCION DE CONFIRMACION BACTERIOLOGICA O HISTOLOGICA"},
{ value: "A163",	text: "TUBERCULOSIS DE GANGLIOS LINFATICOS INTRATORACICOS, SIN MENCION DE CONFIRMACION BACTERIOLOGICA O HISTOLOGICA"},
{ value: "A164",	text: "TUBERCULOSIS DE LARINGE, TRAQUEA Y BRONQUIOS, SIN MENCION DE CONFIRMACION BACTERIOLOGICA O HISTOLOGICA"},
{ value: "A165",	text: "PLEURESIA TUBERCULOSA, SIN MENCION DE CONFIRMACION BACTERIOLOGICA E HISTOLOGICA"},
{ value: "A167",	text: "TUBERCULOSIS RESPIRATORIA PRIMARIA, SIN MENCION DE CONFIRMACION BACTERIOLOGICA E HISTOLOGICA"},
{ value: "A168",	text: "OTRAS TUBERCULOSIS RESPIRATORIAS, SIN MENCION DE CONFIRMACION"},
{ value: "A169",	text: "TUBERCULOSIS RESPIRATORIA NO ESPECIFICADA, SIN MENCION DE CONFIRMACION BACTERIOLOGICA O HISTOLOGICA"},
{ value: "A170",	text: "MENINGITIS TUBERCULOSA (G01*)"},
{ value: "A171",	text: "TUBERCULOMA MENINGEO (G07*)"},
{ value: "A178",	text: "OTRAS TUBERCULOSIS DEL SISTEMA NERVIOSO"},
{ value: "A179",	text: "TUBERCULOSIS DEL SISTEMA NERVIOSO, NO ESPECIFICADA (G99.8*)"},
{ value: "A180",	text: "TUBERCULOSIS DE HUESOS Y ARTICULACIONES"},
{ value: "A181",	text: "TUBERCULOSIS DEL APARATO GENITOURINARIO"},
{ value: "A182",	text: "LINFADENOPATIA PERIFERICA TUBERCULOSA"},
{ value: "A183",	text: "TUBERCULOSIS DE LOS INTESTINOS, EL PERITONEO Y LOS GANGLIOS MESENTERICOS"},
{ value: "A184",	text: "TUBERCULOSIS DE LA PIEL Y EL TEJIDO SUBCUTANEO"},
{ value: "A185",	text: "TUBERCULOSIS DEL OJO"},
{ value: "A186",	text: "TUBERCULOSIS DEL OIDO"},
{ value: "A187",	text: "TUBERCULOSIS DE GLANDULAS SUPRARRENALES (E35.1*)"},
{ value: "A188",	text: "TUBERCULOSIS DE OTROS ORGANOS ESPECIFICADOS"},
{ value: "A190",	text: "TUBERCULOSIS MILIAR AGUDA DE UN SOLO SITIO ESPECIFICADO"},
{ value: "A191",	text: "TUBERCULOSIS MILIAR AGUDA DE SITIOS MULTIPLES"},
{ value: "A192",	text: "TUBERCULOSIS MILIAR AGUDA, NO ESPECIFICADA"},
{ value: "A198",	text: "OTRAS TUBERCULOSIS MILIARES"},
{ value: "A199",	text: "TUBERCULOSIS MILIAR, SIN OTRA ESPECIFICACION"},
{ value: "A200",	text: "PESTE BUBONICA"},
{ value: "A201",	text: "PESTE CELULOCUTANEA"},
{ value: "A202",	text: "PESTE NEUMONICA"},
{ value: "A203",	text: "MENINGITIS POR PESTE"},
{ value: "A207",	text: "PESTE SEPTICEMICA"},
{ value: "A208",	text: "OTRAS FORMAS DE PESTE"},
{ value: "A209",	text: "PESTE, NO ESPECIFICADA"},
{ value: "A210",	text: "TULAREMIA ULCEROGLANDULAR"},
{ value: "A211",	text: "TULAREMIA OCULOGLANDULAR"},
{ value: "A212",	text: "TULAREMIA PULMONAR"},
{ value: "A213",	text: "TULAREMIA GASTROINTESTINAL"},
{ value: "A217",	text: "TULAREMIA GENERALIZADA"},
{ value: "A218",	text: "OTRAS FORMAS DE TULAREMIA"},
{ value: "A219",	text: "TULAREMIA , NO ESPECIFICADA"},
{ value: "A220",	text: "CARBUNCO CUTANEO"},
{ value: "A221",	text: "CARBUNCO PULMONAR"},
{ value: "A222",	text: "CARBUNCO GASTROINTESTINAL"},
{ value: "A227",	text: "CARBUNCO SEPTICEMICO"},
{ value: "A228",	text: "OTRAS FORMAS DE CARBUNCO"},
{ value: "A229",	text: "CARBUNCO, NO ESPECIFICADO"},
{ value: "A230",	text: "BRUCELOSIS DEBIDA A BRUCELLA MELITENSIS"},
{ value: "A231",	text: "BRUCELOSIS DEBIDA A BRUCELLA ABORTUS"},
{ value: "A232",	text: "BRUCELOSIS DEBIDA A BRUCELLA SUIS"},
{ value: "A233",	text: "BRUCELOSIS DEBIDA A BRUCELLA CANIS"},
{ value: "A238",	text: "OTRAS BRUCELOSIS"},
{ value: "A239",	text: "BRUCELOSIS, NO ESPECIFICADA"},
{ value: "A240",	text: "MUERMO"},
{ value: "A241",	text: "MELIOIDOSIS AGUDA Y FULMINANTE"},
{ value: "A242",	text: "MELIOIDOSIS SUBAGUDA Y CRONICA"},
{ value: "A243",	text: "OTRAS MELIOIDOSIS"},
{ value: "A244",	text: "MELIOIDOSIS , NO ESPECIFICADA"},
{ value: "A250",	text: "ESPIRILOSIS"},
{ value: "A251",	text: "ESTREPTOBACILOSIS"},
{ value: "A259",	text: "FIEBRE POR MORDEDURA DE RATA, NO ESPECIFICADA"},
{ value: "A260",	text: "ERISIPELOIDE CUTANEO"},
{ value: "A267",	text: "SEPTICEMIA POR ERYSIPELOTHRIX"},
{ value: "A268",	text: "OTRAS FORMAS DE ERISIPELOIDE"},
{ value: "A269",	text: "ERISIPELOIDE, NO ESPECIFICADA"},
{ value: "A270",	text: "LEPTOSPIROSIS ICTEROHEMORRAGICA"},
{ value: "A278",	text: "OTRAS FORMAS DE LEPTOSPIROSIS"},
{ value: "A279",	text: "LEPTOSPIROSIS, NO ESPECIFICADA"},
{ value: "A280",	text: "PASTEURELOSIS"},
{ value: "A281",	text: "ENFERMEDAD POR RASGUÑO DE GATO"},
{ value: "A282",	text: "YERSINIOSIS EXTRAINTESTINAL"},
{ value: "A288",	text: "OTRAS ENFERMEDADES ZOONOTICAS BACTERIANAS ESPECIFICADAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "A289",	text: "ENFERMEDAD ZOONOTICA BACTERIANA, SIN OTRA ESPECIFICACION"},
{ value: "A300",	text: "LEPRA INDETERMINADA"},
{ value: "A301",	text: "LEPRA TUBERCULOIDE"},
{ value: "A302",	text: "LEPRA TUBERCULOIDE LIMITROFE"},
{ value: "A303",	text: "LEPRA LIMITROFE"},
{ value: "A304",	text: "LEPRA LEPROMATOSA LIMITROFE"},
{ value: "A305",	text: "LEPRA LEPROMATOSA"},
{ value: "A308",	text: "OTRAS FORMAS DE LEPRA"},
{ value: "A309",	text: "LEPRA, NO ESPECIFICADA"},
{ value: "A310",	text: "INFECCIONES POR MICOBACTERIAS PULMONARES"},
{ value: "A311",	text: "INFECCION CUTANEA POR MICOBACTERIAS"},
{ value: "A318",	text: "OTRAS INFECCIONES POR MICOBACTERIAS"},
{ value: "A319",	text: "INFECCION POR MICOBACTERIA, NO ESPECIFICADA"},
{ value: "A320",	text: "LISTERIOSIS CUTANEA"},
{ value: "A321",	text: "MENINGITIS Y MENINGOENCEFALITIS LISTERIANA"},
{ value: "A327",	text: "SEPTICEMIA LISTERIANA"},
{ value: "A328",	text: "OTRAS FORMAS DE LISTERIOSIS"},
{ value: "A329",	text: "LISTERIOSIS, NO ESPECIFICADA"},
{ value: "A33X",	text: "TETANOS NEONATAL"},
{ value: "A34X",	text: "TETANOS OBSTETRICO"},
{ value: "A35X",	text: "OTROS TETANOS"},
{ value: "A360",	text: "DIFTERIA FARINGEA"},
{ value: "A361",	text: "DIFTERIA NASOFARINGEA"},
{ value: "A362",	text: "DIFTERIA LARINGEA"},
{ value: "A363",	text: "DIFTERIA CUTANEA"},
{ value: "A368",	text: "OTRAS DIFTERIAS"},
{ value: "A369",	text: "DIFTERIA, NO ESPECIFICADA"},
{ value: "A370",	text: "TOS FERINA DEBIDA A BORDETELLA PERTUSSIS"},
{ value: "A371",	text: "TOS FERINA DEBIDA A BORDETELLA PARAPERTUSSIS"},
{ value: "A378",	text: "TOS FERINA DEBIDA A OTRAS ESPECIES DE BORDETELLA"},
{ value: "A379",	text: "TOS FERINA, NO ESPECIFICADA"},
{ value: "A38X",	text: "ESCARLATINA"},
{ value: "A390",	text: "MENINGITIS MENINGOCOCICA (G01*)"},
{ value: "A391",	text: "SINDROME DE WATERHOUSE- FRIDERICHSEN (E35.1*)"},
{ value: "A392",	text: "MENINGOCOCEMIA AGUDA"},
{ value: "A393",	text: "MENINGOCOCEMIA CRONICA"},
{ value: "A394",	text: "MENINGOCOCEMIA, NO ESPECIFICADA"},
{ value: "A395",	text: "ENFERMEDAD CARDIACA DEBIDA A MENINGOCOCO"},
{ value: "A398",	text: "OTRAS INFECCIONES MENINGOCOCICAS"},
{ value: "A399",	text: "INFECCION MENINGOCOCICA, NO ESPECIFICADA"},
{ value: "A400",	text: "SEPTICEMIA DEBIDA A ESTREPTOCOCO, GRUPO A"},
{ value: "A401",	text: "SEPTICEMIA DEBIDA A ESTREPTOCOCO, GRUPO B"},
{ value: "A402",	text: "SEPTICEMIA DEBIDA A ESTREPTOCOCO, GRUPO D"},
{ value: "A403",	text: "SEPTICEMIA DEBIDA A STREPTOCOCCUS PNEUMONIAE"},
{ value: "A408",	text: "OTRAS SEPTICEMIAS ESTREPTOCOCICAS"},
{ value: "A409",	text: "SEPTICEMIA ESTREPTOCOCICA, NO ESPECIFICADA"},
{ value: "A410",	text: "SEPTICEMIA DEBIDA STAPHYLOCOCCUS AUREUS"},
{ value: "A411",	text: "SEPTICEMIA DEBIDA A OTRO ESTAFILOCOCO ESPECIFICADO"},
{ value: "A412",	text: "SEPTICEMIA DEBIDA A ESTAFILOCOCO NO ESPECIFICADO"},
{ value: "A413",	text: "SEPTICEMIA DEBIDA A HAEMOPHILUS INFLUENZAE"},
{ value: "A414",	text: "SEPTICEMIA DEBIDA A ANAEROBIOS"},
{ value: "A415",	text: "SEPTICEMIA DEBIDA A OTROS ORGANISMOS GRAMNEGATIVOS"},
{ value: "A418",	text: "OTRAS SEPTICEMIAS ESPECIFICADAS"},
{ value: "A419",	text: "SEPTICEMIA, NO ESPECIFICADA"},
{ value: "A420",	text: "ACTINOMICOSIS PULMONAR"},
{ value: "A421",	text: "ACTINOMICOSIS ABDOMINAL"},
{ value: "A422",	text: "ACTINOMICOSIS CERVICOFACIAL"},
{ value: "A427",	text: "SEPTICEMIA ACTINOMICOTICA"},
{ value: "A428",	text: "OTRAS FORMAS DE ACTINOMICOSIS"},
{ value: "A429",	text: "ACTINOMICOSIS, SIN OTRA ESPECIFICACION"},
{ value: "A430",	text: "NOCARDIOSIS PULMONAR"},
{ value: "A431",	text: "NOCARDIOSIS CUTANEA"},
{ value: "A438",	text: "OTRAS FORMAS DE NOCARDIOSIS"},
{ value: "A439",	text: "NORCARDIOSIS , NO ESPECIFICADA"},
{ value: "A440",	text: "BARTONELOSIS SISTEMICA"},
{ value: "A441",	text: "BARTONELOSIS CUTANEA Y MUCOCUTANEA"},
{ value: "A448",	text: "OTRAS FORMAS DE BARTONELOSIS"},
{ value: "A449",	text: "BARTONELOSIS, NO ESPECIFICADA"},
{ value: "A46X",	text: "ERISIPELA"},
{ value: "A480",	text: "GANGRENA GASEOSA"},
{ value: "A481",	text: "ENFERMEDAD DE LOS LEGIONARIOS"},
{ value: "A482",	text: "ENFERMEDAD DE LOS LEGIONARIOS NO NEUMONICA [FIEBRE DE PONTIAC]"},
{ value: "A483",	text: "SINDROME DE CHOQUE TOXICO"},
{ value: "A484",	text: "FIEBRE PURPURICA BRASILEÑA"},
{ value: "A488",	text: "OTRAS ENFERMEDADES BACTERIANAS ESPECIFICADAS"},
{ value: "A490",	text: "INFECCION ESTAFILOCOCICA, SIN OTRA ESPECIFICACIÓN"},
{ value: "A491",	text: "INFECCION ESTREPTOCOCICA, SIN OTRA ESPECIFICACION"},
{ value: "A492",	text: "INFECCION POR HAEMOPHILUS INFLUENZAE, SIN OTRA ESPECIFICACION"},
{ value: "A493",	text: "INFECCION POR MICOPLASMA, SIN OTRA ESPECIFICACION"},
{ value: "A498",	text: "OTRAS INFECCIONES BACTERIANAS DE SITIO NO ESPECIFICADO"},
{ value: "A499",	text: "INFECCION BACTERIANA, NO ESPECIFICADA"},
{ value: "A500",	text: "SIFILIS CONGENITA PRECOZ, SINTOMATICA"},
{ value: "A501",	text: "SIFILIS CONGENITA PRECOZ, LATENTE"},
{ value: "A502",	text: "SIFILIS CONGENITA PRECOZ SIN OTRA ESPECIFICACIÓN"},
{ value: "A503",	text: "OCULOPATIA SIFILITICA CONGENITA TARDIA"},
{ value: "A504",	text: "NEUROSIFILIS CONGENITA TARDIA [NEUROSIFILIS JUVENIL]"},
{ value: "A505",	text: "OTRAS FORMAS DE SIFILIS CONGENITA TARDIA, SINTOMATICA"},
{ value: "A506",	text: "SIFILIS CONGENITA TARDIA, LATENTE"},
{ value: "A507",	text: "SIFILIS CONGENITA TARDIA, SIN OTRA ESPECIFICACION"},
{ value: "A509",	text: "SIFILIS CONGENITA, SIN OTRA ESPECIFICACIÓN"},
{ value: "A510",	text: "SIFILIS GENITAL PRIMARIA"},
{ value: "A511",	text: "SIFILIS PRIMARIA ANAL"},
{ value: "A512",	text: "SIFILIS PRIMARIA EN OTROS SITIOS"},
{ value: "A513",	text: "SIFILIS SECUNDARIA DE PIEL Y MEMBRANAS MUCOSAS"},
{ value: "A514",	text: "OTRAS SIFILIS SECUNDARIAS"},
{ value: "A515",	text: "SIFILIS PRECOZ, LATENTE"},
{ value: "A519",	text: "SIFILIS PRECOZ, SIN OTRA ESPECIFICACIÓN"},
{ value: "A520",	text: "SIFILIS CARDIOVASCULAR"},
{ value: "A521",	text: "NEUROSIFILIS SINTOMATICA"},
{ value: "A522",	text: "NEUROSIFILIS ASINTOMATICA"},
{ value: "A523",	text: "NEUROSIFILIS NO ESPECIFICADA"},
{ value: "A527",	text: "OTRAS SIFILIS TARDIAS SINTOMATICAS"},
{ value: "A528",	text: "SIFILIS TARDIA, LATENTE"},
{ value: "A529",	text: "SIFILIS TARDIA, NO ESPECIFICADA"},
{ value: "A530",	text: "SIFILIS LATENTE, NO ESPECIFICADA COMO PRECOZ O TARDIA"},
{ value: "A539",	text: "SIFILIS, NO ESPECIFICADA"},
{ value: "A540",	text: "INFECCION GONOCOCICA DEL TRACTO GENITOURINARIO INFERIOR SIN ABSCESO PERIURETRAL O DE GLANDULA ACCESORIA"},
{ value: "A541",	text: "INFECCION GONOCOCICA DEL TRACTO GENITOURINARIO INFERIOR CON ABSCESO PERIURETRAL Y DE GLANDULAS ACCESORIAS"},
{ value: "A542",	text: "PELVIPERITONITIS GONOCOCICA Y OTRAS INFECCIONES GONOCOCICAS GENITOURINARIAS"},
{ value: "A543",	text: "INFECCION GONOCOCICA DEL OJO"},
{ value: "A544",	text: "INFECCION GONOCOCICA DEL SISTEMA OSTEOMUSCULAR"},
{ value: "A545",	text: "FARINGITIS GONOCOCICA"},
{ value: "A546",	text: "INFECCION GONOCOCICA DEL ANO Y DEL RECTO"},
{ value: "A548",	text: "OTRAS INFECCIONES GONOCOCICAS"},
{ value: "A549",	text: "INFECCION, GONOCOCICA, NO ESPECIFICADA"},
{ value: "A55X",	text: "LINFOGRANULOMA (VENEREO) POR CLAMIDIAS"},
{ value: "A560",	text: "INFECCION DEL TRACTO GENITOURINARIO INFERIOR DEBIDA A CLAMIDIAS"},
{ value: "A561",	text: "INFECCION DEL PELVIPERITONEO Y OTROS ORGANOS GENITOURINARIOS DEBIDA A CLAMIDIAS"},
{ value: "A562",	text: "INFECCIONES DEL TRACTO GENITOURINARIO DEBIDAS A CLAMIDIAS, SIN OTRA ESPECIFICACION"},
{ value: "A563",	text: "INFECCION DEL ANO Y DEL RECTO DEBIDA A CLAMIDIAS"},
{ value: "A564",	text: "INFECCION DE FARINGE DEBIDA A CLAMIDIAS"},
{ value: "A568",	text: "INFECCION DE TRANSMISIÓN SEXUAL DE OTROS SITIOS DEBIDA A CLAMIDIAS"},
{ value: "A57X",	text: "CHANCRO BLANDO"},
{ value: "A58X",	text: "GRANULOMA INGUINAL"},
{ value: "A590",	text: "TRICOMONIASIS UROGENITAL"},
{ value: "A598",	text: "TRICOMONIASIS DE OTROS SITIOS"},
{ value: "A599",	text: "TRICOMONIASIS, NO ESPECIFICADA"},
{ value: "A600",	text: "INFECCION DE GENITALES Y TRAYECTO UROGENITAL Y DEBIDA A VIRUS DEL HERPES [HERPES SIMPLE]"},
{ value: "A601",	text: "INFECCION DE LA PIEL PERIANAL Y RECTO POR VIRUS DEL HERPES SIMPLE"},
{ value: "A609",	text: "INFECCION ANOGENITAL POR VIRUS DEL HERPES SIMPLE, SIN OTRA ESPECIFICACION"},
{ value: "A630",	text: "VERRUGAS (VENEREAS) ANOGENITALES"},
{ value: "A638",	text: "OTRAS ENFERMEDADES DE TRANSMISIÓN PREDOMINANTEMENTE SEXUAL, ESPECIFICADAS"},
{ value: "A64X",	text: "ENFERMEDAD DE TRANSMISION SEXUAL NO ESPECIFICADA"},
{ value: "A65X",	text: "SIFILIS NO VENEREA"},
{ value: "A660",	text: "LESIONES INICIALES DE FRAMBESIA"},
{ value: "A661",	text: "LESIONES PAPILOMATOSAS MULTIPLES Y FRAMBESIA CON PASO DE CANGREJO"},
{ value: "A662",	text: "OTRAS LESIONES PRECOCES DE LA PIEL EN LA FRAMBESIA"},
{ value: "A663",	text: "HIPERQUERATOSIS DE FRAMBESIA"},
{ value: "A664",	text: "GOMA Y ULCERAS DE FRAMBESIA"},
{ value: "A665",	text: "GANGOSA"},
{ value: "A666",	text: "LESIONES FRAMBESICAS DE LOS HUESOS Y DE LAS ARTICULACIONES"},
{ value: "A667",	text: "OTRAS MANIFESTACIONES DE FRAMBESIA"},
{ value: "A668",	text: "FRAMBESIA LATENTE"},
{ value: "A669",	text: "FRAMBESIA , NO ESPECIFICADA"},
{ value: "A670",	text: "LESIONES PRIMARIAS DE LA PINTA"},
{ value: "A671",	text: "LESIONES INTERMEDIAS DE LA PINTA"},
{ value: "A672",	text: "LESIONES TARDIAS DE LA PINTA"},
{ value: "A673",	text: "LESIONES MIXTAS DE LA PINTA"},
{ value: "A679",	text: "PINTA, NO ESPECIFICADA"},
{ value: "A680",	text: "FIEBRE RECURRENTE TRANSMITIDA POR PIOJOS"},
{ value: "A681",	text: "FIEBRE RECURRENTE TRANSMITIDA POR GARRAPATAS"},
{ value: "A689",	text: "FIEBRE RECURRENTE, NO ESPECIFICADA"},
{ value: "A690",	text: "ESTOMATITIS ULCERATIVA NECROTIZANTE"},
{ value: "A691",	text: "OTRAS INFECCIONES DE VICENT"},
{ value: "A692",	text: "ENFERMEDAD DE LYME"},
{ value: "A698",	text: "OTRAS INFECCIONES ESPECIFICADAS POR ESPIROQUETAS"},
{ value: "A699",	text: "INFECCION POR ESPIROQUETA, NO ESPECIFICADA"},
{ value: "A70X",	text: "INFECCION DEBIDA A CHLAMYDIA PSITTACI"},
{ value: "A710",	text: "ESTADO INICIAL DE TRACOMA"},
{ value: "A711",	text: "ESTADO ACTIVO DE TRACOMA"},
{ value: "A719",	text: "TRACOMA, NO ESPECIFICADO"},
{ value: "A740",	text: "CONJUNTIVITIS POR CLAMIDIAS (H13.1*)"},
{ value: "A748",	text: "OTRAS ENFERMEDADES POR CLAMIDIAS"},
{ value: "A749",	text: "INFECCION POR CLAMIDIAS, NO ESPECIFICADA"},
{ value: "A750",	text: "TIFUS EPIDEMICO DEBIDO A RICKETTSIA PROWAZEKII TRANSMITIDO POR PIOJOS"},
{ value: "A751",	text: "TIFUS RECRUDESCENTE [ENFERMEDAD DE BRILL]"},
{ value: "A752",	text: "TIFUS DEBIDO A RICKETTSIA TYPHI"},
{ value: "A753",	text: "TIFUS DEBIDO A RICKETTSIA TSUTSUGAMUSHI"},
{ value: "A759",	text: "TIFUS, NO ESPECIFICADO"},
{ value: "A770",	text: "FIEBRE MACULOSA DEBIDO A RICKETTSIA RICKETTSII"},
{ value: "A771",	text: "FIEBRE MACULOSA DEBIDO A RICKETTSIA CONORII"},
{ value: "A772",	text: "FIEBRE MACULOSA DEBIDO A RICKETTSIA SIBERICA"},
{ value: "A773",	text: "FIEBRE MACULOSA DEBIDO A RICKETTSIA AUSTRALIS"},
{ value: "A778",	text: "OTRAS FIEBRES MACULOSAS"},
{ value: "A779",	text: "FIEBRE MACULOSA, NO ESPECIFICADA"},
{ value: "A78X",	text: "FIEBRE Q"},
{ value: "A790",	text: "FIEBRE DE LAS TRINCHERAS"},
{ value: "A791",	text: "RICKETTSIOSIS PUSTULOSA DEBIDA A RICKETTSIA AKARI"},
{ value: "A798",	text: "OTRAS RICKETTSIOSIS ESPECIFICADAS"},
{ value: "A799",	text: "RICKETTSIOSIS, NO ESPECIFICADA"},
{ value: "A800",	text: "POLIOMIELITIS AGUDA PARALITICA, ASOCIADA A VACUNA"},
{ value: "A801",	text: "POLIOMIELITIS AGUDA PARALITICA DEBIDA A VIRUS SALVAJE IMPORTADO"},
{ value: "A802",	text: "POLIOMIELITIS AGUDA PARALITICA DEBIDA A VIRUS SALVAJE AUTOCTONO"},
{ value: "A803",	text: "OTRAS POLIOMIELITIS AGUDAS PARALITICAS, Y NO LAS ESPECIFICADAS"},
{ value: "A804",	text: "POLIOMIELITIS AGUDA NO PARALITICA"},
{ value: "A809",	text: "POLIOMIELITIS AGUDA, SIN OTRA ESPECIFICACIÓN"},
{ value: "A810",	text: "ENFERMEDAD DE CREUTZFELDT-JAKOB"},
{ value: "A811",	text: "PANENCEFALITIS ESCLEROSANTE SUBAGUDA"},
{ value: "A812",	text: "LEUCOENCEFALOPATIA MULTIFOCAL PROGRESIVA"},
{ value: "A818",	text: "OTRAS INFECCIONES DEL SISTEMA NERVIOSO POR VIRUS ATIPICO"},
{ value: "A819",	text: "INFECCIONES DEL SISTEMA NERVIOSO CENTRAL POR VIRUS ATIPICO, SIN OTRA ESPECIFICACION"},
{ value: "A820",	text: "RABIA SELVATICA"},
{ value: "A821",	text: "RABIA URBANA"},
{ value: "A829",	text: "RABIA, SIN OTRA ESPECIFICACION"},
{ value: "A830",	text: "ENCEFALITIS JAPONESA"},
{ value: "A831",	text: "ENCEFALITIS EQUINA DEL OESTE"},
{ value: "A832",	text: "ENCEFALITIS EQUINA DEL ESTE"},
{ value: "A833",	text: "ENCEFALITIS DE SAN LUIS"},
{ value: "A834",	text: "ENCEFALITIS AUSTRALIANA"},
{ value: "A835",	text: "ENCEFALITIS DE CALIFORNIA"},
{ value: "A836",	text: "ENFERMEDAD POR VIRUS ROCIO"},
{ value: "A838",	text: "OTRAS ENCEFALITIS VIRALES TRANSMITIDAS POR MOSQUITOS"},
{ value: "A839",	text: "ENCEFALITIS VIRAL TRANSMITIDA POR MOSQUITOS, SIN OTRA ESPECIFICACION"},
{ value: "A840",	text: "ENCEFALITIS DEL LEJANO ORIENTE TRANSMITIDA POR GARRAPATAS [ENCEFALITIS PRIMAVEROESTIVAL RUSA]"},
{ value: "A841",	text: "ENCEFALITIS CENTROEUROPEA TRANSMITIDA POR GARRAPATAS"},
{ value: "A848",	text: "OTRAS ENCEFALITIS VIRALES TRANSMITIDAS POR GARRAPATAS"},
{ value: "A849",	text: "ENCEFALITIS VIRAL TRANSMITIDA POR GARRAPATAS, SIN OTRA ESPECIFICACION"},
{ value: "A850",	text: "ENCEFALITIS ENTEROVIRAL (G05.1*)"},
{ value: "A851",	text: "ENCEFALITIS POR ADENOVIRUS (G05.1*)"},
{ value: "A852",	text: "ENCEFALITIS VIRAL TRANSMITIDA POR ARTROPODOS, SIN OTRA ESPECIFICACIÓN"},
{ value: "A858",	text: "OTRAS ENCEFALITIS VIRALES ESPECIFICADAS"},
{ value: "A86X",	text: "ENCEFALITIS VIRAL, NO ESPECIFICADA"},
{ value: "A870",	text: "MENINGITIS ENTEROVIRAL (G02.0*)"},
{ value: "A871",	text: "MENINGITIS DEBIDA A ADENOVIRUS (G02.0*)"},
{ value: "A872",	text: "CORIOMENINGITIS LINFOCITICA"},
{ value: "A878",	text: "OTRAS MENINGITIS VIRALES"},
{ value: "A879",	text: "MENINGITIS VIRAL, SIN OTRA ESPECIFICACION"},
{ value: "A880",	text: "FIEBRE EXANTEMATICA ENTEROVIRAL [EXANTEMA DE BOSTON]"},
{ value: "A881",	text: "VERTIGO EPIDEMICO"},
{ value: "A888",	text: "OTRAS INFECCIONES VIRALES ESPECIFICADAS DEL SISTEMA NERVIOSO CENTRAL"},
{ value: "A89X",	text: "INFECCION VIRAL DEL SISTEMA NERVIOSO CENTRAL, NO ESPECIFICADA"},
{ value: "A90X",	text: "FIEBRE DEL DENGUE [DENGUE CLASICO]"},
{ value: "A91X",	text: "FIEBRE DEL DENGUE HEMORRAGICO"},
{ value: "A920",	text: "ENFERMEDAD POR VIRUS CHIKUNGUNYA"},
{ value: "A921",	text: "FIEBRE DE O´NYONG-NYONG"},
{ value: "A922",	text: "FIEBRE EQUINA VENEZOLANA"},
{ value: "A923",	text: "FIEBRE DEL OESTE DEL NILO"},
{ value: "A924",	text: "FIEBRE DEL VALLE DEL RIFT"},
{ value: "A928",	text: "OTRAS FIEBRES VIRALES ESPECIFICADAS TRANSMITIDAS POR MOSQUITOS"},
{ value: "A929",	text: "FIEBRE VIRAL TRANSMITIDA POR MOSQUITO, SIN OTRA ESPECIFICACION"},
{ value: "A930",	text: "ENFERMEDAD POR VIRUS DE OROPOUCHE"},
{ value: "A931",	text: "FIEBRE TRANSMITIDA POR FLEBOTOMOS"},
{ value: "A932",	text: "FIEBRE DE COLORADO TRANSMITIDA POR GARRAPATAS"},
{ value: "A938",	text: "OTRAS FIEBRES VIRALES ESPECIFICADAS TRANSMITIDAS POR ANTROPODOS"},
{ value: "A94X",	text: "FIEBRE VIRAL TRANSMITIDA POR ARTROPODOS, NO ESPECIFICADA"},
{ value: "A950",	text: "FIEBRE AMARILLA SELVATICA"},
{ value: "A951",	text: "FIEBRE AMARILLA URBANA"},
{ value: "A959",	text: "FIEBRE AMARILLA, NO ESPECIFICADA"},
{ value: "A960",	text: "FIEBRE HEMORRAGICA DE JUNIN"},
{ value: "A961",	text: "FIEBRE HEMORRAGICA DE MACHUPO"},
{ value: "A962",	text: "FIEBRE DE LASSA"},
{ value: "A968",	text: "OTRAS FIEBRES HEMORRAGICAS POR ARENAVIRUS"},
{ value: "A969",	text: "FIEBRE HEMORRAGICA POR ARENAVIRUS, SIN OTRA ESPECIFICACION"},
{ value: "A980",	text: "FIEBRE HEMORRAGICA DE CRIMEA- CONGO"},
{ value: "A981",	text: "FIEBRE HEMORRAGICA DE OMSK"},
{ value: "A982",	text: "ENFERMEDAD DE LA SELVA DE KYASANUR"},
{ value: "A983",	text: "ENFERMEDAD POR EL VIRUS DE MARBURG"},
{ value: "A984",	text: "ENFERMEDAD POR EL VIRUS DE EBOLA"},
{ value: "A985",	text: "FIEBRES HEMORRAGICAS CON SINDROME RENAL"},
{ value: "A988",	text: "OTRAS FIEBRES HEMORRAGICAS VIRALES ESPECIFICADAS"},
{ value: "A99X",	text: "FIEBRE VIRAL HEMORRAGICA, NO ESPECIFICADA"},
{ value: "B000",	text: "ECZEMA HERPETICO"},
{ value: "B001",	text: "DERMATITIS VESICULAR HERPETICA"},
{ value: "B002",	text: "GINGIVOESTOMATITIS Y FARINGOAMIGDALITIS HERPETICA"},
{ value: "B003",	text: "MENINGITIS HERPETICA (G02.0*)"},
{ value: "B004",	text: "ENCEFALITIS HERPETICA (G05.1*)"},
{ value: "B005",	text: "OCULOPATIA HERPETICA"},
{ value: "B007",	text: "ENFERMEDAD HERPETICA DISEMINADA"},
{ value: "B008",	text: "OTRAS FORMAS DE INFECCIONES HERPETICAS"},
{ value: "B009",	text: "INFECCION DEBIDA A EL VIRUS DEL HERPES, NO ESPECIFICADA"},
{ value: "B010",	text: "MENINGITIS DEBIDA A LA VARICELA (G02.0*)"},
{ value: "B011",	text: "ENCEFALITIS DEBIDA A LA VARICELA (G05.1*)"},
{ value: "B012",	text: "NEUMONIA DEBIDA A LA VARICELA (J17.1*)"},
{ value: "B018",	text: "VARICELA CON OTRAS COMPLICACIONES"},
{ value: "B019",	text: "VARICELA SIN COMPLICACIONES"},
{ value: "B020",	text: "ENCEFALITIS DEBIDA A HERPES ZOSTER (G05.1*)"},
{ value: "B021",	text: "MENINGITIS DEBIDA A HERPES ZOSTER (G02.0*)"},
{ value: "B022",	text: "HERPES ZOSTER CON OTROS COMPROMISOS DEL SISTEMA NERVIOSO"},
{ value: "B023",	text: "HERPES ZOSTER OCULAR"},
{ value: "B027",	text: "HERPES ZOSTER DISEMINADO"},
{ value: "B028",	text: "HERPES ZOSTER CON OTRAS COMPLICACIONES"},
{ value: "B029",	text: "HERPES ZOSTER SIN COMPLICACIONES"},
{ value: "B03X",	text: "VIRUELA"},
{ value: "B04X",	text: "VIRUELA DE LOS MONOS"},
{ value: "B050",	text: "SARAMPION COMPLICADO CON ENCEFALITIS (G05.1*)"},
{ value: "B051",	text: "SARAMPION COMPLICADO CON MENINGITIS (G02.0*)"},
{ value: "B052",	text: "SARAMPION COMPLICADO CON NEUMONIA (J17.1*)"},
{ value: "B053",	text: "SARAMPION COMPLICADO CON OTITIS MEDIA (H67.1*)"},
{ value: "B054",	text: "SARAMPION CON COMPLICACIONES INTESTINALES"},
{ value: "B058",	text: "SARAMPION CON OTRAS COMPLICACIONES"},
{ value: "B059",	text: "SARAMPION SIN COMPLICACIONES"},
{ value: "B060",	text: "RUBEOLA CON COMPLICACIONES NEUROLOGICAS"},
{ value: "B068",	text: "RUBEOLA CON OTRAS COMPLICACIONES"},
{ value: "B069",	text: "RUBEOLA SIN COMPLICACIONES"},
{ value: "B07X",	text: "VERRUGAS VIRICAS"},
{ value: "B080",	text: "OTRAS INFECCIONES DEBIDAS A ORTOPOXVIRUS"},
{ value: "B081",	text: "MOLUSCO CONTAGIOSO"},
{ value: "B082",	text: "EXANTEMA SUBITO [SEXTA ENFERMEDAD]"},
{ value: "B083",	text: "ERITEMA INFECCIOSO [QUINTA ENFERMEDAD]"},
{ value: "B084",	text: "ESTOMATITIS VESICULAR ENTEROVIRAL CON EXANTEMA"},
{ value: "B085",	text: "FARINGITIS VESICULAR ENTEROVIRICA"},
{ value: "B088",	text: "OTRAS INFECCIONES VIRALES ESPECIFICADAS, CARACTERIZADAS POR LESIONES DE LA PIEL Y DE LAS MEMBRANAS MUCOSAS"},
{ value: "B09X",	text: "INFECCION VIRAL NO ESPECIFICADA, CARACTERIZADA POR LESIONES DE LA PIEL Y DE LAS MEMBRANAS MUCOSAS"},
{ value: "B150",	text: "HEPATITIS AGUDA TIPO A, SIN COMA HEPATICO"},
{ value: "B159",	text: "HEPATITIS AGUDA TIPO A, CON COMA HEPATICO"},
{ value: "B160",	text: "HEPATITIS AGUDA TIPO B, CON AGENTE DELTA (COINFECCION). CON COMA HEPATICO"},
{ value: "B161",	text: "HEPATITIS AGUDA TIPO B, CON AGENTE DELTA (COINFECCION), SIN COMA HEPATICO"},
{ value: "B162",	text: "HEPATITIS AGUDA TIPO B, SIN AGENTE DELTA, CON COMA HEPATICO"},
{ value: "B169",	text: "HEPATITIS AGUDA TIPO B, SIN AGENTE DELTA Y SIN COMA HEPATICO"},
{ value: "B170",	text: "INFECCION (SUPERINFECCION) AGUDA POR AGENTE DELTA EN EL PORTADOR DE HEPATITIS B"},
{ value: "B171",	text: "HEPATITIS AGUDA TIPO C"},
{ value: "B172",	text: "HEPATITIS AGUDA TIPO E"},
{ value: "B178",	text: "OTRAS HEPATITIS VIRALES AGUDAS ESPECIFICADAS"},
{ value: "B180",	text: "HEPATITIS VIRAL TIPO B CRONICA, CON AGENTE DELTA"},
{ value: "B181",	text: "HEPATITIS VIRAL TIPO B CRONICA, SIN AGENTE DELTA"},
{ value: "B182",	text: "HEPATITIS VIRAL TIPO C CRONICA"},
{ value: "B188",	text: "OTRAS HEPATITIS VIRALES CRONICAS"},
{ value: "B189",	text: "HEPATITIS VIRAL CRONICA, SIN OTRA ESPECIFICACION"},
{ value: "B190",	text: "HEPATITIS VIRAL NO ESPECIFICADA CON COMA"},
{ value: "B199",	text: "HEPATITIS VIRAL NO ESPECIFICADA SIN COMA"},
{ value: "B200",	text: "ENFERMEDAD POR EL VIH, RESULTANTE EN INFECCION POR MICOBACTERIAS"},
{ value: "B201",	text: "ENFERMEDAD POR EL VIH, RESULTANTE EN OTRAS INFECCIONES BACTERIANAS"},
{ value: "B202",	text: "ENFERMEDAD POR VIH, RESULTANTE EN ENFERMEDAD POR CITOMEGALOVIRUS"},
{ value: "B203",	text: "ENFERMEDAD POR VIH, RESULTANTE EN OTRAS INFECCIONES VIRALES"},
{ value: "B204",	text: "ENFERMEDAD POR VIH, RESULTANTE EN CANDIDIASIS"},
{ value: "B205",	text: "ENFERMEDAD POR VIH, RESULTANTE EN OTRAS MICOSIS"},
{ value: "B206",	text: "ENFERMEDAD POR VIH, RESULTANTE EN NEUMONIA POR PNEUMOCYSTIS CARINII"},
{ value: "B207",	text: "ENFERMEDAD POR VIH, RESULTANTE EN INFECCIONES MULTIPLES"},
{ value: "B208",	text: "ENFERMEDAD POR VIH, RESULTANTE EN OTRAS ENFERMEDADES INFECCIOSAS O PARASITARIAS"},
{ value: "B209",	text: "ENFERMEDAD POR VIH, RESULTANTE EN ENFERMEDAD INFECCIOSA O PARASITARIA NO ESPECIFICADA"},
{ value: "B210",	text: "ENFERMEDAD POR VIH, RESULTANTE EN SARCOMA DE KAPOSI"},
{ value: "B211",	text: "ENFERMEDAD POR VIH, RESULTANTE EN LINFOMA DE BURKITT"},
{ value: "B212",	text: "ENFERMEDAD POR VIH, RESULTANTE EN OTROS TIPOS DE LINFOMA NO HODGKIN"},
{ value: "B213",	text: "ENFERMEDAD POR VIH, RESULTANTE EN OTROS TUMORES MALIGNOS DEL TEJIDO LINFOIDE, HEMATOPOYETICO Y TEJIDOS RELACIONADOS"},
{ value: "B217",	text: "ENFERMEDAD POR VIH, RESULTANTE EN TUMORES MALIGNOS MULTIPLES"},
{ value: "B218",	text: "ENFERMEDAD POR VIH, RESULTANTE EN OTROS TUMORES MALIGNOS"},
{ value: "B219",	text: "ENFERMEDAD POR VIH, RESULTANTE EN TUMORES MALIGNOS NO ESPECIFICADOS"},
{ value: "B220",	text: "ENFERMEDAD POR VIH, RESULTANTE EN ENCEFALOPATIA"},
{ value: "B221",	text: "ENFERMEDAD POR VIH, RESULTANTE EN NEUMONITIS LINFOIDE INTERSTICIAL"},
{ value: "B222",	text: "ENFERMEDAD POR VIH, RESULTANTE EN SINDROME CAQUECTICO"},
{ value: "B227",	text: "ENFERMEDAD POR VIH, RESULTANTE EN ENFERMEDADES MULTIPLES CLASIFICADAS EN OTRA PARTE"},
{ value: "B230",	text: "SINDROME DE INFECCION AGUDA DEBIDA A VIH"},
{ value: "B231",	text: "ENFERMEDAD POR VIH, RESULTANTE EN LINFADENOPATIA GENERALIZADA (PERSISTENTE)"},
{ value: "B232",	text: "ENFERMEDAD POR VIH, RESULTANTE EN ANORMALIDADES INMUNOLOGICAS Y HEMATOLOGICAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "B238",	text: "ENFERMEDAD POR VIH, RESULTANTE EN OTRAS AFECCIONES ESPECIFICADAS"},
{ value: "B24X",	text: "ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA (VIH), SIN OTRA ESPECIFICACION"},
{ value: "B250",	text: "NEUMONITIS DEBIDA A VIRUS CITOMEGALICO (J17.1*)"},
{ value: "B251",	text: "HEPATITIS DEBIDA A VIRUS CITOMEGALICO"},
{ value: "B252",	text: "PANCREATITIS DEBIDA A VIRUS CITOMEGALICO"},
{ value: "B258",	text: "OTRAS ENFERMEDADES DEBIDAS A VIRUS CITOMEGALICO"},
{ value: "B259",	text: "ENFERMEDAD POR VIRUS CITOMEGALICO, NO ESPECIFICADA"},
{ value: "B260",	text: "ORQUITIS POR PAROTIDITIS (N51.1*)"},
{ value: "B261",	text: "MENINGITIS POR PAROTIDITIS (G02.0*)"},
{ value: "B262",	text: "ENCEFALITIS POR PAROTIDITIS (G05.1*)"},
{ value: "B263",	text: "PANCREATITIS POR PAROTIDITIS (K87.1*)"},
{ value: "B268",	text: "PAROTIDITIS INFECCIOSA CON OTRAS COMPLICACIONES"},
{ value: "B269",	text: "PAROTIDITIS, SIN COMPLICACIONES"},
{ value: "B270",	text: "MONONUCLEOSIS DEBIDA A HERPES VIRUS GAMMA"},
{ value: "B271",	text: "MONONUCLEOSIS POR CITOMEGALOVIRUS"},
{ value: "B278",	text: "OTRAS MONONUCLEOSIS INFECCIOSAS"},
{ value: "B279",	text: "MONONUCLEOSIS INFECCIOSA, NO ESPECIFICADA"},
{ value: "B300",	text: "QUERATOCONJUNTIVITIS DEBIDA A ADENOVIRUS (H19.2*)"},
{ value: "B301",	text: "CONJUNTIVITIS DEBIDA A ADENOVIRUS (H13.1*)"},
{ value: "B302",	text: "FARINGOCONJUNTIVITIS VIRAL"},
{ value: "B303",	text: "CONJUNTIVITIS EPIDEMICA AGUDA HEMORRAGICA (ENTEROVIRICA) (H13.1*)"},
{ value: "B308",	text: "OTRAS CONJUNTIVITIS VIRALES (H13.1*)"},
{ value: "B309",	text: "CONJUNTIVITIS VIRAL, SIN OTRA ESPECIFICACION"},
{ value: "B330",	text: "MIALGIA EPIDEMICA"},
{ value: "B331",	text: "ENFERMEDAD DEL RIO ROSS"},
{ value: "B332",	text: "CARDITIS VIRAL"},
{ value: "B333",	text: "INFECCIONES DEBIDAS A RETROVIRUS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "B338",	text: "OTRAS ENFERMEDADES VIRALES ESPECIFICADAS"},
{ value: "B340",	text: "INFECCION DEBIDA A ADENOVIRUS, SIN OTRA ESPECIFICACION"},
{ value: "B341",	text: "INFECCION DEBIDA A ENTEROVIRUS, SIN OTRA ESPECIFICACION"},
{ value: "B342",	text: "INFECCION DEBIDA A CORONAVIRUS, SIN OTRA ESPECIFICACION"},
{ value: "B343",	text: "INFECCION DEBIDA A PARVOVIRUS, SIN OTRA ESPECIFICACION"},
{ value: "B344",	text: "INFECCION DEBIDA A PAPOVAVIRUS, SIN OTRA ESPECIFICACION"},
{ value: "B348",	text: "OTRAS INFECCIONES VIRALES DE SITIO NO ESPECIFICADO"},
{ value: "B349",	text: "INFECCION VIRAL, NO ESPECIFICADA"},
{ value: "B350",	text: "TIÑA DE LA BARBA Y DEL CUERO CABELLUDO"},
{ value: "B351",	text: "TIÑA DE LAS UÑAS"},
{ value: "B352",	text: "TIÑA DE LA MANO"},
{ value: "B353",	text: "TIÑA DEL PIE [TINEA PEDIS]"},
{ value: "B354",	text: "TIÑA DEL CUERPO [TINEA CORPORIS]"},
{ value: "B355",	text: "TIÑA IMBRICADA [TINEA IMBRICATA]"},
{ value: "B356",	text: "TIÑA INGUINAL [TINEA CRURIS]"},
{ value: "B358",	text: "OTRAS DERMATOFITOSIS"},
{ value: "B359",	text: "DERMATOFITOSIS, NO ESPECIFICADA"},
{ value: "B360",	text: "PITIRIASIS VERSICOLOR"},
{ value: "B361",	text: "TIÑA NEGRA"},
{ value: "B362",	text: "PIEDRA BLANCA"},
{ value: "B363",	text: "PIEDRA NEGRA"},
{ value: "B368",	text: "OTRAS MICOSIS SUPERFICIALES ESPECIFICADAS"},
{ value: "B369",	text: "MICOSIS SUPERFICIAL, SIN OTRA ESPECIFICACION"},
{ value: "B370",	text: "ESTOMATITIS CANDIDIASICA"},
{ value: "B371",	text: "CANDIDIASIS PULMONAR"},
{ value: "B372",	text: "CANDIDIASIS DE LA PIEL Y DE LAS UÑAS"},
{ value: "B373",	text: "CANDIDIASIS DE LA VULVA Y DE LA VAGINA (N77.1*)"},
{ value: "B374",	text: "CANDIDIASIS DE OTRAS LOCALIZACIONES UROGENITALES"},
{ value: "B375",	text: "MENINGITIS DEBIDA A CANDIDA (G02.1*)"},
{ value: "B376",	text: "ENDOCARDITIS DEBIDA A CANDIDA (I39.8*)"},
{ value: "B377",	text: "SEPTICEMIA DEBIDA A CANDIDA"},
{ value: "B378",	text: "CANDIDIASIS DE OTROS SITIOS"},
{ value: "B379",	text: "CANDIDIASIS, NO ESPECIFICADA"},
{ value: "B380",	text: "COCCIDIOIDOMICOSIS PULMONAR AGUDA"},
{ value: "B381",	text: "COCCIDIOIDOMICOSIS PULMONAR CRONICA"},
{ value: "B382",	text: "COCCIDIOIDOMICOSIS PULMONAR, SIN OTRA ESPECIFICACION"},
{ value: "B383",	text: "COCCIDIOIDOMICOSIS CUTANEA"},
{ value: "B384",	text: "MENINGITIS DEBIDA A COCCIDIOIDOMICOSIS (G02.1*)"},
{ value: "B387",	text: "COCCIDIOIDOMICOSIS DISEMINADA"},
{ value: "B388",	text: "OTRAS FORMAS DE COCCIDIOIDOMICOSIS"},
{ value: "B389",	text: "COCCIDIOIDOMICOSIS, NO ESPECIFICADA"},
{ value: "B390",	text: "INFECCION PULMONAR AGUDA DEBIDA A HISTOPLASMA CAPSULATUM"},
{ value: "B391",	text: "INFECCION PULMONAR CRONICA DEBIDA A HISTOPLASMA CAPSULATUM"},
{ value: "B392",	text: "INFECCION PULMONAR DEBIDA A HISTOPLASMA CAPSULATUM, SIN OTRA ESPECIFICACION"},
{ value: "B393",	text: "INFECCION DISEMINADA DEBIDA A HISTOPLASMA CAPSULATUM"},
{ value: "B394",	text: "HISTOPLASMOSIS DEBIDA A HISTOPLASMA CAPSULATUM, SIN OTRA ESPECIFICACION"},
{ value: "B395",	text: "INFECCION DEBIDA A HISTOPLASMA DUBOISII"},
{ value: "B399",	text: "HISTOPLASMOSIS, NO ESPECIFICADA"},
{ value: "B400",	text: "BLASTOMICOSIS PULMONAR AGUDA"},
{ value: "B401",	text: "BLASTOMICOSIS PULMONAR CRONICA"},
{ value: "B402",	text: "BLASTOMICOSIS PULMONAR, SIN OTRA ESPECIFICACION"},
{ value: "B403",	text: "BLASTOMICOSIS CUTANEA"},
{ value: "B407",	text: "BLASTOMICOSIS DISEMINADA"},
{ value: "B408",	text: "OTRAS FORMAS DE BLASTOMICOSIS"},
{ value: "B409",	text: "BLASTOMICOSIS, NO ESPECIFICADA"},
{ value: "B410",	text: "PARACOCCIDIOIDOMICOSIS PULMONAR"},
{ value: "B417",	text: "PARACOCCIDIOIDOMICOSIS DISEMINADA"},
{ value: "B418",	text: "OTRAS FORMAS PARACOCCIDIOIDOMICOSIS"},
{ value: "B419",	text: "PARACOCCIDIOIDOMICOSIS, NO ESPECIFICADA"},
{ value: "B420",	text: "ESPOROTRICOSIS PULMONAR (J99.8*)"},
{ value: "B421",	text: "ESPOROTRICOSIS LINFOCUTANEA"},
{ value: "B427",	text: "ESPOROTRICOSIS DISEMINADA"},
{ value: "B428",	text: "OTRAS FORMAS DE ESPOROTRICOSIS"},
{ value: "B429",	text: "ESPOROTRICOSIS, NO ESPECIFICADA"},
{ value: "B430",	text: "CROMOMICOSIS CUTANEA"},
{ value: "B431",	text: "ABSCESO CEREBRAL FEOMICOTICO"},
{ value: "B432",	text: "ABSCESO Y QUISTE SUBCUTANEO FEOMICOTICO"},
{ value: "B438",	text: "OTRAS FORMAS DE CROMOMICOSIS"},
{ value: "B439",	text: "CROMOMICOSIS, NO ESPECIFICADA"},
{ value: "B440",	text: "ASPERGILOSIS PULMONAR INVASIVA"},
{ value: "B441",	text: "OTRAS ASPERGILOSIS PULMONARES"},
{ value: "B442",	text: "ASPERGILOSIS AMIGDALINA"},
{ value: "B447",	text: "ASPERGILOSIS DISEMINADA"},
{ value: "B448",	text: "OTRAS FORMAS DE ASPERGILOSIS"},
{ value: "B449",	text: "ASPERGILOSIS, NO ESPECIFICADA"},
{ value: "B450",	text: "CRIPTOCOCOSIS PULMONAR"},
{ value: "B451",	text: "CRIPTOCOCOSIS CEREBRAL"},
{ value: "B452",	text: "CRIPTOCOCOSIS CUTANEA"},
{ value: "B453",	text: "CRIPTOCOCOSIS OSEA"},
{ value: "B457",	text: "CRIPTOCOCOSIS DISEMINADA"},
{ value: "B458",	text: "OTRAS FORMAS DE CRIPTOCOCOSIS"},
{ value: "B459",	text: "CRIPTOCOCOSIS, NO ESPECIFICADA"},
{ value: "B460",	text: "MUCORMICOSIS PULMONAR"},
{ value: "B461",	text: "MUCORMICOSIS RINOCEREBRAL"},
{ value: "B462",	text: "MUCORMICOSIS GASTROINTESTINAL"},
{ value: "B463",	text: "MUCORMICOSIS CUTANEA"},
{ value: "B464",	text: "MUCORMICOSIS DISEMINADA"},
{ value: "B465",	text: "MUCORMICOSIS, SIN OTRA ESPECIFICACION"},
{ value: "B468",	text: "OTRAS CIGOMICOSIS"},
{ value: "B469",	text: "CIGOMICOSIS, NO ESPECIFICADA"},
{ value: "B470",	text: "EUMICETOMA"},
{ value: "B471",	text: "ACTINOMICETOMA"},
{ value: "B479",	text: "MICETOMA, NO ESPECIFICADO"},
{ value: "B480",	text: "LOBOMICOSIS"},
{ value: "B481",	text: "RINOSPORIDIOSIS"},
{ value: "B482",	text: "ALESQUERIASIS"},
{ value: "B483",	text: "GEOTRICOSIS"},
{ value: "B484",	text: "PENICILOSIS"},
{ value: "B487",	text: "MICOSIS OPORTUNISTAS"},
{ value: "B488",	text: "OTRAS MICOSIS ESPECIFICADAS"},
{ value: "B49X",	text: "MICOSIS, NO ESPECIFICADA"},
{ value: "B500",	text: "PALUDISMO DEBIDO A PLASMODIUM FALCIPARUM CON COMPLICACIONES CEREBRALES"},
{ value: "B508",	text: "OTRO PALUDISMO GRAVE Y COMPLICADO DEBIDO A PLASMODIUM FALCIPARUM"},
{ value: "B509",	text: "PALUDISMO DEBIDO A PLASMODIUM FALCIPARUM, SIN OTRA ESPECIFICACION"},
{ value: "B510",	text: "PALUDISMO DEBIDO A PLASMODIUM VIVAX CON RUPTURA ESPLENICA"},
{ value: "B518",	text: "PALUDISMO DEBIDO A PLASMODIUM VIVAX CON OTRAS COMPLICACIONES"},
{ value: "B519",	text: "PALUDISMO DEBIDO A PLASMODIUM VIVAX, SIN COMPLICACIONES"},
{ value: "B520",	text: "PALUDISMO DEBIDO A PLASMODIUM MALARIAE CON NEFROPATIA"},
{ value: "B528",	text: "PALUDISMO DEBIDO A PLASMODIUM MALARIAE CON OTRAS COMPLICACIONES"},
{ value: "B529",	text: "PALUDISMO DEBIDO A PLASMODIUM MALARIAE, SIN COMPLICACIONES"},
{ value: "B530",	text: "PALUDISMO DEBIDO A PLASMODIUM AVALE"},
{ value: "B531",	text: "PALUDISMO DEBIDO A PLASMODIOS DE LOS SIMIOS"},
{ value: "B538",	text: "OTRO PALUDISMO CONFIRMADO PARASITOLOGICAMENTE, NO CLASIFICADO EN OTRA PARTE"},
{ value: "B54X",	text: "PALUDISMO [MALARIA] NO ESPECIFICADO"},
{ value: "B550",	text: "LEIISHMANIASIS VISCERAL"},
{ value: "B551",	text: "LEISHMANIASIS CUTANEA"},
{ value: "B552",	text: "LEISHMANIASIS MUCOCUTANEA"},
{ value: "B559",	text: "LEISHMANIASIS, NO ESPECIFICADA"},
{ value: "B560",	text: "TRIPANOSOMIASIS GAMBIENSE"},
{ value: "B561",	text: "TRIPANOSOMIASIS RHODESIENSE"},
{ value: "B569",	text: "TRIPANOSOMIASIS AFRICANA, SIN OTRA ESPECIFICACIÓN"},
{ value: "B570",	text: "ENFERMEDAD DE CHAGAS AGUDA QUE AFECTA AL CORAZON (141.2*, 198.1*)"},
{ value: "B571",	text: "ENFERMEDAD DE CHAGAS AGUDA QUE NO AFECTA AL CORAZON"},
{ value: "B572",	text: "ENFERMEDAD DE CHAGAS (CRONICA) QUE AFECTA AL CORAZON (141.2*,198.1*)"},
{ value: "B573",	text: "ENFERMEDAD DE CHAGAS (CRONICA) QUE AFECTA AL SISTEMA DIGESTIVO"},
{ value: "B574",	text: "ENFERMEDAD DE CHAGAS (CRONICA) QUE AFECTA AL SISTEMA NERVIOSO"},
{ value: "B575",	text: "ENFERMEDAD DE CHAGAS (CRONICA) QUE AFECTA OTROS ORGANOS"},
{ value: "B580",	text: "OCULOPATIA DEBIDA A TOXOPLASMA"},
{ value: "B581",	text: "HEPATITIS DEBIDA A TOXOPLASMA (K77.0*)"},
{ value: "B582",	text: "MENINGOENCEFALITIS DEBIDA A TOXOPLASMA (G05.2*)"},
{ value: "B583",	text: "TOXOPLASMOSIS PULMONAR (J17.3*)"},
{ value: "B588",	text: "TOXOPLASMOSIS CON OTRO ORGANO AFECTADO"},
{ value: "B589",	text: "TOXOPLASMOSIS, NO ESPECIFICADA"},
{ value: "B59X",	text: "NEUMOCISTOSIS"},
{ value: "B600",	text: "BABESIOSIS"},
{ value: "B601",	text: "ACANTAMEBIASIS"},
{ value: "B602",	text: "NAEGLERIASIS"},
{ value: "B608",	text: "OTRAS ENFERMEDADES ESPECIFICADAS DEBIDAS A PROTOZARIOS"},
{ value: "B64X",	text: "ENFERMEDAD DEBIDA A PROTOZOARIOS, NO ESPECIFICADA"},
{ value: "B650",	text: "ESQUISTOSOMIASIS DEBIDA A SCHISTOSOMA HAEMATOBIUM [ESQUISTOSOMIASIS URINARIA]"},
{ value: "B651",	text: "ESQUISTOSOMIASIS DEBIDA A SCHISTOSOMA MANSONI [ESQUISTOSOMIASIS INTESTINAL]"},
{ value: "B652",	text: "ESQUISTOSOMIASIS DEBIDA A SCHISTOSOMA JAPONICUM"},
{ value: "B653",	text: "DERMATITIS POR CERCARIAS"},
{ value: "B658",	text: "OTRAS ESQUISTOSOMIASIS"},
{ value: "B659",	text: "ESQUISTOSOMIASIS, NO ESPECIFICADA"},
{ value: "B660",	text: "OPISTORQUIASIS"},
{ value: "B661",	text: "CLONORQUIASIS"},
{ value: "B662",	text: "DICROCOELIASIS"},
{ value: "B663",	text: "FASCIOLIASIS"},
{ value: "B664",	text: "PARAGONIMIASIS"},
{ value: "B665",	text: "FASCIOLOPSIASIS"},
{ value: "B668",	text: "OTRAS INFECCIONES ESPECIFICADAS DEBIDAS A TREMATODOS"},
{ value: "B669",	text: "INFECCION DEBIDA A TREMATODOS, NO ESPECIFICADA"},
{ value: "B670",	text: "INFECCION DEL HIGADO DEBIDA A ECHINOCOCCUS GRANULOSUS"},
{ value: "B671",	text: "INFECCION DEL PULMON DEBIDA A ECHINOCOCCUS GRANULOSUS"},
{ value: "B672",	text: "INFECCION DE HUESO DEBIDA A ECHINOCOCCUS GRANULOSUS"},
{ value: "B673",	text: "INFECCION DE OTRO ORGANO Y DE SITIOS MULTIPLES DEBIDA A ECHINOCOCCUS GRANULOSUS"},
{ value: "B674",	text: "INFECCION DEBIDA A ECHINOCOCCUS GRANULOSUS, SIN OTRA ESPECIFICACION"},
{ value: "B675",	text: "INFECCION DEL HIGADO DEBIDA A ECHINOCOCCUS MULTILOCULARIS"},
{ value: "B676",	text: "INFECCION DE OTRO ORGANO Y DE SITIOS MULTIPLES DEBIDA A ECHINOCOCCUS MULTILOCULARIS"},
{ value: "B677",	text: "INFECCION DEBIDA A ECHINOCOCCUS MULTILOCULARIS, SIN OTRA ESPECIFICACION"},
{ value: "B678",	text: "EQUINOCOCOSIS DEL HIGADO, NO ESPECIFICADA"},
{ value: "B679",	text: "EQUINOCOCOSIS, OTRA Y LA NO ESPECIFICADA"},
{ value: "B680",	text: "TENIASIS DEBIDA A TAENIA SOLIUM"},
{ value: "B681",	text: "INFECCION DEBIDA A TAENIA SAGINATA"},
{ value: "B689",	text: "TENIASIS, NO ESPECIFICADA"},
{ value: "B690",	text: "CISTICERCOSIS DEL SISTEMA NERVIOSO CENTRAL"},
{ value: "B691",	text: "CISTICERCOSIS DEL OJO"},
{ value: "B698",	text: "CISTICERCOSIS DE OTROS SITIOS"},
{ value: "B699",	text: "CISTICERCOSIS, NO ESPECIFICADA"},
{ value: "B700",	text: "DIFILOBOTRIASIS INTESTINAL"},
{ value: "B701",	text: "ESPARGANOSIS"},
{ value: "B710",	text: "HIMENOLEPIASIS"},
{ value: "B711",	text: "DIPILIDIASIS"},
{ value: "B718",	text: "OTRAS INFECCIONES DEBIDAS A CESTODOS ESPECIFICADAS"},
{ value: "B719",	text: "INFECCION DEBIDA A CESTODOS, NO ESPECIFICADA"},
{ value: "B72X",	text: "DRACONTIASIS"},
{ value: "B73X",	text: "ONCOCERCOSIS"},
{ value: "B740",	text: "FILARIASIS DEBIDA A WUCHERERIA BANCROFTI"},
{ value: "B741",	text: "FILARIASIS DEBIDA A BRUGIA MALAYI"},
{ value: "B742",	text: "FILARIASIS DEBIDA A BRUGIA TIMORI"},
{ value: "B743",	text: "LOAIASIS"},
{ value: "B744",	text: "MANSONELIASIS"},
{ value: "B748",	text: "OTRAS FILARIASIS"},
{ value: "B749",	text: "FILARIASIS, NO ESPECIFICADA"},
{ value: "B75X",	text: "TRIQUINOSIS"},
{ value: "B760",	text: "ANQUILOSTOMIASIS"},
{ value: "B761",	text: "NECATORIASIS"},
{ value: "B768",	text: "OTRAS ENFERMEDADES DEBIDAS A ANQUILOSTOMAS"},
{ value: "B769",	text: "ENFERMEDAD DEBIDA A ANQUILOSTOMAS, NO ESPECIFICADA"},
{ value: "B770",	text: "ASCARIASIS CON COMPLICACIONES INTESTINALES"},
{ value: "B778",	text: "ASCARIASIS CON OTRAS COMPLICACIONES"},
{ value: "B779",	text: "ASCARIASIS, NO ESPECIFICADA"},
{ value: "B780",	text: "ESTRONGILOIDIASIS INTESTINAL"},
{ value: "B781",	text: "ESTRONGILOIDIASIS CUTANEA"},
{ value: "B787",	text: "ESTRONGILOIDIASIS DISEMINADA"},
{ value: "B789",	text: "ESTRONGILOIDIASIS, NO ESPECIFICADA"},
{ value: "B79X",	text: "TRICURIASIS"},
{ value: "B80X",	text: "ENTEROBIASIS"},
{ value: "B810",	text: "ANISAQUIASIS"},
{ value: "B811",	text: "CAPILARIASIS INTESTINAL"},
{ value: "B812",	text: "TRICOESTRONGILIASIS"},
{ value: "B813",	text: "ANGIOESTRONGILIASIS INTESTINAL"},
{ value: "B814",	text: "HELMINTIASIS INTESTINAL MIXTA"},
{ value: "B818",	text: "OTRAS HELMINTIASIS INTESTINALES ESPECIFICADAS"},
{ value: "B820",	text: "HELMINTIASIS INTESTINAL, SIN OTRA ESPECIFICACION"},
{ value: "B829",	text: "PARASITOSIS INTESTINAL, SIN OTRA ESPECIFICACION"},
{ value: "B830",	text: "LARVA MIGRANS VISCERAL"},
{ value: "B831",	text: "GNATOSTOMIASIS"},
{ value: "B832",	text: "ANGIOESTRONGILIASIS DEBIDA A PARASTRONGYLUS CANTONENSIS"},
{ value: "B833",	text: "SINGAMIASIS"},
{ value: "B834",	text: "HIRUDINIASIS INTERNA"},
{ value: "B838",	text: "OTRAS HELMINTIASIS ESPECIFICADAS"},
{ value: "B839",	text: "HELMINTIASIS, NO ESPECIFICADA"},
{ value: "B850",	text: "PEDICULOSIS DEBIDA A PEDICULUS HUMANUS CAPITIS"},
{ value: "B851",	text: "PEDICULOSIS DEBIDA A PEDICULUS HUMANUS CORPORIS"},
{ value: "B852",	text: "PEDICULOSIS, SIN OTRA ESPECIFICACION"},
{ value: "B853",	text: "PHTHIRIASIS"},
{ value: "B854",	text: "PEDICULOSIS Y PHTHIRIASIS MIXTAS"},
{ value: "B86X",	text: "ESCABIOSIS"},
{ value: "B870",	text: "MIASIS CUTANEA"},
{ value: "B871",	text: "MIASIS EN HERIDAS"},
{ value: "B872",	text: "MIASIS OCULAR"},
{ value: "B873",	text: "MIASIS NASOFARINGEA"},
{ value: "B874",	text: "MIASIS AURAL"},
{ value: "B878",	text: "MIASIS DE OTROS SITIOS"},
{ value: "B879",	text: "MIASIS, NO ESPECIFICADA"},
{ value: "B880",	text: "OTRAS ACARIASIS"},
{ value: "B881",	text: "TUNGIASIS [INFECCION DEBIDA A PULGA DE ARENA]"},
{ value: "B882",	text: "OTRAS INFESTACIONES DEBIDAS A ARTROPODOS"},
{ value: "B883",	text: "HIRUDINIASIS EXTERNA"},
{ value: "B888",	text: "OTRAS INFESTACIONES ESPECIFICAS"},
{ value: "B889",	text: "INFESTACION, NO ESPECIFICADA"},
{ value: "B89X",	text: "ENFERMEDAD PARASITARIA, NO ESPECIFICADA"},
{ value: "B900",	text: "SECUELAS DE TUBERCULOSIS DEL SISTEMA NERVIOSO CENTRAL"},
{ value: "B901",	text: "SECUELAS DE TUBERCULOSIS GENITOURINARIA"},
{ value: "B902",	text: "SECUELAS DE TUBERCULOSIS DE HUESOS Y ARTICULACIONES"},
{ value: "B908",	text: "SECUELAS DE TUBERCULOSIS DE OTROS ORGANOS ESPECIFICADOS"},
{ value: "B909",	text: "SECUELAS DE TUBERCULOSIS RESPIRATORIA Y DE TUBERCULOSIS NO ESPECIFICADA"},
{ value: "B91X",	text: "SECUELAS DE POLIOMIELITIS"},
{ value: "B92X",	text: "SECUELAS DE LEPRA"},
{ value: "B940",	text: "SECUELAS DE TRACOMA"},
{ value: "B941",	text: "SECUELAS DE ENCEFALITIS VIRAL"},
{ value: "B942",	text: "SECUELAS DE HEPATITIS VIRAL"},
{ value: "B948",	text: "SECUELAS DE OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS ESPECIFICADAS"},
{ value: "B949",	text: "SECUELAS DE ENFERMEDADES INFECCIOSAS Y PARASITARIAS NO ESPECIFICADAS"},
{ value: "B950",	text: "ESTREPTOCOCO, GRUPO A, COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B951",	text: "ESTREPTOCOCO, GRUPO B, COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B952",	text: "ESTREPTOCOCO, GRUPO D, COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B953",	text: "STREPTOCOCCUS PNEUMONIAE COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B954",	text: "OTROS STREPTOCOCOS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B955",	text: "ESTREPTOCOCO NO ESPECIFICADO COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B956",	text: "STAPHYLOCOCCUS AUREUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B957",	text: "OTROS ESTAFILOCOCOS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B958",	text: "ESTAFILOCOCO NO ESPECIFICADO, COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B960",	text: "MYCOPLASMA PNEUMONIAE [M. PNEUMONIAE] COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B961",	text: "KLEBSIELLA PNEUMONIAE [K. PNEUMONIAE] COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B962",	text: "ESCHERICHIA COLI [E. COLI] COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B963",	text: "HAEMOPHILUS INFLUENZAE [H. INFLUENZAE] COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B964",	text: "PROTEUS (MIRABILIS) (MORGANII) COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B965",	text: "PSEUDOMONAS (AERUGINOSA) (MALLEI) (PSEUDOMALLEI) COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B966",	text: "BACILLUS FRAGILIS [B. FRAGILIS] COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B967",	text: "CLOSTRIDIUM PERFRINGENS [C. PERFRINGENS] COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B968",	text: "OTROS AGENTES BACTERIANOS ESPECIFICADOS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B970",	text: "ADENOVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B971",	text: "ENTEROVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B972",	text: "CORONAVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B973",	text: "RETROVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B974",	text: "VIRUS SINCICIAL RESPIRATORIO COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B975",	text: "REOVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B976",	text: "PARVOVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B977",	text: "PAPILOMAVIRUS COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B978",	text: "OTROS AGENTES VIRALES COMO CAUSA DE ENFERMEDADES CLASIFICADAS EN OTROS CAPITULOS"},
{ value: "B99X",	text: "OTRAS ENFERMEDADES INFECCIOSAS Y LAS NO ESPECIFICADAS"},
{ value: "C000",	text: "TUMOR MALIGNO DEL LABIO SUPERIOR, CARA EXTERNA"},
{ value: "C001",	text: "TUMOR MALIGNO DEL LABIO INFERIOR, CARA EXTERNA"},
{ value: "C002",	text: "TUMOR MALIGNO DEL LABIO, CARA EXTERNA, SIN OTRA ESPECIFICACIÓN"},
{ value: "C003",	text: "TUMOR MALIGNO DEL LABIO SUPERIOR, CARA INTERNA"},
{ value: "C004",	text: "TUMOR MALIGNO DEL LABIO INFERIOR, CARA INTERNA"},
{ value: "C005",	text: "TUMOR MALIGNO DEL LABIO, CARA INTERNA, SIN OTRA ESPECIFICACION"},
{ value: "C006",	text: "TUMOR MALIGNO DE LA COMISURA LABIAL"},
{ value: "C008",	text: "LESION DE SITIOS CONTIGUOS DEL LABIO"},
{ value: "C009",	text: "TUMOR MALIGNO DEL LABIO, PARTE NO ESPECIFICADA"},
{ value: "C01X",	text: "TUMOR MALIGNO DE LA BASE DE LA LENGUA"},
{ value: "C020",	text: "TUMOR MALIGNO DE LA CARA DORSAL DE LA LENGUA"},
{ value: "C021",	text: "TUMOR MALIGNO DEL BORDE DE LA LENGUA"},
{ value: "C022",	text: "TUMOR MALIGNO DE LA CARA VENTRAL DE LA LENGUA"},
{ value: "C023",	text: "TUMOR MALIGNO DE LOS DOS TERCIOS ANTERIORES DE LA LENGUA, PARTE NO ESPECIFICADA"},
{ value: "C024",	text: "TUMOR MALIGNO DE LA AMIGDALA LINGUAL"},
{ value: "C028",	text: "LESION DE SITIOS CONTIGUOS DE LA LENGUA"},
{ value: "C029",	text: "TUMOR MALIGNO DE LA LENGUA, PARTE NO ESPECIFICADA"},
{ value: "C030",	text: "TUMOR MALIGNO DE LA ENCIA SUPERIOR"},
{ value: "C031",	text: "TUMOR MALIGNO DE LA ENCIA INFERIOR"},
{ value: "C039",	text: "TUMOR MALIGNO DE LA ENCIA, PARTE NO ESPECIFICADA"},
{ value: "C040",	text: "TUMOR MALIGNO DE LA PARTE ANTERIOR DEL PISO DE LA BOCA"},
{ value: "C041",	text: "TUMOR MALIGNO DE LA PARTE LATERAL DEL PISO DE LA BOCA"},
{ value: "C048",	text: "LESION DE SITIOS CONTIGUOS DEL PISO DE LA BOCA"},
{ value: "C049",	text: "TUMOR MALIGNO DEL PISO DE LA BOCA, PARTE NO ESPECIFICADA"},
{ value: "C050",	text: "TUMOR MALIGNO DEL PALADAR DURO"},
{ value: "C051",	text: "TUMOR MALIGNO DEL PALADAR BLANDO"},
{ value: "C052",	text: "TUMOR MALIGNO DE LA UVULA"},
{ value: "C058",	text: "LESION DE SITIOS CONTIGUOS DEL PALADAR"},
{ value: "C059",	text: "TUMOR MALIGNO DEL PALADAR, PARTE NO ESPECIFICADA"},
{ value: "C060",	text: "TUMOR MALIGNO DE LA MUCOSA DE LA MEJILLA"},
{ value: "C061",	text: "TUMOR MALIGNO DEL VESTIBULO DE LA BOCA"},
{ value: "C062",	text: "TUMOR MALIGNO DEL AREA RETROMOLAR"},
{ value: "C068",	text: "LESION DE SITIOS CONTIGUOS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA BOCA"},
{ value: "C069",	text: "TUMOR MALIGNO DE LA BOCA, PARTE NO ESPECIFICADA"},
{ value: "C07X",	text: "TUMOR MALIGNO DE LA GLANDULA PAROTIDA"},
{ value: "C080",	text: "TUMOR MALIGNO DE LA GLANDULA SUBMAXILAR"},
{ value: "C081",	text: "TUMOR MALIGNO DE LA GLANDULA SUBLINGUAL"},
{ value: "C088",	text: "LESION DE SITIOS CONTIGUOS DE LAS GLANDULAS SALIVALES MAYORES"},
{ value: "C089",	text: "TUMOR MALIGNO DE GLANDULA SALIVAL MAYOR, NO ESPECIFICADA"},
{ value: "C090",	text: "TUMOR MALIGNO DE LA FOSA AMIGDALINA"},
{ value: "C091",	text: "TUMOR MALIGNO DEL PILAR AMIGDALINO (ANTERIOR) (POSTERIOR)"},
{ value: "C098",	text: "LESION DE SITIOS CONTIGUOS DE LA AMIGDALA"},
{ value: "C099",	text: "TUMOR MALIGNO DE LA AMIGDALA, PARTE NO ESPECIFICADA"},
{ value: "C100",	text: "TUMOR MALIGNO DE LA VALECULA"},
{ value: "C101",	text: "TUMOR MALIGNO DE LA CARA ANTERIOR DE LA EPIGLOTIS"},
{ value: "C102",	text: "TUMOR MALIGNO DE LA PARED LATERAL DE LA OROFARINGE"},
{ value: "C103",	text: "TUMOR MALIGNO DE LA PARED POSTERIOR DE LA OROFARINGE"},
{ value: "C104",	text: "TUMOR MALIGNO DE LA HENDIDURA BRANQUIAL"},
{ value: "C108",	text: "LESION DE SITIOS CONTIGUOS DE LA OROFARINGE"},
{ value: "C109",	text: "TUMOR MALIGNO DE LA OROFARINGE, PARTE NO ESPECIFICADA"},
{ value: "C110",	text: "TUMOR MALIGNO DE LA PARED SUPERIOR DE LA NASOFARINGE"},
{ value: "C111",	text: "TUMOR MALIGNO DE LA PARED POSTERIOR DE LA NASOFARINGE"},
{ value: "C112",	text: "TUMOR MALIGNO DE LA PARED LATERAL DE LA NASOFARINGE"},
{ value: "C113",	text: "TUMOR MALIGNO DE LA PARED ANTERIOR DE LA NASOFARINGE"},
{ value: "C118",	text: "LESION DE SITIOS CONTIGUOS DE LA NASOFARINGE"},
{ value: "C119",	text: "TUMOR MALIGNO DE LA NASOFARINGE, PARTE NO ESPECIFICADA"},
{ value: "C12X",	text: "TUMOR MALIGNO DEL SENO PIRIFORME"},
{ value: "C130",	text: "TUMOR MALIGNO DE LA REGION POSTCRICOIDEA"},
{ value: "C131",	text: "TUMOR MALIGNO DEL PLIEGUE ARITENOEPIGLOTICO, CARA HIPOFARINGEA"},
{ value: "C132",	text: "TUMOR MALIGNO DE LA PARED POSTERIOR DE LA HIPOFARINGE"},
{ value: "C138",	text: "LESION DE SITIOS CONTIGUOS DE LA HIPOFARINGE"},
{ value: "C139",	text: "TUMOR MALIGNO DE LA HIPOFARINGE, PARTE NO ESPECIFICADA"},
{ value: "C140",	text: "TUMOR MALIGNO DE LA FARINGE, PARTE NO ESPECIFICADA"},
{ value: "C142",	text: "TUMOR MALIGNO DEL ANILLO DE WALDEYER"},
{ value: "C148",	text: "LESION DE SITIOS CONTIGUOS DEL LABIO, DE LA CAVIDAD BUCAL Y DE LA LARINGE"},
{ value: "C150",	text: "TUMOR MALIGNO DEL ESOFAGO, PORCION CERVICAL"},
{ value: "C151",	text: "TUMOR MALIGNO DEL ESOFAGO, PORCION TORACICA"},
{ value: "C152",	text: "TUMOR MALIGNO DEL ESOFAGO, PORCION ABDOMINAL"},
{ value: "C153",	text: "TUMOR MALIGNO DEL TERCIO SUPERIOR DEL ESOFAGO"},
{ value: "C154",	text: "TUMOR MALIGNO DEL TERCIO MEDIO DEL ESOFAGO"},
{ value: "C155",	text: "TUMOR MALIGNO DEL TERCIO INFERIOR DEL ESOFAGO"},
{ value: "C158",	text: "LESION DE SITIOS CONTIGUOS DEL ESOFAGO"},
{ value: "C159",	text: "TUMOR MALIGNO DEL ESOFAGO, PARTE NO ESPECIFICADA"},
{ value: "C160",	text: "TUMOR MALIGNO DEL CARDIAS"},
{ value: "C161",	text: "TUMOR MALIGNO DEL FUNDUS GASTRICO"},
{ value: "C162",	text: "TUMOR MALIGNO DEL CUERPO DEL ESTOMAGO"},
{ value: "C163",	text: "TUMOR MALIGNO DEL ANTRO PILORICO"},
{ value: "C164",	text: "TUMOR MALIGNO DEL PILORO"},
{ value: "C165",	text: "TUMOR MALIGNO DE LA CURVATURA MENOR DEL ESTOMAGO, SIN OTRA ESPECIFICACION"},
{ value: "C166",	text: "TUMOR MALIGNO DE LA CURVATURA MAYOR DEL ESTOMAGO, SIN OTRA ESPECIFICACION"},
{ value: "C168",	text: "LESION DE SITIOS CONTIGUOS DEL ESTOMAGO"},
{ value: "C169",	text: "TUMOR MALIGNO DEL ESTOMAGO, PARTE NO ESPECIFICADA"},
{ value: "C170",	text: "TUMOR MALIGNO DEL DUODENO"},
{ value: "C171",	text: "TUMOR MALIGNO DEL YEYUNO"},
{ value: "C172",	text: "TUMOR MALIGNO DEL ILEON"},
{ value: "C173",	text: "TUMOR MALIGNO DEL DIVERTICULO DE MECKEL"},
{ value: "C178",	text: "TUMOR MALIGNO DEL LESION DE SITIOS CONTIGUOS DEL INTESTINO DELGADO"},
{ value: "C179",	text: "TUMOR MALIGNO DEL INTESTINO DELGADO, PARTE NO ESPECIFICADA"},
{ value: "C180",	text: "TUMOR MALIGNO DEL CIEGO"},
{ value: "C181",	text: "TUMOR MALIGNO DEL APENDICE"},
{ value: "C182",	text: "TUMOR MALIGNO DEL COLON ASCENDENTE"},
{ value: "C183",	text: "TUMOR MALIGNO DEL ANGULO HEPATICO"},
{ value: "C184",	text: "TUMOR MALIGNO DEL COLON TRANSVERSO"},
{ value: "C185",	text: "TUMOR MALIGNO DEL ANGULO ESPLENICO"},
{ value: "C186",	text: "TUMOR MALIGNO DEL COLON DESCENDENTE"},
{ value: "C187",	text: "TUMOR MALIGNO DEL COLON SIGMOIDE"},
{ value: "C188",	text: "LESION DE SITIOS CONTIGUOS DEL COLON"},
{ value: "C189",	text: "TUMOR MALIGNO DEL COLON, PARTE NO ESPECIFICADA"},
{ value: "C19X",	text: "TUMOR MALIGNO DE LA UNION RECTOSIGMOIDEA"},
{ value: "C20X",	text: "TUMOR MALIGNO DEL RECTO"},
{ value: "C210",	text: "TUMOR MALIGNO DEL ANO, PARTE NO ESPECIFICADA"},
{ value: "C211",	text: "TUMOR MALIGNO DEL CONDUCTO ANAL"},
{ value: "C212",	text: "TUMOR MALIGNO DE LA ZONA CLOACOGENICA"},
{ value: "C218",	text: "LESION DE SITIOS CONTIGUOS DEL ANO, DEL CONDUCTO ANAL Y DEL RECTO"},
{ value: "C220",	text: "CARCINOMA DE CELULAS HEPATICAS"},
{ value: "C221",	text: "CARCINOMA DE VIAS BILIARES INTRAHEPATICAS"},
{ value: "C222",	text: "HEPATOBLASTOMA"},
{ value: "C223",	text: "ANGIOSARCOMA DEL HIGADO"},
{ value: "C224",	text: "OTROS SARCOMAS DEL HIGADO"},
{ value: "C227",	text: "OTROS CARCINOMAS ESPECIFICADOS DEL HIGADO"},
{ value: "C229",	text: "TUMOR MALIGNO DEL HIGADO, NO ESPECIFICADO"},
{ value: "C23X",	text: "TUMOR MALIGNO DE LA VESICULA BILIAR"},
{ value: "C240",	text: "TUMOR MALIGNO D E LAS VIAS BILIARES EXTRAHEPATICAS"},
{ value: "C241",	text: "TUMOR MALIGNO DE LA AMPOLLA DE VATER"},
{ value: "C248",	text: "LESION DE SITIOS CONTIGUOS DE LAS VIAS BILIARES"},
{ value: "C249",	text: "TUMOR MALIGNO DE LAS VIAS BILIARES, PARTE NO ESPECIFICADA"},
{ value: "C250",	text: "TUMOR MALIGNO DE LA CABEZA DEL PANCREAS"},
{ value: "C251",	text: "TUMOR MALIGNO DEL CUERPO DEL PANCREAS"},
{ value: "C252",	text: "TUMOR MALIGNO DE LA COLA DEL PANCREAS"},
{ value: "C253",	text: "TUMOR MALIGNO DEL CONDUCTO PANCREATICO"},
{ value: "C254",	text: "TUMOR MALIGNO DEL PANCREAS ENDOCRINO"},
{ value: "C257",	text: "TUMOR MALIGNO DE OTRAS PARTES ESPECIFICADAS DEL PANCREAS"},
{ value: "C258",	text: "LESION DE SITIOS CONTIGUOS DEL PANCREAS"},
{ value: "C259",	text: "TUMOR MALIGNO DEL PANCREAS, PARTE NO ESPECIFICADA"},
{ value: "C260",	text: "TUMOR MALIGNO DEL INTESTINO, PARTE NO ESPECIFICADA"},
{ value: "C261",	text: "TUMOR MALIGNO DEL BAZO"},
{ value: "C268",	text: "LESION DE SITIOS CONTIGUOS DE LOS ORGANOS DIGESTIVOS"},
{ value: "C269",	text: "TUMOR MALIGNO DE SITIOS MAL DEFINIDOS DE LOS ORGANOS DIGESTIVOS"},
{ value: "C300",	text: "TUMOR MALIGNO DE LA FOSA NASAL"},
{ value: "C301",	text: "TUMOR MALIGNO DEL OIDO MEDIO"},
{ value: "C310",	text: "TUMOR MALIGNO DEL SENO MAXILAR"},
{ value: "C311",	text: "TUMOR MALIGNO DEL SENO ETMOIDAL"},
{ value: "C312",	text: "TUMOR MALIGNO DEL SENO FRONTAL"},
{ value: "C313",	text: "TUMOR MALIGNO DEL SENO ESFENOIDAL"},
{ value: "C318",	text: "LESION DE SITIOS CONTIGUOS DE LOS SENOS PARANASALES"},
{ value: "C319",	text: "TUMOR MALIGNO DEL SENO PARANASAL NO ESPECIFICADO"},
{ value: "C320",	text: "TUMOR MALIGNO DE LA GLOTIS"},
{ value: "C321",	text: "TUMOR MALIGNO DE LA REGION SUPRAGLOTICA"},
{ value: "C322",	text: "TUMOR MALIGNO DE LA REGION SUBGLOTICA"},
{ value: "C323",	text: "TUMOR MALIGNO DEL CARTILAGO LARINGEO"},
{ value: "C328",	text: "LESION DE SITIOS CONTIGUOS DE LA LARINGE"},
{ value: "C329",	text: "TUMOR MALIGNO DE LA LARINGE, PARTE NO ESPECIFICADA"},
{ value: "C33X",	text: "TUMOR MALIGNO DE LA TRAQUEA"},
{ value: "C340",	text: "TUMOR MALIGNO DEL BRONQUIO PRINCIPAL"},
{ value: "C341",	text: "TUMOR MALIGNO DEL LOBULO SUPERIOR, BRONQUIO O PULMON"},
{ value: "C342",	text: "TUMOR MALIGNO DEL LOBULO MEDIO, BRONQUIO O PULMON"},
{ value: "C343",	text: "TUMOR MALIGNO DEL LOBULO INFERIOR, BRONQUIO O PULMON"},
{ value: "C348",	text: "LESION DE SITIOS CONTIGUOS DE LOS BRONQUIOS Y DEL PULMON"},
{ value: "C349",	text: "TUMOR MALIGNO DE LOS BRONQUIOS O DEL PULMON, PARTE NO ESPECIFICADA"},
{ value: "C37X",	text: "TUMOR MALIGNO DEL TIMO"},
{ value: "C380",	text: "TUMOR MALIGNO DEL CORAZON"},
{ value: "C381",	text: "TUMOR MALIGNO DEL MEDIASTINO ANTERIOR"},
{ value: "C382",	text: "TUMOR MALIGNO DEL MEDIASTINO POSTERIOR"},
{ value: "C383",	text: "TUMOR MALIGNO DEL MEDIASTINO, PARTE NO ESPECIFICADA"},
{ value: "C384",	text: "TUMOR MALIGNO DE LA PLEURA"},
{ value: "C388",	text: "LESION DE SITIOS CONTIGUOS DEL CORAZON, DEL MEDIASTINO Y DE LA PLEURA"},
{ value: "C390",	text: "TUMOR MALIGNO DE LAS VIAS RESPIRATORIAS SUPERIORES, PARTE NO ESPECIFICADA"},
{ value: "C398",	text: "LESION DE SITIOS CONTIGUOS DE LOS ORGANOS RESPIRATORIOS E INTRATORACICOS"},
{ value: "C399",	text: "TUMOR MALIGNO DE SITIOS MAL DEFINIDOS DEL SISTEMA RESPIRATORIO"},
{ value: "C400",	text: "TUMOR MALIGNO DEL OMOPLATO Y DE LOS HUESOS LARGOS DEL MIEMBRO SUPERIOR"},
{ value: "C401",	text: "TUMOR MALIGNO DE LOS HUESOS CORTOS DEL MIEMBRO SUPERIOR"},
{ value: "C402",	text: "TUMOR MALIGNO DE LOS HUESOS LARGOS DEL MIEMBRO INFERIOR"},
{ value: "C403",	text: "TUMOR MALIGNO DE LOS HUESOS CORTOS DEL MIEMBRO INFERIOR"},
{ value: "C408",	text: "LESION DE SITIOS CONTIGUOS DE LOS HUESOS Y DE LOS CARTILAGOS ARTICULARES DE LOS MIEMBROS"},
{ value: "C409",	text: "TUMOR MALIGNO DE LOS HUESOS Y DE LOS CARTILAGOS ARTICULARES DE LOS MIEMBROS, SIN OTRA ESPECIFICACION"},
{ value: "C410",	text: "TUMOR MALIGNO DE LOS HUESOS DEL CRANEO Y DE LA CARA"},
{ value: "C411",	text: "TUMOR MALIGNO DEL HUESO DEL MAXILAR INFERIOR"},
{ value: "C412",	text: "TUMOR MALIGNO DE LA COLUMNA VERTEBRAL"},
{ value: "C413",	text: "TUMOR MALIGNO DE LA COSTILLA, ESTERNON Y CLAVICULA"},
{ value: "C414",	text: "TUMOR MALIGNO DE LOS HUESOS DE LA PELVIS, SACRO Y COCCIX"},
{ value: "C418",	text: "LESION DE SITIOS CONTIGUOS DEL HUESO Y DEL CARTILAGO ARTICULAR"},
{ value: "C419",	text: "TUMOR MALIGNO DE HUESO Y DEL CARTILAGO ARTICULAR, NO ESPECIFICADO"},
{ value: "C430",	text: "MELANOMA MALIGNO DEL LABIO"},
{ value: "C431",	text: "MELANOMA MALIGNO DEL PARPADO, INCLUIDA LA COMISURA PALPEBRAL"},
{ value: "C432",	text: "MELANOMA MALIGNO DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO"},
{ value: "C433",	text: "MELANOMA MALIGNO DE LAS OTRAS PARTES Y LAS NO ESPECIFICADAS DE LA CARA"},
{ value: "C434",	text: "MELANOMA MALIGNO DEL CUERO CABELLUDO Y DEL CUELLO"},
{ value: "C435",	text: "MELANOMA MALIGNO DEL TRONCO"},
{ value: "C436",	text: "MELANOMA MALIGNO DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"},
{ value: "C437",	text: "MELANOMA MALIGNO DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"},
{ value: "C438",	text: "MELANOMA MALIGNO DE SITIOS CONTIGUOS DE LA PIEL"},
{ value: "C439",	text: "MELANOMA MALIGNO DE PIEL, SITIO NO ESPECIFICADO"},
{ value: "C440",	text: "TUMOR MALIGNO DE LA PIEL DEL LABIO"},
{ value: "C441",	text: "TUMOR MALIGNO DE LA PIEL DEL PARPADO, INCLUIDA LA COMISURA PALPEBRAL"},
{ value: "C442",	text: "TUMOR MALIGNO DE LA PIEL DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO"},
{ value: "C443",	text: "TUMOR MALIGNO DE LA PIEL DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CARA"},
{ value: "C444",	text: "TUMOR MALIGNO DE LA PIEL DEL CUERO CABELLUDO Y DEL CUELLO"},
{ value: "C445",	text: "TUMOR MALIGNO DE LA PIEL DEL TRONCO"},
{ value: "C446",	text: "TUMOR MALIGNO DE LA PIEL DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"},
{ value: "C447",	text: "TUMOR MALIGNO DE LA PIEL DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"},
{ value: "C448",	text: "LESION DE SITIOS CONTIGUOS DE LA PIEL"},
{ value: "C449",	text: "TUMOR MALIGNO DE LA PIEL, SITIO NO ESPECIFICADO"},
{ value: "C450",	text: "MESOTELIOMA DE LA PLEURA"},
{ value: "C451",	text: "MESOTELIOMA DEL PERITONEO"},
{ value: "C452",	text: "MESOTELIOMA DEL PERICARDIO"},
{ value: "C457",	text: "MESOTELIOMA DE OTROS SITIOS ESPECIFICADOS"},
{ value: "C459",	text: "MESOTELIOMA, DE SITIO NO ESPECIFICADO"},
{ value: "C460",	text: "SARCOMA DE KAPOSI DE LA PIEL"},
{ value: "C461",	text: "SARCOMA DE KAPOSI DEL TEJIDO BLANDO"},
{ value: "C462",	text: "SARCOMA DE KAPOSI DEL PALADAR"},
{ value: "C463",	text: "SARCOMA DE KAPOSI DE LOS GANGLIOS LINFATICOS"},
{ value: "C467",	text: "SARCOMA DE KAPOSI DE OTROS SITIOS ESPECIFICADOS"},
{ value: "C468",	text: "SARCOMA DE KAPOSI DE MULTIPLES ORGANOS"},
{ value: "C469",	text: "SARCOMA DE KAPOSI, DE SITIO NO ESPECIFICADO"},
{ value: "C470",	text: "TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS DE LA CABEZA, CARA Y CUELLO"},
{ value: "C471",	text: "TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"},
{ value: "C472",	text: "TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"},
{ value: "C473",	text: "TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS DEL TORAX"},
{ value: "C474",	text: "TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS DEL ABDOMEN"},
{ value: "C475",	text: "TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS DE LA PELVIS"},
{ value: "C476",	text: "TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS DEL TRONCO, SIN OTRA ESPECIFICACION"},
{ value: "C478",	text: "LESION DE SITIOS CONTIGUOS DE LOS NERVIOS PERIFERICOS Y DEL SISTEMA NERVIOSO AUTONOMO"},
{ value: "C479",	text: "TUMOR MALIGNO DE LOS NERVIOS PERIFERICOS Y DEL SISTEMA NERVIOSO AUTONOMO, PARTE NO ESPECIFICADA"},
{ value: "C480",	text: "TUMOR MALIGNO DEL RETROPERITONEO"},
{ value: "C481",	text: "TUMOR MALIGNO DE PARTE ESPECIFICADA DEL PERITONEO"},
{ value: "C482",	text: "TUMOR MALIGNO DEL PERITONEO, SIN OTRA ESPECIFICACION"},
{ value: "C488",	text: "LESION DE SITIOS CONTIGUOS DEL PERITONEO Y DEL RETROPERITONEO"},
{ value: "C490",	text: "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DE LA CABEZA, CARA Y CUELLO"},
{ value: "C491",	text: "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"},
{ value: "C492",	text: "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"},
{ value: "C493",	text: "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DEL TORAX"},
{ value: "C494",	text: "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DEL ABDOMEN"},
{ value: "C495",	text: "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DE LA PELVIS"},
{ value: "C496",	text: "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO DEL TRONCO, SIN OTRA ESPECIFICACION"},
{ value: "C498",	text: "LESION DE SITIOS CONTIGUOS DEL TEJIDO CONJUNTIVO Y DEL TEJIDO DEL BLANDO"},
{ value: "C499",	text: "TUMOR MALIGNO DEL TEJIDO CONJUNTIVO Y TEJIDO BLANDO, DE SITIO NO ESPECIFICADO"},
{ value: "C500",	text: "TUMOR MALIGNO DEL PEZON Y AREOLA MAMARIA"},
{ value: "C501",	text: "TUMOR MALIGNO DE LA PORCION CENTRAL DE LA MAMA"},
{ value: "C502",	text: "TUMOR MALIGNO DEL CUADRANTE SUPERIOR INTERNO DE LA MAMA"},
{ value: "C503",	text: "TUMOR MALIGNO DEL CUADRANTE INFERIOR INTERNO DE LA MAMA"},
{ value: "C504",	text: "TUMOR MALIGNO DEL CUADRANTE SUPERIOR EXTERNO DE LA MAMA"},
{ value: "C505",	text: "TUMOR MALIGNO DEL CUADRANTE INFERIOR EXTERNO DE LA MAMA"},
{ value: "C506",	text: "TUMOR MALIGNO DE LA PROLONGACION AXILAR DE LA MAMA"},
{ value: "C508",	text: "LESION DE SITIOS CONTIGUOS DE LA MAMA"},
{ value: "C509",	text: "TUMOR MALIGNO DE LA MAMA, PARTE NO ESPECIFICADA"},
{ value: "C510",	text: "TUMOR MALIGNO DEL LABIO MAYOR"},
{ value: "C511",	text: "TUMOR MALIGNO DEL LABIO MENOR"},
{ value: "C512",	text: "TUMOR MALIGNO DEL CLITORIS"},
{ value: "C518",	text: "LESION DE SITIOS CONTIGUOS DE LA VULVA"},
{ value: "C519",	text: "TUMOR MALIGNO DE LA VULVA, PARTE NO ESPECIFICADA"},
{ value: "C52X",	text: "TUMOR MALIGNO DE LA VAGINA"},
{ value: "C530",	text: "TUMOR MALIGNO DEL ENDOCERVIX"},
{ value: "C531",	text: "TUMOR MALIGNO DE EXOCERVIX"},
{ value: "C538",	text: "LESION DE SITIOS CONTIGUOS DEL CUELLO DEL UTERO"},
{ value: "C539",	text: "TUMOR MALIGNO DEL CUELLO DEL UTERO, SIN OTRA ESPECIFICACION"},
{ value: "C540",	text: "TUMOR MALIGNO DEL ISTMO UTERINO"},
{ value: "C541",	text: "TUMOR MALIGNO DEL ENDOMETRIO"},
{ value: "C542",	text: "TUMOR MALIGNO DEL MIOMETRIO"},
{ value: "C543",	text: "TUMOR MALIGNO DEL FONDO DEL UTERO"},
{ value: "C548",	text: "LESION DE SITIOS CONTIGUOS DEL CUERPO DEL UTERO"},
{ value: "C549",	text: "TUMOR MALIGNO DEL CUERPO DEL UTERO, PARTE NO ESPECIFICADA"},
{ value: "C55X",	text: "TUMOR MALIGNO DEL UTERO, PARTE NO ESPECIFICADA"},
{ value: "C56X",	text: "TUMOR MALIGNO DEL OVARIO"},
{ value: "C570",	text: "TUMOR MALIGNO DE LA TROMPA DE FALOPIO"},
{ value: "C571",	text: "TUMOR MALIGNO DEL LIGAMENTO ANCHO"},
{ value: "C572",	text: "TUMOR MALIGNO DEL LIGAMENTO REDONDO"},
{ value: "C573",	text: "TUMOR MALIGNO DEL PARAMETRIO"},
{ value: "C574",	text: "TUMOR MALIGNO DE LOS ANEXOS UTERINOS, SIN OTRA ESPECIFICACIÓN"},
{ value: "C577",	text: "TUMOR MALIGNO DE OTRAS PARTES ESPECIFICADAS DE LOS ORGANOS GENITALES FEMENINOS"},
{ value: "C578",	text: "LESION DE SITIOS CONTIGUOS DE LOS ORGANOS GENITALES FEMENINOS"},
{ value: "C579",	text: "TUMOR MALIGNO DE ORGANO GENITAL FEMENINO, PARTE NO ESPECIFICADA"},
{ value: "C58X",	text: "TUMOR MALIGNO DE LA PLACENTA"},
{ value: "C600",	text: "TUMOR MALIGNO DEL PREPUCIO"},
{ value: "C601",	text: "TUMOR MALIGNO DEL GLANDE"},
{ value: "C602",	text: "TUMOR MALIGNO DEL CUERPO DEL PENE"},
{ value: "C608",	text: "LESION DE SITIOS CONTIGUOS DEL PENE"},
{ value: "C609",	text: "TUMOR MALIGNO DEL PENE, PARTE NO ESPECIFICADA"},
{ value: "C61X",	text: "TUMOR MALIGNO DE LA PROSTATA"},
{ value: "C620",	text: "TUMOR MALIGNO DEL TESTICULO NO DESCENDIDO"},
{ value: "C621",	text: "TUMOR MALIGNO DEL TESTICULO DESCENDIDO"},
{ value: "C629",	text: "TUMOR MALIGNO DEL TESTICULO, NO ESPECIFICADO"},
{ value: "C630",	text: "TUMOR MALIGNO DEL EPIDIDIMO"},
{ value: "C631",	text: "TUMOR MALIGNO DEL CORDON ESPERMATICO"},
{ value: "C632",	text: "TUMOR MALIGNO DEL ESCROTO"},
{ value: "C637",	text: "TUMOR MALIGNO DE OTRAS PARTES ESPECIFICADAS DE LOS ORGANOS GENITALES MASCULINOS"},
{ value: "C638",	text: "LESION DE SITIOS CONTIGUOS DE LOS ORGANOS GENITALES MASCULINOS"},
{ value: "C639",	text: "TUMOR MALIGNO DE ORGANO GENITAL MASCULINO, PARTE NO ESPECIFICADA"},
{ value: "C64X",	text: "TUMOR MALIGNO DEL RIÑON, EXCEPTO DE LA PELVIS RENAL"},
{ value: "C65X",	text: "TUMOR MALIGNO DE LA PELVIS RENAL"},
{ value: "C66X",	text: "TUMOR MALIGNO DEL URETER"},
{ value: "C670",	text: "TUMOR MALIGNO DEL TRIGONO VESICAL"},
{ value: "C671",	text: "TUMOR MALIGNO DE LA CUPULA VESICAL"},
{ value: "C672",	text: "TUMOR MALIGNO DE LA PARED LATERAL DE LA VEJIGA"},
{ value: "C673",	text: "TUMOR MALIGNO DE LA PARED ANTERIOR DE LA VEJIGA"},
{ value: "C674",	text: "TUMOR MALIGNO DE LA PARED POSTERIOR DE LA VEJIGA"},
{ value: "C675",	text: "TUMOR MALIGNO DEL CUELLO DE LA VEJIGA"},
{ value: "C676",	text: "TUMOR MALIGNO DEL ORIFICIO URETERAL"},
{ value: "C677",	text: "TUMOR MALIGNO DEL URACO"},
{ value: "C678",	text: "LESION DE SITIOS CONTIGUOS DE LA VEJIGA"},
{ value: "C679",	text: "TUMOR MALIGNO DE LA VEJIGA URINARIA, PARTE NO ESPECIFICADA"},
{ value: "C680",	text: "TUMOR MALIGNO DE LA URETRA"},
{ value: "C681",	text: "TUMOR MALIGNO DE LAS GLANDULAS PARAURETRALES"},
{ value: "C688",	text: "LESION DE SITIOS CONTIGUOS DE LOS ORGANOS URINARIOS"},
{ value: "C689",	text: "TUMOR MALIGNO DE ORGANO URINARIO NO ESPECIFICADO"},
{ value: "C690",	text: "TUMOR MALIGNO DE LA CONJUNTIVA"},
{ value: "C691",	text: "TUMOR MALIGNO DE LA CORNEA"},
{ value: "C692",	text: "TUMOR MALIGNO DE LA RETINA"},
{ value: "C693",	text: "TUMOR MALIGNO DE LA COROIDES"},
{ value: "C694",	text: "TUMOR MALIGNO DEL CUERPO CILIAR"},
{ value: "C695",	text: "TUMOR MALIGNO DE LA GLANDULA Y CONDUCTO LAGRIMALES"},
{ value: "C696",	text: "TUMOR MALIGNO DE LA ORBITA"},
{ value: "C698",	text: "LESION DE SITIOS CONTIGUOS DEL OJO Y SUS ANEXOS"},
{ value: "C699",	text: "TUMOR MALIGNO DEL OJO, PARTE NO ESPECIFICADA"},
{ value: "C700",	text: "TUMOR MALIGNO DE LAS MENINGES CEREBRALES"},
{ value: "C701",	text: "TUMOR MALIGNO DE LAS MENINGES RAQUIDEAS"},
{ value: "C709",	text: "TUMOR MALIGNO DE LAS MENINGES, PARTE NO ESPECIFICADA"},
{ value: "C710",	text: "TUMOR MALIGNO DEL CEREBRO, EXCEPTO LOBULOS Y VENTRICULOS"},
{ value: "C711",	text: "TUMOR MALIGNO DEL LOBULO FRONTAL"},
{ value: "C712",	text: "TUMOR MALIGNO DEL LOBULO TEMPORAL"},
{ value: "C713",	text: "TUMOR MALIGNO DEL LOBULO PARIETAL"},
{ value: "C714",	text: "TUMOR MALIGNO DEL LOBULO OCCIPITAL"},
{ value: "C715",	text: "TUMOR MALIGNO DEL VENTRICULO CEREBRAL"},
{ value: "C716",	text: "TUMOR MALIGNO DEL CEREBELO"},
{ value: "C717",	text: "TUMOR MALIGNO DEL PEDUNCULO CEREBRAL"},
{ value: "C718",	text: "LESION DE SITIOS CONTIGUOS DEL ENCEFALO"},
{ value: "C719",	text: "TUMOR MALIGNO DEL ENCEFALO, PARTE NO ESPECIFICADA"},
{ value: "C720",	text: "TUMOR MALIGNO DE LA MEDULA ESPINAL"},
{ value: "C721",	text: "TUMOR MALIGNO DE LA COLA DE CABALLO"},
{ value: "C722",	text: "TUMOR MALIGNO DEL NERVIO OLFATORIO"},
{ value: "C723",	text: "TUMOR MALIGNO DEL NERVIO OPTICO"},
{ value: "C724",	text: "TUMOR MALIGNO DEL NERVIO ACUSTICO"},
{ value: "C725",	text: "TUMOR MALIGNO DE OTROS NERVIOS CRANEALES Y LOS NO ESPECIFICADOS"},
{ value: "C728",	text: "LESION DE SITIOS CONTIGUOS DEL ENCEFALO Y OTRAS PARTES DEL SISTEMA NERVIOSO CENTRAL"},
{ value: "C729",	text: "TUMOR MALIGNO DEL SISTEMA NERVIOSO CENTRAL, SIN OTRA ESPECIFICACION"},
{ value: "C73X",	text: "TUMOR MALIGNO DE LA GLANDULA TIROIDES"},
{ value: "C740",	text: "TUMOR MALIGNO DE LA CORTEZA DE LA GLANDULA SUPRARRENAL"},
{ value: "C741",	text: "TUMOR MALIGNO DE LA MEDULA DE LA GLANDULA SUPRARRENAL"},
{ value: "C749",	text: "TUMOR MALIGNO DE LA GLANDULA SUPRARRENAL, PARTE NO ESPECIFICADA"},
{ value: "C750",	text: "TUMOR MALIGNO DE LA GLANDULA PARATIROIDES"},
{ value: "C751",	text: "TUMOR MALIGNO DE LA HIPOFISIS"},
{ value: "C752",	text: "TUMOR MALIGNO DEL CONDUCTO CRANEOFARINGEO"},
{ value: "C753",	text: "TUMOR MALIGNO DE LA GLANDULA PINEAL"},
{ value: "C754",	text: "TUMOR MALIGNO DEL CUERPO CAROTIDEO"},
{ value: "C755",	text: "TUMOR MALIGNO DEL CUERPO AORTICO Y OTROS CUERPOS CROMAFINES"},
{ value: "C758",	text: "TUMOR MALIGNO PLURIGLANDULAR, NO ESPECIFICADO"},
{ value: "C759",	text: "TUMOR MALIGNO DE GLANDULA ENDOCRINA NO ESPECIFICADA"},
{ value: "C760",	text: "TUMOR MALIGNO DE LA CABEZA, CARA Y CUELLO"},
{ value: "C761",	text: "TUMOR MALIGNO DEL TORAX"},
{ value: "C762",	text: "TUMOR MALIGNO DEL ABDOMEN"},
{ value: "C763",	text: "TUMOR MALIGNO DE LA PELVIS"},
{ value: "C764",	text: "TUMOR MALIGNO DEL MIEMBRO SUPERIOR"},
{ value: "C765",	text: "TUMOR MALIGNO DEL MIEMBRO INFERIOR"},
{ value: "C767",	text: "TUMOR MALIGNO DE OTROS SITIOS MAL DEFINIDOS"},
{ value: "C768",	text: "LESION DE SITIOS CONTIGUOS MAL DEFINIDOS"},
{ value: "C770",	text: "TUMOR MALIGNO DE LOS GANGLIOS LINFATICOS DE LA CABEZA, CARA Y CUELLO"},
{ value: "C771",	text: "TUMOR MALIGNO DE LOS GANGLIOS LINFATICOS INTRATORACICOS"},
{ value: "C772",	text: "TUMOR MALIGNO DE LOS GANGLIOS LINFATICOS INTRAABDOMINALES"},
{ value: "C773",	text: "TUMOR MALIGNO DE LOS GANGLIOS LINFATICOS DE LA AXILA Y DEL MIEMBRO SUPERIOR"},
{ value: "C774",	text: "TUMOR MALIGNO DE LOS GANGLIOS LINFATICOS DE LA REGION INGUINAL Y DEL MIEMBRO INFERIOR"},
{ value: "C775",	text: "TUMOR MALIGNO DE LOS GANGLIOS LINFATICOS DE LA PELVIS"},
{ value: "C778",	text: "TUMOR MALIGNO DE LOS GANGLIOS LINFATICOS DE REGIONES MULTIPLES"},
{ value: "C779",	text: "TUMOR MALIGNO DEL GANGLIO LINFATICO, SITIO NO ESPECIFICADO"},
{ value: "C780",	text: "TUMOR MALIGNO SECUNDARIO DEL PULMON"},
{ value: "C781",	text: "TUMOR MALIGNO SECUNDARIO DEL MEDIASTINO"},
{ value: "C782",	text: "TUMOR MALIGNO SECUNDARIO DE LA PLEURA"},
{ value: "C783",	text: "TUMOR MALIGNO SECUNDARIO DE OTROS ORGANOS RESPIRATORIOS Y DE LOS NO ESPECIFICADOS"},
{ value: "C784",	text: "TUMOR MALIGNO SECUNDARIO DEL INTESTINO DELGADO"},
{ value: "C785",	text: "TUMOR MALIGNO SECUNDARIO DEL INTESTINO GRUESO Y DEL RECTO"},
{ value: "C786",	text: "TUMOR MALIGNO SECUNDARIO DEL PERITONEO Y DEL RETROPERITONEO"},
{ value: "C787",	text: "TUMOR MALIGNO SECUNDARIO DEL HIGADO"},
{ value: "C788",	text: "TUMOR MALIGNO SECUNDARIO DE OTROS ORGANOS DIGESTIVOS Y DE LOS NO ESPECIFICADOS"},
{ value: "C790",	text: "TUMOR MALIGNO SECUNDARIO DEL RIÑON Y DE LA PELVIS RENAL"},
{ value: "C791",	text: "TUMOR MALIGNO SECUNDARIO DE LA VEJIGA, Y DE OTROS ORGANOS Y LOS NO ESPECIFICADOS DE LAS VIAS URINARIAS"},
{ value: "C792",	text: "TUMOR MALIGNO SECUNDARIO DE LA PIEL"},
{ value: "C793",	text: "TUMOR MALIGNO SECUNDARIO DEL ENCEFALO Y DE LAS MENINGES CEREBRALES"},
{ value: "C794",	text: "TUMOR MALIGNO SECUNDARIO DE OTRAS PARTES DEL SISTEMA NERVIOSO Y DE LAS NO ESPECIFICADAS"},
{ value: "C795",	text: "TUMOR MALIGNO SECUNDARIO DE LOS HUESOS Y DE LA MEDULA OSEA"},
{ value: "C796",	text: "TUMOR MALIGNO SECUNDARIO DEL OVARIO"},
{ value: "C797",	text: "TUMOR MALIGNO SECUNDARIO DE LA GLANDULA SUPRARRENAL"},
{ value: "C798",	text: "TUMOR MALIGNO SECUNDARIO DE OTROS SITIOS ESPECIFICADOS"},
{ value: "C80X",	text: "TUMOR MALIGNO DE SITIOS NO ESPECIFICADOS"},
{ value: "C810",	text: "ENFERMEDAD DE HODGKIN CON PREDOMINIO LINFOCITICO"},
{ value: "C811",	text: "ENFERMEDAD DE HODGKIN CON ESCLEROSIS NODULAR"},
{ value: "C812",	text: "ENFERMEDAD DE HODGKIN CON CELULARIDAD MIXTA"},
{ value: "C813",	text: "ENFERMEDAD DE HODGKIN CON DEPLECION LINFOCITICA"},
{ value: "C817",	text: "OTROS TIPOS DE ENFERMEDAD DE HODGKIN"},
{ value: "C819",	text: "ENFERMEDAD DE HODGKIN, NO ESPECIFICADA"},
{ value: "C820",	text: "LINFOMA NO HODGKIN DE CELULAS PEQUEÑAS HENDIDAS, FOLICULAR"},
{ value: "C821",	text: "LINFOMA NO HODGKIN MIXTO, DE PEQUEÑAS CELULAS HENDIDAS Y DE GRANDES CELULAS, FOLICULAR"},
{ value: "C822",	text: "LINFOMA NO HODGKIN DE CELULAS GRANDES, FOLICULAR"},
{ value: "C827",	text: "OTROS TIPOS ESPECIFICADOS DE LINFOMA NO HODGKIN FOLICULAR"},
{ value: "C829",	text: "LINFOMA NO HODGKIN FOLICULAR, SIN OTRA ESPECIFICACION"},
{ value: "C830",	text: "LINFOMA NO HODGKIN DE CELULAS PEQUEÑAS (DIFUSO)"},
{ value: "C831",	text: "LINFOMA NO HODGKIN DE CELULAS PEQUEÑAS HENDIDAS (DIFUSO)"},
{ value: "C832",	text: "LINFOMA NO HODGKIN MIXTO, DE CELULAS PEQUEÑAS Y GRANDES (DIFUSO)"},
{ value: "C833",	text: "LINFOMA NO HODGKIN DE CELULAS GRANDES (DIFUSO)"},
{ value: "C834",	text: "LINFOMA NO HODGKIN INMUNOBLASTICO (DIFUSO)"},
{ value: "C835",	text: "LINFOMA NO HODGKIN LINFOBLASTICO (DIFUSO)"},
{ value: "C836",	text: "LINFOMA NO HODGKIN INDIFERENCIADO (DIFUSO)"},
{ value: "C837",	text: "TUMOR DE BURKITT"},
{ value: "C838",	text: "OTROS TIPOS ESPECIFICADOS DE LINFOMA NO HODGKIN DIFUSO"},
{ value: "C839",	text: "LINFOMA NO HODGKIN DIFUSO, SIN OTRA ESPECIFICACION"},
{ value: "C840",	text: "MICOSIS FUNGOIDE"},
{ value: "C841",	text: "ENFERMEDAD DE SEZARY"},
{ value: "C842",	text: "LINFOMA DE ZONA T"},
{ value: "C843",	text: "LINFOMA LINFOEPITELIOIDE"},
{ value: "C844",	text: "LINFOMA DE CELULAS T PERIFERICO"},
{ value: "C845",	text: "OTROS LINFOMAS DE CELULAS Y LOS NO ESPECIFICADOS"},
{ value: "C850",	text: "LINFOSARCOMA"},
{ value: "C851",	text: "LINFOMA DE CELULAS B, SIN OTRA ESPECIFICACION"},
{ value: "C857",	text: "OTROS TIPOS ESPECIFICADOS DE LINFOMA NO HODGKIN"},
{ value: "C859",	text: "LINFOMA NO HODGKIN, NO ESPECIFICADO"},
{ value: "C880",	text: "MACROGLOBULINEMIA DE WALDENSTROM"},
{ value: "C881",	text: "ENFERMEDAD DE CADENA PESADA ALFA"},
{ value: "C882",	text: "ENFERMEDAD DE CADENA PESADA GAMMA"},
{ value: "C883",	text: "ENFERMEDAD INMUNOPROLIFERATIVA DEL INTESTINO DELGADO"},
{ value: "C887",	text: "OTRAS ENFERMEDADES INMUNOPROLIFERATIVAS MALIGNAS"},
{ value: "C889",	text: "ENFERMEDAD INMUNOPROLIFERATIVA MALIGNA, SIN OTRA ESPECIFICACION"},
{ value: "C900",	text: "MIELOMA MULTIPLE"},
{ value: "C901",	text: "LEUCEMIA DE CELULAS PLASMATICAS"},
{ value: "C902",	text: "PLASMOCITOMA, EXTRAMEDULAR"},
{ value: "C910",	text: "LEUCEMIA LINFOBLASTICA AGUDA"},
{ value: "C911",	text: "LEUCEMIA LINFOCITICA CRONICA"},
{ value: "C912",	text: "LEUCEMIA LINFOCITICA SUBAGUDA"},
{ value: "C913",	text: "LEUCEMIA PROLINFOCITICA"},
{ value: "C914",	text: "LEUCEMIA DE CELULAS VELLOSAS"},
{ value: "C915",	text: "LEUCEMIA DE CELULAS T ADULTAS"},
{ value: "C917",	text: "OTRAS LEUCEMIAS LINFOIDES"},
{ value: "C919",	text: "LEUCEMIA LINFOIDE, SIN OTRA ESPECIFICACION"},
{ value: "C920",	text: "LEUCEMIA MIELOIDE AGUDA"},
{ value: "C921",	text: "LEUCEMIA MIELOIDE CRONICA"},
{ value: "C922",	text: "LEUCEMIA MIELOIDE SUBAGUDA"},
{ value: "C923",	text: "SARCOMA MIELOIDE"},
{ value: "C924",	text: "LEUCEMIA PROMIELOCITICA AGUDA"},
{ value: "C925",	text: "LEUCEMIA MIELOMONOCITICA AGUDA"},
{ value: "C927",	text: "OTRAS LEUCEMIAS MIELOIDES"},
{ value: "C929",	text: "LEUCEMIA MIELOIDE, SIN OTRA ESPECIFICACION"},
{ value: "C930",	text: "LEUCEMIA MONOCITICA AGUDA"},
{ value: "C931",	text: "LEUCEMIA MONOCITICA CRONICA"},
{ value: "C932",	text: "LEUCEMIA MONOCITICA SUBAGUDA"},
{ value: "C937",	text: "OTRAS LEUCEMIAS MONOCITICAS"},
{ value: "C939",	text: "LEUCEMIA MONOCITICA, SIN OTRA ESPECIFICACION"},
{ value: "C940",	text: "ERITREMIA AGUDA Y ERITROLEUCEMIA"},
{ value: "C941",	text: "ERITREMIA CRONICA"},
{ value: "C942",	text: "LEUCEMIA MEGACARIOBLASTICA AGUDA"},
{ value: "C943",	text: "LEUCEMIA DE MASTOCITOS"},
{ value: "C944",	text: "PANMIELOSIS AGUDA"},
{ value: "C945",	text: "MIELOFIBROSIS AGUDA"},
{ value: "C947",	text: "OTRAS LEUCEMIAS ESPECIFICADAS"},
{ value: "C950",	text: "LEUCEMIA AGUDA, CELULAS DE TIPO NO ESPECIFICADO"},
{ value: "C951",	text: "LEUCEMIA CRONICA, CELULAS DE TIPO NO ESPECIFICADO"},
{ value: "C952",	text: "LEUCEMIA SUBAGUDA, CELULAS DE TIPO NO ESPECIFICADO"},
{ value: "C957",	text: "OTRAS LEUCEMIAS DE CELULAS DE TIPO NO ESPECIFICADO"},
{ value: "C959",	text: "LEUCEMIA, NO ESPECIFICADA"},
{ value: "C960",	text: "ENFERMEDAD DE LETTERER-SIWE"},
{ value: "C961",	text: "HISTIOCITOSIS MALIGNA"},
{ value: "C962",	text: "TUMOR MALIGNO DE MASTOCITOS"},
{ value: "C963",	text: "LINFOMA HISTIOCITICO VERDADERO"},
{ value: "C967",	text: "OTROS TUMORES MALIGNOS ESPECIFICADOS DEL TEJIDO LINFATICO, HEMATOPOYETICO Y TEJIDOS AFINES"},
{ value: "C969",	text: "TUMOR MALIGNO DEL TEJIDO LINFATICO, HEMATOPOYETICO Y TEJIDOS AFINES, SIN OTRA ESPECIFICACION"},
{ value: "C97X",	text: "TUMORES MALIGNO (PRIMARIOS) DE SITIOS MULTIPLES INDEPENDIENTES"},
{ value: "D000",	text: "CARCINOMA IN SITU DEL LABIO, DE LA CAVIDAD BUCAL Y DE LA FARINGE"},
{ value: "D001",	text: "CARCINOMA IN SITU DEL ESOFAGO"},
{ value: "D002",	text: "CARCINOMA IN SITU DEL ESTOMAGO"},
{ value: "D010",	text: "CARCINOMA IN SITU DEL COLON"},
{ value: "D011",	text: "CARCINOMA IN SITU DE LA UNION RECTOSIGMOIDEA"},
{ value: "D012",	text: "CARCINOMA IN SITU DEL RECTO"},
{ value: "D013",	text: "CARCINOMA IN SITU DEL ANO Y DEL CONDUCTO ANAL"},
{ value: "D014",	text: "CARCINOMA IN SITU DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL INTESTINO"},
{ value: "D015",	text: "CARCINOMA IN SITU DEL HIGADO, DE LA VESICULA BILIAR Y DEL CONDUCTO BILIAR"},
{ value: "D017",	text: "CARCINOMA IN SITU DE OTRAS PARTES ESPECIFICADAS DE ORGANOS DIGESTIVOS"},
{ value: "D019",	text: "CARCINOMA IN SITU DE ORGANOS DIGESTIVOS NO ESPECIFICADOS"},
{ value: "D020",	text: "CARCINOMA IN SITU DE LA LARINGE"},
{ value: "D021",	text: "CARCINOMA IN SITU DE LA TRAQUEA"},
{ value: "D022",	text: "CARCINOMA IN SITU DEL BRONQUIO Y DEL PULMON"},
{ value: "D023",	text: "CARCINOMA IN SITU DE OTRAS PARTES DEL SISTEMA RESPIRATORIO"},
{ value: "D024",	text: "CARCINOMA IN SITU DE ORGANOS RESPIRATORIOS NO ESPECIFICADOS"},
{ value: "D030",	text: "MELANOMA IN SITU DEL LABIO"},
{ value: "D031",	text: "MELANOMA IN SITU DEL PARPADO Y DE LA COMISURA PALPEBRAL"},
{ value: "D032",	text: "MELANOMA IN SITU DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO"},
{ value: "D033",	text: "MELANOMA IN SITU DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CARA"},
{ value: "D034",	text: "MELANOMA IN SITU DEL CUERO CABELLUDO Y DEL CUELLO"},
{ value: "D035",	text: "MELANOMA IN SITU DEL TRONCO"},
{ value: "D036",	text: "MELANOMA IN SITU DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"},
{ value: "D037",	text: "MELANOMA IN SITU DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"},
{ value: "D038",	text: "MELANOMA IN SITU DE OTROS SITIOS"},
{ value: "D039",	text: "MELANOMA IN SITU, SITIO NO ESPECIFICADO"},
{ value: "D040",	text: "CARCINOMA IN SITU DE LA PIEL DEL LABIO"},
{ value: "D041",	text: "CARCINOMA IN SITU DE LA PIEL DEL PARPADO Y DE LA COMISURA PALPEBRAL"},
{ value: "D042",	text: "CARCINOMA IN SITU DE LA PIEL DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO"},
{ value: "D043",	text: "CARCINOMA IN SITU DE LA PIEL DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CARA"},
{ value: "D044",	text: "CARCINOMA IN SITU DE LA PIEL DEL CUERO CABELLUDO Y CUELLO"},
{ value: "D045",	text: "CARCINOMA IN SITU DE LA PIEL DEL TRONCO"},
{ value: "D046",	text: "CARCINOMA IN SITU DE LA PIEL DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"},
{ value: "D047",	text: "CARCINOMA IN SITU DE LA PIEL DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"},
{ value: "D048",	text: "CARCINOMA IN SITU DE LA PIEL DE OTROS SITIOS ESPECIFICADOS"},
{ value: "D049",	text: "CARCINOMA IN SITU DE LA PIEL, SITIO NO ESPECIFICADO"},
{ value: "D050",	text: "CARCINOMA IN SITU LOBULAR"},
{ value: "D051",	text: "CARCINOMA IN SITU INTRACANALICULAR"},
{ value: "D057",	text: "OTROS CARCINOMAS IN SITU DE LA MAMA"},
{ value: "D059",	text: "CARCINOMA IN SITU DE LA MAMA, PARTE NO ESPECIFICADA"},
{ value: "D060",	text: "CARCINOMA IN SITU DEL ENDOCERVIX"},
{ value: "D061",	text: "CARCINOMA IN SITU DEL EXOCERVIX"},
{ value: "D067",	text: "CARCINOMA IN SITU DE OTRAS PARTES ESPECIFICADAS DEL CUELLO DEL UTERO"},
{ value: "D069",	text: "CARCINOMA IN SITU DEL CUELLO DEL UTERO, PARTE NO ESPECIFICADA"},
{ value: "D070",	text: "CARCINOMA IN SITU DEL ENDOMETRIO"},
{ value: "D071",	text: "CARCINOMA IN SITU DE LA VULVA"},
{ value: "D072",	text: "CARCINOMA IN SITU DE LA VAGINA"},
{ value: "D073",	text: "CARCINOMA IN SITU DE OTROS SITIOS DE ORGANOS GENITALES FEMENINOS Y DE LOS NO ESPECIFICADOS"},
{ value: "D074",	text: "CARCINOMA IN SITU DEL PENE"},
{ value: "D075",	text: "CARCINOMA IN SITU DE LA PROSTATA"},
{ value: "D076",	text: "CARCINOMA IN SITU DE OTROS ORGANOS GENITALES MASCULINOS Y DE LOS NO ESPECIFICADOS"},
{ value: "D090",	text: "CARCINOMA IN SITU DE LA VEJIGA"},
{ value: "D091",	text: "CARCINOMA IN SITU DE OTROS ORGANOS URINARIOS Y DE LOS NO ESPECIFICADOS"},
{ value: "D092",	text: "CARCINOMA IN SITU DEL OJO"},
{ value: "D093",	text: "CARCINOMA IN SITU DE LA GLANDULA TIROIDES Y DE OTRAS GLANDULAS ENDOCRINAS"},
{ value: "D097",	text: "CARCINOMA IN SITU DE OTROS SITIOS ESPECIFICADOS"},
{ value: "D099",	text: "CARCINOMA IN SITU, SITIO NO ESPECIFICADO"},
{ value: "D100",	text: "TUMOR BENIGNO DEL LABIO"},
{ value: "D101",	text: "TUMOR BENIGNO DE LA LENGUA"},
{ value: "D102",	text: "TUMOR BENIGNO DEL PISO DE LA BOCA"},
{ value: "D103",	text: "TUMOR BENIGNO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA BOCA"},
{ value: "D104",	text: "TUMOR BENIGNO DE LA AMIGDALA"},
{ value: "D105",	text: "TUMOR BENIGNO DE OTRAS PARTES DE LA OROFARINGE"},
{ value: "D106",	text: "TUMOR BENIGNO DE LA NASOFARINGE"},
{ value: "D107",	text: "TUMOR BENIGNO DE LA HIPOFARINGE"},
{ value: "D109",	text: "TUMOR BENIGNO DE LA FARINGE, PARTE NO ESPECIFICADA"},
{ value: "D110",	text: "TUMOR BENIGNO DE LA GLANDULA PAROTIDA"},
{ value: "D117",	text: "TUMOR BENIGNO DE OTRAS GLANDULAS SALIVALES MAYORES ESPECIFICADAS"},
{ value: "D119",	text: "TUMOR BENIGNO DE LA GLANDULA SALIVAL MAYOR, SIN OTRA ESPECIFICACION"},
{ value: "D120",	text: "TUMOR BENIGNO DEL CIEGO"},
{ value: "D121",	text: "TUMOR BENIGNO DEL APENDICE"},
{ value: "D122",	text: "TUMOR BENIGNO DEL COLON ASCENDENTE"},
{ value: "D123",	text: "TUMOR BENIGNO DEL COLON TRANSVERSO"},
{ value: "D124",	text: "TUMOR BENIGNO DEL COLON DESCENDENTE"},
{ value: "D125",	text: "TUMOR BENIGNO DEL COLON SIGMOIDE"},
{ value: "D126",	text: "TUMOR BENIGNO DEL COLON, PARTE NO ESPECIFICADA"},
{ value: "D127",	text: "TUMOR BENIGNO DE LA UNION RECTOSIGMOIDEA"},
{ value: "D128",	text: "TUMOR BENIGNO DEL RECTO"},
{ value: "D129",	text: "TUMOR BENIGNO DEL CONDUCTO ANAL Y DEL ANO"},
{ value: "D130",	text: "TUMOR BENIGNO DEL ESOFAGO"},
{ value: "D131",	text: "TUMOR BENIGNO DEL ESTOMAGO"},
{ value: "D132",	text: "TUMOR BENIGNO DEL DUODENO"},
{ value: "D133",	text: "TUMOR BENIGNO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL INTESTINO DELGADO"},
{ value: "D134",	text: "TUMOR BENIGNO DEL HIGADO"},
{ value: "D135",	text: "TUMOR BENIGNO DE LAS VIAS BILIARES EXTRAHEPATICAS"},
{ value: "D136",	text: "TUMOR BENIGNO DEL PANCREAS"},
{ value: "D137",	text: "TUMOR BENIGNO DEL PANCREAS ENDOCRINO"},
{ value: "D139",	text: "TUMOR BENIGNO DE SITIOS MAL DEFINIDOS DEL SISTEMA DIGESTIVO"},
{ value: "D140",	text: "TUMOR BENIGNO DEL OIDO MEDIO, DE LA CAVIDAD NASAL Y DE LOS SENOS PARANASALES"},
{ value: "D141",	text: "TUMOR BENIGNO DE LA LARINGE"},
{ value: "D142",	text: "TUMOR BENIGNO DE LA TRAQUEA"},
{ value: "D143",	text: "TUMOR BENIGNO DE LOS BRONQUIOS Y DEL PULMON"},
{ value: "D144",	text: "TUMOR BENIGNO DEL SISTEMA RESPIRATORIO, SITIO NO ESPECIFICADO"},
{ value: "D150",	text: "TUMOR BENIGNO DEL TIMO"},
{ value: "D151",	text: "TUMOR BENIGNO DEL CORAZON"},
{ value: "D152",	text: "TUMOR BENIGNO DEL MEDIASTINO"},
{ value: "D157",	text: "TUMOR BENIGNO DE OTROS ORGANOS INTRATORACICOS ESPECIFICADOS"},
{ value: "D159",	text: "TUMOR BENIGNO DE ORGANO INTRATORACICO NO ESPECIFICADO"},
{ value: "D160",	text: "TUMOR BENIGNO DEL OMOPLATO Y HUESOS LARGOS DEL MIEMBRO SUPERIOR"},
{ value: "D161",	text: "TUMOR BENIGNO DE LOS HUESOS CORTOS DEL MIEMBRO SUPERIOR"},
{ value: "D162",	text: "TUMOR BENIGNO DE LOS HUESOS LARGOS DEL MIEMBRO INFERIOR"},
{ value: "D163",	text: "TUMOR BENIGNO DE LOS HUESOS CORTOS DEL MIEMBRO INFERIOR"},
{ value: "D164",	text: "TUMOR BENIGNO DE LOS HUESOS DEL CRANEO Y DE LA CARA"},
{ value: "D165",	text: "TUMOR BENIGNO DEL MAXILAR INFERIOR"},
{ value: "D166",	text: "TUMOR BENIGNO DE LA COLUMNA VERTEBRAL"},
{ value: "D167",	text: "TUMOR BENIGNO DE LAS COSTILLAS, ESTERNON Y CLAVICULA"},
{ value: "D168",	text: "TUMOR BENIGNO DE LOS HUESOS PELVICOS, SACRO Y COCCIX"},
{ value: "D169",	text: "TUMOR BENIGNO DEL HUESOS Y DEL CARTILAGO ARTICULAR, SITIO NO ESPECIFICADO"},
{ value: "D170",	text: "TUMOR BENIGNO LIPOMATOSO DE PIEL Y DE TEJIDO SUBCUTANEO DE CABEZA, CARA Y CUELLO"},
{ value: "D171",	text: "TUMOR BENIGNO LIPOMATOSO DE PIEL Y DE TEJIDO SUBCUTANEO DEL TRONCO"},
{ value: "D172",	text: "TUMOR BENIGNO LIPOMATOSO DE PIEL Y DE TEJIDO SUBCUTANEO DE MIEMBROS"},
{ value: "D173",	text: "TUMOR BENIGNO LIPOMATOSO DE PIEL Y DE TEJIDO SUBCUTANEO DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS"},
{ value: "D174",	text: "TUMOR BENIGNO LIPOMATOSO DE LOS ORGANOS INTRATORACICOS"},
{ value: "D175",	text: "TUMOR BENIGNO LIPOMATOSO DE LOS ORGANOS INTRAABDOMINALES"},
{ value: "D176",	text: "TUMOR BENIGNO LIPOMATOSO DEL CORDON ESPERMATICO"},
{ value: "D177",	text: "TUMOR BENIGNO LIPOMATOSO DE OTROS SITIOS ESPECIFICADOS"},
{ value: "D179",	text: "TUMOR BENIGNO LIPOMATOSO, DE SITIO NO ESPECIFICADO"},
{ value: "D180",	text: "HEMANGIOMA, DE CUALQUIER SITIO"},
{ value: "D181",	text: "LINFANGIOMA, DE CUALQUIER SITIO"},
{ value: "D190",	text: "TUMOR BENIGNO DEL TEJIDO MESOTELIAL DE LA PLEURA"},
{ value: "D191",	text: "TUMOR BENIGNO DEL TEJIDO MESOTELIAL DEL PERITONEO"},
{ value: "D197",	text: "TUMOR BENIGNO DEL TEJIDO MESOTELIAL DE OTROS SITIOS ESPECIFICADOS"},
{ value: "D199",	text: "TUMOR BENIGNO DEL TEJIDO MESOTELIAL, DE SITIO NO ESPECIFICADO"},
{ value: "D200",	text: "TUMOR BENIGNO DEL RETROPERITONEO"},
{ value: "D201",	text: "TUMOR BENIGNO DEL PERITONEO"},
{ value: "D210",	text: "TUMOR BENIGNO DEL TEJIDO CUNJUNTIVO Y DE OTROS TEJIDOS BLANDOS DE CABEZA, CARA Y CUELLO"},
{ value: "D211",	text: "TUMOR BENIGNO DEL TEJIDO CUNJUNTIVO Y DE OTROS TEJIDOS BLANDOS DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"},
{ value: "D212",	text: "TUMOR BENIGNO DEL TEJIDO CUNJUNTIVO Y DE OTROS TEJIDOS BLANDOS DEL MIEMBRO INFERIOR, INCLUIDO LA CADERA"},
{ value: "D213",	text: "TUMOR BENIGNO DEL TEJIDO CUNJUNTIVO Y DE OTROS TEJIDOS BLANDOS DEL TORAX"},
{ value: "D214",	text: "TUMOR BENIGNO DEL TEJIDO CUNJUNTIVO Y DE OTROS TEJIDOS BLANDOS DEL ABDOMEN"},
{ value: "D215",	text: "TUMOR BENIGNO DEL TEJIDO CUNJUNTIVO Y DE OTROS TEJIDOS BLANDOS DE LA PELVIS"},
{ value: "D216",	text: "TUMOR BENIGNO DEL TEJIDO CUNJUNTIVO Y DE OTROS TEJIDOS BLANDOS DEL TRONCO, SIN OTRA ESPECIFICACION"},
{ value: "D219",	text: "TUMOR BENIGNO DEL TEJIDO CUNJUNTIVO Y DE OTROS TEJIDOS BLANDOS, DE SITIO NO ESPECIFICADO"},
{ value: "D220",	text: "NEVO MELANOCITICO DEL LABIO"},
{ value: "D221",	text: "NEVO MELANOCITICO DEL PARPADO, INCLUIDA LA COMISURA PALPEBRAL"},
{ value: "D222",	text: "NEVO MELANOCITICO DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO"},
{ value: "D223",	text: "NEVO MELANOCITICO DE OTRAS PARTES DE LAS NO ESPECIFICADAS DE LA CARA"},
{ value: "D224",	text: "NEVO MELANOCITICO DEL CUERO CABELLUDO Y DEL CUELLO"},
{ value: "D225",	text: "NEVO MELANOCITICO DEL TRONCO"},
{ value: "D226",	text: "NEVO MELANOCITICO DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"},
{ value: "D227",	text: "NEVO MELANOCITICO DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"},
{ value: "D229",	text: "NEVO MELANOCITICO, SITIO NO ESPECIFICADO"},
{ value: "D230",	text: "TUMOR BENIGNO DE LA PIEL DEL LABIO"},
{ value: "D231",	text: "TUMOR BENIGNO DE LA PIEL DEL PARPADO, INCLUIDA LA COMISURA PALPEBRAL"},
{ value: "D232",	text: "TUMOR BENIGNO DE LA PIEL DE LA OREJA Y DEL CONDUCTO AUDITIVO EXTERNO"},
{ value: "D233",	text: "TUMOR BENIGNO DE LA PIEL DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CARA"},
{ value: "D234",	text: "TUMOR BENIGNO DE LA PIEL DEL CUERO CABELLUDO Y DEL CUELLO"},
{ value: "D235",	text: "TUMOR BENIGNO DE LA PIEL DEL TRONCO"},
{ value: "D236",	text: "TUMOR BENIGNO DE LA PIEL DEL MIEMBRO SUPERIOR, INCLUIDO EL HOMBRO"},
{ value: "D237",	text: "TUMOR BENIGNO DE LA PIEL DEL MIEMBRO INFERIOR, INCLUIDA LA CADERA"},
{ value: "D239",	text: "TUMOR BENIGNO DE LA PIEL, SITIO NO ESPECIFICADO"},
{ value: "D24X",	text: "TUMOR BENIGNO DE LA MAMA"},
{ value: "D250",	text: "LEIOMIOMA SUBMUCOSO DEL UTERO"},
{ value: "D251",	text: "LEIOMIOMA INTRAMURAL DEL UTERO"},
{ value: "D252",	text: "LEIOMIOMA SUBSEROSO DEL UTERO"},
{ value: "D259",	text: "LEIOMIOMA DEL UTERO, SIN OTRA ESPECIFICACION"},
{ value: "D260",	text: "TUMOR BENIGNO DEL CUELLO DEL UTERO"},
{ value: "D261",	text: "TUMOR BENIGNO DEL CUERPO DEL UTERO"},
{ value: "D267",	text: "TUMOR BENIGNO DE OTRAS PARTES ESPECIFICADAS DEL UTERO"},
{ value: "D269",	text: "TUMOR BENIGNO DEL UTERO, PARTE NO ESPECIFICADA"},
{ value: "D27X",	text: "TUMOR BENIGNO DEL OVARIO"},
{ value: "D280",	text: "TUMOR BENIGNO DE LA VULVA"},
{ value: "D281",	text: "TUMOR BENIGNO DE LA VAGINA"},
{ value: "D282",	text: "TUMOR BENIGNO DE LA TROMPA DE FALOPIO Y DE LOS LIGAMENTOS UTERINOS"},
{ value: "D287",	text: "TUMOR BENIGNO DE OTROS SITIOS ESPECIFICADOS DE LOS ORGANOS GENITALES FEMENINOS"},
{ value: "D289",	text: "TUMOR BENIGNO DE ORGANO GENITAL FEMENINO, SITIO NO ESPECIFICADO"},
{ value: "D290",	text: "TUMOR BENIGNO DEL PENE"},
{ value: "D291",	text: "TUMOR BENIGNO DE LA PROSTATA"},
{ value: "D292",	text: "TUMOR BENIGNO DE LOS TESTICULOS"},
{ value: "D293",	text: "TUMOR BENIGNO DEL EPIDIDIMO"},
{ value: "D294",	text: "TUMOR BENIGNO DEL ESCROTO"},
{ value: "D297",	text: "TUMOR BENIGNO DE OTROS ORGANOS GENITALES MASCULINOS"},
{ value: "D299",	text: "TUMOR BENIGNO DE ORGANO GENITAL MASCULINO, SITIO NO ESPECIFICADO"},
{ value: "D300",	text: "TUMOR BENIGNO DEL RIÑON"},
{ value: "D301",	text: "TUMOR BENIGNO DE LA PELVIS RENAL"},
{ value: "D302",	text: "TUMOR BENIGNO DEL URETER"},
{ value: "D303",	text: "TUMOR BENIGNO DE LA VEJIGA"},
{ value: "D304",	text: "TUMOR BENIGNO DE LA URETRA"},
{ value: "D307",	text: "TUMOR BENIGNO DE OTROS ORGANOS URINARIOS"},
{ value: "D309",	text: "TUMOR BENIGNO DE ORGANO URINARIO NO ESPECIFICADO"},
{ value: "D310",	text: "TUMOR BENIGNO DE LA CONJUNTIVA"},
{ value: "D311",	text: "TUMOR BENIGNO DE LA CORNEA"},
{ value: "D312",	text: "TUMOR BENIGNO DE LA RETINA"},
{ value: "D313",	text: "TUMOR BENIGNO DE LA COROIDES"},
{ value: "D314",	text: "TUMOR BENIGNO DEL CUERPO CILIAR"},
{ value: "D315",	text: "TUMOR BENIGNO DE LAS GLANDULAS Y DE LOS CONDUCTOS LAGRIMALES"},
{ value: "D316",	text: "TUMOR BENIGNO DE LA ORBITA, PARTE NO ESPECIFICADA"},
{ value: "D319",	text: "TUMOR BENIGNO DEL OJO, PARTE NO ESPECIFICADA"},
{ value: "D320",	text: "TUMOR BENIGNO DE LAS MENINGES CEREBRALES"},
{ value: "D321",	text: "TUMOR BENIGNO DE LAS MENINGES RAQUIDEAS"},
{ value: "D329",	text: "TUMOR BENIGNO DE LAS MENINGES, PARTE NO ESPECIFICADA"},
{ value: "D330",	text: "TUMOR BENIGNO DEL ENCEFALO, SUPRATENTORIAL"},
{ value: "D331",	text: "TUMOR BENIGNO DEL ENCEFALO, INFRATENTORIAL"},
{ value: "D332",	text: "TUMOR BENIGNO DE ENCEFALO, PARTE NO ESPECIFICADA"},
{ value: "D333",	text: "TUMOR BENIGNO DE LOS NERVIOS CRANEALES"},
{ value: "D334",	text: "TUMOR BENIGNO DE LA MEDULA ESPINAL"},
{ value: "D337",	text: "TUMOR BENIGNO DE OTRAS PARTES ESPECIFICADAS DEL SISTEMA NERVIOSO CENTRAL"},
{ value: "D339",	text: "TUMOR BENIGNO DEL SISTEMA NERVIOSO CENTRAL, SITIO NO ESPECIFICADO"},
{ value: "D34X",	text: "TUMOR BENIGNO DE LA GLANDULA TIROIDES"},
{ value: "D350",	text: "TUMOR BENIGNO DE LA GLANDULA SUPRARRENAL"},
{ value: "D351",	text: "TUMOR BENIGNO DE LA GLANDULA PARATIROIDES"},
{ value: "D352",	text: "TUMOR BENIGNO DE LA HIPOFISIS"},
{ value: "D353",	text: "TUMOR BENIGNO DEL CONDUCTO CRANEOFARINGEO"},
{ value: "D354",	text: "TUMOR BENIGNO DE LA GLANDULA PINEAL"},
{ value: "D355",	text: "TUMOR BENIGNO DEL CUERPO CAROTIDEO"},
{ value: "D356",	text: "TUMOR BENIGNO DEL CUERPO AORTICO Y DE OTROS CUERPOS CROMAFINES"},
{ value: "D357",	text: "TUMOR BENIGNO DE OTRAS GLANDULAS ENDOCRINAS ESPECIFICADAS"},
{ value: "D358",	text: "TUMOR BENIGNO DE PLURIGLANDULAR"},
{ value: "D359",	text: "TUMOR BENIGNO DE GLANDULA ENDOCRINA NO ESPECIFICADA"},
{ value: "D360",	text: "TUMOR BENIGNO DE LOS GANGLIOS LINFATICOS"},
{ value: "D361",	text: "TUMOR BENIGNO DE LOS NERVIOS PERIFERICOS Y DEL SISTEMA NERVIOSO AUTONOMO"},
{ value: "D367",	text: "TUMOR BENIGNO DE OTROS SITIOS ESPECIFICADOS"},
{ value: "D369",	text: "TUMOR BENIGNO DE SITIO NO ESPECIFICADO"},
{ value: "D370",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL LABIO, DE LA CAVIDAD BUCAL Y DE LA FARINGE"},
{ value: "D371",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ESTOMAGO"},
{ value: "D372",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL INTESTINO DELGADO"},
{ value: "D373",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL APENDICE"},
{ value: "D374",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL COLON"},
{ value: "D375",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL RECTO"},
{ value: "D376",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL HIGADO, DE LA VESICULA BILIAR Y DEL CONDUCTO BILIAR"},
{ value: "D377",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS ORGANOS DIGESTIVOS ESPECIFICADOS"},
{ value: "D379",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE ORGANOS DIGESTIVOS, SITIO NO ESPECIFICADO"},
{ value: "D380",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LARINGE"},
{ value: "D381",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA TRAQUEA, DE LOS BRONQUIOS Y DEL PULMON"},
{ value: "D382",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA PLEURA"},
{ value: "D383",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL MEDIASTINO"},
{ value: "D384",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TIMO"},
{ value: "D385",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS ORGANOS RESPIRATORIOS Y DEL OIDO MEDIO"},
{ value: "D386",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE ORGANOS RESPIRATORIOS, SITIO NO ESPECIFICADO"},
{ value: "D390",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL UTERO"},
{ value: "D391",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL OVARIO"},
{ value: "D392",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA PLACENTA"},
{ value: "D397",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS ORGANOS GENITALES FEMENINOS"},
{ value: "D399",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE ORGANO GENITAL FEMENINO NO ESPECIFICADO"},
{ value: "D400",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA PROSTATA"},
{ value: "D401",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TESTICULO"},
{ value: "D407",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS ORGANOS GENITALES MASCULINOS"},
{ value: "D409",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE ORGANO GENITAL MASCULINO NO ESPECIFICADO"},
{ value: "D410",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL RIÑON"},
{ value: "D411",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEC LA PELVIS RENAL"},
{ value: "D412",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL URETER"},
{ value: "D413",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA URETRA"},
{ value: "D414",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA VEJIGA"},
{ value: "D417",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS ORGANOS URINARIOS"},
{ value: "D419",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE ORGANO URINARIO NO ESPECIFICADO"},
{ value: "D420",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS MENINGES CEREBRALES"},
{ value: "D421",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS MENINGES RAQUIDEAS"},
{ value: "D429",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LAS MENINGES, PARTE NO ESPECIFICADA"},
{ value: "D430",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ENCEFALO, SUPRATENTORIAL"},
{ value: "D431",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ENCEFALO, INFRATENTORIAL"},
{ value: "D432",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL ENCEFALO, PARTE NO ESPECIFICADA"},
{ value: "D433",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LOS NERVIOS CRANEALES"},
{ value: "D434",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA MEDULA ESPINAL"},
{ value: "D437",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTRAS PARTES ESPECIFICADAS DEL SISTEMA NERVIOSO CENTRAL"},
{ value: "D439",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL SISTEMA NERVIOSO CENTRAL, SITIO NO ESPECIFICADO"},
{ value: "D440",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA GLANDULA TIROIDES"},
{ value: "D441",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA GLANDULA SUPRARRENAL"},
{ value: "D442",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA GLANDULA PARATIROIDES"},
{ value: "D443",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA GLANDULA HIPOFISIS"},
{ value: "D444",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL CONDUCTO CRANEOFARINGEO"},
{ value: "D445",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA GLANDULA PINEAL"},
{ value: "D446",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL CUERPO CAROTIDEO"},
{ value: "D447",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL CUERPO AORTICO Y OTROS CROMAFINES"},
{ value: "D448",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO CON AFECTACION PLURIGLANDULAR"},
{ value: "D449",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE GLANDULA ENDOCRINA NO ESPECIFICADA"},
{ value: "D45X",	text: "POLICITEMIA VERA"},
{ value: "D460",	text: "ANEMIA REFRACTARIA SIN SIDEROBLASTOS, ASI DESCRITA"},
{ value: "D461",	text: "ANEMIA REFRACTARIA CON SIDEROBLASTOS"},
{ value: "D462",	text: "ANEMIA REFRACTARIA CON EXCESO DE BLASTOS"},
{ value: "D463",	text: "ANEMIA REFRACTARIA CON EXCESO DE BLASTOS CON TRANSFORMACION"},
{ value: "D464",	text: "ANEMIA REFRACTARIA, SIN OTRA ESPECIFICACION"},
{ value: "D467",	text: "OTROS SINDROMES MIELODISPLASICOS"},
{ value: "D469",	text: "SINDROME MIELODISPLASICO, SIN OTRA ESPECIFICACION"},
{ value: "D470",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LOS MASTOCITOS E HISTIOCITOS"},
{ value: "D471",	text: "ENFERMEDAD MIELOPROLIFERATIVA CRONICA"},
{ value: "D472",	text: "GAMMOPATIA MONOCLONAL"},
{ value: "D473",	text: "TROMBOCITOPENIA (HEMORRAGICA) ESENCIAL"},
{ value: "D477",	text: "OTROS TUMORES ESPECIFICADOS DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TEJIDO LINFATICO, DE LOS ORGANOS HEMATOPOYETICOS Y DE TEJIDOS AFINES"},
{ value: "D479",	text: "TUMORES DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TEJIDO LINFATICO, DE LOS ORGANOS HEMATOPOYETICOS Y DE TEJIDOS AFINES, NO ESPECIFICADOS"},
{ value: "D480",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL HUESO Y CARTILAGO ARTICULAR"},
{ value: "D481",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL TEJIDO CONJUNTIVO Y OTRO TEJIDO BLANDO"},
{ value: "D482",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LOS NERVIOS PERIFERICOS Y DEL SISTEMA NERVIOSO AUTONOMO"},
{ value: "D483",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL RETROPERITONEO"},
{ value: "D484",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DEL PERITONEO"},
{ value: "D485",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA PIEL"},
{ value: "D486",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE LA MAMA"},
{ value: "D487",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO DE OTROS SITIOS ESPECIFICADOS"},
{ value: "D489",	text: "TUMOR DE COMPORTAMIENTO INCIERTO O DESCONOCIDO, DE SITIO NO ESPECIFICADO"},
{ value: "D500",	text: "ANEMIA POR DEFICIENCIA DE HIERRO SECUNDARIA A PERDIDA DE SANGRE (CRONICA)"},
{ value: "D501",	text: "DISFAGIA SIDEROPENICA"},
{ value: "D508",	text: "OTRAS ANEMIAS POR DEFICIENCIA DE HIERRO"},
{ value: "D509",	text: "ANEMIA POR DEFICIENCIA DE HIERRO SIN OTRA ESPECIFICACION"},
{ value: "D510",	text: "ANEMIA POR DEFICIENCIA DE VITAMINA B12 DEBIDA A DEFICIENCIA DEL FACTOR INTRINSECO"},
{ value: "D511",	text: "ANEMIA POR DEFICIENCIA DE VITAMINA B12 DEBIDA A MALA ABSORCION SELECTIVA DE VITAMINA B12 CON PROTEINURIA"},
{ value: "D512",	text: "DEFICIENCIA DE TRASCOBALAMINA II"},
{ value: "D513",	text: "OTRAS ANEMIAS POR DEFICIENCIA DIETETICA DE VITAMINA B12"},
{ value: "D518",	text: "OTRAS ANEMIAS POR DEFICIENCIA DE VITAMINA B12"},
{ value: "D519",	text: "ANEMIA POR DEFICIENCIA DE VITAMINA B12, SIN OTRA ESPECIFICACION"},
{ value: "D520",	text: "ANEMIA POR DEFICIENCIA DIETETICA DE FOLATOS"},
{ value: "D521",	text: "ANEMIA POR DEFICIENCIA DE FOLATOS INDUCIDA POR DROGAS"},
{ value: "D528",	text: "OTRAS ANEMIAS POR DEFICIENCIA DE FOLATOS"},
{ value: "D529",	text: "ANEMIA POR DEFICIENCIA DE FOLATOS, SIN OTRA ESPECIFICACION"},
{ value: "D530",	text: "ANEMIA POR DEFICIENCIA DE PROTEINAS"},
{ value: "D531",	text: "OTRAS ANEMIAS MEGALOBLASTICAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "D532",	text: "ANEMIA ESCORBUTICA"},
{ value: "D538",	text: "OTRAS ANEMIAS NUTRICIONALES ESPECIFICADAS"},
{ value: "D539",	text: "ANEMIA NUTRICIONAL, NO ESPECIFICADA"},
{ value: "D550",	text: "ANEMIA DEBIDA A DEFICIENCIA DE GLUCOSA-6-FOSFATO DESHIDROGENASA (G6FD)"},
{ value: "D551",	text: "ANEMIA DEBIDA A OTROS TRASTORNOS DEL METABOLISMO DEL GLUTATION"},
{ value: "D552",	text: "ANEMIA DEBIDA A TRASTORNOS DE LAS ENZIMAS GLUCOLITICAS"},
{ value: "D553",	text: "ANEMIA DEBIDA A TRASTORNOS DEL METABOLISMO DE LOS NUCLEOTIDOS"},
{ value: "D558",	text: "OTRAS ANEMIAS DEBIDAS A TRASTORNOS ENZIMATICOS"},
{ value: "D559",	text: "ANEMIA DEBIDA A TRASTORNOS ENZIMATICOS, SIN OTRA ESPECIFICACION"},
{ value: "D560",	text: "ALFA TALASEMIA"},
{ value: "D561",	text: "BETA TALASEMIA"},
{ value: "D562",	text: "DELTA-BETA TALASEMIA"},
{ value: "D563",	text: "RASGO TALASEMICO"},
{ value: "D564",	text: "PERSISTENCIA HEREDITARIA DE LA HEMOGLOBINA FETAL (PHHF)"},
{ value: "D568",	text: "OTRAS TALASEMIAS"},
{ value: "D569",	text: "TALASEMIA, NO ESPECIFICADA"},
{ value: "D570",	text: "ANEMIA FALCIFORME CON CRISIS"},
{ value: "D571",	text: "ANEMIA FALCIFORME SIN CRISIS"},
{ value: "D572",	text: "TRASTORNOS FALCIFORMES HETEROCIGOTICOS DOBLES"},
{ value: "D573",	text: "RASGO DREPANOCITICO"},
{ value: "D578",	text: "OTROS TRASTORNOS FALCIFORMES"},
{ value: "D580",	text: "ESFEROCITOSIS HEREDITARIA"},
{ value: "D581",	text: "ELIPTOCITOSIS HEREDITARIA"},
{ value: "D582",	text: "OTRAS HEMOGLOBINOPATIAS"},
{ value: "D588",	text: "OTRAS ANEMIAS HEMOLITICAS HEREDITARIAS ESPECIFICADAS"},
{ value: "D589",	text: "ANEMIA HEMOLITICA HEREDITARIA, SIN OTRA ESPECIFICACION"},
{ value: "D590",	text: "ANEMIA HEMOLITICA AUTOINMUNE INDUCIDA POR DROGAS"},
{ value: "D591",	text: "OTRAS ANEMIAS HEMOLITICAS AUTOINMUNES"},
{ value: "D592",	text: "ANEMIA HEMOLITICA NO AUTOINMUNE INDUCIDA POR DROGAS"},
{ value: "D593",	text: "SINDROME HEMOLITICO-UREMICO"},
{ value: "D594",	text: "OTRAS ANEMIAS HEMOLITICAS NO AUTOINMUNES"},
{ value: "D595",	text: "HEMOGLOBINURIA PAROXISTICA NOCTURNA [MARCHIAFAVA-MICHELI]"},
{ value: "D596",	text: "HEMOGLOBINURIA DEBIDA A HEMOLISIS POR OTRAS CAUSAS EXTERNAS"},
{ value: "D598",	text: "OTRAS ANEMIAS HEMOLITICAS ADQUIRIDAS"},
{ value: "D599",	text: "ANEMIAS HEMOLITICA ADQUIRIDA, SIN OTRA ESPECIFICACION"},
{ value: "D600",	text: "APLASIA CRONICA ADQUIRIDA, EXCLUSIVA DE LA SERIE ROJA"},
{ value: "D601",	text: "APLASIA TRANSITORIA ADQUIRIDA, EXCLUSIVA DE LA SERIE ROJA"},
{ value: "D608",	text: "OTRAS APLASIAS ADQUIRIDAS, EXCLUSIVAS DE LA SERIE ROJA"},
{ value: "D609",	text: "APLASIA ADQUIRIDA, EXCLUSIVA DE LA SERIE ROJA, NO ESPECIFICADA"},
{ value: "D610",	text: "ANEMIA APLASTICA CONSTITUCIONAL"},
{ value: "D611",	text: "ANEMIA APLASTICA INDUCIDA POR DROGAS"},
{ value: "D612",	text: "ANEMIA APLASTICA DEBIDA A OTROS AGENTES EXTERNOS"},
{ value: "D613",	text: "ANEMIA APLASTICA IDIOPATICA"},
{ value: "D618",	text: "OTRAS ANEMIAS APLASTICAS ESPECIFICADAS"},
{ value: "D619",	text: "ANEMIA APLASTICA, SIN OTRA ESPECIFICACION"},
{ value: "D62X",	text: "ANEMIA POSTHEMORRAGICA AGUDA"},
{ value: "D630",	text: "ANEMIA EN ENFERMEDAD NEOPLASICA (C00-D48†)"},
{ value: "D638",	text: "ANEMIA EN OTRAS ENFERMEDADES CRONICAS CLASIFICADAS EN OTRA PARTE"},
{ value: "D640",	text: "ANEMIA SIDEROBLASTICA HEREDITARIA"},
{ value: "D641",	text: "ANEMIA SIDEROBLASTICA SECUNDARIA A OTRA ENFERMEDAD"},
{ value: "D642",	text: "ANEMIA SIDEROBLASTICA SECUNDARIA, DEBIDA A DROGAS Y TOXINAS"},
{ value: "D643",	text: "OTRAS ANEMIAS SIDEROBLASTICAS"},
{ value: "D644",	text: "ANEMIA DISERITROPOYETICA CONGENITA"},
{ value: "D648",	text: "OTRAS ANEMIAS ESPECIFICADAS"},
{ value: "D649",	text: "ANEMIA DE TIPO NO ESPECIFICADO"},
{ value: "D65X",	text: "COAGULACION INTRAVASCULAR DISEMINADA [SINDROME DE DESFIBRINACION]"},
{ value: "D66X",	text: "DEFICIENCIA HEREDITARIA DEL FACTOR VIII"},
{ value: "D67X",	text: "DEFICIENCIA HEREDITARIA DEL FACTOR IX"},
{ value: "D680",	text: "ENFERMEDAD DE VON WILLEBRAND"},
{ value: "D681",	text: "DEFICIENCIA HEREDITARIA DEL FACTOR XI"},
{ value: "D682",	text: "DEFICIENCIA HEREDITARIA DE OTROS FACTORES DE LA COAGULACION"},
{ value: "D683",	text: "TRASTORNO HEMORRAGICO DEBIDO A ANTICOAGULANTES CIRCULANTES"},
{ value: "D684",	text: "DEFICIENCIA ADQUIRIDA DE FACTORES DE LA COAGULACION"},
{ value: "D688",	text: "OTROS DEFECTOS ESPECIFICADOS DE LA COAGULACION"},
{ value: "D689",	text: "DEFECTO DE LA COAGULACION, NO ESPECIFICADO"},
{ value: "D690",	text: "PURPURA ALERGICA"},
{ value: "D691",	text: "DEFECTOS CUALITATIVOS DE LAS PLAQUETAS"},
{ value: "D692",	text: "OTRAS PURPURAS NO TROMBOCITOPENICAS"},
{ value: "D693",	text: "PURPURA TROMBOCITOPENICA IDIOPATICA"},
{ value: "D694",	text: "OTRAS TROMBOCITOPENIAS PRIMARIAS"},
{ value: "D695",	text: "TROMBOCITOPENIA SECUNDARIA"},
{ value: "D696",	text: "TROMBOCITOPENIA NO ESPECIFICADA"},
{ value: "D698",	text: "OTRAS AFECCIONES HEMORRAGICAS ESPECIFICADAS"},
{ value: "D699",	text: "AFECCION HEMORRAGICA, NO ESPECIFICADA"},
{ value: "D70X",	text: "AGRANULOCITOSIS"},
{ value: "D71X",	text: "TRASTORNOS FUNCIONALES DE LOS POLIMORFONUCLEARES NEUTROFILOS"},
{ value: "D720",	text: "ANOMALIAS GENETICAS DE LOS LEUCOCITOS"},
{ value: "D721",	text: "EOSINOFILIA"},
{ value: "D728",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LOS LEUCOCITOS"},
{ value: "D729",	text: "TRASTORNOS DE LOS LEUCOCITOS, NO ESPECIFICADO"},
{ value: "D730",	text: "HIPOESPLENISMO"},
{ value: "D731",	text: "HIPERESPLENISMO"},
{ value: "D732",	text: "ESPLENOMEGALIA CONGESTIVA CRONICA"},
{ value: "D733",	text: "ABSCESO DEL BAZO"},
{ value: "D734",	text: "QUISTE DEL BAZO"},
{ value: "D735",	text: "INFARTO DEL BAZO"},
{ value: "D738",	text: "OTRAS ENFERMEDADES DEL BAZO"},
{ value: "D739",	text: "ENFERMEDAD DEL BAZO, NO ESPECIFICADA"},
{ value: "D740",	text: "METAHEMOGLOBINEMIA CONGENITA"},
{ value: "D748",	text: "OTRAS METAHEMOGLOBINEMIAS"},
{ value: "D749",	text: "METAHEMOGLOBINEMIA, NO ESPECIFICADA"},
{ value: "D750",	text: "ERITROCITOSIS FAMILIAR"},
{ value: "D751",	text: "POLICITEMIA SECUNDARIA"},
{ value: "D752",	text: "TROMBOCITOSIS ESENCIAL"},
{ value: "D758",	text: "OTRAS ENFERMEDADES ESPECIFICADAS DE LA SANGRE Y DE LOS ORGANOS HEMATOPOYETICOS"},
{ value: "D759",	text: "ENFERMEDAD DE LA SANGRE Y DE LOS ORGANOS HEMATOPOYETICOS, NO ESPECIFICADA"},
{ value: "D760",	text: "HISTIOCITOSIS DE LAS CELULAS DE LANGERHANS, NO CLASIFICADA EN OTRA PARTE"},
{ value: "D761",	text: "LINFOHISTIOCITOSIS HEMOFAGOCITICA"},
{ value: "D762",	text: "SINDROME HEMOFAGOCITICO ASOCIADO A INFECCION"},
{ value: "D763",	text: "OTROS SINDROMES HISTIOCITICOS"},
{ value: "D77X",	text: "OTROS TRASTORNOS DE LA SANGRE Y DE LOS ORGANOS HEMATOPOYETICOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "D800",	text: "HIPOGAMMAGLOBULINEMIA HEREDITARIA"},
{ value: "D801",	text: "HIPOGAMMAGLOBULINEMIA NO FAMILIAR"},
{ value: "D802",	text: "DEFICIENCIA SELECTIVA DE INMUNOGLOBULINA A [IgA]"},
{ value: "D803",	text: "DEFICIENCIA SELECTIVA DE SUBCLASES DE LA INMUNOGLOBULINA G [IgG]"},
{ value: "D804",	text: "DEFICIENCIA SELECTIVA DE INMUNOGLOBULINA M [IgM]"},
{ value: "D805",	text: "INMUNODEFICIENCIA CON INCREMENTO DE INMUNOGLOBULINA M [IgM]"},
{ value: "D806",	text: "DEFICIENCIA DE ANTICUERPOS CON INMUNOGLOBULINAS CASI NORMALES O CON HIPERINMUNOGLOBULINEMIA"},
{ value: "D807",	text: "HIPOGAMMAGLOBULINEMIA TRANSITORIA DE LA INFANCIA"},
{ value: "D808",	text: "OTRAS INMUNODEFICIENCIAS CON PREDOMINIO DE DEFECTOS DE LOS ANTICUERPOS"},
{ value: "D809",	text: "INMUNODEFICIENCIA CON PREDOMINIO DE DEFECTOS DE LOS ANTICUERPOS, NO ESPECIFICADA"},
{ value: "D810",	text: "INMUNODEFICIENCIA COMBINADA SEVERA [IDCS] CON DISGENESIA RETICULAR"},
{ value: "D811",	text: "INMUNODEFICIENCIA COMBINADA SEVERA [IDCS] CON LINFOCITOPENIA T Y B"},
{ value: "D812",	text: "INMUNODEFICIENCIA COMBINADA SEVERA [IDCS] CON CIFRA BAJA O NORMAL DE LINFOCITOS B"},
{ value: "D813",	text: "DEFICIENCIA DE LA ADENOSINA DEAMINASA [ADA]"},
{ value: "D814",	text: "SINDROME DE NEZELOF"},
{ value: "D815",	text: "DEFICIENCIA DE LA FOSFORILASA PURINONUCLEOSIDA [FPN]"},
{ value: "D816",	text: "DEFICIENCIA DE LA CLASE I DEL COMPLEJO DE HISTOCOMPATIBILIDAD MAYOR"},
{ value: "D817",	text: "DEFICIENCIA DE LA CLASE II DEL COMPLEJO DE HISTOCOMPATIBILIDAD MAYOR"},
{ value: "D818",	text: "OTRAS INMUNODEFICIENCIAS COMBINADAS"},
{ value: "D819",	text: "INMUNODEFICIENCIA COMBINADA, NO ESPECIFICADA"},
{ value: "D820",	text: "SINDROME DE WISKOTT-ALDRICH"},
{ value: "D821",	text: "SINDROME DE DI GEORGE"},
{ value: "D822",	text: "INMUNODEFICIENCIA CON ENANISMO MICROMELICO [MIEMBROS CORTOS]"},
{ value: "D823",	text: "INMUNODEFICIENCIA CONSECUTIVA A RESPUESTA DEFECTUOSA HEREDITARIA CONTRA EL VIRUS DE EPSTEIN-BARR"},
{ value: "D824",	text: "SINDROME DE HIPERINMUNOGLOBULINA E [IgE]"},
{ value: "D828",	text: "INMUNODEFICIENCIA ASOCIADA CON OTROS DEFECTOS MAYORES ESPECIFICADOS"},
{ value: "D829",	text: "INMUNODEFICIENCIA ASOCIADA CON OTROS DEFECTOS MAYORES NO ESPECIFICADOS"},
{ value: "D830",	text: "INMUNODEFICIENCIA VARIABLE COMUN CON PREDOMINIO DE ANORMALIDADES EN EL NUMERO Y LA FUNCION DE LOS LINFOCITOS B"},
{ value: "D831",	text: "INMUNODEFICIENCIA VARIABLE COMUN CON PREDOMINIO DE TRASTORNOS INMUNORREGULADORES DE LOS LINFOCITOS T"},
{ value: "D832",	text: "INMUNODEFICIENCIA VARIABLE COMUN CON AUTOANTICUERPOS ANTI-B O ANTI- T"},
{ value: "D838",	text: "OTRAS INMUNODEFICIENCIAS VARIABLES COMUNES"},
{ value: "D839",	text: "INMUNODEFICIENCIA VARIABLE COMUN, NO ESPECIFICADA"},
{ value: "D840",	text: "DEFECTO DE LA FUNCION DEL ANTIGENO-1 DEL LINFOCITO [LFA-1]"},
{ value: "D841",	text: "DEFECTO DEL SISTEMA DEL COMPLEMENTO"},
{ value: "D848",	text: "OTRAS INMUNODEFICIENCIAS ESPECIFICADAS"},
{ value: "D849",	text: "INMUNODEFICIENCIA, NO ESPECIFICADA"},
{ value: "D860",	text: "SARCOIDOSIS DEL PULMON"},
{ value: "D861",	text: "SARCOIDOSIS DE LOS GANGLIOS LINFÁTICOS"},
{ value: "D862",	text: "SARCOIDOSIS DEL PULMON Y DE LOS GANGLIOS LINFATICOS"},
{ value: "D863",	text: "SARCOIDOSIS DE LA PIEL"},
{ value: "D868",	text: "SARCOIDOSIS DE OTROS SITIOS ESPECIFICADOS O DE SITIOS COMBINADOS"},
{ value: "D869",	text: "SARCOIDOSIS DE SITIO NO ESPECIFICADO"},
{ value: "D890",	text: "HIPERGAMMAGLOBULINEMIA POLICLONAL"},
{ value: "D891",	text: "CRIOGLOBULINEMIA"},
{ value: "D892",	text: "HIPERGAMMAGLOBULINEMIA, NO ESPECIFICADA"},
{ value: "D898",	text: "OTROS TRASTORNOS ESPECIFICADOS QUE AFECTAN EL MECANISMO DE LA INMUNIDAD, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "D899",	text: "TRASTORNO QUE AFECTA AL MECANISMO DE LA INMUNIDAD, NO ESPECIFICADO"},
{ value: "E000",	text: "SINDROME CONGENITO DE DEFICIENCIA DE YODO, TIPO NEUROLOGICO"},
{ value: "E001",	text: "SINDROME DE DEFICIENCIA CONGENITA DE YODO, TIPO MIXEDEMATOSO"},
{ value: "E002",	text: "SINDROME CONGENITO DE DEFICIENCIA DE YODO, TIPO MIXTO"},
{ value: "E009",	text: "SINDROME CONGENITO DE DEFICIENCIA DE YODO, NO ESPECIFICADO"},
{ value: "E010",	text: "BOCIO DIFUSO (ENDEMICO) RELACIONADO CON DEFICIENCIA DE YODO"},
{ value: "E011",	text: "BOCIO MULTINODULAR (ENDEMICO) RELACIONADO CON DEFICIENCIA DE YODO"},
{ value: "E012",	text: "BOCIO (ENDEMICO) RELACIONADO CON DEFICIENCIA DE YODO, NO ESPECIFICADO"},
{ value: "E018",	text: "OTROS TRASTORNOS DE LA TIROIDES RELACIONADOS CON DEFICIENCIA DE YODO Y AFECCIONES SIMILARES"},
{ value: "E02X",	text: "HIPOTIROIDISMO SUBCLINICO POR DEFICIENCIA DE YODO"},
{ value: "E030",	text: "HIPOTIROIDISMO CONGENITO CON BOCIO DIFUSO"},
{ value: "E031",	text: "HIPOTIROIDISMO CONGENITO SIN BOCIO"},
{ value: "E032",	text: "HIPOTIROIDISMO DEBIDO A MEDICAMENTOS Y A OTRAS SUSTANCIAS EXOGENAS"},
{ value: "E033",	text: "HIPOTIROIDISMO POSTINFECCIOSO"},
{ value: "E034",	text: "ATROFIA DE TIROIDES (ADQUIRIDA)"},
{ value: "E035",	text: "COMA MIXEDEMATOSO"},
{ value: "E038",	text: "OTROS HIPOTIROIDISMOS ESPECIFICADOS"},
{ value: "E039",	text: "HIPOTIROIDISMO, NO ESPECIFICADO"},
{ value: "E040",	text: "BOCIO DIFUSO NO TOXICO"},
{ value: "E041",	text: "NODULO TIROIDEO SOLITARIO NO TOXICO"},
{ value: "E042",	text: "BOCIO MULTINODULAR NO TOXICO"},
{ value: "E048",	text: "OTROS BOCIOS NO TOXICOS ESPECIFICADOS"},
{ value: "E049",	text: "BOCIO NO TOXICO, NO ESPECIFICADO"},
{ value: "E050",	text: "TIROTOXICOSIS CON BOCIO DIFUSO"},
{ value: "E051",	text: "TIROTOXICOSIS CON NODULO SOLITARIO TIROIDEO TOXICO"},
{ value: "E052",	text: "TIROTOXICOSIS CON BOCIO MULTINODULAR TOXICO"},
{ value: "E053",	text: "TIROTOXICOSIS POR TEJIDO TIROIDEO ECTOPICO"},
{ value: "E054",	text: "TIROTOXICOSIS FACTICIA"},
{ value: "E055",	text: "CRISIS O TORMENTA TIROTOXICA"},
{ value: "E058",	text: "OTRAS TIROTOXICOSIS"},
{ value: "E059",	text: "TIROTOXICOSIS, NO ESPECIFICADA"},
{ value: "E060",	text: "TIROIDITIS AGUDA"},
{ value: "E061",	text: "TIROIDITIS SUBAGUDA"},
{ value: "E062",	text: "TIROIDITIS CRONICA CON TIROTOXICOSIS TRANSITORIA"},
{ value: "E063",	text: "TIROIDITIS AUTOINMUNE"},
{ value: "E064",	text: "TIROIDITIS INDUCIDA POR DROGAS"},
{ value: "E065",	text: "OTRAS TIROIDITIS CRONICAS"},
{ value: "E069",	text: "TIROIDITIS, NO ESPECIFICADA"},
{ value: "E070",	text: "HIPERSECRECION DE CALCITONINA"},
{ value: "E071",	text: "BOCIO DISHORMOGENETICO"},
{ value: "E078",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA GLANDULA TIROIDES"},
{ value: "E079",	text: "TRASTORNO DE LA GLANDULA TIROIDES, NO ESPECIFICADO"},
{ value: "E100",	text: "DIABETES MELLITUS INSULINODEPENDIENTE CON COMA"},
{ value: "E101",	text: "DIABETES MELLITUS INSULINODEPENDIENTE CON CETOACIDOSIS"},
{ value: "E102",	text: "DIABETES MELLITUS INSULINODEPENDIENTE CON COMPLICACIONES RENALES"},
{ value: "E103",	text: "DIABETES MELLITUS INSULINODEPENDIENTE CON COMPLICACIONES OFTALMICAS"},
{ value: "E104",	text: "DIABETES MELLITUS INSULINODEPENDIENTE CON COMPLICACIONES NEUROLOGICAS"},
{ value: "E105",	text: "DIABETES MELLITUS INSULINODEPENDIENTE CON COMPLICACIONES CIRCULATORIAS PERIFERICAS"},
{ value: "E106",	text: "DIABETES MELLITUS INSULINODEPENDIENTE CON OTRAS COMPLICACIONES ESPECIFICADAS"},
{ value: "E107",	text: "DIABETES MELLITUS INSULINODEPENDIENTE CON COMPLICACIONES MULTIPLES"},
{ value: "E108",	text: "DIABETES MELLITUS INSULINODEPENDIENTE CON COMPLICACIONES NO ESPECIFICADAS"},
{ value: "E109",	text: "DIABETES MELLITUS INSULINODEPENDIENTE SIN MENCION DE COMPLICACION"},
{ value: "E110",	text: "DIABETES MELLITUS NO INSULINODEPENDIENTE CON COMA"},
{ value: "E111",	text: "DIABETES MELLITUS NO INSULINODEPENDIENTE CON CETOACIDOSIS"},
{ value: "E112",	text: "DIABETES MELLITUS NO INSULINODEPENDIENTE CON COMPLICACIONES RENALES"},
{ value: "E113",	text: "DIABETES MELLITUS NO INSULINODEPENDIENTE CON COMPLICACIONES OFTALMICAS"},
{ value: "E114",	text: "DIABETES MELLITUS NO INSULINODEPENDIENTE CON COMPLICACIONES NEUROLOGICAS"},
{ value: "E115",	text: "DIABETES MELLITUS NO INSULINODEPENDIENTE CON COMPLICACIONES CIRCULATORIAS PERIFERICAS"},
{ value: "E116",	text: "DIABETES MELLITUS NO INSULINODEPENDIENTE CON OTRAS COMPLICACIONES ESPECIFICADAS"},
{ value: "E117",	text: "DIABETES MELLITUS NO INSULINODEPENDIENTE CON COMPLICACIONES MULTIPLES"},
{ value: "E118",	text: "DIABETES MELLITUS NO INSULINODEPENDIENTE CON COMPLICACIONES NO ESPECIFICADAS"},
{ value: "E119",	text: "DIABETES MELLITUS NO INSULINODEPENDIENTE SIN MENCION DE COMPLICACION"},
{ value: "E120",	text: "DIABETES MELLITUS ASOCIADA CON DESNUTRICION CON COMA"},
{ value: "E121",	text: "DIABETES MELLITUS ASOCIADA CON DESNUTRICION CON CETOACIDOSIS"},
{ value: "E122",	text: "DIABETES MELLITUS ASOCIADA CON DESNUTRICION CON COMPLICACIONES RENALES"},
{ value: "E123",	text: "DIABETES MELLITUS ASOCIADA CON DESNUTRICION CON COMPLICACIONES OFTALMICAS"},
{ value: "E124",	text: "DIABETES MELLITUS ASOCIADA CON DESNUTRICION CON COMPLICACIONES NEUROLOGICAS"},
{ value: "E125",	text: "DIABETES MELLITUS ASOCIADA CON DESNUTRICION CON COMPLICACIONES CIRCULATORIAS PERIFERICAS"},
{ value: "E126",	text: "DIABETES MELLITUS ASOCIADA CON DESNUTRICION CON OTRAS COMPLICACIONES ESPECIFICADAS"},
{ value: "E127",	text: "DIABETES MELLITUS ASOCIADA CON DESNUTRICION CON COMPLICACIONES MULTIPLES"},
{ value: "E128",	text: "DIABETES MELLITUS ASOCIADA CON DESNUTRICION CON COMPLICACIONES NO ESPECIFICADAS"},
{ value: "E129",	text: "DIABETES MELLITUS ASOCIADA CON DESNUTRICION SIN MENCION DE COMPLICACION"},
{ value: "E130",	text: "OTRAS DIABETES MELLITUS ESPECIFICADAS CON COMA"},
{ value: "E131",	text: "OTRAS DIABETES MELLITUS ESPECIFICADAS CON CETOACIDOSIS"},
{ value: "E132",	text: "OTRAS DIABETES MELLITUS ESPECIFICADAS CON COMPLICACIONES RENALES"},
{ value: "E133",	text: "OTRAS DIABETES MELLITUS ESPECIFICADAS CON COMPLICACIONES OFTALMICAS"},
{ value: "E134",	text: "OTRAS DIABETES MELLITUS ESPECIFICADAS CON COMPLICACIONES NEUROLOGICAS"},
{ value: "E135",	text: "OTRAS DIABETES MELLITUS ESPECIFICADAS CON COMPLICACIONES CIRCULATORIAS PERIFERICAS"},
{ value: "E136",	text: "OTRAS DIABETES MELLITUS ESPECIFICADAS CON OTRAS COMPLICACIONES ESPECIFICADAS"},
{ value: "E137",	text: "OTRAS DIABETES MELLITUS ESPECIFICADAS CON COMPLICACIONES MULTIPLES"},
{ value: "E138",	text: "OTRAS DIABETES MELLITUS ESPECIFICADAS CON COMPLICACIONES NO ESPECIFICADAS"},
{ value: "E139",	text: "OTRAS DIABETES MELLITUS ESPECIFICADAS SIN MENCION DE COMPLICACION"},
{ value: "E140",	text: "DIABETES MELLITUS, NO ESPECIFICADA CON COMA"},
{ value: "E141",	text: "DIABETES MELLITUS, NO ESPECIFICADA CON CETOACIDOSIS"},
{ value: "E142",	text: "DIABETES MELLITUS, NO ESPECIFICADA CON COMPLICACIONES RENALES"},
{ value: "E143",	text: "DIABETES MELLITUS, NO ESPECIFICADA CON COMPLICACIONES OFTALMICAS"},
{ value: "E144",	text: "DIABETES MELLITUS, NO ESPECIFICADA CON COMPLICACIONES NEUROLOGICAS"},
{ value: "E145",	text: "DIABETES MELLITUS, NO ESPECIFICADA CON COMPLICACIONES CIRCULATORIAS PERIFERICAS"},
{ value: "E146",	text: "DIABETES MELLITUS, NO ESPECIFICADA CON OTRAS COMPLICACIONES ESPECIFICADAS"},
{ value: "E147",	text: "DIABETES MELLITUS, NO ESPECIFICADA CON COMPLICACIONES MULTIPLES"},
{ value: "E148",	text: "DIABETES MELLITUS, NO ESPECIFICADA CON COMPLICACIONES NO ESPECIFICADAS"},
{ value: "E149",	text: "DIABETES MELLITUS, NO ESPECIFICADA SIN MENCION DE COMPLICACION"},
{ value: "E15X",	text: "COMA HIPOGLICEMICO NO DIABETICO"},
{ value: "E160",	text: "HIPOGLICEMIA SIN COMA, INDUCIDA POR DROGAS"},
{ value: "E161",	text: "OTRAS HIPOGLICEMIAS"},
{ value: "E162",	text: "HIPOGLICEMIA , NO ESPECIFICADA"},
{ value: "E163",	text: "SECRECION EXAGERADA DEL GLUCAGON"},
{ value: "E164",	text: "SECRECION ANORMAL DE GASTRINA"},
{ value: "E168",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA SECRECION INTERNA DEL PANCREAS"},
{ value: "E169",	text: "TRASTORNOS DE LA SECRECION INTERNA DEL PANCREAS, SIN OTRA ESPECIFICACION"},
{ value: "E200",	text: "HIPOPARATIROIDISMO IDIOPATICO"},
{ value: "E201",	text: "PSEUDOHIPOPARATIROIDISMO"},
{ value: "E208",	text: "OTROS TIPOS DE HIPOPARATIROIDISMO"},
{ value: "E209",	text: "HIPOPARATIROIDISMO, NO ESPECIFICADO"},
{ value: "E210",	text: "HIPERPARATIROIDISMO PRIMARIO"},
{ value: "E211",	text: "HIPERPARATIROIDISMO SECUNDARIO NO CLASIFICADO EN OTRA PARTE"},
{ value: "E212",	text: "OTROS TIPOS DE HIPERPARATIROIDISMO"},
{ value: "E213",	text: "HIPERPARATIROIDISMO, SIN OTRA ESPECIFICACION"},
{ value: "E214",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA GLANDULA PARATIROIDES"},
{ value: "E215",	text: "TRASTORNO DE LA GLANDULA PARATIROIDES, NO ESPECIFICADO"},
{ value: "E220",	text: "ACROMEGALIA Y GIGANTISMO HIPOFISARIO"},
{ value: "E221",	text: "HIPERPROLACTINEMIA"},
{ value: "E222",	text: "SINDROME DE SECRECION INAPROPIADA DE HORMONA ANTIDIURETICA"},
{ value: "E228",	text: "OTRAS HIPERFUNCIONES DE LA GLANDULA HIPOFISIS"},
{ value: "E229",	text: "HIPERFUNCION DE LA GLANDULA HIPOFISIS, NO ESPECIFICADA"},
{ value: "E230",	text: "HIPOPITUITARISMO"},
{ value: "E231",	text: "HIPOPITUITARISMO INDUCIDO POR DROGAS"},
{ value: "E232",	text: "DIABETES INSIPIDA"},
{ value: "E233",	text: "DISFUNCION HIPOTALAMICA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "E236",	text: "OTROS TRASTORNOS DE LA GLANDULA HIPOFISIS"},
{ value: "E237",	text: "TRASTORNO DE LA GLANDULA HIPOFISIS, NO ESPECIFICADO"},
{ value: "E240",	text: "ENFERMEDAD DE CUSHING DEPENDIENTE DE LA HIPOFISIS"},
{ value: "E241",	text: "SINDROME DE NELSON"},
{ value: "E242",	text: "SINDROME DE CUSHING INDUCIDO POR DROGAS"},
{ value: "E243",	text: "SINDROME DE ACTH ECTOPICO"},
{ value: "E244",	text: "SINDROME DE SEUDO-CUSHING INDUCIDO POR ALCOHOL"},
{ value: "E248",	text: "OTROS TIPOS DE SINDROME DE CUSHING"},
{ value: "E249",	text: "SINDROME DE CUSHING, NO ESPECIFICADO"},
{ value: "E250",	text: "TRASTORNOS ADRENOGENITALES CONGENITOS CON DEFICIENCIA ENZIMATICA"},
{ value: "E258",	text: "OTROS TRASTORNOS ADRENOGENITALES"},
{ value: "E259",	text: "TRASTORNO ADRENOGENITAL, NO ESPECIFICADO"},
{ value: "E260",	text: "HIPERALDOSTERONISMO PRIMARIO"},
{ value: "E261",	text: "HIPERALDOSTERONISMO SECUNDARIO"},
{ value: "E268",	text: "OTROS TIPOS DE HIPERALDOSTERONISMO"},
{ value: "E269",	text: "HIPERALDOSTERONISMO, NO ESPECIFICADO"},
{ value: "E270",	text: "OTRA HIPERACTIVIDAD CORTICOSUPRARRENAL"},
{ value: "E271",	text: "INSUFICIENCIA CORTICOSUPRARRENAL PRIMARIA"},
{ value: "E272",	text: "CRISIS ADDISONIANA"},
{ value: "E273",	text: "INSUFICIENCIA CORTICOSUPRARRENAL INDUCIDA POR DROGAS"},
{ value: "E274",	text: "OTRAS INSUFICIENCIAS CORTICOSUPRARRENALES Y LAS NO ESPECIFICADAS"},
{ value: "E275",	text: "HIPERFUNCION DE LA MEDULA SUPRARRENAL"},
{ value: "E278",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA GLANDULA SUPRARRENAL"},
{ value: "E279",	text: "TRASTORNO DE LA GLANDULA SUPRARRENAL, NO ESPECIFICADO"},
{ value: "E280",	text: "EXCESO DE ESTROGENOS"},
{ value: "E281",	text: "EXCESO DE ANDROGENOS"},
{ value: "E282",	text: "SINDROME DE OVARIO POLIQUISTICO"},
{ value: "E283",	text: "INSUFICIENCIA OVARICA PRIMARIA"},
{ value: "E288",	text: "OTRAS DISFUNCIONES OVARICAS"},
{ value: "E289",	text: "DISFUNCION OVARICA, NO ESPECIFICADA"},
{ value: "E290",	text: "HIPERFUNCION TESTICULAR"},
{ value: "E291",	text: "HIPOFUNCION TESTICULAR"},
{ value: "E298",	text: "OTRAS DISFUNCIONES TESTICULARES"},
{ value: "E299",	text: "DISFUNCION TESTICULAR, NO ESPECIFICADA"},
{ value: "E300",	text: "PUBERTAD RETARDADA"},
{ value: "E301",	text: "PUBERTAD PRECOZ"},
{ value: "E308",	text: "OTROS TRASTORNOS DE LA PUBERTAD"},
{ value: "E309",	text: "TRASTORNO DE LA PUBERTAD, NO ESPECIFICADO"},
{ value: "E310",	text: "INSUFICIENCIA PILOGLANDULAR AUTOINMUNE"},
{ value: "E311",	text: "HIPERFUNCION POLIGLANDULAR"},
{ value: "E318",	text: "OTRAS DISFUNCIONES POLIGLANDULARES"},
{ value: "E319",	text: "DISFUNCION POLIGLANDULAR, NO ESPECIFICADA"},
{ value: "E320",	text: "HIPERPLASIA PERSISTENTE DEL TIMO"},
{ value: "E321",	text: "ABSCESO DEL TIMO"},
{ value: "E328",	text: "OTRAS ENFERMEDADES DEL TIMO"},
{ value: "E329",	text: "ENFERMEDAD DEL TIMO, NO ESPECIFICADA"},
{ value: "E340",	text: "SINDROME CARCINOIDE"},
{ value: "E341",	text: "OTRAS HIPERSECRECIONES DE HORMONAS INTESTINALES"},
{ value: "E342",	text: "SECRECION HORMONAL ECTOPICA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "E343",	text: "ENANISMO, NO CLASIFICADO EN OTRA PARTE"},
{ value: "E344",	text: "ESTATURA ALTA CONSTITUCIONAL"},
{ value: "E345",	text: "SINDROME DE RESISTENCIA ANDROGENICA"},
{ value: "E348",	text: "OTROS TRASTORNOS ENDOCRINOS ESPECIFICADOS"},
{ value: "E349",	text: "TRASTORNO ENDOCRINO, NO ESPECIFICADO"},
{ value: "E350",	text: "TRASTORNOS DE LA GLANDULA TIROIDES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "E351",	text: "TRASTORNOS DE LA GLANDULAS SUPRARRENALES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "E358",	text: "TRASTORNOS DE OTRAS GLANDULAS ENDOCRINAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "E40X",	text: "KWASHIORKOR"},
{ value: "E41X",	text: "MARASMO NUTRICIONAL"},
{ value: "E42X",	text: "KWASHIORKOR MARASMATICO"},
{ value: "E43X",	text: "DESNUTRICION PROTEICOCALORICA SEVERA, NO ESPECIFICADA"},
{ value: "E440",	text: "DESNUTRICION PROTEICOCALORICA MODERADA"},
{ value: "E441",	text: "DESNUTRICION PROTEICOCALORICA LEVE"},
{ value: "E45X",	text: "RETARDO DEL DESARROLLO DEBIDO A DESNUTRICION PROTEICOCALORICA"},
{ value: "E46X",	text: "DESNUTRICION PROTEICOCALORICA , NO ESPECIFICADA"},
{ value: "E500",	text: "DEFICIENCIA DE VITAMINA A CON XEROSIS CONJUNTIVAL"},
{ value: "E501",	text: "DEFICIENCIA DE VITAMINA A CON MANCHA DE BITOT Y XEROSIS CONJUNTIVAL"},
{ value: "E502",	text: "DEFICIENCIA DE VITAMINA A CON XEROSIS CORNEAL"},
{ value: "E503",	text: "DEFICIENCIA DE VITAMINA A CON ULCERACION CORNEAL Y XEROSIS"},
{ value: "E504",	text: "DEFICIENCIA DE VITAMINA A CON QUERATOMALACIA"},
{ value: "E505",	text: "DEFICIENCIA DE VITAMINA A CON CEGUERA NOCTURNA"},
{ value: "E506",	text: "DEFICIENCIA DE VITAMINA A CON CICATRICES XEROFTALMICAS DE LA CORNEA"},
{ value: "E507",	text: "OTRAS MANIFESTACIONES OCULARES DE DEFICIENCIA DE VITAMINA A"},
{ value: "E508",	text: "OTRAS MANIFESTACIONES DE DEFICIENCIA DE VITAMINA A"},
{ value: "E509",	text: "DEFICIENCIA DE VITAMINA A, NO ESPECIFICADA"},
{ value: "E511",	text: "BERIBERI"},
{ value: "E512",	text: "ENCEFALOPATIA DE WERNICKE"},
{ value: "E518",	text: "OTRAS MANIFESTACIONES DE LA DEFICIENCIA DE TIAMINA"},
{ value: "E519",	text: "DEFICIENCIA DE TIAMINA, NO ESPECIFICADA"},
{ value: "E52X",	text: "DEFICIENCIA DE NIACINA [PELAGRA]"},
{ value: "E530",	text: "DEFICIENCIA DE RIBOFLAVINA"},
{ value: "E531",	text: "DEFICIENCIA DE PIRIDOXINA"},
{ value: "E538",	text: "DEFICIENCIA DE OTRAS VITAMINAS DEL GRUPO B"},
{ value: "E539",	text: "DEFICIENCIA DE VITAMINA B, NO ESPECIFICADA"},
{ value: "E54X",	text: "DEFICIENCIA DE ACIDO ASCORBICO"},
{ value: "E550",	text: "RAQUITISMO ACTIVO"},
{ value: "E559",	text: "DEFICIENCIA DE VITAMINA D, NO ESPECIFICADA"},
{ value: "E560",	text: "DEFICIENCIA DE VITAMINA E"},
{ value: "E561",	text: "DEFICIENCIA DE VITAMINA K"},
{ value: "E568",	text: "DEFICIENCIA DE OTRAS VITAMINAS"},
{ value: "E569",	text: "DEFICIENCIA DE VITAMINA, NO ESPECIFICADA"},
{ value: "E58X",	text: "DEFICIENCIA DIETETICA DE CALCIO"},
{ value: "E59X",	text: "DEFICIENCIA DIETETICA DE SELENIO"},
{ value: "E60X",	text: "DEFICIENCIA DIETETICA DE ZINC"},
{ value: "E610",	text: "DEFICIENCIA DE COBRE"},
{ value: "E611",	text: "DEFICIENCIA DE HIERRO"},
{ value: "E612",	text: "DEFICIENCIA DE MAGNESIO"},
{ value: "E613",	text: "DEFICIENCIA DE MANGANESO"},
{ value: "E614",	text: "DEFICIENCIA DE CROMO"},
{ value: "E615",	text: "DEFICIENCIA DE MOLIBDENO"},
{ value: "E616",	text: "DEFICIENCIA DE VANADIO"},
{ value: "E617",	text: "DEFICIENCIA DE MULTIPLES ELEMENTOS NUTRICIONALES"},
{ value: "E618",	text: "DEFICIENCIA DE OTROS ELEMENTOS NUTRICIONALES ESPECIFICADOS"},
{ value: "E619",	text: "DEFICIENCIA DE OTRO ELEMENTO NUTRICIONAL, NO ESPECIFICADO"},
{ value: "E630",	text: "DEFICIENCIA DE ACIDOS GRASOS ESENCIALES [AGE]"},
{ value: "E631",	text: "DESEQUILIBRIO DE LOS CONSTITUYENTES EN LA DIETA"},
{ value: "E638",	text: "OTRAS DEFICIENCIAS NUTRICIONALES ESPECIFICADAS"},
{ value: "E639",	text: "DEFICIENCIA NUTRICIONAL, NO ESPECIFICADA"},
{ value: "E640",	text: "SECUELAS DE LA DESNUTRICION PROTEICOCALORICA"},
{ value: "E641",	text: "SECUELAS DE LA DEFICIENCIA DE VITAMINA A"},
{ value: "E642",	text: "SECUELAS DE LA DEFICIENCIA DE VITAMINA C"},
{ value: "E643",	text: "SECUELAS DEL RAQUITISMO"},
{ value: "E648",	text: "SECUELAS DE OTRAS DEFICIENCIAS NUTRICIONALES"},
{ value: "E649",	text: "SECUELAS DE LA DEFICIENCIA NUTRICIONAL NO ESPECIFICADA"},
{ value: "E65X",	text: "ADIPOSIDAD LOCALIZADA"},
{ value: "E660",	text: "OBESIDAD DEBIDA A EXCESO DE CALORIAS"},
{ value: "E661",	text: "OBESIDAD INDUCIDA POR DROGAS"},
{ value: "E662",	text: "OBESIDAD EXTREMA CON HIPOVENTILACION ALVEOLAR"},
{ value: "E668",	text: "OTROS TIPOS DE OBESIDAD"},
{ value: "E669",	text: "OBESIDAD, NO ESPECIFICADA"},
{ value: "E670",	text: "HIPERVITAMINOSIS A"},
{ value: "E671",	text: "HIPERCAROTINEMIA"},
{ value: "E672",	text: "SINDROME DE MEGAVITAMINA B6"},
{ value: "E673",	text: "HIPERVITAMINOSIS D"},
{ value: "E678",	text: "OTROS TIPOS DE HIPERALIMENTACION ESPECIFICADOS"},
{ value: "E68X",	text: "SECUELAS DE HIPERALIMENTACION"},
{ value: "E700",	text: "FENILCETONURIA CLASICA"},
{ value: "E701",	text: "OTRAS HIPERFENILALANINEMIAS"},
{ value: "E702",	text: "TRASTORNOS DEL METABOLISMO DE LA TIROSINA"},
{ value: "E703",	text: "ALBINISMO"},
{ value: "E708",	text: "OTROS TRASTORNOS DEL METABOLISMO DE LOS AMINOACIDOS AROMATICOS"},
{ value: "E709",	text: "TRASTORNO DEL METABOLISMO DE LOS AMINOACIDOS AROMATICOS, NO ESPECIFICADO"},
{ value: "E710",	text: "ENFERMEDAD DE LA ORINA EN JARABE DE ARCE"},
{ value: "E711",	text: "OTROS TRASTORNOS DEL METABOLISMO DE LOS AMINOACIDOS DE CADENA RAMIFICADA"},
{ value: "E712",	text: "OTROS TRASTORNOS DEL METABOLISMO DE LOS AMINOACIDOS DE CADENA RAMIFICADA, NO ESPECIFICADOS"},
{ value: "E713",	text: "TRASTORNOS DEL METABOLISMO DE LOS ACIDOS GRASOS"},
{ value: "E720",	text: "TRASTORNOS DEL TRANSPORTE DE LOS AMINOACIDOS"},
{ value: "E721",	text: "TRASTORNO DEL METABOLISMO DE LOS AMINOACIDOS AZUFRADOS"},
{ value: "E722",	text: "TRASTORNOS DEL METABOLISMO DEL CICLO DE LA UREA"},
{ value: "E723",	text: "TRASTORNOS DEL METABOLISMO DE LA LISINA Y LA HIDROXILISINA"},
{ value: "E724",	text: "TRASTORNO DEL METABOLISMO DE LA ORNITINA"},
{ value: "E725",	text: "TRASTORNO DEL METABOLISMO DE LA GLICINA"},
{ value: "E728",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL METABOLISMO DE LOS AMINOACIDOS"},
{ value: "E729",	text: "TRASTORNO DEL METABOLISMO DE LOS AMINOACIDOS, NO ESPECIFICADO"},
{ value: "E730",	text: "DEFICIENCIA CONGENITA DE LACTASA"},
{ value: "E731",	text: "DEFICIENCIA SECUNDARIA DE LACTASA"},
{ value: "E738",	text: "OTROS TIPOS DE INTOLERANCIA A LA LACTOSA"},
{ value: "E739",	text: "INTOLERANCIA A LA LACTOSA, NO ESPECIFICADA"},
{ value: "E740",	text: "ENFERMEDAD DEL ALMACENAMIENTO DE GLUCOGENO"},
{ value: "E741",	text: "TRASTORNOS DEL METABOLISMO DE LA FRUCTOSA"},
{ value: "E742",	text: "TRASTORNO DEL METABOLISMO DE LA GALACTOSA"},
{ value: "E743",	text: "OTROS TRASTORNOS DE LA ABSORCION INTESTINAL DE CARBOHIDRATOS"},
{ value: "E744",	text: "TRASTORNOS DEL METABOLISMO DEL PIRUVATO Y DE LAS GLUCONEOGENESIS"},
{ value: "E748",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL METABOLISMO DE LOS CARBOHIDRATOS"},
{ value: "E749",	text: "TRASTORNO DEL METABOLISMO DE LOS CARBOHIDRATOS, NO ESPECIFICADO"},
{ value: "E750",	text: "GANGLIOSIDOSIS GM2"},
{ value: "E751",	text: "OTRAS GANGLIOSIDOSIS"},
{ value: "E752",	text: "OTRAS ESFINGOLIPIDOSIS"},
{ value: "E753",	text: "ESFINGOLIPIDOSIS, NO ESPECIFICADA"},
{ value: "E754",	text: "LIPOFUSCINOSIS CEROIDE NEURONAL"},
{ value: "E755",	text: "OTROS TRASTORNOS DEL ALMACENAMIENTO DE LIPIDOS"},
{ value: "E756",	text: "TRASTORNOS DE ALMACENAMIENTO DE LIPIDOS, NO ESPECIFICADO"},
{ value: "E760",	text: "MUCOPOLISACARIDOSIS TIPO I"},
{ value: "E761",	text: "MUCOPOLISACARIDOSIS TIPO II"},
{ value: "E762",	text: "OTRAS MUCOPOLISACARIDOSIS"},
{ value: "E763",	text: "MUCOPOLISACARIDOSIS NO ESPECIFICADA"},
{ value: "E768",	text: "OTRO TRASTORNOS DEL METABOLISMO DE LOS GLUCOSAMINOGLICANOS"},
{ value: "E769",	text: "TRASTORNO DEL METABOLISMO DE LOS GLUCOSAMINOGLICANOS, NO ESPECIFICADO"},
{ value: "E770",	text: "DEFECTOS EN LA MODIFICACION POSTRASLACIONAL DE ENZIMAS LISOSOMALES"},
{ value: "E771",	text: "DEFECTOS DE LA DEGRADACION DE GLUCOPROTEINAS"},
{ value: "E778",	text: "OTROS TRASTORNOS DEL METABOLISMO DE LAS GLUCOPROTEINAS"},
{ value: "E779",	text: "TRASTORNO DEL METABOLISMO DE LAS GLUCOPROTEINAS, NO ESPECIFICADO"},
{ value: "E780",	text: "HIPERCOLESTEROLEMIA PURA"},
{ value: "E781",	text: "HIPERGLICERIDEMIA PURA"},
{ value: "E782",	text: "HIPERLIPIDEMIA MIXTA"},
{ value: "E783",	text: "HIPERQUILOMICRONEMIA"},
{ value: "E784",	text: "OTRA HIPERLIPIDEMIA"},
{ value: "E785",	text: "HIPERLIPIDEMIA NO ESPECIFICADA"},
{ value: "E786",	text: "DEFICIENCIA DE LIPOPROTEINAS"},
{ value: "E788",	text: "OTROS TRASTORNOS DEL METABOLISMO DE LAS LIPOPROTEINAS"},
{ value: "E789",	text: "TRASTORNO DEL METABOLISMO DE LAS LIPOPROTEINAS, NO ESPECIFICADO"},
{ value: "E790",	text: "HIPERURICEMIA SIN SIGNOS DE ARTRITIS INFLAMATORIA Y ENFERMEDAD TOFACEA"},
{ value: "E791",	text: "SINDROME DE LESCH-NYHAN"},
{ value: "E798",	text: "OTROS TRASTORNOS DEL METABOLISMO DE LAS PURINAS Y DE LAS PIRIMIDINAS"},
{ value: "E799",	text: "TRASTORNOS DEL METABOLISMO DE LAS PURINAS Y DE LAS PIRIMIDINAS, NO ESPECIFICADO"},
{ value: "E800",	text: "PORFIRIA ERITROPOYETICA HEREDITARIA"},
{ value: "E801",	text: "PORFIRIA CUTANEA TARDIA"},
{ value: "E802",	text: "OTRAS PORFIRIAS"},
{ value: "E803",	text: "DEFECTOS DE CATALASA Y PEROXIDASA"},
{ value: "E804",	text: "SINDROME DE GILBERT"},
{ value: "E805",	text: "SINDROME DE CRIGLER-NAJJAR"},
{ value: "E806",	text: "OTROS TRASTORNOS DEL METABOLISMO DE LA BILIRRUBINA"},
{ value: "E807",	text: "TRASTORNOS DEL METABOLISMO DE LA BILIRRUBINA, NO ESPECIFICADO"},
{ value: "E830",	text: "TRASTORNOS DEL METABOLISMO DEL COBRE"},
{ value: "E831",	text: "TRASTORNOS DEL METABOLISMO DEL HIERRO"},
{ value: "E832",	text: "TRASTORNOS DEL METABOLISMO DEL ZINC"},
{ value: "E833",	text: "TRASTORNOS DEL METABOLISMO DEL FOSFORO"},
{ value: "E834",	text: "TRASTORNOS DEL METABOLISMO DEL MAGNESIO"},
{ value: "E835",	text: "TRASTORNOS DEL METABOLISMO DEL CALCIO"},
{ value: "E838",	text: "OTROS TRASTORNOS DEL METABOLISMO DE LOS MINERALES"},
{ value: "E839",	text: "TRASTORNO DEL METABOLISMO DE LOS MINERALES, NO ESPECIFICADO"},
{ value: "E840",	text: "FIBROSIS QUISTICA CON MANIFESTACIONES PULMONARES"},
{ value: "E841",	text: "FIBROSIS QUISTICA CON MANIFESTACIONES INTESTINALES"},
{ value: "E848",	text: "FIBROSIS QUISTICA CON OTRAS MANIFESTACIONES"},
{ value: "E849",	text: "FIBROSIS QUISTICA, SIN OTRA ESPECIFICACION"},
{ value: "E850",	text: "AMILOIDOSIS HEREDOFAMILIAR NO NEUROPATICA"},
{ value: "E851",	text: "AMILOIDOSIS HEREDOFAMILIAR NEUROPATICA"},
{ value: "E852",	text: "AMILOIDOSIS HEREDOFAMILIAR, NO ESPECIFICADA"},
{ value: "E853",	text: "AMILOIDOSIS SISTEMICA SECUNDARIA"},
{ value: "E854",	text: "AMILOIDOSIS LIMITADA A UN ORGANO"},
{ value: "E858",	text: "OTRAS AMILOIDOSIS"},
{ value: "E859",	text: "AMILOIDOSIS, NO ESPECIFICADA"},
{ value: "E86X",	text: "DEPLECION DEL VOLUMEN"},
{ value: "E870",	text: "HIPEROSMOLARIDAD E HIPERNATREMIA"},
{ value: "E871",	text: "HIPOSMOLARIDAD E HIPERNATREMIA"},
{ value: "E872",	text: "ACIDOSIS"},
{ value: "E873",	text: "ALCALOSIS"},
{ value: "E874",	text: "TRASTORNOS MIXTOS DEL BALANCE ACIDO-BASICO"},
{ value: "E875",	text: "HIPERPOTASEMIA"},
{ value: "E876",	text: "HIPOPOTASMIA"},
{ value: "E877",	text: "SOBRECARGA DE LIQUIDOS"},
{ value: "E878",	text: "OTROS TRASTORNOS DEL EQUILIBRIO DE LOS ELECTROLITOS Y DE LOS LIQUIDOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "E880",	text: "TRASTORNOS DEL METABOLISMO DE LAS PROTEINAS PLASMATICAS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "E881",	text: "LIPODISTROFIA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "E882",	text: "LIPOMATOSIS, NO CLASIFICADA EN OTRA PARTE"},
{ value: "E888",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL METABOLISMO"},
{ value: "E889",	text: "TRASTORNO METABOLICO, NO ESPECIFICADO"},
{ value: "E890",	text: "HIPOTIROIDISMO CONSECUTIVO A PROCEDIMIENTOS"},
{ value: "E891",	text: "HIPOINSULINEMIA CONSECUTIVA A PROCEDIMIENTOS"},
{ value: "E892",	text: "HIPOPARATIROIDISMO CONSECUTIVO A PROCEDIMIENTOS"},
{ value: "E893",	text: "HIPOPITUITARISMO CONSECUTIVO A PROCEDIMIENTOS"},
{ value: "E894",	text: "INSUFICIENCIA OVARICA CONSECUTIVA A PROCEDIMIENTOS"},
{ value: "E895",	text: "HIPOFUNCION TESTICULAR CONSECUTIVA A PROCEDIMIENTOS"},
{ value: "E896",	text: "HIPOFUNCION ADRENOCORTICAL [MEDULA SUPRARRENAL] CONSECUTIVA A PROCEDIMIENTOS"},
{ value: "E898",	text: "OTROS TRASTORNOS METABOLICOS Y ENDOCRINOS CONSECUTIVO A PROCEDIMIENTOS"},
{ value: "E899",	text: "TRASTORNO ENDOCRINO Y METABOLICO CONSECUTIVO A PROCEDIMIENTOS, NO ESPECIFICADO"},
{ value: "E90X",	text: "TRASTORNOS NUTRICIONALES Y METABOLICOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "F000",	text: "DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER, DE COMIENZO TEMPRANO (G30.0†)"},
{ value: "F001",	text: "DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER, DE COMIENZO TARDIO (G30.1†)"},
{ value: "F002",	text: "DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER, ATIPICA O DE TIPO MIXTO (G30.8†)"},
{ value: "F009",	text: "DEMENCIA EN LA ENFERMEDAD DE ALZHEIMER, NO ESPECIFICADA (G30.9†)"},
{ value: "F010",	text: "DEMENCIA VASCULAR DE COMIENZO AGUDO"},
{ value: "F011",	text: "DEMENCIA VASCULAR POR INFARTOS MULTIPLES"},
{ value: "F012",	text: "DEMENCIA VASCULAR SUBCORTICAL"},
{ value: "F013",	text: "DEMENCIA VASCULAR MIXTA, CORTICAL Y SUBCORTICAL"},
{ value: "F018",	text: "OTRAS DEMENCIAS VASCULARES"},
{ value: "F019",	text: "DEMENCIA VASCULAR, NO ESPECIFICADA"},
{ value: "F020",	text: "DEMENCIA EN LA ENFERMEDAD DE PICK (G31.0†)"},
{ value: "F021",	text: "DEMENCIA EN LA ENFERMEDAD DE CREUTZFELDT-JAKOB (A81.0†)"},
{ value: "F022",	text: "DEMENCIA EN LA ENFERMEDAD DE HUNTINGTON (G10†)"},
{ value: "F023",	text: "DEMENCIA EN LA ENFERMEDAD DE PARKINSON (G20†)"},
{ value: "F024",	text: "DEMENCIA EN LA ENFERMEDAD POR VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH] (B22.0†)"},
{ value: "F028",	text: "DEMENCIA EN OTRAS ENFERMEDADES ESPECIFICADAS CLASIFICADAS EN OTRA PARTE"},
{ value: "F03X",	text: "DEMENCIA , NO ESPECIFICADA"},
{ value: "F04X",	text: "SINDROME AMNESICO ORGANICO, NO INDUCIDO POR ACOHOL O POR OTRAS SUSTANCIAS PSICOACTIVAS"},
{ value: "F050",	text: "DELIRIO NO SUPERPUESTO A UN CUADRO DE DEMENCIA, ASI DESCRITO"},
{ value: "F051",	text: "DELIRIO SUPERPUESTO A UN CUADRO DE DEMENCIA"},
{ value: "F058",	text: "OTROS DELIRIOS"},
{ value: "F059",	text: "DELIRIO, NO ESPECIFICADO"},
{ value: "F060",	text: "ALUCINOSIS ORGANICA"},
{ value: "F061",	text: "TRASTORNO CATATONICO, ORGANICO"},
{ value: "F062",	text: "TRASTORNO DELIRANTE [ESQUIZOFRENIFORME], ORGANICO"},
{ value: "F063",	text: "TRASTORNOS DEL HUMOR [AFECTIVOS], ORGANICOS"},
{ value: "F064",	text: "TRASTORNO DE ANSIEDAD, ORGANICO"},
{ value: "F065",	text: "TRASTORNO DISOCIATIVO, ORGANICO"},
{ value: "F066",	text: "TRASTORNO DE LABILIDAD EMOCIONAL [ASTENICO], ORGANICO"},
{ value: "F067",	text: "TRASTORNO COGNOSCITIVO LEVE"},
{ value: "F068",	text: "OTROS TRASTORNOS MENTALES ESPECIFICADOS DEBIDOS A LESION Y DISFUNCION CEREBRAL Y A ENFERMEDAD FISICA"},
{ value: "F069",	text: "TRASTORNO MENTAL NO ESPECIFICADO DEBIDO A LESION Y DISFUNCION CEREBRAL Y A ENFERMEDAD FISICA"},
{ value: "F070",	text: "TRASTORNO DE LA PERSONALIDAD, ORGANICO"},
{ value: "F071",	text: "SINDROME POSTENCEFALITICO"},
{ value: "F072",	text: "SINDROME POSTCONCUSIONAL"},
{ value: "F078",	text: "OTROS TRASTORNOS ORGANICOS DE LA PERSONALIDAD Y DEL COMPORTAMIENTO DEBIDOS A ENFERMEDAD, LESION Y DISFUNCION CEREBRALES"},
{ value: "F079",	text: "TRASTORNO ORGANICO DE LA PERSONALIDAD Y DEL COMPORTAMIENTO, NO ESPECIFICADO, DEBIDO A ENFERMEDAD, LESION Y DISFUNCION CEREBRAL"},
{ value: "F09X",	text: "TRASTORNO MENTAL ORGANICO O SINTOMATICO, NO ESPECIFICADO"},
{ value: "F100",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DEL ALCOHOL: INTOXICACION AGUDA"},
{ value: "F101",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DEL ALCOHOL: USO NOCIVO"},
{ value: "F102",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DEL ALCOHOL: SINDROME DE DEPENDENCIA"},
{ value: "F103",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DEL ALCOHOL: ESTADO DE ABSTINENCIA"},
{ value: "F104",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DEL ALCOHOL: ESTADO DE ABSTINENCIA CON DELIRIO"},
{ value: "F105",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DEL ALCOHOL: TRASTORNO PSICOTICO"},
{ value: "F106",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DEL ALCOHOL: SINDROME AMNESICO"},
{ value: "F107",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DEL ALCOHOL: TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO"},
{ value: "F108",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DEL ALCOHOL: OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"},
{ value: "F109",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DEL ALCOHOL: TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"},
{ value: "F110",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIACEOS: INTOXICACION AGUDA"},
{ value: "F111",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIACEOS: USO NOCIVO"},
{ value: "F112",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIACEOS: SINDROME DE DEPENDENCIA"},
{ value: "F113",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIACEOS: ESTADO DE ABSTINENCIA"},
{ value: "F114",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIACEOS: ESTADO DE ABSTINENCIA CON DELIRIO"},
{ value: "F115",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIACEOS: TRASTORNO PSICOTICO"},
{ value: "F116",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIACEOS: SINDROME AMNESICO"},
{ value: "F117",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIACEOS: TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO"},
{ value: "F118",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIACEOS: OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"},
{ value: "F119",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OPIACEOS: TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"},
{ value: "F120",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES: INTOXICACION AGUDA"},
{ value: "F121",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES: USO NOCIVO"},
{ value: "F122",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES: SINDROME DE DEPENDENCIA"},
{ value: "F123",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES: ESTADO DE ABSTINENCIA"},
{ value: "F124",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES: ESTADO DE ABSTINENCIA CON DELIRIO"},
{ value: "F125",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES: TRASTORNO PSICOTICO"},
{ value: "F126",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES: SINDROME AMNESICO"},
{ value: "F127",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES: TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO"},
{ value: "F128",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES: OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"},
{ value: "F129",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE CANNABINOIDES: TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"},
{ value: "F130",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNOTICOS: INTOXICACION AGUDA"},
{ value: "F131",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNOTICOS: USO NOCIVO"},
{ value: "F132",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNOTICOS: SINDROME DE DEPENDENCIA"},
{ value: "F133",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNOTICOS: ESTADO DE ABSTINENCIA"},
{ value: "F134",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNOTICOS: ESTADO DE ABSTINENCIA CON DELIRIO"},
{ value: "F135",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNOTICOS: TRASTORNO PSICOTICO"},
{ value: "F136",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNOTICOS: SINDROME AMNESICO"},
{ value: "F137",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNOTICOS: TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO"},
{ value: "F138",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNOTICOS: OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"},
{ value: "F139",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE SEDANTES O HIPNOTICOS: TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"},
{ value: "F140",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAINA: INTOXICACION AGUDA"},
{ value: "F141",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAINA: USO NOCIVO"},
{ value: "F142",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAINA: SINDROME DE DEPENDENCIA"},
{ value: "F143",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAINA: ESTADO DE ABSTINENCIA"},
{ value: "F144",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAINA: ESTADO DE ABSTINENCIA CON DELIRIO"},
{ value: "F145",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAINA: TRASTORNO PSICOTICO"},
{ value: "F146",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAINA: SINDROME AMNESICO"},
{ value: "F147",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAINA: TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO"},
{ value: "F148",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAINA: OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"},
{ value: "F149",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE COCAINA: TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"},
{ value: "F150",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEINA: INTOXICACION AGUDA"},
{ value: "F151",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEINA: USO NOCIVO"},
{ value: "F152",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEINA: SINDROME DE DEPENDENCIA"},
{ value: "F153",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEINA: ESTADO DE ABSTINENCIA"},
{ value: "F154",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEINA:. ESTADO DE ABSTINENCIA CON DELIRIO"},
{ value: "F155",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEINA: TRASTORNO PSICOTICO"},
{ value: "F156",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEINA: SINDROME AMNESICO"},
{ value: "F157",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEINA: TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO"},
{ value: "F158",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEINA: OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"},
{ value: "F159",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE OTROS ESTIMULANTES, INCLUIDA LA CAFEINA: TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"},
{ value: "F160",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINOGENOS: INTOXICACION AGUDA"},
{ value: "F161",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINOGENOS: USO NOCIVO"},
{ value: "F162",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINOGENOS: SINDROME DE DEPENDENCIA"},
{ value: "F163",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINOGENOS: ESTADO DE ABSTINENCIA"},
{ value: "F164",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINOGENOS: ESTADO DE ABSTINENCIA CON DELIRIO"},
{ value: "F165",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINOGENOS: TRASTORNO PSICOTICO"},
{ value: "F166",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINOGENOS: SINDROME AMNESICO"},
{ value: "F167",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINOGENOS: TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO"},
{ value: "F168",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINOGENOS: OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"},
{ value: "F169",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE ALUCINOGENOS: TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"},
{ value: "F170",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO: INTOXICACION AGUDA"},
{ value: "F171",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO: USO NOCIVO"},
{ value: "F172",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO: SINDROME DE DEPENDENCIA"},
{ value: "F173",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO: ESTADO DE ABSTINENCIA"},
{ value: "F174",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO: ESTADO DE ABSTINENCIA CON DELIRIO"},
{ value: "F175",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO: TRASTORNO PSICOTICO"},
{ value: "F176",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO: SINDROME AMNESICO"},
{ value: "F177",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO: TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO"},
{ value: "F178",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO: OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"},
{ value: "F179",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE TABACO: TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"},
{ value: "F180",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLATILES: INTOXICACION AGUDA"},
{ value: "F181",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLATILES: USO NOCIVO"},
{ value: "F182",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLATILES: SINDROME DE DEPENDENCIA"},
{ value: "F183",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLATILES: ESTADO DE ABSTINENCIA"},
{ value: "F184",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLATILES: ESTADO DE ABSTINENCIA CON DELIRIO"},
{ value: "F185",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLATILES: TRASTORNO PSICOTICO"},
{ value: "F186",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLATILES: SINDROME AMNESICO"},
{ value: "F187",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLATILES: TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO"},
{ value: "F188",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLATILES: OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"},
{ value: "F189",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE DISOLVENTES VOLATILES: TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"},
{ value: "F190",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MULTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS: INTOXICACION AGUDA"},
{ value: "F191",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MULTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS: USO NOCIVO"},
{ value: "F192",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MULTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS: SINDROME DE DEPENDENCIA"},
{ value: "F193",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MULTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS: ESTADO DE ABSTINENCIA"},
{ value: "F194",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MULTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS: ESTADO DE ABSTINENCIA CON DELIRIO"},
{ value: "F195",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MULTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS: TRASTORNO PSICOTICO"},
{ value: "F196",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MULTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS: SINDROME AMNESICO"},
{ value: "F197",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MULTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS: TRASTORNO PSICOTICO RESIDUAL Y DE COMIENZO TARDIO"},
{ value: "F198",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MULTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS: OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"},
{ value: "F199",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO DEBIDOS AL USO DE MULTIPLES DROGAS Y AL USO DE OTRAS SUSTANCIAS PSICOACTIVAS: TRASTORNO MENTAL Y DEL COMPORTAMIENTO, NO ESPECIFICADO"},
{ value: "F200",	text: "ESQUIZOFRENIA PARANOIDE"},
{ value: "F201",	text: "ESQUIZOFRENIA HEBEFRENICA"},
{ value: "F202",	text: "ESQUIZOFRENIA CATATONICA"},
{ value: "F203",	text: "ESQUIZOFRENIA INDIFERENCIADA"},
{ value: "F204",	text: "DEPRESION POSTESQUIZOFRENICA"},
{ value: "F205",	text: "ESQUIZOFRENIA RESIDUAL"},
{ value: "F206",	text: "ESQUIZOFRENIA SIMPLE"},
{ value: "F208",	text: "OTRAS ESQUIZOFRENIAS"},
{ value: "F209",	text: "ESQUIZOFRENIA, NO ESPECIFICADA"},
{ value: "F21X",	text: "TRASTORNO ESQUIZOTIPICO"},
{ value: "F220",	text: "TRASTORNO DELIRANTE"},
{ value: "F228",	text: "OTROS TRASTORNOS DELIRANTES PERSISTENTES"},
{ value: "F229",	text: "TRASTORNO DELIRANTE PERSISTENTE, NO ESPECIFICADO"},
{ value: "F230",	text: "TRASTORNO PSICOTICO AGUDO POLIMORFO, SIN SINTOMAS DE ESQUIZOFRENIA"},
{ value: "F231",	text: "TRASTORNO PSICOTICO AGUDO POLIMORFO, CON SINTOMAS DE ESQUIZOFRENIA"},
{ value: "F232",	text: "TRASTORNO PSICOTICO AGUDO DE TIPO ESQUIZOFRENICO"},
{ value: "F233",	text: "OTRO TRASTORNO PSICOTICO AGUDO, CON PREDOMINIO DE IDEAS DELIRANTES"},
{ value: "F238",	text: "OTROS TRASTORNOS PSICOTICOS AGUDOS Y TRANSITORIOS"},
{ value: "F239",	text: "TRASTORNO PSICOTICO AGUDO Y TRANSITORIO, NO ESPECIFICADO DE TIPO ESQUIZOFRENICO"},
{ value: "F24X",	text: "TRASTORNO DELIRANTE INDUCIDO"},
{ value: "F250",	text: "TRASTORNO ESQUIZOAFECTIVO DE TIPO MANIACO"},
{ value: "F251",	text: "TRASTORNO ESQUIZOAFECTIVO DE TIPO DEPRESIVO"},
{ value: "F252",	text: "TRASTORNO ESQUIZOAFECTIVO DE TIPO MIXTO"},
{ value: "F258",	text: "OTROS TRASTORNOS ESQUIZOAFECTIVOS"},
{ value: "F259",	text: "TRASTORNO ESQUIZOAFECTIVO, NO ESPECIFICADO"},
{ value: "F28X",	text: "OTROS TRASTORNOS PSICOTICOS DE ORIGEN NO ORGANICO"},
{ value: "F29X",	text: "PSICOSIS DE ORIGEN NO ORGANICO, NO ESPECIFICADA"},
{ value: "F300",	text: "HPOMANIA"},
{ value: "F301",	text: "MANIA SIN SINTOMAS PSICOTICOS"},
{ value: "F302",	text: "MANIA CON SINTOMAS PSICOTICOS"},
{ value: "F308",	text: "OTROS EPISODIOS MANIACOS"},
{ value: "F309",	text: "EPISODIO MANIACO, NO ESPECIFICADO"},
{ value: "F310",	text: "TRASTORNO AFECTIVO BIPOLAR, EPISODIO HIPOMANIACO PRESENTE"},
{ value: "F311",	text: "TRASTORNO AFECTIVO BIPOLAR, EPISODIO MANIACO PRESENTE SIN SINTOMAS PSICOTICOS"},
{ value: "F312",	text: "TRASTORNO AFECTIVO BIPOLAR, EPISODIO MANIACO PRESENTE CON SINTOMAS PSICOTICOS"},
{ value: "F313",	text: "TRASTORNO AFECTIVO BIPOLAR, EPISODIO DEPRESIVO PRESENTE LEVE O MODERADO"},
{ value: "F314",	text: "TRASTORNO AFECTIVO BIPOLAR, EPISODIO DEPRESIVO GRAVE PRESENTE SIN SINTOMAS PSICOTICOS"},
{ value: "F315",	text: "TRASTORNO AFECTIVO BIPOLAR, EPISODIO DEPRESIVO GRAVE PRESENTE CON SINTOMAS PSICOTICOS"},
{ value: "F316",	text: "TRASTORNO AFECTIVO BIPOLAR, EPISODIO MIXTO PRESENTE"},
{ value: "F317",	text: "TRASTORNO AFECTIVO BIPOLAR, ACTUALMENTE EN REMISION"},
{ value: "F318",	text: "OTROS TRASTORNOS AFECTIVOS BIPOLARES"},
{ value: "F319",	text: "TRASTORNO AFECTIVO BIPOLAR, NO ESPECIFICADO"},
{ value: "F320",	text: "EPISODIO DEPRESIVO LEVE"},
{ value: "F321",	text: "EPISODIO DEPRESIVO MODERADO"},
{ value: "F322",	text: "EPISODIO DEPRESIVO GRAVE SIN SINTOMAS PSICOTICOS"},
{ value: "F323",	text: "EPISODIO DEPRESIVO GRAVE CON SINTOMAS PSICOTICOS"},
{ value: "F328",	text: "OTROS EPISODIOS DEPRESIVOS"},
{ value: "F329",	text: "EPISODIO DEPRESIVO, NO ESPECIFICADO"},
{ value: "F330",	text: "TRASTORNO DEPRESIVO RECURRENTE, EPISODIO LEVE PRESENTE"},
{ value: "F331",	text: "TRASTORNO DEPRESIVO RECURRENTE, EPISODIO MODERADO PRESENTE"},
{ value: "F332",	text: "TRASTORNO DEPRESIVO RECURRENTE, EPISODIO DEPRESIVO GRAVE PRESENTE SIN SINTOMAS PSICOTICOS"},
{ value: "F333",	text: "TRASTORNO DEPRESIVO RECURRENTE, EPISODIO DEPRESIVO GRAVE PRESENTE, CON SINTOMAS PSICOTICOS"},
{ value: "F334",	text: "TRASTORNO DEPRESIVO RECURRENTE ACTUALMENTE EN REMISION"},
{ value: "F338",	text: "OTROS TRASTORNOS DEPRESIVOS RECURRENTES"},
{ value: "F339",	text: "TRASTORNO DEPRESIVO RECURRENTE, NO ESPECIFICADO"},
{ value: "F340",	text: "CICLOTIMIA"},
{ value: "F341",	text: "DISTIMIA"},
{ value: "F348",	text: "OTROS TRASTORNOS DEL HUMOR [AFECTIVOS] PERSISTENTES"},
{ value: "F349",	text: "TRASTORNO PERSISTENTE DEL HUMOR [AFECTIVO], NO ESPECIFICADO"},
{ value: "F380",	text: "OTROS TRASTORNOS DEL HUMOR [AFECTIVOS], AISLADOS"},
{ value: "F381",	text: "OTROS TRASTORNOS DEL HUMOR [AFECTIVOS], RECURRENTES"},
{ value: "F388",	text: "OTROS TRASTORNOS DEL HUMOR [AFECTIVOS], ESPECIFICADOS"},
{ value: "F39X",	text: "TRASTORNO DEL HUMOR [AFECTIVO], NO ESPECIFICADO"},
{ value: "F400",	text: "AGORAFOBIA"},
{ value: "F401",	text: "FOBIAS SOCIALES"},
{ value: "F402",	text: "FOBIAS ESPECIFICADAS [AISLADAS]"},
{ value: "F408",	text: "OTROS TRASTORNOS FOBICOS DE ANSIEDAD"},
{ value: "F409",	text: "TRASTORNO FOBICO DE ANSIEDAD, NO ESPECIFICADO"},
{ value: "F410",	text: "TRASTORNO DE PANICO [ANSIEDAD PAROXISTICA EPISODICA]"},
{ value: "F411",	text: "TRASTORNO DE ANSIEDAD GENERALIZADA"},
{ value: "F412",	text: "TRASTORNO MIXTO DE ANSIEDAD Y DEPRESION"},
{ value: "F413",	text: "OTROS TRASTORNOS DE ANSIEDAD MIXTOS"},
{ value: "F418",	text: "OTROS TRASTORNOS DE ANSIEDAD ESPECIFICADOS"},
{ value: "F419",	text: "TRASTORNO DE ANSIEDAD , NO ESPECIFICADO"},
{ value: "F420",	text: "PREDOMINIO DE PENSAMIENTOS O RUMIACIONES OBSESIVAS"},
{ value: "F421",	text: "PREDOMINIO DE ACTOS COMPULSIVOS [RITUALES OBSESIVOS]"},
{ value: "F422",	text: "ACTOS E IDEAS OBSESIVAS MIXTOS"},
{ value: "F428",	text: "OTROS TRASTORNOS OBSESIVO- COMPULSIVOS"},
{ value: "F429",	text: "TRASTORNO OBSESIVO-COMPULSIVO, NO ESPECIFICADO"},
{ value: "F430",	text: "REACCION AL ESTRÉS AGUDO"},
{ value: "F431",	text: "TRASTORNO DE ESTRÉS POSTRAUMATICO"},
{ value: "F432",	text: "TRASTORNOS DE ADAPTACION"},
{ value: "F438",	text: "OTRAS REACCIONES AL ESTRÉS GRAVE"},
{ value: "F439",	text: "REACCION AL ESTRÉS GRAVE, NO ESPECIFICADA"},
{ value: "F440",	text: "AMNESIA DISOCIATIVA"},
{ value: "F441",	text: "FUGA DISOCIATIVA"},
{ value: "F442",	text: "ESTUPOR DISOCIATIVO"},
{ value: "F443",	text: "TRASTORNOS DE TRANCE Y DE POSESION"},
{ value: "F444",	text: "TRASTORNOS DISOCIATIVOS DEL MOVIMIENTO"},
{ value: "F445",	text: "CONVULSIONES DISOCIATIVAS"},
{ value: "F446",	text: "ANESTESIA DISOCIATIVA Y PERDIDA SENSORIAL"},
{ value: "F447",	text: "TRASTORNOS DISOCIATIVOS MIXTOS [Y DE CONVERSION]"},
{ value: "F448",	text: "OTROS TRASTORNOS DISOCIATIVOS [DE CONVERSION]"},
{ value: "F449",	text: "TRASTORNO DISOCIATIVO [DE CONVERSION], NO ESPECIFICADO"},
{ value: "F450",	text: "TRASTORNO DE SOMATIZACION"},
{ value: "F451",	text: "TRASTORNO SOMATOMORFO INDIFERENCIADO"},
{ value: "F452",	text: "TRASTORNO HIPOCONDRIACO"},
{ value: "F453",	text: "DISFUNCION AUTONOMICA SOMATOMORFA"},
{ value: "F454",	text: "TRASTORNO DE DOLOR PERSISTENTE SOMATOMORFO"},
{ value: "F458",	text: "OTROS TRASTORNOS SOMATOMORFOS"},
{ value: "F459",	text: "TRASTORNO SOMATOMORFO, NO ESPECIFICADO"},
{ value: "F480",	text: "NEURASTENIA"},
{ value: "F481",	text: "SINDROME DE DESPERSONALIZACION Y DESVINCULACION DE LA REALIDAD"},
{ value: "F488",	text: "OTROS TRASTORNOS NEUROTICOS ESPECIFICADOS"},
{ value: "F489",	text: "TRASTORNO NEUROTICO, NO ESPECIFICADO"},
{ value: "F500",	text: "ANOREXIA NERVIOSA"},
{ value: "F501",	text: "ANOREXIA NERVIOSA ATIPICA"},
{ value: "F502",	text: "BULIMIA NERVIOSA"},
{ value: "F503",	text: "BULIMIA NERVIOSA ATIPICA"},
{ value: "F504",	text: "HIPERFAGIA ASOCIADA CON OTRAS ALTERACIONES PSICOLOGICAS"},
{ value: "F505",	text: "VOMITOS ASOCIADOS CON OTRAS ALTERACIONES PSICOLOGICAS"},
{ value: "F508",	text: "OTROS TRASTORNOS DE LA INGESTION DE ALIMENTOS"},
{ value: "F509",	text: "TRASTORNO DE LA INGESTION DE ALIMENTOS, NO ESPECIFICADO"},
{ value: "F510",	text: "INSOMNIO NO ORGANICO"},
{ value: "F511",	text: "HIPERSOMNIO NO ORGANICO"},
{ value: "F512",	text: "TRASTORNO NO ORGANICO DEL CICLO SUEÑO-VIGILIA"},
{ value: "F513",	text: "SONAMBULISMO"},
{ value: "F514",	text: "TERRORES DEL SUEÑO [TERRORES NOCTURNOS]"},
{ value: "F515",	text: "PESADILLAS"},
{ value: "F518",	text: "OTROS TRASTORNOS NO ORGANICOS DEL SUEÑO"},
{ value: "F519",	text: "TRASTORNO NO ORGANICO DEL SUEÑO, NO ESPECIFICADO"},
{ value: "F520",	text: "FALTA O PERDIDA DEL DESEO SEXUAL"},
{ value: "F521",	text: "AVERSION AL SEXO Y FALTA DE GOCE SEXUAL"},
{ value: "F522",	text: "FALLA DE LA RESPUESTA GENITAL"},
{ value: "F523",	text: "DISFUNCION ORGASMICA"},
{ value: "F524",	text: "EYACULACION PRECOZ"},
{ value: "F525",	text: "VAGINISMO NO ORGANICO"},
{ value: "F526",	text: "DISPAREUNIA NO ORGANICA"},
{ value: "F527",	text: "IMPULSO SEXUAL EXCESIVO"},
{ value: "F528",	text: "OTRAS DISFUNCIONES SEXUALES, NO OCASIONADAS POR TRASTORNO NI POR ENFERMEDAD ORGANICOS"},
{ value: "F529",	text: "DISFUNCION SEXUAL, NO OCASIONADA POR TRASTORNO NI POR ENFERMEDAD ORGANICOS, NO ESPECIFICADA"},
{ value: "F530",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO LEVES, ASOCIADOS CON EL PUERPERIO, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "F531",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO GRAVES, ASOCIADOS CON EL PUERPERIO, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "F538",	text: "TRASTORNOS MENTALES Y DEL COMPORTAMIENTO ASOCIADOS CON EL PUERPERIO, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "F539",	text: "TRASTORNO MENTAL PUERPERAL, NO ESPECIFICADO"},
{ value: "F54X",	text: "FACTORES PSICOLOGICOS Y DEL COMPORTAMIENTO ASOCIADOS CON TRASTORNOS O ENFERMEDADES CLASIFICADOS EN OTRA PARTE"},
{ value: "F55X",	text: "ABUSO DE SUSTANCIAS QUE NO PRODUCEN DEPENDENCIA"},
{ value: "F59X",	text: "SINDROMES DEL COMPORTAMIENTO ASOCIADOS CON ALTERACIONES FISIOLOGICAS Y FACTORES FISICOS, NO ESPECIFICADOS"},
{ value: "F600",	text: "TRASTORNO PARANOIDE DE LA PERSONALIDAD"},
{ value: "F601",	text: "TRASTORNO ESQUIZOIDE DE LA PERSONALIDAD"},
{ value: "F602",	text: "TRASTORNO ASOCIAL DE LA PERSONALIDAD"},
{ value: "F603",	text: "TRASTORNO DE LA PERSONALIDAD EMOCIONALMENTE INESTABLE"},
{ value: "F604",	text: "TRASTORNO HISTRIONICO DE LA PERSONALIDAD"},
{ value: "F605",	text: "TRASTORNO ANANCASTICO DE LA PERSONALIDAD"},
{ value: "F606",	text: "TRASTORNO DE LA PERSONALIDAD ANSIOSA (EVASIVA, ELUSIVA)"},
{ value: "F607",	text: "TRASTORNO DE LA PERSONALIDAD DEPENDIENTE"},
{ value: "F608",	text: "OTROS TRASTORNOS ESPECIFICOS DE LA PERSONALIDAD"},
{ value: "F609",	text: "TRASTORNO DE LA PERSONALIDAD, NO ESPECIFICADO"},
{ value: "F61X",	text: "TRASTORNOS MIXTOS Y OTROS TRASTORNOS DE LA PERSONALIDAD"},
{ value: "F620",	text: "CAMBIO PERDURABLE DE LA PERSONALIDAD DESPUES DE UNA EXPERIENCIA CATASTROFICA"},
{ value: "F621",	text: "CAMBIO PERDURABLE DE LA PERSONALIDAD CONSECUTIVO A UNA ENFERMEDAD PSIQUIATRICA"},
{ value: "F628",	text: "OTROS CAMBIOS PERDURABLES DE LA PERSONALIDAD"},
{ value: "F629",	text: "CAMBIO PERDURABLE DE LA PERSONALIDAD, NO ESPECIFICADO"},
{ value: "F630",	text: "JUEGO PATOLOGICO"},
{ value: "F631",	text: "PIROMANIA"},
{ value: "F632",	text: "HURTO PATOLOGICO (CLEPTOMANIA)"},
{ value: "F633",	text: "TRICOTILOMANIA"},
{ value: "F638",	text: "OTROS TRASTORNOS DE LOS HABITOS Y DE LOS IMPULSOS"},
{ value: "F639",	text: "TRASTORNO DE LOS HABITOS Y DE LOS IMPULSOS, NO ESPECIFICADO"},
{ value: "F640",	text: "TRANSEXUALISMO"},
{ value: "F641",	text: "TRANSVESTISMO DE ROL DUAL"},
{ value: "F642",	text: "TRASTORNO DE LA IDENTIDAD DE GENERO EN LA NIÑEZ"},
{ value: "F648",	text: "OTROS TRASTORNOS DE LA IDENTIDAD DE GENERO"},
{ value: "F649",	text: "TRASTORNO DE LA IDENTIDAD DE GENERO, NO ESPECIFICADO"},
{ value: "F650",	text: "FETICHISMO"},
{ value: "F651",	text: "TRANSVESTISMO FETICHISTA"},
{ value: "F652",	text: "EXHIBICIONISMO"},
{ value: "F653",	text: "VOYEURISMO"},
{ value: "F654",	text: "PEDOFILIA"},
{ value: "F655",	text: "SADOMASOQUISMO"},
{ value: "F656",	text: "TRASTORNOS MULTIPLES DE LA PREFERENCIA SEXUAL"},
{ value: "F658",	text: "OTROS TRASTORNOS DE LA PREFERENCIA SEXUAL"},
{ value: "F659",	text: "TRASTORNO DE LA PREFERENCIA SEXUAL, NO ESPECIFICADO"},
{ value: "F660",	text: "TRASTORNO DE LA MADURACION SEXUAL"},
{ value: "F661",	text: "ORIENTACION SEXUAL EGODISTONICA"},
{ value: "F662",	text: "TRASTORNO DE LA RELACION SEXUAL"},
{ value: "F668",	text: "OTROS TRASTORNOS DEL DESARROLLO PSICOSEXUAL"},
{ value: "F669",	text: "TRASTORNO DEL DESARROLLO PSICOSEXUAL, NO ESPECIFICADO"},
{ value: "F680",	text: "ELABORACION DE SINTOMAS FISICOS POR CAUSAS PSICOLOGICAS"},
{ value: "F681",	text: "PRODUCCION INTENCIONAL O SIMULACION DE SINTOMAS O DE INCAPACIDADES, TANTO FISICAS COMO PSICOLOGICAS [TRASTORNO FACTICIO]"},
{ value: "F688",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA PERSONALIDAD Y DEL COMPORTAMIENTO EN ADULTOS"},
{ value: "F69X",	text: "TRASTORNO DE LA PERSONALIDAD Y DEL COMPORTAMIENTO EN ADULTOS, NO ESPECIFICADO"},
{ value: "F700",	text: "RETRASO MENTAL LEVE: DETERIORO DEL COMPORTAMIENTO NULO O MINIMO"},
{ value: "F701",	text: "RETRASO MENTAL LEVE: DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, QUE REQUIERE ATENCION O TRATAMIENTO"},
{ value: "F708",	text: "RETRASO MENTAL LEVE: OTROS DETERIOROS DEL COMPORTAMIENTO"},
{ value: "F709",	text: "RETRASO MENTAL LEVE: DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO"},
{ value: "F710",	text: "RETRASO MENTAL MODERADO: DETERIORO DEL COMPORTAMIENTO NULO O MINIMO"},
{ value: "F711",	text: "RETRASO MENTAL MODERADO: DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, QUE REQUIERE ATENCION O TRATAMIENTO"},
{ value: "F718",	text: "RETRASO MENTAL MODERADO: OTROS DETERIOROS DEL COMPORTAMIENTO"},
{ value: "F719",	text: "RETRASO MENTAL MODERADO: DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO"},
{ value: "F720",	text: "RETRASO MENTAL GRAVE: DETERIORO DEL COMPORTAMIENTO NULO O MINIMO"},
{ value: "F721",	text: "RETRASO MENTAL GRAVE: DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, QUE REQUIERE ATENCION O TRATAMIENTO"},
{ value: "F728",	text: "RETRASO MENTAL GRAVE: OTROS DETERIOROS DEL COMPORTAMIENTO"},
{ value: "F729",	text: "RETRASO MENTAL GRAVE: DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO"},
{ value: "F730",	text: "RETRASO MENTAL PROFUNDO: DETERIORO DEL COMPORTAMIENTO NULO O MINIMO"},
{ value: "F731",	text: "RETRASO MENTAL PROFUNDO: DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, QUE REQUIERE ATENCION O TRATAMIENTO"},
{ value: "F738",	text: "RETRASO MENTAL PROFUNDO: OTROS DETERIOROS DEL COMPORTAMIENTO"},
{ value: "F739",	text: "RETRASO MENTAL PROFUNDO: DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO"},
{ value: "F780",	text: "OTROS TIPOS DE RETRASO MENTAL PROFUNDO: DETERIORO DEL COMPORTAMIENTO NULO O MINIMO"},
{ value: "F781",	text: "OTROS TIPOS DE RETRASO MENTAL PROFUNDO: DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, QUE REQUIERE ATENCION O TRATAMIENTO"},
{ value: "F788",	text: "OTROS TIPOS DE RETRASO MENTAL PROFUNDO: OTROS DETERIOROS DEL COMPORTAMIENTO"},
{ value: "F789",	text: "OTROS TIPOS DE RETRASO MENTAL PROFUNDO: DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO"},
{ value: "F790",	text: "RETRASO MENTAL, NO ESPECIFICADO: DETERIORO DEL COMPORTAMIENTO NULO O MINIMO"},
{ value: "F791",	text: "RETRASO MENTAL, NO ESPECIFICADO: DETERIORO DEL COMPORTAMIENTO SIGNIFICATIVO, QUE REQUIERE ATENCION O TRATAMIENTO"},
{ value: "F798",	text: "RETRASO MENTAL, NO ESPECIFICADO: OTROS DETERIOROS DEL COMPORTAMIENTO"},
{ value: "F799",	text: "RETRASO MENTAL, NO ESPECIFICADO: DETERIORO DEL COMPORTAMIENTO DE GRADO NO ESPECIFICADO"},
{ value: "F800",	text: "TRASTORNO ESPECIFICO DE LA PRONUNCIACION"},
{ value: "F801",	text: "TRASTORNO DEL LENGUAJE EXPRESIVO"},
{ value: "F802",	text: "TRASTORNO DE LA RECEPCION DEL LENGUAJE"},
{ value: "F803",	text: "AFASIA ADQUIRIDA CON EPILEPSIA [LANDAU-KLEFFNER]"},
{ value: "F808",	text: "OTROS TRASTORNOS DEL DESARROLLO DEL HABLA Y DEL LENGUAJE"},
{ value: "F809",	text: "TRASTORNO DEL DESARROLLO DEL HABLA Y DEL LENGUAJE NO ESPECIFICADO"},
{ value: "F810",	text: "TRASTORNO ESPECIFICO DE LA LECTURA"},
{ value: "F811",	text: "TRASTORNO ESPECIFICO DEL DELETREO (ORTOGRAFIA)"},
{ value: "F812",	text: "TRASTORNO ESPECIFICO DE LAS HABILIDADES ARITMETICAS"},
{ value: "F813",	text: "TRASTORNO MIXTO DE LAS HABILIDADES ESCOLARES"},
{ value: "F818",	text: "OTROS TRASTORNOS DEL DESARROLLO DE LAS HABILIDADES ESCOLARES"},
{ value: "F819",	text: "TRASTORNO DEL DESARROLLO DE LAS HABILIDADES ESCOLARES, NO ESPECIFICADO"},
{ value: "F82X",	text: "TRASTORNO ESPECIFICO DEL DESARROLLO DE LA FUNCION MOTRIZ"},
{ value: "F83X",	text: "TRASTORNOS ESPECIFICOS MIXTOS DEL DESARROLLO"},
{ value: "F840",	text: "AUTISMO EN LA NIÑEZ"},
{ value: "F841",	text: "AUTISMO ATIPICO"},
{ value: "F842",	text: "SINDROME DE RETT"},
{ value: "F843",	text: "OTRO TRASTORNO DESINTEGRATIVO DE LA NIÑEZ"},
{ value: "F844",	text: "TRASTORNO HIPERACTIVO ASOCIADO CON RETRASO MENTAL Y MOVIMIENTOS ESTEREOTIPADOS"},
{ value: "F845",	text: "SINDROME DE ASPERGER"},
{ value: "F848",	text: "OTROS TRASTORNOS GENERALIZADOS DEL DESARROLLO"},
{ value: "F849",	text: "TRASTORNO GENERALIZADO DEL DESARROLLO NO ESPECIFICADO"},
{ value: "F88X",	text: "OTROS TRASTORNOS DEL DESARROLLO PSICOLOGICO"},
{ value: "F89X",	text: "TRASTORNO DEL DESARROLLO PSICOLOGICO, NO ESPECIFICADO"},
{ value: "F900",	text: "PERTURBACION DE LA ACTIVIDAD Y DE LA ATENCION"},
{ value: "F901",	text: "TRASTORNO HIPERCINETICO DE LA CONDUCTA"},
{ value: "F908",	text: "OTROS TRASTORNOS HIPERCINETICOS"},
{ value: "F909",	text: "TRASTORNO HIPERCINETICO, NO ESPECIFICADO"},
{ value: "F910",	text: "TRASTORNO DE LA CONDUCTA LIMITADO AL CONTEXTO FAMILIAR"},
{ value: "F911",	text: "TRASTORNO DE LA CONDUCTA INSOCIABLE"},
{ value: "F912",	text: "TRASTORNO DE LA CONDUCTA SOCIABLE"},
{ value: "F913",	text: "TRASTORNO OPOSITOR DESAFIANTE"},
{ value: "F918",	text: "OTROS TRASTORNOS DE LA CONDUCTA"},
{ value: "F919",	text: "TRASTORNO DE LA CONDUCTA, NO ESPECIFICADO"},
{ value: "F920",	text: "TRASTORNO DEPRESIVO DE LA CONDUCTA"},
{ value: "F928",	text: "OTROS TRASTORNOS MIXTOS DE LA CONDUCTA Y DE LAS EMOCIONES"},
{ value: "F929",	text: "TRASTORNO MIXTO DE LA CONDUCTA Y DE LAS EMOCIONES, NO ESPECIFICADO"},
{ value: "F930",	text: "TRASTORNO DE ANSIEDAD DE SEPARACION EN LA NIÑEZ"},
{ value: "F931",	text: "TRASTORNO DE ANSIEDAD FOBICA EN LA NIÑEZ"},
{ value: "F932",	text: "TRASTORNO DE ANSIEDAD SOCIAL EN LA NIÑEZ"},
{ value: "F933",	text: "TRASTORNO DE RIVALIDAD ENTRE HERMANOS"},
{ value: "F938",	text: "OTROS TRASTORNOS EMOCIONALES EN LA NIÑEZ"},
{ value: "F939",	text: "TRASTORNO EMOCIONAL EN LA NIÑEZ, NO ESPECIFICADO"},
{ value: "F940",	text: "MUTISMO ELECTIVO"},
{ value: "F941",	text: "TRASTORNO DE VINCULACION REACTIVA EN LA NIÑEZ"},
{ value: "F942",	text: "TRASTORNO DE VINCULACION DESINHIBIDA EN LA NIÑEZ"},
{ value: "F948",	text: "OTROS TRASTORNOS DEL COMPORTAMIENTO SOCIAL EN LA NIÑEZ"},
{ value: "F949",	text: "TRASTORNO DEL COMPORTAMIENTO SOCIAL EN LA NIÑEZ, NO ESPECIFICADO"},
{ value: "F950",	text: "TRASTORNO POR TIC TRANSITORIO"},
{ value: "F951",	text: "TRASTORNO POR TIC MOTOR O VOCAL CRONICO"},
{ value: "F952",	text: "TRASTORNOS POR TICS MOTORES Y VOCALES MULTIPLES COMBINADOS [DE LA TOURETTE]"},
{ value: "F958",	text: "OTROS TRASTORNOS POR TIC"},
{ value: "F959",	text: "TRASTORNO POR TIC, NO ESPECIFICADO"},
{ value: "F980",	text: "ENURESIS NO ORGANICA"},
{ value: "F981",	text: "ECOPRESIS NO ORGANICA"},
{ value: "F982",	text: "TRASTORNO DE LA INGESTION ALIMENTARIA EN LA INFANCIA Y EN LA NIÑEZ"},
{ value: "F983",	text: "PICA EN LA INFANCIA Y LA NIÑEZ"},
{ value: "F984",	text: "TRASTORNOS DE LOS MOVIMIENTOS ESTEREOTIPADOS"},
{ value: "F985",	text: "TARTAMUDEZ [ESPASMOFEMIA]"},
{ value: "F986",	text: "FARFULLEO"},
{ value: "F988",	text: "OTROS TRASTORNOS EMOCIONALES Y DEL COMPORTAMIENTO QUE APARECEN HABITUALMENTE EN LA NIÑEZ Y EN LA ADOLESCENCIA"},
{ value: "F989",	text: "TRASTORNOS NO ESPECIFICADOS, EMOCIONALES Y DEL COMPORTAMIENTO, QUE APARECEN HABITUALMENTE EN LA NIÑEZ Y EN LA ADOLESCENCIA"},
{ value: "F99X",	text: "TRASTORNO MENTAL, NO ESPECIFICADO"},
{ value: "G000",	text: "MENINGITIS POR HEMOFILOS"},
{ value: "G001",	text: "MENINGITIS NEUMOCOCICA"},
{ value: "G002",	text: "MENINGITIS ESTREPTOCOCICA"},
{ value: "G003",	text: "MENINGITIS ESTAFILOCOCICA"},
{ value: "G008",	text: "OTRAS MENINGITIS BACTERIANAS"},
{ value: "G009",	text: "MENINGITIS BACTERIANA, NO ESPECIFICADA"},
{ value: "G01X",	text: "MENINGITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"},
{ value: "G020",	text: "MENINGITIS EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE"},
{ value: "G021",	text: "MENINGITIS EN MICOSIS"},
{ value: "G028",	text: "MENINGITIS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS ESPECIFICADAS CLASIFICADAS EN OTRA PARTE"},
{ value: "G030",	text: "MENINGITIS APIOGENA"},
{ value: "G031",	text: "MENINGITIS CRONICA"},
{ value: "G032",	text: "MENINGITIS RECURRENTE BENIGNA [MOLLARET]"},
{ value: "G038",	text: "MENINGITIS DEBIDAS A OTRAS CAUSAS ESPECIFICADAS"},
{ value: "G039",	text: "MENINGITIS, NO ESPECIFICADA"},
{ value: "G040",	text: "ENCEFALITIS AGUDA DISEMINADA"},
{ value: "G041",	text: "PARAPLEJÍA ESPASTICA TROPICAL"},
{ value: "G042",	text: "MENINGOENCEFALITIS Y MENINGOMIELITIS BACTERIANAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "G048",	text: "OTRAS ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS"},
{ value: "G049",	text: "ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS, NO ESPECIFICADAS"},
{ value: "G050",	text: "ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"},
{ value: "G051",	text: "ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE"},
{ value: "G052",	text: "ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "G058",	text: "ENCEFALITIS, MIELITIS Y ENCEFALOMIELITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G060",	text: "ABSCESO Y GRANULOMA INTRACRANEAL"},
{ value: "G061",	text: "ABSCESO Y GRANULOMA INTRARRAQUIDEO"},
{ value: "G062",	text: "ABSCESO EXTRADURAL Y SUBDURAL, NO ESPECIFICADO"},
{ value: "G07X",	text: "ABSCESO Y GRANULOMA INTRACRANEAL E INTRARRAQUIDEO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G08X",	text: "FLEBITIS Y TROMBOFLEBITIS INTRACRANEAL E INTRARRAQUIDEA"},
{ value: "G09X",	text: "SECUELAS DE ENFERMEDADES INFLAMATORIAS DEL SISTEMA NERVIOSO CENTRAL"},
{ value: "G10X",	text: "ENFERMEDAD DE HUNTINGTON"},
{ value: "G110",	text: "ATAXIA CONGENITA NO PROGRESIVA"},
{ value: "G111",	text: "ATAXIA CEREBELOSA DE INICIACION TEMPRANA"},
{ value: "G112",	text: "ATAXIA CEREBELOSA DE INICIACION TARDIA"},
{ value: "G113",	text: "ATAXIA CEREBELOSA CON REPARACION DEFECTUOSA DEL ADN"},
{ value: "G114",	text: "PARAPLEJÍA ESPASTICA HEREDITARIA"},
{ value: "G118",	text: "OTRAS ATAXIAS HEREDITARIAS"},
{ value: "G119",	text: "ATAXIA HEREDITARIA, NO ESPECIFICADA"},
{ value: "G120",	text: "ATROFIA MUSCULAR ESPINAL INFANTIL, TIPO I [WERDNIG-HOFFMAN]"},
{ value: "G121",	text: "OTRAS ATROFIAS MUSCULARES ESPINALES HEREDITARIAS"},
{ value: "G122",	text: "ENFERMEDADES DE LAS NEURONAS MOTORAS"},
{ value: "G128",	text: "OTRAS ATROFIAS MUSCULARES ESPINALES Y SINDROMES AFINES"},
{ value: "G129",	text: "ATROFIA MUSCULAR ESPINAL, SIN OTRA ESPECIFICACION"},
{ value: "G130",	text: "NEUROMIOPATIA Y NEUROPATIA PARANEOPLASICA"},
{ value: "G131",	text: "OTRAS ATROFIAS SISTEMICAS QUE AFECTAN EL SISTEMA NERVIOSO CENTRAL EN ENFERMEDAD NEOPLASICA"},
{ value: "G132",	text: "ATROFIA SISTEMICA QUE AFECTA PRIMARIAMENTE EL SISTEMA NERVIOSO CENTRAL EN EL MIXEDEMA (E00.1†, E03. †)"},
{ value: "G138",	text: "ATROFIA SISTEMICA QUE AFECTA PRIMARIAMENTE EL SISTEMA NERVIOSO CENTRAL EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G20X",	text: "ENFERMEDAD DE PARKINSON"},
{ value: "G210",	text: "SINDROME NEUROLEPTICO MALIGNO"},
{ value: "G211",	text: "OTRO PARKINSONISMO SECUNDARIO INDUCIDO POR DROGAS"},
{ value: "G212",	text: "PARKINSONISMO SECUNDARIO DEBIDO A OTROS AGENTES EXTERNOS"},
{ value: "G213",	text: "PARKINSONISMO POSTENCEFALITICO"},
{ value: "G218",	text: "OTROS TIPOS DE PARKINSONISMO SECUNDARIO"},
{ value: "G219",	text: "PARKINSONISMO SECUNDARIO, NO ESPECIFICADO"},
{ value: "G22X",	text: "PARKINSONISMO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G230",	text: "ENFERMEDAD DE HALLERVORDEN-SPATZ"},
{ value: "G231",	text: "OFTALMOPLEJIA SUPRANUCLEAR PROGRESIVA [STEELE-RICHARDSON- OLSZEWSKI]"},
{ value: "G232",	text: "DESGENERACION NIGROESTRIADA"},
{ value: "G238",	text: "OTRAS ENFERMEDADES DEGENERATIVAS ESPECIFICADAS DE LOS NUCLEOS DE LA BASE"},
{ value: "G239",	text: "ENFERMEDAD DEGENERATIVA DE LOS NUCLEOS DE LA BASE, NO ESPECIFICADA"},
{ value: "G240",	text: "DISTONIA INDUCIDA POR DROGAS"},
{ value: "G241",	text: "DISTONIA IDIOPATICA FAMILIAR"},
{ value: "G242",	text: "DISTONIA IDIOPATICA NO FAMILIAR"},
{ value: "G243",	text: "TORTICOLIS ESPASMODICA"},
{ value: "G244",	text: "DISTONIA BUCOFACIAL IDIOPATICA"},
{ value: "G245",	text: "BLEFAROSPASMO"},
{ value: "G248",	text: "OTRAS DISTONIAS"},
{ value: "G249",	text: "DISTONIA, NO ESPECIFICADA"},
{ value: "G250",	text: "TEMBLOR ESENCIAL"},
{ value: "G251",	text: "TEMBLOR INDUCIDO POR DROGAS"},
{ value: "G252",	text: "OTRAS FORMAS ESPECIFICADAS DE TEMBLOR"},
{ value: "G253",	text: "MIOCLONIA"},
{ value: "G254",	text: "COREA INDUCIDA POR DROGAS"},
{ value: "G255",	text: "OTRAS COREAS"},
{ value: "G256",	text: "TIC INDUCIDOS POR DROGAS Y OTROS TICS DE ORIGEN ORGANICO"},
{ value: "G258",	text: "OTROS TRASTORNOS EXTRAPIRAMIDALES Y DEL MOVIMIENTO"},
{ value: "G259",	text: "TRASTORNO EXTRAPIRAMIDAL Y DEL MOVIMIENTO, NO ESPECIFICADO"},
{ value: "G26X",	text: "TRASTORNOS EXTRAPIRAMIDALES Y DEL MOVIMIENTO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G300",	text: "ENFERMEDAD DE ALZHEIMER DE COMIENZO TEMPRANO"},
{ value: "G301",	text: "ENFERMEDAD DE ALZHEIMER COMIENZO TARDIO"},
{ value: "G308",	text: "OTROS TIPOS DE ENFERMEDAD DE ALZHEIMER"},
{ value: "G309",	text: "ENFERMEDAD DE ALZHEIMER, NO ESPECIFICADA"},
{ value: "G310",	text: "ATROFIA CEREBRAL CIRCUNSCRITA"},
{ value: "G311",	text: "DEGENERACION CEREBRAL SENIL NO CLASIFICADA EN OTRA PARTE"},
{ value: "G312",	text: "DEGENERACION DEL SISTEMA NERVIOSO DEBIDA A EL ALCOHOL"},
{ value: "G318",	text: "OTRAS ENFERMEDADES DEGENERATIVAS ESPECIFICADAS DEL SISTEMA NERVIOSO"},
{ value: "G319",	text: "DEGENERACION DEL SISTEMA NERVIOSO, NO ESPECIFICADA"},
{ value: "G320",	text: "DEGENERACION COMBINADA SUBAGUDA DE LA MEDULA ESPINAL EN ENFERNEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G328",	text: "OTROS TRASTORNOS DEGENERATIVOS ESPECIFICADOS DEL SISTEMA NERVIOSO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G35X",	text: "ESCLEROSIS MULTIPLE"},
{ value: "G360",	text: "NEUROMIELITIS OPTICA [DEVIC]"},
{ value: "G361",	text: "LEUCOENCEFALITIS HEMORRAGICA AGUDA Y SUBAGUDA [HURST]"},
{ value: "G368",	text: "OTRAS DESMIELINIZACIONES AGUDAS DISEMINADAS ESPECIFICADAS"},
{ value: "G369",	text: "DESMIELINIZACION DISEMINADA AGUDA SIN OTRA ESPECIFICACION"},
{ value: "G370",	text: "ESCLEROSIS DIFUSA"},
{ value: "G371",	text: "DESMIELINIZACION CENTRAL DEL CUERPO CALLOSO"},
{ value: "G372",	text: "MIELINOLISIS CENTRAL PONTINA"},
{ value: "G373",	text: "MIELITIS TRANSVERSA AGUDA EN ENFERMEDAD DESMIELINIZANTE DEL SISTEMA NERVIOSO CENTRAL"},
{ value: "G374",	text: "MIELITIS NECROTIZANTE SUBAGUDA"},
{ value: "G375",	text: "ESCLEROSIS CONCENTRICA (BALO)"},
{ value: "G378",	text: "OTRAS ENFERMEDADES DESMIELINIZANTES DEL SISTEMA NERVIOSO CENTRAL, ESPECIFICADAS"},
{ value: "G379",	text: "ENFERMEDAD DESMIELINIZANTE DEL SISTEMA NERVIOSO CENTRAL, NO ESPECIFICADA"},
{ value: "G400",	text: "EPILEPSIA Y SINDROMES EPILEPTICOS IDIOPATICOS RELACIONADOS CON LOCALIZACIONES (FOCALES) (PARCIALES) Y CON ATAQUES DE INICIO LOCALIZADO"},
{ value: "G401",	text: "EPILEPSIA Y SINDROMES EPILEPTICOS SINTOMATICOS RELACIONADOS CON LOCALIZACIONES (FOCALES) (PARCIALES) Y CON ATAQUES PARCIALES SIMPLES"},
{ value: "G402",	text: "EPILEPSIA Y SINDROMES EPILEPTICOS SINTOMATICOS RELACIONADOS CON LOCALIZACIONES (FOCALES) (PARCIALES) Y CON ATAQUES PARCIALES COMPLEJOS"},
{ value: "G403",	text: "EPILEPSIA Y SINDROMES EPILEPTICOS IDIOPATICOS GENERALIZADOS"},
{ value: "G404",	text: "OTRAS EPILEPSIAS Y SINDROMES EPILEPTICOS GENERALIZADOS"},
{ value: "G405",	text: "SINDROMES EPILEPTICOS ESPECIALES"},
{ value: "G406",	text: "ATAQUES DE GRAN MAL, NO ESPECIFICADOS (CON O SIN PEQUEÑO MAL)"},
{ value: "G407",	text: "PEQUEÑO MAL, NO ESPECIFICADO (SIN ATAQUE DE GRAN MAL)"},
{ value: "G408",	text: "OTRAS EPILEPSIAS"},
{ value: "G409",	text: "EPILEPSIA, TIPO NO ESPECIFICADO"},
{ value: "G410",	text: "ESTADO DE GRAN MAL EPILEPTICO"},
{ value: "G411",	text: "ESTADO DE PEQUEÑO MAL EPILEPTICO"},
{ value: "G412",	text: "ESTADO DE MAL EPILEPTICO PARCIAL COMPLEJO"},
{ value: "G418",	text: "OTROS ESTADOS EPILÉPTICOS"},
{ value: "G419",	text: "ESTADO DE MAL EPILEPTICO DE TIPO NO ESPECIFICADO"},
{ value: "G430",	text: "MIGRAÑA SIN AURA [MIGRAÑA COMUN]"},
{ value: "G431",	text: "MIGRAÑA CON AURA [MIGRAÑA CLASICA]"},
{ value: "G432",	text: "ESTADO MIGRAÑOSO"},
{ value: "G433",	text: "MIGRAÑA COMPLICADA"},
{ value: "G438",	text: "OTRAS MIGRAÑAS"},
{ value: "G439",	text: "MIGRAÑA, NO ESPECIFICADA"},
{ value: "G440",	text: "SINDROME DE CEFALEA EN RACIMOS"},
{ value: "G441",	text: "CEFALEA VASCULAR, NCOP"},
{ value: "G442",	text: "CEFALEA DEBIDA A TENSION"},
{ value: "G443",	text: "CEFALEA POSTRAUMATICA CRONICA"},
{ value: "G444",	text: "CEFALEA INDUCIDA POR DROGAS, NO CLASIFICADA EN OTRA PARTE"},
{ value: "G448",	text: "OTROS SINDROMES DE CEFALEA ESPECIFICADOS"},
{ value: "G450",	text: "SINDROME ARTERIAL VERTEBRO- BASILAR"},
{ value: "G451",	text: "SINDROME DE LA ARTERIA CAROTIDA (HEMISFÉRICO)"},
{ value: "G452",	text: "SINDROMES ARTERIALES PRECEREBRALES BILATERALES Y MULTIPLES"},
{ value: "G453",	text: "AMAUROSIS FUGAZ"},
{ value: "G454",	text: "AMNESIA GLOBAL TRANSITORIA"},
{ value: "G458",	text: "OTRAS ISQUEMIAS CEREBRALES TRANSITORIAS Y SINDROMES AFINES"},
{ value: "G459",	text: "ISQUEMIA CEREBRAL TRANSITORIA, SIN OTRA ESPECIFICACION"},
{ value: "G460",	text: "CEREBRAL MEDIA (I66.0†)"},
{ value: "G461",	text: "SINDROME DE LA ARTERIA CEREBRAL ANTERIOR (I66.1†)"},
{ value: "G462",	text: "SINDROME DE LA ARTERIA CEREBRAL POSTERIOR (I66.2†)"},
{ value: "G463",	text: "SINDROMES APOPLETICOS DEL TALLO ENCEFALICO (I60-I67†)"},
{ value: "G464",	text: "SINDROME DE INFARTO CEREBELOSO (I60-I67†)"},
{ value: "G465",	text: "SINDROME LACUNAR MOTOR PURO (I60- I67†)"},
{ value: "G466",	text: "SINDROME LACUNAR SENSORIAL PURO (I60-I67†)"},
{ value: "G467",	text: "OTROS SINDROMES LACUNARES (I60- I67†)"},
{ value: "G468",	text: "OTROS SINDROMES VASCULARES ENCEFALICOS EN ENFERMEDADES CEREBROVASCULARES (I60-I67†)"},
{ value: "G470",	text: "TRASTORNOS DEL INICIO Y DEL MANTENIMIENTO DEL SUEÑO [INSOMNIOS]"},
{ value: "G471",	text: "TRASTORNOS DE SOMNOLENCIA EXCESIVA [HIPERSOMNIOS]"},
{ value: "G472",	text: "TRASTORNOS DEL RITMO NICTAMERAL"},
{ value: "G473",	text: "APNEA DEL SUEÑO"},
{ value: "G474",	text: "NARCOLEPSIA Y CATAPLEXIA"},
{ value: "G478",	text: "OTROS TRASTORNOS DEL SUEÑO"},
{ value: "G479",	text: "TRASTORNO DEL SUEÑO, NO ESPECIFICADO"},
{ value: "G500",	text: "NEURALGIA DEL TRIGEMINO"},
{ value: "G501",	text: "DOLOR FACIAL ATIPICO"},
{ value: "G508",	text: "OTROS TRASTORNOS DEL TRIGEMINO"},
{ value: "G509",	text: "TRASTORNOS DEL TRIGEMINO, NO ESPECIFICADO"},
{ value: "G510",	text: "PARALISIS DE BELL"},
{ value: "G511",	text: "GANGLIONITIS GENICULADA"},
{ value: "G512",	text: "SINDROME DE MELKERSSON"},
{ value: "G513",	text: "ESPASMO HEMIFACIAL CLONICO"},
{ value: "G514",	text: "MIOQUIMIA FACIAL"},
{ value: "G518",	text: "OTROS TRASTORNOS DEL NERVIO FACIAL"},
{ value: "G519",	text: "TRASTORNOS DEL NERVIO FACIAL, NO ESPECIFICADO"},
{ value: "G520",	text: "TRASTORNOS DEL NERVIO OLFATORIO"},
{ value: "G521",	text: "TRASTORNOS DEL NERVIO GLOSOFARINGEO"},
{ value: "G522",	text: "TRASTORNOS DEL NERVIO VAGO"},
{ value: "G523",	text: "TRASTORNOS DEL NERVIO HIPOGLOSO"},
{ value: "G527",	text: "TRASTORNOS DE MULTIPLES NERVIOS CRANEALES"},
{ value: "G528",	text: "TRASTORNOS DE OTROS NERVIOS CRANEALES ESPECIFICADOS"},
{ value: "G529",	text: "TRASTORNO DE NERVIO CRANEAL, NO ESPECIFICADO"},
{ value: "G530",	text: "NEURALGIA POSTHERPES ZOSTER (B02.2†)"},
{ value: "G531",	text: "PARALISIS MULTIPLE DE LOS NERVIOS CRANEALES EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE (A00- B99†)"},
{ value: "G532",	text: "PARALISIS MULTIPLE DE LOS NERVIOS CRANEALES, EN LA SARCOIDOSIS (D86.8†)"},
{ value: "G533",	text: "PARALISIS MULTIPLE DE LOS NERVIOS CRANEALES, EN ENFERMEDADES NEOPLASICAS (C00-D48†)"},
{ value: "G538",	text: "OTROS TRASTORNOS DE LOS NERVIOS CRANEALES EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G540",	text: "TRASTORNOS DEL PLEXO BRAQUIAL"},
{ value: "G541",	text: "TRASTORNOS DEL PLEXO LUMBOSACRO"},
{ value: "G542",	text: "TRASTORNOS DE LA RAIZ CERVICAL, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "G543",	text: "TRASTORNOS DE LA RAIZ TORACICA, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "G544",	text: "TRASTORNOS DE LA RAIZ LUMBOSACRA, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "G545",	text: "AMIOTROFIA NEURALGICA"},
{ value: "G546",	text: "SINDROME DEL MIEMBRO FANTASMA CON DOLOR"},
{ value: "G547",	text: "SINDROME DEL MIEMBRO FANTASMA SIN DOLOR"},
{ value: "G548",	text: "OTROS TRASTORNOS DE LAS RAICES Y PLEXOS NERVIOSOS"},
{ value: "G549",	text: "TRASTORNO DE LA RAIZ Y PLEXOS NERVIOSOS, NO ESPECIFICADO"},
{ value: "G550",	text: "COMPRESIONES DE LAS RAICES Y PLEXOS NERVIOSOS EN ENFERMEDADES NEOPLASICAS (C00-D48†)"},
{ value: "G551",	text: "COMPRESIONES DE LAS RAICES Y PLEXOS NERVIOSOS EN TRASTORNOS DE LOS DISCOS INTERVERTEBRALES (M50-M51†)"},
{ value: "G552",	text: "COMPRESIONES DE LAS RAICES Y PLEXOS NERVIOSOS EN LA ESPONDILOSIS (M47.-†)"},
{ value: "G553",	text: "COMPRESIONES DE LAS RAICES Y PLEXOS NERVIOSOS EN OTRAS DORSOPATIAS (M45-M46†, M48.-†, M53- M54†)"},
{ value: "G558",	text: "COMPRESIONES DE LAS RAICES Y PLEXOS NERVIOSOS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G560",	text: "SINDROME DEL TUNEL CARPIANO"},
{ value: "G561",	text: "OTRAS LESIONES DEL NERVIO MEDIANO"},
{ value: "G562",	text: "LESION DEL NERVIO CUBITAL"},
{ value: "G563",	text: "LESION DEL NERVIO RADIAL"},
{ value: "G564",	text: "CAUSALGIA"},
{ value: "G568",	text: "OTRAS MONONEUROPATIAS DEL MIEMBRO SUPERIOR"},
{ value: "G569",	text: "MONONEUROPATIA DEL MIEMBRO SUPERIOR SIN OTRA ESPECIFICACION"},
{ value: "G570",	text: "LESION DEL NERVIO CIATICO"},
{ value: "G571",	text: "MERALGIA PARESTESICA"},
{ value: "G572",	text: "LESION DEL NERVIO CRURAL"},
{ value: "G573",	text: "LESION DEL NERVIO CIATICO POPLITEO EXTERNO"},
{ value: "G574",	text: "LESION DEL NERVIO CIATICO POPLITEO INTERNO"},
{ value: "G575",	text: "SINDROME DEL TUNEL CALCANEO"},
{ value: "G576",	text: "LESION DEL NERVIO PLANTAR"},
{ value: "G578",	text: "OTRAS MONONEUROPATIAS DEL MIEMBRO INFERIOR"},
{ value: "G579",	text: "MONONEUROPATIA DEL MIEMBRO INFERIOR, SIN OTRA ESPECIFICACION"},
{ value: "G580",	text: "NEUROPATIA INTERCOSTAL"},
{ value: "G587",	text: "MONONEURITIS MULTIPLE"},
{ value: "G588",	text: "OTRAS MONONEUROPATIAS ESPECIFICADAS"},
{ value: "G589",	text: "MONONEUROPATIA, NO ESPECIFICADA"},
{ value: "G590",	text: "MONONEUROPATIA DIABETICA (E10- E14† COMO CUARTO CARACTER COMUN .4)"},
{ value: "G598",	text: "OTRAS MONONEUROPATIAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G600",	text: "NEUROPTIA HEREDITARIA MOTORA Y SENSORIAL"},
{ value: "G601",	text: "ENFERMEDAD DE REFSUM"},
{ value: "G602",	text: "NEUROPATIA ASOCIADA CON ATAXIA HEREDITARIA"},
{ value: "G603",	text: "NEUROPATIA PROGRESIVA IDIOPATICA"},
{ value: "G608",	text: "OTRAS NEUROPATIAS HEREDITARIAS E IDIOPATICAS"},
{ value: "G609",	text: "NEUROPATIA HEREDITARIA E IDIOPATICA, SIN OTRA ESPECIFICACION"},
{ value: "G610",	text: "SINDROME DE GUILLAIN-BARRE"},
{ value: "G611",	text: "NEUROPATIA AL SUERO"},
{ value: "G618",	text: "OTRAS POLINEUROPATIAS INFLAMATORIAS"},
{ value: "G619",	text: "POLINEUROPATIA INFLAMATORIA, NO ESPECIFICADA"},
{ value: "G620",	text: "POLINEUROPATIA INDUCIDA POR DROGAS"},
{ value: "G621",	text: "POLINEUROPATIA ALCOHOLICA"},
{ value: "G622",	text: "POLINEUROPATIA DEBIDA A OTRO AGENTE TOXICO"},
{ value: "G628",	text: "OTRAS POLINEUROPATIAS ESPECIFICADAS"},
{ value: "G629",	text: "POLINEUROPATIA, NO ESPECIFICADA"},
{ value: "G630",	text: "POLINEUROPATIA EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "G631",	text: "POLINEUROPATIA EN ENFERMEDAD NEOPLASICA (C00-D48†)"},
{ value: "G632",	text: "POLINEUROPATIA DIABETICA (E10-E14† CON CUARTO CARACTER COMUN .4)"},
{ value: "G633",	text: "POLINEUROPATIA EN OTRAS ENFERMEDADES ENDOCRINAS Y METABOLICAS (E00-E07†, E15-E16†, E20-E34†, E70-E89†)"},
{ value: "G634",	text: "POLINEUROPATIA EN DEFICIENCIA NUTRICIONAL (E40-E64†)"},
{ value: "G635",	text: "POLINEUROPATIA EN TRASTORNOS DEL TEJIDO CONECTIVO SISTEMICO (M30- M35†)"},
{ value: "G636",	text: "POLINEUROPATIA EN OTROS TRASTORNOS OSTEOMUSCULARES (M00- M25†, M40-M96†)"},
{ value: "G638",	text: "POLINEUROPATIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G64X",	text: "OTROS TRASTORNOS DEL SISTEMA NERVIOSO PERIFERICO"},
{ value: "G700",	text: "MIASTENIA GRAVIS"},
{ value: "G701",	text: "TRASTORNOS TOXICOS NEUROMUSCULARES"},
{ value: "G702",	text: "MIASTENIA CONGENITA O DEL DESARROLLO"},
{ value: "G708",	text: "OTROS TRASTORNOS NEUROMUSCULARES ESPECIFICADOS"},
{ value: "G709",	text: "TRASTORNO NEUROMUSCULAR, NO ESPECIFICADO"},
{ value: "G710",	text: "DISTROFIA MUSCULAR"},
{ value: "G711",	text: "TRASTORNOS MIOTONICOS"},
{ value: "G712",	text: "MIOPATIAS CONGENITAS"},
{ value: "G713",	text: "MIOPATIA MITOCONDRICA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "G718",	text: "OTROS TRASTORNOS PRIMARIOS DE LOS MUSCULOS"},
{ value: "G719",	text: "TRASTORNO PRIMARIO DEL MUSCULO, TIPO NO ESPECIFICADO"},
{ value: "G720",	text: "MIOPATIA INDUCIDA POR DROGAS"},
{ value: "G721",	text: "MIOPATIA ALCOHOLICA"},
{ value: "G722",	text: "MIOPATIA DEBIDA A OTROS AGENTES TOXICOS"},
{ value: "G723",	text: "PARALISIS PERIODICA"},
{ value: "G724",	text: "MIOPATIA INFLAMATORIA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "G728",	text: "OTRAS MIOPATIAS ESPECIFICADAS"},
{ value: "G729",	text: "MIOPATIA, NO ESPECIFICADA"},
{ value: "G730",	text: "SINDROMES MIASTENICOS EN ENFERMEDADES ENDOCRINAS"},
{ value: "G731",	text: "SINDROME DE EATON-LAMBERT (C80†)"},
{ value: "G732",	text: "OTROS SINDROMES MIASTENICOS EN ENFERMEDAD NEOPLASICA (C00-D48†)"},
{ value: "G733",	text: "SINDROMES MIASTENICOS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G734",	text: "MIOPATIA EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "G735",	text: "MIOPATIA EN ENFERMEDADES ENDOCRINAS"},
{ value: "G736",	text: "MIOPATIA EN ENFERMEDADES METABOLICAS"},
{ value: "G737",	text: "MIOPATIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G800",	text: "PARALISIS CEREBRAL ESPASTICA"},
{ value: "G801",	text: "DIPLEJIA ESPASTICA"},
{ value: "G802",	text: "HEMIPLEJIA INFANTIL"},
{ value: "G803",	text: "PARALISIS CEREBRAL DISCINETICA"},
{ value: "G804",	text: "PARALISIS CEREBRAL ATAXICA"},
{ value: "G808",	text: "OTROS TIPOS DE PARALISIS CEREBRAL INFANTIL"},
{ value: "G809",	text: "PARALISIS CEREBRAL INFANTIL, SIN OTRA ESPECIFICACION"},
{ value: "G810",	text: "HEMIPLEJIA FLACIDA"},
{ value: "G811",	text: "HEMIPLEJIA ESPASTICA"},
{ value: "G819",	text: "HEMIPLEJIA, NO ESPECIFICADA"},
{ value: "G820",	text: "PARAPLEJIA FLACIDA"},
{ value: "G821",	text: "PARAPLEJIA ESPASTICA"},
{ value: "G822",	text: "PARAPLEJIA, NO ESPECIFICADA"},
{ value: "G823",	text: "CUADRIPLEJIA FLACIDA"},
{ value: "G824",	text: "CUADRIPLEJIA ESPASTICA"},
{ value: "G825",	text: "CUADRIPLEJIA, NO ESPECIFICADA"},
{ value: "G830",	text: "DIPLEJIA DE LOS MIEMBROS SUPERIORES"},
{ value: "G831",	text: "MONOPLEJIA DEL MIEMBRO INFERIOR"},
{ value: "G832",	text: "MONOPLEJIA DEL MIEMBRO SUPERIOR"},
{ value: "G833",	text: "MONOPLEJIA, NO ESPECIFICADA"},
{ value: "G834",	text: "SINDROME DE LA COLA DE CABALLO"},
{ value: "G838",	text: "OTROS SINDROMES PARALITICOS ESPECIFICADOS"},
{ value: "G839",	text: "SINDROME PARALITICO, NO ESPECIFICADO"},
{ value: "G900",	text: "NEUROPATIA AUTONOMA PERIFERICA IDIOPATICA"},
{ value: "G901",	text: "DISAUTONOMIA FAMILIAR [SINDROME DE RILEY-DAY]"},
{ value: "G902",	text: "SINDROME DE HORNER"},
{ value: "G903",	text: "DEGENERACION DE SISTEMAS MULTIPLES"},
{ value: "G908",	text: "OTROS TRASTORNOS DEL SISTEMA NERVIOSO AUTONOMO"},
{ value: "G909",	text: "TRASTORNO DEL SISTEMA NERVIOSO AUTONOMO, NO ESPECIFICADO"},
{ value: "G910",	text: "HIDROCEFALO COMUNICANTE"},
{ value: "G911",	text: "HIDROCEFALO OBSTRUCTIVO"},
{ value: "G912",	text: "HIDROCEFALO DE PRESION NORMAL"},
{ value: "G913",	text: "HIDROCEFALO POSTRAUMATICO, SIN OTRA ESPECIFICACION"},
{ value: "G918",	text: "OTROS TIPOS DE HIDROCEFALO"},
{ value: "G919",	text: "HIDROCEFALO, NO ESPECIFICADO"},
{ value: "G92X",	text: "ENCEFALOPATIA TOXICA"},
{ value: "G930",	text: "QUISTE CEREBRAL"},
{ value: "G931",	text: "LESION CEREBRAL ANOXICA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "G932",	text: "HIPERTENSION INTRACRANEAL BENIGNA"},
{ value: "G933",	text: "SINDROME DE FATIGA POSTVIRAL"},
{ value: "G934",	text: "ENCEFALOPATIA NO ESPECIFICADA"},
{ value: "G935",	text: "COMPRESION DEL ENCEFALO"},
{ value: "G936",	text: "EDEMA CEREBRAL"},
{ value: "G937",	text: "SINDROME DE REYE"},
{ value: "G938",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL ENCEFALO"},
{ value: "G939",	text: "TRASTORNO DEL ENCEFALO, NO ESPECIFICADO"},
{ value: "G940",	text: "HIDROCEFALO EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE (A00- B99†)"},
{ value: "G941",	text: "HIDROCEFALO EN ENFERMEDAD NEOPLASICA (C00-D48†)"},
{ value: "G942",	text: "HIDROCEFALO EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G948",	text: "OTROS TRASTORNOS ENCEFALITICOS ESPECIFICADOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G950",	text: "SIRINGOMIELIA Y SIRINGOBULBIA"},
{ value: "G951",	text: "MIELOPATIAS VASCULARES"},
{ value: "G952",	text: "COMPRESION MEDULAR, NO ESPECIFICADA"},
{ value: "G958",	text: "OTRAS ENFERMEDADES ESPECIFICADAS DE LA MEDULA ESPINAL"},
{ value: "G959",	text: "ENFERMEDAD DE LA MEDULA ESPINAL, NO ESPECIFICADA"},
{ value: "G960",	text: "PERDIDA DE LIQUIDO CEFALORRAQUIDEO"},
{ value: "G961",	text: "TRASTORNOS DE LA MENINGES, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "G968",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL SISTEMA NERVIOSO CENTRAL"},
{ value: "G969",	text: "TRASTORNO DEL SISTEMA NERVIOSO CENTRAL, NO ESPECIFICADO"},
{ value: "G970",	text: "PERDIDA DE LIQUIDO CEFALORRAQUIDEO POR PUNCION ESPINAL"},
{ value: "G971",	text: "OTRA REACCION A LA PUNCION ESPINAL Y LUMBAR"},
{ value: "G972",	text: "HIPOTENSION INTRACRANEAL POSTERIOR A ANASTOMOSIS VENTRICULAR"},
{ value: "G978",	text: "OTROS TRASTORNOS DEL SISTEMA NERVIOSO CONSECUTIVOS A PROCEDIMIENTOS"},
{ value: "G979",	text: "TRASTORNOS NO ESPECIFICADOS DEL SISTEMA NERVIOSO, CONSECUTIVOS A PROCEDIMIENTOS"},
{ value: "G98X",	text: "OTROS TRASTORNOS DEL SISTEMA NERVIOSO, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "G990",	text: "NEUROPATIA AUTONOMICA EN ENFERNEDADES METABOLICAS Y ENDOCRINAS"},
{ value: "G991",	text: "OTROS TRASTORNOS DEL SISTEMA NERVIOSO AUTONOMO EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G992",	text: "MIELOPATIA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "G998",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL SISTEMA NERVIOSO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H000",	text: "ORZUELO Y OTRAS INFLAMACIONES PROFUNDAS DEL PARPADO"},
{ value: "H001",	text: "CALACIO [CHALAZION]"},
{ value: "H010",	text: "BLEFARITIS"},
{ value: "H011",	text: "DERMATOSIS NO INFECCIOSA DEL PARPADO"},
{ value: "H018",	text: "OTRAS INFLAMACIONES ESPECIFICADAS DEL PARPADO"},
{ value: "H019",	text: "INFLAMACION DEL PARPADO, NO ESPECIFICADA"},
{ value: "H020",	text: "ENTROPION Y TRIQUIASIS PALPEBRAL"},
{ value: "H021",	text: "ECTROPION DEL PARPADO"},
{ value: "H022",	text: "LAGOFTALMOS"},
{ value: "H023",	text: "BLEFAROCALASIA"},
{ value: "H024",	text: "BLEFAROPTOSIS"},
{ value: "H025",	text: "OTROS TRASTORNOS FUNCIONALES DEL PARPADO"},
{ value: "H026",	text: "XANTELASMA DEL PARPADO"},
{ value: "H027",	text: "OTROS TRASTORNOS DEGENERATIVOS DEL PARPADO Y DEL AREA PERIOCULAR"},
{ value: "H028",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL PARPADO"},
{ value: "H029",	text: "TRASTORNOS DEL PARPADO, NO ESPECIFICADO"},
{ value: "H030",	text: "INFECCION E INFESTACION PARASITARIAS DEL PARPADO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H031",	text: "COMPROMISO DEL PARPADO EN ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE"},
{ value: "H038",	text: "COMPROMISO DEL PARPADO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H040",	text: "DACRIOADENITIS"},
{ value: "H041",	text: "OTROS TRASTORNOS DE LA GLANDULA LAGRIMAL"},
{ value: "H042",	text: "EPIFORA"},
{ value: "H043",	text: "INFLAMACION AGUDA Y LA NO ESPECIFICADA DE LAS VIAS LAGRIMALES"},
{ value: "H044",	text: "INFLAMACION CRONICA DE LAS VIAS LAGRIMALES"},
{ value: "H045",	text: "ESTENOSIS E INSUFICIENCIA DE LAS VIAS LAGRIMALES"},
{ value: "H046",	text: "OTROS CAMBIOS DE LAS VIAS LAGRIMALES"},
{ value: "H048",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL APARATO LAGRIMAL"},
{ value: "H049",	text: "TRASTORNO DEL APARATO LAGRIMAL, NO ESPECIFICADO"},
{ value: "H050",	text: "INFLAMACION AGUDA DE LA ORBITA"},
{ value: "H051",	text: "TRASTORNOS INFLAMATORIOS CRONICOS DE LA ORBITA"},
{ value: "H052",	text: "AFECCIONES EXOFTALMICAS"},
{ value: "H053",	text: "DEFORMIDAD DE LA ORBITA"},
{ value: "H054",	text: "ENOFTALMIA"},
{ value: "H055",	text: "RETENCION DE CUERPO EXTRAÑO (ANTIGUO), CONSECUTIVA A HERIDA PENETRANTE DE LA ORBITA"},
{ value: "H058",	text: "OTROS TRASTORNOS DE LA ORBITA"},
{ value: "H059",	text: "TRASTORNO DE LA ORBITA, NO ESPECIFICADO"},
{ value: "H060",	text: "TRASTORNOS DEL APARATO LAGRIMAL EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H061",	text: "INFECCION O INFESTACION PARASITARIA DE LA ORBITA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H062",	text: "EXOFTALMIA HIPERTIROIDEA (E05.-†)"},
{ value: "H063",	text: "OTROS TRASTORNOS DE LA ORBITA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H100",	text: "CONJUNTIVITIS MUCOPURULENTA"},
{ value: "H101",	text: "CONJUNTIVITIS ATOPICA AGUDA"},
{ value: "H102",	text: "OTRAS CONJUNTIVITIS AGUDAS"},
{ value: "H103",	text: "CONJUNTIVITIS AGUDA, NO ESPECIFICADA"},
{ value: "H104",	text: "CONJUNTIVITIS CRONICA"},
{ value: "H105",	text: "BLEFAROCONJUNTIVITIS"},
{ value: "H108",	text: "OTRAS CONJUNTIVITIS"},
{ value: "H109",	text: "CONJUNTIVITIS, NO ESPECIFICADA"},
{ value: "H110",	text: "PTERIGION"},
{ value: "H111",	text: "DEGENERACIONES Y DEPOSITOS CONJUNTIVALES"},
{ value: "H112",	text: "CICATRICES CONJUNTIVALES"},
{ value: "H113",	text: "HEMORRAGIA CONJUNTIVAL"},
{ value: "H114",	text: "OTROS TRASTORNOS VASCULARES Y QUISTES CONJUNTIVALES"},
{ value: "H118",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA CONJUNTIVA"},
{ value: "H119",	text: "TRASTORNO DE LA CONJUNTIVA, NO ESPECIFICADO"},
{ value: "H130",	text: "INFECCION FILARICA DE LA CONJUNTIVA (B74.-†)"},
{ value: "H131",	text: "CONJUNTIVITIS EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "H132",	text: "CONJUNTIVITIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H133",	text: "PENFIGOIDE OCULAR (L12.-†)"},
{ value: "H138",	text: "OTROS TRASTORNOS DE LA CONJUNTIVA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H150",	text: "ESCLERITIS"},
{ value: "H151",	text: "EPISCLERITIS"},
{ value: "H158",	text: "OTROS TRASTORNOS DE LA ESCLEROTICA"},
{ value: "H159",	text: "TRASTORNOS DE LA ESCLEROTICA, NO ESPECIFICADO"},
{ value: "H160",	text: "ULCERA DE LA CORNEA"},
{ value: "H161",	text: "OTRAS QUERATITIS SUPERFICIALES SIN CUNJUNTIVITIS"},
{ value: "H162",	text: "QUERATOCONJUNTIVITIS"},
{ value: "H163",	text: "QUERATITIS INTERSTICIAL Y PROFUNDA"},
{ value: "H164",	text: "NEOVASCULARIZACION DE LA CORNEA"},
{ value: "H168",	text: "OTRAS QUERATITIS"},
{ value: "H169",	text: "QUERATITIS, NO ESPECIFICADA"},
{ value: "H170",	text: "LEUCOMA ADHERENTE"},
{ value: "H171",	text: "OTRAS OPACIDADES CENTRALES DE LA CORNEA"},
{ value: "H178",	text: "OTRAS OPACIDADES O CICATRICES DE LA CORNEA"},
{ value: "H179",	text: "CICATRIZ U OPACIDAD DE LA CORNEA, NO ESPECIFICADA"},
{ value: "H180",	text: "PIGMENTACIONES Y DEPOSITOS EN LA CORNEA"},
{ value: "H181",	text: "QUERATOPATIA VESICULAR"},
{ value: "H182",	text: "OTROS EDEMAS DE LA CORNEA"},
{ value: "H183",	text: "CAMBIOS EN LAS MEMBRANAS DE LA CORNEA"},
{ value: "H184",	text: "DEGENERACION DE LA CORNEA"},
{ value: "H185",	text: "DISTROFIA HEREDITARIA DE LA CORNEA"},
{ value: "H186",	text: "QUERATOCONO"},
{ value: "H187",	text: "OTRAS DEFORMIDADES DE LA CORNEA"},
{ value: "H188",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA CORNEA"},
{ value: "H189",	text: "TRASTORNO DE LA CORNEA, NO ESPECIFICADO"},
{ value: "H190",	text: "ESCLERITIS Y EPISCLERITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H191",	text: "QUERATITIS Y QUERATOCONJUNTIVITIS POR HERPES SIMPLE (B00.5†)"},
{ value: "H192",	text: "QUERATITIS Y QUERATOCONJUNTIVITIS EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS, CLASIFICADAS EN OTRA PARTE"},
{ value: "H193",	text: "QUERATITIS Y QUERATOCONJUNTIVITIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H198",	text: "OTROS TRASTORNOS DE LA ESCLEROTICA Y DE LA CORNEA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H200",	text: "IRIDOCICLITIS AGUDA Y SUBAGUDA"},
{ value: "H201",	text: "IRIDOCICLITIS CRONICA"},
{ value: "H202",	text: "IRIDOCICLITIS INDUCIDA POR TRASTORNO DEL CRISTALINO"},
{ value: "H208",	text: "OTRAS IRIDOCICLITIS ESPECIFICADAS"},
{ value: "H209",	text: "IRIDOCICLITIS, NO ESPECIFICADA"},
{ value: "H210",	text: "HIFEMA"},
{ value: "H211",	text: "OTROS TRASTORNOS VASCULARES DEL IRIS Y DEL CUERPO CILIAR"},
{ value: "H212",	text: "DEGENERACION DEL IRIS Y DEL CUERPO CILIAR"},
{ value: "H213",	text: "QUISTE DEL IRIS, DEL CUERPO CILIAR Y DE LA CAMARA ANTERIOR"},
{ value: "H214",	text: "MEMBRANAS PUPILARES"},
{ value: "H215",	text: "OTRAS ADHERENCIAS Y DESGARROS DEL IRIS Y DEL CUERPO CILIAR"},
{ value: "H218",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL IRIS Y DEL CUERPO CILIAR"},
{ value: "H219",	text: "DEL IRIS Y DEL CUERPO CILIAR, NO ESPECIFICADO"},
{ value: "H220",	text: "IRIDOCICLITIS EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "H221",	text: "IRIDOCICLITIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H228",	text: "OTROS TRASTORNOS DEL IRIS Y DEL CUERPO CILIAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H250",	text: "CATARATA SENIL INCIPIENTE"},
{ value: "H251",	text: "CATARATA SENIL NUCLEAR"},
{ value: "H252",	text: "CATARATA SENIL, TIPO MORGAGNIAN"},
{ value: "H258",	text: "OTRAS CATARATAS SENILES"},
{ value: "H259",	text: "CATARATA SENIL, NO ESPECIFICADA"},
{ value: "H260",	text: "CATARATA INFANTIL, JUVENIL Y PRESENIL"},
{ value: "H261",	text: "CATARATA TRAUMATICA"},
{ value: "H262",	text: "CATARATA COMPLICADA"},
{ value: "H263",	text: "CATARATA INDUCIDA POR DROGAS"},
{ value: "H264",	text: "CATARATA RESIDUAL"},
{ value: "H268",	text: "OTRAS FORMAS ESPECIFICADAS DE CATARATA"},
{ value: "H269",	text: "CATARATA, NO ESPECIFICADA"},
{ value: "H270",	text: "AFAQUIA"},
{ value: "H271",	text: "LUXACION DEL CRISTALINO"},
{ value: "H278",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL CRISTALINO"},
{ value: "H279",	text: "TRASTORNO DEL CRISTALINO, NO ESPECIFICADO"},
{ value: "H280",	text: "CATARATA DIABETICA (E10-E14† CON CUARTO CARACTER COMUN .3)"},
{ value: "H281",	text: "CATARATA EN OTRAS ENFERMEDADES ENDOCRINAS, NUTRICIONALES Y METABOLICAS CLASIFICADAS EN OTRA PARTE"},
{ value: "H282",	text: "CATARATA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H288",	text: "OTROS TRASTORNOS DEL CRISTALINO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H300",	text: "CORIORRETINITIS FOCAL"},
{ value: "H301",	text: "CORIORRETINITIS DISEMINADA"},
{ value: "H302",	text: "CICLITIS POSTERIOR"},
{ value: "H308",	text: "OTRAS CORIORRETINITIS"},
{ value: "H309",	text: "CORIORRETINITIS, NO ESPECIFICADA"},
{ value: "H310",	text: "CICATRICES CORIORRETINIANAS"},
{ value: "H311",	text: "DESGENERACION COROIDEA"},
{ value: "H312",	text: "DISTROFIA COROIDEA HEREDITARIA"},
{ value: "H313",	text: "HEMORRAGIA Y RUPTURA DE LA COROIDES"},
{ value: "H314",	text: "DESPRENDIMIENTO DE LA COROIDES"},
{ value: "H318",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA COROIDES"},
{ value: "H319",	text: "TRASTORNO DE LA COROIDES, NO ESPECIFICADO"},
{ value: "H320",	text: "INFLAMACION CORIORRETINIANA EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "H328",	text: "OTROS TRASTORNOS CORIORRETINIANOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H330",	text: "DESPRENDIMIENTO DE LA RETINA CON RUPTURA"},
{ value: "H331",	text: "RETINOSQUISIS Y QUISTES DE LA RETINA"},
{ value: "H332",	text: "DESPRENDIMIENTO SEROSO DE LA RETINA"},
{ value: "H333",	text: "DESGARRO DE LA RETINA SIN DESPRENDIMIENTO"},
{ value: "H334",	text: "DESPRENDIMIENTO DE LA RETINA POR TRACCION"},
{ value: "H335",	text: "OTROS DESPRENDIMIENTO DE LA RETINA"},
{ value: "H340",	text: "OCLUSION ARTERIAL TRANSITORIA DE LA RETINA"},
{ value: "H341",	text: "OCLUSION DE LA ARTERIA CENTRAL DE LA RETINA"},
{ value: "H342",	text: "OTRAS FORMAS DE OCLUSION DE LA ARTERIA DE LA RETINA"},
{ value: "H348",	text: "OTRAS OCLUSIONES VASCULARES RETINIANAS"},
{ value: "H349",	text: "OCLUSION VASCULAR RETINIANA, SIN OTRA ESPECIFICACION"},
{ value: "H350",	text: "RETINOPATIAS DEL FONDO Y CAMBIOS VASCULARES RETINIANOS"},
{ value: "H351",	text: "RETINOPATIA DE LA PREMATURIDAD"},
{ value: "H352",	text: "OTRAS RETINOPATIAS PROLIFERATIVAS"},
{ value: "H353",	text: "DEGENERACION DE LA MACULA Y DEL POLO POSTERIOR DEL OJO"},
{ value: "H354",	text: "DEGENERACION PERIFERICA DE LA RETINA"},
{ value: "H355",	text: "DISTROFIA HEREDITARIA DE LA RETINA"},
{ value: "H356",	text: "HEMORRAGIA RETINIANA"},
{ value: "H357",	text: "SEPARACION DE LAS CASPAS DE LA RETINA"},
{ value: "H358",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA RETINA"},
{ value: "H359",	text: "TRASTORNO DE LA RETINA, NO ESPECIFICADO"},
{ value: "H360",	text: "RETINOPATIA DIABETICA (E10-E14† CON CUARTO CARACTER COMUN .3)"},
{ value: "H368",	text: "OTROS TRASTORNOS DE LA RETINA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H400",	text: "SOSPECHA DE GLAUCOMA"},
{ value: "H401",	text: "GLAUCOMA PRIMARIO DE ANGULO ABIERTO"},
{ value: "H402",	text: "GLAUCOMA PRIMARIO DE ANGULO CERRADO"},
{ value: "H403",	text: "GLAUCOMA SECUNDARIO A TRAUMATISMO OCULAR"},
{ value: "H404",	text: "GLAUCOMA SECUNDARIO A INFLAMACION OCULAR"},
{ value: "H405",	text: "GLAUCOMA SECUNDARIO A OTROS TRASTORNOS DEL OJO"},
{ value: "H406",	text: "GLAUCOMA SECUNDARIO A DROGAS"},
{ value: "H408",	text: "OTROS GLAUCOMAS"},
{ value: "H409",	text: "GLAUCOMA, NO ESPECIFICADO"},
{ value: "H420",	text: "GLAUCOMA EN ENFERMEDADES ENDOCRINAS, NUTRICIONALES Y METABOLICAS, CLASIFICADAS EN OTRA PARTE"},
{ value: "H428",	text: "GLAUCOMA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H430",	text: "PROLAPSO DEL VITREO"},
{ value: "H431",	text: "HEMORRAGIA DEL VITREO"},
{ value: "H432",	text: "DEPOSITOS CRISTALINOS EN EL CUERPO VITREO"},
{ value: "H433",	text: "OTRAS OPACIDADES VITREAS"},
{ value: "H438",	text: "OTROS TRASTORNOS DEL CUERPO VITREO"},
{ value: "H439",	text: "TRASTORNOS DEL CUERPO VITREO, NO ESPECIFICADO"},
{ value: "H440",	text: "ENDOFTALMITIS PURULENTA"},
{ value: "H441",	text: "OTRAS ENDOFTALMITIS"},
{ value: "H442",	text: "MIOPIA DEGENERATIVA"},
{ value: "H443",	text: "OTROS TRASTORNOS DEGENERATIVOS DEL GLOBO OCULAR"},
{ value: "H444",	text: "HIPOTONIA OCULAR"},
{ value: "H445",	text: "AFECCIONES DEGENERATIVAS DEL GLOBO OCULAR"},
{ value: "H446",	text: "RETENCION INTRAOCULAR DE CUERPO EXTRAÑO MAGNETICO (ANTIGUO)"},
{ value: "H447",	text: "RETENCION INTRAOCULAR DE CUERPO EXTRAÑO NO MAGNETICO (ANTIGUO)"},
{ value: "H448",	text: "OTROS TRASTORNOS DEL GLOBO OCULAR"},
{ value: "H449",	text: "TRASTORNO DEL GLOBO OCULAR, NO ESPECIFICADO"},
{ value: "H450",	text: "HEMORRAGIA DEL VITREO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H451",	text: "ENDOFTALMITIS EN ENFERNEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H458",	text: "OTROS TRASTORNOS DEL CUERPO VITREO Y DEL GLOBO OCULAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H46X",	text: "NEURITIS OPTICA"},
{ value: "H470",	text: "TRASTORNOS DEL NERVIO OPTICO, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "H471",	text: "PAPILEDEMA, NO ESPECIFICADO"},
{ value: "H472",	text: "ATROFIA OPTICA"},
{ value: "H473",	text: "OTROS TRASTORNOS DEL DISCO OPTICO"},
{ value: "H474",	text: "TRASTORNOS DEL QUIASMA OPTICO"},
{ value: "H475",	text: "TRASTORNOS DE OTRAS VIAS OPTICAS"},
{ value: "H476",	text: "TRASTORNOS DE LA CORTEZA VISUAL"},
{ value: "H477",	text: "TRASTORNOS DE LAS VIAS OPTICAS, NO ESPECIFICADO"},
{ value: "H480",	text: "ATROFIA OPTICA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H481",	text: "NEURITIS RETROBULBAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H488",	text: "OTROS TRASTORNOS DEL NERVIO OPTICO Y DE LAS VIAS OPTICAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H490",	text: "PARALISIS DEL NERVIO MOTOR OCULAR COMUN [III PAR]"},
{ value: "H491",	text: "PARALISIS DEL NERVIO PATETICO [IV PAR]"},
{ value: "H492",	text: "PARALISIS DEL NERVIO MOTOR OCULAR EXTERNO [VI PAR]"},
{ value: "H493",	text: "OFTALMOPLEJIA TOTAL (EXTERNA)"},
{ value: "H494",	text: "OFTALMOPLEJIA EXTERNA PROGRESIVA"},
{ value: "H498",	text: "OTROS ESTRABISMOS PARALITICOS"},
{ value: "H499",	text: "ESTRABISMO PARALITICO, NO ESPECIFICADO"},
{ value: "H500",	text: "ESTRABISMO CONCOMITANTE CONVERGENTE"},
{ value: "H501",	text: "ESTRABISMO CONCOMITANTE DIVERGENTE"},
{ value: "H502",	text: "ESTRABISMO VERTICAL"},
{ value: "H503",	text: "HETEROTROPIA INTERMITENTE"},
{ value: "H504",	text: "OTRAS HETEROTROPIAS O LAS NO ESPECIFICADAS"},
{ value: "H505",	text: "HETEROFORIA"},
{ value: "H506",	text: "ESTRABISMO MECANICO"},
{ value: "H508",	text: "OTROS ESTRABISMOS ESPECIFICADOS"},
{ value: "H509",	text: "ESTRABISMO, NO ESPECIFICADO"},
{ value: "H510",	text: "PARALISIS DE LA CONJUGACION DE LA MIRADA"},
{ value: "H511",	text: "EXCESO E INSUFICIENCIA DE LA CONVERGENCIA OCULAR"},
{ value: "H512",	text: "OFTALMOPLEJIA INTERNUCLEAR"},
{ value: "H518",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LOS MOVIMIENTOS BINOCULARES"},
{ value: "H519",	text: "TRASTORNOS DEL MOVIMIENTO BINOCULAR, NO ESPECIFICADO"},
{ value: "H520",	text: "HIPERMETROPIA"},
{ value: "H521",	text: "MIOPIA"},
{ value: "H522",	text: "ASTIGMATISMO"},
{ value: "H523",	text: "ANISOMETROPIA Y ANISEICONIA"},
{ value: "H524",	text: "PRESBICIA"},
{ value: "H525",	text: "TRASTORNOS DE LA ACOMODACION"},
{ value: "H526",	text: "OTROS TRASTORNOS DE LA REFRACCION"},
{ value: "H527",	text: "TRASTORNO DE LA REFRACCION, NO ESPECIFICADO"},
{ value: "H530",	text: "AMBLIOPIA EX ANOPSIA"},
{ value: "H531",	text: "ALTERACIONES VISUALES SUBJETIVAS"},
{ value: "H532",	text: "DIPLOPIA"},
{ value: "H533",	text: "OTROS TRASTORNOS DE LA VISION BINOCULAR"},
{ value: "H534",	text: "DEFECTOS DEL CAMPO VISUAL"},
{ value: "H535",	text: "DEFICIENCIAS DE LA VISION CROMATICA"},
{ value: "H536",	text: "CEGUERA NOCTURNA"},
{ value: "H538",	text: "OTRAS ALTERACIONES VISUALES"},
{ value: "H539",	text: "ALTERACION VISUAL, NO ESPECIFICADA"},
{ value: "H540",	text: "CEGUERA DE AMBOS OJOS"},
{ value: "H541",	text: "CEGUERA DE UN OJO, VISION SUBNORMAL DEL OTRO"},
{ value: "H542",	text: "VISION SUBNORMAL DE AMBOS OJOS"},
{ value: "H543",	text: "DISMINUCION INDETERMINADA DE LA AGUDEZA VISUAL EN AMBOS OJOS"},
{ value: "H544",	text: "CEGUERA DE UN OJO"},
{ value: "H545",	text: "VISION SUBNORMAL DE UN OJO"},
{ value: "H546",	text: "DISMINUCION INDETERMINADA DE LA AGUDEZA VISUAL DE UN OJO"},
{ value: "H547",	text: "DISMINUCION DE LA AGUDEZA VISUAL, SIN ESPECIFICACION"},
{ value: "H55X",	text: "NISTAGMO Y OTROS MOVIMIENTOS OCULARES IRREGULARES"},
{ value: "H570",	text: "ANOMALIAS DE LA FUNCION PUPILAR"},
{ value: "H571",	text: "DOLOR OCULAR"},
{ value: "H578",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL OJO Y SUS ANEXOS"},
{ value: "H579",	text: "TRASTORNO DEL OJO Y SUS ANEXOS, NO ESPECIFICADO"},
{ value: "H580",	text: "ANOMALIAS DE LA FUNCION PUPILAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H581",	text: "ALTERACIONES DE LA VISION EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H588",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL OJO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H590",	text: "SINDROME VITREO CONSECUTIVO A CIRUGIA DE CATARATA"},
{ value: "H598",	text: "OTROS TRASTORNOS DEL OJO Y SUS ANEXOS, CONSECUTIVOS A PROCEDIMIENTOS"},
{ value: "H599",	text: "TRASTORNO NO ESPECIFICADO DEL OJO Y SUS ANEXOS, CONSECUTIVO A PROCEDIMIENTOS"},
{ value: "H600",	text: "ABSCESO DEL OIDO EXTERNO"},
{ value: "H601",	text: "CELULITIS DEL OIDO EXTERNO"},
{ value: "H602",	text: "OTITIS EXTERNA MALIGNA"},
{ value: "H603",	text: "OTRAS OTITIS EXTERNAS INFECCIOSAS"},
{ value: "H604",	text: "COLESTEATOMA DEL OIDO EXTERNO"},
{ value: "H605",	text: "OTITIS EXTERNA AGUDA, NO INFECCIOSA"},
{ value: "H608",	text: "OTRAS OTITIS EXTERNAS"},
{ value: "H609",	text: "OTITIS EXTERNA, SIN OTRA ESPECIFICACION"},
{ value: "H610",	text: "PERICONDRITIS DEL OIDO EXTERNO"},
{ value: "H611",	text: "AFECCIONES NO INFECCIOSAS DEL PABELLON AUDITIVO"},
{ value: "H612",	text: "CERUMEN IMPACTADO"},
{ value: "H613",	text: "ESTENOSIS ADQUIRIDA DEL CONDUCTO AUDITIVO EXTERNO"},
{ value: "H618",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL OIDO EXTERNO"},
{ value: "H619",	text: "TRASTORNO DEL OIDO EXTERNO, NO ESPECIFICADO"},
{ value: "H620",	text: "OTITIS EXTERNA EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"},
{ value: "H621",	text: "OTITIS EXTERNA EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE"},
{ value: "H622",	text: "OTITIS EXTERNA EN MICOSIS"},
{ value: "H623",	text: "OTITIS EXTERNA EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PATE"},
{ value: "H624",	text: "OTITIS EXTERNA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PATE"},
{ value: "H628",	text: "OTROS TRASTORNOS DEL OIDO EXTERNO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H650",	text: "OTITIS MEDIA AGUDA SEROSA"},
{ value: "H651",	text: "OTRA OTITIS MEDIA AGUDA, NO SUPURATIVA"},
{ value: "H652",	text: "OTITIS MEDIA CRONICA SEROSA"},
{ value: "H653",	text: "OTITIS MEDIA CRONICA MUCOIDE"},
{ value: "H654",	text: "OTRAS OTITIS MEDIAS CRONICAS NO SUPURATIVAS"},
{ value: "H659",	text: "OTITIS MEDIA NO SUPURATIVA, SIN OTRA ESPECIFICACION"},
{ value: "H660",	text: "OTITIS MEDIA SUPURATIVA AGUDA"},
{ value: "H661",	text: "OTITIS MEDIA TUBOTIMPANICA SUPURATIVA CRONICA"},
{ value: "H662",	text: "OTITIS MEDIA SUPURATIVA CRONICA ATICOANTRAL"},
{ value: "H663",	text: "OTRAS OTITIS MEDIAS CRONICAS SUPURATIVAS"},
{ value: "H664",	text: "OTITIS MEDIA SUPURATIVA, SIN OTRA ESPECIFICACION"},
{ value: "H669",	text: "OTITIS MEDIA, NO ESPECIFICADA"},
{ value: "H670",	text: "OTITIS MEDIA EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"},
{ value: "H671",	text: "OTITIS MEDIA EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE"},
{ value: "H678",	text: "OTITIS MEDIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H680",	text: "SALPINGITIS EUSTAQUIANA"},
{ value: "H681",	text: "OBSTRUCCION DE LA TROMPA DE EUSTAQUIO"},
{ value: "H690",	text: "DISTENSION DE LA TROMPA DE EUSTAQUIO"},
{ value: "H698",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA TROMPA DE EUSTAQUIO"},
{ value: "H699",	text: "TRASTORNO DE LA TROMPA DE EUSTAQUIO, NO ESPECIFICADO"},
{ value: "H700",	text: "MASTOIDITIS AGUDA"},
{ value: "H701",	text: "MASTOIDITIS CRONICA"},
{ value: "H702",	text: "PETROSITIS"},
{ value: "H708",	text: "OTRAS MASTOIDITIS Y AFECCIONES RELACIONADAS"},
{ value: "H709",	text: "MASTOIDITIS, NO ESPECIFICADA"},
{ value: "H71X",	text: "COLESTEATOMA DEL OIDO MEDIO"},
{ value: "H720",	text: "PERFORACION CENTRAL DE LA MEMBRANA TIMPANICA"},
{ value: "H721",	text: "PERFORACION ATICA DE LA MEMBRANA TIMPANICA"},
{ value: "H722",	text: "OTRAS PERFORACIONES MARGINALES DE LA MEMBRANA TIMPANICA"},
{ value: "H728",	text: "OTRAS PERFORACIONES DE LA MEMBRANA TIMPANICA"},
{ value: "H729",	text: "PERFORACION DE LA MEMBRANA TIMPANICA, SIN OTRA ESPECIFICACION"},
{ value: "H730",	text: "MIRINGITIS AGUDA"},
{ value: "H731",	text: "MIRINGITIS CRONICA"},
{ value: "H738",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA MEMBRANA TIMPANICA"},
{ value: "H739",	text: "TRASTORNO DE LA MEMBRANA TIMPANICA, NO ESPECIFICADO"},
{ value: "H740",	text: "TIMPANOSCLEROSIS"},
{ value: "H741",	text: "ENFERMEDAD ADHESIVA DEL OIDO MEDIO"},
{ value: "H742",	text: "DISCONTINUIDAD Y DISLOCACION DE LOS HUESECILLOS DEL OIDO"},
{ value: "H743",	text: "OTRAS ANORMALIDADES ADQUIRIDAS DE LOS HUESECILLOS DEL OIDO"},
{ value: "H744",	text: "POLIPO DEL OIDO MEDIO"},
{ value: "H748",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL OIDO MEDIO Y DE LA APOFISIS MASTOIDES"},
{ value: "H749",	text: "TRASTORNO DEL OIDO MEDIO Y DE LA APOFISIS MASTOIDES, NO ESPECIFICADO"},
{ value: "H750",	text: "MASTOIDITIS EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "H758",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL OIDO MEDIO Y DE LA APOFISIS MASTOIDES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H800",	text: "OTOSCLEROSIS QUE AFECTA LA VENTANA OVAL, NO OBLITERANTE"},
{ value: "H801",	text: "OTOSCLEROSIS QUE AFECTA LA VENTANA OVAL, OBLITERANTE"},
{ value: "H802",	text: "OSTOSCLEROSIS COCLEAR"},
{ value: "H808",	text: "OTRAS OTOSCLEROSIS"},
{ value: "H809",	text: "OTOSCLEROSIS, NO ESPECIFICADA"},
{ value: "H810",	text: "ENFERMEDAD DE MENIERE"},
{ value: "H811",	text: "VERTIGO PAROXISTICO BENIGNO"},
{ value: "H812",	text: "NEURONITIS VESTIBULAR"},
{ value: "H813",	text: "OTROS VERTIGOS PERIFERICOS"},
{ value: "H814",	text: "VERTIGO DE ORIGEN CENTRAL"},
{ value: "H818",	text: "OTROS TRASTORNOS DE LA FUNCION VESTIBULAR"},
{ value: "H819",	text: "TRASTORNO DE LA FUNCION VESTIBULAR, NO ESPECIFICADO"},
{ value: "H82X",	text: "SINDROMES VERTIGINOSOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H830",	text: "LABERINTITIS"},
{ value: "H831",	text: "FISTULA DEL LABERINTO"},
{ value: "H832",	text: "DISFUNCION DEL LABERINTO"},
{ value: "H833",	text: "EFECTOS DEL RUIDO SOBRE EL OIDO INTERNO"},
{ value: "H838",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL OIDO INTERNO"},
{ value: "H839",	text: "TRASTORNO DEL OIDO INTERNO, NO ESPECIFICADO"},
{ value: "H900",	text: "HIPOACUSIA CONDUCTIVA BILATERAL"},
{ value: "H901",	text: "HIPOACUSIA CONDUCTIVA, UNILATERAL CON AUDICION IRRESTRICTA CONTRALATERAL"},
{ value: "H902",	text: "HIPOACUSIA CONDUCTIVA, SIN OTRA ESPECIFICACION"},
{ value: "H903",	text: "HIPOACUSIA NEUROSENSORIAL, BILATERAL"},
{ value: "H904",	text: "HIPOACUSIA NEUROSENSORIAL, UNILATERAL CON AUDICION IRRESTRICTA CONTRALATERAL"},
{ value: "H905",	text: "HIPOACUSIA NEUROSENSORIAL, SIN OTRA ESPECIFICACION"},
{ value: "H906",	text: "HIPOACUSIA MIXTA CONDUCTIVA Y NEUROSENSORIAL, BILATERAL"},
{ value: "H907",	text: "HIPOACUSIA MIXTA CONDUCTIVA Y NEUROSENSORIAL, UNILATERAL CON AUDICION IRRESTRICTA CONTRALATERAL"},
{ value: "H908",	text: "HIPOACUSIA MIXTA CONDUCTIVA Y NEUROSENSORIAL, NO ESPECIFICADA"},
{ value: "H910",	text: "HIPOACUSIA OTOTOXICA"},
{ value: "H911",	text: "PRESBIACUSIA"},
{ value: "H912",	text: "HIPOACUSIA SUBIDA IDIOPATICA"},
{ value: "H913",	text: "SORDOMUDEZ, NO CLASIFICADA EN OTRA PARTE"},
{ value: "H918",	text: "OTRAS HIPOACUSIAS ESPECIFICADAS"},
{ value: "H919",	text: "HIPOACUSIA, NO ESPECIFICADA"},
{ value: "H920",	text: "OTALGIA"},
{ value: "H921",	text: "OTORREA"},
{ value: "H922",	text: "OTORRAGIA"},
{ value: "H930",	text: "TRASTORNOS DEGENERATIVOS Y VASCULARES DEL OIDO"},
{ value: "H931",	text: "TINNITUS"},
{ value: "H932",	text: "OTRAS PERCEPCIONES AUDITIVAS ANORMALES"},
{ value: "H933",	text: "TRASTORNOS DEL NERVIO AUDITIVO"},
{ value: "H938",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL OIDO"},
{ value: "H939",	text: "TRASTORNO DEL OIDO, NO ESPECIFICADO"},
{ value: "H940",	text: "NEURITIS DEL NERVIO AUDITIVO EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "H948",	text: "OTROS TRASTORNOS DEL OIDO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "H950",	text: "COLESTEATOMA RECURRENTE DE LA CAVIDAD RESULTANTE DE LA MASTOIDECTOMIA"},
{ value: "H951",	text: "OTROS TRASTORNOS POSTERIORES A LA MASTOIDECTOMIA"},
{ value: "H958",	text: "OTROS TRASTORNOS DEL OIDO Y DE LA APOFISIS MASTOIDES, CONSECUTIVOS A PROCEDIMIENTOS"},
{ value: "H959",	text: "TRASTORNOS NO ESPECIFICADOS DEL OIDO Y DE LA APOFISIS MASTOIDES, CONSECUTIVOS A PROCEDIMIENTOS"},
{ value: "I00X",	text: "FIEBRE REUMATICA SIN MENCION DE COMPLICACION CARDIACA"},
{ value: "I010",	text: "PERICARDITIS REUMATICA AGUDA"},
{ value: "I011",	text: "ENDOCARDITIS REUMATICA AGUDA"},
{ value: "I012",	text: "MIOCARDITIS REUMATICA AGUDA"},
{ value: "I018",	text: "OTRAS ENFERMEDADES REUMATICAS AGUDAS DEL CORAZON"},
{ value: "I019",	text: "ENFERMEDAD REUMATICA AGUDA DEL CORAZON, NO ESPECIFICADA"},
{ value: "I020",	text: "COREA REUMATICA CON COMPLICACION CARDIACA"},
{ value: "I029",	text: "COREA REUMATICA SIN MENCION DE COMPLICACION CARDIACA"},
{ value: "I050",	text: "ESTENOSIS MITRAL"},
{ value: "I051",	text: "INSUFICIENCIA MITRAL REUMATICA"},
{ value: "I052",	text: "ESTENOSIS MITRAL CON INSUFICIENCIA"},
{ value: "I058",	text: "OTRAS ENFERMEDADES DE LA VALVULA MITRAL"},
{ value: "I059",	text: "ENFERMEDAD VALVULAR MITRAL, NO ESPECIFICADA"},
{ value: "I060",	text: "ESTENOSIS AORTICA REUMATICA"},
{ value: "I061",	text: "INSUFICIENCIA AORTICA REUMATICA"},
{ value: "I062",	text: "INSUFICIENCIA AORTICA REUMATICA CON INSUFICIENCIA"},
{ value: "I068",	text: "OTRAS ENFERMEDADES REUMATICAS DE LA VALVULA AORTICA"},
{ value: "I069",	text: "ENFERMEDAD VALVULAR AORTICA REUMATICA, NO ESPECIFICADA"},
{ value: "I070",	text: "ESTENOSIS TRICUSPIDE"},
{ value: "I071",	text: "INSUFICIENCIA TRICUSPIDE"},
{ value: "I072",	text: "ESTENOSIS E INSUFICIENCIA TRICUSPIDE"},
{ value: "I078",	text: "OTRAS ENFERMEDADES DE LA VALVULA TRICUSPIDE"},
{ value: "I079",	text: "ENFERMEDAD DE LA VALVULA TRICUSPIDE, NO ESPECIFICADA"},
{ value: "I080",	text: "TRASTORNOS DE LAS VALVULAS MITRAL Y AORTICA"},
{ value: "I081",	text: "TRASTORNOS DE LAS VALVULAS MITRAL Y TRICUSPIDE"},
{ value: "I082",	text: "TRASTORNOS DE LAS VALVULAS AORTICA Y TRICUSPIDE"},
{ value: "I083",	text: "TRASTORNOS COMBINADOS DE LAS VALVULAS MITRAL, TRICUSPIDE Y AORTICA"},
{ value: "I088",	text: "OTRAS ENFERMEDADES DE MULTIPLES VALVULAS"},
{ value: "I089",	text: "ENFERMEDAD DE MULTIPLES VALVULAS, NO ESPECIFICADA"},
{ value: "I090",	text: "MIOCARDITIS REUMATICA"},
{ value: "I091",	text: "ENFERMEDADES REUMATICAS DEL ENDOCARDIO, VALVULA NO ESPECIFICADA"},
{ value: "I092",	text: "PERICARDITIS REUMATICA CRONICA"},
{ value: "I098",	text: "OTRAS ENFERMEDADES REUMATICAS ESPECIFICADAS DEL CORAZON"},
{ value: "I099",	text: "ENFERMEDAD REUMATICA DEL CORAZON, NO ESPECIFICADA"},
{ value: "I10X",	text: "HIPERTENSION ESENCIAL (PRIMARIA)"},
{ value: "I110",	text: "ENFERMEDAD CARDIACA HIPERTENSIVA CON INSUFICIENCIA CARDIACA (CONGESTIVA)"},
{ value: "I119",	text: "ENFERMEDAD CARDIACA HIPERTENSIVA SIN INSUFICIENCIA CARDIACA (CONGESTIVA)"},
{ value: "I120",	text: "ENFERMEDAD RENAL HIPERTENSIVA CON INSUFICIENCIA RENAL"},
{ value: "I129",	text: "ENFERMEDAD RENAL HIPERTENSIVA SIN INSUFICIENCIA RENAL"},
{ value: "I130",	text: "ENFERMEDAD CARDIORRENAL HIPERTENSIVA CON INSUFICIENCIA CARDIACA (CONGESTIVA)"},
{ value: "I131",	text: "ENFERMEDAD CARDIORRENAL HIPERTENSIVA CON INSUFICIENCIA RENAL"},
{ value: "I132",	text: "ENFERMEDAD CARDIORRENAL HIPERTENSIVA CON INSUFICIENCIA CARDIACA (CONGESTIVA) E INSUFICIENCIA RENAL"},
{ value: "I139",	text: "ENFERMEDAD CARDIORRENAL HIPERTENSIVA, NO ESPECIFICADA"},
{ value: "I150",	text: "HIPERTENSION RENOVASCULAR"},
{ value: "I151",	text: "HIPERTENSION SECUNDARIA A OTROS TRASTORNOS RENALES"},
{ value: "I152",	text: "HIPERTENSION SECUNDARIA A TRASTORNOS ENDOCRINOS"},
{ value: "I158",	text: "OTROS TIPOS DE HIPERTENSION SECUNDARIA"},
{ value: "I159",	text: "HIPERTENSION SECUNDARIA, NO ESPECIFICADA"},
{ value: "I200",	text: "ANGINA INESTABLE"},
{ value: "I201",	text: "ANGINA DE PECHO CON ESPASMO DOCUMENTADO"},
{ value: "I208",	text: "OTRAS FORMAS ESPECIFICADAS DE ANGINA DE PECHO"},
{ value: "I209",	text: "ANGINA DE PECHO, NO ESPECIFICADA"},
{ value: "I210",	text: "INFARTO TRANSMURAL AGUDO DEL MIOCARDIO DE LA PARED ANTERIOR"},
{ value: "I211",	text: "INFARTO TRANSMURAL AGUDO DEL MIOCARDIO DE LA PARED INFERIOR"},
{ value: "I212",	text: "INFARTO AGUDO TRANSMURAL DEL MIOCARDIO DE OTROS SITIOS"},
{ value: "I213",	text: "INFARTO TRANSMURAL AGUDO DEL MIOCARDIO, DE SITIO NO ESPECIFICADO"},
{ value: "I214",	text: "INFARTO SUBENDOCARDICO AGUDO DEL MIOCARDIO"},
{ value: "I219",	text: "INFARTO AGUDO DEL MIOCARDIO, SIN OTRA ESPECIFICACION"},
{ value: "I220",	text: "INFARTO SUBSECUENTE DEL MIOCARDIO DE LA PARED ANTERIOR"},
{ value: "I221",	text: "INFARTO SUBSECUENTE DEL MIOCARDIO DE LA PARED INFERIOR"},
{ value: "I228",	text: "INFARTO SUBSECUENTE DEL MIOCARDIO DE OTROS SITIOS"},
{ value: "I229",	text: "INFARTO SUBSECUENTE DEL MIOCARDIO DE PARTE NO ESPECIFICADA"},
{ value: "I230",	text: "HEMOPERICARDIO COMO COMPLICACION PRESENTE POSTERIOR AL INFARTO AGUDO DEL MIOCARDIO"},
{ value: "I231",	text: "DEFECTO DEL TABIQUE AURICULAR COMO COMPLICACION PRESENTE POSTERIOR AL INFARTO DEL MIOCARDIO"},
{ value: "I232",	text: "DEFECTO DEL TABIQUE VENTRICULAR COMO COMPLICACION PRESENTE POSTERIOR AL INFARTO DEL MIOCARDIO"},
{ value: "I233",	text: "RUPTURA DE LA PARED CARDIACA SIN HEMOPERICARDIO COMO COMPILACION PRESENTE POSTERIOR AL INFARTO AGUDO DEL MIOCARDIO"},
{ value: "I234",	text: "RUPTURA DE LAS CUERDAS TENDINOSAS COMO COMPLICACION PRESENTE POSTERIOR AL INFARTO AGUDO DEL MIOCARDIO"},
{ value: "I235",	text: "RUPTURA DE MUSCULO PAPILAR COMO COMPLICACION PRESENTE POSTERIOR AL INFARTO AGUDO DEL MIOCARDIO"},
{ value: "I236",	text: "TROMBOSIS DE LA AURICULA, APENDICE AURICULAR Y VENTRICULO COMO COMPLICACION PRESENTE POSTERIOR AL INFARTO AGUDO DEL MIOCARDIO"},
{ value: "I238",	text: "OTRAS COMPLICACIONES PRESENTES POSTERIORES AL INFARTO AGUDO DEL MIOCARDIO"},
{ value: "I240",	text: "TROMBOSIS CORONARIA QUE NO RESULTA EN INFARTO DEL MIOCARDIO"},
{ value: "I241",	text: "SINDROME DE DRESSLER"},
{ value: "I248",	text: "OTRAS FORMAS DE ENFERMEDAD ISQUEMICA AGUDA DEL CORAZON"},
{ value: "I249",	text: "ENFERMEDAD ISQUEMICA AGUDA DEL CORAZON, NO ESPECIFICADA"},
{ value: "I250",	text: "ENFERMEDAD CARDIOVASCULAR ATEROSCLEROTICA, ASI DESCRITA"},
{ value: "I251",	text: "ENFERMEDAD ATEROSCLEROTICA DEL CORAZON"},
{ value: "I252",	text: "INFARTO ANTIGUO DEL MIOCARDIO"},
{ value: "I253",	text: "ANEURISMA CARDIACO"},
{ value: "I254",	text: "ANEURISMA DE ARTERIA CORONARIA"},
{ value: "I255",	text: "CARDIOMIOPATIA ISQUEMICA"},
{ value: "I256",	text: "ISQUEMIA SILENTE DEL MIOCARDIO"},
{ value: "I258",	text: "OTRAS FORMAS DE ENFERMEDAD ISQUEMICA CRONICA DEL CORAZON"},
{ value: "I259",	text: "ENFERMEDAD ISQUEMIA CRONICA DEL CORAZON, NO ESPECIFICADA"},
{ value: "I260",	text: "EMBOLIA PULMONAR CON MENCION DE CORAZON PULMONAR AGUDO"},
{ value: "I269",	text: "EMBOLIA PULMONAR SIN MENCION DE CORAZON PULMONAR AGUDO"},
{ value: "I270",	text: "HIPERTENSION PULMONAR PRIMARIA"},
{ value: "I271",	text: "ENFERMEDAD CIFOSCOLIOTICA DEL CORAZON"},
{ value: "I278",	text: "OTRAS ENFERMEDADES CARDIOPULMONARES ESPECIFICADAS"},
{ value: "I279",	text: "ENFERMEDAD PULMONAR DEL CORAZON, NO ESPECIFICADA"},
{ value: "I280",	text: "FISTULA ARTERIOVENOSA DE LOS VASOS PULMONARES"},
{ value: "I281",	text: "ANEURISMA DE LA ARTERIA PULMONAR"},
{ value: "I288",	text: "OTRAS ENFERMEDADES ESPECIFICADAS DE LOS VASOS PULMONARES"},
{ value: "I289",	text: "ENFERMEDAD DE LOS VASOS PULMONARES, NO ESPECIFICADA"},
{ value: "I300",	text: "PERICARDITIS IDIOPATICA AGUDA INESPECIFICA"},
{ value: "I301",	text: "PERICARDITIS INFECCIOSA"},
{ value: "I308",	text: "OTRAS FORMAS DE PERICARDITIS AGUDA"},
{ value: "I309",	text: "PERICARDITIS AGUDA, NO ESPECIFICADA"},
{ value: "I310",	text: "PERICARDITIS CRONICA ADHESIVA"},
{ value: "I311",	text: "PERICARDITIS CONSTRICTIVA CRONICA"},
{ value: "I312",	text: "HEMOPERICARDIO, NO CLASIFICADO EN OTRA PARTE"},
{ value: "I313",	text: "DERRAME PERICARDICO (NO INFLAMATORIO)"},
{ value: "I318",	text: "OTRAS ENFERMEDADES ESPECIFICADAS DEL PERICARDIO"},
{ value: "I319",	text: "ENFERMEDAD DEL PERICARDIO, NO ESPECIFICADA"},
{ value: "I320",	text: "PERICARDITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"},
{ value: "I321",	text: "PERICARDITIS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "I328",	text: "PERICARDITIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I330",	text: "ENDOCARDITIS INFECCIOSA AGUDA Y SUBAGUDA"},
{ value: "I339",	text: "ENDOCARDITIS AGUDA, NO ESPECIFICADA"},
{ value: "I340",	text: "INSUFICIENCIA (DE LA VALVULA) MITRAL"},
{ value: "I341",	text: "PROLAPSO (DE LA VALVULA) MITRAL"},
{ value: "I342",	text: "ESTENOSIS (DE LA VALVULA) MITRAL, NO REUMATICA"},
{ value: "I348",	text: "OTROS TRASTORNOS NO REUMATICOS DE LA VALVULA MITRAL"},
{ value: "I349",	text: "TRASTORNO MITRAL NO REUMATICO, NO ESPECIFICADO"},
{ value: "I350",	text: "ESTENOSIS (DE LA VALVULA) AORTICA"},
{ value: "I351",	text: "INSUFICIENCIA (DE LA VALVULA) AORTICA"},
{ value: "I352",	text: "ESTENOSIS (DE LA VALVULA) AORTICA CON INSUFICIENCIA"},
{ value: "I358",	text: "OTROS TRASTORNOS DE LA VALVULA AORTICA"},
{ value: "I359",	text: "TRASTORNO DE LA VALVULA AORTICA, NO ESPECIFICADO"},
{ value: "I360",	text: "ESTENOSIS NO REUMATICA (DE LA VALVULA) TRICUSPIDE)"},
{ value: "I361",	text: "INSUFICIENCIA NO REUMATICA (DE LA VALVULA) TRICUSPIDE"},
{ value: "I362",	text: "ESTENOSIS CON INSUFICIENCIA NO REUMATICA (DE LA VALVULA) TRICUSPIDE"},
{ value: "I368",	text: "OTROS TRASTORNOS NO REUMATICOS DE LA VALVULA TRICUSPIDE"},
{ value: "I369",	text: "TRASTORNO NO REUMATICO DE LA VALVULA TRICUSPIDE, NO ESPECIFICADO"},
{ value: "I370",	text: "ESTENOSIS DE LA VALVULA PULMONAR"},
{ value: "I371",	text: "INSUFICIENCIA DE LA VALVULA PULMONAR"},
{ value: "I372",	text: "ESTENOSIS DE LA VALVULA PULMONAR CON INSUFICIENCIA"},
{ value: "I378",	text: "OTROS TRASTORNOS DE LA VALVULA PULMONAR"},
{ value: "I379",	text: "TRASTORNO DE LA VALVULA PULMONAR, NO ESPECIFICADO"},
{ value: "I38X",	text: "ENDOCARDITIS, VALVULA NO ESPECIFICADA"},
{ value: "I390",	text: "TRASTORNOS DE LA VALVULA MITRAL EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I391",	text: "TRASTORNOS DE LA VALVULA AORTICA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I392",	text: "TRASTORNOS DE LA VALVULA TRICUSPIDE EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I393",	text: "TRASTORNOS DE LA VALVULA PULMONAR EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I394",	text: "TRASTORNOS DE LA VALVULARES MULTIPLES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I398",	text: "ENDOCARDITIS, VALVULA NO ESPECIFICADA, EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I400",	text: "MIOCARDITIS INFECCIOSA"},
{ value: "I401",	text: "MIOCARDITIS AISLADA"},
{ value: "I408",	text: "OTRAS MIOCARDITIS AGUDAS"},
{ value: "I409",	text: "MIOCARDITIS AGUDA, NO ESPECIFICADA"},
{ value: "I410",	text: "MIOCARDITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"},
{ value: "I411",	text: "MIOCARDITIS EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE"},
{ value: "I412",	text: "MIOCARDITIS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "I418",	text: "MIOCARDITIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I420",	text: "CARDIOMIOPATIA DILATADA"},
{ value: "I421",	text: "CARDIOMIOPATIA HIPERTROFICA OBSTRUCTIVA"},
{ value: "I422",	text: "OTRAS CARDIOMIOPATIA HIPERTROFICAS"},
{ value: "I423",	text: "ENFERMEDAD ENDOMIOCARDICA (EOSINOFILICA)"},
{ value: "I424",	text: "FIBROELASTOSIS ENDOCARDICA"},
{ value: "I425",	text: "OTRAS CARDIOMIOPATIAS RESTRICTIVAS"},
{ value: "I426",	text: "CARDIOMIOPATIA ALCOHOLICA"},
{ value: "I427",	text: "CARDIOMIOPATIA DEBIDA A DROGAS Y A OTROS AGENTES EXTERNOS"},
{ value: "I428",	text: "OTRAS CARDIOMIOPATIA"},
{ value: "I429",	text: "CARDIOMIOPATIA, NO ESPECIFICADA"},
{ value: "I430",	text: "CARDIOMIOPATIA EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "I431",	text: "CARDIOMIOPATIA EN ENFERMEDADES METABOLICAS"},
{ value: "I432",	text: "CARDIOMIOPATIA EN ENFERMEDADES NUTRICIONALES"},
{ value: "I438",	text: "CARDIOMIOPATIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I440",	text: "BLOQUEO AURICULOVENTRICULAR DE PRIMER GRADO"},
{ value: "I441",	text: "BLOQUEO AURICULOVENTRICULAR DE SEGUNDO GRADO"},
{ value: "I442",	text: "BLOQUEO AURICULOVENTRICULAR COMPLETO"},
{ value: "I443",	text: "OTROS TIPOS DE BLOQUEO AURICULOVENTRICULAR Y LOS NO ESPECIFICADOS"},
{ value: "I444",	text: "BLOQUEO FASCICULAR ANTERIOR IZQUIERDO"},
{ value: "I445",	text: "BLOQUEO FASCICULAR POSTERIOR IZQUIERDO"},
{ value: "I446",	text: "OTROS TIPOS DE BLOQUEO FASCICULAR Y LOS NO ESPECIFICADOS"},
{ value: "I447",	text: "BLOQUEO DE RAMA IZQUIERDA DEL HAZ, SIN OTRA ESPECIFICACION"},
{ value: "I450",	text: "BLOQUEO FASCICULAR DERECHO"},
{ value: "I451",	text: "OTROS TIPOS DE BLOQUEO DE RAMA DERECHA DEL HAZ Y LOS NO ESPECIFICADOS"},
{ value: "I452",	text: "BLOQUEO BIFASCICULAR"},
{ value: "I453",	text: "BLOQUEO TRIFASCICULAR"},
{ value: "I454",	text: "BLOQUEO INTRAVENTRICULAR NO ESPECIFICADO"},
{ value: "I455",	text: "OTROS TIPOS ESPECIFICADOS DE BLOQUEO DEL CORAZON"},
{ value: "I456",	text: "SINDROME DE PREEXCITACION"},
{ value: "I458",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA CONDUCCION"},
{ value: "I459",	text: "TRASTORNO DE LA CONDUCCION, NO ESPECIFICADO"},
{ value: "I460",	text: "PARO CARDIACO CON RESUCITACION EXITOSA"},
{ value: "I461",	text: "MUERTE CARDIACA SUBITA, ASI DESCRITA"},
{ value: "I469",	text: "PARO CARDIACO, NO ESPECIFICADO"},
{ value: "I470",	text: "ARRITMIA POR REENTRADA VENTRICULAR"},
{ value: "I471",	text: "TAQUICARDIA SUPRAVENTRICULAR"},
{ value: "I472",	text: "TAQUICARDIA VENTRICULAR"},
{ value: "I479",	text: "TAQUICARDIA PAROXISTICA, NO ESPECIFICADA"},
{ value: "I48X",	text: "FIBRILACION Y ALETEO AURICULAR"},
{ value: "I490",	text: "FIBRILACION Y ALETEO VENTRICULAR"},
{ value: "I491",	text: "DESPOLARIZACION AURICULAR PREMATURA"},
{ value: "I492",	text: "DESPOLARIZACION PREMATURA NODAL"},
{ value: "I493",	text: "DESPOLARIZACION VENTRICULAR PREMATURA"},
{ value: "I494",	text: "OTROS TIPOS DE DESPOLARIZACION PREMATURA Y LOS NO ESPECIFICADOS"},
{ value: "I495",	text: "SINDROME DEL SENO ENFERMO"},
{ value: "I498",	text: "OTRAS ARRITMIAS CARDIACAS ESPECIFICADAS"},
{ value: "I499",	text: "ARRITMIA CARDIACA, NO ESPECIFICADA"},
{ value: "I500",	text: "INSUFICIENCIA CARDIACA CONGESTIVA"},
{ value: "I501",	text: "INSUFICIENCIA VENTRICULAR IZQUIERDA"},
{ value: "I509",	text: "INSUFICIENCIA CARDIACA, NO ESPECIFICADA"},
{ value: "I510",	text: "DEFECTO DEL TABIQUE CARDIACO, ADQUIRIDO"},
{ value: "I511",	text: "RUPTURA DE CUERDA TENDINOSA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "I512",	text: "RUPTURA DEL MUSCULO PAPILAR, NO CLASIFICADA EN OTRA PARTE"},
{ value: "I513",	text: "TROMBOSIS INTRACARDIACA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "I514",	text: "MIOCARDITIS, NO ESPECIFICADA"},
{ value: "I515",	text: "DEGENERACION MIOCARDICA"},
{ value: "I516",	text: "ENFERMEDAD CARDIOVASCULAR, NO ESPECIFICADA"},
{ value: "I517",	text: "CARDIOMEGALIA"},
{ value: "I518",	text: "OTRAS ENFERMEDADES CARDIACAS MAL DEFINIDAS"},
{ value: "I519",	text: "ENFERMEDAD CARDIACA, NO ESPECIFICADA"},
{ value: "I520",	text: "OTROS TRASTORNOS CARDIACOS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"},
{ value: "I521",	text: "OTROS TRASTORNOS CARDIACOS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "I528",	text: "OTROS TRASTORNOS CARDIACOS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I600",	text: "HEMORRAGIA SUBARACNOIDEA DE SIFON Y BIFURCACION CAROTIDEA"},
{ value: "I601",	text: "HEMORRAGIA SUBARACNOIDEA DE ARTERIA CEREBRAL MEDIA"},
{ value: "I602",	text: "HEMORRAGIA SUBARACNOIDEA DE ARTERIA COMUNICANTE ANTERIOR"},
{ value: "I603",	text: "HEMORRAGIA SUBARACNOIDEA DE ARTERIA COMUNICANTE POSTERIOR"},
{ value: "I604",	text: "HEMORRAGIA SUBARACNOIDEA DE ARTERIA BASILAR"},
{ value: "I605",	text: "HEMORRAGIA SUBARACNOIDEA DE ARTERIA VERTEBRAL"},
{ value: "I606",	text: "HEMORRAGIA SUBARACNOIDEA DE OTRAS ARTERIAS INTRACRANEALES"},
{ value: "I607",	text: "HEMORRAGIA SUBARACNOIDEA DE ARTERIA INTRACRANEAL NO ESPECIFICADA"},
{ value: "I608",	text: "OTRAS HEMORRAGIAS SUBARACNOIDEAS"},
{ value: "I609",	text: "HEMORRAGIA SUBARACNOIDEA, NO ESPECIFICADA"},
{ value: "I610",	text: "HEMORRAGIA INTRACEREBRAL EN HEMISFERIO, SUBCORTICAL"},
{ value: "I611",	text: "HEMORRAGIA INTRACEREBRAL EN HEMISFERIO, CORTICAL"},
{ value: "I612",	text: "HEMORRAGIA INTRACEREBRAL EN HEMISFERIO, NO ESPECIFICADA"},
{ value: "I613",	text: "HEMORRAGIA INTRAENCEFALICA EN TALLO CEREBRAL"},
{ value: "I614",	text: "HEMORRAGIA INTRAENCEFALICA EN CEREBELO"},
{ value: "I615",	text: "HEMORRAGIA INTRAENCEFALICA, INTRAVENTRICULAR"},
{ value: "I616",	text: "HEMORRAGIA INTRAENCEFALICA DE LOCALIZACIONES MULTIPLES"},
{ value: "I618",	text: "OTRAS HEMORRAGIAS INTRAENCEFALICAS"},
{ value: "I619",	text: "HEMORRAGIA INTRAENCEFALICA, NO ESPECIFICADA"},
{ value: "I620",	text: "HEMORRAGIA SUBDURAL (AGUDA) (NO TRAUMATICA)"},
{ value: "I621",	text: "HEMORRAGIA EXTRADURAL NO TRAUMATICA"},
{ value: "I629",	text: "HEMORRAGIA INTRACRANEAL (NO TRAUMATICA), NO ESPECIFICADA"},
{ value: "I630",	text: "INFARTO CEREBRAL DEBIDO A TROMBOSIS DE ARTERIAS PRECEREBRALES"},
{ value: "I631",	text: "INFARTO CEREBRAL DEBIDO A EMBOLIA DE ARTERIAS PRECEREBRALES"},
{ value: "I632",	text: "INFARTO CEREBRAL DEBIDO A OCLUSION O ESTENOSIS NO ESPECIFICADA DE ARTERIAS PRECEREBRALES"},
{ value: "I633",	text: "INFARTO CEREBRAL DEBIDO A TROMBOSIS DE ARTERIAS CEREBRALES"},
{ value: "I634",	text: "INFARTO CEREBRAL DEBIDO A EMBOLIA DE ARTERIAS CEREBRALES"},
{ value: "I635",	text: "INFARTO CEREBRAL DEBIDO A OCLUSION O ESTENOSIS NO ESPECIFICADA DE ARTERIAS CEREBRALES"},
{ value: "I636",	text: "INFARTO CEREBRAL DEBIDO A TROMBOSIS DE VENAS CEREBRALES, NO PIOGENO"},
{ value: "I638",	text: "OTROS INFARTOS CEREBRALES"},
{ value: "I639",	text: "INFARTO CEREBRAL, NO ESPECIFICADO"},
{ value: "I64X",	text: "ACCIDENTE VASCULAR ENCEFALICO AGUDO, NO ESPECIFICADO COMO HEMORRAGICO O ISQUEMICO"},
{ value: "I650",	text: "OCLUSION Y ESTENOSIS DE ARTERIA VERTEBRAL"},
{ value: "I651",	text: "OCLUSION Y ESTENOSIS DE ARTERIA BASILAR"},
{ value: "I652",	text: "OCLUSION Y ESTENOSIS DE ARTERIA CAROTIDA"},
{ value: "I653",	text: "OCLUSION Y ESTENOSIS MULTIPLE BILATERAL DE ARTERIAS PRECEREBRALES"},
{ value: "I658",	text: "OCLUSION Y ESTENOSIS DE OTRAS ARTERIAS PRECEREBRALES"},
{ value: "I659",	text: "OCLUSION Y ESTENOSIS DE ARTERIA PRECEREBRAL NO ESPECIFICADA"},
{ value: "I660",	text: "OCLUSION Y ESTENOSIS DE LA ARTERIA CEREBRAL MEDIA"},
{ value: "I661",	text: "OCLUSION Y ESTENOSIS DE LA ARTERIA CEREBRAL ANTERIOR"},
{ value: "I662",	text: "OCLUSION Y ESTENOSIS DE LA ARTERIA CEREBRAL POSTERIOR"},
{ value: "I663",	text: "OCLUSION Y ESTENOSIS DE ARTERIAS CEREBELOSAS"},
{ value: "I664",	text: "OCLUSION Y ESTENOSIS MULTIPLE BILATERAL DE ARTERIAS CEREBRALES"},
{ value: "I668",	text: "OCLUSION Y ESTENOSIS DE OTRAS ARTERIAS CEREBRALES"},
{ value: "I669",	text: "OCLUSION Y ESTENOSIS DE ARTERIA CEREBRAL NO ESPECIFICADA"},
{ value: "I670",	text: "DISECCION DE ARTERIAS CEREBRALES, SIN RUPTURA"},
{ value: "I671",	text: "ANEURISMA CEREBRAL, SIN RUPTURA"},
{ value: "I672",	text: "ATEROSCLEROSIS CEREBRAL"},
{ value: "I673",	text: "LEUCOENCEFALOPATIA VASCULAR PROGRESIVA"},
{ value: "I674",	text: "ENCEFALOPATIA HIPERTENSIVA"},
{ value: "I675",	text: "ENFERMEDAD DE MOYAMOYA"},
{ value: "I676",	text: "TROMBOSIS APIOGENA DEL SISTEMA VENOSO INTRACRANEAL"},
{ value: "I677",	text: "ARTERITIS CEREBRAL, NO CLASIFICADA EN OTRA PARTE"},
{ value: "I678",	text: "OTRAS ENFERMEDADES CEREBROVASCULARES ESPECIFICADAS"},
{ value: "I679",	text: "ENFERMEDAD CEREBROVASCULAR, NO ESPECIFICADA"},
{ value: "I680",	text: "ANGIOPATIA CEREBRAL AMILOIDE (E85.- †)"},
{ value: "I681",	text: "ARTERITIS CEREBRAL EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADA EN OTRA PARTE"},
{ value: "I682",	text: "ARTERITIS CEREBRAL EN OTRAS ENFERMEDADES CLASIFICADA EN OTRA PARTE"},
{ value: "I688",	text: "OTROS TRASTORNOS CEREBROVASCULARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I690",	text: "SECUELAS DE HEMORRAGIA SUBARACNOIDEA"},
{ value: "I691",	text: "SECUELAS DE HEMORRAGIA INTRAENCEFALICA"},
{ value: "I692",	text: "SECUELAS DE OTRAS HEMORRAGIAS INTRACRANEALES NO TRAUMATICAS"},
{ value: "I693",	text: "SECUELAS DE INFARTO CEREBRAL"},
{ value: "I694",	text: "SECUELAS DE ENFERMEDAD CEREBROVASCULAR, NO ESPECIFICADA COMO HEMORRAGICA U OCLUSIVA"},
{ value: "I698",	text: "SECUELAS DE OTRAS ENFERMEDADES CEREBROVASCULARES Y DE LAS NO ESPECIFICADAS"},
{ value: "I700",	text: "ATEROSCLEROSIS DE LA AORTA"},
{ value: "I701",	text: "ATEROSCLEROSIS DE LA ARTERIA RENAL"},
{ value: "I702",	text: "ATEROSCLEROSIS DE LAS ARTERIAS DE LOS MIEMBROS"},
{ value: "I708",	text: "ATEROSCLEROSIS DE OTRAS ARTERIAS"},
{ value: "I709",	text: "ATEROSCLEROSIS GENERALIZADA Y LA NO ESPECIFICADA"},
{ value: "I710",	text: "DISECCION DE AORTA (CUALQUIER PARTE)"},
{ value: "I711",	text: "RUPTURA DE ANEURISMA DE LA AORTA TORACICA"},
{ value: "I712",	text: "ANEURISMA DE LA AORTA TORACICA, SIN MENCION DE RUPTURA"},
{ value: "I713",	text: "RUPTURA DE ANEURISMA DE LA AORTA ABDOMINAL"},
{ value: "I714",	text: "ANEURISMA DE LA AORTA ABDOMINAL, SIN MENCION DE RUPTURA"},
{ value: "I715",	text: "RUPTURA DE ANEURISMA DE LA AORTA TORACOABDOMINAL"},
{ value: "I716",	text: "ANEURISMA DE LA AORTA TORACOABDOMINAL, SIN MENCION DE RUPTURA"},
{ value: "I718",	text: "RUPTURA DE ANEURISMA AORTICO, SITIO NO ESPECIFICADO"},
{ value: "I719",	text: "ANEURISMA DE LA AORTA, SITIO NO ESPECIFICADO, SIN MENCION DE RUPTURA"},
{ value: "I720",	text: "ANEURISMA DE LA ARTERIA CAROTIDA"},
{ value: "I721",	text: "ANEURISMA DE LA ARTERIA DEL MIEMBRO SUPERIOR"},
{ value: "I722",	text: "ANEURISMA DE ARTERIA RENAL"},
{ value: "I723",	text: "ANEURISMA DE ARTERIA ILIACA"},
{ value: "I724",	text: "ANEURISMA DE ARTERIA DEL MIEMBRO INFERIOR"},
{ value: "I728",	text: "ANEURISMA DE OTRAS ARTERIAS ESPECIFICADAS"},
{ value: "I729",	text: "ANEURISMA DE SITIO NO ESPECIFICADO"},
{ value: "I730",	text: "SINDROME DE RAYNAUD"},
{ value: "I731",	text: "TROMBOANGEITIS OBLITERANTE [BUERGER]"},
{ value: "I738",	text: "OTRAS ENFERMEDADES VASCULARES PERIFERICAS ESPECIFICADAS"},
{ value: "I739",	text: "ENFERMEDAD VASCULAR PERIFERICA, NO ESPECIFICADA"},
{ value: "I740",	text: "EMBOLIA Y TROMBOSIS DE LA AORTA ABDOMINAL"},
{ value: "I741",	text: "EMBOLIA Y TROMBOSIS DE OTRAS PORCIONES Y LAS NO ESPECIFICADAS DE LA AORTA"},
{ value: "I742",	text: "EMBOLIA Y TROMBOSIS DE ARTERIAS DE LOS MIEMBROS SUPERIORES"},
{ value: "I743",	text: "EMBOLIA Y TROMBOSIS DE ARTERIAS DE LOS MIEMBROS INFERIORES"},
{ value: "I744",	text: "EMBOLIA Y TROMBOSIS DE ARTERIAS DE LOS MIEMBROS, NO ESPECIFICADAS"},
{ value: "I745",	text: "EMBOLIA Y TROMBOSIS DE ARTERIA ILIACA"},
{ value: "I748",	text: "EMBOLIA Y TROMBOSIS DE OTRAS ARTERIAS"},
{ value: "I749",	text: "EMBOLIA Y TROMBOSIS DE ARTERIA NO ESPECIFICADA"},
{ value: "I770",	text: "FISTULA ARTERIOVENOSA, ADQUIRIDA"},
{ value: "I771",	text: "ESTRECHEZ ARTERIAL"},
{ value: "I772",	text: "RUPTURA ARTERIAL"},
{ value: "I773",	text: "DISPLASIA FIBROMUSCULAR ARTERIAL"},
{ value: "I774",	text: "SINDROME DE COMPRESION DEL TRONCO CELIACO"},
{ value: "I775",	text: "NECROSIS ARTERIAL"},
{ value: "I776",	text: "ARTERITIS, NO ESPECIFICADA"},
{ value: "I778",	text: "OTROS TRASTORNOS ESPECIFICADOS DE ARTERIAS Y ARTERIOLAS"},
{ value: "I779",	text: "TRASTORNOS DE ARTERIAS Y ARTERIOLAS, NO ESPECIFICADO"},
{ value: "I780",	text: "TELANGIECTASIA HEMORRAGICA HEREDITARIA"},
{ value: "I781",	text: "NEVO, NO NEOPLASICO"},
{ value: "I788",	text: "OTRAS ENFERMEDADES DE LOS CAPILARES"},
{ value: "I789",	text: "ENFERMEDAD DE LOS VASOS CAPILARES, NO ESPECIFICADA"},
{ value: "I790",	text: "ANEURISMA DE LA AORTA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I791",	text: "AORTITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I792",	text: "ANGIOPATIA PERIFERICA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I798",	text: "OTROS TRASTORNOS DE ARTERIAS, ARTERIOLAS Y VASOS CAPILARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I800",	text: "FLEBITIS Y TROMBOFLEBITIS DE VASOS SUPERFICIALES DE LOS MIEMBROS INFERIORES"},
{ value: "I801",	text: "FLEBITIS Y TROMBOFLEBITIS DE LA VENA FEMORAL"},
{ value: "I802",	text: "FLEBITIS Y TROMBOFLEBITIS DE OTROS VASOS PROFUNDOS DE LOS MIEMBROS INFERIORES"},
{ value: "I803",	text: "FLEBITIS Y TROMBOFLEBITIS DE LOS MIEMBROS INFERIORES, NO ESPECIFICADA"},
{ value: "I808",	text: "FLEBITIS Y TROMBOFLEBITIS DE OTROS SITIOS"},
{ value: "I809",	text: "FLEBITIS Y TROMBOFLEBITIS DE SITIO NO ESPECIFICADO"},
{ value: "I81X",	text: "TROMBOSIS DE LA VENA PORTA"},
{ value: "I820",	text: "SINDROME DE BUDD-CHIARI"},
{ value: "I821",	text: "TROMBOFLEBITIS MIGRATORIA"},
{ value: "I822",	text: "EMBOLIA Y TROMBOSIS DE VENA CAVA"},
{ value: "I823",	text: "EMBOLIA Y TROMBOSIS DE VENA RENAL"},
{ value: "I828",	text: "EMBOLIA Y TROMBOSIS DE OTRAS VENAS ESPECIFICADAS"},
{ value: "I829",	text: "EMBOLIA Y TROMBOSIS DE VENA NO ESPECIFICADA"},
{ value: "I830",	text: "VENAS VARICOSAS DE LOS MIEMBROS INFERIORES CON ULCERA"},
{ value: "I831",	text: "VENAS VARICOSAS DE LOS MIEMBROS INFERIORES CON INFLAMACION"},
{ value: "I832",	text: "VENAS VARICOSAS DE LOS MIEMBROS INFERIORES CON ULCERA E INFLAMACION"},
{ value: "I839",	text: "VENAS VARICOSAS DE LOS MIEMBROS INFERIORES SIN ULCERA NI INFLAMACION"},
{ value: "I840",	text: "HEMORROIDES INTERNAS TROMBOSADAS"},
{ value: "I841",	text: "HEMORROIDES INTERNAS CON OTRAS COMPLICACIONES"},
{ value: "I842",	text: "HEMORROIDES INTERNAS SIN COMPLICACION"},
{ value: "I843",	text: "HEMORROIDES EXTERNAS TROMBOSADAS"},
{ value: "I844",	text: "HEMORROIDES EXTERNAS CON OTRAS COMPLICACIONES"},
{ value: "I845",	text: "HEMORROIDES EXTERNAS SIN COMPLICACION"},
{ value: "I846",	text: "PROMINENCIAS CUTANEAS, RESIDUO DE HEMORROIDES"},
{ value: "I847",	text: "HEMORROIDES TROMBOSADAS NO ESPECIFICADAS"},
{ value: "I848",	text: "HEMORROIDES NO ESPECIFICADAS, CON OTRAS COMPLICACIONES"},
{ value: "I849",	text: "HEMORROIDES NO ESPECIFICADAS, SIN COMPLICACION"},
{ value: "I850",	text: "VARICES ESOFAGICAS CON HEMORRAGIA"},
{ value: "I859",	text: "VARICES ESOFAGICAS SIN HEMORRAGIA"},
{ value: "I860",	text: "VARICES SUBLINGUALES"},
{ value: "I861",	text: "VARICES ESCROTALES"},
{ value: "I862",	text: "VARICES PELVICAS"},
{ value: "I863",	text: "VARICES DE LA VULVA"},
{ value: "I864",	text: "VARICES GASTRICAS"},
{ value: "I868",	text: "VARICES EN OTROS SITIOS ESPECIFICADOS"},
{ value: "I870",	text: "SINDROME POSTFLEBITICO"},
{ value: "I871",	text: "COMPRESION DE VENA"},
{ value: "I872",	text: "INSUFICIENCIA VENOSA (CRONICA) (PERIFERICA)"},
{ value: "I878",	text: "OTROS TRASTORNOS VENOSOS ESPECIFICADOS"},
{ value: "I879",	text: "TRASTORNO VENOSO, NO ESPECIFICADO"},
{ value: "I880",	text: "LINFADENITIS MESENTERICA INESPECIFICA"},
{ value: "I881",	text: "LINFADENITIS CRONICA, EXCEPTO LA MESENTERICA"},
{ value: "I888",	text: "OTRAS LINFADENITIS INESPECIFICAS"},
{ value: "I889",	text: "LINFADENITIS INESPECIFICA NO ESPECIFICADA"},
{ value: "I890",	text: "LINFEDEMA, NO CLASIFICADO EN OTRA PARTE"},
{ value: "I891",	text: "LINFANGITIS"},
{ value: "I898",	text: "OTROS TRASTORNOS ESPECIFICADOS NO INFECCIOSOS DE LOS VASOS Y GANGLIOS LINFATICOS"},
{ value: "I899",	text: "TRASTORNO NO INFECCIOSO DE VASOS Y GANGLIOS LINFATICOS, NO ESPECIFICADO"},
{ value: "I950",	text: "HIPOTENSION IDIOPATICA"},
{ value: "I951",	text: "HIPOTENSION ORTOSTATICA"},
{ value: "I952",	text: "HIPOTENSION DEBIDA A DROGAS"},
{ value: "I958",	text: "OTROS TIPOS DE HIPOTENSION"},
{ value: "I959",	text: "HIPOTENSION, NO ESPECIFICADA"},
{ value: "I970",	text: "SINDROME DE POSTCARDIOTOMIA"},
{ value: "I971",	text: "OTRAS ALTERACIONES FUNCIONALES CONSECUTIVAS A CIRUGIA CARDIACA"},
{ value: "I972",	text: "SINDROME DE LINFEDEMA POSTMASTECTOMIA"},
{ value: "I978",	text: "OTROS TRASTORNOS DEL SISTEMA CIRCULATORIO CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "I979",	text: "TRASTORNO NO ESPECIFICADO DEL SISTEMA CIRCULATORIO CONSECUTIVO A PROCEDIMIENTOS"},
{ value: "I980",	text: "SIFILIS CARDIOVASCULAR"},
{ value: "I981",	text: "TRASTORNOS CARDIOVASCULARES EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "I982",	text: "VARICES ESOFAGICAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I988",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL APARATO CIRCULATORIO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "I99X",	text: "OTROS TRASTORNOS Y LOS NO ESPECIFICADOS DEL SISTEMA CIRCULATORIO"},
{ value: "J00X",	text: "RINOFARINGITIS AGUDA (RESFRIADO COMUN)"},
{ value: "J010",	text: "SINUSITIS MAXILAR AGUDA"},
{ value: "J011",	text: "SINUSITIS FRONTAL AGUDA"},
{ value: "J012",	text: "SINUSITIS ETMOIDAL AGUDA"},
{ value: "J013",	text: "SINUSITIS ESFENOIDAL AGUDA"},
{ value: "J014",	text: "PANSINUSITIS AGUDA"},
{ value: "J018",	text: "OTRAS SINUSITIS AGUDAS"},
{ value: "J019",	text: "SINUSITIS AGUDA, NO ESPECIFICADA"},
{ value: "J020",	text: "FARINGITIS ESTREPTOCOCICA"},
{ value: "J028",	text: "FARINGITIS AGUDA DEBIDA A OTROS MICROORGANISMOS ESPECIFICADOS"},
{ value: "J029",	text: "FARINGITIS AGUDA, NO ESPECIFICADA"},
{ value: "J030",	text: "AMIGDALITIS ESTREPTOCOCICA"},
{ value: "J038",	text: "AMIGDALITIS AGUDA DEBIDA A OTROS MICROORGANISMOS ESPECIFICADOS"},
{ value: "J039",	text: "AMIGDALITIS AGUDA, NO ESPECIFICADA"},
{ value: "J040",	text: "LARINGITIS AGUDA"},
{ value: "J041",	text: "TRAQUEITIS AGUDA"},
{ value: "J042",	text: "LARINGOTRAQUEITIS AGUDA"},
{ value: "J050",	text: "LARINGITIS OBSTRUCTIVA, AGUDA [CRUP]"},
{ value: "J051",	text: "EPIGLOTITIS AGUDA"},
{ value: "J060",	text: "LARINGOFARINGITIS AGUDA"},
{ value: "J068",	text: "OTRAS INFECCIONES AGUDAS DE SITIOS MULTIPLES DE LAS VIAS RESPIRATORIAS SUPERIORES"},
{ value: "J069",	text: "INFECCION AGUDA DE LAS VIAS RESPIRATORIAS SUPERIORES, NO ESPECIFICADA"},
{ value: "J100",	text: "INFLUENZA CON NEUMONIA, DEBIDA A VIRUS DE LA INFLUENZA IDENTIFICADO"},
{ value: "J101",	text: "INFLUENZA CON OTRAS MANIFESTACIONES RESPIRATORIAS, DEBIDA A VIRUS DE LA INFLUENZA IDENTIFICADO"},
{ value: "J108",	text: "INFLUENZA, CON OTRAS MANIFESTACIONES, DEBIDA A VIRUS DE LA INFLUENZA IDENTIFICADO"},
{ value: "J110",	text: "INFLUENZA CON NEUMONIA, VIRUS NO IDENTIFICADO"},
{ value: "J111",	text: "INFLUENZA CON OTRAS MANIFESTACIONES RESPIRATORIAS, VIRUS NO IDENTIFICADO"},
{ value: "J118",	text: "INFLUENZA CON OTRAS MANIFESTACIONES, VIRUS NO IDENTIFICADO"},
{ value: "J120",	text: "NEUMONIA DEBIDA A ADENOVIRUS"},
{ value: "J121",	text: "NEUMONIA DEBIDA A VIRUS SINCITIAL RESPIRATORIO"},
{ value: "J122",	text: "NEUMONIA DEBIDA A VIRUS PARAINFLUENZA"},
{ value: "J128",	text: "NEUMONIA DEBIDA A OTROS VIRUS"},
{ value: "J129",	text: "NEUMONIA VIRAL, NO ESPECIFICADA"},
{ value: "J13X",	text: "NEUMONIA DEBIDA A STREPTOCOCCUS PNEUMONIAE"},
{ value: "J14X",	text: "NEUMONIA DEBIDA A HAEMOPHILUS INFLUENZAE"},
{ value: "J150",	text: "NEUMONIA DEBIDA A KLEBSIELLA PNEUMONIAE"},
{ value: "J151",	text: "NEUMONIA DEBIDA A PSEUDOMONAS"},
{ value: "J152",	text: "NEUMONIA DEBIDA A ESTAFILOCOCOS"},
{ value: "J153",	text: "NEUMONIA DEBIDA A ESTREPTOCOCOS DEL GRUPO B"},
{ value: "J154",	text: "NEUMONIA DEBIDA A OTROS ESTREPTOCOCOS"},
{ value: "J155",	text: "NEUMONIA DEBIDA A ESCHERICHIA COLI"},
{ value: "J156",	text: "NEUMONIA DEBIDA A OTRAS BACTERIAS AEROBICAS GRAMNEGATIVAS"},
{ value: "J157",	text: "NEUMONIA DEBIDA A MYCOPLASMA PNEUMONIAE"},
{ value: "J158",	text: "OTRAS NEUMONIAS BACTERIANAS"},
{ value: "J159",	text: "NEUMONIA BACTERIANA, NO ESPECIFICADA"},
{ value: "J160",	text: "NEUMONIA DEBIDA A CLAMIDIAS"},
{ value: "J168",	text: "NEUMONIA DEBIDA A OTROS MICROORGANISMOS INFECCIOSOS ESPECIFICADOS"},
{ value: "J170",	text: "NEUMONIA EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"},
{ value: "J171",	text: "NEUMONIA EN ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE"},
{ value: "J172",	text: "NEUMONIA EN MICOSIS"},
{ value: "J173",	text: "NEUMONIA EN ENFERMEDADES PARASITARIAS"},
{ value: "J178",	text: "NEUMONIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "J180",	text: "BRONCONEUMONIA, NO ESPECIFICADA"},
{ value: "J181",	text: "NEUMONÍA LOBAR, NO ESPECIFICADA"},
{ value: "J182",	text: "NEUMONIA HIPOSTATICA, NO ESPECIFICADA"},
{ value: "J188",	text: "OTRAS NEUMONIAS, DE MICROORGANISMO NO ESPECIFICADO"},
{ value: "J189",	text: "NEUMONIA, NO ESPECIFICADA"},
{ value: "J200",	text: "BRONQUITIS AGUDA DEBIDA A MYCOPLASMA PNEUMONIAE"},
{ value: "J201",	text: "BRONQUITIS AGUDA DEBIDA A HAEMOPHILUS INFLUENZAE"},
{ value: "J202",	text: "BRONQUITIS AGUDA DEBIDA A ESTREPTOCOCOS"},
{ value: "J203",	text: "BRONQUITIS AGUDA DEBIDA A VIRUS COXSACKIE"},
{ value: "J204",	text: "BRONQUITIS AGUDA DEBIDA A VIRUS PARAINFLUENZA"},
{ value: "J205",	text: "BRONQUITIS AGUDA DEBIDA A VIRUS SINCITIAL RESPIRATORIO"},
{ value: "J206",	text: "BRONQUITIS AGUDA DEBIDA A RINOVIRUS"},
{ value: "J207",	text: "BRONQUITIS AGUDA DEBIDA A VIRUS ECHO"},
{ value: "J208",	text: "BRONQUITIS AGUDA DEBIDA A OTROS MICROORGANISMOS ESPECIFICADOS"},
{ value: "J209",	text: "BRONQUITIS AGUDA, NO ESPECIFICADA"},
{ value: "J210",	text: "BRONQUIOLITIS AGUDA DEBIDA A VIRUS SINCITIAL RESPIRATORIO"},
{ value: "J218",	text: "BRONQUIOLITIS AGUDA DEBIDA A OTROS MICROORGANISMOS ESPECIFICADOS"},
{ value: "J219",	text: "BRONQUIOLITIS AGUDA, NO ESPECIFICADA"},
{ value: "J22X",	text: "INFECCION AGUDA NO ESPECIFICADA DE LAS VIAS RESPIRATORIAS INFERIORES"},
{ value: "J300",	text: "RINITIS VASOMOTORA"},
{ value: "J301",	text: "RINITIS ALERGICA DEBIDA AL POLEN"},
{ value: "J302",	text: "OTRA RINITIS ALERGICA ESTACIONAL"},
{ value: "J303",	text: "OTRAS RINITIS ALERGICAS"},
{ value: "J304",	text: "RINITIS ALERGICA, NO ESPECIFICADA"},
{ value: "J310",	text: "RINITIS CRONICA"},
{ value: "J311",	text: "RINOFARINGITIS CRONICA"},
{ value: "J312",	text: "FARINGITIS CRONICA"},
{ value: "J320",	text: "SINUSITIS MAXILAR CRONICA"},
{ value: "J321",	text: "SINUSITIS FRONTAL CRONICA"},
{ value: "J322",	text: "SINUSITIS ETMOIDAL CRONICA"},
{ value: "J323",	text: "SINUSITIS ESFENOIDAL CRONICA"},
{ value: "J324",	text: "PANSINUSITIS CRONICA"},
{ value: "J328",	text: "OTRAS SINUSITIS CRONICAS"},
{ value: "J329",	text: "SINUSITIS CRONICA, NO ESPECIFICADA"},
{ value: "J330",	text: "POLIPO DE LA CAVIDAD NASAL"},
{ value: "J331",	text: "DEGENERACION POLIPOIDE DE SENO PARANASAL"},
{ value: "J338",	text: "OTROS POLIPOS DE LOS SENOS PARANASALES"},
{ value: "J339",	text: "POLIPO NASAL, NO ESPECIFICADO"},
{ value: "J340",	text: "ABSCESO, FURUNCULO Y ANTRAX DE LA NARIZ"},
{ value: "J341",	text: "QUISTE Y MUCOCELE DE LA NARIZ Y DEL SENO PARANASAL"},
{ value: "J342",	text: "DESVIACION DEL TABIQUE NASAL"},
{ value: "J343",	text: "HIPERTROFIA DE LOS CORNETES NASALES"},
{ value: "J348",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA NARIZ Y DE LOS SENOS PARANASALES"},
{ value: "J350",	text: "AMIGDALITIS CRONICA"},
{ value: "J351",	text: "HIPERTROFIA DE LAS AMIGDALAS"},
{ value: "J352",	text: "HIPERTROFIA DE LAS ADENOIDES"},
{ value: "J353",	text: "HIPERTROFIA DE LAS AMIGDALAS CON HIPERTROFIA DE LAS ADENOIDES"},
{ value: "J358",	text: "OTRAS ENFERMEDADES CRONICAS DE LAS AMIGDALAS Y DE LAS ADENOIDES"},
{ value: "J359",	text: "ENFERMEDAD CRONICAS DE LAS AMIGDALAS Y DE LAS ADENOIDES, NO ESPECIFICADA"},
{ value: "J36X",	text: "ABSCESO PERIAMIGDALINO"},
{ value: "J370",	text: "LARINGITIS CRONICA"},
{ value: "J371",	text: "LARINGOTRAQUEITIS CRONICA"},
{ value: "J380",	text: "PARALISIS DE LAS CUERDAS VOCALES Y DE LA LARINGE"},
{ value: "J381",	text: "POLIPO DE LAS CUERDAS VOCALES Y DE LA LARINGE"},
{ value: "J382",	text: "NODULOS DE LAS CUERDAS VOCALES"},
{ value: "J383",	text: "OTRAS ENFERMEDADES DE LAS CUERDAS VOCALES"},
{ value: "J384",	text: "EDEMA DE LARINGE"},
{ value: "J385",	text: "ESPASMO LARINGEO"},
{ value: "J386",	text: "ESTENOSIS LARINGEA"},
{ value: "J387",	text: "OTRAS ENFERMEDADES DE LA LARINGE"},
{ value: "J390",	text: "ABSCESO RETROFARINGEO Y PARAFARINGEO"},
{ value: "J391",	text: "OTROS ABSCESOS DE LA FARINGE"},
{ value: "J392",	text: "OTRAS ENFERMEDADES DE LA FARINGE"},
{ value: "J393",	text: "REACCION DE HIPERSENSIBILIDAD DE LAS VIAS RESPIRATORIAS SUPERIORES, SITIO NO ESPECIFICADO"},
{ value: "J398",	text: "OTRAS ENFERMEDADES ESPECIFICADAS DE LAS VIAS RESPIRATORIAS SUPERIORES"},
{ value: "J399",	text: "ENFERMEDAD DE LAS VIAS RESPIRATORIAS SUPERIORES, NO ESPECIFICADA"},
{ value: "J40X",	text: "BRONQUITIS, NO ESPECIFICADA COMO AGUDA O CRONICA"},
{ value: "J410",	text: "BRONQUITIS CRONICA SIMPLE"},
{ value: "J411",	text: "BRONQUITIS CRONICA MUCOPURULENTA"},
{ value: "J418",	text: "BRONQUITIS CRONICA MIXTA SIMPLE Y MUCOPURULENTA"},
{ value: "J42X",	text: "BRONQUITIS CRONICA NO ESPECIFICADA"},
{ value: "J430",	text: "SINDROME DE MACLEOD"},
{ value: "J431",	text: "ENFISEMA PANLOBULAR"},
{ value: "J432",	text: "ENFISEMA CENTROLOBULAR"},
{ value: "J438",	text: "OTROS TIPOS DE ENFISEMA"},
{ value: "J439",	text: "ENFISEMA, NO ESPECIFICADO"},
{ value: "J440",	text: "ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA CON INFECCION AGUDA DE LAS VIAS RESPIRATORIAS INFERIORES"},
{ value: "J441",	text: "ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA CON EXACERBACION AGUDA, NO ESPECIFICADA"},
{ value: "J448",	text: "OTRAS ENFERMEDADES PULMONARES OBSTRUCTIVAS CRONICAS ESPECIFICADAS"},
{ value: "J449",	text: "ENFERMEDAD PULMONAR OBSTRUCTIVA CRONICA, NO ESPECIFICADA"},
{ value: "J450",	text: "ASMA PREDOMINANTEMENTE ALERGICA"},
{ value: "J451",	text: "ASMA NO ALERGICA"},
{ value: "J458",	text: "ASMA MIXTA"},
{ value: "J459",	text: "ASMA, NO ESPECIFICADA"},
{ value: "J46X",	text: "ESTADO ASMATICO"},
{ value: "J47X",	text: "BRONQUIECTASIA"},
{ value: "J60X",	text: "NEUMOCONIOSIS DE LOS MINEROS DEL CARBON"},
{ value: "J61X",	text: "NEUMOCONIOSIS DEBIDA AL ASBESTO Y A OTRAS FIBRAS MINERALES"},
{ value: "J620",	text: "NEUMOCONIOSIS DEBIDA A POLVO DE TALCO"},
{ value: "J628",	text: "NEUMOCONIOSIS DEBIDA A OTROS POLVOS QUE CONTIENEN SILICE"},
{ value: "J630",	text: "ALUMINOSIS (DEL PULMON)"},
{ value: "J631",	text: "FIBROSIS (DEL PULMON) DEBIDA A BAUXITA"},
{ value: "J632",	text: "BERILIOSIS"},
{ value: "J633",	text: "FIBROSIS (DEL PULMON) DEBIDA A GRAFITO"},
{ value: "J634",	text: "SIDEROSIS"},
{ value: "J635",	text: "ESTAÑOSIS"},
{ value: "J638",	text: "NEUMOCONIOSIS DEBIDA A OTROS POLVOS INORGANICOS ESPECIFICADOS"},
{ value: "J64X",	text: "NEUMOCONIOSIS, NO ESPECIFICADA"},
{ value: "J65X",	text: "NEUMOCONIOSIS, ASOCIADA CON TUBERCULOSIS"},
{ value: "J660",	text: "BISINOSIS"},
{ value: "J661",	text: "ENFERMEDAD DE LOS TRABAJADORES DEL LINO"},
{ value: "J662",	text: "CANABINOSIS"},
{ value: "J668",	text: "ENFERMEDAD DE LAS VIAS AEREAS DEBIDAS A OTROS POLVOS ORGANICOS ESPECIFICOS"},
{ value: "J670",	text: "PULMON DEL GRANJERO"},
{ value: "J671",	text: "BAGAZOSIS"},
{ value: "J672",	text: "PULMON DEL ORNITOFILO"},
{ value: "J673",	text: "SUBEROSIS"},
{ value: "J674",	text: "PULMON DEL MANIPULADOR DE MALTA"},
{ value: "J675",	text: "PULMON DEL MANIPULADOR DE HONGOS"},
{ value: "J676",	text: "PULMON DEL DESCORTEZADOR DEL ARCE"},
{ value: "J677",	text: "NEUMONITIS DE LA VENTILACION DEBIDA AL ACONDICIONADOR Y HUMIDIFICADOR DEL AIRE"},
{ value: "J678",	text: "NEUMONITIS DEBIDA A HIPERSENSIBILIDAD A OTROS POLVOS ORGANICOS"},
{ value: "J679",	text: "NEUMONITIS DEBIDA A HIPERSENSIBILIDAD A POLVO ORGANICO NO ESPECIFICADO"},
{ value: "J680",	text: "BRONQUITIS Y NEUMONITIS DEBIDAS A INHALACION DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUIMICAS"},
{ value: "J681",	text: "EDEMA PULMONAR AGUDO DEBIDO A INHALACION DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUIMICAS"},
{ value: "J682",	text: "INFLAMACION RESPIRATORIA SUPERIOR DEBIDA A INHALACION DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUIMICAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "J683",	text: "OTRAS AFECCIONES RESPIRATORIAS AGUDAS Y SUBAGUDAS DEBIDAS A INHALACION DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUÍMICAS"},
{ value: "J684",	text: "AFECCIONES RESPIRATORIAS CRONICAS DEBIDAS A INHALACION DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUIMICAS"},
{ value: "J688",	text: "OTRAS AFECCIONES RESPIRATORIAS DEBIDAS A INHALACION DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUIMICAS"},
{ value: "J689",	text: "AFECCION RESPIRATORIA NO ESPECIFICADA, DEBIDA A INHALACION DE GASES, HUMOS, VAPORES Y SUSTANCIAS QUIMICAS"},
{ value: "J690",	text: "NEUMONITIS DEBIDA A ASPIRACION DE ALIMENTO O VOMITO"},
{ value: "J691",	text: "NEUMONITIS DEBIDA A ASPIRACION DE ACEITES Y ESENCIAS"},
{ value: "J698",	text: "NEUMONITIS DEBIDA A ASPIRACION DE OTROS SOLIDOS Y LIQUIDOS"},
{ value: "J700",	text: "MANIFESTACIONES PULMONARES AGUDAS DEBIDAS A RADIACION"},
{ value: "J701",	text: "MANIFESTACIONES PULMONARES CRONICAS Y OTRAS MANIFESTACIONES DEBIDAS A RADIACION"},
{ value: "J702",	text: "TRASTORNOS PULMONARES INTERSTICIALES AGUDOS INDUCIDOS POR DROGAS"},
{ value: "J703",	text: "TRASTORNOS PULMONARES INTERSTICIALES CRONICOS INDUCIDOS POR DROGAS"},
{ value: "J704",	text: "TRASTORNOS PULMONARES INTERSTICIALES NO ESPECIFICADOS INDUCIDOS POR DROGAS"},
{ value: "J708",	text: "AFECCIONES RESPIRATORIAS DEBIDAS A OTROS AGENTES EXTERNOS ESPECIFICADOS"},
{ value: "J709",	text: "AFECCIONES RESPIRATORIAS DEBIDAS A AGENTES EXTERNOS NO ESPECIFICADOS"},
{ value: "J80X",	text: "SINDROME DE DIFICULTAD RESPIRATORIA DEL ADULTO"},
{ value: "J81X",	text: "EDEMA PULMONAR"},
{ value: "J82X",	text: "EOSINOFILIA PULMONAR, NO CLASIFICADA EN OTRA PARTE"},
{ value: "J840",	text: "AFECCIONES ALVEOLARES Y ALVEOLOPARIETALES"},
{ value: "J841",	text: "OTRAS ENFERMEDADES PULMONARES INTERSTICIALES CON FIBROSIS"},
{ value: "J848",	text: "OTRAS ENFERMEDADES PULMONARES INTERSTICIALES ESPECIFICADAS"},
{ value: "J849",	text: "ENFERMEDAD PULMONAR INTERSTICIAL, NO ESPECIFICADA"},
{ value: "J850",	text: "GANGRENA Y NECROSIS DEL PULMON"},
{ value: "J851",	text: "ABSCESO DEL PULMON CON NEUMONIA"},
{ value: "J852",	text: "ABSCESO DEL PULMON SIN NEUMONIA"},
{ value: "J853",	text: "ABSCESO DEL MEDIASTINO"},
{ value: "J860",	text: "PIOTORAX CON FISTULA"},
{ value: "J869",	text: "PIOTORAX SIN FISTULA"},
{ value: "J90X",	text: "DERRAME PLEURAL NO CLASIFICADO EN OTRA PARTE"},
{ value: "J91X",	text: "DERRAME PLEURAL EN AFECCIONES CLASIFICADAS EN OTRA PARTE"},
{ value: "J920",	text: "PAQUIPLEURITIS CON ASBESTOSIS"},
{ value: "J929",	text: "PAQUIPLEURITIS SIN ASBESTOSIS"},
{ value: "J930",	text: "NEUMOTORAX ESPONTANEO A PRESION"},
{ value: "J931",	text: "OTROS TIPOS DE NEUMOTORAX ESPONTANEO"},
{ value: "J938",	text: "OTROS NEUMOTORAX"},
{ value: "J939",	text: "NEUMOTORAX, NO ESPECIFICADO"},
{ value: "J940",	text: "QUILOTORAX"},
{ value: "J941",	text: "FIBROTORAX"},
{ value: "J942",	text: "HEMOTORAX"},
{ value: "J948",	text: "OTRAS AFECCIONES ESPECIFICADAS DE LA PLEURA"},
{ value: "J949",	text: "AFECCION PLEURAL, NO ESPECIFICADA"},
{ value: "J950",	text: "FUNCIONAMIENTO DEFECTUOSO DE LA TRAQUEOSTOMIA"},
{ value: "J951",	text: "INSUFICIENCIA PULMONAR AGUDA CONSECUTIVA A CIRUGIA TORACICA"},
{ value: "J952",	text: "INSUFICIENCIA PULMONAR AGUDA CONSECUTIVA A CIRUGIA EXTRATORACICA"},
{ value: "J953",	text: "INSUFICIENCIA PULMONAR CRONICA CONSECUTIVA A CIRUGIA"},
{ value: "J954",	text: "SINDROME DE MENDELSON"},
{ value: "J955",	text: "ESTENOSIS SUBGLOTICA CONSECUTIVA A PROCEDIMIENTOS"},
{ value: "J958",	text: "OTROS TRASTORNOS RESPIRATORIOS CONSECUTIVOS A PROCEDIMIENTOS"},
{ value: "J959",	text: "TRASTORNO NO ESPECIFICADO DEL SISTEMA RESPIRATORIO, CONSECUTIVOS A PROCEDIMIENTOS"},
{ value: "J960",	text: "INSUFICIENCIA RESPIRATORIA AGUDA"},
{ value: "J961",	text: "INSUFICIENCIA RESPIRATORIA CRONICA"},
{ value: "J969",	text: "INSUFICIENCIA RESPIRATORIA, NO ESPECIFICADA"},
{ value: "J980",	text: "ENFERMEDADES DE LA TRAQUEA Y DE LOS BRONQUIOS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "J981",	text: "COLAPSO PULMONAR"},
{ value: "J982",	text: "ENFISEMA INTERSTICIAL"},
{ value: "J983",	text: "ENFISEMA COMPENSATORIO"},
{ value: "J984",	text: "OTROS TRASTORNOS DEL PULMON"},
{ value: "J985",	text: "ENFERMEDADES DEL MEDIASTINO, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "J986",	text: "TRASTORNOS DEL DIAFRAGMA"},
{ value: "J988",	text: "OTROS TRASTORNOS RESPIRATORIOS ESPECIFICADOS"},
{ value: "J989",	text: "TRASTORNO RESPIRATORIO, NO ESPECIFICADO"},
{ value: "J990",	text: "ENFERMEDAD PULMONAR REUMATOIDE (M05.1†)"},
{ value: "J991",	text: "TRASTORNOS RESPIRATORIOS EN OTROS TRASTORNOS DIFUSOS DEL TEJIDO CONJUNTIVO"},
{ value: "J998",	text: "TRASTORNOS RESPIRATORIOS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "K000",	text: "ANODONCIA"},
{ value: "K001",	text: "DIENTES SUPERNUMERARIOS"},
{ value: "K002",	text: "ANOMALIAS DEL TAMAÑO Y DE LA FORMA DEL DIENTE"},
{ value: "K003",	text: "DIENTES MOTEADOS"},
{ value: "K004",	text: "ALTERACIONES EN LA FORMACION DENTARIA"},
{ value: "K005",	text: "ALTERACIONES HEREDITARIAS DE LA ESTRUCTURA DENTARIA, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "K006",	text: "ALTERACIONES EN LA ERUPCION DENTARIA"},
{ value: "K007",	text: "SINDROME DE LA ERUPCION DENTARIA"},
{ value: "K008",	text: "OTROS TRASTORNOS DEL DESARROLLO DE LOS DIENTES"},
{ value: "K009",	text: "TRASTORNO DEL DESARROLLO DE LOS DIENTES, NO ESPECIFICADO"},
{ value: "K010",	text: "DIENTES INCLUIDOS"},
{ value: "K011",	text: "DIENTES IMPACTADOS"},
{ value: "K020",	text: "CARIES LIMITADA AL ESMALTE"},
{ value: "K021",	text: "CARIES DE LA DENTINA"},
{ value: "K022",	text: "CARIES DEL CEMENTO"},
{ value: "K023",	text: "CARIES DENTARIA DETENIDA"},
{ value: "K024",	text: "ODONTOCLASIA"},
{ value: "K028",	text: "OTRAS CARIES DENTALES"},
{ value: "K029",	text: "CARIES DENTAL, NO ESPECIFICADA"},
{ value: "K030",	text: "ATRICION EXCESIVA DE LOS DIENTES"},
{ value: "K031",	text: "ABRASION DE LOS DIENTES"},
{ value: "K032",	text: "EROSION DE LOS DIENTES"},
{ value: "K033",	text: "REABSORCION PATOLOGICA DE LOS DIENTES"},
{ value: "K034",	text: "HIPERCEMENTOSIS"},
{ value: "K035",	text: "ANQUILOSIS DENTAL"},
{ value: "K036",	text: "DEPOSITOS [ACRECIONES] EN LOS DIENTES"},
{ value: "K037",	text: "CAMBIOS POSTERUPTIVOS DEL COLOR DE LOS TEJIDOS DENTALES DUROS"},
{ value: "K038",	text: "OTRAS ENFERMEDADES ESPECIFICADAS DE LOS TEJIDOS DUROS DE LOS DIENTES"},
{ value: "K039",	text: "ENFERMEDAD NO ESPECIFICADA DE LOS TEJIDOS DENTALES DUROS"},
{ value: "K040",	text: "PULPITIS"},
{ value: "K041",	text: "NECROSIS DE LA PULPA"},
{ value: "K042",	text: "DEGENERACION DE LA PULPA"},
{ value: "K043",	text: "FORMACION ANORMAL DE TEJIDO DURO EN LA PULPA"},
{ value: "K044",	text: "PERIODONTITIS APICAL AGUDA ORIGINADA EN LA PULPA"},
{ value: "K045",	text: "PERIODONTITIS APICAL CRONICA"},
{ value: "K046",	text: "ABSCESO PERIAPICAL CON FISTULA"},
{ value: "K047",	text: "ABSCESO PERIAPICAL SIN FISTULA"},
{ value: "K048",	text: "QUISTE RADICULAR"},
{ value: "K049",	text: "OTRAS ENFERMEDADES Y LAS NO ESPECIFICADAS DE LA PULPA Y DEL TEJIDO PERIAPICAL"},
{ value: "K050",	text: "GINGIVITIS AGUDA"},
{ value: "K051",	text: "GINGIVITIS CRONICA"},
{ value: "K052",	text: "PERIODONTITIS AGUDA"},
{ value: "K053",	text: "PERIODONTITIS CRONICA"},
{ value: "K054",	text: "PERIODONTOSIS"},
{ value: "K055",	text: "OTRAS ENFERMEDADES PERIODONTALES"},
{ value: "K056",	text: "ENFERMEDAD DE PERIODONTO, NO ESPECIFICADA"},
{ value: "K060",	text: "RETRACCION GINGIVAL"},
{ value: "K061",	text: "HIPERPLASIA GINGIVAL"},
{ value: "K062",	text: "LESIONES DE LA ENCIA Y DE LA ZONA EDENTULA ASOCIADAS CON TRAUMATISMO"},
{ value: "K068",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA ENCIA Y DE LA ZONA EDENTULA"},
{ value: "K069",	text: "TRASTORNO NO ESPECIFICADO DE LA ENCIA Y DE LA ZONA EDENTULA"},
{ value: "K070",	text: "ANOMALIAS EVIDENTES DEL TAMAÑO DE LOS MAXILARES"},
{ value: "K071",	text: "ANOMALIAS DE LA RELACION MAXILOBASILAR"},
{ value: "K072",	text: "ANOMALIAS DE LA RELACION ENTRE LOS ARCOS DENTARIOS"},
{ value: "K073",	text: "ANOMALIAS DE LA POSICION DEL DIENTE"},
{ value: "K074",	text: "MALOCLUSION DE TIPO NO ESPECIFICADO"},
{ value: "K075",	text: "ANOMALIAS DENTOFACIALES FUNCIONALES"},
{ value: "K076",	text: "TRASTORNOS DE LA ARTICULACION TEMPOROMAXILAR"},
{ value: "K078",	text: "OTRAS ANOMALIAS DENTOFACIALES"},
{ value: "K079",	text: "ANOMALIA DENTOFACIAL, NO ESPECIFICADA"},
{ value: "K080",	text: "EXFOLIACION DE LOS DIENTES DEBIDA A CAUSAS SISTEMICAS"},
{ value: "K081",	text: "PERDIDA DE DIENTES DEBIDA A ACCIDENTE, EXTRACCION O ENFERMEDAD PERIODONTAL LOCAL"},
{ value: "K082",	text: "ATROFIA DE REBORDE ALVEOLAR DESDENTADO"},
{ value: "K083",	text: "RAIZ DENTAL RETENIDA"},
{ value: "K088",	text: "OTRAS AFECCIONES ESPECIFICADAS DE LOS DIENTES Y DE SUS ESTRUCTURAS DE SOSTEN"},
{ value: "K089",	text: "TRASTORNO DE LOS DIENTES Y DE SUS ESTRUCTURAS DE SOSTEN, NO ESPECIFICADO"},
{ value: "K090",	text: "QUISTES ORIGINADOS POR EL DESARROLLO DE LOS DIENTES"},
{ value: "K091",	text: "QUISTES DE LAS FISURAS (NO ODONTOGENICOS)"},
{ value: "K092",	text: "OTROS QUISTES DE LOS MAXILARES"},
{ value: "K098",	text: "OTROS QUISTES DE LA REGION BUCAL, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "K099",	text: "QUISTE DE LA REGION BUCAL, SIN OTRA ESPECIFICACION"},
{ value: "K100",	text: "TRASTORNOS DEL DESARROLLO DE LOS MAXILARES"},
{ value: "K101",	text: "GRANULOMA CENTRAL DE CELULAS GIGANTES"},
{ value: "K102",	text: "AFECCIONES INFLAMATORIAS DE LOS MAXILARES"},
{ value: "K103",	text: "ALVEOLITIS DEL MAXILAR"},
{ value: "K108",	text: "OTRAS ENFERMEDADES ESPECIFICADAS DE LOS MAXILARES"},
{ value: "K109",	text: "ENFERMEDAD DE LOS MAXILARES, NO ESPECIFICADA"},
{ value: "K110",	text: "ATROFIA DE GLANDULA SALIVAL"},
{ value: "K111",	text: "HIPERTROFIA DE GLANDULA SALIVAL"},
{ value: "K112",	text: "SIALADENITIS"},
{ value: "K113",	text: "ABSCESO DE GLANDULA SALIVAL"},
{ value: "K114",	text: "FISTULA DE GLANDULA SALIVAL"},
{ value: "K115",	text: "SIALOLITIASIS"},
{ value: "K116",	text: "MUCOCELE DE GLANDULA SALIVAL"},
{ value: "K117",	text: "ALTERACIONES DE LA SECRECION SALIVAL"},
{ value: "K118",	text: "OTRAS ENFERMEDADES DE LAS GLANDULAS SALIVALES"},
{ value: "K119",	text: "ENFERMEDAD DE GLANDULA SALIVAL. NO ESPECIFICADA"},
{ value: "K120",	text: "ESTOMATITIS AFTOSA RECURRENTE"},
{ value: "K121",	text: "OTRAS FORMAS DE ESTOMATITIS"},
{ value: "K122",	text: "CELULITIS Y ABSCESO DE BOCA"},
{ value: "K130",	text: "ENFERMEDADES DE LOS LABIOS"},
{ value: "K131",	text: "MORDEDURA DEL LABIO Y DE LA MEJILLA"},
{ value: "K132",	text: "LEUCOPLASIA Y OTRAS ALTERACIONES DEL EPITELIO BUCAL, INCLUYENDO LA LENGUA"},
{ value: "K133",	text: "LEUCOPLASIA PILOSA"},
{ value: "K134",	text: "GRANULOMA Y LESIONES SEMEJANTES DE LA MUCOSA BUCAL"},
{ value: "K135",	text: "FIBROSIS DE LA SUBMUCOSA BUCAL"},
{ value: "K136",	text: "HIPERPLASIA IRRITATIVA DE LA MUCOSA BUCAL"},
{ value: "K137",	text: "OTRAS LESIONES Y LAS NO ESPECIFICADAS DE LA MUCOSA BUCAL"},
{ value: "K140",	text: "GLOSITIS"},
{ value: "K141",	text: "LENGUA GEOGRAFICA"},
{ value: "K142",	text: "GLOSITIS ROMBOIDEA MEDIANA"},
{ value: "K143",	text: "HIPERTROFIA DE LAS PAPILAS LINGUALES"},
{ value: "K144",	text: "ATROFIA DE LAS PAPILAS LINGUALES"},
{ value: "K145",	text: "LENGUA PLEGADA"},
{ value: "K146",	text: "GLOSODINIA"},
{ value: "K148",	text: "OTRAS ENFERMEDADES DE LA LENGUA"},
{ value: "K149",	text: "ENFERMEDAD DE LA LENGUA, NO ESPECIFICADA"},
{ value: "K20X",	text: "ESOFAGITIS"},
{ value: "K210",	text: "ENFERMEDAD DEL REFLUJO GASTROESOFAGICO CON ESOFAGITIS"},
{ value: "K219",	text: "ENFERMEDAD DEL REFLUJO GASTROESOFAGICO SIN ESOFAGITIS"},
{ value: "K220",	text: "ACALASIA DEL CARDIAS"},
{ value: "K221",	text: "ULCERA DEL ESOFAGO"},
{ value: "K222",	text: "OBSTRUCCION DEL ESOFAGO"},
{ value: "K223",	text: "PERFORACION DEL ESOFAGO"},
{ value: "K224",	text: "DISQUINESIA DEL ESOFAGO"},
{ value: "K225",	text: "DIVERTICULO DEL ESOFAGO, ADQUIRIDO"},
{ value: "K226",	text: "SINDROME DE LACERACION Y HEMORRAGIA GASTROESOFAGICAS"},
{ value: "K228",	text: "OTRAS ENFERMEDADES ESPECIFICADAS DEL ESOFAGO"},
{ value: "K229",	text: "ENFERMEDAD DEL ESOFAGO, NO ESPECIFICADA"},
{ value: "K230",	text: "ESOFAGITIS TUBERCULOSA (A18.†)"},
{ value: "K231",	text: "MEGAESOFAGO EN LA ENFERMEDAD DE CHAGAS (B57.3†)"},
{ value: "K238",	text: "TRASTORNOS DEL ESOFAGO EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "K250",	text: "ULCERA GASTRICA AGUDA CON HEMORRAGIA"},
{ value: "K251",	text: "ULCERA GASTRICA AGUDA CON PERFORACION"},
{ value: "K252",	text: "ULCERA GASTRICA AGUDA CON HEMORRAGIA Y PERFORACION"},
{ value: "K253",	text: "ULCERA GASTRICA AGUDA SIN HEMORRAGIA NI PERFORACION"},
{ value: "K254",	text: "ULCERA GASTRICA CRONICA O NO ESPECIFICADA, CON HEMORRAGIA"},
{ value: "K255",	text: "ULCERA GASTRICA CRONICA O NO ESPECIFICADA, CON PERFORACION"},
{ value: "K256",	text: "ULCERA GASTRICA CRONICA O NO ESPECIFICADA, CON HEMORRAGIA Y PERFORACION"},
{ value: "K257",	text: "ULCERA GASTRICA CRONICA SIN HEMORRAGIA NI PERFORACION"},
{ value: "K259",	text: "ULCERA GASTRICA NO ESPECIFICADA COMO AGUDA NI CRONICA, SIN HEMORRAGIA NI PERFORACION"},
{ value: "K260",	text: "ULCERA DUODENAL AGUDA CON HEMORRAGIA"},
{ value: "K261",	text: "ULCERA DUODENAL AGUDA CON PERFORACION"},
{ value: "K262",	text: "ULCERA DUODENAL AGUDA CON HEMORRAGIA Y PERFORACION"},
{ value: "K263",	text: "ULCERA DUODENAL AGUDA SIN HEMORRAGIA NI PERFORACION"},
{ value: "K264",	text: "ULCERA DUODENAL CRONICA O NO ESPECIFICADA, CON HEMORRAGIA"},
{ value: "K265",	text: "ULCERA DUODENAL CRONICA O NO ESPECIFICADA, CON PERFORACION"},
{ value: "K266",	text: "ULCERA DUODENAL CRONICA O NO ESPECIFICADA, CON HEMORRAGIA Y PERFORACION"},
{ value: "K267",	text: "ULCERA DUODENAL CRONICA SIN HEMORRAGIA NI PERFORACION"},
{ value: "K269",	text: "ULCERA DUODENAL NO ESPECIFICADA COMO AGUDA NI CRONICA, SIN HEMORRAGIA NI PERFORACION"},
{ value: "K270",	text: "ULCERA PEPTICA, DE SITIO NO ESPECIFICADO AGUDA CON HEMORRAGIA"},
{ value: "K271",	text: "ULCERA PEPTICA, DE SITIO NO ESPECIFICADO AGUDA CON PERFORACION"},
{ value: "K272",	text: "ULCERA PEPTICA, DE SITIO NO ESPECIFICADO AGUDA CON HEMORRAGIA Y PERFORACION"},
{ value: "K273",	text: "ULCERA PEPTICA, DE SITIO NO ESPECIFICADO AGUDA SIN HEMORRAGIA NI PERFORACION"},
{ value: "K274",	text: "ULCERA PEPTICA, DE SITIO NO ESPECIFICADO CRONICA O NO ESPECIFICADA, CON HEMORRAGIA"},
{ value: "K275",	text: "ULCERA PEPTICA, DE SITIO NO ESPECIFICADO CRONICA O NO ESPECIFICADA, CON PERFORACION"},
{ value: "K276",	text: "ULCERA PEPTICA, DE SITIO NO ESPECIFICADO CRONICA O NO ESPECIFICADA, CON HEMORRAGIA Y PERFORACION"},
{ value: "K277",	text: "ULCERA PEPTICA, DE SITIO NO ESPECIFICADO CRONICA SIN HEMORRAGIA NI PERFORACION"},
{ value: "K279",	text: "ULCERA PEPTICA, DE SITIO NO ESPECIFICADO NO ESPECIFICADA COMO AGUDA NI CRONICA, SIN HEMORRAGIA NI PERFORACION"},
{ value: "K280",	text: "ULCERA GASTROYEYUNAL AGUDA CON HEMORRAGIA"},
{ value: "K281",	text: "ULCERA GASTROYEYUNAL AGUDA CON PERFORACION"},
{ value: "K282",	text: "ULCERA GASTROYEYUNAL AGUDA CON HEMORRAGIA Y PERFORACION"},
{ value: "K283",	text: "ULCERA GASTROYEYUNAL AGUDA SIN HEMORRAGIA NI PERFORACION"},
{ value: "K284",	text: "ULCERA GASTROYEYUNAL CRONICA O NO ESPECIFICADA, CON HEMORRAGIA"},
{ value: "K285",	text: "ULCERA GASTROYEYUNAL CRONICA O NO ESPECIFICADA, CON PERFORACION"},
{ value: "K286",	text: "ULCERA GASTROYEYUNAL CRONICA O NO ESPECIFICADA, CON HEMORRAGIA Y PERFORACION"},
{ value: "K287",	text: "ULCERA GASTROYEYUNAL CRONICA SIN HEMORRAGIA NI PERFORACION"},
{ value: "K289",	text: "ULCERA GASTROYEYUNAL NO ESPECIFICADA COMO AGUDA NI CRONICA, SIN HEMORRAGIA NI PERFORACION"},
{ value: "K290",	text: "GASTRITIS AGUDA HEMORRAGICA"},
{ value: "K291",	text: "OTRAS GASTRITIS AGUDAS"},
{ value: "K292",	text: "GASTRITIS ALCOHOLICA"},
{ value: "K293",	text: "GASTRITIS CRONICA SUPERFICIAL."},
{ value: "K294",	text: "GASTRITIS CRONICA ATROFICA"},
{ value: "K295",	text: "GASTRITIS CRONICA, NO ESPECIFICADA"},
{ value: "K296",	text: "OTRAS GASTRITIS"},
{ value: "K297",	text: "GASTRITIS, NO ESPECIFICADA"},
{ value: "K298",	text: "DUODENITIS"},
{ value: "K299",	text: "GASTRODUODENITIS, NO ESPECIFICADA"},
{ value: "K30X",	text: "DISPEPSIA"},
{ value: "K310",	text: "DILATACION AGUDA DEL ESTOMAGO"},
{ value: "K311",	text: "ESTENOSIS PILORICA HIPERTROFICA DEL ADULTO"},
{ value: "K312",	text: "ESTRECHEZ O ESTENOSIS DEL ESTOMAGO EN RELOJ DE ARENA"},
{ value: "K313",	text: "ESPASMO DEL PILORO, NO CLASIFICADO EN OTRA PARTE"},
{ value: "K314",	text: "DIVERTICULO GASTRICO"},
{ value: "K315",	text: "OBSTRUCCION DEL DUODENO"},
{ value: "K316",	text: "FISTULA DEL ESTOMAGO Y DEL DUODENO"},
{ value: "K317",	text: "POLIPO DEL ESTOMAGO Y DEL DUODENO"},
{ value: "K318",	text: "OTRAS ENFERMEDADES ESPECIFICADAS DEL ESTOMAGO Y DEL DUODENO"},
{ value: "K319",	text: "ENFERMEDAD DEL ESTOMAGO Y DEL DUODENO, NO ESPECIFICADA"},
{ value: "K350",	text: "APENDICITIS AGUDA CON PERITONITIS GENERALIZADA"},
{ value: "K351",	text: "APENDICITIS AGUDA CON ABSCESO PERITONEAL"},
{ value: "K359",	text: "APENDICITIS AGUDA, NO ESPECIFICADA"},
{ value: "K36X",	text: "OTROS TIPOS DE APENDICITIS"},
{ value: "K37X",	text: "APENDICITIS, NO ESPECIFICADA"},
{ value: "K380",	text: "HIPERPLASIA DEL APENDICE"},
{ value: "K381",	text: "CONCRECIONES APENDICULARES"},
{ value: "K382",	text: "DIVERTICULO DEL APENDICE"},
{ value: "K383",	text: "FISTULA DEL APENDICE"},
{ value: "K388",	text: "OTRAS ENFERMEDADES ESPECIFICADAS DEL APENDICE"},
{ value: "K389",	text: "ENFERMEDAD DEL APENDICE, NO ESPECIFICADA"},
{ value: "K400",	text: "HERNIA INGUINAL BILATERAL CON OBSTRUCCION, SIN GANGRENA"},
{ value: "K401",	text: "HERNIA INGUINAL BILATERAL CON GANGRENA"},
{ value: "K402",	text: "HERNIA INGUINAL BILATERAL, SIN OBSTRUCCION NI GANGRENA"},
{ value: "K403",	text: "HERNIA INGUINAL UNILATERAL O NO ESPECIFICADA, CON OBSTRUCCION, SIN GANGRENA"},
{ value: "K404",	text: "HERNIA INGUINAL UNILATERAL O NO ESPECIFICADA, CON GANGRENA"},
{ value: "K409",	text: "HERNIA INGUINAL UNILATERAL O NO ESPECIFICADA, SIN OBSTRUCION NI GANGRENA"},
{ value: "K410",	text: "HERNIA FEMORAL BILATERAL, CON OBSTRUCCION, SIN GANGRENA"},
{ value: "K411",	text: "HERNIA FEMORAL BILATERAL, CON GANGRENA"},
{ value: "K412",	text: "HERNIA FEMORAL BILATERAL, SIN OBSTRUCCION NI GANGRENA"},
{ value: "K413",	text: "HERNIA FEMORAL UNILATERAL O NO ESPECIFICADA, CON OBSTRUCCION, SIN GANGRENA"},
{ value: "K414",	text: "HERNIA FEMORAL UNILATERAL O NO ESPECIFICADA, CON GANGRENA"},
{ value: "K419",	text: "HERNIA FEMORAL UNILATERAL O NO ESPECIFICADA, SIN OBSTRUCCION NI GANGRENA"},
{ value: "K420",	text: "HERNIA UMBILICAL CON OBSTRUCCION, SIN GANGRENA"},
{ value: "K421",	text: "HERNIA UMBILICAL CON GANGRENA"},
{ value: "K429",	text: "HERNIA UMBILICAL SIN OBSTRUCCION NI GANGRENA"},
{ value: "K430",	text: "HERNIA VENTRAL CON OBSTRUCCION, SIN GANGRENA"},
{ value: "K431",	text: "HERNIA VENTRAL CON GANGRENA"},
{ value: "K439",	text: "HERNIA VENTRAL SIN OBSTRUCCION NI GANGRENA"},
{ value: "K440",	text: "HERNIA DIAFRAGMATICA CON OBSTRUCCION, SIN GANGRENA"},
{ value: "K441",	text: "HERNIA DIAFRAGMATICA CON GANGRENA"},
{ value: "K449",	text: "HERNIA DIAFRAGMATICA SIN OBSTRUCCIÓN NI GANGRENA"},
{ value: "K450",	text: "OTRAS HERNIAS DE LA CAVIDAD ABDOMINAL ESPECIFICADAS, CON OBSTRUCCION, SIN GANGRENA"},
{ value: "K451",	text: "OTRAS HERNIAS DE LA CAVIDAD ABDOMINAL ESPECIFICADAS, CON GANGRENA"},
{ value: "K458",	text: "OTRAS HERNIAS DE LA CAVIDAD ABDOMINAL ESPECIFICADAS, SIN OBSTRUCCION NI GANGRENA"},
{ value: "K460",	text: "HERNIA ABDOMINAL NO ESPECIFICADA, CON OBSTRUCCION, SIN GANGRENA"},
{ value: "K461",	text: "HERNIA ABDOMINAL NO ESPECIFICADA, CON GANGRENA"},
{ value: "K469",	text: "HERNIA ABDOMINAL NO ESPECIFICADA, SIN OBSTRUCCION NI GANGRENA"},
{ value: "K500",	text: "ENFERMEDAD DE CROHN DEL INTESTINO DELGADO"},
{ value: "K501",	text: "ENFERMEDAD DE CROHN DEL INTESTINO GRUESO"},
{ value: "K508",	text: "OTROS TIPOS DE ENFERMEDAD DE CROHN"},
{ value: "K509",	text: "ENFERMEDAD DE CROHN, NO ESPECIFICADA"},
{ value: "K510",	text: "ENTEROCOLITIS (CRONICA) ULCERATIVA"},
{ value: "K511",	text: "ILEOCOLITIS (CRONICA) ULCERATIVA"},
{ value: "K512",	text: "PROCTITIS (CRONICA) ULCERATIVA"},
{ value: "K513",	text: "RECTOSIGMOIDITIS (CRONICA) ULCERATIVA"},
{ value: "K514",	text: "SEUDOPOLIPOSIS DEL COLON"},
{ value: "K515",	text: "PROCTOCOLITIS MUCOSA"},
{ value: "K518",	text: "OTRAS COLITIS ULCERATIVAS"},
{ value: "K519",	text: "COLITIS ULCERATIVA, SIN OTRA ESPECIFICACION"},
{ value: "K520",	text: "COLITIS Y GASTROENTERITIS DEBIDAS A RADIACION"},
{ value: "K521",	text: "COLITIS Y GASTROENTERITIS TOXICAS"},
{ value: "K522",	text: "COLITIS Y GASTROENTERITIS ALERGICAS Y DIETETICAS"},
{ value: "K528",	text: "OTRAS COLITIS Y GASTROENTERITIS NO INFECCIOSAS ESPECIFICADAS"},
{ value: "K529",	text: "COLITIS Y GASTROENTERITIS NO INFECCIOSAS, NO ESPECIFICADAS"},
{ value: "K550",	text: "TRASTORNO VASCULAR AGUDO DE LOS INTESTINOS"},
{ value: "K551",	text: "TRASTORNO VASCULAR CRONICO DEL INTESTINO"},
{ value: "K552",	text: "ANGIODISPLASIA DEL COLON"},
{ value: "K558",	text: "OTROS TRASTORNOS VASCULARES DEL INTESTINO"},
{ value: "K559",	text: "TRASTORNO VASCULAR DEL INTESTINO, NO ESPECIFICADO"},
{ value: "K560",	text: "ILEO PARALITICO"},
{ value: "K561",	text: "INVAGINACION"},
{ value: "K562",	text: "VOLVULO"},
{ value: "K563",	text: "ILEO POR CALCULO BILIAR"},
{ value: "K564",	text: "OTRAS OBSTRUCCIONES DEL INTESTINO"},
{ value: "K565",	text: "ADHERENCIAS [BRIDAS] INTESTINALES CON OBSTRUCCION"},
{ value: "K566",	text: "OTRAS OBSTRUCCIONES INTESTINALES Y LAS NO ESPECIFICADAS"},
{ value: "K567",	text: "ILEO, NO ESPECIFICADO"},
{ value: "K570",	text: "ENFERMEDAD DIVERTICULAR DEL INTESTINO DELGADO CON PERFORACION Y ABSCESO"},
{ value: "K571",	text: "ENFERMEDAD DIVERTICULAR DEL INTESTINO DELGADO SIN PERFORACION NI ABSCESO"},
{ value: "K572",	text: "ENFERMEDAD DIVERTICULAR DEL INTESTINO GRUESO CON PERFORACION Y ABSCESO"},
{ value: "K573",	text: "ENFERMEDAD DIVERTICULAR DEL INTESTINO GRUESO SIN PERFORACION NI ABSCESO"},
{ value: "K574",	text: "ENFERMEDAD DIVERTICULAR DE AMBOS INTESTINOS CON PERFORACION Y ABSCESO"},
{ value: "K575",	text: "ENFERMEDAD DIVERTICULAR DE AMBOS INTESTINOS, SIN PERFORACION NI ABSCESO"},
{ value: "K578",	text: "ENFERMEDAD DIVERTICULAR DEL INTESTINO, PARTE NO ESPECIFICADA, CON PERFORACION Y ABSCESO"},
{ value: "K579",	text: "ENFERMEDAD DIVERTICULAR DEL INTESTINO, PARTE NO ESPECIFICADA, SIN PERFORACION NI ABSCESO"},
{ value: "K580",	text: "SINDROME DEL COLON IRRITABLE CON DIARREA"},
{ value: "K589",	text: "SINDROME DEL COLON IRRITABLE SIN DIARREA"},
{ value: "K590",	text: "CONSTIPACION"},
{ value: "K591",	text: "DIARREA FUNCIONAL"},
{ value: "K592",	text: "INTESTINO NEUROGENICO, NO CLASIFICADO EN OTRA PARTE"},
{ value: "K593",	text: "MEGACOLON, NO CLASIFICADO EN OTRA PARTE"},
{ value: "K594",	text: "ESPASMO ANAL"},
{ value: "K598",	text: "OTROS TRASTORNOS FUNCIONALES ESPECIFICADOS DEL INTESTINO"},
{ value: "K599",	text: "TRASTORNO FUNCIONAL INTESTINAL, NO ESPECIFICADO"},
{ value: "K600",	text: "FISURA ANAL AGUDA"},
{ value: "K601",	text: "FISURA ANAL CRONICA"},
{ value: "K602",	text: "FISURA ANAL, NO ESPECIFICADA"},
{ value: "K603",	text: "FISURA ANAL"},
{ value: "K604",	text: "FISTULA RECTAL"},
{ value: "K605",	text: "FISTULA ANORRECTAL"},
{ value: "K610",	text: "ABSCESO ANAL"},
{ value: "K611",	text: "ABSCESO RECTAL"},
{ value: "K612",	text: "ABSCESO ANORRECTAL"},
{ value: "K613",	text: "ABSCESO ISQUIORRECTAL"},
{ value: "K614",	text: "ABSCESO INTRAESFINTERIANO"},
{ value: "K620",	text: "POLIPO ANAL"},
{ value: "K621",	text: "POLIPO RECTAL"},
{ value: "K622",	text: "PROLAPSO ANAL"},
{ value: "K623",	text: "PROLAPSO RECTAL"},
{ value: "K624",	text: "ESTENOSIS DEL ANO Y DEL RECTO"},
{ value: "K625",	text: "HEMORRAGIA DEL ANO Y DEL RECTO"},
{ value: "K626",	text: "ULCERA DEL ANO Y DEL RECTO"},
{ value: "K627",	text: "PROCTITIS POR RADIACION"},
{ value: "K628",	text: "OTRAS ENFERMEDADES ESPECIFICADAS DEL ANO Y DEL RECTO"},
{ value: "K629",	text: "ENFERMEDAD DEL ANO Y DEL RECTO, NO ESPECIFICADA"},
{ value: "K630",	text: "ABSCESO DEL INTESTINO"},
{ value: "K631",	text: "PERFORACION DEL INTESTINO (NO TRAUMATICA)"},
{ value: "K632",	text: "FISTULA DEL INTESTINO"},
{ value: "K633",	text: "ULCERA DEL INTESTINO"},
{ value: "K634",	text: "ENTEROPTOSIS"},
{ value: "K635",	text: "POLIPO DEL COLON"},
{ value: "K638",	text: "OTRAS ENFERMEDADES ESPECIFICADAS DEL INTESTINO"},
{ value: "K639",	text: "ENFERMEDAD DEL INTESTINO, NO ESPECIFICADA"},
{ value: "K650",	text: "PERITONITIS AGUDA"},
{ value: "K658",	text: "OTRAS PERITONITIS"},
{ value: "K659",	text: "PERITONITIS, NO ESPECIFICADA"},
{ value: "K660",	text: "ADHERENCIAS PERITONEALES"},
{ value: "K661",	text: "HEMOPERITONEO"},
{ value: "K668",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL PERITONEO"},
{ value: "K669",	text: "TRASTORNO DEL PERITONEO, NO ESPECIFICADO"},
{ value: "K670",	text: "PERITONITIS POR CLAMIDIAS (A74.8†)"},
{ value: "K671",	text: "PERITONITIS GONOCOCICA (A54.8†)"},
{ value: "K672",	text: "PERITONITIS SIFILITICA (A52.7†)"},
{ value: "K673",	text: "PERITONITIS TUBERCULOSA (A18.3†)"},
{ value: "K678",	text: "OTROS TRASTORNOS DEL PERITONEO EN ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE"},
{ value: "K700",	text: "HIGADO ALCOHOLICO ADIPOSO"},
{ value: "K701",	text: "HEPATITIS ALCOHOLICA"},
{ value: "K702",	text: "FIBROSIS Y ESCLEROSIS DEL HIGADO, ALCOHOLICA"},
{ value: "K703",	text: "CIRROSIS HEPATICA ALCOHOLICA"},
{ value: "K704",	text: "INSUFICIENCIA HEPATICA ALCOHOLICA"},
{ value: "K709",	text: "ENFERMEDAD HEPATICA ALCOHOLICA, NO ESPECIFICADA"},
{ value: "K710",	text: "ENFERMEDAD TOXICA DEL HIGADO, CON COLESTASIS"},
{ value: "K711",	text: "ENFERMEDAD TOXICA DEL HIGADO, CON NECROSIS HEPATICA"},
{ value: "K712",	text: "ENFERMEDAD TOXICA DEL HIGADO, CON HEPATITIS AGUDA"},
{ value: "K713",	text: "ENFERMEDAD TOXICA DEL HIGADO, CON HEPATITIS CRONICA PERSISTENTE"},
{ value: "K714",	text: "ENFERMEDAD TOXICA DEL HIGADO, CON HEPATITIS CRONICA LOBULAR"},
{ value: "K715",	text: "ENFERMEDAD TOXICA DEL HIGADO, CON HEPATITIS CRONICA ACTIVA"},
{ value: "K716",	text: "ENFERMEDAD TOXICA DEL HIGADO, CON HEPATITIS NO CLASIFICADA EN OTRA PARTE"},
{ value: "K717",	text: "ENFERMEDAD TOXICA DEL HIGADO, CON CIRROSIS Y FIBROSIS DEL HIGADO"},
{ value: "K718",	text: "ENFERMEDAD TOXICA DEL HIGADO, CON OTROS TRASTORNOS HEPATICOS"},
{ value: "K719",	text: "ENFERMEDAD TOXICA DEL HIGADO, NO ESPECIFICADA"},
{ value: "K720",	text: "INSUFICIENCIA HEPATICA AGUDA O SUBAGUDA"},
{ value: "K721",	text: "INSUFICIENCIA HEPATICA CRONICA"},
{ value: "K729",	text: "INSUFICIENCIA HEPATICA, NO ESPECIFICADA"},
{ value: "K730",	text: "HEPATITIS CRONICA PERSISTENTE, NO CLASIFICADA EN OTRA PARTE"},
{ value: "K731",	text: "HEPATITIS CRONICA LOBULAR, NO CLASIFICADA EN OTRA PARTE"},
{ value: "K732",	text: "HEPATITIS CRONICA ACTIVA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "K738",	text: "OTRAS HEPATITIS CRONICAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "K739",	text: "HEPATITIS CRONICA, NO ESPECIFICADA"},
{ value: "K740",	text: "FIBROSIS HEPATICA"},
{ value: "K741",	text: "ESCLEROSIS HEPATICA"},
{ value: "K742",	text: "FIBROSIS HEPATICA CON ESCLEROSIS HEPATICA"},
{ value: "K743",	text: "CIRROSIS BILIAR PRIMARIA"},
{ value: "K744",	text: "CIRROSIS BILIAR SECUNDARIA"},
{ value: "K745",	text: "CIRROSIS BILIAR, NO ESPECIFICADA"},
{ value: "K746",	text: "OTRAS CIRROSIS DEL HIGADO Y LA NO ESPECIFICADAS"},
{ value: "K750",	text: "ABSCESO DEL HIGADO"},
{ value: "K751",	text: "FLEBITIS DE LA VENA PORTA"},
{ value: "K752",	text: "HEPATITIS REACTIVA NO ESPECIFICA"},
{ value: "K753",	text: "HEPATITIS GRANULOMATOSA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "K754",	text: "HEPATITIS AUTOINMUNE"},
{ value: "K758",	text: "OTRAS ENFERMEDADES INFLAMATORIAS DEL HIGADO, ESPECIFICADAS"},
{ value: "K759",	text: "ENFERMEDAD INFLAMATORIA DEL HIGADO, NO ESPECIFICADA"},
{ value: "K760",	text: "DEGENERACION GRASA DEL HIGADO, NO CLASIFICADA EN OTRA PARTE"},
{ value: "K761",	text: "CONGESTION PASIVA CRONICA DEL HIGADO"},
{ value: "K762",	text: "NECROSIS HEMORRAGICA CENTRAL DEL HIGADO"},
{ value: "K763",	text: "INFARTO DEL HIGADO"},
{ value: "K764",	text: "PELIOSIS HEPATICA"},
{ value: "K765",	text: "ENFERMEDAD VENO-OCLUSIVA DEL HIGADO"},
{ value: "K766",	text: "HIPERTENSION PORTAL"},
{ value: "K767",	text: "SINDROME HEPATORRENAL"},
{ value: "K768",	text: "OTRAS ENFERMEDADES ESPECIFICAS DEL HIGADO"},
{ value: "K769",	text: "ENFERMEDAD DEL HIGADO, NO ESPECIFICADA"},
{ value: "K770",	text: "TRASTORNOS DEL HIGADO EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "K778",	text: "TRASTORNO DEL HIGADO EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "K800",	text: "CALCULO DE LA VESICULA BILIAR CON COLECISTITIS AGUDA"},
{ value: "K801",	text: "CALCULO DE LA VESICULA BILIAR CON OTRA COLECISTITIS"},
{ value: "K802",	text: "CALCULO DE LA VESICULA BILIAR SIN COLECISTITIS"},
{ value: "K803",	text: "CALCULO DE CONDUCTO BILIAR CON COLANGITIS"},
{ value: "K804",	text: "CALCULO DE CONDUCTO BILIAR CON COLECISTITIS"},
{ value: "K805",	text: "CALCULO DE CONDUCTO BILIAR SIN COLANGITIS NI COLECISTITIS"},
{ value: "K808",	text: "OTRAS COLELITIASIS"},
{ value: "K810",	text: "COLECISTITIS AGUDA"},
{ value: "K811",	text: "COLECISTITIS CRONICA"},
{ value: "K818",	text: "OTRAS COLECISTITIS"},
{ value: "K819",	text: "COLECISTITIS, NO ESPECIFICADA"},
{ value: "K820",	text: "OBSTRUCCION DE LA VESICULA BILIAR"},
{ value: "K821",	text: "HIDROPESIA DE LA VESICULA BILIAR"},
{ value: "K822",	text: "PERFORACION DE LA VESICULA BILIAR"},
{ value: "K823",	text: "FISTULA DE LA VESICULA BILIAR"},
{ value: "K824",	text: "COLESTEROLOSIS DE LA VESICULA BILIAR"},
{ value: "K828",	text: "OTRAS ENFERMEDADES ESPECIFICADAS DE LA VESICULA BILIAR"},
{ value: "K829",	text: "ENFERMEDAD DE LA VESICULA BILIAR, NO ESPECIFICADA"},
{ value: "K830",	text: "COLANGITIS"},
{ value: "K831",	text: "OBSTRUCCION DEL CONDUCTO BILIAR"},
{ value: "K832",	text: "PERFORACION DEL CONDUCTO BILIAR"},
{ value: "K833",	text: "FISTULA DEL CONDUCTO BILIAR"},
{ value: "K834",	text: "ESPASMO DEL ESFINTER DE ODDI"},
{ value: "K835",	text: "QUISTE BILIAR"},
{ value: "K838",	text: "OTRAS ENFERMEDADES ESPECIALIZADAS DE LAS VIAS BILIARES"},
{ value: "K839",	text: "ENFERMEDAD DE LAS VIAS BILIARES, NO ESPECIFICADA"},
{ value: "K85X",	text: "PANCREATITIS AGUDA"},
{ value: "K860",	text: "PANCREATITIS CRONICA INDUCIDA POR EL ALCOHOL"},
{ value: "K861",	text: "OTRAS PANCREATITIS CRONICAS"},
{ value: "K862",	text: "QUISTE DEL PANCREAS"},
{ value: "K863",	text: "SEUDOQUISTE DEL PANCREAS"},
{ value: "K868",	text: "OTRAS ENFERMEDADES ESPECIFICADAS DEL PANCREAS"},
{ value: "K869",	text: "ENFERMEDAD DEL PANCREAS, NO ESPECIFICADA"},
{ value: "K870",	text: "TRASTORNOS DE LA VESICULA BILIAR Y DE LAS VIAS BILIARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "K871",	text: "TRASTORNOS DEL PANCREAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "K900",	text: "ENFERMEDAD CELIACA"},
{ value: "K901",	text: "ESPRUE TROPICAL"},
{ value: "K902",	text: "SINDROME DEL ASA CIEGA, NO CLASIFICADO EN OTRA PARTE"},
{ value: "K903",	text: "ESTEATORREA PANCREATICA"},
{ value: "K904",	text: "MALABSORCION DEBIDA A INTOLERANCIA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "K908",	text: "OTROS TIPOS DE MALABSORCION INTESTINAL"},
{ value: "K909",	text: "MALABSORCION INTESTINAL, NO ESPECIFICADA"},
{ value: "K910",	text: "VOMITO POSTCIRUGIA GASTROINTESTINAL"},
{ value: "K911",	text: "SINDROMES CONSECUTIVOS A LA CIRUGIA GASTRICA"},
{ value: "K912",	text: "MALABSORCION POSTQUIRURGICA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "K913",	text: "OBSTRUCION INTESTINAL POSTOPERATORIA"},
{ value: "K914",	text: "DISFUNCION DE COLOSTOMIA O ENTEROSTOMIA"},
{ value: "K915",	text: "SINDROME POSTCOLECISTECTOMIA"},
{ value: "K918",	text: "OTROS TRASTORNOS DEL SISTEMA DIGESTIVO CONSECUTIVOS A PROCEDIMIENTOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "K919",	text: "TRASTORNO NO ESPECIFICADO AL SISTEMA DIGESTIVO CONSECUTIVO A PROCEDIMIENTOS"},
{ value: "K920",	text: "HEMATEMESIS"},
{ value: "K921",	text: "MELENA"},
{ value: "K922",	text: "HEMORRAGIA GASTROINTESTINAL, NO ESPECIFICADA"},
{ value: "K928",	text: "OTRAS ENFERMEDADES ESPECIFICADAS DEL SISTEMA DIGESTIVO"},
{ value: "K929",	text: "ENFERMEDAD DEL SISTEMA DIGESTIVO, NO ESPECIFICADA"},
{ value: "K930",	text: "TRASTORNOS TUBERCULOSOS DEL INTESTINO, PERITONEO Y GANGLIOS MESENTERICOS (A18.3†)"},
{ value: "K931",	text: "MEGACOLON EN LA ENFERMEDAD DE CHAGAS (B57.3†)"},
{ value: "K938",	text: "TRASTORNOS DE OTROS ORGANOS DIGESTIVOS ESPECIFICADOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "L00X",	text: "SINDROME ESTAFILOCOCICO DE LA PIEL ESCALDADA"},
{ value: "L010",	text: "IMPETIGO [CUALQUIER SITIO ANATOMICO] [CUALQUIER ORGANISMO]"},
{ value: "L011",	text: "IMPETIGINIZACION DE OTRAS DERMATOSIS"},
{ value: "L020",	text: "ABSCESO CUTANEO, FURUNCULO Y ANTRAX DE LA CARA"},
{ value: "L021",	text: "ABSCESO CUTANEO, FURUNCULO Y ANTRAX DE LA CUELLO"},
{ value: "L022",	text: "ABSCESO CUTANEO, FURUNCULO Y ANTRAX DEL TRONCO"},
{ value: "L023",	text: "ABSCESO CUTANEO, FURUNCULO Y ANTRAX DE GLUTEOS"},
{ value: "L024",	text: "ABSCESO CUTANEO, FURUNCULO Y ANTRAX DE MIEMBRO"},
{ value: "L028",	text: "ABSCESO CUTANEO, FURUNCULO Y ANTRAX DE OTROS SITIOS"},
{ value: "L029",	text: "ABSCESO CUTANEO, FURUNCULO Y ANTRAX DE SITIO NO ESPECIFICADO"},
{ value: "L030",	text: "CELULITIS DE LOS DEDOS DE LA MANO Y DEL PIE"},
{ value: "L031",	text: "CELULITIS DE OTRAS PARTES DE LOS MIEMBROS"},
{ value: "L032",	text: "CELULITIS DE LA CARA"},
{ value: "L033",	text: "CELULITIS DEL TRONCO"},
{ value: "L038",	text: "CELULITIS DE OTROS SITIOS"},
{ value: "L039",	text: "CELULITIS DE SITIO NO ESPECIFICADO"},
{ value: "L040",	text: "LINFADENITIS AGUDA DE CARA, CABEZA Y CUELLO"},
{ value: "L041",	text: "LINFADENITIS AGUDA DEL TRONCO"},
{ value: "L042",	text: "LINFADENITIS AGUDA DEL MIEMBRO SUPERIOR"},
{ value: "L043",	text: "LINFADENITIS AGUDA DEL MIEMBRO INFERIOR"},
{ value: "L048",	text: "LINFADENITIS AGUDA DE OTROS SITIOS"},
{ value: "L049",	text: "LINFADENITIS AGUDA DE SITIO NO ESPECIFICADO"},
{ value: "L050",	text: "QUISTE PILONIDAL CON ABSCESO"},
{ value: "L059",	text: "QUISTE PILONIDAL SIN ABSCESO"},
{ value: "L080",	text: "PIODERMA"},
{ value: "L081",	text: "ERITRASMA"},
{ value: "L088",	text: "OTRAS INFECCIONES LOCALES ESPECIFICADAS DE LA PIEL Y DEL TEJIDO SUBCUTANEO"},
{ value: "L089",	text: "INFECCION LOCAL DE LA PIEL Y DEL TEJIDO SUBCUTANEO, NO ESPECIFICADA"},
{ value: "L100",	text: "PENFIGO VULGAR"},
{ value: "L101",	text: "PENFIGO VEGETANTE"},
{ value: "L102",	text: "PENFIGO FOLIACEO"},
{ value: "L103",	text: "PENFIGO BRASILEÑO [FOGO SELVAGEM]"},
{ value: "L104",	text: "PENFIGO ERITEMATOSO"},
{ value: "L105",	text: "PENFIGO INDUCIDO POR DROGAS"},
{ value: "L108",	text: "OTROS PENFIGOS"},
{ value: "L109",	text: "PENFIGO, NO ESPECIFICADO"},
{ value: "L110",	text: "QUERATOSIS FOLICULAR ADQUIRIDA"},
{ value: "L111",	text: "DERMATOSIS ACANTOLITICA TRANSITORIA [GROVER]"},
{ value: "L118",	text: "OTROS TRASTORNOS ACANTOLITICOS ESPECIFICADOS"},
{ value: "L119",	text: "TRASTORNO ACANTOLITICO, NO ESPECIFICADO"},
{ value: "L120",	text: "PENFIGOIDE FLICTENULAR"},
{ value: "L121",	text: "PENFIGOIDE CICATRICIAL"},
{ value: "L122",	text: "ENFERMEDAD FLICTENULAR CRONICA DE LA INFANCIA"},
{ value: "L123",	text: "EPIDERMOLISIS BULLOSA ADQUIRIDA"},
{ value: "L128",	text: "OTROS PENFIGOIDES"},
{ value: "L129",	text: "PENFIGOIDE, NO ESPECIFICADO"},
{ value: "L130",	text: "DERMATITIS HERPETIFORME"},
{ value: "L131",	text: "DERMATITIS PUSTULOSA SUBCORNEAL"},
{ value: "L138",	text: "OTROS TRASTORNOS FLICTENULARES ESPECIFICADOS"},
{ value: "L139",	text: "TRASTORNO FLICTENULAR, NO ESPECIFICADO"},
{ value: "L14X",	text: "TRASTORNOS FLICTENULARES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "L200",	text: "PRURIGO DE BESNIER"},
{ value: "L208",	text: "OTRAS DERMATITIS ATOPICAS"},
{ value: "L209",	text: "DERMATITIS ATOPICA, NO ESPECIFICADA"},
{ value: "L210",	text: "SEBORREA CAPITIS"},
{ value: "L211",	text: "DERMATITIS SEBORREICA INFANTIL"},
{ value: "L218",	text: "OTRAS DERMATITIS SEBORREICAS"},
{ value: "L219",	text: "DERMATITIS SEBORREICA, NO ESPECIFICADA"},
{ value: "L22X",	text: "DERMATITIS DEL PAÑAL"},
{ value: "L230",	text: "DERMATITIS ALERGICA DE CONTACTO DEBIDA A METALES"},
{ value: "L231",	text: "DERMATITIS ALERGICA DE CONTACTO DEBIDA A ADHESIVOS"},
{ value: "L232",	text: "DERMATITIS ALERGICA DE CONTACTO DEBIDA A COSMETICOS"},
{ value: "L233",	text: "DERMATITIS ALERGICA DE CONTACTO DEBIDA A DROGAS EN CONTACTO CON LA PIEL"},
{ value: "L234",	text: "DERMATITIS ALERGICA DE CONTACTO DEBIDA A COLORANTES"},
{ value: "L235",	text: "DERMATITIS ALERGICA DE CONTACTO DEBIDA A OTROS PRODUCTOS QUIMICOS"},
{ value: "L236",	text: "DERMATITIS ALERGICA DE CONTACTO DEBIDA A ALIMENTOS EN CONTACTO CON LA PIEL"},
{ value: "L237",	text: "DERMATITIS ALERGICA DE CONTACTO DEBIDA A PLANTAS, EXCEPTO LAS ALIMENTICIAS"},
{ value: "L238",	text: "DERMATITIS ALERGICA DE CONTACTO DEBIDA A OTROS AGENTES"},
{ value: "L239",	text: "DERMATITIS ALERGICA DE CONTACTO, DE CAUSA NO ESPECIFICADA"},
{ value: "L240",	text: "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A DETERGENTES"},
{ value: "L241",	text: "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A ACEITES Y GRASAS"},
{ value: "L242",	text: "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A DISOLVENTES"},
{ value: "L243",	text: "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A COSMETICOS"},
{ value: "L244",	text: "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A DROGAS EN CONTACTO CON LA PIEL"},
{ value: "L245",	text: "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A OTROS PRODUCTOS QUIMICOS"},
{ value: "L246",	text: "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A ALIMENTOS EN CONTACTO CON LA PIEL"},
{ value: "L247",	text: "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A PLANTAS, EXCEPTO LAS ALIMENTICIAS"},
{ value: "L248",	text: "DERMATITIS DE CONTACTO POR IRRITANTES, DEBIDA A OTROS AGENTES"},
{ value: "L249",	text: "DERMATITIS DE CONTACTO POR IRRITANTES, DE CAUSA NO ESPECIFICADA"},
{ value: "L250",	text: "DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, DEBIDA A COSMETICOS"},
{ value: "L251",	text: "DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, DEBIDA A DROGAS EN CONTACTO CON LA PIEL"},
{ value: "L252",	text: "DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, DEBIDA A COLORANTES"},
{ value: "L253",	text: "DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, DEBIDA A OTROS PRODUCTOS QUIMICOS"},
{ value: "L254",	text: "DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, DEBIDA A ALIMENTOS EN CONTACTO CON LA PIEL"},
{ value: "L255",	text: "DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, , DEBIDA A PLANTAS, EXCEPTO LAS ALIMENTICIAS"},
{ value: "L258",	text: "DERMATITIS DE CONTACTO, FORMA NO ESPECIFICADA, DEBIDA A OTROS AGENTES"},
{ value: "L259",	text: "DERMATITIS DE CONTACTO, FORMA Y CAUSA NO ESPECIFICADAS"},
{ value: "L26X",	text: "DERMATITIS EXFOLIATIVA"},
{ value: "L270",	text: "ERUPCION CUTANEA GENERALIZADA DEBIDA A DROGAS Y MEDICAMENTOS"},
{ value: "L271",	text: "ERUPCION CUTANEA LOCALIZADA DEBIDA A DROGAS Y MEDICAMENTOS"},
{ value: "L272",	text: "DERMATITIS DEBIDA A INGESTION DE ALIMENTOS"},
{ value: "L278",	text: "DERMATITIS DEBIDA A OTRAS SUSTANCIAS INGERIDAS"},
{ value: "L279",	text: "DERMATITIS DEBIDA A SUSTANCIAS INGERIDAS NO ESPECIFICADAS"},
{ value: "L280",	text: "LIQUEN SIMPLE CRONICO"},
{ value: "L281",	text: "PRURIGO NODULAR"},
{ value: "L282",	text: "OTROS PRURIGOS"},
{ value: "L290",	text: "PRURITO ANAL"},
{ value: "L291",	text: "PRURITO ESCROTAL"},
{ value: "L292",	text: "PRURITO VULVAR"},
{ value: "L293",	text: "PRURITO ANOGENITAL, NO ESPECIFICADO"},
{ value: "L298",	text: "OTROS PRURITOS"},
{ value: "L299",	text: "PRURITO, NO ESPECIFICADO"},
{ value: "L300",	text: "DERMATITIS NUMULAR"},
{ value: "L301",	text: "DISHIDROSIS [PONFOLIX]"},
{ value: "L302",	text: "AUTOSENSIBILIZACION CUTANEA"},
{ value: "L303",	text: "DERMATITIS INFECCIOSA"},
{ value: "L304",	text: "ERITEMA INTERTRIGO"},
{ value: "L305",	text: "PITIRIASIS ALBA"},
{ value: "L308",	text: "OTRAS DERMATITIS ESPECIFICADAS"},
{ value: "L309",	text: "DERMATITIS, NO ESPECIFICADA"},
{ value: "L400",	text: "PSORIASIS VULGAR"},
{ value: "L401",	text: "PSORIASIS PUSTULOSA GENERALIZADA"},
{ value: "L402",	text: "ACRODERMATITIS CONTINUA"},
{ value: "L403",	text: "PUSTULOSIS PALMAR Y PLANTAR"},
{ value: "L404",	text: "PSORIASIS GUTTATA"},
{ value: "L405",	text: "ARTROPATIA PSORIASICA (M07.0*- M07.3*, M09.0*)"},
{ value: "L408",	text: "OTRAS PSORIASIS"},
{ value: "L409",	text: "PSORIASIS, NO ESPECIFICADA"},
{ value: "L410",	text: "PITIRIASIS LINQUENOIDE Y VARIOLIFORME AGUDA"},
{ value: "L411",	text: "PITIRIASIS LINQUENOIDE CRONICA"},
{ value: "L412",	text: "PAPULOSIS LINFOMATOIDE"},
{ value: "L413",	text: "PARAPSORIASIS EN PLACAS PEQUEÑAS"},
{ value: "L414",	text: "PARAPSORIASIS EN PLACAS GRANDES"},
{ value: "L415",	text: "PARAPSORIASIS RETIFORME"},
{ value: "L418",	text: "OTRAS PARAPSORIASIS"},
{ value: "L419",	text: "PARAPSORIASIS, NO ESPECIFICADA"},
{ value: "L42X",	text: "PITIRIASIS ROSADA"},
{ value: "L430",	text: "LIQUEN PLANO HIPERTROFICO"},
{ value: "L431",	text: "LIQUEN PLANO FLICTENULAR"},
{ value: "L432",	text: "REACCION LINQUENOIDE DEBIDA A DROGAS"},
{ value: "L433",	text: "LIQUEN PLANO SUBAGUDO (ACTIVO)"},
{ value: "L438",	text: "OTROS LIQUENES PLANOS"},
{ value: "L439",	text: "LIQUEN PLANO, NO ESPECIFICADO"},
{ value: "L440",	text: "PITIRIASIS RUBRA PILARIS"},
{ value: "L441",	text: "LIQUEN NITIDO"},
{ value: "L442",	text: "LIQUEN ESTRIADO"},
{ value: "L443",	text: "LIQUEN ROJO MONILIFORME"},
{ value: "L444",	text: "ACRODERMATITIS PAPULAR INFANTIL [GIANNOTTI-CROSTI]"},
{ value: "L448",	text: "OTROS TRASTORNOS PALPULOESCAMOSOS ESPECIFICADOS"},
{ value: "L449",	text: "TRASTORNO PAPULOESCAMOSO, NO ESPECIFICADO"},
{ value: "L45X",	text: "TRASTORNOS PAPULOESCAMOSOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "L500",	text: "URTICARIA ALERGICA"},
{ value: "L501",	text: "URTICARIA IDIOPATICA"},
{ value: "L502",	text: "URTICARIA DEBIDA AL CALOR Y AL FRIO"},
{ value: "L503",	text: "URTICARIA DERMATOGRAFICA"},
{ value: "L504",	text: "URTICARIA VIBRATORIA"},
{ value: "L505",	text: "URTICARIA COLINERGICA"},
{ value: "L506",	text: "URTICARIA POR CONTACTO"},
{ value: "L508",	text: "OTRAS URTICARIAS"},
{ value: "L509",	text: "URTICARIA, NO ESPECIFICADA"},
{ value: "L510",	text: "ERITEMA MULTIFORME NO FLICTENULAR"},
{ value: "L511",	text: "ERITEMA MULTIFORME FLICTENULAR"},
{ value: "L512",	text: "NECROLISIS EPIDERMICA TOXICA [LYELL]"},
{ value: "L518",	text: "OTROS ERITEMAS MULTIFORMES"},
{ value: "L519",	text: "ERITEMA MULTIFORME, NO ESPECIFICADO"},
{ value: "L52X",	text: "ERITEMA NUDOSO"},
{ value: "L530",	text: "ERITEMA TOXICO"},
{ value: "L531",	text: "ERITEMA ANULAR CENTRIFUGO"},
{ value: "L532",	text: "ERITEMA MARGINADO"},
{ value: "L533",	text: "OTROS ERITEMAS FIGURADOS CRONICOS"},
{ value: "L538",	text: "OTRAS AFECCIONES ERITEMATOSAS ESPECIFICADAS"},
{ value: "L539",	text: "AFECCION ERITEMATOSA, NO ESPECIFICADA"},
{ value: "L540",	text: "ERITEMA MARGINADO EN LA FIEBRE REUMATICA AGUDA (I00†)"},
{ value: "L548",	text: "ERITEMA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "L550",	text: "QUEMADURA SOLAR PRIMER GRADO"},
{ value: "L551",	text: "QUEMADURA SOLAR SEGUNDO GRADO"},
{ value: "L552",	text: "QUEMADURA SOLAR TERCER GRADO"},
{ value: "L558",	text: "OTRAS QUEMADURAS SOLARES"},
{ value: "L559",	text: "QUEMADURA SOLAR, NO ESPECIFICADA"},
{ value: "L560",	text: "RESPUESTA FOTOTOXICA A DROGAS"},
{ value: "L561",	text: "RESPUESTA FOTOALERGICA A DROGAS"},
{ value: "L562",	text: "DERMATITIS POR FOTOCONTACTO [DERMATITIS DE BERLOQUE]"},
{ value: "L563",	text: "URTICARIA SOLAR"},
{ value: "L564",	text: "ERUPCION POLIMORFA A LA LUZ"},
{ value: "L568",	text: "OTROS CAMBIOS AGUDOS ESPECIFICADOS DE LA PIEL DEBIDOS A RADIACION ULTRAVIOLETA"},
{ value: "L569",	text: "CAMBIO AGUDO DE LA PIEL DEBIDO A RADIACION ULTRAVIOLETA, SIN OTRA ESPECIFICACION"},
{ value: "L570",	text: "QUERATOSIS ACTINICA"},
{ value: "L571",	text: "RETICULOIDE ACTINICO"},
{ value: "L572",	text: "PIEL ROMBOIDAL DE LA NUCA"},
{ value: "L573",	text: "POIQUILODERMIA DE CIVATTE"},
{ value: "L574",	text: "PIEL LAXA SENIL"},
{ value: "L575",	text: "GRANULOMA ACTINICO"},
{ value: "L578",	text: "OTROS CAMBIOS DE LA PIEL DEBIDOS A EXPOSICION CRONICA A RADIACION NO IONIZANTE"},
{ value: "L579",	text: "CAMBIOS DE LA PIEL DEBIDOS A EXPOSICION CRONICA A RADIACION NO IONIZANTE, SIN OTRA ESPECIFICACION"},
{ value: "L580",	text: "RADIODERMATITIS AGUDA"},
{ value: "L581",	text: "RADIODERMATITIS CRONICA"},
{ value: "L589",	text: "RADIODERMATITIS, NO ESPECIFICADA"},
{ value: "L590",	text: "ERITEMA AB IGNE [DERMATITIS AB IGNE]"},
{ value: "L598",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA PIEL Y DEL TEJIDO SUBCUTANEO RELACIONADOS CON RADIACION"},
{ value: "L599",	text: "TRASTORNOS NO ESPECIFICADOS DE LA PIEL Y DEL TEJIDO SUBCUTANEO RELACIONADOS CON RADIACION"},
{ value: "L600",	text: "UÑA ENCARNADA"},
{ value: "L601",	text: "ONICOLISIS"},
{ value: "L602",	text: "ONICOGRIPOSIS"},
{ value: "L603",	text: "DISTROFIA UNGUEAL"},
{ value: "L604",	text: "LINEAS DE BEAU"},
{ value: "L605",	text: "SINDROME DE LA UÑA AMARILLA"},
{ value: "L608",	text: "OTROS TRASTORNOS DE LAS UÑAS"},
{ value: "L609",	text: "TRASTORNO DE LA UÑA, NO ESPECIFICADO"},
{ value: "L620",	text: "UÑA DEFORME DE LA PAQUIDERMOPERIOSTOSIS (M89.4†)"},
{ value: "L628",	text: "TRASTORNOS DE LAS UÑAS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "L630",	text: "ALOPECIA (CAPITIS) TOTAL"},
{ value: "L631",	text: "ALOPECIA UNIVERSAL"},
{ value: "L632",	text: "OFIASIS"},
{ value: "L638",	text: "OTRAS ALOPECIAS AREATAS"},
{ value: "L639",	text: "ALOPECIA AREATA, NO ESPECIFICADA"},
{ value: "L640",	text: "ALOPECIA ANDROGENA, INDUCIDA POR DROGAS"},
{ value: "L648",	text: "OTRAS ALOPECIAS ANDROGENAS"},
{ value: "L649",	text: "ALOPECIA ANDROGENA, NO ESPECIFICADA"},
{ value: "L650",	text: "PERDIDA CAPILAR TELOGENA"},
{ value: "L651",	text: "PERDIDA CAPILAR ANAGENA"},
{ value: "L652",	text: "ALOPECIA MUCINOSA"},
{ value: "L658",	text: "OTRAS PERDIDAS ESPECIFICADAS NO CICATRICIALES DEL PELO"},
{ value: "L659",	text: "PERDIDA NO CICATRICIAL DEL PELO, SIN OTRA ESPECIFICACION"},
{ value: "L660",	text: "SEUDOPELADA"},
{ value: "L661",	text: "LIQUEN PLANO PILARIS"},
{ value: "L662",	text: "FOLICULITIS DECALVANTE"},
{ value: "L663",	text: "PERIFOLICULITIS CAPITIS ABSCEDENS"},
{ value: "L664",	text: "FOLICULITIS ULERITEMATOSA RETICULADA"},
{ value: "L668",	text: "OTRAS ALOPECIAS CICATRICIALES"},
{ value: "L669",	text: "ALOPECIA CICATRICIAL, NO ESPECIFICADA"},
{ value: "L670",	text: "TRICORREXIS NUDOSA"},
{ value: "L671",	text: "VARIACION DEL COLOR DEL PELO"},
{ value: "L678",	text: "OTRAS ANOMALIAS DEL TALLO Y DEL COLOR DEL PELO"},
{ value: "L679",	text: "ANORMALIDAD NO ESPECIFICADA DEL TALLO Y DEL COLOR DEL PELO"},
{ value: "L680",	text: "HIRSUTISMO"},
{ value: "L681",	text: "HIPERTRICOSIS LANUGINOSA ADQUIRIDA"},
{ value: "L682",	text: "HIPERTRICOSIS LOCALIZADA"},
{ value: "L683",	text: "POLITRIQUIA"},
{ value: "L688",	text: "OTRAS HIPERTRICOSIS"},
{ value: "L689",	text: "HIPERTRICOSIS , NO ESPECIFICADA"},
{ value: "L700",	text: "ACNE VULGAR"},
{ value: "L701",	text: "ACNE CONGLOBADO"},
{ value: "L702",	text: "ACNE VARIOLIFORME"},
{ value: "L703",	text: "ACNE TROPICAL"},
{ value: "L704",	text: "ACNE INFANTIL"},
{ value: "L705",	text: "ACNE EXCORIADO DE LA MUJER JOVEN"},
{ value: "L708",	text: "OTROS ACNES"},
{ value: "L709",	text: "ACNE, NO ESPECIFICADO"},
{ value: "L710",	text: "DERMATITIS PERIBUCAL"},
{ value: "L711",	text: "RINOFIMA"},
{ value: "L718",	text: "OTRAS ROSACEAS"},
{ value: "L719",	text: "ROSACEA, NO ESPECIFICADA"},
{ value: "L720",	text: "QUISTE EPIDERMICO"},
{ value: "L721",	text: "QUISTE TRICODERMICO"},
{ value: "L722",	text: "ESTEATOCISTOMA MULTIPLE"},
{ value: "L728",	text: "OTROS QUISTES FOLICULARES DE LA PIEL Y DEL TEJIDO SUBCUTANEO"},
{ value: "L729",	text: "QUISTE FOLICULAR DE LA PIEL Y DEL TEJIDO SUBCUTANEO, SIN OTRA ESPECIFICACION"},
{ value: "L730",	text: "ACNE QUELOIDE"},
{ value: "L731",	text: "SEUDOFOLICULITIS DE LA BARBA"},
{ value: "L732",	text: "HIDRADENITIS SUPURATIVA"},
{ value: "L738",	text: "OTROS TRASTORNOS FOLICULARES ESPECIFICADOS"},
{ value: "L739",	text: "TRASTORNO FOLICULAR, NO ESPECIFICADO"},
{ value: "L740",	text: "MILIARIA RUBRA"},
{ value: "L741",	text: "MILIARIA CRISTALINA"},
{ value: "L742",	text: "MILIARIA PROFUNDA"},
{ value: "L743",	text: "MILIARIA , NO ESPECIFICADA"},
{ value: "L744",	text: "ANHIDROSIS"},
{ value: "L748",	text: "OTROS TRASTORNOS SUDORIPADOS ECRINOS"},
{ value: "L749",	text: "TRASTORNO SUDORIPARO ECRINO, NO ESPECIFICADO"},
{ value: "L750",	text: "BROMHIDROSIS"},
{ value: "L751",	text: "CROMHIDROSIS"},
{ value: "L752",	text: "MILIARIA APOCRINA"},
{ value: "L758",	text: "OTROS TRASTORNOS SUDORIPARO APOCRINO"},
{ value: "L759",	text: "TRASTORNO SUDORIPARO APOCRINO, NO ESPECIFICADO"},
{ value: "L80X",	text: "VITILIGO"},
{ value: "L810",	text: "HIPERPIGMENTACION POSTINFLAMATORIA"},
{ value: "L811",	text: "CLOASMA"},
{ value: "L812",	text: "EFELIDE"},
{ value: "L813",	text: "MANCHAS CAFÉ CON LECHE"},
{ value: "L814",	text: "OTROS TIPOS DE HIPERPIGMENTACION MELANODERMICA"},
{ value: "L815",	text: "LEUCODERMIA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "L816",	text: "OTROS TRASTORNOS DE DISMINUCION DE LA FORMACION DE LA MELANINA"},
{ value: "L817",	text: "DERMATOSIS PURPURICA PIGMENTADA"},
{ value: "L818",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA PIGMENTACION"},
{ value: "L819",	text: "TRASTORNO DE LA PIGMENTACION, NO ESPECIFICADO"},
{ value: "L82X",	text: "QUERATOSIS SEBORREICA"},
{ value: "L83X",	text: "ACANTOSIS NIGRICANS"},
{ value: "L84X",	text: "CALLOS Y CALLOSIDADES"},
{ value: "L850",	text: "ICTIOSIS ADQUIRIDA"},
{ value: "L851",	text: "QUERATOSIS [QUERATODERMIA] PALMAR Y PLANTAR ADQUIRIDA"},
{ value: "L852",	text: "QUERATOSIS PUNCTATA (PALMAR Y PLANTAR)"},
{ value: "L853",	text: "XEROSIS DEL CUTIS"},
{ value: "L858",	text: "OTROS ENGROSAMIENTOS EPIDERMICOS ESPECIFICADOS"},
{ value: "L859",	text: "ENGROSAMIENTO EPIDERMICO, NO ESPECIFICADO"},
{ value: "L86X",	text: "QUERODERMA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "L870",	text: "QUERATOSIS FOLICULAR Y PARAFOLICULAR PENETRANTE DEL CUTIS [KYRLE]"},
{ value: "L871",	text: "COLAGENOSIS PERFORANTE REACTIVA"},
{ value: "L872",	text: "ELASTOSIS SERPIGINOSA PERFORANTE"},
{ value: "L878",	text: "OTROS TRASTORNOS DE LA ELIMINACION TRANSEPIDERMICA"},
{ value: "L879",	text: "TRASTORNO DE LA ELIMINACION TRANSEPIDERMICA. NO ESPECIFICADO"},
{ value: "L88X",	text: "PIODERMA GANGRENOSO"},
{ value: "L89X",	text: "ULCERA DE DECUBITO"},
{ value: "L900",	text: "LIQUEN ESCLEROSO Y ATROFICO"},
{ value: "L901",	text: "ANETODERMIA DE SCHWENINGER-BUZZI"},
{ value: "L902",	text: "ANETODERMIA DE JADASSOHN- PELLIZZARI"},
{ value: "L903",	text: "ATROFODERMA DE PASINI Y PIERINI"},
{ value: "L904",	text: "ACRODERMATITIS CRONICA ATROFICA"},
{ value: "L905",	text: "FIBROSIS Y AFECCIONES CICATRICIALES DE LA PIEL"},
{ value: "L906",	text: "ESTRIAS ATROFICAS"},
{ value: "L908",	text: "OTROS TRASTORNOS ATROFICOS DE LA PIEL"},
{ value: "L909",	text: "TRASTORNO ATROFICO DE LA PIEL, NO ESPECIFICADO"},
{ value: "L910",	text: "CICATRIZ QUELOIDE"},
{ value: "L918",	text: "OTROS TRASTORNOS HIPERTROFICOS DE LA PIEL"},
{ value: "L919",	text: "TRASTORNO HIPERTROFICO DE LA PIEL, NO ESPECIFICADO"},
{ value: "L920",	text: "GRANULOMA ANULAR"},
{ value: "L921",	text: "NECROBIOSIS LIPIDICA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "L922",	text: "GRANULOMA FACIAL [GRANULOMA EOSINOFILO DE LA PIEL]"},
{ value: "L923",	text: "GRANULOMA POR CUERPO EXTRAÑO DE LA PIEL Y EN EL TEJIDO SUBCUTANEO"},
{ value: "L928",	text: "OTROS TRASTORNOS GRANULOMATOSOS DE LA PIEL Y DEL TEJIDO SUBCUTANEO"},
{ value: "L929",	text: "TRASTORNO GRANULOMATOSO DE LA PIEL Y DEL TEJIDO SUBCUTANEO, NO ESPECIFICADO"},
{ value: "L930",	text: "LUPUS ERITEMATOSO DISCOIDE"},
{ value: "L931",	text: "LUPUS ERITEMATOSO CUTANEO SUBAGUDO"},
{ value: "L932",	text: "OTROS LUPUS ERITEMATOSOS LOCALIZADOS"},
{ value: "L940",	text: "ESCLERODERMA LOCALIZADO [MORFEA]"},
{ value: "L941",	text: "ESCLERODERMA LINEAL"},
{ value: "L942",	text: "CALCINOSIS DE LA PIEL"},
{ value: "L943",	text: "ESCLERODACTILIA"},
{ value: "L944",	text: "PAPULAS DE GOTTRON"},
{ value: "L945",	text: "POIQUILODERMIA VASCULAR ATROFICA"},
{ value: "L946",	text: "AINHUM"},
{ value: "L948",	text: "OTROS TRASTORNOS LOCALIZADOS ESPECIFICADOS DEL TEJIDO CONJUNTIVO"},
{ value: "L949",	text: "TRASTORNO LOCALIZADO DEL TEJIDO CONJUNTIVO, NO ESPECIFICADO"},
{ value: "L950",	text: "VASCULITIS LIVEDOIDE"},
{ value: "L951",	text: "ERITEMA ELEVATUM DIUTINUM"},
{ value: "L958",	text: "OTRAS VASCULITIS LIMITADAS DE LA PIEL"},
{ value: "L959",	text: "VASCULITIS LIMITADA A LA PIEL, SIN OTRA ESPECIFICACION"},
{ value: "L97X",	text: "ULCERA DEL MIEMBRO INFERIOR, NO CLASIFICADA EN OTRA PARTE"},
{ value: "L980",	text: "GRANULOMA PIOGENO"},
{ value: "L981",	text: "DERMATITIS FACTICIA"},
{ value: "L982",	text: "DERMATOSIS NEUTROFILA FEBRIL [SWEET]"},
{ value: "L983",	text: "CELULITIS EOSINOFILA [WELLS]"},
{ value: "L984",	text: "ULCERA CRONICA DE LA PIEL, NO CLASIFICADA EN OTRA PARTE"},
{ value: "L985",	text: "MUCINOSIS DE LA PIEL"},
{ value: "L986",	text: "OTROS TRASTORNOS INFILTRATIVOS DE LA PIEL Y DEL TEJIDO SUBCUTÁNEO"},
{ value: "L988",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA PIEL Y DEL TEJIDO SUBCUTANEO"},
{ value: "L989",	text: "TRASTORNO DE LA PIEL Y DEL TEJIDO SUBCUTANEO, NO ESPECIFICADO"},
{ value: "L990",	text: "AMILOIDOSIS DE LA PIEL (E85.-†)"},
{ value: "L998",	text: "OTROS TRASTORNOS DE LA PIEL Y DEL TEJIDO SUBCUTANEO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "M000",	text: "ARTRITIS Y POLIARTRITIS ESTAFILOCOCICA"},
{ value: "M001",	text: "ARTRITIS Y POLIARTRITIS NEUMOCOCICA"},
{ value: "M002",	text: "ARTRITIS Y POLIARTRITIS ESTREPTOCOCICAS"},
{ value: "M008",	text: "ARTRITIS Y POLIARTRITIS DEBIDAS A OTROS AGENTES BACTERIANOS ESPECIFICADOS"},
{ value: "M009",	text: "ARTRITIS PIOGENA, NO ESPECIFICADA"},
{ value: "M010",	text: "ARTRITIS MENINGOCOCICA (A39.8†)"},
{ value: "M011",	text: "TUBERCULOSA (A18.0†)"},
{ value: "M012",	text: "ARTRITIS EN LA ENFERMEDAD DE LYME (A69.2†)"},
{ value: "M013",	text: "ARTRITIS EN OTRAS ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"},
{ value: "M014",	text: "ARTRITIS EN RUBEOLA (B06.8†)"},
{ value: "M015",	text: "ARTRITIS EN OTRAS ENFERMEDADES VIRALES CLASIFICADAS EN OTRA PARTE"},
{ value: "M016",	text: "ARTRITIS EN MICOSIS (B35-B49†)"},
{ value: "M018",	text: "ARTRITIS EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "M020",	text: "ARTROPATIA CONSECUTIVA A DERIVACION INTESTINAL"},
{ value: "M021",	text: "ARTROPATIA POSTDISENTERICA"},
{ value: "M022",	text: "ARTROPATIA POSTINMUNIZACION"},
{ value: "M023",	text: "ENFERMEDAD DE REITER"},
{ value: "M028",	text: "OTRAS ARTROPATIA REACTIVAS"},
{ value: "M029",	text: "ARTROPATIA REACTIVA, NO ESPECIFICADA"},
{ value: "M030",	text: "ARTRITIS POSTMENINGOCOCICA (A39.8†)"},
{ value: "M031",	text: "ARTROPATIA POSTINFECCIOSA EN SIFILIS"},
{ value: "M032",	text: "OTRAS ARTROPATIAS POSTINFECCIOSAS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "M036",	text: "ARTROPATIA REACTIVA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "M050",	text: "SINDROME DE FELTY"},
{ value: "M051",	text: "ENFERMEDAD REUMATOIDE DEL PULMON (J99.0*)"},
{ value: "M052",	text: "VASCULITIS REUMATOIDE"},
{ value: "M053",	text: "ARTRITIS REUMATOIDE CON COMPROMISO DE OTROS ORGANOS O SISTEMAS"},
{ value: "M058",	text: "OTRAS ARTRITIS REUMATOIDEAS SEROPOSITIVAS"},
{ value: "M059",	text: "ARTRITIS REUMATOIDE SEROPOSITIVA, SIN OTRA ESPECIFICACION"},
{ value: "M060",	text: "ARTRITIS REUMATOIDE SERONEGATIVA"},
{ value: "M061",	text: "ENFERMEDAD DE STILL DE COMIENZO EN EL ADULTO"},
{ value: "M062",	text: "BURSITIS REUMATOIDE"},
{ value: "M063",	text: "NODULO REUMATOIDE"},
{ value: "M064",	text: "POLIARTROPATIA INFLAMATORIA"},
{ value: "M068",	text: "OTRAS ARTRITIS REUMATOIDEAS ESPECIFICADAS"},
{ value: "M069",	text: "ARTRITIS REUMATOIDE, NO ESPECIFICADA"},
{ value: "M070",	text: "ARTROPATIA PSORIASICA INTERFALANGICA DISTAL (L40.5†)"},
{ value: "M071",	text: "ARTRITIS MUTILANTE (L40.5†)"},
{ value: "M072",	text: "ESPONDILITIS PSORIASICA (L40.5†)"},
{ value: "M073",	text: "OTRAS ARTROPATIAS PSORIASICAS (L40.5†)"},
{ value: "M074",	text: "ARTROPATIA EN LA ENFERMEDAD DE CROHN (ENTERITIS REGIONAL) (K50.-†)"},
{ value: "M075",	text: "ARTROPATIA EN LA COLITIS ULCERATIVA (K51.-†)"},
{ value: "M076",	text: "OTRAS ARTROPATIAS ENTEROPATICAS"},
{ value: "M080",	text: "ARTRITIS REUMATOIDE JUVENIL"},
{ value: "M081",	text: "ESPONDILITIS ANQUILOSANTE JUVENIL"},
{ value: "M082",	text: "ARTRITIS JUVENIL DE COMIENZO GENERALIZADO"},
{ value: "M083",	text: "POLIARTRITIS JUVENIL (SERONEGATIVA)"},
{ value: "M084",	text: "ARTRITIS JUVENIL PAUCIARTICULAR"},
{ value: "M088",	text: "OTRAS ARTRITIS JUVENILES"},
{ value: "M089",	text: "ARTRITIS JUVENIL, NO ESPECIFICADA"},
{ value: "M090",	text: "ARTRITIS JUVENIL EN LA PSORIASIS (L40.5†)"},
{ value: "M091",	text: "ARTRITIS JUVENIL EN LA ENFERMEDAD DE CROHN [ENTERITIS REGIONAL] (K50. †)"},
{ value: "M092",	text: "ARTRITIS JUVENIL EN LA COLITIS ULCERATIVA (K51.-†)"},
{ value: "M098",	text: "ARTRITIS JUVENIL EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "M100",	text: "GOTA IDIOPATICA"},
{ value: "M101",	text: "GOTA SATURNINA"},
{ value: "M102",	text: "GOTA INDUCIDA POR DROGAS"},
{ value: "M103",	text: "GOTA DEBIDA A ALTERACION RENAL"},
{ value: "M104",	text: "OTRAS GOTAS SECUNDARIAS"},
{ value: "M109",	text: "GOTA, NO ESPECIFICADA"},
{ value: "M110",	text: "ENFERMEDAD POR DEPOSITO DE HIDROXIAPATITA"},
{ value: "M111",	text: "CONDROCALCINOSIS FAMILIAR"},
{ value: "M112",	text: "OTRAS CONDROCALCINOSIS"},
{ value: "M118",	text: "OTRAS ARTROPATIAS POR CRISTALES, ESPECIFICADAS"},
{ value: "M119",	text: "ARTROPATIA POR CRISTALES, NO ESPECIFICADA"},
{ value: "M120",	text: "ARTROPATIA POSTREUMATICA CRONICA [DE JACCOUD]"},
{ value: "M121",	text: "ENFERMEDAD DE KASCHIN-BECK"},
{ value: "M122",	text: "SINOVITIS VELLONODULAR (PIGMENTADA)"},
{ value: "M123",	text: "REUMATISMO PALINDROMICO"},
{ value: "M124",	text: "HIDRARTROSIS INTERMITENTE"},
{ value: "M125",	text: "ARTROPATIA TRAUMATICA"},
{ value: "M128",	text: "OTRAS ARTROPATIAS ESPECIFICAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "M130",	text: "POLIARTRITIS, NO ESPECIFICADA"},
{ value: "M131",	text: "MONOARTRITIS, NO CLASIFICADA EN OTRA PARTE"},
{ value: "M138",	text: "OTRAS ARTRITIS ESPECIFICADAS"},
{ value: "M139",	text: "ARTRITIS, NO ESPECIFICADA"},
{ value: "M140",	text: "ARTROPATIA GOTOSA DEBIDA A DEFECTOS ENZIMATICOS Y A OTROS TRASTORNOS HEREDITARIOS, CLASIFICADOS EN OTRA PARTE"},
{ value: "M141",	text: "ARTROPATIA POR CRISTALES EN OTROS TRASTORNOS METABÓLICOS"},
{ value: "M142",	text: "ARTROPATIA DIABETICA (E10-E14† CON CUARTO CARÁCTER COMUN .6)"},
{ value: "M143",	text: "DERMATOARTRITIS LIPOIDE (E78.8†)"},
{ value: "M144",	text: "ARTROPATIA EN LA AMILOIDOSIS (E85.- †)"},
{ value: "M145",	text: "ARTROPATIA EN OTROS TRASTORNOS ENDOCRINOS, METABOLICOS Y NUTRICIONALES"},
{ value: "M146",	text: "ARTROPATIA NEUROPATICA"},
{ value: "M148",	text: "ARTROPATIA EN OTRAS ENFERMEDADES ESPECIFICADAS, CLASIFICADAS EN OTRA PARTE"},
{ value: "M150",	text: "OSTEO)ARTROSIS PRIMARIA GENERALIZADA"},
{ value: "M151",	text: "NODULOS DE HEBERDEN (CON ARTROPATIA)"},
{ value: "M152",	text: "NODULOS DE BOUCHARD (CON ARTROPATIA)"},
{ value: "M153",	text: "ARTROSIS SECUNDARIA MULTIPLE"},
{ value: "M154",	text: "OSTEO)ARTROSIS EROSIVA"},
{ value: "M158",	text: "OTRAS POLIARTROSIS"},
{ value: "M159",	text: "POLIARTROSIS, NO ESPECIFICADA"},
{ value: "M160",	text: "COXARTROSIS PRIMARIA, BILATERAL"},
{ value: "M161",	text: "OTRAS COXARTROSIS PRIMARIAS"},
{ value: "M162",	text: "COXARTROSIS A CONSECUENCIA DE DISPLASIA, BILATERAL"},
{ value: "M163",	text: "OTRAS COXARTROSIS DISPLASICAS"},
{ value: "M164",	text: "COXARTROSIS POSTRAUMATICA, BILATERAL"},
{ value: "M165",	text: "OTRA COXARTROSIS POSTRAUMATICA"},
{ value: "M166",	text: "OTRA COXARTROSIS SECUNDARIA, BILATERAL"},
{ value: "M167",	text: "OTRAS COXARTROSIS SECUNDARIAS"},
{ value: "M169",	text: "COXARTROSIS, NO ESPECIFICADA"},
{ value: "M170",	text: "GONARTROSIS PRIMARIA, BILATERAL"},
{ value: "M171",	text: "OTRAS GONARTROSIS PRIMARIAS"},
{ value: "M172",	text: "GONARTROSIS POSTRAUMATICA, BILATERAL"},
{ value: "M173",	text: "OTRAS GONARTROSIS POSTRAUMATICAS"},
{ value: "M174",	text: "OTRAS GONARTROSIS SECUNDARIAS, BILATERALES"},
{ value: "M175",	text: "OTRAS GONARTROSIS SECUNDARIAS"},
{ value: "M179",	text: "GONARTROSIS, NO ESPECIFICADA"},
{ value: "M180",	text: "ARTROSIS PRIMARIA DE LA PRIMERA ARTICULACION CARPOMETACARPIANA, BILATERAL"},
{ value: "M181",	text: "OTRAS ARTROSIS PRIMARIAS DE LA PRIMERA ARTICULACION CARPOMETACARPIANA"},
{ value: "M182",	text: "ARTROSIS POSTRAUMATICA DE LA PRIMERA ARTICULACION CARPOMETACARPIANA, BILATERAL"},
{ value: "M183",	text: "OTRAS ARTROSIS POSTRAUMATICAS DE LA PRIMERA ARTICULACION CARPOMETACARPIANA"},
{ value: "M184",	text: "OTRAS ARTROSIS SECUNDARIAS DE LA PRIMERA ARTICULACION CARPOMETACARPIANA, BILATERALES"},
{ value: "M185",	text: "OTRAS ARTROSIS SECUNDARIAS DE LA PRIMERA ARTICULACION CARPOMETACARPIANA"},
{ value: "M189",	text: "ARTROSIS DE LA PRIMERA ARTICULACION CARPOMETACARPIANA, SIN OTRA ESPECIFICACION"},
{ value: "M190",	text: "ARTROSIS PRIMARIA DE OTRAS ARTICULACIONES"},
{ value: "M191",	text: "ARTROSIS POSTRAUMATICA DE OTRAS ARTICULACIONES"},
{ value: "M192",	text: "ARTROSIS SECUNDARIA DE OTRAS ARTICULACIONES"},
{ value: "M198",	text: "OTRAS ARTROSIS ESPECIFICADAS"},
{ value: "M199",	text: "ARTROSIS, NO ESPECIFICADA"},
{ value: "M200",	text: "DEFORMIDAD DE DEDO(S) DE LA MANO"},
{ value: "M201",	text: "HALLUX VALGUS (ADQUIRIDO)"},
{ value: "M202",	text: "HALLUX RIGIDUS"},
{ value: "M203",	text: "OTRAS DEFORMIDADES DEL HALLUX (ADQUIRIDAS)"},
{ value: "M204",	text: "OTRO(S) DEDO(S) DEL PIE EN MARTILLO (ADQUIRIDOS)"},
{ value: "M205",	text: "OTRAS DEFORMIDADES (ADQUIRIDAS) DEL (DE LOS) DEDO(S) DEL PIE"},
{ value: "M206",	text: "DEFORMIDADES ADQUIRIDAS DE LOS DEDOS DEL PIE, NO ESPECIFICADAS"},
{ value: "M210",	text: "DEFORMIDAD EN VALGO, NO CLASIFICADA EN OTRA PARTE"},
{ value: "M211",	text: "DEFORMIDAD EN VARO, NO CLASIFICADA EN OTRA"},
{ value: "M212",	text: "DEFORMIDAD EN FLEXION"},
{ value: "M213",	text: "MUÑECA O PIE EN PENDULO (ADQUIRIDO)"},
{ value: "M214",	text: "PIE PLANO [PES PLANUS] (ADQUIRIDO)"},
{ value: "M215",	text: "MANO O PIE EN GARRA O EN TALIPES, PIE EQUINOVARO O ZAMBO ADQUIRIDOS"},
{ value: "M216",	text: "OTRAS DEFORMIDADES ADQUIRIDAS DEL TOBILLO Y DEL PIE"},
{ value: "M217",	text: "LONGITUD DESIGUAL DE LOS MIEMBROS (ADQUIRIDA)"},
{ value: "M218",	text: "OTRAS DEFORMIDADES ADQUIRIDAS DE LOS MIEMBROS, ESPECIFICADAS"},
{ value: "M219",	text: "DEFORMIDAD ADQUIRIDA DEL MIEMBRO, NO ESPECIFICADA"},
{ value: "M220",	text: "LUXACION RECIDIVANTE DE LA ROTULA"},
{ value: "M221",	text: "SUBLUXACION RECIDIVANTE DE LA ROTULA"},
{ value: "M222",	text: "TRASTORNOS ROTULOFEMORALES"},
{ value: "M223",	text: "OTROS DESARREGLOS DE LA ROTULA"},
{ value: "M224",	text: "CONDROMALACIA DE LA ROTULA"},
{ value: "M228",	text: "OTROS TRASTORNOS DE LA ROTULA"},
{ value: "M229",	text: "TRASTORNO DE LA ROTULA, NO ESPECIFICADO"},
{ value: "M230",	text: "MENISCO QUISTICO"},
{ value: "M231",	text: "MENISCO DISCOIDE (CONGENITO)"},
{ value: "M232",	text: "TRASTORNO DEL MENISCO DEBIDO A DESGARRO O LESION ANTIGUA"},
{ value: "M233",	text: "OTROS TRASTORNOS DE LOS MENISCOS"},
{ value: "M234",	text: "CUERPO FLOTANTE EN LA RODILLA"},
{ value: "M235",	text: "INESTABILIDAD CRONICA DE LA RODILLA"},
{ value: "M236",	text: "OTRA RUPTURA ESPONTANEA DEL (DE LOS) LIGAMENTO(S) DE LA RODILLA"},
{ value: "M238",	text: "OTROS TRASTORNOS INTERNOS DE LA RODILLA"},
{ value: "M239",	text: "TRASTORNOS INTERNO DE LA RODILLA, NO ESPECIFICADO"},
{ value: "M240",	text: "CUERPO FLOTANTE ARTICULAR"},
{ value: "M241",	text: "OTROS TRASTORNOS DEL CARTILAGO ARTICULAR"},
{ value: "M242",	text: "TRASTORNO DEL LIGAMENTO"},
{ value: "M243",	text: "LUXACION Y SUBLUXACION PATOLOGICA DE LA ARTICULACION, NO CLASIFICADA EN OTRA PARTE"},
{ value: "M244",	text: "LUXACION Y SUBLUXACION RECIDIVANTE DE LA ARTICULACION"},
{ value: "M245",	text: "CONTRACTURA ARTICULAR"},
{ value: "M246",	text: "ANQUILOSIS ARTICULAR"},
{ value: "M247",	text: "PROTRUSION DE ACETABULO"},
{ value: "M248",	text: "OTRAS LESIONES ARTICULADAS ESPECIFICADAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "M249",	text: "DESARREGLO ARTICULAR, NO ESPECIFICADO"},
{ value: "M250",	text: "HEMARTROSIS"},
{ value: "M251",	text: "FISTULA ARTICULAR"},
{ value: "M252",	text: "ARTICULACION INESTABLE"},
{ value: "M253",	text: "OTRAS INESTABILIDADES ARTICULARES"},
{ value: "M254",	text: "DERRAME ARTICULAR"},
{ value: "M255",	text: "DOLOR EN ARTICULACION"},
{ value: "M256",	text: "RIGIDEZ ARTICULAR, NO CLASIFICADA EN OTRA PARTE"},
{ value: "M257",	text: "OSTEOFITO"},
{ value: "M258",	text: "OTROS TRASTORNOS ARTICULARES ESPECIFICADOS"},
{ value: "M259",	text: "TRASTORNO ARTICULAR, NO ESPECIFICADO"},
{ value: "M300",	text: "POLIARTERITIS NUDOSA"},
{ value: "M301",	text: "POLIARTERITIS CON COMPROMISO PULMONAR [CHURG-STRAUSS]"},
{ value: "M302",	text: "POLIARTERITIS JUVENIL"},
{ value: "M303",	text: "SINDROME MUCOCUTANEO LIFONODULAR [KAWASAKI]"},
{ value: "M308",	text: "OTRAS AFECCIONES RELACIONADAS CON LA POLIARTERITIS NUDOSA"},
{ value: "M310",	text: "ANGIITIS DEBIDA A HIPERSENSIBILIDAD"},
{ value: "M311",	text: "MICROANGIOPATIA TROMBOTICA"},
{ value: "M312",	text: "GRANULOMA LETAL DE LA LINEA MEDIA"},
{ value: "M313",	text: "GRANULOMATOSIS DE WEGENER"},
{ value: "M314",	text: "SINDROME DEL CAYADO DE LA AORTA [TAKAYASU]"},
{ value: "M315",	text: "ARTERITIS DE CELULAS GIGANTES CON POLIMIALGIA REUMATICA"},
{ value: "M316",	text: "OTRAS ARTERITIS DE CELULAS GIGANTES"},
{ value: "M318",	text: "OTRAS VASCULOPATIAS NECROTIZANTES ESPECIFICADAS"},
{ value: "M319",	text: "VASCULOPATIA NECROTIZANTE, NO ESPECIFICADA"},
{ value: "M320",	text: "LUPUS ERITEMATOSO SISTEMICO, INDUCIDO POR DROGAS"},
{ value: "M321",	text: "LUPUS ERITEMATOSO SISTEMICO CON COMPROMISO DE ORGANOS O SISTEMAS"},
{ value: "M328",	text: "OTRAS FORMAS DE LUPUS ERITEMATOSO SISTEMICO"},
{ value: "M329",	text: "LUPUS ERITEMATOSO SISTEMICO, SIN OTRA ESPECIFICACION"},
{ value: "M330",	text: "DERMATOMIOSITIS JUVENIL"},
{ value: "M331",	text: "OTRAS DERMATOMIOSITIS"},
{ value: "M332",	text: "POLIMIOSITIS"},
{ value: "M339",	text: "DERMATOPOLIMIOSITIS, NO ESPECIFICADA"},
{ value: "M340",	text: "ESCLEROSIS SISTEMICA PROGRESIVA"},
{ value: "M341",	text: "SINDROME CR(E)ST"},
{ value: "M342",	text: "ESCLEROSIS SISTEMICA INDUCIDA POR DROGAS O PRODUCTOS QUIMICOS"},
{ value: "M348",	text: "OTRAS FORMAS DE ESCLEROSIS SISTEMICA"},
{ value: "M349",	text: "ESCLEROSIS SISTEMICA, NO ESPECIFICADA"},
{ value: "M350",	text: "SINDROME SECO [SJÖGREN]"},
{ value: "M351",	text: "OTROS SINDROMES SUPERPUESTOS"},
{ value: "M352",	text: "ENFERMEDAD DE BEHCET"},
{ value: "M353",	text: "POLIMIALGIA REUMATICA"},
{ value: "M354",	text: "FASCITIS DIFUSA (EOSINOFILICA)"},
{ value: "M355",	text: "FIBROSCLEROSIS MULTIFOCAL"},
{ value: "M356",	text: "PANICULITIS RECIDIVANTE [WEBER- CHRISTIAN]"},
{ value: "M357",	text: "SINDROME DE HIPERMOVILIDAD"},
{ value: "M358",	text: "OTRAS ENFERMEDADES ESPECIFICADAS CON COMPROMISO SISTEMICO DEL TEJIDO CONJUNTIVO"},
{ value: "M359",	text: "COMPROMISO SISTEMICO DEL TEJIDO CONJUNTIVO, NO ESPECIFICADO"},
{ value: "M360",	text: "DERMATO(POLI)MIOSITIS EN ENFERMEDAD NEOPLASICA (C00-D48†)"},
{ value: "M361",	text: "ARTROPATIA EN ENFERMEDAD NEOPLASICA (C00-D48†)"},
{ value: "M362",	text: "ARTROPATIA HEMOFILICA (D66-D68†)"},
{ value: "M363",	text: "ARTROPATIA EN OTROS TRASTORNOS DE LA SANGRE (D50-D76†)"},
{ value: "M364",	text: "ARTROPATIA EN REACCIONES DE HIPERSENSIBILIDAD CLASIFICADAS EN OTRA PARTE"},
{ value: "M368",	text: "TRASTORNOS SISTEMICOS DEL TEJIDO CONJUNTIVO EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "M400",	text: "CIFOSIS POSTURAL"},
{ value: "M401",	text: "OTRAS CIFOSIS SECUNDARIAS"},
{ value: "M402",	text: "OTRAS CIFOSIS Y LAS NO ESPECIFICADAS"},
{ value: "M403",	text: "SINDROME DE ESPALDA PLANA"},
{ value: "M404",	text: "OTRAS LORDOSIS"},
{ value: "M405",	text: "LORDOSIS, NO ESPECIFICADA"},
{ value: "M410",	text: "ESCOLIOSIS IDIOPATICA INFANTIL"},
{ value: "M411",	text: "ESCOLIOSIS IDIOPATICA JUVENIL"},
{ value: "M412",	text: "OTRAS ESCOLIOSIS IDIOPATICAS"},
{ value: "M413",	text: "ESCOLIOSIS TORACOGENICA"},
{ value: "M414",	text: "ESCOLIOSIS NEUROMUSCULAR"},
{ value: "M415",	text: "OTRAS ESCOLIOSIS SECUNDARIAS"},
{ value: "M418",	text: "OTRAS FORMAS DE ESCOLIOSIS"},
{ value: "M419",	text: "ESCOLIOSIS, NO ESPECIFICADA"},
{ value: "M420",	text: "OSTEOCONDROSIS JUVENIL DE LA COLUMNA VERTEBRAL"},
{ value: "M421",	text: "OSTEOCONDROSIS DE LA COLUMNA VERTEBRAL DEL ADULTO"},
{ value: "M429",	text: "OSTEOCONDROSIS VERTEBRAL, NO ESPECIFICADA"},
{ value: "M430",	text: "ESPONDILOLISIS"},
{ value: "M431",	text: "ESPONDILOLISTESIS"},
{ value: "M432",	text: "OTRAS FUSIONES COLUMNA VERTEBRAL"},
{ value: "M433",	text: "SUBLUXACION ATLANTO-AXOIDEA RECURRENTE, CON MIELOPATIA"},
{ value: "M434",	text: "OTRAS SUBLUXACIONES ATLANTO- AXOIDEAS RECURRENTES"},
{ value: "M435",	text: "OTRAS SUBLUXACIONES VERTEBRALES RECURRENTES"},
{ value: "M436",	text: "TORTICOLIS"},
{ value: "M438",	text: "OTRAS DORSOPATIAS DEFORMANTES DE LA COLUMNA VERTEBRAL ESPECIFICADAS"},
{ value: "M439",	text: "DORSOPATIA DEFORMANTE, NO ESPECIFICADA"},
{ value: "M45X",	text: "ESPONDILITIS ANQUILOSANTE"},
{ value: "M460",	text: "ENTESOPATIA VERTEBRAL"},
{ value: "M461",	text: "SACROILIITIS, NO CLASIFICADA EN OTRA PARTE"},
{ value: "M462",	text: "OSTEOMIELITIS DE VERTEBRA"},
{ value: "M463",	text: "INFECCION DE DISCO INTERVERTEBRAL (PIOGENA)"},
{ value: "M464",	text: "DISCITIS, NO ESPECIFICADA"},
{ value: "M465",	text: "OTRAS ESPONDILOPATIAS INFECCIOSAS"},
{ value: "M468",	text: "OTRAS ESPONDILOPATIAS INFLAMATORIAS ESPECIFICADAS"},
{ value: "M469",	text: "ESPONDILOPATIA INFLAMATORIA, NO ESPECIFICADA"},
{ value: "M470",	text: "SINDROMES DE COMPRESION DE LA ARTERIA ESPINAL O VERTEBRAL ANTERIOR (G99.2*)"},
{ value: "M471",	text: "OTRAS ESPONDILOSIS CON MIELOPATIA"},
{ value: "M472",	text: "OTRAS ESPONDILOSIS CON RADICULOPATIA"},
{ value: "M478",	text: "OTRAS ESPONDILOSIS"},
{ value: "M479",	text: "ESPONDILOSIS, NO ESPECIFICADA"},
{ value: "M480",	text: "ESTENOSIS ESPINAL"},
{ value: "M481",	text: "HIPEROSTOSIS ANQUILOSANTE [FORESTIER]"},
{ value: "M482",	text: "ESPONDILOPATIA INTERESPINOSA (VERTEBRAS 'EN BESO')"},
{ value: "M483",	text: "ESPONDILOPATIA TRAUMATICA"},
{ value: "M484",	text: "FRACTURA DE VERTEBRA POR FATIGA"},
{ value: "M485",	text: "VERTEBRA COLAPSADA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "M488",	text: "OTRAS ESPONDILOPATIAS ESPECIFICADAS"},
{ value: "M489",	text: "ESPONDILOPATIA , NO ESPECIFICADA"},
{ value: "M490",	text: "TUBERCULOSIS DE LA COLUMNA VERTEBRAL (A180†)"},
{ value: "M491",	text: "ESPONDILITIS POR BRUCELOSIS (A23.- †)"},
{ value: "M492",	text: "ESPONDILITIS POR ENTEROBACTERIAS (A01-A04†)"},
{ value: "M493",	text: "ESPONDILOPATIA EN OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "M494",	text: "ESPONDILOPATIA NEUROPATICA"},
{ value: "M495",	text: "VERTEBRA COLAPSADA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "M498",	text: "ESPONDILOPATIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "M500",	text: "TRASTORNO DE DISCO CERVICAL CON MIELOPATIA (G99.2*)"},
{ value: "M501",	text: "TRASTORNO DE DISCO CERVICAL CON RADICULOPATIA"},
{ value: "M502",	text: "OTROS DESPLAZAMIENTOS DEL DISCO CERVICAL"},
{ value: "M503",	text: "OTRAS DEGENERACIONES DEL DISCO CERVICAL"},
{ value: "M508",	text: "OTROS TRASTORNOS DEL DISCO CERVICAL"},
{ value: "M509",	text: "TRASTORNO DE DISCO CERVICAL, NO ESPECIFICADO"},
{ value: "M510",	text: "TRASTORNOS DE DISCOS INTERVERTEBRALES LUMBARES Y OTROS, CON MIELOPATIA (G99.2*)"},
{ value: "M511",	text: "TRASTORNO DE DISCO LUMBAR Y OTROS, CON RADICULOPATIA"},
{ value: "M512",	text: "OTROS DESPLAZAMIENTOS ESPECIFICADOS DE DISCO INTERVERTEBRAL"},
{ value: "M513",	text: "OTRAS DEGENERACIONES ESPECIFICADAS DE DISCO INTERVERTEBRAL"},
{ value: "M514",	text: "NODULOS DE SCHMORL"},
{ value: "M518",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LOS DISCOS INTERVERTEBRALES"},
{ value: "M519",	text: "TRASTORNOS DE LOS DISCOS INTERVERTEBRALES, NO ESPECIFICADO"},
{ value: "M530",	text: "SINDROME CERVICOCRANEAL"},
{ value: "M531",	text: "SINDROME CERVICOBRAQUIAL"},
{ value: "M532",	text: "INESTABILIDAD DE LA COLUMNA VERTEBRAL"},
{ value: "M533",	text: "TRASTORNOS SACROCOCCIGEOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "M538",	text: "OTRAS DORSOPATIAS ESPECIFICADAS"},
{ value: "M539",	text: "DORSOPATIA, NO ESPECIFICADA"},
{ value: "M540",	text: "PANICULITIS QUE AFECTA REGIONES DEL CUELLO Y DE LA ESPALDA"},
{ value: "M541",	text: "RADICULOPATIA"},
{ value: "M542",	text: "CERVICALGIA"},
{ value: "M543",	text: "CIATICA"},
{ value: "M544",	text: "LUMBAGO CON CIATICA"},
{ value: "M545",	text: "LUMBAGO NO ESPECIFICADO"},
{ value: "M546",	text: "DOLOR EN LA COLUMNA DORSAL"},
{ value: "M548",	text: "OTRAS DORSALGIAS"},
{ value: "M549",	text: "DORSALGIA, NO ESPECIFICADA"},
{ value: "M600",	text: "MIOSITIS INFECCIOSA"},
{ value: "M601",	text: "MIOSITIS INTERSTICIAL"},
{ value: "M602",	text: "GRANULOMA POR CUERPO EXTRAÑO EN TEJIDO BLANDO, NO CLASIFICADO EN OTRA PARTE"},
{ value: "M608",	text: "OTRAS MIOSITIS"},
{ value: "M609",	text: "MIOSITIS, NO ESPECIFICADA"},
{ value: "M610",	text: "MIOSITIS OSIFICANTE TRAUMATICA"},
{ value: "M611",	text: "MIOSITIS OSIFICANTE PROGRESIVA"},
{ value: "M612",	text: "CALCIFICACION Y OSIFICACION PARALITICA DEL MUSCULO"},
{ value: "M613",	text: "CALCIFICACION Y OSIFICACION DE LOS MUSCULOS ASOCIADAS CON QUEMADURAS"},
{ value: "M614",	text: "OTRAS CALCIFICACIONES DEL MUSCULO"},
{ value: "M615",	text: "OTRAS OSIFICACIONES DEL MUSCULO"},
{ value: "M619",	text: "CALCIFICACION Y OSIFICACION DEL MUSCULO, NO ESPECIFICADA"},
{ value: "M620",	text: "DIASTASIS DEL MUSCULO"},
{ value: "M621",	text: "OTROS DESGARROS (NO TRAUMATICOS) DEL MUSCULO"},
{ value: "M622",	text: "INFARTO ISQUEMICO DEL MUSCULO"},
{ value: "M623",	text: "SINDROME DE INMOVILIDAD (PARAPLEJICO)"},
{ value: "M624",	text: "CONTRACTURA MUSCULAR"},
{ value: "M625",	text: "ATROFIA Y DESGASTE MUSCULARES, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "M626",	text: "DISTENSION MUSCULAR"},
{ value: "M628",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LOS MUSCULOS"},
{ value: "M629",	text: "TRASTORNO MUSCULAR, NO ESPECIFICADO"},
{ value: "M630",	text: "MIOSITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"},
{ value: "M631",	text: "MIOSITIS EN INFECCIONES POR PROTOZOARIOS Y PARASITOS CLASIFICADAS EN OTRA PARTE"},
{ value: "M632",	text: "MIOSITIS EN ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE"},
{ value: "M633",	text: "MIOSITIS EN SARCOIDOSIS (D86.8†)"},
{ value: "M638",	text: "OTROS TRASTORNOS DE LOS MUSCULOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "M650",	text: "ABSCESO DE VAINA TENDINOSA"},
{ value: "M651",	text: "OTRAS (TENO) SINOVITIS INFECCIOSAS"},
{ value: "M652",	text: "TENDINITIS CALCIFICADA"},
{ value: "M653",	text: "DEDO EN GATILLO"},
{ value: "M654",	text: "TENOSINOVITIS DE ESTILOIDES RADIAL [DE QUERVAIN]"},
{ value: "M658",	text: "OTRAS SINOVITIS Y TENOSINOVITIS"},
{ value: "M659",	text: "SINOVITIS Y TENOSINOVITIS, NO ESPECIFICADA"},
{ value: "M660",	text: "RUPTURA DE QUISTE SINOVIAL POPLITEO"},
{ value: "M661",	text: "RUPTURA DE LA SINOVIA"},
{ value: "M662",	text: "RUPTURA ESPONTANEA DE TENDONES EXTENSORES"},
{ value: "M663",	text: "RUPTURA ESPONTANEA DE TENDONES FLEXORES"},
{ value: "M664",	text: "RUPTURA ESPONTANEA DE OTROS TENDONES"},
{ value: "M665",	text: "RUPTURA ESPONTANEA DE TENDON NO ESPECIFICADO"},
{ value: "M670",	text: "ACORTAMIENTO DEL TENDON DE AQUILES (ADQUIRIDO)"},
{ value: "M671",	text: "OTRAS CONTRACTURAS DE TENDON (VAINA)"},
{ value: "M672",	text: "HIPERTROFIA SINOVIAL, NO CLASIFICADA EN OTRA PARTE"},
{ value: "M673",	text: "SINOVITIS TRANSITORIA"},
{ value: "M674",	text: "GANGLION"},
{ value: "M678",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA SINOVIA Y DEL TENDON"},
{ value: "M679",	text: "HIPERTROFIA SINOVIAL Y TENDINOSO, NO ESPECIFICADO"},
{ value: "M680",	text: "SINOVITIS Y TENOSINOVITIS EN ENFERMEDADES BACTERIANAS CLASIFICADAS EN OTRA PARTE"},
{ value: "M688",	text: "OTROS TRASTORNOS SINOVIALES Y TENDINOSOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "M700",	text: "SINOVITIS CREPITANTE CRONICA DE LA MANO Y DE LA MUÑECA"},
{ value: "M701",	text: "BURSITIS DE LA MANO"},
{ value: "M702",	text: "BURSITIS DEL OLECRANON"},
{ value: "M703",	text: "OTRAS BURSITIS DEL CODO"},
{ value: "M704",	text: "OTRAS BURSITIS PRERROTULIANAS"},
{ value: "M705",	text: "OTRAS BURSITIS DE LA RODILLA"},
{ value: "M706",	text: "BURSITIS DEL TROCANTER"},
{ value: "M707",	text: "OTRAS BURSITIS DE LA CADERA"},
{ value: "M708",	text: "OTROS TRASTORNOS DE LOS TEJIDOS BLANDOS RELACIONADOS CON EL USO, EL USO EXCESIVO Y LA PRESION"},
{ value: "M709",	text: "TRASTORNO NO ESPECIFICADO DE LOS TEJIDOS BLANDOS RELACIONADO CON EL USO EXCESIVO Y LA PRESION"},
{ value: "M710",	text: "ABSCESO DE LA BOLSA SINOVIAL"},
{ value: "M711",	text: "OTRAS BURSITIS INFECCIOSAS"},
{ value: "M712",	text: "QUISTE SINOVIAL DEL HUECO POPLITEO [DE BAKER]"},
{ value: "M713",	text: "OTROS QUISTES DE LA BOLSA SEROSA"},
{ value: "M714",	text: "DEPOSITO DE CALCIO EN LA BOLSA SEROSA"},
{ value: "M715",	text: "OTRAS BURSITIS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "M718",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA BOLSA SEROSA"},
{ value: "M719",	text: "BURSOPATIA, NO ESPECIFICADA"},
{ value: "M720",	text: "FIBROMATOSIS DE LA APONEUROSIS PALMAR [DUPUYTREN]"},
{ value: "M721",	text: "NODULOS INTERFALANGICOS"},
{ value: "M722",	text: "FIBROMATOSIS DE LA APONEUROSIS PLANTAR"},
{ value: "M723",	text: "FASCITIS NODULAR"},
{ value: "M724",	text: "FIBROMATOSIS SEUDOSARCOMATOSA"},
{ value: "M725",	text: "FASCITIS, NO CLASIFICADA EN OTRA PARTE"},
{ value: "M728",	text: "OTROS TRASTORNOS FIBROBLASTICOS"},
{ value: "M729",	text: "TRASTORNO FIBROBLASTICO, NO ESPECIFICADO"},
{ value: "M730",	text: "BURSITIS GONOCOCICA (A54.4†)"},
{ value: "M731",	text: "BURSITIS SIFILITICA (A52.7†)"},
{ value: "M738",	text: "OTROS TRASTORNOS DE LOS TEJIDOS BLANDOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "M750",	text: "CAPSULITIS ADHESIVA DEL HOMBRO"},
{ value: "M751",	text: "SINDROME DE MANGUITO ROTATORIO"},
{ value: "M752",	text: "TENDINITIS DE BICEPS"},
{ value: "M753",	text: "TENDINITIS CALCIFICANTE DEL HOMBRO"},
{ value: "M754",	text: "SINDROME DE ABDUCCION DOLOROSA DEL HOMBRO"},
{ value: "M755",	text: "BURSITIS DEL HOMBRO"},
{ value: "M758",	text: "OTRAS LESIONES DEL HOMBRO"},
{ value: "M759",	text: "LESIONES DEL HOMBRO, NO ESPECIFICADA"},
{ value: "M760",	text: "TENDINITIS DEL GLUTEO"},
{ value: "M761",	text: "TENDINITIS DEL PSOAS"},
{ value: "M762",	text: "ESPOLON DE LA CRESTA ILIACA"},
{ value: "M763",	text: "SINDROME DEL TENDON DEL TENSOR DE LA FASCIA LATA"},
{ value: "M764",	text: "BURSITIS TIBIAL COLATERAL [PELLEGRINI-STIEDA]"},
{ value: "M765",	text: "TENDINITIS ROTULIANA"},
{ value: "M766",	text: "TENDINITIS AQUILIANA"},
{ value: "M767",	text: "TENDINITIS PERONEAL"},
{ value: "M768",	text: "OTRAS ENTESOPATIAS DEL MIEMBRO INFERIOR, EXCLUIDO EL PIE"},
{ value: "M769",	text: "ENTESOPATIA DEL MIEMBRO INFERIOR, NO ESPECIFICADA"},
{ value: "M770",	text: "EPICONDILITIS MEDIA"},
{ value: "M771",	text: "EPICONDILITIS LATERAL"},
{ value: "M772",	text: "PERIARTRITIS DE LA MUÑECA"},
{ value: "M773",	text: "ESPOLON CALCANEO"},
{ value: "M774",	text: "METATARSALGIA"},
{ value: "M775",	text: "OTRAS ENTESOPATIAS DEL PIE"},
{ value: "M778",	text: "OTRAS ENTESOPATIAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "M779",	text: "ENTESOPATIA, NO ESPECIFICADA"},
{ value: "M790",	text: "REUMATISMO, NO ESPECIFICADO"},
{ value: "M791",	text: "MIALGIA"},
{ value: "M792",	text: "NEURALGIA Y NEURITIS, NO ESPECIFICADAS"},
{ value: "M793",	text: "PANICULITIS, NO ESPECIFICADA"},
{ value: "M794",	text: "HIPERTROFIA DE PAQUETE ADIPOSO (INFRARROTULIANO)"},
{ value: "M795",	text: "CUERPO EXTRAÑO RESIDUAL EN TEJIDO BLANDO"},
{ value: "M796",	text: "DOLOR EN MIEMBRO"},
{ value: "M798",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LOS TEJIDOS BLANDOS"},
{ value: "M799",	text: "TRASTORNO DE LOS TEJIDOS BLANDOS, NO ESPECIFICADO"},
{ value: "M800",	text: "OSTEOPOROSIS POSTMENOPAUSICA, CON FRACTURA PATOLOGICA"},
{ value: "M801",	text: "OSTEOPOROSIS POSTOOFORECTOMIA, CON FRACTURA PATOLOGICA"},
{ value: "M802",	text: "OSTEOPOROSIS POR DESUSO, CON FRACTURA PATOLOGICA"},
{ value: "M803",	text: "OSTEOPOROSIS POR MALABSORCION POSTQUIRURGICA, CON FRACTURA PATOLOGICA"},
{ value: "M804",	text: "OSTEOPOROSIS INDUCIDA POR DROGAS, CON FRACTURA PATOLOGICA"},
{ value: "M805",	text: "OSTEOPOROSIS IDIOPATICA, CON FRACTURA PATOLOGICA"},
{ value: "M808",	text: "OTRAS OSTEOPOROSIS, CON FRACTURA PATOLOGICA"},
{ value: "M809",	text: "OSTEOPOROSIS NO ESPECIFICADA, CON FRACTURA PATOLOGICA"},
{ value: "M810",	text: "OSTEOPOROSIS POSTMENOPAUSICA, SIN FRACTURA PATOLOGICA"},
{ value: "M811",	text: "OSTEOPOROSIS POSTOOFORECTOMIA, SIN FRACTURA PATOLOGICA"},
{ value: "M812",	text: "OSTEOPOROSIS POR DESUSO, SIN FRACTURA PATOLOGICA"},
{ value: "M813",	text: "OSTEOPOROSIS POR MALABSORCION POSTQUIRURGICA, SIN FRACTURA PATOLOGICA"},
{ value: "M814",	text: "OSTEOPOROSIS INDUCIDA POR DROGAS, SIN FRACTURA PATOLOGICA"},
{ value: "M815",	text: "OSTEOPOROSIS IDIOPATICA, SIN FRACTURA PATOLOGICA"},
{ value: "M816",	text: "OSTEOPOROSIS LOCALIZADA [LEQUESNE], SIN FRACTURA PATOLOGICA"},
{ value: "M818",	text: "OTRAS OSTEOPOROSIS, SIN FRACTURA PATOLOGICA"},
{ value: "M819",	text: "OSTEOPOROSIS NO ESPECIFICADA, SIN FRACTURA PATOLOGICA"},
{ value: "M820",	text: "OSTEOPOROSIS EN MIELOMATOSIS MULTIPLE (C90.0†)"},
{ value: "M821",	text: "OSTEOPOROSIS EN TRASTORNOS ENDOCRINOS (E00-E34†)"},
{ value: "M828",	text: "OSTEOPOROSIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "M830",	text: "OSTEOMALACIA PUERPERAL"},
{ value: "M831",	text: "OSTEOMALACIA SENIL"},
{ value: "M832",	text: "OSTEOMALACIA DEL ADULTO DEBIDA A MALABSORCION"},
{ value: "M833",	text: "OSTEOMALACIA DEL ADULTO DEBIDA A DESNUTRICION"},
{ value: "M834",	text: "ENFERMEDAD DE LOS HUESOS POR ALUMINIO"},
{ value: "M835",	text: "OTRAS OSTEOMALACIAS DEL ADULTO INDUCIDAS POR DROGAS"},
{ value: "M838",	text: "OTRAS OSTEOMALACIAS DEL ADULTO"},
{ value: "M839",	text: "OSTEOMALACIA DEL ADULTO, NO ESPECIFICADA"},
{ value: "M840",	text: "CONSOLIDACION DEFECTUOSA DE FRACTURA"},
{ value: "M841",	text: "FALTA DE CONSOLIDACION DE FRACTURA [SEUDOARTROSIS]"},
{ value: "M842",	text: "CONSOLIDACION RETARDADA DE FRACTURA"},
{ value: "M843",	text: "FRACTURA POR TENSION, NO CLASIFICADA EN OTRA PARTE"},
{ value: "M844",	text: "FRACTURA PATOLOGICA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "M848",	text: "OTROS TRASTORNOS DE LA CONTINUIDAD DEL HUESO"},
{ value: "M849",	text: "TRASTORNO DE LA CONTINUIDAD DEL HUESO, NO ESPECIFICADO"},
{ value: "M850",	text: "DISPLASIA FIBROSA (MONOSTOTICA)"},
{ value: "M851",	text: "FLUOROSIS DEL ESQUELETO"},
{ value: "M852",	text: "HIPEROSTOSIS DEL CRANEO"},
{ value: "M853",	text: "OSTEITIS CONDENSANTE"},
{ value: "M854",	text: "QUISTE OSEO SOLITARIO"},
{ value: "M855",	text: "QUISTE OSEO ANEURISMATICO"},
{ value: "M856",	text: "OTROS QUISTES OSEOS"},
{ value: "M858",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA DENSIDAD Y DE LA ESTRUCTURA OSEAS"},
{ value: "M859",	text: "TRASTORNO DE LA DENSIDAD Y DE LA ESTRUCTURA OSEAS, NO ESPECIFICADO"},
{ value: "M860",	text: "OSTEOMIELITIS HEMATOGENA AGUDA"},
{ value: "M861",	text: "OTRAS OSTEOMIELITIS AGUDAS"},
{ value: "M862",	text: "OSTEOMIELITIS SUBAGUDA"},
{ value: "M863",	text: "OSTEOMIELITIS MULTIFOCAL CRONICA"},
{ value: "M864",	text: "OSTEOMIELITIS CRONICA CON DRENAJE DEL SENO"},
{ value: "M865",	text: "OTRAS OSTEOMIELITIS HEMATOGENAS CRONICAS"},
{ value: "M866",	text: "OTRAS OSTEOMIELITIS CRONICAS"},
{ value: "M868",	text: "OTRAS OSTEOMIELITIS"},
{ value: "M869",	text: "OSTEOMIELITIS, NO ESPECIFICADA"},
{ value: "M870",	text: "NECROSIS ASEPTICA IDIOPATICA OSEA"},
{ value: "M871",	text: "OSTEONECROSIS DEBIDA A DROGAS"},
{ value: "M872",	text: "OSTEONECROSIS DEBIDA A TRAUMATISMO PREVIO"},
{ value: "M873",	text: "OTRAS OSTEONECROSIS SECUNDARIAS"},
{ value: "M878",	text: "OTRAS OSTEONECROSIS"},
{ value: "M879",	text: "OSTEONECROSIS, NO ESPECIFICADA"},
{ value: "M880",	text: "ENFERMEDAD DE PAGET DEL CRANEO"},
{ value: "M888",	text: "ENFERMEDAD DE PAGET DE OTROS HUESOS"},
{ value: "M889",	text: "ENFERMEDAD OSEA DE PAGET, HUESOS NO ESPECIFICADOS"},
{ value: "M890",	text: "ALGONEURODISTROFIA"},
{ value: "M891",	text: "DETENCION DEL CRECIMIENTO EPIFISARIO"},
{ value: "M892",	text: "OTROS TRASTORNOS DEL DESARROLLO Y CRECIMIENTO OSEO"},
{ value: "M893",	text: "HIPERTROFIA DEL HUESO"},
{ value: "M894",	text: "OTRAS OSTEOARTROPATIAS HIPERTROFICAS"},
{ value: "M895",	text: "OSTEOLISIS"},
{ value: "M896",	text: "OSTEOPATIA A CONSECUENCIA DE POLIOMIELITIS"},
{ value: "M898",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL HUESO"},
{ value: "M899",	text: "TRASTORNO DEL HUESO, NO ESPECIFICADO"},
{ value: "M900",	text: "TUBERCULOSIS OSEAS (A18.0†)"},
{ value: "M901",	text: "PERIOSTITIS EN OTRAS ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE"},
{ value: "M902",	text: "OSTEOPATIA EN OTRAS ENFERMEDADES INFECCIOSAS CLASIFICADAS EN OTRA PARTE"},
{ value: "M903",	text: "OSTEONECROSIS EN LA ENFERMEDAD CAUSADA POR DESCOMPRESION (T70.3†)"},
{ value: "M904",	text: "OSTEONECROSIS DEBIDA A HEMOGLOBINOPATIA (D50-D64†)"},
{ value: "M905",	text: "OSTEONECROSIS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "M906",	text: "OSTEITIS DEFORMANTE EN ENFERMEDAD NEOPLASICA (C00-D48†)"},
{ value: "M907",	text: "FRACTURA OSEA EN ENFERMEDAD NEOPLASICA (C00-D48†)"},
{ value: "M908",	text: "OSTEOPATIA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "M910",	text: "OSTEOCONDROSIS JUVENIL DE LA PELVIS"},
{ value: "M911",	text: "OSTEOCONDROSIS JUVENIL DE LA CABEZA DEL FEMUR [LEGG-CALVE- PERTHES]"},
{ value: "M912",	text: "COXA PLANA"},
{ value: "M913",	text: "PSEUDOCOXALGIA"},
{ value: "M918",	text: "OTRAS OSTEOCONDROSIS JUVENILES DE LA CADERA Y DE LA PELVIS"},
{ value: "M919",	text: "OSTEOCONDROSIS JUVENIL DE LA CADERA Y DE LA PELVIS, SIN OTRA ESPECIFICACION"},
{ value: "M920",	text: "OSTEOCONDROSIS JUVENIL DEL HUMERO"},
{ value: "M921",	text: "OSTEOCONDROSIS JUVENIL DEL CUBITO Y DEL RADIO"},
{ value: "M922",	text: "OSTEOCONDROSIS JUVENIL DE LA MANO"},
{ value: "M923",	text: "OTRAS OSTEOCONDROSIS JUVENILES DEL MIEMBRO SUPERIOR"},
{ value: "M924",	text: "OSTEOCONDROSIS JUVENIL DE LA ROTULA"},
{ value: "M925",	text: "OSTEOCONDROSIS JUVENIL DE LA TIBIA Y DEL PERONE"},
{ value: "M926",	text: "OSTEOCONDROSIS JUVENIL DEL TARSO"},
{ value: "M927",	text: "OSTEOCONDROSIS JUVENIL DEL METATARSO"},
{ value: "M928",	text: "OTRAS OSTEOCONDROSIS JUVENILES ESPECIFICADAS"},
{ value: "M929",	text: "OSTEOCONDROSIS JUVENIL, NO ESPECIFICADA"},
{ value: "M930",	text: "DESLIZAMIENTO DE LA EPIFISIS FEMORAL SUPERIOR (NO TRAUMATICO)"},
{ value: "M931",	text: "ENFERMEDAD DE KIENBÖCK DEL ADULTO"},
{ value: "M932",	text: "OSTEOCONDROSIS DISECANTE"},
{ value: "M938",	text: "OTRAS OSTEOCONDROPATIAS ESPECIFICADAS"},
{ value: "M939",	text: "OSTEOCONDROPATIA, NO ESPECIFICADA"},
{ value: "M940",	text: "SINDROME DE LA ARTICULACION CONDROCOSTAL [TIETZE]"},
{ value: "M941",	text: "POLICONDRITIS RECIDIVANTE"},
{ value: "M942",	text: "CONDROMALACIA"},
{ value: "M943",	text: "CONDROLISIS"},
{ value: "M948",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL CARTILAGO"},
{ value: "M949",	text: "TRASTORNO DEL CARTILAGO, NO ESPECIFICADO"},
{ value: "M950",	text: "DEFORMIDAD ADQUIRIDA DE LA NARIZ"},
{ value: "M951",	text: "OREJA EN COLIFLOR"},
{ value: "M952",	text: "OTRAS DEFORMIDADES ADQUIRIDAS DE LA CABEZA"},
{ value: "M953",	text: "DEFORMIDAD ADQUIRIDA DEL CUELLO"},
{ value: "M954",	text: "DEFORMIDAD ADQUIRIDA DE COSTILLAS Y TORAX"},
{ value: "M955",	text: "DEFORMIDAD ADQUIRIDA DE LA PELVIS"},
{ value: "M958",	text: "OTRAS DEFORMIDADES ADQUIRIDA ESPECIFICADAS DEL SISTEMA OSTEOMUSCULAR"},
{ value: "M959",	text: "DEFORMIDAD ADQUIRIDA DEL SISTEMA OSTEOMUSCULAR, NO ESPECIFICADA"},
{ value: "M960",	text: "SEUDOARTROSIS CONSECUTIVA A FUSION O ARTRODESIS"},
{ value: "M961",	text: "SINDROME POSTLAMINECTOMIA, NO CLASIFICADO EN OTRA PARTE"},
{ value: "M962",	text: "CIFOSIS POSTRADIACION"},
{ value: "M963",	text: "CIFOSIS POSTLAMINECTOMIA"},
{ value: "M964",	text: "LORDOSIS POSTQUIRURGICA"},
{ value: "M965",	text: "ESCOLIOSIS POSTRRADIACION"},
{ value: "M966",	text: "FRACTURA DE HUESO POSTERIOR A INSERCION O IMPLANTE ORTOPEDICO, PROTESIS ARTICULAR O PLACA OSEA"},
{ value: "M968",	text: "OTROS TRASTORNOS OSTEOMUSCULARES CONSECUTIVOS A PROCEDIMIENTOS"},
{ value: "M969",	text: "TRASTORNOS OSTEOMUSCULARES NO ESPECIFICADOS CONSECUTIVOS A PROCEDIMIENTOS"},
{ value: "M990",	text: "DISFUNCION SEGMENTAL O SOMATICA"},
{ value: "M991",	text: "COMPLEJO DE SUBLUXACION (VERTEBRAL)"},
{ value: "M992",	text: "SUBLUXACION CON ESTENOSIS DEL CANAL NEURAL"},
{ value: "M993",	text: "ESTENOSIS OSEA DEL CANAL NEURAL"},
{ value: "M994",	text: "ESTENOSIS DEL CANAL NEURAL POR TEJIDO CONJUNTIVO"},
{ value: "M995",	text: "ESTENOSIS DEL CANAL NEURAL POR DISCO INTERVERTEBRAL"},
{ value: "M996",	text: "ESTENOSIS OSEA Y SUBLUXACION DE LOS AGUJEROS INTERVERTEBRALES"},
{ value: "M997",	text: "ESTENOSIS DE LOS AGUJEROS INTERVERTEBRALES POR TEJIDO CONJUNTIVO O POR DISCO INTERVERTEBRAL"},
{ value: "M998",	text: "OTRAS LESIONES BIOMECANICAS"},
{ value: "M999",	text: "LESION BIOMECANICA, NO ESPECIFICADA"},
{ value: "N000",	text: "SINDROME NEFRÍTICO AGUDO: ANOMALIA GLOMERULAR MINIMA"},
{ value: "N001",	text: "SINDROME NEFRÍTICO AGUDO: LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS"},
{ value: "N002",	text: "SINDROME NEFRITICO AGUDO: GLOMERULONEFRITIS MEMBRANOSA DIFUSA"},
{ value: "N003",	text: "SINDROME NEFRITICO AGUDO: GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA"},
{ value: "N004",	text: "SINDROME NEFRITICO AGUDO: GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA"},
{ value: "N005",	text: "SINDROME NEFRITICO AGUDO: GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA"},
{ value: "N006",	text: "SINDROME NEFRITICO AGUDO: ENFERMEDAD POR DEPOSITOS DENSOS"},
{ value: "N007",	text: "SINDROME NEFRITICO AGUDO: GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA"},
{ value: "N008",	text: "SINDROME NEFRITICO AGUDO: OTRAS"},
{ value: "N009",	text: "SINDROME NEFRITICO AGUDO: NO ESPECIFICADA"},
{ value: "N010",	text: "SINDROME NEFRITICO RAPIDAMENTE PROGRESIVO: ANOMALIA GLOMERULAR MINIMA"},
{ value: "N011",	text: "SINDROME NEFRÍTICO RAPIDAMENTE PROGRESIVO: LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS"},
{ value: "N012",	text: "SINDROME NEFRITICO RAPIDAMENTE PROGRESIVO: GLOMERULONEFRITIS MEMBRANOSA DIFUSA"},
{ value: "N013",	text: "SINDROME NEFRITICO RAPIDAMENTE PROGRESIVO: GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA"},
{ value: "N014",	text: "SINDROME NEFRITICO RAPIDAMENTE PROGRESIVO: GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA"},
{ value: "N015",	text: "SINDROME NEFRITICO RAPIDAMENTE PROGRESIVO: GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA"},
{ value: "N016",	text: "SINDROME NEFRITICO RAPIDAMENTE PROGRESIVO: ENFERMEDAD POR DEPOSITOS DENSOS"},
{ value: "N017",	text: "SINDROME NEFRITICO RAPIDAMENTE PROGRESIVO: GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA"},
{ value: "N018",	text: "SINDROME NEFRITICO RAPIDAMENTE PROGRESIVO: OTRAS"},
{ value: "N019",	text: "SINDROME NEFRITICO RAPIDAMENTE PROGRESIVO: NO ESPECIFICADA"},
{ value: "N020",	text: "HEMATURIA RECURRENTE Y PERSISTENTE: ANOMALIA GLOMERULAR MINIMA"},
{ value: "N021",	text: "HEMATURIA RECURRENTE Y PERSISTENTE: LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS"},
{ value: "N022",	text: "HEMATURIA RECURRENTE Y PERSISTENTE: GLOMERULONEFRITIS MEMBRANOSA DIFUSA"},
{ value: "N023",	text: "HEMATURIA RECURRENTE Y PERSISTENTE: GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA"},
{ value: "N024",	text: "HEMATURIA RECURRENTE Y PERSISTENTE: GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA"},
{ value: "N025",	text: "HEMATURIA RECURRENTE Y PERSISTENTE: GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA"},
{ value: "N026",	text: "HEMATURIA RECURRENTE Y PERSISTENTE: ENFERMEDAD POR DEPOSITOS DENSOS"},
{ value: "N027",	text: "HEMATURIA RECURRENTE Y PERSISTENTE: GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA"},
{ value: "N028",	text: "HEMATURIA RECURRENTE Y PERSISTENTE: OTRAS"},
{ value: "N029",	text: "HEMATURIA RECURRENTE Y PERSISTENTE: NO ESPECIFICADA"},
{ value: "N030",	text: "SINDROME NEFRITICO CRONICO: ANOMALIA GLOMERULAR MINIMA"},
{ value: "N031",	text: "SINDROME NEFRITICO CRONICO: LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS"},
{ value: "N032",	text: "SINDROME NEFRITICO CRONICO: GLOMERULONEFRITIS MEMBRANOSA DIFUSA"},
{ value: "N033",	text: "SINDROME NEFRITICO CRONICO: GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA"},
{ value: "N034",	text: "SINDROME NEFRITICO CRONICO: GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA"},
{ value: "N035",	text: "SINDROME NEFRITICO CRONICO: GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA"},
{ value: "N036",	text: "SINDROME NEFRITICO CRONICO: ENFERMEDAD POR DEPOSITOS DENSOS"},
{ value: "N037",	text: "SINDROME NEFRITICO CRONICO: GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA"},
{ value: "N038",	text: "SINDROME NEFRITICO CRONICO: OTRAS"},
{ value: "N039",	text: "SINDROME NEFRITICO CRONICO: NO ESPECIFICADA"},
{ value: "N040",	text: "SINDROME NEFROTICO: ANOMALIA GLOMERULAR MINIMA"},
{ value: "N041",	text: "SINDROME NEFROTICO: LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS"},
{ value: "N042",	text: "SINDROME NEFROTICO: GLOMERULONEFRITIS MEMBRANOSA DIFUSA"},
{ value: "N043",	text: "SINDROME NEFROTICO: GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA"},
{ value: "N044",	text: "SINDROME NEFROTICO: GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA"},
{ value: "N045",	text: "SINDROME NEFROTICO: GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA"},
{ value: "N046",	text: "SINDROME NEFROTICO: ENFERMEDAD POR DEPOSITOS DENSOS"},
{ value: "N047",	text: "SINDROME NEFROTICO: GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA"},
{ value: "N048",	text: "SINDROME NEFROTICO: OTRAS"},
{ value: "N049",	text: "SINDROME NEFROTICO: NO ESPECIFICADA"},
{ value: "N050",	text: "SINDROME NEFRITICO NO ESPECIFICADO: ANOMALIA GLOMERULAR MINIMA"},
{ value: "N051",	text: "SINDROME NEFRITICO NO ESPECIFICADO: LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS"},
{ value: "N052",	text: "SINDROME NEFRITICO NO ESPECIFICADO: GLOMERULONEFRITIS MEMBRANOSA DIFUSA"},
{ value: "N053",	text: "SINDROME NEFRITICO NO ESPECIFICADO: GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA"},
{ value: "N054",	text: "SINDROME NEFRITICO NO ESPECIFICADO: GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA"},
{ value: "N055",	text: "SINDROME NEFRITICO NO ESPECIFICADO: GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA"},
{ value: "N056",	text: "SINDROME NEFRITICO NO ESPECIFICADO: ENFERMEDAD POR DEPOSITOS DENSOS"},
{ value: "N057",	text: "SINDROME NEFRITICO NO ESPECIFICADO: GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA"},
{ value: "N058",	text: "SINDROME NEFRITICO NO ESPECIFICADO: OTRAS"},
{ value: "N059",	text: "SINDROME NEFRITICO NO ESPECIFICADO: NO ESPECIFICADA"},
{ value: "N060",	text: "PROTEINURIA AISLADA CON LESION MORFOLOGICA ESPECIFICADA: ANOMALIA GLOMERULAR MINIMA"},
{ value: "N061",	text: "PROTEINURIA AISLADA CON LESION MORFOLOGICA ESPECIFICADA LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS"},
{ value: "N062",	text: "PROTEINURIA AISLADA CON LESION MORFOLOGICA ESPECIFICADA: GLOMERULONEFRITIS MEMBRANOSA DIFUSA"},
{ value: "N063",	text: "PROTEINURIA AISLADA CON LESION MORFOLOGICA ESPECIFICADA: GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA"},
{ value: "N064",	text: "PROTEINURIA AISLADA CON LESION MORFOLOGICA ESPECIFICADA: GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA"},
{ value: "N065",	text: "PROTEINURIA AISLADA CON LESION MORFOLOGICA ESPECIFICADA: GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA"},
{ value: "N066",	text: "PROTEINURIA AISLADA CON LESION MORFOLOGICA ESPECIFICADA: ENFERMEDAD POR DEPOSITOS DENSOS"},
{ value: "N067",	text: "PROTEINURIA AISLADA CON LESION MORFOLOGICA ESPECIFICADA: GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA"},
{ value: "N068",	text: "PROTEINURIA AISLADA CON LESION MORFOLOGICA ESPECIFICADA: OTRAS"},
{ value: "N069",	text: "PROTEINURIA AISLADA CON LESION MORFOLOGICA ESPECIFICADA: NO ESPECIFICADA"},
{ value: "N070",	text: "NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE: ANOMALIA GLOMERULAR MINIMA"},
{ value: "N071",	text: "NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE: LESIONES GLOMERULARES FOCALES Y SEGMENTARIAS"},
{ value: "N072",	text: "NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE: GLOMERULONEFRITIS MEMBRANOSA DIFUSA"},
{ value: "N073",	text: "NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE: GLOMERULONEFRITIS PROLIFERATIVA MESANGIAL DIFUSA"},
{ value: "N074",	text: "NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE: GLOMERULONEFRITIS PROLIFERATIVA ENDOCAPILAR DIFUSA"},
{ value: "N075",	text: "NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE: GLOMERULONEFRITIS MESANGIOCAPILAR DIFUSA"},
{ value: "N076",	text: "NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE: ENFERMEDAD POR DEPOSITOS DENSOS"},
{ value: "N077",	text: "NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE: GLOMERULONEFRITIS DIFUSA EN MEDIA LUNA"},
{ value: "N078",	text: "NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE: OTRAS"},
{ value: "N079",	text: "NEFROPATIA HEREDITARIA, NO CLASIFICADA EN OTRA PARTE: NO ESPECIFICADA"},
{ value: "N080",	text: "TRASTORNOS GLOMERULARES EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "N081",	text: "TRASTORNOS GLOMERULARES EN ENFERMEDADES NEOPLASICAS"},
{ value: "N082",	text: "TRASTORNOS GLOMERULARES EN ENFERMEDADES DE LA SANGRE Y OTROS TRASTORNOS QUE AFECTAN AL MECANISMO INMUNITARIO"},
{ value: "N083",	text: "TRASTORNOS GLOMERULARES EN DIABETES MELLITUS (E10-E14† CON CUARTO CARÁCTER COMUN .2)"},
{ value: "N084",	text: "TRASTORNOS GLOMERULARES EN OTRAS ENFERMEDADES ENDOCRINAS, NUTRICIONALES Y METABOLICAS"},
{ value: "N085",	text: "TRASTORNOS GLOMERULARES EN TRASTORNOS SISTEMICOS DEL TEJIDO CONJUNTIVO"},
{ value: "N088",	text: "TRASTORNOS GLOMERULARES EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "N10X",	text: "NEFRITIS TUBULOINTERSTICIAL AGUDA"},
{ value: "N110",	text: "PIELONEFRITIS CRONICA NO OBSTRUCTIVA ASOCIADA CON REFLUJO"},
{ value: "N111",	text: "PIELONEFRITIS CRONICA OBSTRUCTIVA"},
{ value: "N118",	text: "OTRAS NEFRITIS TUBULOINTERSTICIALES CRONICAS"},
{ value: "N119",	text: "NEFRITIS TUBULOINTERSTICIAL CRONICA, SIN OTRA ESPECIFICACION"},
{ value: "N12X",	text: "NEFRITIS TUBULOINTERSTICIAL, NO ESPECIFICADA COMO AGUDA O CRONICA"},
{ value: "N130",	text: "HIDRONEFROSIS CON OBSTRUCCION DE LA UNION URETERO-PELVICA"},
{ value: "N131",	text: "HIDRONEFROSIS CON ESTRECHEZ URETERAL, NO CLASIFICADA EN OTRA PARTE"},
{ value: "N132",	text: "HIDRONEFROSIS CON OBSTRUCCION POR CALCULOS DEL RIÑON Y DEL URETER"},
{ value: "N133",	text: "OTRAS HIDRONEFROSIS Y LAS NO ESPECIFICADAS"},
{ value: "N134",	text: "HIDROURETER"},
{ value: "N135",	text: "TORSION Y ESTRECHEZ DEL URETER SIN HIDRONEFROSIS"},
{ value: "N136",	text: "PIONEFROSIS"},
{ value: "N137",	text: "UROPATIA ASOCIADA CON REFLUJO VESICOURETERAL"},
{ value: "N138",	text: "OTRAS UROPATIAS OBSTRUCTIVAS POR REFLUJO"},
{ value: "N139",	text: "UROPATIA OBSTRUCTIVA POR REFLUJO, SIN OTRA ESPECIFICACION"},
{ value: "N140",	text: "NEFROPATIA INDUCIDA POR ANALGESICOS"},
{ value: "N141",	text: "NEFROPATIA INDUCIDA POR OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS"},
{ value: "N142",	text: "NEFROPATIA INDUCIDA POR DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS"},
{ value: "N143",	text: "NEFROPATIA INDUCIDA POR METALES PESADOS"},
{ value: "N144",	text: "NEFROPATIA TOXICA, NO ESPECIFICADA EN OTRA PARTE"},
{ value: "N150",	text: "NEFROPATIA DE LOS BALCANES"},
{ value: "N151",	text: "ABSCESO RENAL Y PERIRRENAL"},
{ value: "N158",	text: "OTRAS ENFERMEDADES RENALES TUBULOINTERSTICIALES ESPECIFICADAS"},
{ value: "N159",	text: "ENFERMEDAD RENAL TUBULOINTERSTICIAL, NO ESPECIFICADA"},
{ value: "N160",	text: "TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PATRTE"},
{ value: "N161",	text: "TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES NEOPLASICAS"},
{ value: "N162",	text: "TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES DE LA SANGRE Y EN TRASTORNOS QUE AFECTAN EL MECANISMO INMUNITARIO"},
{ value: "N163",	text: "TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES METABOLICAS"},
{ value: "N164",	text: "TRASTORNOS RENALES TUBULOINTERSTICIALES EN ENFERMEDADES DEL TEJIDO CONJUNTIVO"},
{ value: "N165",	text: "TRASTORNOS RENALES TUBULOINTERSTICIALES EN RECHAZO DE TRASPLANTE (T86.-†)"},
{ value: "N168",	text: "TRASTORNOS RENALES TUBULOINTERSTICIALES EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "N170",	text: "INSUFICIENCIA RENAL AGUDA CON NECROSIS TUBULAR"},
{ value: "N171",	text: "INSUFICIENCIA RENAL AGUDA CON NECROSIS CORTICAL AGUDA"},
{ value: "N172",	text: "INSUFICIENCIA RENAL AGUDA CON NECROSIS MEDULAR"},
{ value: "N178",	text: "OTRAS INSUFICIENCIAS RENALES AGUDAS"},
{ value: "N179",	text: "INSUFICIENCIA RENAL AGUDA, NO ESPECIFICADA"},
{ value: "N180",	text: "INSUFICIENCIA RENAL TERMINAL"},
{ value: "N188",	text: "OTRAS INSUFICIENCIAS RENALES CRONICAS"},
{ value: "N189",	text: "INSUFICIENCIA RENAL CRONICA, NO ESPECIFICADA"},
{ value: "N19X",	text: "INSUFICIENCIA RENAL NO ESPECIFICADA"},
{ value: "N200",	text: "CALCULO DEL RIÑON"},
{ value: "N201",	text: "CALCULO DEL URETER"},
{ value: "N202",	text: "CALCULO DEL RIÑON CON CALCULO DEL URETER"},
{ value: "N209",	text: "CALCULO URINARIO, NO ESPECIFICADO"},
{ value: "N210",	text: "CALCULO EN LA VEJIGA"},
{ value: "N211",	text: "CALCULO EN LA URETRA"},
{ value: "N218",	text: "OTROS CALCULOS DE LAS VIAS URINARIAS INFERIORES"},
{ value: "N219",	text: "CALCULO DE LAS VIAS URINARIAS INFERIORES, NO ESPECIFICADO"},
{ value: "N220",	text: "LITIASIS URINARIA EN ESQUISTOSOMIASIS [BILHARZIASIS] (B65.-†)"},
{ value: "N228",	text: "CALCULO DE LAS VIAS URINARIAS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "N23X",	text: "COLICO RENAL, NO ESPECIFICADO"},
{ value: "N250",	text: "OSTEODISTROFIA RENAL"},
{ value: "N251",	text: "DIABETES INSIPIDA NEFROGENA"},
{ value: "N258",	text: "OTROS TRASTORNOS RESULTANTES DE LA FUNCION TUBULAR RENAL ALTERADA"},
{ value: "N259",	text: "TRASTORNO NO ESPECIFICADO, RESULTANTE DE LA FUNCION TUBULAR RENAL ALTERADA"},
{ value: "N26X",	text: "RIÑON CONTRAIDO, NO ESPECIFICADO"},
{ value: "N270",	text: "RIÑON PEQUEÑO, UNILATERAL"},
{ value: "N271",	text: "RIÑON PEQUEÑO, BILATERAL"},
{ value: "N279",	text: "RIÑON PEQUEÑO, NO ESPECIFICADO"},
{ value: "N280",	text: "ISQUEMIA E INFARTO DEL RIÑON"},
{ value: "N281",	text: "QUISTE DE RIÑON, ADQUIRIDO"},
{ value: "N288",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL RIÑON Y DEL URETER"},
{ value: "N289",	text: "TRASTORNO DEL RIÑON Y DEL URETER, NO ESPECIFICADO"},
{ value: "N290",	text: "SIFILIS RENAL TARDIA (A52.7†)"},
{ value: "N291",	text: "OTROS TRASTORNOS DEL RIÑON Y DEL URETER EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "N298",	text: "OTROS TRASTORNOS DEL RIÑON Y DEL URETER EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "N300",	text: "CISTITIS AGUDAS"},
{ value: "N301",	text: "CISTITIS INTERSTICIAL (CRONICA)"},
{ value: "N302",	text: "OTRAS CISTITIS CRONICAS"},
{ value: "N303",	text: "TRIGONITIS"},
{ value: "N304",	text: "CISTITIS POR IRRADIACION"},
{ value: "N308",	text: "OTRAS CISTITIS"},
{ value: "N309",	text: "CISTITIS, NO ESPECIFICADA"},
{ value: "N310",	text: "VEJIGA NEUROPATICA NO INHIBIDA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "N311",	text: "VEJIGA NEUROPATICA REFLEJA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "N312",	text: "VEJIGA NEUROPATICA FLACIDA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "N318",	text: "OTRAS DISFUNCIONES NEUROMUSCULARES DE LA VEJIGA"},
{ value: "N319",	text: "DISFUNCION NEUROMUSCULAR DE LA VEJIGA, NO ESPECIFICADA"},
{ value: "N320",	text: "OBSTRUCCION DE CUELLO DE LA VEJIGA"},
{ value: "N321",	text: "FISTULA VESICOINTESTINAL"},
{ value: "N322",	text: "FISTULA DE LA VEJIGA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "N323",	text: "DIVERTICULO DE LA VEJIGA"},
{ value: "N324",	text: "RUPTURA DE LA VEJIGA, NO TRAUMATICA"},
{ value: "N328",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA VEJIGA"},
{ value: "N329",	text: "TRASTORNO DE LA VEJIGA, NO ESPECIFICADO"},
{ value: "N330",	text: "CISTITIS TUBERCULOSA (A18.1†)"},
{ value: "N338",	text: "TRASTORNOS DE LA VEJIGA EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "N340",	text: "ABSCESO URETRAL"},
{ value: "N341",	text: "URETRITIS NO ESPECIFICADA"},
{ value: "N342",	text: "OTRAS URETRITIS"},
{ value: "N343",	text: "SINDROME URETRAL, NO ESPECIFICADO"},
{ value: "N350",	text: "ESTRECHEZ URETRAL POSTRAUMATICA"},
{ value: "N351",	text: "ESTRECHEZ URETRAL POSTINFECCION, NO CLASIFICADA EN OTRA PARTE"},
{ value: "N358",	text: "OTRAS ESTRECHECES URETRALES"},
{ value: "N359",	text: "ESTRECHEZ URETRAL, NO ESPECIFICADA"},
{ value: "N360",	text: "FISTULA DE LA URETRA"},
{ value: "N361",	text: "DIVERTICULO DE LA URETRA"},
{ value: "N362",	text: "CARUNCULA URETRAL"},
{ value: "N363",	text: "PROLAPSO DE LA MUCOSA URETRAL"},
{ value: "N368",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA URETRA"},
{ value: "N369",	text: "TRASTORNO DE LA URETRA, NO ESPECIFICADO"},
{ value: "N370",	text: "URETRITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "N378",	text: "OTROS TRASTORNOS URETRALES EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "N390",	text: "INFECCION DE VIAS URINARIAS, SITIO NO ESPECIFICADO"},
{ value: "N391",	text: "PROTEINURIA PERSISTENTE, NO ESPECIFICADA"},
{ value: "N392",	text: "PROTEINURIA ORTOSTATICA, NO ESPECIFICADA"},
{ value: "N393",	text: "INCONTINENCIA URINARIA POR TENSION"},
{ value: "N394",	text: "OTRAS INCONTINENCIAS URINARIAS ESPECIFICADAS"},
{ value: "N398",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL SISTEMA URINARIO"},
{ value: "N399",	text: "TRASTORNO DEL SISTEMA URINARIO, NO ESPECIFICADO"},
{ value: "N40X",	text: "HIPERPLASIA DE LA PROSTATA"},
{ value: "N410",	text: "PROSTATITIS AGUDA"},
{ value: "N411",	text: "PROSTATITIS CRONICA"},
{ value: "N412",	text: "ABSCESO DE LA PROSTATA"},
{ value: "N413",	text: "PROSTATOCISTITIS"},
{ value: "N418",	text: "OTRAS ENFERMEDADES INFLAMATORIAS DE LA PROSTATA"},
{ value: "N419",	text: "ENFERMEDAD INFLAMATORIA DE LA PROSTATA, NO ESPECIFICADA"},
{ value: "N420",	text: "CALCULO DE LA PROSTATA"},
{ value: "N421",	text: "CONGESTION Y HEMORRAGIA DE LA PROSTATA"},
{ value: "N422",	text: "ATROFIA DE LA PROSTATA"},
{ value: "N428",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA PROSTATA"},
{ value: "N429",	text: "TRASTORNO DE LA PROSTATA, NO ESPECIFICADO"},
{ value: "N430",	text: "HIDROCELE ENQUISTADO"},
{ value: "N431",	text: "HIDROCELE INFECTADO"},
{ value: "N432",	text: "OTRAS HIDROCELES"},
{ value: "N433",	text: "HIDROCELE, NO ESPECIFICADO"},
{ value: "N434",	text: "ESPERMATOCELE"},
{ value: "N44X",	text: "TORSION DEL TESTICULO"},
{ value: "N450",	text: "ORQUITIS, EPIDIDIMITIS Y ORQUIEPIDIDIMITIS CON ABSCESO"},
{ value: "N459",	text: "ORQUITIS, EPIDIDIMITIS Y ORQUIEPIDIDIMITIS SIN ABSCESO"},
{ value: "N46X",	text: "ESTERILIDAD EN EL VARON"},
{ value: "N47X",	text: "PREPUCIO REDUNDANTE, FIMOSIS Y PARAFIMOSIS"},
{ value: "N480",	text: "LEUCOPLASIA DEL PENE"},
{ value: "N481",	text: "BALANOPOSTITIS"},
{ value: "N482",	text: "OTROS TRASTORNOS INFLAMATORIOS DEL PENE"},
{ value: "N483",	text: "PRIAPISMO"},
{ value: "N484",	text: "IMPOTENCIA DE ORIGEN ORGANICO"},
{ value: "N485",	text: "ULCERA DEL PENE"},
{ value: "N486",	text: "BALANITIS XEROTICA OBLITERANTE"},
{ value: "N488",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL PENE"},
{ value: "N489",	text: "TRASTORNO DEL PENE, NO ESPECIFICADO"},
{ value: "N490",	text: "TRASTORNOS INFLAMATORIOS DE VESICULA SEMINAL"},
{ value: "N491",	text: "TRASTORNOS INFLAMATORIOS DEL CORDON ESPERMATICO, TUNICA VAGINAL Y CONDUCTO DEFERENTE"},
{ value: "N492",	text: "TRASTORNOS INFLAMATORIOS DEL ESCROTO"},
{ value: "N498",	text: "OTROS TRASTORNOS INFLAMATORIOS DE LOS ORGANOS GENITALES MASCULINOS"},
{ value: "N499",	text: "TRASTORNO INFLAMATORIO DE ORGANO MASCULINO, NO ESPECIFICADO"},
{ value: "N500",	text: "ATROFIA DEL TESTICULO"},
{ value: "N501",	text: "TRASTORNOS VASCULARES DE LOS ORGANOS GENITALES MASCULINOS"},
{ value: "N508",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LOS ORGANOS GENITALES MASCULINOS"},
{ value: "N509",	text: "TRASTORNO NO ESPECIFICADO DE LOS ORGANOS GENITALES MASCULINOS"},
{ value: "N510",	text: "TRASTORNOS DE PROSTATA EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "N511",	text: "TRASTORNO DEL TESTICULO Y DEL EPIDIDIMO EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "N512",	text: "BALANITIS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "N518",	text: "OTROS TRASTORNOS DE LOS ORGANOS GENITALES MASCULINOS EN ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "N600",	text: "QUISTE SOLITARIO DE LA MAMA"},
{ value: "N601",	text: "MASTOPATIA QUISTICA DIFUSA"},
{ value: "N602",	text: "FIBROADENOSIS DE LA MAMA"},
{ value: "N603",	text: "FIBROESCLEROSIS DE MAMA"},
{ value: "N604",	text: "ECTASIA DE CONDUCTO MAMARIO"},
{ value: "N608",	text: "OTRAS DISPLASIAS MAMARIAS BENIGNAS"},
{ value: "N609",	text: "DISPLASIA MAMARIA BENIGNA, SIN OTRA ESPECIFICACION"},
{ value: "N61X",	text: "TRASTORNOS INFLAMATORIOS DE LA MAMA"},
{ value: "N62X",	text: "HIPERTROFIA DE LA MAMA"},
{ value: "N63X",	text: "MASA NO ESPECIFICADA EN LA MAMA"},
{ value: "N640",	text: "FISURA Y FISTULA DEL PEZON"},
{ value: "N641",	text: "NECROSIS GRASA DE LA MAMA"},
{ value: "N642",	text: "ATROFIA DE LA MAMA"},
{ value: "N643",	text: "GALACTORREA NO ASOCIADA CON EL PARTO"},
{ value: "N644",	text: "MASTODINIA"},
{ value: "N645",	text: "OTROS SIGNOS Y SINTOMAS RELATIVOS A LA MAMA"},
{ value: "N648",	text: "OTROS TRASTORNOS ESPECIFICADOS DE LA MAMA"},
{ value: "N649",	text: "TRASTORNO DE LA MAMA, NO ESPECIFICADO"},
{ value: "N700",	text: "SALPINGITIS Y OOFORITIS AGUDA"},
{ value: "N701",	text: "SALPINGITIS Y OOFORITIS CRONICA"},
{ value: "N709",	text: "SALPINGITIS Y OOFORITIS, NO ESPECIFICADAS"},
{ value: "N710",	text: "ENFERMEDAD INFLAMATORIA AGUDA DEL UTERO"},
{ value: "N711",	text: "ENFERMEDAD INFLAMATORIA CRONICA DEL UTERO"},
{ value: "N719",	text: "ENFERMEDAD INFLAMATORIA DEL UTERO, NO ESPECIFICADAS"},
{ value: "N72X",	text: "ENFERMEDAD INFLAMATORIA DEL CUELLO UTERINO"},
{ value: "N730",	text: "PARAMETRITIS Y CELULITIS PELVICA AGUDA"},
{ value: "N731",	text: "PARAMETRITIS Y CELULITIS PELVICA CRONICA"},
{ value: "N732",	text: "PARAMETRITIS Y CELULITIS PELVICA NO ESPECIFICADA"},
{ value: "N733",	text: "PERITONITIS PELVICA AGUDA, FEMENINA"},
{ value: "N734",	text: "PERITONITIS PELVICA CRONICA, FEMENINA"},
{ value: "N735",	text: "PERITONITIS PELVICA FEMENINA, NO ESPECIFICADA"},
{ value: "N736",	text: "ADHERENCIAS PERITONEALES PELVICAS FEMENINAS"},
{ value: "N738",	text: "OTRAS ENFERMEDADES INFLAMATORIAS PELVICAS FEMENINAS"},
{ value: "N739",	text: "ENFERMEDAD INFLAMATORIA PELVICA FEMENINA, NO ESPECIFICADA"},
{ value: "N740",	text: "INFECCION TUBERCULOSA DEL CUELLO DEL UTERO (A18.1†)"},
{ value: "N741",	text: "ENFERMEDAD INFLAMATORIA PELVICA FEMENINA POR TUBERCULOSIS (A18.1†)"},
{ value: "N742",	text: "ENFERMEDAD INFLAMATORIA PELVICA FEMENINA POR SIFILIS (A51.4†, A52.7†)"},
{ value: "N743",	text: "ENFERMEDAD INFLAMATORIA PELVICA FEMENINA POR GONOCOCOS (A54.2†)"},
{ value: "N744",	text: "ENFERMEDAD INFLAMATORIA PELVICA FEMENINA POR CLAMIDIAS (A56.1†)"},
{ value: "N748",	text: "TRASTORNOS INFLAMATORIOS PELVICOS FEMENINOS EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "N750",	text: "QUISTE DE LA GLANDULA DE BARTHOLIN"},
{ value: "N751",	text: "ABSCESO DE LA GLANDULA DE BARTHOLIN"},
{ value: "N758",	text: "OTRAS ENFERMEDADES DE LA GLANDULA DE BARTHOLIN"},
{ value: "N759",	text: "ENFERMEDAD DE LA GLANDULA DE BARTHOLIN, NO ESPECIFICADA"},
{ value: "N760",	text: "VAGINITIS AGUDA"},
{ value: "N761",	text: "VAGINITIS SUBAGUDA Y CRONICA"},
{ value: "N762",	text: "VULVITIS AGUDA"},
{ value: "N763",	text: "VULVITIS SUBAGUDA Y CRONICA"},
{ value: "N764",	text: "ABSCESO VULVAR"},
{ value: "N765",	text: "ULCERACION DE LA VAGINA"},
{ value: "N766",	text: "ULCERACION DE LA VULVA"},
{ value: "N768",	text: "OTRAS INFLAMACIONES ESPECIFICADAS DE LA VAGINA Y DE LA VULVA"},
{ value: "N770",	text: "ULCERACION DE LA VULVA EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "N771",	text: "VAGINITIS, VULVITIS Y VULVOVAGINITIS EN ENFERMEDADES INFECCIOSAS Y PARASITARIAS CLASIFICADAS EN OTRA PARTE"},
{ value: "N778",	text: "ULCERACION E INFLAMACION VULVOVAGINAL EN OTRAS ENFERMEDADES CLASIFICADAS EN OTRA PARTE"},
{ value: "N800",	text: "ENDOMETRIOSIS DEL UTERO"},
{ value: "N801",	text: "ENDOMETRIOSIS DEL OVARIO"},
{ value: "N802",	text: "ENDOMETRIOSIS DE LA TROMPA DE FALOPIO"},
{ value: "N803",	text: "ENDOMETRIOSIS DEL PERITONEO PELVICO"},
{ value: "N804",	text: "ENDOMETRIOSIS DEL TABIQUE RECTOVAGINAL Y DE LA VAGINA"},
{ value: "N805",	text: "ENDOMETRIOSIS DEL INTESTINO"},
{ value: "N806",	text: "ENDOMETRIOSIS EN CICATRIZ CUTANEA"},
{ value: "N808",	text: "OTRAS ENDOMETRIOSIS"},
{ value: "N809",	text: "ENDOMETRIOSIS, NO ESPECIFICADA"},
{ value: "N810",	text: "URETROCELE FEMENINO"},
{ value: "N811",	text: "CISTOCELE"},
{ value: "N812",	text: "PROLAPSO UTEROVAGINAL INCOMPLETO"},
{ value: "N813",	text: "PROLAPSO UTEROVAGINAL COMPLETO"},
{ value: "N814",	text: "PROLAPSO UTEROVAGINAL, SIN OTRA ESPECIFICACION"},
{ value: "N815",	text: "ENTEROCELE VAGINAL"},
{ value: "N816",	text: "RECTOCELE"},
{ value: "N818",	text: "OTROS PROLAPSOS GENITALES FEMENINOS"},
{ value: "N819",	text: "PROLAPSO GENITAL FEMENINO, NO ESPECIFICADO"},
{ value: "N820",	text: "FISTULA VESICOVAGINAL"},
{ value: "N821",	text: "OTRAS FISTULAS DE LAS VIAS GENITOURINARIAS FEMENINAS"},
{ value: "N822",	text: "FISTULA DE LA VAGINA AL INTESTINO DELGADO"},
{ value: "N823",	text: "FISTULA DE LA VAGINA AL INTESTINO GRUESO"},
{ value: "N824",	text: "OTRAS FISTULAS DEL TRACTO GENITAL FEMENINO AL TRACTO INTESTINAL"},
{ value: "N825",	text: "FISTULA DEL TRACTO GENITAL FEMENINO A LA PIEL"},
{ value: "N828",	text: "OTRAS FISTULAS DEL TRACTO GENITAL FEMENINO"},
{ value: "N829",	text: "FISTULA DEL TRACTO GENITAL FEMENINO, SIN OTRA ESPECIFICACION"},
{ value: "N830",	text: "QUISTE FOLICULAR DEL OVARIO"},
{ value: "N831",	text: "QUISTE DEL CUERPO AMARILLO"},
{ value: "N832",	text: "OTROS QUISTES OVARICOS Y LOS NO ESPECIFICADOS"},
{ value: "N833",	text: "ATROFIA ADQUIRIDA DEL OVARIO Y DE LA TROMPA FALOPIO"},
{ value: "N834",	text: "PROLAPSO Y HERNIA DEL OVARIO Y DE LA TROMPA DE FALOPIO"},
{ value: "N835",	text: "TORSION DE OVARIO, PEDICULO DE OVARIO Y TROMPA DE FALOPIO"},
{ value: "N836",	text: "HEMATOSALPINX"},
{ value: "N837",	text: "HEMATOMA DEL LIGAMENTO ANCHO"},
{ value: "N838",	text: "OTROS TRASTORNOS NO INFLAMATORIOS DEL OVARIO, DE LA TROMPA DE FALOPIO Y DEL LIGAMENTO ANCHO"},
{ value: "N839",	text: "ENFERMEDAD NO INFLAMATORIA DEL OVARIO, DE LA TROMPA DE FALOPIO Y DEL LIGAMENTO ANCHO, NO ESPECIFICADA"},
{ value: "N840",	text: "POLIPO DEL CUERPO DEL UTERO"},
{ value: "N841",	text: "POLIPO DEL CUELLO DEL UTERO"},
{ value: "N842",	text: "POLIPO DE LA VAGINA"},
{ value: "N843",	text: "POLIPO DE LA VULVA"},
{ value: "N848",	text: "POLIPO DE OTRAS PARTES DEL TRACTO GENITAL FEMENINO"},
{ value: "N849",	text: "POLIPO DEL TRACTO GENITAL FEMENINO, NO ESPECIFICADO"},
{ value: "N850",	text: "HIPERPLASIA DE GLANDULA DEL ENDOMETRIO"},
{ value: "N851",	text: "HIPERPLASIA ADENOMATOSA DEL ENDOMETRIO"},
{ value: "N852",	text: "HIPERTROFIA DEL UTERO"},
{ value: "N853",	text: "SUBINVOLUCION DEL UTERO"},
{ value: "N854",	text: "MALA POSICION DEL UTERO"},
{ value: "N855",	text: "INVERSION DEL UTERO"},
{ value: "N856",	text: "SINEQUIAS INTRAUTERINAS"},
{ value: "N857",	text: "HEMATOMETRA"},
{ value: "N858",	text: "OTROS TRASTORNOS NO INFLAMATORIOS ESPECIFICADOS DEL UTERO"},
{ value: "N859",	text: "TRASTORNO NO INFLAMATORIO DEL UTERO, NO ESPECIFICADO"},
{ value: "N86X",	text: "EROSION Y ECTROPION DEL CUELLO DEL UTERO"},
{ value: "N870",	text: "DISPLASIA CERVICAL LEVE"},
{ value: "N871",	text: "DISPLASIA CERVICAL MODERADA"},
{ value: "N872",	text: "DISPLASIA CERVICAL SEVERA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "N879",	text: "DISPLASIA DEL CUELLO DEL UTERO, NO ESPECIFICADA"},
{ value: "N880",	text: "LEUCOPLASIA DEL CUELLO DEL UTERO"},
{ value: "N881",	text: "LACERACION ANTIGUA DEL CUELLO DEL UTERO"},
{ value: "N882",	text: "ESTRECHEZ Y ESTENOSIS DEL CUELLO DEL UTERO"},
{ value: "N883",	text: "INCOMPETENCIA DEL CUELLO DEL UTERO"},
{ value: "N884",	text: "ELONGACION HIPERTROFICA DEL CUELLO DEL UTERO"},
{ value: "N888",	text: "OTROS TRASTORNOS NO INFLAMATORIOS ESPECIFICADOS DEL CUELLO DEL UTERO"},
{ value: "N889",	text: "TRASTORNO NO INFLAMATORIO DEL CUELLO DEL UTERO, NO ESPECIFICADO"},
{ value: "N890",	text: "DISPLASIA VAGINAL LEVE"},
{ value: "N891",	text: "DISPLASIA VAGINAL MODERADA"},
{ value: "N892",	text: "DISPLASIA VAGINAL SEVERA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "N893",	text: "DISPLASIA DE LA VAGINA, NO ESPECIFICADA"},
{ value: "N894",	text: "LEUCOPLASIA DE LA VAGINA"},
{ value: "N895",	text: "ESTRECHEZ Y ATRESIA DE LA VAGINA"},
{ value: "N896",	text: "ANILLO DE HIMEN ESTRECHO"},
{ value: "N897",	text: "HEMATOCOLPOS"},
{ value: "N898",	text: "OTROS TRASTORNOS ESPECIFICADOS NO INFLAMATORIOS DE LA VAGINA"},
{ value: "N899",	text: "TRASTORNO NO INFLAMATORIO DE LA VAGINA, NO ESPECIFICADO"},
{ value: "N900",	text: "DISPLASIA VULVAR LEVE"},
{ value: "N901",	text: "DISPLASIA VULVAR MODERADA"},
{ value: "N902",	text: "DISPLASIA VULVAR SEVERA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "N903",	text: "DISPLASIA DE LA VULVA, NO ESPECIFICADA"},
{ value: "N904",	text: "LEUCOPLASIA DE LA VULVA"},
{ value: "N905",	text: "ATROFIA DE LA VULVA"},
{ value: "N906",	text: "HIPERTROFIA DE LA VULVA"},
{ value: "N907",	text: "QUISTE DE LA VULVA"},
{ value: "N908",	text: "OTROS TRASTORNOS NO INFLAMATORIOS ESPECIFICADOS DE LA VULVA Y DEL PERINEO"},
{ value: "N909",	text: "TRASTORNO NO INFLAMATORIO DE LA VULVA Y DEL PERINEO, NO ESPECIFICADO"},
{ value: "N910",	text: "AMENORREA PRIMARIA"},
{ value: "N911",	text: "AMENORREA SECUNDARIA"},
{ value: "N912",	text: "AMENORREA, SIN OTRA ESPECIFICACION"},
{ value: "N913",	text: "OLIGOMENORREA PRIMARIA"},
{ value: "N914",	text: "OLIGOMENORREA SECUNDARIA"},
{ value: "N915",	text: "OLIGOMENORREA NO ESPECIFICADA"},
{ value: "N920",	text: "MENSTRUACION EXCESIVA Y FRECUENTE CON CICLO REGULAR"},
{ value: "N921",	text: "MENSTRUACION EXCESIVA Y FRECUENTE CON CICLO IRREGULAR"},
{ value: "N922",	text: "MENSTRUACION EXCESIVA EN LA PUBERTAD"},
{ value: "N923",	text: "HEMORRAGIA POR OVULACION"},
{ value: "N924",	text: "HEMORRAGIA EXCESIVA EN PERIODO PREMENOPAUSICO"},
{ value: "N925",	text: "OTRAS MENSTRUACIONES IRREGULARES ESPECIFICADAS"},
{ value: "N926",	text: "MENSTRUACION IRREGULAR, NO ESPECIFICADA"},
{ value: "N930",	text: "HEMORRAGIA POSTCOITO Y POSTCONTACTO"},
{ value: "N938",	text: "OTRAS HEMORRAGIAS UTERINAS O VAGINALES ANORMALES ESPECIFICADAS"},
{ value: "N939",	text: "HEMORRAGIA VAGINAL Y UTERINA ANORMAL, NO ESPECIFICADA"},
{ value: "N940",	text: "DOLOR INTERMENSTRUAL"},
{ value: "N941",	text: "DISPAREUNIA"},
{ value: "N942",	text: "VAGINISMO"},
{ value: "N943",	text: "SINDROME DE TENSION PREMENSTRUAL"},
{ value: "N944",	text: "DISMENORREA PRIMARIA"},
{ value: "N945",	text: "DISMENORREA SECUNDARIA"},
{ value: "N946",	text: "DISMENORREA, NO ESPECIFICADA"},
{ value: "N948",	text: "OTRAS AFECCIONES ESPECIFICADAS ASOCIADAS CON LOS ORGANOS GENITALES FEMENINOS Y EL CICLO MENSTRUAL"},
{ value: "N949",	text: "AFECCIONES NO ESPECIFICADAS ASOCIADAS CON LOS ORGANOS GENITALES FEMENINOS Y EL CICLO MENSTRUAL"},
{ value: "N950",	text: "HEMORRAGIA POSTMENOPAUSICA"},
{ value: "N951",	text: "ESTADOS MENOPAUSICOS Y CLIMATERICOS FEMENINOS"},
{ value: "N952",	text: "VAGINITIS ATROFICA POSTMENOPAUSICA"},
{ value: "N953",	text: "ESTADOS ASOCIADOS CON MENOPAUSIA ARTIFICIAL"},
{ value: "N958",	text: "OTROS TRASTORNOS MENOPAUSICOS Y PERIMENOPAUSICOS ESPECIFICADOS"},
{ value: "N959",	text: "TRASTORNO MENOPAUSICO Y PERIMENOPAUSICO, NO ESPECIFICADO"},
{ value: "N96X",	text: "ABORTADORA HABITUAL"},
{ value: "N970",	text: "INFERTILIDAD FEMENINA ASOCIADA CON FALTA DE OVULACION"},
{ value: "N971",	text: "INFERTILIDAD FEMENINA DE ORIGEN TUBARICO"},
{ value: "N972",	text: "INFERTILIDAD FEMENINA DE ORIGEN UTERINO"},
{ value: "N973",	text: "INFERTILIDAD FEMENINA DE ORIGEN CERVICAL"},
{ value: "N974",	text: "INFERTILIDAD FEMENINA ASOCIADA CON FACTORES MASCULINOS"},
{ value: "N978",	text: "INFERTILIDAD FEMENINA DE OTRO ORIGEN"},
{ value: "N979",	text: "INFERTILIDAD FEMENINA, NO ESPECIFICADA"},
{ value: "N980",	text: "INFECCION ASOCIADA CON INSEMINACION ARTIFICIAL"},
{ value: "N981",	text: "HIPERESTIMULACION DE OVARIOS"},
{ value: "N982",	text: "COMPLICACIONES EN EL INTENTO DE INTRODUCCION DEL HUEVO FECUNDADO EN LA FERTILIZACION EN VITRO"},
{ value: "N983",	text: "COMPLICACIONES EN EL INTENTO INTRODUCCION DEL EMBRION EN LA TRANSFERENCIA DE EMBRIONES"},
{ value: "N988",	text: "OTRAS COMPLICACIONES ASOCIADAS CON LA FECUNDACION ARTIFICIAL"},
{ value: "N989",	text: "COMPLICACION NO ESPECIFICADA ASOCIADA CON LA FECUNDACION ARTIFICIAL"},
{ value: "N990",	text: "INSUFICIENCIA RENAL CONSECUTIVA A PROCEDIMIENTOS"},
{ value: "N991",	text: "ESTRECHEZ URETRAL CONSECUTIVA A PROCEDIMIENTOS"},
{ value: "N992",	text: "ADHERENCIAS POSTOPERATORIAS DE LA VAGINA"},
{ value: "N993",	text: "PROLAPSO DE LA CUPULA VAGINAL DESPUES DE HISTERECTOMIA"},
{ value: "N994",	text: "ADHERENCIAS PERITONEALES PELVICAS CONSECUTIVAS A PROCEDIMIENTOS"},
{ value: "N995",	text: "MAL FUNCIONAMIENTO DE ESTOMA EXTERNO DE VIAS URINARIAS"},
{ value: "N998",	text: "OTROS TRASTORNOS DEL SISTEMA GENITOURINARIO CONSECUTIVOS A PROCEDIMIENTOS"},
{ value: "N999",	text: "TRASTORNO NO ESPECIFICADO DEL SISTEMA GENITOURINARIO CONSECUTIVO A PROCEDIMIENTOS"},
{ value: "O000",	text: "EMBARAZO ABDOMINAL"},
{ value: "O001",	text: "EMBARAZO TUBARICO"},
{ value: "O002",	text: "EMBARAZO OVARICO"},
{ value: "O008",	text: "OTROS EMBARAZOS ECTOPICOS"},
{ value: "O009",	text: "EMBARAZO ECTOPICO, NO ESPECIFICADO"},
{ value: "O010",	text: "MOLA HIDATIFORME CLASICA"},
{ value: "O011",	text: "MOLA HIDATIFORME, INCOMPLETA O PARCIAL"},
{ value: "O019",	text: "MOLA HIDATIFORME, NO ESPECIFICADA"},
{ value: "O020",	text: "DETENCION DEL DESARROLLO DEL HUEVO Y MOLA NO HIDATIFORME"},
{ value: "O021",	text: "ABORTO RETENIDO"},
{ value: "O028",	text: "OTROS PRODUCTOS ANORMALES ESPECIFICADOS DE LA CONCEPCION"},
{ value: "O029",	text: "PRODUCTO ANORMAL DE LA CONCEPCION, NO ESPECIFICADO"},
{ value: "O030",	text: "ABORTO ESPONTANEO: INCOMPLETO, COMPLICADO CON INFECCIÓN GENITAL Y PELVIANA"},
{ value: "O031",	text: "ABORTO ESPONTANEO: INCOMPLETO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDIA"},
{ value: "O032",	text: "ABORTO ESPONTANEO: INCOMPLETO, COMPLICADO POR EMBOLIA"},
{ value: "O033",	text: "ABORTO ESPONTANEO: INCOMPLETO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"},
{ value: "O034",	text: "ABORTO ESPONTANEO: INCOMPLETO, SIN COMPLICACION"},
{ value: "O035",	text: "ABORTO ESPONTANEO: COMPLETO O NO ESPECIFICADO, COMPLICADO CON INFECCION GENITAL Y PELVIANA"},
{ value: "O036",	text: "ABORTO ESPONTANEO: COMPLETO O NO ESPECIFICADO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDIA"},
{ value: "O037",	text: "ABORTO ESPONTANEO: COMPLETO O NO ESPECIFICADO, COMPLICADO POR EMBOLIA"},
{ value: "O038",	text: "ABORTO ESPONTANEO: COMPLETO O NO ESPECIFICADO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"},
{ value: "O039",	text: "ABORTO ESPONTANEO: COMPLETO O NO ESPECIFICADO, SIN COMPLICACION"},
{ value: "O040",	text: "ABORTO MEDICO: INCOMPLETO, COMPLICADO CON INFECCIÓN GENITAL Y PELVIANA"},
{ value: "O041",	text: "ABORTO MEDICO: INCOMPLETO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDIA"},
{ value: "O042",	text: "ABORTO MEDICO: INCOMPLETO, COMPLICADO POR EMBOLIA"},
{ value: "O043",	text: "ABORTO MEDICO: INCOMPLETO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"},
{ value: "O044",	text: "ABORTO MEDICO: INCOMPLETO, SIN COMPLICACION"},
{ value: "O045",	text: "ABORTO MEDICO: COMPLETO O NO ESPECIFICADO, COMPLICADO CON INFECCION GENITAL Y PELVIANA"},
{ value: "O046",	text: "ABORTO MEDICO: COMPLETO O NO ESPECIFICADO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDIA"},
{ value: "O047",	text: "ABORTO MEDICO: COMPLETO O NO ESPECIFICADO, COMPLICADO POR EMBOLIA"},
{ value: "O048",	text: "ABORTO MEDICO: COMPLETO O NO ESPECIFICADO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"},
{ value: "O049",	text: "ABORTO MEDICO: COMPLETO O NO ESPECIFICADO, SIN COMPLICACION"},
{ value: "O050",	text: "OTRO ABORTO: INCOMPLETO, COMPLICADO CON INFECCIÓN GENITAL Y PELVIANA"},
{ value: "O051",	text: "OTRO ABORTO: INCOMPLETO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDIA"},
{ value: "O052",	text: "OTRO ABORTO: INCOMPLETO, COMPLICADO POR EMBOLIA"},
{ value: "O053",	text: "OTRO ABORTO: INCOMPLETO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"},
{ value: "O054",	text: "OTRO ABORTO: INCOMPLETO, SIN COMPLICACION"},
{ value: "O055",	text: "OTRO ABORTO: COMPLETO O NO ESPECIFICADO, COMPLICADO CON INFECCION GENITAL Y PELVIANA"},
{ value: "O056",	text: "OTRO ABORTO: COMPLETO O NO ESPECIFICADO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDIA"},
{ value: "O057",	text: "OTRO ABORTO: COMPLETO O NO ESPECIFICADO, COMPLICADO POR EMBOLIA"},
{ value: "O058",	text: "OTRO ABORTO: COMPLETO O NO ESPECIFICADO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"},
{ value: "O059",	text: "OTRO ABORTO: COMPLETO O NO ESPECIFICADO, SIN COMPLICACION"},
{ value: "O060",	text: "ABORTO NO ESPECIFICADO: INCOMPLETO, COMPLICADO CON INFECCIÓN GENITAL Y PELVIANA"},
{ value: "O061",	text: "ABORTO NO ESPECIFICADO: INCOMPLETO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDIA"},
{ value: "O062",	text: "ABORTO NO ESPECIFICADO: INCOMPLETO, COMPLICADO POR EMBOLIA"},
{ value: "O063",	text: "ABORTO NO ESPECIFICADO: INCOMPLETO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"},
{ value: "O064",	text: "ABORTO NO ESPECIFICADO: INCOMPLETO, SIN COMPLICACION"},
{ value: "O065",	text: "ABORTO NO ESPECIFICADO: COMPLETO O NO ESPECIFICADO, COMPLICADO CON INFECCION GENITAL Y PELVIANA"},
{ value: "O066",	text: "ABORTO NO ESPECIFICADO: COMPLETO O NO ESPECIFICADO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDIA"},
{ value: "O067",	text: "ABORTO NO ESPECIFICADO: COMPLETO O NO ESPECIFICADO, COMPLICADO POR EMBOLIA"},
{ value: "O068",	text: "ABORTO NO ESPECIFICADO: COMPLETO O NO ESPECIFICADO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"},
{ value: "O069",	text: "ABORTO NO ESPECIFICADO: COMPLETO O NO ESPECIFICADO, SIN COMPLICACION"},
{ value: "O070",	text: "FALLA DE LA INDUCCION MEDICA DEL ABORTO, COMPLICADO CON INFECCIÓN GENITAL Y PELVIANA"},
{ value: "O071",	text: "FALLA DE LA INDUCCION MEDICA DEL ABORTO, COMPLICADO POR HEMORRAGIA EXCESIVA O TARDIA"},
{ value: "O072",	text: "FALLA DE LA INDUCCION MEDICA DEL ABORTO, COMPLICADO POR EMBOLIA"},
{ value: "O073",	text: "FALLA DE LA INDUCCION MEDICA DEL ABORTO, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"},
{ value: "O074",	text: "FALLA DE LA INDUCCION MEDICA DEL ABORTO, SIN COMPLICACION"},
{ value: "O075",	text: "OTROS INTENTOS FALLIDOS DE ABORTO Y LOS NO ESPECIFICADOS, COMPLICADOS POR INFECCION GENITAL Y PELVIANA"},
{ value: "O076",	text: "OTROS INTENTOS FALLIDOS DE ABORTO Y LOS NO ESPECIFICADOS, COMPLICADOS POR HEMORRAGIA EXCESIVA O TARDIA"},
{ value: "O077",	text: "OTROS INTENTOS FALLIDOS DE ABORTO Y LOS NO ESPECIFICADOS, COMPLICADOS POR EMBOLIA"},
{ value: "O078",	text: "OTROS INTENTOS FALLIDOS DE ABORTO Y LOS NO ESPECIFICADOS, CON OTRAS COMPLICACIONES ESPECIFICADAS Y LAS NO ESPECIFICADAS"},
{ value: "O079",	text: "OTROS INTENTOS FALLIDOS DE ABORTO Y LOS NO ESPECIFICADOS, SIN COMPLICACION"},
{ value: "O080",	text: "INFECCION GENITAL Y PELVIANA CONSECUTIVA AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR"},
{ value: "O081",	text: "HEMORRAGIA EXCESIVA O TARDIA CONSECUTIVA AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR"},
{ value: "O082",	text: "EMBOLIA CONSECUTIVA AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR"},
{ value: "O083",	text: "CHOQUE CONSECUTIVO AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR"},
{ value: "O084",	text: "INSUFICIENCIA RENAL CONSECUTIVA AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR"},
{ value: "O085",	text: "TRASTORNO METABOLICO CONSECUTIVO AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR"},
{ value: "O086",	text: "LESION DE ORGANOS O TEJIDOS DE LA PELVIS CONSECUTIVO AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR"},
{ value: "O087",	text: "OTRAS COMPLICACIONES VENOSAS CONSECUTIVA AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR"},
{ value: "O088",	text: "OTRAS COMPLICACIONES CONSECUTIVAS AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR"},
{ value: "O089",	text: "COMPLICACION NO ESPECIFICADA CONSECUTIVA AL ABORTO, AL EMBARAZO ECTOPICO Y AL EMBARAZO MOLAR"},
{ value: "O100",	text: "HIPERTENSION ESENCIAL PREEXISTENTE QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O101",	text: "ENFERMEDAD CARDIACA HIPERTENSIVA PREEXISTENTE QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O102",	text: "ENFERMEDAD RENAL HIPERTENSIVA PREEXISTENTE QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O103",	text: "ENFERMEDAD CARDIO-RENAL HIPERTENSIVA PREEXISTENTE QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O104",	text: "HIPERTENSION SECUNDARIA PREEXISTENTE QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O109",	text: "HIPERTENSION PREEXISTENTE NO ESPECIFICADA, QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O11X",	text: "TRASTORNOS HIPERTENSIVOS PREEXISTENTES, CON PROTEINURIA AGREGADA"},
{ value: "O120",	text: "EDEMA GESTACIONAL"},
{ value: "O121",	text: "PROTEINURIA GESTACIONAL"},
{ value: "O122",	text: "EDEMA GESTACIONAL CON PROTEINURIA"},
{ value: "O13X",	text: "HIPERTENSION GESTACIONAL (INDUCIDA POR EL EMBARAZO) SIN PROTEINURIA SIGNIFICATIVA"},
{ value: "O140",	text: "PREECLAMPSIA MODERADA"},
{ value: "O141",	text: "PREECLAMPSIA SEVERA"},
{ value: "O149",	text: "PREECLAMPSIA, NO ESPECIFICADA"},
{ value: "O150",	text: "PREECLAMPSIA EN EL EMBARAZO"},
{ value: "O151",	text: "PREECLAMPSIA DURANTE EL TRABAJO DE PARTO"},
{ value: "O152",	text: "PREECLAMPSIA EN EL PUERPERIO"},
{ value: "O159",	text: "PREECLAMPSIA, EN PERIODO NO ESPECIFICADO"},
{ value: "O16X",	text: "HIPERTENSION MATERNA, NO ESPECIFICADA"},
{ value: "O200",	text: "AMENAZA DE ABORTO"},
{ value: "O208",	text: "OTRAS HEMORRAGIAS PRECOCES DEL EMBARAZO"},
{ value: "O209",	text: "HEMORRAGIA PRECOZ DEL EMBARAZO, SIN OTRA ESPECIFICACION"},
{ value: "O210",	text: "HIPEREMESIS GRAVIDICA LEVE"},
{ value: "O211",	text: "HIPEREMESIS GRAVIDICA CON TRASTORNOS METABOLICOS"},
{ value: "O212",	text: "HIPEREMESIS GRAVIDICA TARDIA"},
{ value: "O218",	text: "OTROS VOMITOS QUE COMPLICAN EL EMBARAZO"},
{ value: "O219",	text: "VOMITOS DEL EMBARAZO, NO ESPECIFICADO"},
{ value: "O220",	text: "VENAS VARICOSAS DE LOS MIEMBROS INFERIORES EN EL EMBARAZO"},
{ value: "O221",	text: "VARICES GENITALES EN EL EMBARAZO"},
{ value: "O222",	text: "TROMBOFLEBITIS EN EL EMBARAZO"},
{ value: "O223",	text: "FLEBOTROMBOSIS PROFUNDA EN EL EMBARAZO"},
{ value: "O224",	text: "HEMORROIDES EN EL EMBARAZO"},
{ value: "O225",	text: "TROMBOSIS VENOSA CEREBRAL EN EL EMBARAZO"},
{ value: "O228",	text: "OTRAS COMPLICACIONES VENOSAS EN EL EMBARAZO"},
{ value: "O229",	text: "COMPLICACION VENOSA NO ESPECIFICADA EN EL EMBARAZO"},
{ value: "O230",	text: "INFECCION DEL RIÑON EN EL EMBARAZO"},
{ value: "O231",	text: "INFECCION DE LA VEJIGA URINARIA EN EL EMBARAZO"},
{ value: "O232",	text: "INFECCION DE LA URETRA EN EL EMBARAZO"},
{ value: "O233",	text: "INFECCION DE OTRAS PARTES DE LAS VIAS URINARIAS EN EL EMBARAZO"},
{ value: "O234",	text: "INFECCION NO ESPECIFICADA DE LAS VIAS URINARIAS EN EL EMBARAZO"},
{ value: "O235",	text: "INFECCION GENITAL EN EL EMBARAZO"},
{ value: "O239",	text: "OTRAS INFECCION Y LAS NO ESPECIFICADAS DE LAS VIAS GENITOURINARIAS EN EL EMBARAZO"},
{ value: "O240",	text: "DIABETES MELLITUS PREEXISTENTE INSULINODEPENDIENTE, EN EL EMBARAZO"},
{ value: "O241",	text: "DIABETES MELLITUS PREEXISTENTE NO INSULINODEPENDIENTE, EN EL EMBARAZO"},
{ value: "O242",	text: "DIABETES MELLITUS PREEXISTENTE RELACIONADA CON DESNUTRICION, EN EL EMBARAZO"},
{ value: "O243",	text: "DIABETES MELLITUS PREEXISTENTE, SIN OTRA ESPECIFICACION, EN EL EMBARAZO"},
{ value: "O244",	text: "DIABETES MELLITUS QUE SE ORIGINA EN EL EMBARAZO"},
{ value: "O249",	text: "DIABETES MELLITUS NO ESPECIFICADA, EN EL EMBARAZO"},
{ value: "O25X",	text: "DESNUTRICION EN EL EMBARAZO"},
{ value: "O260",	text: "AUMENTO EXCESIVO DE PESO EN EL EMBARAZO"},
{ value: "O261",	text: "AUMENTO PEQUEÑO DE PESO EN EL EMBARAZO"},
{ value: "O262",	text: "ATENCION DEL EMBARAZO EN UNA ABORTADORA HABITUAL"},
{ value: "O263",	text: "RETENCION DE DISPOSITIVO ANTICONCEPTIVO INTRAUTERINO EN EL EMBARAZO"},
{ value: "O264",	text: "HERPES GESTACIONAL"},
{ value: "O265",	text: "SINDROME DE HIPOTENSION MATERNA"},
{ value: "O266",	text: "TRASTORNO DEL HIGADO EN EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O267",	text: "SUBLUXACION DE LA SINFISIS (DEL PUBIS) EN EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O268",	text: "OTRAS COMPLICACIONES ESPECIFICADAS RELACIONADAS CON EL EMBARAZO"},
{ value: "O269",	text: "COMPLICACION RELACIONADA CON EL EMBARAZO, NO ESPECIFICADA"},
{ value: "O280",	text: "HALLAZGO HEMATOLOGICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE"},
{ value: "O281",	text: "HALLAZGO BIOQUIMICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE"},
{ value: "O282",	text: "HALLAZGO CITOLOGICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE"},
{ value: "O283",	text: "HALLAZGO ULTRASONICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE"},
{ value: "O284",	text: "HALLAZGO RADIOLOGICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE"},
{ value: "O285",	text: "HALLAZGO CROMOSOMICO O GENETICO ANORMAL EN EL EXAMEN PRENATAL DE LA MADRE"},
{ value: "O288",	text: "OTROS HALLAZGOS ANORMALES EN EL EXAMEN PRENATAL DE LA MADRE"},
{ value: "O289",	text: "HALLAZGO ANORMAL NO ESPECIFICADO EN EL EXAMEN PRENATAL DE LA MADRE"},
{ value: "O290",	text: "COMPLICACIONES PULMONARES DE LA ANESTESIA ADMINISTRADA DURANTE EL EMBARAZO"},
{ value: "O291",	text: "COMPLICACIONES CARDIACAS DE LA ANESTESIA ADMINISTRADA DURANTE EL EMBARAZO"},
{ value: "O292",	text: "COMPLICACIONES DEL SISTEMA NERVIOSO CENTRAL DEBIDAS A LA ANESTESIA ADMINISTRADA DURANTE EL EMBARAZO"},
{ value: "O293",	text: "REACCION TOXICA A LA ANESTESIA LOCAL ADMINISTRADA DURANTE EL EMBARAZO"},
{ value: "O294",	text: "CEFALALGIA INDUCIDA POR LA ANESTESIA ESPINAL O EPIDURAL ADMINISTRADAS DURANTE EL EMBARAZO"},
{ value: "O295",	text: "OTRAS COMPLICACIONES DE LA ANESTESIA ESPINAL O EPIDURAL ADMINISTRADAS DURANTE EL EMBARAZO"},
{ value: "O296",	text: "FALLA O DIFICULTAD EN LA INTUBACION DURANTE EL EMBARAZO"},
{ value: "O298",	text: "OTRAS COMPLICACIONES DE LA ANESTESIA ADMINISTRADA DURANTE EL EMBARAZO"},
{ value: "O299",	text: "COMPLICACION NO ESPECIFICADA DE LA ANESTESIA ADMINISTRADA DURANTE EL EMBARAZO"},
{ value: "O300",	text: "EMBARAZO DOBLE"},
{ value: "O301",	text: "EMBARAZO TRIPLE"},
{ value: "O302",	text: "EMBARAZO CUADRUPLE"},
{ value: "O308",	text: "OTROS EMBARAZOS MULTIPLES"},
{ value: "O309",	text: "EMBARAZO MULTIPLE, NO ESPECIFICADO"},
{ value: "O310",	text: "FETO PAPIRACEO"},
{ value: "O311",	text: "EMBARAZO QUE CONTINUA DESPUES DEL ABORTO DE UN FETO O MAS"},
{ value: "O312",	text: "EMBARAZO QUE CONTINUA DESPUES DE LA MUERTE INTRAUTERINA DE UN FETO O MAS"},
{ value: "O318",	text: "OTRAS COMPLICACIONES ESPECIFICADAS DEL EMBARAZO"},
{ value: "O320",	text: "ATENCION MATERNA POR POSICION FETAL INESTABLE"},
{ value: "O321",	text: "ATENCION MATERNA POR PRESENTACION DE NALGAS"},
{ value: "O322",	text: "ATENCION MATERNA POR POSICION FETAL OBLICUA O TRANSVERSA"},
{ value: "O323",	text: "ATENCION MATERNA POR PRESENTACION DE CARA, DE FRENTE O DE MENTON"},
{ value: "O324",	text: "ATENCION MATERNA POR CABEZA ALTA EN GESTACION A TERMINO"},
{ value: "O325",	text: "ATENCION MATERNA POR EMBARAZO MULTIPLE CON PRESENTACION ANORMAL DE UN FETO O MAS"},
{ value: "O326",	text: "ATENCION MATERNA POR PRESENTACION COMPUESTA"},
{ value: "O328",	text: "ATENCION MATERNA POR OTRAS PRESENTACIONES ANORMALES DEL FETO"},
{ value: "O329",	text: "ATENCION MATERNA POR PRESENTACION ANORMAL NO ESPECIFICADA DEL FETO"},
{ value: "O330",	text: "ATENCION MATERNA POR DESPROPORCION DEBIDA A DEFORMIDAD DE LA PELVIS OSEA EN LA MADRE"},
{ value: "O331",	text: "ATENCION MATERNA POR DESPROPORCION DEBIDA A ESTRECHEZ GENERAL DE LA PELVIS"},
{ value: "O332",	text: "ATENCION MATERNA POR DESPROPORCION DEBIDA A DISMINUCION DEL ESTRECHO SUPERIOR DE LA PELVIS"},
{ value: "O333",	text: "ATENCION MATERNA POR DESPROPORCION DEBIDA A DISMINUCION DEL ESTRECHO INFERIOR DE LA PELVIS"},
{ value: "O334",	text: "ATENCION MATERNA POR DESPROPORCION FETOPELVIANA DE ORIGEN MIXTO, MATERNO Y FETAL"},
{ value: "O335",	text: "ATENCION MATERNA POR DESPROPORCION DEBIDA A FETO DEMASIADO GRANDE"},
{ value: "O336",	text: "ATENCION MATERNA POR DESPROPORCION DEBIDA A FETO HIDROCEFALICO"},
{ value: "O337",	text: "ATENCION MATERNA POR DESPROPORCION DEBIDA A OTRA DEFORMIDAD FETAL"},
{ value: "O338",	text: "ATENCION MATERNA POR DESPROPORCION DE OTRO ORIGEN"},
{ value: "O339",	text: "ATENCION MATERNA POR DESPROPORCION DE ORIGEN NO ESPECIFICADO"},
{ value: "O340",	text: "ATENCION MATERNA POR ANOMALIA CONGENITA DEL UTERO"},
{ value: "O341",	text: "ATENCION MATERNA POR TUMOR DEL CUERPO DEL UTERO"},
{ value: "O342",	text: "ATENCION MATERNA POR CICATRIZ UTERINA DEBIDA A CIRUGIA PREVIA"},
{ value: "O343",	text: "ATENCION MATERNA POR INCOMPETENCIA DEL CUELLO UTERINO"},
{ value: "O344",	text: "ATENCION MATERNA POR OTRA ANORMALIDAD DEL CUELLO UTERINO"},
{ value: "O345",	text: "ATENCION MATERNA POR OTRAS ANORMALIDADES DEL UTERO GRAVIDO"},
{ value: "O346",	text: "ATENCION MATERNA POR ANORMALIDAD DE LA VAGINA"},
{ value: "O347",	text: "ATENCION MATERNA POR ANORMALIDAD DE LA VULVA Y DEL PERINEO"},
{ value: "O348",	text: "ATENCION MATERNA POR ANORMALIDADES DE LOS ORGANOS PELVIANOS"},
{ value: "O349",	text: "ATENCION MATERNA POR ANORMALIDAD NO ESPECIFICADA DE ORGANO PELVIANO"},
{ value: "O350",	text: "ATENCION MATERNA POR (PRESUNTA) MALFORMACION DEL SISTEMA NERVIOSO CENTRAL EN EL FETO"},
{ value: "O351",	text: "ATENCION MATERNA POR (PRESUNTA) ANORMALIDAD CROMOSOMICA EN EL FETO"},
{ value: "O352",	text: "ATENCION MATERNA POR (PRESUNTA) ENFERMEDAD HEREDITARIA EN EL FETO"},
{ value: "O353",	text: "ATENCION MATERNA POR (PRESUNTA) LESION FETAL DEBIDA A ENFERMEDAD VIRICA EN LA MADRE"},
{ value: "O354",	text: "ATENCION MATERNA POR (PRESUNTA) LESION AL FETO DEBIDA AL ALCOHOL"},
{ value: "O355",	text: "ATENCION MATERNA POR (PRESUNTA) LESION FETAL DEBIDA A DROGAS"},
{ value: "O356",	text: "ATENCION MATERNA POR (PRESUNTA) LESION AL FETO DEBIDA A RADIACION"},
{ value: "O357",	text: "ATENCION MATERNA POR (PRESUNTA) LESION FETAL DEBIDA A OTROS PROCEDIMIENTOS MEDICOS"},
{ value: "O358",	text: "ATENCION MATERNA POR OTRAS (PRESUNTA) ANORMALIDADES Y LESIONES FETALES"},
{ value: "O359",	text: "ATENCION MATERNA POR (PRESUNTA) ANORMALIDAD Y LESION FETAL NO ESPECIFICADA"},
{ value: "O360",	text: "ATENCION MATERNA POR ISOINMUNIZACION RHESUS"},
{ value: "O361",	text: "ATENCION MATERNA POR OTRA ISOINMUNIZACION"},
{ value: "O362",	text: "ATENCION MATERNA POR HIDROPESIA FETAL"},
{ value: "O363",	text: "ATENCION MATERNA POR SIGNOS DE HIPOXIA FETAL"},
{ value: "O364",	text: "ATENCION MATERNA POR MUERTE INTRAUTERINA"},
{ value: "O365",	text: "ATENCION MATERNA POR DEFICIT DEL CRECIMIENTO FETAL"},
{ value: "O366",	text: "ATENCION MATERNA POR CRECIMIENTO FETAL EXCESIVO"},
{ value: "O367",	text: "ATENCION MATERNA POR FETO VIABLE EN EMBARAZO ABDOMINAL"},
{ value: "O368",	text: "ATENCION MATERNA POR OTROS PROBLEMAS FETALES ESPECIFICADOS"},
{ value: "O369",	text: "ATENCION MATERNA POR OTROS PROBLEMAS FETALES NO ESPECIFICADOS"},
{ value: "O40X",	text: "POLIHIDRAMNIOS"},
{ value: "O410",	text: "OLIGOHIDRAMNIOS"},
{ value: "O411",	text: "INFECCION DE LA BOLSA AMNIOTICA O DE LAS MEMBRANAS"},
{ value: "O418",	text: "OTROS TRASTORNOS ESPECIFICADOS DEL LIQUIDO AMNIOTICO Y DE LAS MEMBRANAS"},
{ value: "O419",	text: "TRASTORNO DEL LIQUIDO AMNIOTICO Y DE LAS MEMBRANAS, NO ESPECIFICADO"},
{ value: "O420",	text: "RUPTURA PREMATURA DE LAS MEMBRANAS, E INICIO DEL TRABAJO DE PARTO DENTRO DE LAS 24 HORAS"},
{ value: "O421",	text: "RUPTURA PREMATURA DE LAS MEMBRANAS, E INICIO DEL TRABAJO DE PARTO DESPUÉS DE LAS 24 HORAS"},
{ value: "O422",	text: "RUPTURA PREMATURA DE LAS MEMBRANAS, TRABAJO DE PARTO RETRASADO POR LA TERAPEUTICA"},
{ value: "O429",	text: "RUPTURA PREMATURA DE LAS MEMBRANAS, SIN OTRA ESPECIFICACION"},
{ value: "O430",	text: "SINDROME DE TRANSFUSION PLACENTERA"},
{ value: "O431",	text: "MALFORMACION DE LA PLACENTA"},
{ value: "O438",	text: "OTROS TRASTORNOS PLACENTARIOS"},
{ value: "O439",	text: "TRASTORNO DE LA PLACENTA, NO ESPECIFICADO"},
{ value: "O440",	text: "PLACENTA PREVIA CON ESPECIFICACION DE QUE NO HUBO HEMORRAGIA"},
{ value: "O441",	text: "PLACENTA PREVIA CON HEMORRAGIA"},
{ value: "O450",	text: "DESPRENDIMIENTO PREMATURO DE LA PLACENTA CON DEFECTO DE LA COAGULACION"},
{ value: "O458",	text: "OTROS DESPRENDIMIENTOS PREMATUROS DE LA PLACENTA"},
{ value: "O459",	text: "DESPRENDIMIENTO PREMATURO DE LA PLACENTA, SIN OTRA ESPECIFICACION"},
{ value: "O460",	text: "HEMORRAGIA ANTEPARTO CON DEFECTO DE LA COAGULACIÓN"},
{ value: "O468",	text: "OTRAS HEMORRAGIAS ANTEPARTO"},
{ value: "O469",	text: "HEMORRAGIA ANTEPARTO, NO ESPECIFICADA"},
{ value: "O470",	text: "FALSO TRABAJO DE PARTO ANTES DE LA 37 SEMANAS COMPLETAS DE GESTACION"},
{ value: "O471",	text: "FALSO TRABAJO DE PARTO ANTES DE LA 37 Y MAS SEMANAS COMPLETAS DE GESTACION"},
{ value: "O479",	text: "FALSO TRABAJO DE PARTO SIN OTRA ESPECIFICACION"},
{ value: "O48X",	text: "EMBARAZO PROLONGADO"},
{ value: "O60X",	text: "PARTO PREMATURO"},
{ value: "O610",	text: "FRACASO DE LA INDUCCION MEDICA DEL TRABAJO DE PARTO"},
{ value: "O611",	text: "FRACASO DE LA INDUCCION INSTRUMENTAL DEL TRABAJO DE PARTO"},
{ value: "O618",	text: "OTROS FRACASOS DE LA INDUCCION DEL TRABAJO DE PARTO"},
{ value: "O619",	text: "FRACASO NO ESPECIFICADO DE LA INDUCCION DEL TRABAJO DE PARTO"},
{ value: "O620",	text: "CONTRACCIONES PRIMARIAS INADECUADAS"},
{ value: "O621",	text: "INERCIA UTERINA SECUNDARIA"},
{ value: "O622",	text: "OTRAS INERCIAS UTERINAS"},
{ value: "O623",	text: "TRABAJO DE PARTO PRECIPITADO"},
{ value: "O624",	text: "CONTRACCIONES UTERINAS HIPERTONICAS, INCOORDINADAS Y PROLONGADAS"},
{ value: "O628",	text: "OTRAS ANOMALIAS DINAMICAS DEL TRABAJO DE PARTO"},
{ value: "O629",	text: "ANOMALIA DINAMICA DEL TRABAJO DE PARTO, NO ESPECIFICADA"},
{ value: "O630",	text: "PROLONGACION DEL PRIMER PERIODO (DEL TRABAJO DE PARTO)"},
{ value: "O631",	text: "PROLONGACION DEL SEGUNDO PERIODO (DEL TRABAJO DE PARTO)"},
{ value: "O632",	text: "RETRASO DE LA EXPULSION DEL SEGUNDO GEMELO, DEL TERCERO, ETC"},
{ value: "O639",	text: "TRABAJO DE PARTO PROLONGADO, NO ESPECIFICADO"},
{ value: "O640",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A ROTACION INCOMPLETA DE LA CABEZA FETAL"},
{ value: "O641",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACION DE NALGAS"},
{ value: "O642",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACION DE CARA"},
{ value: "O643",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACION DE FRENTE"},
{ value: "O644",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACION DE HOMBRO"},
{ value: "O645",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACION COMPUESTA"},
{ value: "O648",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A OTRAS PRESENTACIONES ANORMALES DEL FETO"},
{ value: "O649",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A PRESENTACION ANORMAL DEL FETO NO ESPECIFICADA"},
{ value: "O650",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A DEFORMIDAD DE LA PELVIS"},
{ value: "O651",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A ESTRECHEZ GENERAL DE LA PELVIS"},
{ value: "O652",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A DISMINUCION DEL ESTRECHO SUPERIOR DE LA PELVIS"},
{ value: "O653",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A DISMINUCION DEL ESTRECHO INFERIOR DE LA PELVIS"},
{ value: "O654",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A DESPROPORCION FETOPELVIANA, SIN OTRA ESPECIFICACION"},
{ value: "O655",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A ANOMALIAS DE LOS ORGANOS PELVIANOS MATERNOS"},
{ value: "O658",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A OTRAS ANOMALIAS PELVIANAS MATERNAS"},
{ value: "O659",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A ANOMALIA PELVIANA NO ESPECIFICADA"},
{ value: "O660",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A DISTOCIA DE HOMBROS"},
{ value: "O661",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A DISTOCIA GEMELAR"},
{ value: "O662",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A DISTOCIA POR FETO INUSUALMENTE GRANDE"},
{ value: "O663",	text: "TRABAJO DE PARTO OBSTRUIDO DEBIDO A OTRAS ANORMALIDADES DEL FETO"},
{ value: "O664",	text: "FRACASO DE LA PRUEBA DEL TRABAJO DE PARTO, NO ESPECIFICADA"},
{ value: "O665",	text: "FRACASO NO ESPECIFICADO DE LA APLICACION DE FORCEPS O DE VENTOSA EXTRACTORA"},
{ value: "O668",	text: "OTRAS OBSTRUCCIONES ESPECIFICADAS DEL TRABAJO DE PARTO"},
{ value: "O669",	text: "TRABAJO DE PARTO OBSTRUIDO, SIN OTRA ESPECIFICACION"},
{ value: "O670",	text: "HEMORRAGIA INTRAPARTO CON DEFECTOS DE LA COAGULACION"},
{ value: "O678",	text: "OTRAS HEMORRAGIAS INTRAPARTO"},
{ value: "O679",	text: "HEMORRAGIA INTRAPARTO, NO ESPECIFICADA"},
{ value: "O680",	text: "TRABAJO DE PARTO Y PARTO COMPLICADOS POR ANOMALIAS DE LA FRECUENCIA CARDIACA FETAL"},
{ value: "O681",	text: "TRABAJO DE PARTO Y PARTO COMPLICADOS POR LA PRESENCIA DE MECONIO EN EL LIQUIDO AMNIOTICO"},
{ value: "O682",	text: "TRABAJO DE PARTO Y PARTO COMPLICADOS POR ANOMALIA DE LA FRECUENCIA CARDIACA FETAL ASOCIADA CON PRESENCIA DE MECONIO EN LIQUIDO AMNIOTICO"},
{ value: "O683",	text: "TRABAJO DE PARTO Y PARTO COMPLICADOS POR EVIDENCIA BIOQUIMICA DE SUFRIMIENTO FETAL"},
{ value: "O688",	text: "TRABAJO DE PARTO Y PARTO COMPLICADOS POR OTRAS EVIDENCIAS DE SUFRIMIENTO FETAL"},
{ value: "O689",	text: "TRABAJO DE PARTO Y PARTO COMPLICADOS POR SUFRIMIENTO FETAL, SIN OTRA ESPECIFICACION"},
{ value: "O690",	text: "TRABAJO DE PARTO Y PARTO COMPLICADOS POR PROLAPSO DEL CORDON UMBILICAL"},
{ value: "O691",	text: "TRABAJO DE PARTO Y PARTO COMPLICADOS POR CIRCULAR PERICERVICAL DEL CORDON, CON COMPRESION"},
{ value: "O692",	text: "TRABAJO DE PARTO Y PARTO COMPLICADOS POR OTROS ENREDOS DEL CORDON"},
{ value: "O693",	text: "TRABAJO DE PARTO Y PARTO COMPLICADOS POR CORDON UMBILICAL CORTO"},
{ value: "O694",	text: "TRABAJO DE PARTO Y PARTO COMPLICADOS POR VASA PREVIA"},
{ value: "O695",	text: "TRABAJO DE PARTO Y PARTO COMPLICADOS POR LESION VASCULAR DEL CORDON"},
{ value: "O698",	text: "TRABAJO DE PARTO Y PARTO COMPLICADOS POR OTROS PROBLEMAS DEL CORDON UMBILICAL"},
{ value: "O699",	text: "TRABAJO DE PARTO Y PARTO COMPLICADOS POR PROBLEMAS NO ESPECIFICADOS DEL CORDON UMBILICAL"},
{ value: "O700",	text: "DESGARRO PERINEAL DE PRIMER GRADO DURANTE EL PARTO"},
{ value: "O701",	text: "DESGARRO PERINEAL DE SEGUNDO GRADO DURANTE EL PARTO"},
{ value: "O702",	text: "DESGARRO PERINEAL DE TERCER GRADO DURANTE EL PARTO"},
{ value: "O703",	text: "DESGARRO PERINEAL DE CUARTO GRADO DURANTE EL PARTO"},
{ value: "O709",	text: "DESGARRO PERINEAL DURANTE EL PARTO, DE GRADO NO ESPECIFICADO"},
{ value: "O710",	text: "RUPTURA DEL UTERO ANTES DEL INICIO DEL TRABAJO DE PARTO"},
{ value: "O711",	text: "RUPTURA DEL UTERO DURANTE EL TRABAJO DE PARTO"},
{ value: "O712",	text: "INVERSION DEL UTERO, POSTPARTO"},
{ value: "O713",	text: "DESGARRO OBSTETRICO DEL CUELLO UTERINO"},
{ value: "O714",	text: "DESGARRO VAGINAL OBSTETRICO ALTO, SOLO"},
{ value: "O715",	text: "OTROS TRAUMATISMOS OBSTETRICOS DE LOS ORGANOS PELVIANOS"},
{ value: "O716",	text: "TRAUMATISMO OBSTETRICO DE LOS LIGAMENTOS ARTICULACIONES DE LA PELVIS"},
{ value: "O717",	text: "HEMATOMA OBSTETRICO DE LA PELVIS"},
{ value: "O718",	text: "OTROS TRAUMAS OBSTETRICOS ESPECIFICADOS"},
{ value: "O719",	text: "TRAUMA OBSTETRICO, NO ESPECIFICADO"},
{ value: "O720",	text: "HEMORRAGIA DEL TERCER PERIODO DEL PARTO"},
{ value: "O721",	text: "OTRAS HEMORRAGIAS POSTPARTO INMEDIATAS"},
{ value: "O722",	text: "HEMORRAGIA POSTPARTO SECUNDARIA O TARDIA"},
{ value: "O723",	text: "DEFECTO DE LA COAGULACIÓN POSTPARTO"},
{ value: "O730",	text: "RETENCION DE LA PLACENTA SIN HEMORRAGIA"},
{ value: "O731",	text: "RETENCION DE FRAGMENTOS DE LA PLACENTA O DE LAS MEMBRANAS, SIN HEMORRAGIA"},
{ value: "O740",	text: "NEUMONITIS POR ASPIRACION DEBIDA A LA ANESTESIA ADMINISTRADA DURANTE EL TRABAJO DE PARTO Y EL PARTO"},
{ value: "O741",	text: "OTRAS COMPLICACIONES PULMONARES DEBIDAS A LA ANESTESIA ADMINISTRADA DURANTE EL TRABAJO DE PARTO Y EL PARTO"},
{ value: "O742",	text: "COMPLICACIONES CARDIACAS DE LA ANESTESIA ADMINISTRADA DURANTE EL TRABAJO DE PARTO Y EL PARTO"},
{ value: "O743",	text: "COMPLICACIONES DEL SISTEMA NERVIOSO CENTRAL POR LA ANESTESIA ADMINISTRADA DURANTE EL TRABAJO DE PARTO Y EL PARTO"},
{ value: "O744",	text: "REACCION TOXICA A LA ANESTESIA LOCAL ADMINISTRADA DURANTE EL TRABAJO DE PARTO Y EL PARTO"},
{ value: "O745",	text: "CEFALALGIA INDUCIDA POR LA ANESTESIA ESPINAL O EPIDURAL ADMINISTRADAS DURANTE EL TRABAJO DE PARTO Y EL PARTO"},
{ value: "O746",	text: "OTRAS COMPLICACIONES DE LA ANESTESIA ESPINAL O EPIDURAL ADMINISTRADAS DURANTE EL TRABAJO DE PARTO Y EL PARTO"},
{ value: "O747",	text: "FALLA O DIFICULTAD EN LA INTUBACION DURANTE EL TRABAJO DE PARTO Y EL PARTO"},
{ value: "O748",	text: "OTRAS COMPLICACIONES DE LA ANESTESIA ADMINISTRADA DURANTE EL TRABAJO DE PARTO Y EL PARTO"},
{ value: "O749",	text: "COMPLICACION NO ESPECIFICADA DE LA ANESTESIA ADMINISTRADA DURANTE EL TRABAJO DE PARTO Y EL PARTO"},
{ value: "O750",	text: "SUFRIMIENTO MATERNO DURANTE EL TRABAJO DE PARTO Y EL PARTO"},
{ value: "O751",	text: "CHOQUE DURANTE O DESPUES DEL TRABAJO DE PARTO Y EL PARTO"},
{ value: "O752",	text: "PIREXA DURANTE EL TRABAJO DE PARTO, NO CLASIFICADA EN OTRA PARTE"},
{ value: "O753",	text: "OTRAS INFECCIONES DURANTE EL TRABAJO DE PARTO"},
{ value: "O754",	text: "OTRAS COMPLICACIONES DE PROCEDIMIENTOS Y DE CIRUGIA OBSTETRICA"},
{ value: "O755",	text: "RETRASO DEL PARTO DESPUES DE LA RUPTURA ARTIFICIAL DE LAS MEMBRANAS"},
{ value: "O756",	text: "RETRASO DEL PARTO DESPUES DE LA RUPTURA ESPONTANEA O NO ESPECIFICADA DE LAS MEMBRANAS"},
{ value: "O757",	text: "PARTO VAGINAL POSTERIOR A UNA CESAREA PREVIA"},
{ value: "O758",	text: "OTRAS COMPLICACIONES ESPECIFICADAS DEL TRABAJO DE PARTO Y DEL PARTO"},
{ value: "O759",	text: "COMPLICACION NO ESPECIFICADA DEL TRABAJO DE PARTO Y DEL PARTO"},
{ value: "O800",	text: "PARTO UNICO ESPONTANEO, PRESENTACION CEFALICA DE VERTICE"},
{ value: "O801",	text: "PARTO UNICO ESPONTANEO, PRESENTACION DE NALGAS O PODALICA"},
{ value: "O808",	text: "PARTO UNICO ESPONTANEO, OTRAS PRESENTACIONES"},
{ value: "O809",	text: "PARTO UNICO ESPONTANEO, SIN OTRA ESPECIFICACION"},
{ value: "O810",	text: "PARTO CON FORCEPS BAJO"},
{ value: "O811",	text: "PARTO CON FORCEPS MEDIO"},
{ value: "O812",	text: "PARTO CON FORCEPS MEDIO CON ROTACION"},
{ value: "O813",	text: "PARTO CON FORCEPS DE OTROS TIPOS Y LOS NO ESPECIFICADOS"},
{ value: "O814",	text: "PARTO CON VENTOSA EXTRACTORA"},
{ value: "O815",	text: "PARTO CON COMBINACION DE FORCEPS Y VENTOSA EXTRACTORA"},
{ value: "O820",	text: "PARTO POR CESAREA ELECTIVA"},
{ value: "O821",	text: "PARTO POR CESAREA DE EMERGENCIA"},
{ value: "O822",	text: "PARTO POR CESAREA CON HISTERECTOMIA"},
{ value: "O828",	text: "OTROS PARTOS UNICOS POR CESAREA"},
{ value: "O829",	text: "PARTO POR CESAREA, SIN OTRA ESPECIFICACION"},
{ value: "O830",	text: "EXTRACCION DE NALGAS"},
{ value: "O831",	text: "OTROS PARTOS UNICOS ASISTIDOS, DE NALGAS"},
{ value: "O832",	text: "OTROS PARTOS UNICOS CON AYUDA DE MANIPULACION OBSTETRICA"},
{ value: "O833",	text: "PARTO DE FETO VIABLE EN EMBARAZO ABDOMINAL"},
{ value: "O834",	text: "OPERACION DESTRUCTIVA PARA FACILITAR EL PARTO"},
{ value: "O838",	text: "OTROS PARTOS UNICOS ASISTIDOS ESPECIFICADOS"},
{ value: "O839",	text: "PARTO UNICO ASISTIDO, SIN OTRA ESPECIFICACION"},
{ value: "O840",	text: "PARTO MULTIPLE, TODOS ESPONTANEOS"},
{ value: "O841",	text: "PARTO MULTIPLE, TODOS FORCEPS Y VENTOSA EXTRACTORA"},
{ value: "O842",	text: "PARTO MULTIPLE, TODOS POR CESAREA"},
{ value: "O848",	text: "OTROS PARTOS MULTIPLES"},
{ value: "O849",	text: "PARTO MULTIPLE, NO ESPECIFICADO"},
{ value: "O85X",	text: "SEPSIS PUERPERAL"},
{ value: "O860",	text: "INFECCION DE HERIDA QUIRURGICA OBSTETRICA"},
{ value: "O861",	text: "OTRAS INFECCIONES GENITALES CONSECUTIVAS AL PARTO"},
{ value: "O862",	text: "INFECCION DE LAS VIAS URINARIAS CONSECUTIVA AL PARTO"},
{ value: "O863",	text: "OTRAS INFECCIONES DE LAS VIAS GENITOURINARIAS CONSECUTIVAS AL PARTO"},
{ value: "O864",	text: "PIREXA DE ORIGEN DESCONOCIDO CONSECUTIVA AL PARTO"},
{ value: "O868",	text: "OTRAS INFECCIONES PUERPERALES ESPECIFICADAS"},
{ value: "O870",	text: "TROMBOFLEBITIS SUPERFICIAL EN EL PUERPERIO"},
{ value: "O871",	text: "FLEBOTROMBOSIS PROFUNDA EN EL PUERPERIO"},
{ value: "O872",	text: "HEMORROIDES EN EL PUERPERIO"},
{ value: "O873",	text: "TROBOSIS VENOSA CEREBRAL EN EL PUERPERIO"},
{ value: "O878",	text: "OTRAS COMPLICACIONES VENOSAS EN EL PUERPERIO"},
{ value: "O879",	text: "COMPLICACION VENOSA EN EL PUERPERIO, NO ESPECIFICADA"},
{ value: "O880",	text: "EMBOLIA GASEOSA, OBSTETRICA"},
{ value: "O881",	text: "EMBOLIA DE LIQUIDO AMNIOTICO"},
{ value: "O882",	text: "EMBOLIA DE COAGULO SANGUINEO, OBSTETRICA"},
{ value: "O883",	text: "EMBOLIA SEPTICA Y PIEMICA, OBSTETRICA"},
{ value: "O888",	text: "OTRAS EMBOLIAS OBSTETRICAS"},
{ value: "O890",	text: "COMPLICACIONES PULMONARES DE LA ANESTESIA ADMINISTRADA DURANTE EL PUERPERIO"},
{ value: "O891",	text: "COMPLICACIONES CARDIACAS DE LA ANESTESIA ADMINISTRADA DURANTE EL PUERPERIO"},
{ value: "O892",	text: "COMPLICACIONES DEL SISTEMA NERVIOSO CENTRAL DEBIDAS A LA ANESTESIA ADMINISTRADA DURANTE EL PUERPERIO"},
{ value: "O893",	text: "REACCION TOXICA A LA ANESTESIA LOCAL ADMINISTRADA DURANTE EL PUERPERIO"},
{ value: "O894",	text: "CEFALALGIA INDUCIDA POR LA ANESTESIA ESPINAL O EPIDURAL ADMINISTRADAS DURANTE EL PUERPERIO"},
{ value: "O895",	text: "OTRAS COMPLICACIONES DE LA ANESTESIA ESPINAL O EPIDURAL ADMINISTRADAS DURANTE EL PUERPERIO"},
{ value: "O896",	text: "FALLA O DIFICULTAD EN LA INTUBACION DURANTE EL PUERPERIO"},
{ value: "O898",	text: "OTRAS COMPLICACIONES DE LA ANESTESIA ADMINISTRADA DURANTE EL PUERPERIO"},
{ value: "O899",	text: "COMPLICACION NO ESPECIFICADA DE LA ANESTESIA ADMINISTRADA DURANTE EL TRABAJO PUERPERIO"},
{ value: "O900",	text: "DEHISCENCIA DE SUTURA DE CESAREA"},
{ value: "O901",	text: "DEHISCENCIA DE SUTURA OBSTÉTRICA PERINEAL"},
{ value: "O902",	text: "HEMATOMA DE HERIDA QUIRÚRGICA OBSTETRICA"},
{ value: "O903",	text: "CARDIOMIOPATIA EN EL PUERPERIO"},
{ value: "O904",	text: "INSUFICIENCIA RENAL AGUDA POSTPARTO"},
{ value: "O905",	text: "TIROIDITIS POSTPARTO"},
{ value: "O908",	text: "OTRAS COMPLICACIONES PUERPERALES, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "O909",	text: "COMPLICACION PUERPERAL , NO ESPECIFICADA"},
{ value: "O910",	text: "INFECCIONES DEL PEZON ASOCIADAS CON EL PARTO"},
{ value: "O911",	text: "ABSCESO DE LA MAMA ASOCIADO CON EL PARTO"},
{ value: "O912",	text: "MASTITIS NO PURULENTA ASOCIADA CON EL PARTO"},
{ value: "O920",	text: "RETRACCION DEL PEZON ASOCIADA CON EL PARTO"},
{ value: "O921",	text: "FISURAS DEL PEZON ASOCIADAS CON EL PARTO"},
{ value: "O922",	text: "OTROS TRASTORNOS DE LA MAMA Y LOS NO ESPECIFICADOS ASOCIADOS CON EL PARTO"},
{ value: "O923",	text: "AGALACTIA"},
{ value: "O924",	text: "HIPOGALACTIA"},
{ value: "O925",	text: "SUPRESION DE LA LACTANCIA"},
{ value: "O926",	text: "GALACTORREA"},
{ value: "O927",	text: "OTROS TRASTORNOS Y LOS NO ESPECIFICADOS DE LA LACTANCIA"},
{ value: "O95X",	text: "MUERTE OBSTETRICA DE CAUSA NO ESPECIFICADA"},
{ value: "O96X",	text: "MUERTE MATERNA DEBIDA A CUALQUIER CAUSA OBSTETRICA QUE OCURRE DESPUES DE 42 DIAS PERO ANTES DE UN AÑO DEL PARTO"},
{ value: "O97X",	text: "MUERTE POR SECUELAS DE CAUSAS OBSTETRICAS DIRECTAS"},
{ value: "O980",	text: "TUBERCULOSIS QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O981",	text: "SIFILIS QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O982",	text: "GONORREA A QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O983",	text: "OTRAS INFECCIONES CON UN MODO DE TRANSMISION PREDOMINANTE SEXUAL QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O984",	text: "HEPATITIS VIRAL QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O985",	text: "OTRAS ENFERMEDADES VIRALES QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O986",	text: "ENFERMEDADES CAUSADAS POR PROTOZOARIOS QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O988",	text: "OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS MATERNAS QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O989",	text: "ENFERMEDAD INFECCIOSA Y PARASITARIA MATERNA NO ESPECIFICADA QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O990",	text: "ANEMIA QUE COMPLICA EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O991",	text: "OTRAS ENFERMEDADES DE LA SANGRE Y DE LOS ORGANOS HEMATOPOYETICOS Y CIERTOS TRASTORNOS QUE AFECTAN EL SISTEMA INMUNITARIO CUANDO COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O992",	text: "ENFERMEDADES ENDOCRINAS, DE LA NUTRICION Y DEL METABOLISMO QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O993",	text: "TRASTORNOS MENTALES Y ENFERMEDADES DEL SISTEMA NERVIOSO QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O994",	text: "ENFERMEDADES DEL SISTEMA CIRCULATORIO QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O995",	text: "ENFERMEDADES DEL SISTEMA RESPIRATORIO QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O996",	text: "ENFERMEDADES DEL SISTEMA DIGESTIVO QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O997",	text: "ENFERMEDADES DE LA PIEL Y DE TEJIDO SUBCUTANEO QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "O998",	text: "OTRAS ENFERMEDADES ESPECIFICADAS Y AFECCIONES QUE COMPLICAN EL EMBARAZO, EL PARTO Y EL PUERPERIO"},
{ value: "P000",	text: "FETO Y RECIEN NACIDO AFECTADOS POR TRASTORNOS HIPERTENSIVOS DE LA MADRE"},
{ value: "P001",	text: "FETO Y RECIEN NACIDO AFECTADOS POR ENFERMEDADES RENALES Y DE LAS  VIAS URINARIAS DE LA MADRE"},
{ value: "P002",	text: "FETO Y RECIEN NACIDO AFECTADOS POR ENFERMEDADES INFECCIOSAS Y PARASITARIAS DE LA MADRE"},
{ value: "P003",	text: "FETO Y RECIEN NACIDO AFECTADOS POR ENFERMEDADES CIRCULATORIAS Y RESPIRATORIAS DE LA MADRE"},
{ value: "P004",	text: "FETO Y RECIEN NACIDO AFECTADOS POR TRASTORNOS NUTRICIONALES DE LA MADRE"},
{ value: "P005",	text: "FETO Y RECIEN NACIDO AFECTADOS POR TRAUMATISMO DE LA MADRE"},
{ value: "P006",	text: "FETO Y RECIEN NACIDO AFECTADOS POR PROCEDIMIENTO QUIRURGICO DE LA MADRE"},
{ value: "P007",	text: "FETO Y RECIEN NACIDO AFECTADOS POR OTRO PROCEDIMIENTO MEDICO DE LA MADRE, NO CLASIFICADO EN OTRA PARTE"},
{ value: "P008",	text: "FETO Y RECIEN NACIDO AFECTADOS POR OTRAS AFECCIONES MATERNAS"},
{ value: "P009",	text: "FETO Y RECIEN NACIDO AFECTADOS POR AFECCION MATERNA NO ESPECIFICADA"},
{ value: "P010",	text: "FETO Y RECIEN NACIDO AFECTADOS POR INCOMPETENCIA DEL CUELLO UTERINO"},
{ value: "P011",	text: "FETO Y RECIEN NACIDO AFECTADOS POR RUPTURA PREMATURA DE LAS MEMBRANAS"},
{ value: "P012",	text: "FETO Y RECIEN NACIDO AFECTADOS POR OLIGOHIDRAMNIOS"},
{ value: "P013",	text: "FETO Y RECIEN NACIDO AFECTADOS POR POLIHIDRAMNIOS"},
{ value: "P014",	text: "FETO Y RECIEN NACIDO AFECTADOS POR EMBARAZO ECTOPICO"},
{ value: "P015",	text: "FETO Y RECIEN NACIDO AFECTADOS POR EMBARAZO MULTIPLE"},
{ value: "P016",	text: "FETO Y RECIEN NACIDO AFECTADOS POR MUERTE MATERNA"},
{ value: "P017",	text: "FETO Y RECIEN NACIDO AFECTADOS POR PRESENTACION ANOMALA ANTES DEL TRABAJO DE PARTO"},
{ value: "P018",	text: "FETO Y RECIEN NACIDO AFECTADOS POR OTRAS COMPLICACIONES MATERNAS DEL EMBARAZO"},
{ value: "P019",	text: "FETO Y RECIEN NACIDO AFECTADOS POR COMPLICACIONES MATERNAS NO ESPECIFICADAS DEL EMBARAZO"},
{ value: "P020",	text: "FETO Y RECIEN NACIDO AFECTADOS POR PLACENTA PREVIA"},
{ value: "P021",	text: "FETO Y RECIEN NACIDO AFECTADOS POR OTRAS FORMAS DE DESPRENDIMIENTO  Y DE HEMORRAGIA PLACENTARIOS"},
{ value: "P022",	text: "FETO Y RECIEN NACIDO AFECTADOS POR OTRAS ANOMALIAS MORFOLOGICAS Y FUNCIONALES DE LA PLACENTA Y LAS NO ESPECIFICADAS"},
{ value: "P023",	text: "FETO Y RECIEN NACIDO AFECTADOS POR SINDROMES DE TRANSFUSION PLACENTARIA"},
{ value: "P024",	text: "FETO Y RECIEN NACIDO AFECTADOS POR PROLAPSO DEL CORDON UMBILICAL"},
{ value: "P025",	text: "FETO Y RECIEN NACIDO AFECTADOS POR OTRA COMPRESION DEL CORDON UMBILICAL"},
{ value: "P026",	text: "FETO Y RECIEN NACIDO AFECTADOS POR OTRAS COMPLICACIONES DEL CORDON UMBILICAL Y LAS NO ESPECIFICADAS"},
{ value: "P027",	text: "FETO Y RECIEN NACIDO AFECTADOS POR CORIOAMNIONITIS"},
{ value: "P028",	text: "FETO Y RECIEN NACIDO AFECTADOS POR OTRAS ANORMALIDADES DE LAS MEMBRANAS"},
{ value: "P029",	text: "FETO Y RECIEN NACIDO AFECTADOS POR ANORMALIDAD NO ESPECIFICADA DE LAS MEMBRANAS"},
{ value: "P030",	text: "FETO Y RECIEN NACIDO AFECTADOS POR PARTO Y EXTRACCION DE NALGAS"},
{ value: "P031",	text: "FETO Y RECIEN NACIDO AFECTADOS POR OTRA PRESENTACION ANOMALA, POSICION ANOMALA Y DESPROPORCION DURANTE EL TRABAJO DE PARTO Y EL PARTO"},
{ value: "P032",	text: "FETO Y RECIEN NACIDO AFECTADOS POR PARTO CON FORCEPS"},
{ value: "P033",	text: "FETO Y RECIEN NACIDO AFECTADOS POR PARTO CON VENTOSA EXTRACTORA"},
{ value: "P034",	text: "FETO Y RECIEN NACIDO AFECTADOS POR PARTO POR CESAREA"},
{ value: "P035",	text: "FETO Y RECIEN NACIDO AFECTADOS POR PARTO PRECIPITADO"},
{ value: "P036",	text: "FETO Y RECIEN NACIDO AFECTADOS POR CONTRACCIONES UTERINAS ANORMALES"},
{ value: "P038",	text: "FETO Y RECIEN NACIDO AFECTADOS POR OTRAS COMPLICACIONES ESPECIFICADAS DEL TRABAJO DE PARTO Y DEL PARTO"},
{ value: "P039",	text: "FETO Y RECIEN NACIDO AFECTADOS POR COMPLICACIONES NO ESPECIFICADAS DEL TRABAJO DE PARTO Y DEL PARTO"},
{ value: "P040",	text: "FETO Y RECIEN NACIDO AFECTADOS POR ANESTESIA Y ANALGESIA MATERNA EN EL EMBARAZO, EN EL TRABAJO DE PARTO Y EN EL PARTO"},
{ value: "P041",	text: "FETO Y RECIEN NACIDO AFECTADOS POR OTRAS MEDICACIONES MATERNAS"},
{ value: "P042",	text: "FETO Y RECIEN NACIDO AFECTADOS POR TABAQUISMO DE LA MADRE"},
{ value: "P043",	text: "FETO Y RECIEN NACIDO AFECTADOS POR ALCOHOLISMO DE LA MADRE"},
{ value: "P044",	text: "FETO Y RECIEN NACIDO AFECTADOS POR DROGADICCION MATERNA"},
{ value: "P045",	text: "FETO Y RECIEN NACIDO AFECTADOS POR EL USO MATERNO DE SUSTANCIAS QUIMICAS NUTRICIONALES"},
{ value: "P046",	text: "FETO Y RECIEN NACIDO AFECTADOS POR EXPOSICION MATERNA A SUSTANCIAS QUIMICAS AMBIENTALES"},
{ value: "P048",	text: "FETO Y RECIEN NACIDO AFECTADOS POR OTRAS INFLUENCIAS NOCIVAS DE LA MADRE"},
{ value: "P049",	text: "FETO Y RECIEN NACIDO AFECTADOS POR INFLUENCIAS NOCIVAS DE LA MADRE, NO ESPECIFICADAS"},
{ value: "P050",	text: "BAJO PESO PARA LA EDAD GESTACIONAL"},
{ value: "P051",	text: "PEQUEÑO PARA EDAD GESTACIONAL"},
{ value: "P052",	text: "DESNUTRICION FETAL, SIN MENCION DE PESO O TALLA BAJOS PARA LA EDAD GESTACIONAL"},
{ value: "P059",	text: "RETARDO DEL CRECIMIENTO FETAL, NO ESPECIFICADO"},
{ value: "P070",	text: "PESO EXTREMADAMENTE BAJO AL NACER"},
{ value: "P071",	text: "OTRO PESO BAJO AL NACER"},
{ value: "P072",	text: "INMATURIDAD EXTREMA"},
{ value: "P073",	text: "OTROS RECIEN NACIDOS PRETERMINO"},
{ value: "P080",	text: "RECIEN NACIDO EXCEPCIONALMENTE GRANDE"},
{ value: "P081",	text: "OTROS RECIEN NACIDOS CON SOBREPESO PARA LA EDAD GESTACIONAL"},
{ value: "P082",	text: "RECIEN NACIDO POSTERMINO SIN SOBREPESO PARA SU EDAD GESTACIONAL"},
{ value: "P100",	text: "HEMORRAGIA SUBDURAL DEBIDA A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P101",	text: "HEMORRAGIA CEREBRAL DEBIDA A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P102",	text: "HEMORRAGIA INTRAVENTRICULAR DEBIDA A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P103",	text: "HEMORRAGIA SUBARACNOIDEA DEBIDA A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P104",	text: "DESGARRO TENTORIAL DEBIDO A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P108",	text: "OTRAS HEMORRAGIAS Y LACERACIONES INTRACRANEALES DEBIDAS A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P109",	text: "HEMORRAGIA Y LACERACION INTRACRANEALES NO ESPECIFICADAS, DEBIDAS A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P110",	text: "EDEMA CEREBRAL DEBIDO A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P111",	text: "OTRAS LESIONES ESPECIFICADAS DEL ENCEFALO DEBIDAS A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P112",	text: "LESION NO ESPECIFICADA DEL ENCEFALO, DEBIDA A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P113",	text: "TRAUMATISMO DEL NACIMIENTO EN EL NERVIO FACIAL"},
{ value: "P114",	text: "TRAUMATISMO DEL NACIMIENTO EN OTROS NERVIOS CRANEALES"},
{ value: "P115",	text: "TRAUMATISMO DEL NACIMIENTO EN LA COLUMNA VERTEBRAL Y EN LA MEDULA ESPINAL"},
{ value: "P119",	text: "TRAUMATISMO DEL NACIMIENTO EN EL SISTEMA NERVIOSO CENTRAL, NO ESPECIFICADO"},
{ value: "P120",	text: "CEFALOHEMATOMA DEBIDO A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P121",	text: "CAPUT SUCCEDANEUM DEBIDO A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P122",	text: "HEMORRAGIA EPICRANEAL SUBAPONEUROTICA DEBIDA A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P123",	text: "EQUIMOSIS DEL CUERO CABELLUDO DEBIDA A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P124",	text: "TRAUMATISMO EN EL CUERO CABELLUDO DEL RECIEN NACIDO POR MONITOREO FETAL"},
{ value: "P128",	text: "OTROS TRAUMATISMOS DEL NACIMIENTO EN EL CUERO CABELLUDO"},
{ value: "P129",	text: "TRAUMATISMO DEL NACIMIENTO EN EL CUERO CABELLUDO, NO ESPECIFICADO"},
{ value: "P130",	text: "FRACTURA DEL CRANEO DEBIDA A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P131",	text: "OTROS TRAUMATISMOS DEL CRANEO DURANTE EL NACIMIENTO"},
{ value: "P132",	text: "TRAUMATISMO DEL FEMUR DURANTE EL NACIMIENTO"},
{ value: "P133",	text: "TRAUMATISMO DE OTROS HUESOS LARGOS DURANTE EL NACIMIENTO"},
{ value: "P134",	text: "FRACTURA DE LA CLAVICULA DEBIDA A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P138",	text: "TRAUMATISMO DEL NACIMIENTO EN OTRAS PARTES DEL ESQUELETO"},
{ value: "P139",	text: "TRAUMATISMO NO ESPECIFICADO DEL ESQUELETO DURANTE EL NACIMIENTO"},
{ value: "P140",	text: "PARALISIS DE ERB DEBIDA A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P141",	text: "PARALISIS DE KLUMPKE DEBIDA A TRAUMATISMO DL NACIMIENTO"},
{ value: "P142",	text: "PARALISIS DEL NERVIO FRENICO DEBIDA A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P143",	text: "OTRO TRAUMATISMO DEL PLEXO BRAQUIAL DURANTE EL NACIMIENTO"},
{ value: "P148",	text: "TRAUMATISMO DURANTE EL NACIMIENTO EN OTRAS PARTES DEL SISTEMA NERVIOSO PERIFERICO"},
{ value: "P149",	text: "TRAUMATISMO NO ESPECIFICADO DEL SISTEMA NERVIOSO PERIFERICO DURANTE EL NACIMIENTO"},
{ value: "P150",	text: "LESION DEL HIGADO DURANTE EL NACIMIENTO"},
{ value: "P151",	text: "LESION DEL BAZO DURANTE EL NACIMIENTO"},
{ value: "P152",	text: "TRAUMATISMO DEL MÚSCULO ESTERNOCLEIDOMASTOIDEO DURANTE EL NACIMIENTO"},
{ value: "P153",	text: "TRAUMATISMO OCULAR DURANTE EL NACIMIENTO"},
{ value: "P154",	text: "TRAUMATISMO FACIAL DURANTE EL NACIMIENTO"},
{ value: "P155",	text: "TRAUMATISMO DE LOS GENITALES EXTERNOS DURANTE EL NACIMIENTO"},
{ value: "P156",	text: "NECROSIS GRASA SUBCUTANEA DEBIDA A TRAUMATISMO DEL NACIMIENTO"},
{ value: "P158",	text: "OTROS TRAUMATISMOS ESPECIFICADOS, DURANTE EL NACIMIENTO"},
{ value: "P159",	text: "TRAUMATISMO NO ESPECIFICADO, DURANTE EL NACIMIENTO"},
{ value: "P200",	text: "HIPOXIA INTRAUTERINA NOTADA POR PRIMERA VEZ ANTES DEL INICIO DEL TRABAJO DE PARTO"},
{ value: "P201",	text: "HIPOXIA INTRAUTERINA NOTADA POR PRIMERA VEZ DURANTE EL TRABAJO DE PARTO"},
{ value: "P209",	text: "HIPOXIA INTRAUTERINA, NO ESPECIFICADA"},
{ value: "P210",	text: "ASFIXIA DEL NACIMIENTO, SEVERA"},
{ value: "P211",	text: "ASFIXIA DEL NACIMIENTO, LEVE Y MODERADA"},
{ value: "P219",	text: "ASFIXIA DEL NACIMIENTO, NO ESPECIFICADA"},
{ value: "P220",	text: "SINDROME DE DIFICULTAD RESPIRATORIA DEL RECIEN NACIDO"},
{ value: "P221",	text: "TAQUIPNEA TRANSITORIA DEL RECIEN NACIDO"},
{ value: "P228",	text: "OTRAS DIFICULTADES RESPIRATORIAS DEL RECIEN NACIDO"},
{ value: "P229",	text: "DIFICULTAD RESPIRATORIA DEL RECIEN NACIDO, NO ESPECIFICADA"},
{ value: "P230",	text: "NEUMONIA CONGENITA DEBIDA A AGENTE VIRAL"},
{ value: "P231",	text: "NEUMONIA CONGENITA DEBIDA A CHLAMYDIA"},
{ value: "P232",	text: "NEUMONIA CONGENITA DEBIDA A ESTAFILOCOCOS"},
{ value: "P233",	text: "NEUMONIA CONGENITA DEBIDA A ESTREPTOCOCOS DEL GRUPO B"},
{ value: "P234",	text: "NEUMONIA CONGENITA DEBIDA A ESCHERICHIA COLI"},
{ value: "P235",	text: "NEUMONIA CONGENITA DEBIDA A PSEUDOMONAS"},
{ value: "P236",	text: "NEUMONIA CONGENITA DEBIDA A OTROS AGENTES BACTERIANOS"},
{ value: "P238",	text: "NEUMONIA CONGENITA DEBIDA A OTROS ORGANISMOS"},
{ value: "P239",	text: "NEUMONIA CONGENITA, ORGANISMO NO ESPECIFICADO"},
{ value: "P240",	text: "ASPIRACION NEONATAL DE MECONIO"},
{ value: "P241",	text: "ASPIRACION NEONATAL DEL LIQUIDO AMNIOTICO Y DE MOCO"},
{ value: "P242",	text: "ASPIRACION NEONATAL DE SANGRE"},
{ value: "P243",	text: "ASPIRACION NEONATAL DE LECHE Y ALIMENTO REGURGITADO"},
{ value: "P248",	text: "OTROS SINDROMES DE ASPIRACION NEONATAL"},
{ value: "P249",	text: "SINDROME DE ASPIRACION NEONATAL, SIN OTRA ESPECIFICACION"},
{ value: "P250",	text: "ENFISEMA INTERSTICIAL ORIGINADO EN EL PERIODO PERINATAL"},
{ value: "P251",	text: "NEUMOTORAX ORIGINADO EN EL PERIODO PERINATAL"},
{ value: "P252",	text: "NEUMOMEDIASTINO ORIGINADO EN EL PERIODO PERINATAL"},
{ value: "P253",	text: "NEUMOPERICARDIO ORIGINADO EN EL PERIODO PERINATAL"},
{ value: "P258",	text: "OTRAS AFECCIONES RELACIONADAS CON EL ENFISEMA INTERSTICIAL, ORIGINADAS EN EL PERIODO PERINATAL"},
{ value: "P260",	text: "HEMORRAGIA TRAQUEOBRONQUIAL ORIGINADA EN EL PERIODO PERINATAL"},
{ value: "P261",	text: "HEMORRAGIA PULMONAR MASIVA ORIGINADA EN EL PERIODO PERINATAL"},
{ value: "P268",	text: "OTRAS HEMORRAGIAS PULMONARES ORIGINADAS EN EL PERIODO PERINATAL"},
{ value: "P269",	text: "HEMORRAGIA PULMONAR NO ESPECIFICADA, ORIGINADA EN EL PERIODO PERINATAL"},
{ value: "P270",	text: "SINDROME DE WILSON-MIKITY"},
{ value: "P271",	text: "DISPLASIA BRONCOPULMONAR ORIGINADA EN EL PERIODO PERINATAL"},
{ value: "P278",	text: "OTRAS ENFERMEDADES RESPIRATORIAS CRONICAS ORIGINADAS EN EL PERIODO PERINATAL"},
{ value: "P279",	text: "ENFERMEDAD RESPIRATORIA CRONICA NO ESPECIFICADA ORIGINADA EN EL PERIODO PERINATAL"},
{ value: "P280",	text: "ATELECTASIA PRIMARIA DEL RECIEN NACIDO"},
{ value: "P281",	text: "OTRAS ATELECTASIAS DEL RECIEN NACIDO Y LAS NO ESPECIFICADAS"},
{ value: "P282",	text: "ATAQUE CIANOTICO DEL RECIEN NACIDO"},
{ value: "P283",	text: "APNEA PRIMARIA DEL SUEÑO DEL RECIEN NACIDO"},
{ value: "P284",	text: "OTRAS APNEAS DEL RECIEN NACIDO"},
{ value: "P285",	text: "INSUFICIENCIA RESPIRATORIA DEL RECIEN NACIDO"},
{ value: "P288",	text: "OTROS PROBLEMAS RESPIRATORIOS ESPECIFICADOS DEL RECIEN NACIDO"},
{ value: "P289",	text: "AFECCION RESPIRATORIA NO ESPECIFICADA DEL RECIEN NACIDO"},
{ value: "P290",	text: "INSUFICIENCIA CARDIACA NEONATAL"},
{ value: "P291",	text: "DISRITMIA CARDIACA NEONATAL"},
{ value: "P292",	text: "HIPERTENSION NEONATAL"},
{ value: "P293",	text: "PERSISTENCIA DE LA CIRCULACION FETAL"},
{ value: "P294",	text: "ISQUEMIA MIOCARDICA TRANSITORIA DEL RECIEN NACIDO"},
{ value: "P298",	text: "OTROS TRASTORNOS CARDIOVASCULARES ORIGINADOS EN EL PERIODO PERINATAL"},
{ value: "P299",	text: "TRASTORNO CARDIOVASCULAR NO ESPECIFICADO, ORIGINADO EN EL PERIODO PERINATAL"},
{ value: "P350",	text: "SINDROME DE RUBEOLA CONGENITA"},
{ value: "P351",	text: "INFECCION CITOMEGALOVIRICA CONGENITA"},
{ value: "P352",	text: "INFECCIONES CONGENITAS POR VIRUS DEL HERPES SIMPLE"},
{ value: "P353",	text: "HEPATITIS VIRAL CONGENITA"},
{ value: "P358",	text: "OTRAS ENFERMEDADES VIRALES CONGENITAS"},
{ value: "P359",	text: "ENFERMEDAD VIRAL CONGÉNITA, SIN OTRA ESPECIFICACION"},
{ value: "P360",	text: "SEPSIS DEL RECIEN NACIDO DEBIDA A ESTREPTOCOCO DEL GRUPO B"},
{ value: "P361",	text: "SEPSIS DEL RECIEN NACIDO DEBIDA A OTROS ESTREPTOCOCOS Y A LOS NO ESPECIFICADOS"},
{ value: "P362",	text: "SEPSIS DEL RECIEN NACIDO DEBIDA A STAPHYLOCOCCUS AUREUS"},
{ value: "P363",	text: "SEPSIS DEL RECIEN NACIDO DEBIDA A OTROS ESTAFILOCOCOS Y A LOS NO ESPECIFICADOS"},
{ value: "P364",	text: "SEPSIS DEL RECIEN NACIDO DEBIDA A ESCHERICHIA COLI"},
{ value: "P365",	text: "SEPSIS DEL RECIEN NACIDO DEBIDA A ANAEROBIOS"},
{ value: "P368",	text: "SEPSIS DEL RECIEN NACIDO DEBIDA A OTRAS BACTERIAS"},
{ value: "P369",	text: "SEPSIS BACTERIANA DEL RECIEN NACIDO, NO ESPECIFICADA"},
{ value: "P370",	text: "TUBERCULOSIS CONGENITA"},
{ value: "P371",	text: "TOXOPLASMOSIS CONGENITA"},
{ value: "P372",	text: "LISTERIOSIS CONGENITA (DISEMINADA)"},
{ value: "P373",	text: "PALUDISMO CONGENITO POR PLASMODIUM FALCIPARUM"},
{ value: "P374",	text: "OTROS PALUDISMOS CONGENITOS"},
{ value: "P375",	text: "CANDIDIASIS NEONATAL"},
{ value: "P378",	text: "OTRAS ENFERMEDADES NEONATALES INFECCIOSAS O PARASITARIAS ESPECIFICADAS"},
{ value: "P379",	text: "ENFERMEDAD INFECCIOSA O PARASITARIA CONGENITA, NO ESPECIFICADA"},
{ value: "P38X",	text: "ONFALITIS DEL RECIEN NACIDO CON O SIN HEMORRAGIA LEVE"},
{ value: "P390",	text: "MASTITIS INFECCIOSA NEONATAL"},
{ value: "P391",	text: "CONJUNTIVITIS Y DACRIOCISTITIS NEONATALES"},
{ value: "P392",	text: "INFECCION INTRAAMNIOTICA DEL FETO, NO CLASIFICADA EN OTRA PARTE"},
{ value: "P393",	text: "INFECCION NEONATAL DE LAS VIAS URINARIAS"},
{ value: "P394",	text: "INFECCION CUTANEA NEONATAL"},
{ value: "P398",	text: "OTRAS INFECCIONES ESPECIFICADAS PROPIAS DEL PERIODO PERINATAL"},
{ value: "P399",	text: "INFECCION PROPIA DEL PERIODO PERINATAL, NO ESPECIFICADA"},
{ value: "P500",	text: "PERDIDA DE SANGRE FETAL POR VASA PREVIA"},
{ value: "P501",	text: "PERDIDA DE SANGRE FETAL POR RUPTURA DEL CORDON UMBILICAL"},
{ value: "P502",	text: "PERDIDA DE SANGRE FETAL POR LA PLACENTA"},
{ value: "P503",	text: "HEMORRAGIA FETAL HACIA OTRO GEMELO"},
{ value: "P504",	text: "HEMORRAGIA FETAL HACIA LA CIRCULACION MATERNA"},
{ value: "P505",	text: "PERDIDA DE SANGRE FETAL POR EL CORTE DEL CORDON UMBILICAL EN EL OTRO GEMELO"},
{ value: "P508",	text: "OTRAS PERDIDAS DE SANGRE FETAL"},
{ value: "P509",	text: "PERDIDA DE SANGRE FETAL NO ESPECIFICADA"},
{ value: "P510",	text: "HEMORRAGIA UMBILICAL MASIVA DEL RECIEN NACIDO"},
{ value: "P518",	text: "OTRAS HEMORRAGIAS UMBILICALES DEL RECIEN NACIDO"},
{ value: "P519",	text: "HEMORRAGIA UMBILICAL DEL RECIEN NACIDO, SIN OTRA ESPECIFICACION"},
{ value: "P520",	text: "HEMORRAGIA INTRAVENTRICULAR (NO TRAUMATICA) GRADO 1, DEL FETO Y DEL RECIEN NACIDO"},
{ value: "P521",	text: "HEMORRAGIA INTRAVENTRICULAR (NO TRAUMATICA) GRADO 2, DEL FETO Y DEL RECIEN NACIDO"},
{ value: "P522",	text: "HEMORRAGIA INTRAVENTRICULAR (NO TRAUMATICA) GRADO 3, DEL FETO Y DEL RECIEN NACIDO"},
{ value: "P523",	text: "HEMORRAGIA INTRAVENTRICULAR (NO TRAUMATICA) DEL FETO Y DEL RECIEN NACIDO, SIN OTRA ESPECIFICACION"},
{ value: "P524",	text: "HEMORRAGIA INTRACEREBRAL (NO TRAUMATICA) DEL FETO Y DEL RECIEN NACIDO"},
{ value: "P525",	text: "HEMORRAGIA SUBARACNOIDEA (NO TRAUMATICA) DEL FETO Y DEL RECIEN NACIDO"},
{ value: "P526",	text: "HEMORRAGIA CEREBELOSA Y DE LA FOSA POSTERIOR (NO TRAUMATICA) DEL FETO Y DEL RECIEN NACIDO"},
{ value: "P528",	text: "OTRAS HEMORRAGIAS INTRACRANEALES (NO TRAUMATICAS) DEL FETO Y DEL RECIEN NACIDO"},
{ value: "P529",	text: "HEMORRAGIA INTRACRANEAL (NO TRAUMATICA) DEL FETO Y DEL RECIEN NACIDO, SIN OTRA ESPECIFICACION"},
{ value: "P53X",	text: "ENFERMEDAD HEMORRAGICA DEL FETO Y DEL RECIEN NACIDO"},
{ value: "P540",	text: "HEMATEMESIS NEONATAL"},
{ value: "P541",	text: "MELENA NEONATAL"},
{ value: "P542",	text: "HEMORRAGIA RECTAL NEONATAL"},
{ value: "P543",	text: "OTRAS HEMORRAGIAS GASTROINTESTINALES NEONATALES"},
{ value: "P544",	text: "HEMORRAGIA SUPRARRENAL NEONATAL"},
{ value: "P545",	text: "HEMORRAGIA CUTANEA NEONATAL"},
{ value: "P546",	text: "HEMORRAGIA VAGINAL NEONATAL"},
{ value: "P548",	text: "OTRAS HEMORRAGIAS FETALES Y NEONATALES ESPECIFICADAS"},
{ value: "P549",	text: "HEMORRAGIA NEONATAL, NO ESPECIFICADA"},
{ value: "P550",	text: "INCOMPATIBILIDAD RH DEL FETO Y DEL RECIEN NACIDO"},
{ value: "P551",	text: "INCOMPATIBILIDAD ABO DEL FETO Y DEL RECIEN NACIDO"},
{ value: "P558",	text: "OTRAS ENFERMEDADES HEMOLITICAS DEL FETO Y DEL RECIEN NACIDO"},
{ value: "P559",	text: "ENFERMEDAD HEMOLITICA DEL FETO Y DEL RECIEN NACIDO, NO ESPECIFICADA"},
{ value: "P560",	text: "HIDROPESIA FETAL DEBIDA A INCOMPATIBILIDAD"},
{ value: "P569",	text: "HIDROPESIA FETAL DEBIDA A OTRAS ENFERMEDADES HEMOLITICAS ESPECIFICADAS Y A LAS NO ESPECIFICADAS"},
{ value: "P570",	text: "KERNICTERUS DEBIDO A INCOMPATIBILIDAD"},
{ value: "P578",	text: "KERNICTERUS DEBIDO A OTRAS CAUSAS ESPECIFICADAS"},
{ value: "P579",	text: "KERNICTERUS, NO ESPECIFICADO"},
{ value: "P580",	text: "ICTERICIA NEONATAL DEBIDA A CONTUSION"},
{ value: "P581",	text: "ICTERICIA NEONATAL DEBIDA A HEMORRAGIA"},
{ value: "P582",	text: "ICTERICIA NEONATAL DEBIDA A INFECCION"},
{ value: "P583",	text: "ICTERICIA NEONATAL DEBIDA A POLICITEMIA"},
{ value: "P584",	text: "ICTERICIA NEONATAL DEBIDA A DROGAS O TOXINAS TRANSMITIDAS POR LA MADRE O ADMINISTRADAS AL RECIEN NACIDO"},
{ value: "P585",	text: "ICTERICIA NEONATAL DEBIDA A DEGLUCION DE SANGRE MATERNA"},
{ value: "P588",	text: "ICTERICIA NEONATAL DEBIDA A OTRAS HEMOLISIS EXCESIVAS ESPECIFICADAS"},
{ value: "P589",	text: "ICTERICIA NEONATAL DEBIDA A HEMOLISIS EXCESIVA SIN OTRA ESPECIFICACION"},
{ value: "P590",	text: "ICTERICIA NEONATAL ASOCIADA CON EL PARTO ANTES DE TERMINO"},
{ value: "P591",	text: "SINDROME DE LA BILIS ESPESA"},
{ value: "P592",	text: "ICTERICIA NEONATAL DEBIDA A OTRA LESION HEPATICA ESPECIFICADA O NO"},
{ value: "P593",	text: "ICTERICIA NEONATAL POR INHIBIDOR DE LA LECHE MATERNA"},
{ value: "P598",	text: "ICTERICIA NEONATAL POR OTRAS CAUSAS ESPECIFICADAS"},
{ value: "P599",	text: "ICTERICIA NEONATAL, NO ESPECIFICADA"},
{ value: "P60X",	text: "COAGULACION INTRAVASCULAR DISEMINADA EN EL FETO Y EL RECIEN NACIDO"},
{ value: "P610",	text: "TROMBOCITOPENIA NEONATAL TRANSITORIA"},
{ value: "P611",	text: "POLICITEMIA NEONATAL"},
{ value: "P612",	text: "ANEMIA DE LA PREMATURIDAD"},
{ value: "P613",	text: "ANEMIA CONGENITA DEBIDA A PERDIDA DE SANGRE FETAL"},
{ value: "P614",	text: "OTRAS ANEMIAS CONGENITAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "P615",	text: "NEUTROPENIA NEONATAL TRANSITORIA"},
{ value: "P616",	text: "OTROS TRASTORNOS NEONATALES TRANSITORIOS DE LA COAGULACIÓN"},
{ value: "P618",	text: "OTROS TRASTORNOS HEMATOLOGICOS PERINATALES ESPECIFICADOS"},
{ value: "P619",	text: "TRASTORNO HEMATOLOGICO PERINATAL, NO ESPECIFICADO"},
{ value: "P700",	text: "SINDROME DEL RECIEN NACIDO DE MADRE CON DIABETES GESTACIONAL"},
{ value: "P701",	text: "SINDROME DEL RECIEN NACIDO DE MADRE CON DIABETICA"},
{ value: "P702",	text: "DIABETES MELLITUS NEONATAL"},
{ value: "P703",	text: "HIPOGLICEMIA NEONATAL YATROGENICA"},
{ value: "P704",	text: "OTRAS HIPOGLICEMIAS NEONATALES"},
{ value: "P708",	text: "OTROS TRASTORNOS TRANSITORIOS DEL METABOLISMO DE LOS CARBOHIDRATOS EN EL FETO Y EL RECIEN NACIDO"},
{ value: "P709",	text: "TRASTORNO TRANSITORIO NO ESPECIFICADO DEL METABOLISMO DE LOS CARBOHIDRATOS EN EL FETO Y EL RECIEN NACIDO"},
{ value: "P710",	text: "HIPOCALCEMIA DEL RECIEN NACIDO DEBIDA A LA LECHE DE VACA"},
{ value: "P711",	text: "OTRA HIPOCALCEMIA NEONATAL"},
{ value: "P712",	text: "HIPOMAGNESEMIA NEONATAL"},
{ value: "P713",	text: "TETANIA NEONATAL SIN MENCION DE DEFICIENCIA DE CALCIO O DE MAGNESIO"},
{ value: "P714",	text: "HIPOPARATIROIDISMO NEONATAL TRANSITORIO"},
{ value: "P718",	text: "OTROS TRASTORNOS NEONATALES TRANSITORIOS DEL METABOLISMO DEL CALCIO Y DEL MAGNESIO"},
{ value: "P719",	text: "TRASTORNO NEONATAL TRANSITORIO NO ESPECIFICADO DEL METABOLISMO DEL CALCIO Y DEL MAGNESIO"},
{ value: "P720",	text: "BOCIO NEONATAL, NO CLASIFICADO EN OTRA PARTE"},
{ value: "P721",	text: "HIPERTIROIDISMO NEONATAL TRANSITORIO"},
{ value: "P722",	text: "OTROS TRASTORNOS NEONATALES TRANSITORIOS DE LA FUNCION TIROIDEA, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "P728",	text: "OTROS TRASTORNOS ENDOCRINOS NEONATALES TRANSITORIOS ESPECIFICADOS"},
{ value: "P729",	text: "TRASTORNO ENDOCRINO NEONATAL TRANSITORIO, NO ESPECIFICADO"},
{ value: "P740",	text: "ACIDOSIS METABOLICA TARDIA DEL RECIEN NACIDO"},
{ value: "P741",	text: "DESHIDRATACION DEL RECIEN NACIDO"},
{ value: "P742",	text: "ALTERACIONES DEL EQUILIBRIO DEL SODIO EN EL RECIEN NACIDO"},
{ value: "P743",	text: "ALTERACIONES DEL EQUILIBRIO DEL POTASIO EN EL RECIEN NACIDO"},
{ value: "P744",	text: "OTRAS ALTERACIONES ELECTROLITICAS TRANSITORIAS DEL RECIEN NACIDO"},
{ value: "P745",	text: "TIROSINEMIA TRANSITORIA DEL RECIEN NACIDO"},
{ value: "P748",	text: "OTRAS ALTERACIONES METABOLICAS TRANSITORIAS DEL RECIEN NACIDO"},
{ value: "P749",	text: "TRASTORNO METABOLICO TRANSITORIO DEL RECIEN NACIDO, NO ESPECIFICADO"},
{ value: "P75X",	text: "ILEO MECONIAL (E84.1†)"},
{ value: "P760",	text: "SINDROME DEL TAPON DE MECONIO"},
{ value: "P761",	text: "ILEO TRANSITORIO DEL RECIEN NACIDO"},
{ value: "P762",	text: "OBSTRUCCION INTESTINAL DEBIDA A LA LECHE ESPESA"},
{ value: "P768",	text: "OTRAS OBSTRUCCIONES INTESTINALES ESPECIFICADAS DEL RECIEN NACIDO"},
{ value: "P769",	text: "OBSTRUCCION INTESTINAL DEL RECIEN NACIDO, NO ESPECIFICADA"},
{ value: "P77X",	text: "ENTEROCOLITIS NECROTIZANTE DEL FETO Y DEL RECIEN NACIDO"},
{ value: "P780",	text: "PERFORACION INTESTINAL PERINATAL"},
{ value: "P781",	text: "OTRAS PERITONITIS NEONATALES"},
{ value: "P782",	text: "HEMATEMESIS Y MELENA NEONATALES DEBIDAS A LA DEGLUCION DE SANGRE MATERNA"},
{ value: "P783",	text: "DIARREA NEONATAL NO INFECCIOSA"},
{ value: "P788",	text: "OTROS TRASTORNOS PERINATALES ESPECIFICOS DEL SISTEMA DIGESTIVO"},
{ value: "P789",	text: "TRASTORNO PERINATAL DEL SISTEMA DIGESTIVO, NO ESPECIFICADO"},
{ value: "P800",	text: "SINDROME DE ENFRIAMIENTO"},
{ value: "P808",	text: "OTRAS HIPOTERMIAS DEL RECIEN NACIDO"},
{ value: "P809",	text: "HIPOTERMIA DEL RECIEN NACIDO, NO ESPECIFICADA"},
{ value: "P810",	text: "HIPERTERMIA DEL RECIEN NACIDO INDUCIDA POR LAS CONDICIONES AMBIENTALES"},
{ value: "P818",	text: "OTRAS ALTERACIONES ESPECIFICADAS DE LA REGULACION DE LA TEMPERATURA DEL RECIEN NACIDO"},
{ value: "P819",	text: "ALTERACION NO ESPECIFICADA DE LA REGULACION DE LA TEMPERATURA EN EL RECIEN NACIDO"},
{ value: "P830",	text: "ESCLEREMA NEONATAL"},
{ value: "P831",	text: "ERITEMA TOXICO NEONATAL"},
{ value: "P832",	text: "HIDROPESIA FETAL NO DEBIDA A ENFERMEDAD HEMOLITICA"},
{ value: "P833",	text: "OTROS EDEMAS Y LOS NO ESPECIFICADOS, PROPIOS DEL FETO Y DEL RECIEN NACIDO"},
{ value: "P834",	text: "INGURGITACION MAMARIA DEL RECIEN NACIDO"},
{ value: "P835",	text: "HIDROCELE CONGENITO"},
{ value: "P836",	text: "POLIPO UMBILICAL DEL RECIEN NACIDO"},
{ value: "P838",	text: "OTRAS AFECCIONES ESPECIFICADAS DE LA PIEL, PROPIAS DEL FETO Y DEL RECIEN NACIDO"},
{ value: "P839",	text: "AFECCION NO ESPECIFICADA DE LA PIEL, PROPIAS DEL FETO Y DEL RECIEN NACIDO"},
{ value: "P90X",	text: "CONVULSIONES DEL RECIEN NACIDO"},
{ value: "P910",	text: "ISQUEMIA CEREBRAL NEONATAL"},
{ value: "P911",	text: "QUISTES PERIVENTRICULARES ADQUIRIDOS DEL RECIEN NACIDO"},
{ value: "P912",	text: "LEUCOMALACIA NEONATAL"},
{ value: "P913",	text: "IRRITABILIDAD CEREBRAL NEONATAL"},
{ value: "P914",	text: "DEPRESION CEREBRAL NEONATAL"},
{ value: "P915",	text: "COMA NEONATAL"},
{ value: "P918",	text: "OTRAS ALTERACIONES CEREBRALES ESPECIFICADAS DEL RECIEN NACIDO"},
{ value: "P919",	text: "ALTERACION CEREBRAL NO ESPECIFICADA DEL RECIEN NACIDO"},
{ value: "P920",	text: "VOMITOS DEL RECIEN NACIDO"},
{ value: "P921",	text: "REGURGITACION Y RUMIACION DEL RECIEN NACIDO"},
{ value: "P922",	text: "LENTITUD EN LA INGESTION DE ALIMENTOS DEL RECIEN NACIDO"},
{ value: "P923",	text: "HIPOALIMENTACION DEL RECIEN NACIDO"},
{ value: "P924",	text: "HIPERALIMENTACION DEL RECIEN NACIDO"},
{ value: "P925",	text: "DIFICULTAD NEONATAL EN LA LACTANCIA MATERNA"},
{ value: "P928",	text: "OTROS PROBLEMAS DE ALIMENTACION DEL RECIEN NACIDO"},
{ value: "P929",	text: "PROBLEMA NO ESPECIFICADO DE LA ALIMENTACION DEL RECIEN NACIDO"},
{ value: "P93X",	text: "REACCIONES E INTOXICACIONES DEBIDAS A DROGAS ADMINISTRADAS AL FETO Y AL RECIEN NACIDO"},
{ value: "P940",	text: "MIASTENIA GRAVE NEONATAL TRANSITORIA"},
{ value: "P941",	text: "HIPERTONIA CONGENITA"},
{ value: "P942",	text: "HIPOTONIA CONGENITA"},
{ value: "P948",	text: "OTROS TRASTORNOS DEL TONO MUSCULAR EN EL RECIEN NACIDO"},
{ value: "P949",	text: "TRASTORNO NO ESPECIFICADO DEL TONO MUSCULAR EN EL RECIEN NACIDO"},
{ value: "P95X",	text: "MUERTE FETAL DE CAUSA NO ESPECIFICADA"},
{ value: "P960",	text: "INSUFICIENCIA RENAL CONGENITA"},
{ value: "P961",	text: "SINTOMAS NEONATALES DE ABSTINENCIA POR DROGADICCION MATERNA"},
{ value: "P962",	text: "SINTOMAS DE ABSTINENCIA POR EL USO TERAPEUTICO DE DROGAS EN EL RECIEN NACIDO"},
{ value: "P963",	text: "AMPLITUD DE LA SUTURAS CRANEALES DEL RECIEN NACIDO"},
{ value: "P964",	text: "TERMINACION DEL EMBARAZO, QUE AFECTA AL FETO Y AL RECIEN NACIDO"},
{ value: "P965",	text: "COMPLICACIONES DE PROCEDIMIENTOS INTRAUTERINOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "P968",	text: "OTRAS AFECCIONES ESPECIFICADAS ORIGINADAS EN EL PERIODO PERINATAL"},
{ value: "P969",	text: "AFECCION NO ESPECIFICADA ORIGINADA EN EL PERIODO PERINATAL"},
{ value: "Q000",	text: "ANENCEFALIA"},
{ value: "Q001",	text: "CRANEORRAQUISQUISIS"},
{ value: "Q002",	text: "INIENCEFALIA"},
{ value: "Q010",	text: "ENCEFALOCELE FRONTAL"},
{ value: "Q011",	text: "ENCEFALOCELE NASOFRONTAL"},
{ value: "Q012",	text: "ENCEFALOCELE OCCIPITAL"},
{ value: "Q018",	text: "ENCEFALOCELE DE OTROS SITIOS"},
{ value: "Q019",	text: "ENCEFALOCELE, NO ESPECIFICADO"},
{ value: "Q02X",	text: "MICROCEFALIA"},
{ value: "Q030",	text: "MALFORMACIONES DEL ACUEDUCTO DE SILVIO"},
{ value: "Q031",	text: "ATRESIA DE LOS AGUJEROS DE MAGENDIE Y DE LUSCHKA"},
{ value: "Q038",	text: "OTROS HIDROCEFALOS CONGENITOS"},
{ value: "Q039",	text: "HIDROCEFALO CONGENITO, NO ESPECIFICADO"},
{ value: "Q040",	text: "MALFORMACIONES CONGENITAS DEL CUERPO CALLOSO"},
{ value: "Q041",	text: "ARRINENCEFALIA"},
{ value: "Q042",	text: "HOLOPROSENCEFALIA"},
{ value: "Q043",	text: "OTRAS ANOMALIAS HIPOPLASICAS DEL ENCEFALO"},
{ value: "Q044",	text: "DISPLASIA OPTICOSEPTAL"},
{ value: "Q045",	text: "MEGALENCEFALIA"},
{ value: "Q046",	text: "QUISTES CEREBRALES CONGENITOS"},
{ value: "Q048",	text: "OTRAS MALFORMACIONES CONGENITAS DEL ENCEFALO, ESPECIFICADAS"},
{ value: "Q049",	text: "MALFORMACION CONGENITA DEL ENCEFALO, NO ESPECIFICADA"},
{ value: "Q050",	text: "ESPINA BIFIDA CERVICAL CON HIDROCEFALO"},
{ value: "Q051",	text: "ESPINA BIFIDA TORACICA CON HIDROCEFALO"},
{ value: "Q052",	text: "ESPINA BIFIDA LUMBAR CON HIDROCEFALO"},
{ value: "Q053",	text: "ESPINA BIFIDA SACRA CON HIDROCEFALO"},
{ value: "Q054",	text: "ESPINA BIFIDA CON HIDROCEFALO, SIN OTRA ESPECIFICACION"},
{ value: "Q055",	text: "ESPINA BIFIDA CERVICAL SIN HIDROCEFALO"},
{ value: "Q056",	text: "ESPINA BIFIDA TORACICA SIN HIDROCEFALO"},
{ value: "Q057",	text: "ESPINA BIFIDA LUMBAR SIN HIDROCEFALO"},
{ value: "Q058",	text: "ESPINA BIFIDA SACRA SIN HIDROCEFALO"},
{ value: "Q059",	text: "ESPINA BIFIDA, NO ESPECIFICADA"},
{ value: "Q060",	text: "AMIELIA"},
{ value: "Q061",	text: "HIPOPLASIA Y DISPLASIA DE LA MEDULA ESPINAL"},
{ value: "Q062",	text: "DIASTEMATOMIELIA"},
{ value: "Q063",	text: "OTRAS ANOMALIAS CONGENITAS DE LA COLA DE CABALLO"},
{ value: "Q064",	text: "HIDROMIELIA"},
{ value: "Q068",	text: "OTRAS MALFORMACIONES CONGENITAS ESPECIFICADAS DE LA MEDULA ESPINAL"},
{ value: "Q069",	text: "MALFORMACION CONGENITA DE LA MEDULA ESPINAL, NO ESPECIFICADA"},
{ value: "Q070",	text: "SINDROME DE ARNOLD-CHIARI"},
{ value: "Q078",	text: "OTRAS MALFORMACIONES CONGENITAS DEL SISTEMA NERVIOSO, ESPECIFICADAS"},
{ value: "Q079",	text: "MALFORMACION CONGENITA DEL SISTEMA NERVIOSO, NO ESPECIFICADA"},
{ value: "Q100",	text: "BLEFAROPTOSIS CONGENITA"},
{ value: "Q101",	text: "ECTROPION CONGENITO"},
{ value: "Q102",	text: "ENTROPION CONGENITO"},
{ value: "Q103",	text: "OTRAS MALFORMACIONES CONGENITAS DE LOS PARPADOS"},
{ value: "Q104",	text: "AUSENCIA Y AGENESIA DEL APARATO LAGRIMAL"},
{ value: "Q105",	text: "ESTENOSIS Y ESTRECHEZ CONGENITAS DEL CONDUCTO LAGRIMAL"},
{ value: "Q106",	text: "OTRAS MALFORMACIONES CONGENITAS DEL APARATO LAGRIMAL"},
{ value: "Q107",	text: "MALFORMACION CONGENITA DE LA ORBITA"},
{ value: "Q110",	text: "GLOBO OCULAR QUISTICO"},
{ value: "Q111",	text: "OTRAS ANOFTALMIAS"},
{ value: "Q112",	text: "MICROFTALMIA"},
{ value: "Q113",	text: "MACROFTALMIA"},
{ value: "Q120",	text: "CATARATA CONGENITA"},
{ value: "Q121",	text: "DESPLAZAMIENTO CONGENITO DEL CRISTALINO"},
{ value: "Q122",	text: "COLOBOMA DEL CRISTALINO"},
{ value: "Q123",	text: "AFAQUIA CONGENITA"},
{ value: "Q124",	text: "ESFEROFAQUIA"},
{ value: "Q128",	text: "OTRAS MALFORMACIONES CONGENITAS DEL CRISTALINO"},
{ value: "Q129",	text: "MALFORMACION CONGENITA DEL CRISTALINO, NO ESPECIFICADA"},
{ value: "Q130",	text: "COLOBOMA DEL IRIS"},
{ value: "Q131",	text: "AUSENCIA DEL IRIS"},
{ value: "Q132",	text: "OTRAS MALFORMACIONES DEL IRIS"},
{ value: "Q133",	text: "OPACIDAD CORNEAL CONGENITA"},
{ value: "Q134",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA CORNEA"},
{ value: "Q135",	text: "ESCLEROTICA AZUL"},
{ value: "Q138",	text: "OTRAS MALFORMACIONES CONGENITAS DEL SEGMENTO ANTERIOR DEL OJO"},
{ value: "Q139",	text: "MALFORMACION CONGENITA DEL SEGMENTO ANTERIOR DEL OJO, NO ESPECIFICADA"},
{ value: "Q140",	text: "MALFORMACION CONGENITA DEL HUMOR VITREO"},
{ value: "Q141",	text: "MALFORMACION CONGENITA DE LA RETINA"},
{ value: "Q142",	text: "MALFORMACION CONGENITA DEL DISCO OPTICO"},
{ value: "Q143",	text: "MALFORMACION CONGENITA DE LA COROIDES"},
{ value: "Q148",	text: "OTRAS MALFORMACIONES CONGENITAS DEL SEGMENTO POSTERIOR DEL OJO"},
{ value: "Q149",	text: "MALFORMACION CONGENITA DEL SEGMENTO POSTERIOR DEL OJO, NO ESPECIFICADA"},
{ value: "Q150",	text: "GLAUCOMA CONGENITO"},
{ value: "Q158",	text: "OTRAS MALFORMACIONES CONGENITAS DEL OJO, ESPECIFICADAS"},
{ value: "Q159",	text: "MALFORMACIONES CONGENITAS DEL OJO, NO ESPECIFICADAS"},
{ value: "Q160",	text: "AUSENCIA CONGENITA DEL PABELLON (DE LA OREJA)"},
{ value: "Q161",	text: "AUSENCIA CONGENITA, ATRESIA O ESTRECHEZ DEL CONDUCTO AUDITIVO (EXTERNO)"},
{ value: "Q162",	text: "AUSENCIA DE LA TROMPA DE EUSTAQUIO"},
{ value: "Q163",	text: "MALFORMACION CONGENITA DE LOS HUESILLOS DEL OIDO"},
{ value: "Q164",	text: "OTRAS MALFORMACIONES CONGENITAS DEL OIDO MEDIO"},
{ value: "Q165",	text: "MALFORMACION CONGENITA DEL OIDO INTERNO"},
{ value: "Q169",	text: "MALFORMACION CONGENITA DEL OIDO QUE CAUSA ALTERACION DE LA AUDICION, SIN OTRA ESPECIFICACION"},
{ value: "Q170",	text: "OREJA SUPERNUMERARIA"},
{ value: "Q171",	text: "MACROTIA"},
{ value: "Q172",	text: "MICROTIA"},
{ value: "Q173",	text: "OTRAS DEFORMIDADES DEL PABELLON AURICULAR"},
{ value: "Q174",	text: "ANOMALIA DE LA POSICION DE LA OREJA"},
{ value: "Q175",	text: "OREJA PROMINENTE"},
{ value: "Q178",	text: "OTRAS MALFORMACIONES CONGENITAS DEL OIDO, ESPECIFICADAS"},
{ value: "Q179",	text: "MALFORMACION CONGENITA DEL OIDO, NO ESPECIFICADA"},
{ value: "Q180",	text: "SENO, FISTULA O QUISTE DE LA HENDIDURA BRANQUIAL"},
{ value: "Q181",	text: "SENO Y QUISTE PREAURICULAR"},
{ value: "Q182",	text: "OTRAS MALFORMACIONES DE LAS HENDIDURAS BRANQUIALES"},
{ value: "Q183",	text: "PTERIGION DEL CUELLO"},
{ value: "Q184",	text: "MACROSTOMIA"},
{ value: "Q185",	text: "MICROSTOMIA"},
{ value: "Q186",	text: "MACROQUEILIA"},
{ value: "Q187",	text: "MICROQUEILIA"},
{ value: "Q188",	text: "OTRAS MALFORMACIONES CONGENITAS ESPECIFICADAS DE CARA Y CUELLO"},
{ value: "Q189",	text: "MALFORMACION CONGENITA DE LA CARA Y DEL CUELLO, NO ESPECIFICADA"},
{ value: "Q200",	text: "TRONCO ARTERIOSO COMUN"},
{ value: "Q201",	text: "TRANSPOSICION DE LOS GRANDES VASOS DEL VENTRICULO DERECHO"},
{ value: "Q202",	text: "TRANSPOSICION DE LOS GRANDES VASOS DEL VENTRICULO IZQUIERDO"},
{ value: "Q203",	text: "DISCORDANCIA DE LA CONEXIÓN VENTRICULOARTERIAL"},
{ value: "Q204",	text: "VENTRICULO CON DOBLE ENTRADA"},
{ value: "Q205",	text: "DISCORDANCIA DE LA CONEXIÓN AURICULOVENTRICULAR"},
{ value: "Q206",	text: "ISOMERISMO DE LOS APENDICES AURICULARES"},
{ value: "Q208",	text: "OTRAS MALFORMACIONES CONGENITAS DE LAS CAMARAS CARDIACAS Y SUS CONEXIONES"},
{ value: "Q209",	text: "MALFORMACION CONGENITAS DE LAS CAMARAS CARDIACAS Y SUS CONEXIONES, NO ESPECIFICADA"},
{ value: "Q210",	text: "DEFECTO DEL TABIQUE VENTRICULAR"},
{ value: "Q211",	text: "DEFECTO DEL TABIQUE AURICULAR"},
{ value: "Q212",	text: "DEFECTO DEL TABIQUE AURICULOVENTRICULAR"},
{ value: "Q213",	text: "TETRALOGIA DE FALLOT"},
{ value: "Q214",	text: "DEFECTO DEL TABIQUE AORTOPULMONAR"},
{ value: "Q218",	text: "OTRAS MALFORMACIONES CONGENITAS DE LOS TABIQUES CARDIACOS"},
{ value: "Q219",	text: "MALFORMACION CONGENITA DEL TABIQUE CARDIACO, NO ESPECIFICADA"},
{ value: "Q220",	text: "ATRESIA DE LA VALVULA PULMONAR"},
{ value: "Q221",	text: "ESTENOSIS CONGENITA DE LA VALVULA PULMONAR"},
{ value: "Q222",	text: "INSUFICIENCIA CONGENITA DE LA VALVULA PULMONAR"},
{ value: "Q223",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA VALVULA PULMONAR"},
{ value: "Q224",	text: "ESTENOSIS CONGENITA DE LA VALVULA TRICUSPIDE"},
{ value: "Q225",	text: "ANOMALIA DE EBSTEIN"},
{ value: "Q226",	text: "SINDROME DE HIPOPLASIA DEL CORAZON DERECHO"},
{ value: "Q228",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA VALVULA TRICUSPIDE"},
{ value: "Q229",	text: "MALFORMACION CONGENITA DE LA VALVULA TRICUSPIDE, NO ESPECIFICADA"},
{ value: "Q230",	text: "ESTENOSIS CONGENITA DE LA VALVULA AORTICA"},
{ value: "Q231",	text: "INSUFICIENCIA CONGENITA DE LA VALVULA AORTICA"},
{ value: "Q232",	text: "ESTENOSIS MITRAL CONGENITA"},
{ value: "Q233",	text: "INSUFICIENCIA MITRAL CONGENITA"},
{ value: "Q234",	text: "SINDROME DE HIPOPLASIA DEL CORAZON IZQUIERDO"},
{ value: "Q238",	text: "OTRAS MALFORMACIONES CONGENITAS DE LAS VALVULAS AORTICA Y MITRAL"},
{ value: "Q239",	text: "MALFORMACION CONGENITA DE LAS VALVULAS AORTICA Y MITRAL, NO ESPECIFICADA"},
{ value: "Q240",	text: "DEXTROCARDIA"},
{ value: "Q241",	text: "LEVOCARDIA"},
{ value: "Q242",	text: "CORAZON TRIAURICULAR"},
{ value: "Q243",	text: "ESTENOSIS DEL INFUNDIBULO PULMONAR"},
{ value: "Q244",	text: "ESTENOSIS SUBAORTICA CONGENITA"},
{ value: "Q245",	text: "MALFORMACION DE LOS VASOS CORONARIOS"},
{ value: "Q246",	text: "BLOQUEO CARDIACO CONGENITO"},
{ value: "Q248",	text: "OTRAS MALFORMACIONES CONGENITAS DEL CORAZON, ESPECIFICADAS"},
{ value: "Q249",	text: "MALFORMACION CONGENITA DEL CORAZON, NO ESPECIFICADA"},
{ value: "Q250",	text: "CONDUCTO ARTERIOSO PERMEABLE"},
{ value: "Q251",	text: "COARTACION DE LA AORTA"},
{ value: "Q252",	text: "ATRESIA DE LA AORTA"},
{ value: "Q253",	text: "ESTENOSIS DE LA AORTA"},
{ value: "Q254",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA AORTA"},
{ value: "Q255",	text: "ATRESIA DE LA ARTERIA PULMONAR"},
{ value: "Q256",	text: "ESTENOSIS DE LA ARTERIA PULMONAR"},
{ value: "Q257",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA ARTERIA PULMONAR"},
{ value: "Q258",	text: "OTRAS MALFORMACIONES CONGENITAS DE LAS GRANDES ARTERIAS"},
{ value: "Q259",	text: "MALFORMACION CONGENITA DE LAS GRANDES ARTERIAS, NO ESPECIFICADA"},
{ value: "Q260",	text: "ESTENOSIS CONGENITA DE LA VENA CAVA"},
{ value: "Q261",	text: "PERSISTENCIA DE LA VENA CAVA SUPERIOR IZQUIERDA"},
{ value: "Q262",	text: "CONEXIÓN ANOMALA TOTAL DE LAS VENAS PULMONARES"},
{ value: "Q263",	text: "CONEXIÓN ANOMALA PARCIAL DE LAS VENAS PULMONARES"},
{ value: "Q264",	text: "CONEXIÓN ANOMALA DE LAS VENAS PULMONARES, SIN OTRA ESPECIFICACION"},
{ value: "Q265",	text: "CONEXIÓN ANOMALA DE LA VENA PORTA"},
{ value: "Q266",	text: "FISTULA ARTERIA HEPATICA-VENA PORTA"},
{ value: "Q268",	text: "OTRAS MALFORMACIONES CONGENITAS DE LAS GRANDES VENAS"},
{ value: "Q269",	text: "MALFORMACION CONGENITA DE LAS GRANDES VENAS, NO ESPECIFICADA"},
{ value: "Q270",	text: "AUSENCIA DE HIPOPLASIA CONGENITA DE LA ARTERIA UMBILICAL"},
{ value: "Q271",	text: "ESTENOSIS CONGENITA DE LA ARTERIA RENAL"},
{ value: "Q272",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA ARTERIA RENAL"},
{ value: "Q273",	text: "MALFORMACION ARTERIOVENOSA PERIFERICA"},
{ value: "Q274",	text: "FLEBECTASIA CONGENITA"},
{ value: "Q278",	text: "OTRAS MALFORMACIONES CONGENITAS DEL SISTEMA VASCULAR PERIFERICO, ESPECIFICADAS"},
{ value: "Q279",	text: "MALFORMACION CONGENITA DEL SISTEMA VASCULAR PERIFERICO, NO ESPECIFICADA"},
{ value: "Q280",	text: "MALFORMACION ARTERIOVENOSA DE LOS VASOS PRECEREBRALES"},
{ value: "Q281",	text: "OTRAS MALFORMACIONES DE LOS VASOS PRECEREBRALES"},
{ value: "Q282",	text: "MALFORMACION ARTERIOVENOSA DE LOS VASOS CEREBRALES"},
{ value: "Q283",	text: "OTRAS MALFORMACIONES DE LOS VASOS CEREBRALES"},
{ value: "Q288",	text: "OTRAS MALFORMACIONES CONGENITAS DEL SISTEMA CIRCULATORIO, ESPECIFICADAS"},
{ value: "Q289",	text: "MALFORMACION CONGENITA DEL SISTEMA CIRCULATORIO NO ESPECIFICADA"},
{ value: "Q300",	text: "ATRESIA DE LAS COANAS"},
{ value: "Q301",	text: "AGENESIA O HIPOPLASIA DE LA NARIZ"},
{ value: "Q302",	text: "HENDIDURA, FISURA O MUESCA DE LA NARIZ"},
{ value: "Q303",	text: "PERFORACION CONGENITA DEL TABIQUE NASAL"},
{ value: "Q308",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA NARIZ"},
{ value: "Q309",	text: "MALFORMACION CONGENITA DE LA NARIZ, NO ESPECIFICADA"},
{ value: "Q310",	text: "PTERIGION DE LA LARINGE"},
{ value: "Q311",	text: "ESTENOSIS SUBGLOTICA CONGENITA"},
{ value: "Q312",	text: "HIPOPLASIA LARINGEA"},
{ value: "Q313",	text: "LARINGOCELE"},
{ value: "Q314",	text: "ESTRIDOR LARINGEO CONGENITO"},
{ value: "Q318",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA LARINGE"},
{ value: "Q319",	text: "MALFORMACION CONGENITA DE LA LARINGE, NO ESPECIFICADA"},
{ value: "Q320",	text: "TRAQUEOMALACIA CONGENITA"},
{ value: "Q321",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA TRAQUEA"},
{ value: "Q322",	text: "BRONCOMALACIA CONGENITA"},
{ value: "Q323",	text: "ESTENOSIS CONGENITA DE LOS BRONQUIOS"},
{ value: "Q324",	text: "OTRAS MALFORMACIONES CONGENITAS DE LOS BRONQUIOS"},
{ value: "Q330",	text: "QUISTE PULMONAR CONGENITO"},
{ value: "Q331",	text: "LOBULO PULMONAR SUPERNUMERARIO"},
{ value: "Q332",	text: "SECUESTRO DEL PULMON"},
{ value: "Q333",	text: "AGENESIA DEL PULMON"},
{ value: "Q334",	text: "BRONQUIECTASIA CONGENITA"},
{ value: "Q335",	text: "TEJIDO ECTOPICO EN EL PULMON"},
{ value: "Q336",	text: "HIPOPLASIA Y DISPLASIA PULMONAR"},
{ value: "Q338",	text: "OTRAS MALFORMACIONES CONGENITAS DEL PULMON"},
{ value: "Q339",	text: "MALFORMACION CONGENITA DEL PULMON, NO ESPECIFICADA"},
{ value: "Q340",	text: "ANOMALIA DE LA PLEURA"},
{ value: "Q341",	text: "QUISTE CONGENITO DEL MEDIASTINO"},
{ value: "Q348",	text: "OTRAS MALFORMACIONES CONGENITAS ESPECIFICADAS DEL SISTEMA RESPIRATORIO"},
{ value: "Q349",	text: "MALFORMACION CONGENITA DEL SISTEMA RESPIRATORIO, NO ESPECIFICADA"},
{ value: "Q351",	text: "FISURA DEL PALADAR DURO"},
{ value: "Q353",	text: "FISURA DEL PALADAR BLANDO"},
{ value: "Q355",	text: "FISURA DEL PALADAR DURO Y DEL PALADAR BLANDO"},
{ value: "Q356",	text: "FISURA DEL PALADAR, LINEA MEDIA"},
{ value: "Q357",	text: "FISURA DE LA UVULA"},
{ value: "Q359",	text: "FISURA DEL PALADAR, SIN OTRA ESPECIFICACION"},
{ value: "Q360",	text: "LABIO LEPORINO, BILATERAL"},
{ value: "Q361",	text: "LABIO LEPORINO, LINEA MEDIA"},
{ value: "Q369",	text: "LABIO LEPORINO, UNILATERAL"},
{ value: "Q370",	text: "FISURA DEL PALADAR DURO CON LABIO LEPORINO BILATERAL"},
{ value: "Q371",	text: "FISURA DEL PALADAR DURO CON LABIO LEPORINO UNILATERAL"},
{ value: "Q372",	text: "FISURA DEL PALADAR BLANDO CON LABIO LEPORINO BILATERAL"},
{ value: "Q373",	text: "FISURA DEL PALADAR BLANDO CON LABIO LEPORINO UNILATERAL"},
{ value: "Q374",	text: "FISURA DEL PALADAR DURO Y DEL PALADAR BLANDO CON LABIO LEPORINO BILATERAL"},
{ value: "Q375",	text: "FISURA DEL PALADAR DURO Y DEL PALADAR BLANDO CON LABIO LEPORINO UNILATERAL"},
{ value: "Q378",	text: "FISURA DEL PALADAR CON LABIO LEPORINO BILATERAL, SIN OTRA ESPECIFICACION"},
{ value: "Q379",	text: "FISURA DEL PALADAR CON LABIO LEPORINO UNILATERAL, SIN OTRA ESPECIFICACION"},
{ value: "Q380",	text: "MALFORMACIONES CONGENITAS DE LOS LABIOS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "Q381",	text: "ANQUILOGLOSIA"},
{ value: "Q382",	text: "MACROGLOSIA"},
{ value: "Q383",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA LENGUA"},
{ value: "Q384",	text: "MALFORMACIONES CONGENITAS DE LAS GLANDULAS Y DE LOS CONDUCTOS SALIVALES"},
{ value: "Q385",	text: "MALFORMACIONES CONGENITAS DEL PALADAR, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "Q386",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA BOCA"},
{ value: "Q387",	text: "DIVERTICULO FARINGEO"},
{ value: "Q388",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA LARINGE"},
{ value: "Q390",	text: "ATRESIA DEL ESOFAGO SIN MENCION DE FISTULA"},
{ value: "Q391",	text: "ATRESIA DEL ESOFAGO CON FISTULA TRAQUEOESOFAGICA"},
{ value: "Q392",	text: "FISTULA TRAQUEOESOFAGICA CONGENITA SIN MENCION DE ATRESIA"},
{ value: "Q393",	text: "ESTRECHEZ O ESTENOSIS CONGENITA DEL ESOFAGO"},
{ value: "Q394",	text: "PTERIGION DEL ESOFAGO"},
{ value: "Q395",	text: "DILATACION CONGENITA DEL ESOFAGO"},
{ value: "Q396",	text: "DIVERTICULO DEL ESOFAGO"},
{ value: "Q398",	text: "OTRAS MALFORMACIONES CONGENITAS DEL ESOFAGO"},
{ value: "Q399",	text: "MALFORMACION CONGENITA DEL ESOFAGO, NO ESPECIFICADA"},
{ value: "Q400",	text: "ESTENOSIS HIPERTROFICA CONGENITA DEL PILORO"},
{ value: "Q401",	text: "HERNIA HIATAL CONGENITA"},
{ value: "Q402",	text: "OTRAS MALFORMACIONES CONGENITAS DEL ESTOMAGO, ESPECIFICADAS"},
{ value: "Q403",	text: "MALFORMACION CONGENITA DEL ESTOMAGO, NO ESPECIFICADA"},
{ value: "Q408",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA PARTE SUPERIOR DEL TUBO DIGESTIVO"},
{ value: "Q409",	text: "MALFORMACION CONGENITA DE LA PARTE SUPERIOR DEL TUBO DIGESTIVO, NO ESPECIFICADA"},
{ value: "Q410",	text: "AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL DUODENO"},
{ value: "Q411",	text: "AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL YEYUNO"},
{ value: "Q412",	text: "AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL ILEON"},
{ value: "Q418",	text: "AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DE OTRAS PARTES ESPECIFICADAS DEL INTESTINO DELGADO"},
{ value: "Q419",	text: "AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL INTESTINO DELGADO, PARTE NO ESPECIFICADA"},
{ value: "Q420",	text: "AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL RECTO, CON FISTULA"},
{ value: "Q421",	text: "AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL RECTO, SIN FISTULA"},
{ value: "Q422",	text: "AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL ANO, CON FISTULA"},
{ value: "Q423",	text: "AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL ANO, SIN FISTULA"},
{ value: "Q428",	text: "AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DE OTRAS PARTES DEL INTESTINO GRUESO"},
{ value: "Q429",	text: "AUSENCIA, ATRESIA Y ESTENOSIS CONGENITA DEL INTESTINO GRUESO, PARTE NO ESPECIFICADA"},
{ value: "Q430",	text: "DIVERTICULO DE MECKEL"},
{ value: "Q431",	text: "ENFERMEDAD DE HIRSCHSPRUNG"},
{ value: "Q432",	text: "OTROS TRASTORNOS FUNCIONALES CONGENITOS DEL COLON"},
{ value: "Q433",	text: "MALFORMACIONES CONGENITAS DE LA FIJACION DEL INTESTINO"},
{ value: "Q434",	text: "DUPLICACION DEL INTESTINO"},
{ value: "Q435",	text: "ANO ECTOPICO"},
{ value: "Q436",	text: "FISTULA CONGENITA DEL RECTO Y DEL ANO"},
{ value: "Q437",	text: "PERSISTENCIA DE LA CLOACA"},
{ value: "Q438",	text: "OTRAS MALFORMACIONES CONGENITAS DEL INTESTINO, NO ESPECIFICADAS"},
{ value: "Q439",	text: "MALFORMACION CONGENITA DEL INTESTINO, NO ESPECIFICADA"},
{ value: "Q440",	text: "AGENESIA, APLASIA E HIPOPLASIA DE LA VESICULA BILIAR"},
{ value: "Q441",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA VESICULA BILIAR"},
{ value: "Q442",	text: "ATRESIA DE LOS CONDUCTOS BILIARES"},
{ value: "Q443",	text: "ESTRECHEZ Y ESTENOSIS CONGENITA DE LOS CONDUCTOS BILIARES"},
{ value: "Q444",	text: "QUISTE DEL COLEDOCO"},
{ value: "Q445",	text: "OTRAS MALFORMACIONES CONGENITAS DE LOS CONDUCTOS BILIARES"},
{ value: "Q446",	text: "ENFERMEDAD QUISTICA DEL HIGADO"},
{ value: "Q447",	text: "OTRAS MALFORMACIONES CONGENITAS DEL HIGADO"},
{ value: "Q450",	text: "AGENESIA, APLASIA E HIPOPLASIA DEL PANCREAS"},
{ value: "Q451",	text: "PANCREAS ANULAR"},
{ value: "Q452",	text: "QUISTE CONGENITO DEL PANCREAS"},
{ value: "Q453",	text: "OTRAS MALFORMACIONES CONGENITAS DEL PANCREAS Y DEL CONDUCTO PANCREATICO"},
{ value: "Q458",	text: "OTRAS MALFORMACIONES CONGENITAS DEL SISTEMA DIGESTIVO, ESPECIFICADAS"},
{ value: "Q459",	text: "MALFORMACION CONGENITA DEL SISTEMA DIGESTIVO, NO ESPECIFICADA"},
{ value: "Q500",	text: "AUSENCIA CONGENITA DEL OVARIO"},
{ value: "Q501",	text: "QUISTE EN DESARROLLO DEL OVARIO"},
{ value: "Q502",	text: "TORSION CONGENITA DEL OVARIO"},
{ value: "Q503",	text: "OTRAS MALFORMACIONES CONGENITAS DE LOS OVARIOS"},
{ value: "Q504",	text: "QUISTE EMBRIONARIO DE LA TROMPA DE FALOPIO"},
{ value: "Q505",	text: "QUISTE EMBRIONARIO DEL LIGAMENTO ANCHO"},
{ value: "Q506",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA TROMPA DE FALOPIO Y DEL LIGAMENTO ANCHO"},
{ value: "Q510",	text: "AGENESIA Y APLASIA DEL UTERO"},
{ value: "Q511",	text: "DUPLICACION DEL UTERO CON DUPLICACION DEL CUELLO UTERINO Y DE LA VAGINA"},
{ value: "Q512",	text: "OTRA DUPLICACION DEL UTERO"},
{ value: "Q513",	text: "UTERO BICORNE"},
{ value: "Q514",	text: "UTERO UNICORNE"},
{ value: "Q515",	text: "AGENESIA Y APLASIA DEL CUELLO UTERINO"},
{ value: "Q516",	text: "QUISTE EMBRIONARIO DL CUELLO UTERINO"},
{ value: "Q517",	text: "FISTULA CONGENITA ENTRE EL UTERO Y EL TRACTO DIGESTIVO Y URINARIO"},
{ value: "Q518",	text: "OTRA MALFORMACIONES CONGENITAS DEL UTERO Y DEL CUELLO UTERINO"},
{ value: "Q519",	text: "MALFORMACION CONGENITA DEL UTERO Y DEL CUELLO UTERINO, NO ESPECIFICADA"},
{ value: "Q520",	text: "AUSENCIA CONGENITA DE LA VAGINA"},
{ value: "Q521",	text: "DUPLICACION DE LA VAGINA"},
{ value: "Q522",	text: "FISTULA RECTOVAGINAL CONGENITA"},
{ value: "Q523",	text: "HIMEN IMPERFORADO"},
{ value: "Q524",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA VAGINA"},
{ value: "Q525",	text: "FUSION DE LABIOS DE LA VULVA"},
{ value: "Q526",	text: "MALFORMACION CONGENITA DEL CLITORIS"},
{ value: "Q527",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA VULVA"},
{ value: "Q528",	text: "OTRAS MALFORMACIONES CONGENITAS DE LOS ORGANOS GENITALES FEMENINOS, ESPECIFICADAS"},
{ value: "Q529",	text: "MALFORMACION CONGENITA DE LOS GENITALES FEMENINOS, NO ESPECIFICADA"},
{ value: "Q530",	text: "ECTOPIA TESTICULAR"},
{ value: "Q531",	text: "TESTICULO NO DESCENDIDO, UNILATERAL"},
{ value: "Q532",	text: "TESTICULO NO DESCENDIDO, BILATERAL"},
{ value: "Q539",	text: "TESTICULO NO DESCENDIDO, SIN OTRA ESPECIFICACION"},
{ value: "Q540",	text: "HIPOSPADIAS DEL GLANDE"},
{ value: "Q541",	text: "HIPOSPADIAS PENEANA"},
{ value: "Q542",	text: "HIPOSPADIAS PENOSCROTAL"},
{ value: "Q543",	text: "HIPOSPADIAS PERINEAL"},
{ value: "Q544",	text: "ENCORDAMIENTO CONGENITO DEL PENE"},
{ value: "Q548",	text: "OTRAS HIPOSPADIAS"},
{ value: "Q549",	text: "HIPOSPADIAS, NO ESPECIFICADA"},
{ value: "Q550",	text: "AUSENCIA Y APLASIA DEL TESTICULO"},
{ value: "Q551",	text: "HIPOPLASIA DEL TESTICULO Y DEL ESCROTO"},
{ value: "Q552",	text: "OTRAS MALFORMACIONES CONGENITAS DE LOS TESTICULOS Y DEL ESCROTO"},
{ value: "Q553",	text: "ATRESIA DEL CONDUCTO DEFERENTE"},
{ value: "Q554",	text: "OTRAS MALFORMACIONES CONGENITAS DE LOS CONDUCTOS DEFERENTES, DEL EPIDIDIMO, DE LAS VESICULAS SEMINALES Y DE LA PROSTATA"},
{ value: "Q555",	text: "APLASIA Y AUSENCIA CONGENITA DEL PENE"},
{ value: "Q556",	text: "OTRAS MALFORMACIONES CONGENITAS DEL PENE"},
{ value: "Q558",	text: "OTRAS MALFORMACIONES CONGENITAS DE LOS ORGANOS GENITALES MASCULINOS, ESPECIFICADAS"},
{ value: "Q559",	text: "MALFORMACION CONGENITA DE LOS ORGANOS GENITALES MASCULINOS, NO ESPECIFICADA"},
{ value: "Q560",	text: "HERMAFRODITISMO, NO CLASIFICADO EN OTRA PARTE"},
{ value: "Q561",	text: "SEUDOHERMAFRODITISMO MASCULINO, NO CLASIFICADO EN OTRA PARTE"},
{ value: "Q562",	text: "SEUDOHERMAFRODITISMO FEMENINO, NO CLASIFICADO EN OTRA PARTE"},
{ value: "Q563",	text: "SEUDOHERMAFRODITISMO, NO ESPECIFICADO"},
{ value: "Q564",	text: "SEXO INDETERMINADO, SIN OTRA ESPECIFICACION"},
{ value: "Q600",	text: "AGENESIA RENAL, UNILATERAL"},
{ value: "Q601",	text: "AGENESIA RENAL, BILATERAL"},
{ value: "Q602",	text: "AGENESIA RENAL, SIN OTRA ESPECIFICACIÓN"},
{ value: "Q603",	text: "HIPOPLASIA RENAL, UNILATERAL"},
{ value: "Q604",	text: "HIPOPLASIA RENAL, BILATERAL"},
{ value: "Q605",	text: "HIPOPLASIA RENAL, NO ESPECIFICADA"},
{ value: "Q606",	text: "SINDROME DE POTTER"},
{ value: "Q610",	text: "QUISTE RENAL SOLITARIO CONGENITO"},
{ value: "Q611",	text: "RIÑON POLIQUISTICO, TIPO INFANTIL"},
{ value: "Q612",	text: "RIÑON POLIQUISTICO, TIPO ADULTO"},
{ value: "Q613",	text: "RIÑON POLIQUISTICO, TIPO NO ESPECIFICADO"},
{ value: "Q614",	text: "DISPLASIA RENAL"},
{ value: "Q615",	text: "RIÑON QUISTICO MEDULAR"},
{ value: "Q618",	text: "OTRAS ENFERMEDADES RENALES QUISTICAS"},
{ value: "Q619",	text: "ENFERMEDAD QUISTICA DEL RIÑON, NO ESPECIFICADA"},
{ value: "Q620",	text: "HIDRONEFROSIS CONGENITA"},
{ value: "Q621",	text: "ATRESIA Y ESTENOSIS DEL URETER"},
{ value: "Q622",	text: "MEGALOURETER CONGENITO"},
{ value: "Q623",	text: "OTROS DEFECTOS OBSTRUCTIVOS DE LA PELVIS RENAL Y DEL URETER"},
{ value: "Q624",	text: "AGENESIA DEL URETER"},
{ value: "Q625",	text: "DUPLICACION DEL URETER"},
{ value: "Q626",	text: "MALA POSICION DEL URETER"},
{ value: "Q627",	text: "REFLUJO VESICO-URETERO-RENAL CONGENITO"},
{ value: "Q628",	text: "OTRAS MALFORMACIONES CONGENITAS DEL URETER"},
{ value: "Q630",	text: "RIÑON SUPERNUMERARIO"},
{ value: "Q631",	text: "RIÑON LOBULADO, FUSIONADO Y EN HERRADURA"},
{ value: "Q632",	text: "RIÑON ECTOPICO"},
{ value: "Q633",	text: "HIPERPLASIA RENAL Y RIÑON GIGANTE"},
{ value: "Q638",	text: "OTRAS MALFORMACIONES CONGENITAS DEL RIÑON, ESPECIFICADAS"},
{ value: "Q639",	text: "MALFORMACION CONGENITA DEL RIÑON, NO ESPECIFICADA"},
{ value: "Q640",	text: "EPISPADIAS"},
{ value: "Q641",	text: "EXTROFIA DE LA VEJIGA URINARIA"},
{ value: "Q642",	text: "VALVULAS URETRALES POSTERIORES CONGENITAS"},
{ value: "Q643",	text: "OTRAS ATRESIAS Y ESTENOSIS DE LA URETRA Y DEL CUELLO DE LA VEJIGA"},
{ value: "Q644",	text: "MALFORMACION DEL URACO"},
{ value: "Q645",	text: "AUSENCIA CONGENITA DE LA VEJIGA Y DE LA URETRA"},
{ value: "Q646",	text: "DIVERTICULO CONGENITO DE LA VEJIGA"},
{ value: "Q647",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA VEJIGA Y DE LA URETRA"},
{ value: "Q648",	text: "OTRAS MALFORMACIONES CONGENITAS DEL APARATO URINARIO, ESPECIFICADAS"},
{ value: "Q649",	text: "MALFORMACION CONGENITA DEL APARATO URINARIO, NO ESPECIFICADA"},
{ value: "Q650",	text: "LUXACION CONGENITA DE LA CADERA, UNILATERAL"},
{ value: "Q651",	text: "LUXACION CONGENITA DE LA CADERA, BILATERAL"},
{ value: "Q652",	text: "LUXACION CONGENITA DE LA CADERA, NO ESPECIFICADA"},
{ value: "Q653",	text: "SUBLUXACION CONGENITA DE LA CADERA, UNILATERAL"},
{ value: "Q654",	text: "SUBLUXACION CONGENITA DE LA CADERA, BILATERAL"},
{ value: "Q655",	text: "SUBLUXACION CONGENITA DE LA CADERA, NO ESPECIFICADA"},
{ value: "Q656",	text: "CADERA INESTABLE"},
{ value: "Q658",	text: "OTRAS DEFORMIDADES CONGENITAS DE LA CADERA"},
{ value: "Q659",	text: "DEFORMIDAD CONGENITA DE LA CADERA, NO ESPECIFICADA"},
{ value: "Q660",	text: "TALIPES EQUINOVARUS"},
{ value: "Q661",	text: "TALIPES CALCANEOVARUS"},
{ value: "Q662",	text: "METATARSUS VARUS"},
{ value: "Q663",	text: "OTRAS DEFORMIDADES VARUS CONGENITAS DE LOS PIES"},
{ value: "Q664",	text: "TALIPES CALCANEOVALGUS"},
{ value: "Q665",	text: "PIE PLANO CONGENITO"},
{ value: "Q666",	text: "OTRAS DEFORMIDADES VALGUS CONGENITAS DE LOS PIES"},
{ value: "Q667",	text: "PIE CAVUS"},
{ value: "Q668",	text: "OTRAS DEFORMIDADES CONGENITAS DE LOS PIES"},
{ value: "Q669",	text: "DEFORMIDAD CONGENITA DE LOS PIES, NO ESPECIFICADA"},
{ value: "Q670",	text: "ASIMETRIA FACIAL"},
{ value: "Q671",	text: "FACIES COMPRIMIDA"},
{ value: "Q672",	text: "DOLICOCEFALIA"},
{ value: "Q673",	text: "PLAGIOCEFALIA"},
{ value: "Q674",	text: "OTRAS DEFORMIDADES CONGENITAS DEL CRANEO, DE LA CARA Y DE LA MANDIBULA"},
{ value: "Q675",	text: "DEFORMIDAD CONGENITA DE LA COLUMNA VERTEBRAL"},
{ value: "Q676",	text: "TORAX EXCAVADO"},
{ value: "Q677",	text: "TORAX EN QUILLA"},
{ value: "Q678",	text: "OTRAS DEFORMIDADES CONGENITAS DEL TORAX"},
{ value: "Q680",	text: "DEFORMIDAD CONGENITA DEL MUSCULO ESTERNOCLEIDOMASTOIDEO"},
{ value: "Q681",	text: "DEFORMIDAD CONGENITA DE LA MANO"},
{ value: "Q682",	text: "DEFORMIDAD CONGENITA DE LA RODILLA"},
{ value: "Q683",	text: "CURVATURA CONGENITA DEL FEMUR"},
{ value: "Q684",	text: "CURVATURA CONGENITA DE LA TIBIA Y EL PERONE"},
{ value: "Q685",	text: "CURVATURA CONGENITA DE HUESO(S) LARGO(S) DEL MIEMBRO INFERIOR, SIN OTRA ESPECIFICACION"},
{ value: "Q688",	text: "OTRAS DEFORMIDADES CONGENITAS OSTEOMUSCULARES, ESPECIFICADAS"},
{ value: "Q690",	text: "DEDO(S) SUPERNUMERARIO(S) DE LA MANO"},
{ value: "Q691",	text: "PULGAR(ES) SUPERNUMERARIO(S)"},
{ value: "Q692",	text: "DEDO(S) SUPERNUMERARIO(S) DEL PIE"},
{ value: "Q699",	text: "POLIDACTILIA, NO ESPECIFICADA"},
{ value: "Q700",	text: "FUSION DE LOS DEDOS DE LA MANO"},
{ value: "Q701",	text: "MEMBRANA INTERDIGITAL DE LA MANO"},
{ value: "Q702",	text: "FUSION DE LOS DEDOS DEL PIE"},
{ value: "Q703",	text: "MEMBRANA INTERDIGITAL DEL PIE"},
{ value: "Q704",	text: "POLISINDACTILIA"},
{ value: "Q709",	text: "SINDACTILIA, NO ESPECIFICADA"},
{ value: "Q710",	text: "AUSENCIA CONGENITA COMPLETA DEL (DE LOS) MIEMBRO(S) SUPERIOR(ES)"},
{ value: "Q711",	text: "AUSENCIA CONGENITA DEL BRAZO Y DEL ANTEBRAZO CON PRESENCIA DE LA MANO"},
{ value: "Q712",	text: "AUSENCIA CONGENITA DEL ANTEBRAZO Y DE LA MANO"},
{ value: "Q713",	text: "AUSENCIA CONGENITA DE LA MANO Y EL (LOS) DEDO(S)"},
{ value: "Q714",	text: "DEFECTO POR REDUCCION LONGITUDINAL DEL RADIO"},
{ value: "Q715",	text: "DEFECTO POR REDUCCION LONGITUDINAL DEL CUBITO"},
{ value: "Q716",	text: "MANO EN PINZA DE LANGOSTA"},
{ value: "Q718",	text: "OTROS DEFECTOS POR REDUCCION DEL (DE LOS) MIEMBRO(S) SUPERIOR(ES)"},
{ value: "Q719",	text: "DEFECTO POR REDUCCION DEL MIEMBRO SUPERIOR, NO ESPECIFICADO"},
{ value: "Q720",	text: "AUSENCIA CONGENITA COMPLETA DEL (DE LOS) MIEMBRO(S) INFERIOR(ES)"},
{ value: "Q721",	text: "AUSENCIA CONGENITA DEL MUSLO Y DE LA PIERNA CON PRESENCIA DEL PIE"},
{ value: "Q722",	text: "AUSENCIA CONGENITA DE LA PIERNA Y DEL PIE"},
{ value: "Q723",	text: "AUSENCIA CONGENITA DEL PIE Y DEDO(S) DEL PIE"},
{ value: "Q724",	text: "DEFECTO POR REDUCCION LONGITUDINAL DEL FEMUR"},
{ value: "Q725",	text: "DEFECTO POR REDUCCION LONGITUDINAL DE LA TIBIA"},
{ value: "Q726",	text: "DEFECTO POR REDUCCION LONGITUDINAL DEL PERONE"},
{ value: "Q727",	text: "PIE HENDIDO"},
{ value: "Q728",	text: "OTROS DEFECTOS POR REDUCCION DEL (DE LOS) MIEMBRO(S) INFERIOR(ES)"},
{ value: "Q729",	text: "DEFECTO POR REDUCCION DEL MIEMBRO INFERIOR, NO ESPECIFICADO"},
{ value: "Q730",	text: "AUSENCIA COMPLETA DE MIEMBRO(S) NO ESPECIFICADO(S)"},
{ value: "Q731",	text: "FOCOMELIA, MIEMBRO(S) NO ESPECIFICADO(S)"},
{ value: "Q738",	text: "OTROS DEFECTOS POR REDUCCION DE MIEMBRO(S) NO ESPECIFICADO(S)"},
{ value: "Q740",	text: "OTRAS MALFORMACIONES CONGENITAS DE (DE LOS) MIEMBRO(S) SUPERIOR(ES), INCLUIDA LA CINTURA ESCAPULAR"},
{ value: "Q741",	text: "MALFORMACION CONGENITA DE LA RODILLA"},
{ value: "Q742",	text: "OTRAS MALFORMACIONES CONGENITAS DEL (DE LOS) MIEMBRO(S) INFERIOR(ES), INCLUIDA LA CINTURA PELVIANA"},
{ value: "Q743",	text: "ARTROGRIPOSIS MULTIPLE CONGENITA"},
{ value: "Q748",	text: "OTRAS MALFORMACIONES CONGENITAS ESPECIFICADAS DEL (DE LOS) MIEMBRO(S)"},
{ value: "Q749",	text: "MALFORMACION CONGENITA DE MIEMBRO(S), NO ESPECIFICADA"},
{ value: "Q750",	text: "CRANEOSINOSTOSIS"},
{ value: "Q751",	text: "DISOSTOSIS CRANEOFACIAL"},
{ value: "Q752",	text: "HIPERTELORISMO"},
{ value: "Q753",	text: "MACROCEFALIA"},
{ value: "Q754",	text: "DISOSTOSIS MAXILOFACIAL"},
{ value: "Q755",	text: "DISOSTOSIS OCULOMAXILAR"},
{ value: "Q758",	text: "OTRAS MALFORMACIONES CONGENITAS ESPECIFICADAS DE LOS HUESOS DEL CRANEO Y DE LA CARA"},
{ value: "Q759",	text: "MALFORMACION CONGENITA NO ESPECIFICADA DE LOS HUESOS DEL CRANEO Y DE LA CARA"},
{ value: "Q760",	text: "ESPINA BIFIDA OCULTA"},
{ value: "Q761",	text: "SINDROME DE KLIPPEL-FEIL"},
{ value: "Q762",	text: "ESPONDILOLISTESIS CONGENITA"},
{ value: "Q763",	text: "ESCOLIOSIS CONGENITA DEBIDA A MALFORMACION CONGENITA OSEA"},
{ value: "Q764",	text: "OTRA MALFORMACION CONGENITA DE LA COLUMNA VERTEBRAL, NO ASOCIADA CON ESCOLIOSIS"},
{ value: "Q765",	text: "COSTILLA CERVICAL"},
{ value: "Q766",	text: "OTRAS MALFORMACIONES CONGENITAS DE LAS COSTILLAS"},
{ value: "Q767",	text: "MALFORMACION CONGENITA DEL ESTERNON"},
{ value: "Q768",	text: "OTRAS MALFORMACIONES CONGENITAS DEL TORAX OSEO"},
{ value: "Q769",	text: "MALFORMACION CONGENITA DEL TORAX OSEO, NO ESPECIFICADA"},
{ value: "Q770",	text: "ACONDROGENESIS"},
{ value: "Q771",	text: "ENANISMO TANATOFORICO"},
{ value: "Q772",	text: "SINDROME DE COSTILLA CORTA"},
{ value: "Q773",	text: "CONDRODISPLASIA PUNCTATA"},
{ value: "Q774",	text: "ACONDROPLASIA"},
{ value: "Q775",	text: "DISPLASIA DISTROFICA"},
{ value: "Q776",	text: "DISPLASIA CONDROECTODERMICA"},
{ value: "Q777",	text: "DISPLASIA ESPONDILOEPIFISARIA"},
{ value: "Q778",	text: "OTRAS OSTEOCONDRODISPLASIAS CON DEFECTOS DEL CRECIMIENTO DE LOS HUESOS LARGOS Y DE LA COLUMNA VERTEBRAL"},
{ value: "Q779",	text: "OSTEOCONDRODISPLASIA CON DEFECTOS DEL CRECIMIENTO DE LOS HUESOS LARGOS Y DE LA COLUMNA VERTEBRAL, SIN OTRA ESPECIFICACION"},
{ value: "Q780",	text: "OSTEOGENESIS IMPERFECTA"},
{ value: "Q781",	text: "DISPLASIA POLIOSTOTICA FIBROSA"},
{ value: "Q782",	text: "OSTEOPETROSIS"},
{ value: "Q783",	text: "DISPLASIA DIAFISARIA PROGRESIVA"},
{ value: "Q784",	text: "ENCONDROMATOSIS"},
{ value: "Q785",	text: "DISPLASIA METAFISARIA"},
{ value: "Q786",	text: "EXOSTOSIS CONGENITA MULTIPLE"},
{ value: "Q788",	text: "OTRAS OSTEOCONDRODISPLASIAS ESPECIFICADAS"},
{ value: "Q789",	text: "OSTEOCONDRODISPLASIA, NO ESPECIFICADA"},
{ value: "Q790",	text: "HERNIA DIAFRAGMATICA CONGENITA"},
{ value: "Q791",	text: "OTRAS MALFORMACIONES CONGENITAS DEL DIAFRAGMA"},
{ value: "Q792",	text: "EXONFALOS"},
{ value: "Q793",	text: "GASTROSQUISIS"},
{ value: "Q794",	text: "SINDROME DEL ABDOMEN EN CIRUELA PASA"},
{ value: "Q795",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA PARED ABDOMINAL"},
{ value: "Q796",	text: "SINDROME DE EHLERS-DANLOS"},
{ value: "Q798",	text: "OTRAS MALFORMACIONES CONGENITAS DEL SISTEMA OSTEOMUSCULAR"},
{ value: "Q799",	text: "MALFORMACION CONGENITA DEL SISTEMA OSTEOMUSCULAR, NO ESPECIFICADA"},
{ value: "Q800",	text: "ICTIOSIS VULGAR"},
{ value: "Q801",	text: "ICTIOSIS LIGADA AL CROMOSOMA X"},
{ value: "Q802",	text: "ICTIOSIS LAMELAR"},
{ value: "Q803",	text: "ERITRODERMIA ICTIOSIFORME VESICULAR CONGENITA"},
{ value: "Q804",	text: "FETO ARLEQUÍN"},
{ value: "Q808",	text: "OTRAS ICTIOSIS CONGENITAS"},
{ value: "Q809",	text: "ICTIOSIS CONGENITA, NO ESPECIFICADA"},
{ value: "Q810",	text: "EPIDERMOLISIS BULLOSA SIMPLE"},
{ value: "Q811",	text: "EPIDERMOLISIS BULLOSA LETAL"},
{ value: "Q812",	text: "EPIDERMOLISIS BULLOSA DISTROFICA"},
{ value: "Q818",	text: "OTRAS EPIDERMOLISIS BULLOSAS"},
{ value: "Q819",	text: "EPIDERMOLISIS BULLOSA, NO ESPECIFICADA"},
{ value: "Q820",	text: "LINFEDEMA HEREDITARIO"},
{ value: "Q821",	text: "XERODERMA PIGMENTOSO"},
{ value: "Q822",	text: "MASTOCITOSIS"},
{ value: "Q823",	text: "INCONTINENCIA PIGMENTARIA"},
{ value: "Q824",	text: "DISPLASIA ECTODERMICA (ANHIDROTICA)"},
{ value: "Q825",	text: "NEVO NO NEOPLASICO, CONGENITO"},
{ value: "Q828",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA PIEL, ESPECIFICADAS"},
{ value: "Q829",	text: "MALFORMACION CONGENITA DE LA PIEL, NO ESPECIFICADA"},
{ value: "Q830",	text: "AUSENCIA CONGENITA DE LA MAMA CON AUSENCIA DEL PEZON"},
{ value: "Q831",	text: "MAMA SUPERNUMERARIA"},
{ value: "Q832",	text: "AUSENCIA DEL PEZON"},
{ value: "Q833",	text: "PEZON SUPERNUMERARIO"},
{ value: "Q838",	text: "OTRAS MALFORMACIONES CONGENITAS DE LA MAMA"},
{ value: "Q839",	text: "MALFORMACION CONGENITA DE LA MAMA, NO ESPECIFICADA"},
{ value: "Q840",	text: "ALOPECIA CONGENITA"},
{ value: "Q841",	text: "ALTERACIONES MORFOLOGICAS CONGENITAS DEL PELO, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "Q842",	text: "OTRAS MALFORMACIONES CONGENITAS DEL PELO"},
{ value: "Q843",	text: "ANONIQUIA"},
{ value: "Q844",	text: "LEUCONIQUIA CONGENITA"},
{ value: "Q845",	text: "AGRANDAMIENTO E HIPERTROFIA DE LAS UÑAS"},
{ value: "Q846",	text: "OTRAS MALFORMACIONES CONGENITAS DE LAS UÑAS"},
{ value: "Q848",	text: "OTRAS MALFORMACIONES CONGENITAS DE LAS FANERAS, ESPECIFICADAS"},
{ value: "Q849",	text: "MALFORMACION CONGENITA DE LAS FANERAS, NO ESPECIFICADA"},
{ value: "Q850",	text: "NEUROFIBROMATOSIS (NO MALIGNA)"},
{ value: "Q851",	text: "ESCLEROSIS TUBEROSA"},
{ value: "Q858",	text: "OTRAS FACOMATOSIS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "Q859",	text: "FACOMATOSIS, NO ESPECIFICADA"},
{ value: "Q860",	text: "SINDROME FETAL (DISMORFICO) DEBIDO AL ALCOHOL"},
{ value: "Q861",	text: "SINDROME DE HIDANTOINA FETAL"},
{ value: "Q862",	text: "DISMORFISMO DEBIDO A WARFARINA"},
{ value: "Q868",	text: "OTROS SINDROMES DE MALFORMACIONES CONGENITAS DEBIDOS A CAUSAS EXOGENAS CONOCIDAS"},
{ value: "Q870",	text: "SINDROMES DE MALFORMACIONES CONGENITAS QUE AFECTAN PRINCIPALMENTE LA APARIENCIA FACIAL"},
{ value: "Q871",	text: "SINDROMES DE MALFORMACIONES CONGENITAS ASOCIADAS PRINCIPALMENTE CON ESTATURA BAJA"},
{ value: "Q872",	text: "SINDROMES DE MALFORMACIONES CONGENITAS QUE AFECTAN PRINCIPALMENTE LOS MIEMBROS"},
{ value: "Q873",	text: "SINDROMES DE MALFORMACIONES CONGENITAS CON EXCESO DE CRECIMIENTO PRECOZ"},
{ value: "Q874",	text: "SINDROME DE MARFAN"},
{ value: "Q875",	text: "OTROS SINDROMES DE MALFORMACIONES CONGENITAS CON OTROS CAMBIOS ESQUELETICOS"},
{ value: "Q878",	text: "OTROS SINDROMES DE MALFORMACIONES CONGENITAS ESPECIFICADOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Q890",	text: "MALFORMACIONES CONGENITAS DEL BAZO"},
{ value: "Q891",	text: "MALFORMACIONES CONGENITAS DE LA GLANDULA SUPRARRENAL"},
{ value: "Q892",	text: "MALFORMACIONES CONGENITAS OTRAS GLANDULAS ENDOCRINAS"},
{ value: "Q893",	text: "SITUS INVERSUS"},
{ value: "Q894",	text: "GEMELOS SIAMESES"},
{ value: "Q897",	text: "MALFORMACIONES CONGENITAS MULTIPLES, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "Q898",	text: "OTRAS MALFORMACIONES CONGENITAS, ESPECIFICADAS"},
{ value: "Q899",	text: "MALFORMACION CONGENITA, NO ESPECIFICADA"},
{ value: "Q900",	text: "TRISOMIA 21, POR FALTA DE DISYUNCION MEIOTICA"},
{ value: "Q901",	text: "TRISOMIA 21, MOSAICO (POR FALTA DE DISYUNCION MITOTICA)"},
{ value: "Q902",	text: "TRISOMIA 21, POR TRANSLOCACION"},
{ value: "Q909",	text: "SINDROME DE DOWN, NO ESPECIFICADO"},
{ value: "Q910",	text: "TRISOMIA 18, POR FALTA DE DISYUNCION MEIOTICA"},
{ value: "Q911",	text: "TRISOMIA 18, MOSAICO (POR FALTA DE DISYUNCION MITOTICA)"},
{ value: "Q912",	text: "TRISOMIA 18, POR TRANSLOCACION"},
{ value: "Q913",	text: "SINDROME DE EDWARDS, NO ESPECIFICADO"},
{ value: "Q914",	text: "TRISOMIA 13, POR FALTA DE DISYUNCION MEIOTICA"},
{ value: "Q915",	text: "TRISOMIA 13, MOSAICO (POR FALTA DE DISYUNCION MITOTICA)"},
{ value: "Q916",	text: "TRISOMIA 13, POR TRANSLOCACION"},
{ value: "Q917",	text: "SINDROME DE PATAU, NO ESPECIFICADO"},
{ value: "Q920",	text: "TRISOMIA DE UN CROMOSOMA COMPLETO, POR FALTA DE DISYUNCION MEIOTICA"},
{ value: "Q921",	text: "TRISOMIA DE UN CROMOSOMA COMPLETO, MOSAICO (POR FALTA DE DISYUNCION MITOTICA)"},
{ value: "Q922",	text: "TRISOMIA PARCIAL MAYOR"},
{ value: "Q923",	text: "TRISOMIA PARCIAL MENOR"},
{ value: "Q924",	text: "DUPLICACIONES VISIBLES SOLO EN LA PROMETAFASE"},
{ value: "Q925",	text: "DUPLICACIONES CON OTROS REORDENAMIENTOS COMPLEJOS"},
{ value: "Q926",	text: "CROMOSOMAS MARCADORES SUPLEMENTARIOS"},
{ value: "Q927",	text: "TRIPLOIDIA Y POLIPLOIDIA"},
{ value: "Q928",	text: "OTRAS TRISOMIAS Y TRISOMIAS PARCIALES DE LOS AUTOSOMAS, ESPECIFICADAS"},
{ value: "Q929",	text: "TRISOMIA Y TRISOMIA PARCIAL DE LOS AUTOSOMAS, SIN OTRA ESPECIFICACION"},
{ value: "Q930",	text: "MONOSOMIA COMPLETA DE UN CROMOSOMA, POR FALTA DE DISYUNCION MEIOTICA"},
{ value: "Q931",	text: "MONOSOMIA COMPLETA DE UN CROMOSOMA, MOSAICO (POR FALTA DE DISYUNCION MITOTICA)"},
{ value: "Q932",	text: "CROMOSOMA REEMPLAZADO POR ANILLO O DICENTRICO"},
{ value: "Q933",	text: "SUPRESION DEL BRAZO CORTO DEL CROMOSOMA 4"},
{ value: "Q934",	text: "SUPRESION DEL BRAZO CORTO DEL CROMOSOMA 5"},
{ value: "Q935",	text: "OTRAS SUPRESIONES DE PARTE DE UN CROMOSOMA"},
{ value: "Q936",	text: "SUPRESIONES VISIBLES SOLO EN LA PROMETAFASE"},
{ value: "Q937",	text: "SUPRESIONES CON OTROS REORDENAMIENTOS COMPLEJOS"},
{ value: "Q938",	text: "OTRAS SUPRESIONES DE LOS AUTOSOMAS"},
{ value: "Q939",	text: "SUPRESION DE LOS AUTOSOMAS, NO ESPECIFICADA"},
{ value: "Q950",	text: "TRANSLOCACION EQUILIBRADA E INSERCION EN INDIVIDUO NORMAL"},
{ value: "Q951",	text: "INVERSION CROMOSOMICA EN INDIVIDUO NORMAL"},
{ value: "Q952",	text: "REORDENAMIENTO AUTOSOMICO EQUILIBRADO EN INDIVIDUO ANORMAL"},
{ value: "Q953",	text: "REORDENAMIENTO AUTOSOMICO/SEXUAL EQUILIBRADO EN INDIVIDUO ANORMAL"},
{ value: "Q954",	text: "INDIVIDUOS CON HETEROCROMATINA MARCADORA"},
{ value: "Q955",	text: "INDIVIDUOS CON SITIO FRAGIL AUTOSOMICO"},
{ value: "Q958",	text: "OTROS REORDENAMIENTOS EQUILIBRADOS Y MARCADORES ESTRUCTURALES"},
{ value: "Q959",	text: "REORDENAMIENTO EQUILIBRADO Y MARCADOR ESTRUCTURAL, SIN OTRA ESPECIFICACION"},
{ value: "Q960",	text: "CARIOTIPO 45,X"},
{ value: "Q961",	text: "CARIOTIPO 46,X ISO (XQ)"},
{ value: "Q962",	text: "CARIOTIPO 46,X CON CROMOSOMA SEXUAL ANORMAL EXCEPTO ISO (XQ)"},
{ value: "Q963",	text: "MOSAICO 45,X/46,XX O XY"},
{ value: "Q964",	text: "MOSAICO 45,X/OTRA(S) LINEA(S) CELULAR(ES) CON CROMOSOMA SEXUAL ANORMAL"},
{ value: "Q968",	text: "OTRAS VARIANTES DEL SINDROME DE TURNER"},
{ value: "Q969",	text: "SINDROME DE TURNER, NO ESPECIFICADO"},
{ value: "Q970",	text: "CARIOTIPO 47,XXX"},
{ value: "Q971",	text: "MUJER CON MAS DE TRES CROMOSOMAS X"},
{ value: "Q972",	text: "MOSAICO, LINEAS CON NUMERO VARIABLE DE CROMOSOMAS X"},
{ value: "Q973",	text: "MUJER CON CARIOTIPO 46,XY"},
{ value: "Q978",	text: "OTRAS ANOMALIAS DE LOS CROMOSOMAS SEXUALES, CON FENOTIPO FEMENINO, ESPECIFICADAS"},
{ value: "Q979",	text: "ANOMALIA DE LOS CROMOSOMAS SEXUALES, CON FENOTIPO FEMENINO, SIN OTRA ESPECIFICACION"},
{ value: "Q980",	text: "SINDROME DE KLINEFELTER, CARIOTIPO 47,XXY"},
{ value: "Q981",	text: "SINDROME DE KLINEFELTER, HOMBRE CON MAS DE DOS CROMOSOMAS X"},
{ value: "Q982",	text: "SINDROME DE KLINEFELTER, HOMBRE CON CARIOTIPO 46,XX"},
{ value: "Q983",	text: "OTRO HOMBRE CON CARIOTIPO 46,XX"},
{ value: "Q984",	text: "SINDROME DE KLINEFELTER, NO ESPECIFICADO"},
{ value: "Q985",	text: "CARIOTIPO 47,XYY"},
{ value: "Q986",	text: "HOMBRE CON CROMOSOMA SEXUAL ESTRUCTURALMENTE ANORMAL"},
{ value: "Q987",	text: "HOMBRE CON MOSAICO DE CROMOSOMAS SEXUALES"},
{ value: "Q988",	text: "OTRAS ANOMALIAS DE LOS CROMOSOMAS SEXUALES, CON FENOTIPO MASCULINO, ESPECIFICADAS"},
{ value: "Q989",	text: "ANOMALIA DE LOS CROMOSOMAS SEXUALES, FENOTIPO MASCULINO, SIN OTRA ESPECIFICACION"},
{ value: "Q990",	text: "QUIMERA 46,XX/46,XY"},
{ value: "Q991",	text: "HERMAFRODITA VERDADERO 46,XX"},
{ value: "Q992",	text: "CROMOSOMA X FRAGIL"},
{ value: "Q998",	text: "OTRAS ANOMALIAS DE LOS CROMOSOMAS. ESPECIFICADAS"},
{ value: "Q999",	text: "ANOMALIA CROMOSOMICA, NO ESPECIFICADA"},
{ value: "R000",	text: "TAQUICARDIA, NO ESPECIFICADA"},
{ value: "R001",	text: "BRADICARDIA, NO ESPECIFICADA"},
{ value: "R002",	text: "PALPITACIONES"},
{ value: "R008",	text: "OTRAS ANORMALIDADES DEL LATIDO CARDIACO Y LAS NO ESPECIFICADAS"},
{ value: "R010",	text: "SOPLOS CARDIACOS BENIGNOS O INOCENTES"},
{ value: "R011",	text: "SOPLO CARDIACO, NO ESPECIFICADO"},
{ value: "R012",	text: "OTROS SONIDOS CARDIACOS"},
{ value: "R02X",	text: "GANGRENA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "R030",	text: "LECTURA ELEVADA DE LA PRESION SANGUINEA, SIN DIAGNOSTICO DE HIPERTENSION"},
{ value: "R031",	text: "LECTURA DE PRESION BAJA NO ESPECIFICA"},
{ value: "R040",	text: "EPISTAXIS"},
{ value: "R041",	text: "HEMORRAGIA DE LA GARGANTA"},
{ value: "R042",	text: "HEMOPTISIS"},
{ value: "R048",	text: "HEMORRAGIA DE OTROS SITIOS DE LAS VIAS RESPIRATORIAS"},
{ value: "R049",	text: "HEMORRAGIA DE LAS VIAS RESPIRATORIAS, NO ESPECIFICADA"},
{ value: "R05X",	text: "TOS"},
{ value: "R060",	text: "DISNEA"},
{ value: "R061",	text: "ESTRIDOR"},
{ value: "R062",	text: "SILBIDO"},
{ value: "R063",	text: "RESPIRACION PERIODICA"},
{ value: "R064",	text: "HIPERVENTILACION"},
{ value: "R065",	text: "RESPIRACION CON LA BOCA"},
{ value: "R066",	text: "HIPO"},
{ value: "R067",	text: "ESTORNUDO"},
{ value: "R068",	text: "OTRAS ANORMALIDADES DE LA RESPIRACION Y LAS NO ESPECIFICADAS"},
{ value: "R070",	text: "DOLOR DE GARGANTA"},
{ value: "R071",	text: "DOLOR EN EL PECHO AL RESPIRAR"},
{ value: "R072",	text: "DOLOR PRECORDIAL"},
{ value: "R073",	text: "OTROS DOLORES EN EL PECHO"},
{ value: "R074",	text: "DOLOR EN EL PECHO, NO ESPECIFICADO"},
{ value: "R090",	text: "ASFIXIA"},
{ value: "R091",	text: "PLEURESIA"},
{ value: "R092",	text: "PARO RESPIRATORIO"},
{ value: "R093",	text: "ESPUTO ANORMAL"},
{ value: "R098",	text: "OTROS SINTOMAS Y SIGNOS ESPECIFICADOS QUE INVOLUCRAN LOS SISTEMAS CIRCULATORIO Y RESPIRATORIO"},
{ value: "R100",	text: "ABDOMEN AGUDO"},
{ value: "R101",	text: "DOLOR ABDOMINAL LOCALIZADO EN PARTE SUPERIOR"},
{ value: "R102",	text: "DOLOR PELVICO Y PERINEAL"},
{ value: "R103",	text: "DOLOR LOCALIZADO EN OTRAS PARTES INFERIORES DEL ABDOMEN"},
{ value: "R104",	text: "OTROS DOLORES ABDOMINALES Y LOS NO ESPECIFICADOS"},
{ value: "R11X",	text: "NAUSEA Y VOMITO"},
{ value: "R12X",	text: "ACIDEZ"},
{ value: "R13X",	text: "DISFAGIA"},
{ value: "R14X",	text: "FLATULENCIA Y AFECCIONES AFINES"},
{ value: "R15X",	text: "INCONTINENCIA FECAL"},
{ value: "R160",	text: "HEPATOMEGALIA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "R161",	text: "ESPLENOMEGALIA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "R162",	text: "HEPATOMEGALIA CON ESPLENOMEGALIA, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "R17X",	text: "ICTERICIA NO ESPECIFICADA"},
{ value: "R18X",	text: "ASCITIS"},
{ value: "R190",	text: "TUMEFACCION, MASA O PROMINENCIA INTRAABDOMINAL Y PELVICA"},
{ value: "R191",	text: "SONIDOS INTESTINALES ANORMALES"},
{ value: "R192",	text: "PERISTALSIS VISIBLE"},
{ value: "R193",	text: "RIGIDEZ ABDOMINAL"},
{ value: "R194",	text: "CAMBIOS EN LOS HABITOS INTESTINALES"},
{ value: "R195",	text: "OTRAS ANORMALIDADES FECALES"},
{ value: "R196",	text: "HALITOSIS"},
{ value: "R198",	text: "OTROS SINTOMAS Y SIGNOS ESPECIFICADOS QUE INVOLUCRAN EL SISTEMA DIGESTIVO Y EL ABDOMEN"},
{ value: "R200",	text: "ANESTESIA DE LA PIEL"},
{ value: "R201",	text: "HIPOESTESIA DE LA PIEL"},
{ value: "R202",	text: "PARESTESIA DE LA PIEL"},
{ value: "R203",	text: "HIPERESTESIA"},
{ value: "R208",	text: "OTRAS ALTERACIONES DE LA SENSIBILIDAD CUTANEA Y LAS NO ESPECIFICADAS"},
{ value: "R21X",	text: "SALPULLIDO Y OTRAS ERUPCIONES CUTANEAS NO ESPECIFICADAS"},
{ value: "R220",	text: "TUMEFACCION, MASA O PROMINENCIA LOCALIZADA EN LA CABEZA"},
{ value: "R221",	text: "TUMEFACCION, MASA O PROMINENCIA LOCALIZADA EN EL CUELLO"},
{ value: "R222",	text: "TUMEFACCION, MASA O PROMINENCIA LOCALIZADA EN EL TRONCO"},
{ value: "R223",	text: "TUMEFACCION, MASA O PROMINENCIA LOCALIZADA EN EL MIEMBRO SUPERIOR"},
{ value: "R224",	text: "TUMEFACCION, MASA O PROMINENCIA LOCALIZADA EN EL MIEMBRO INFERIOR"},
{ value: "R227",	text: "TUMEFACCION, MASA O PROMINENCIA LOCALIZADA EN SITIOS MULTIPLES"},
{ value: "R229",	text: "TUMEFACCION, MASA O PROMINENCIA LOCALIZADA EN PARTE NO ESPECIFICADA"},
{ value: "R230",	text: "CIANOSIS"},
{ value: "R231",	text: "PALIDEZ"},
{ value: "R232",	text: "RUBOR"},
{ value: "R233",	text: "EQUIMOSIS ESPONTANEA"},
{ value: "R234",	text: "CAMBIOS EN LA TEXTURA DE LA PIEL"},
{ value: "R238",	text: "OTROS CAMBIOS DE LA PIEL Y LOS NO ESPECIFICADOS"},
{ value: "R250",	text: "MOVIMIENTOS ANORMALES DE LA CABEZA"},
{ value: "R251",	text: "TEMBLOR NO ESPECIFICADO"},
{ value: "R252",	text: "CALAMBRES Y ESPASMOS"},
{ value: "R253",	text: "FASCICULACION"},
{ value: "R258",	text: "OTROS MOVIMIENTOS ANORMALES INVOLUNTARIOS Y LOS NO ESPECIFICADOS"},
{ value: "R260",	text: "MARCHA ATAXICA"},
{ value: "R261",	text: "MARCHA PARALITICA"},
{ value: "R262",	text: "DIFICULTAD PARA CAMINAR, NO CLASIFICADA EN OTRA PARTE"},
{ value: "R268",	text: "OTRAS ANORMALIDADES DE LA MARCHA Y DE LA MOVILIDAD Y LAS NO ESPECIFICADAS"},
{ value: "R270",	text: "ATAXIA, NO ESPECIFICADA"},
{ value: "R278",	text: "OTRAS FALLAS DE LA COORDINACION Y LAS NO ESPECIFICADAS"},
{ value: "R290",	text: "TETANIA"},
{ value: "R291",	text: "MENINGISMO"},
{ value: "R292",	text: "REFLEJOS ANORMALES"},
{ value: "R293",	text: "POSTURA ANORMAL"},
{ value: "R294",	text: "CHASQUIDO DE LA CADERA"},
{ value: "R298",	text: "OTROS SINTOMAS Y SIGNOS QUE INVOLUCRAN LOS SISTEMAS NERVIOSO Y OSTEOMUSCULAR Y LOS NO ESPECIFICADOS"},
{ value: "R300",	text: "DISURIA"},
{ value: "R301",	text: "TENESMO VESICAL"},
{ value: "R309",	text: "MICCION DOLOROSA, NO ESPECIFICADA"},
{ value: "R31X",	text: "HEMATURIA, NO ESPECIFICADA"},
{ value: "R32X",	text: "INCONTINENCIA URINARIA, NO ESPECIFICADA"},
{ value: "R33X",	text: "RETENCION DE ORINA"},
{ value: "R34X",	text: "ANURIA Y OLIGURIA"},
{ value: "R35X",	text: "POLIURIA"},
{ value: "R36X",	text: "DESCARGA URETRAL"},
{ value: "R390",	text: "EXTRAVASACION DE LA ORINA"},
{ value: "R391",	text: "OTRAS DIFICULTADES DE LA MICCION"},
{ value: "R392",	text: "UREMIA EXTRARRENAL"},
{ value: "R398",	text: "OTROS SINTOMAS Y SIGNOS QUE INVOLUCRAN EL SISTEMA URINARIO Y LOS NO ESPECIFICADOS"},
{ value: "R400",	text: "SOMNOLENCIA"},
{ value: "R401",	text: "ESTUPOR"},
{ value: "R402",	text: "COMA, NO ESPECIFICADO"},
{ value: "R410",	text: "DESORIENTACION NO ESPECIFICADA"},
{ value: "R411",	text: "AMNESIA ANTEROGRADA"},
{ value: "R412",	text: "AMNESIA RETROGRADA"},
{ value: "R413",	text: "OTRA AMNESIA"},
{ value: "R418",	text: "OTROS SINTOMAS Y SIGNOS QUE INVOLUCRAN LA FUNCION COGNOSCITIVA Y LA CONCIENCIA Y LOS NO ESPECIFICADOS"},
{ value: "R42X",	text: "MAREO Y DESVANECIMIENTO"},
{ value: "R430",	text: "ANOSMIA"},
{ value: "R431",	text: "PAROSMIA"},
{ value: "R432",	text: "PARAGEUSIA"},
{ value: "R438",	text: "OTRAS ALTERACIONES DEL GUSTO Y DEL OLFATO Y LAS NO ESPECIFICADAS"},
{ value: "R440",	text: "ALUCINACIONES AUDITIVAS"},
{ value: "R441",	text: "ALUCINACIONES VISUALES"},
{ value: "R442",	text: "OTRAS ALUCINACIONES"},
{ value: "R443",	text: "ALUCINACIONES, NO ESPECIFICADAS"},
{ value: "R448",	text: "OTROS SINTOMAS Y SIGNOS QUE INVOLUCRAN LAS SENSACIONES Y PERCEPCIONES GENERALES Y LOS NO ESPECIFICADOS"},
{ value: "R450",	text: "NERVIOSISMO"},
{ value: "R451",	text: "INQUIETUD Y AGITACION"},
{ value: "R452",	text: "INFELICIDAD"},
{ value: "R453",	text: "DESMORALIZACION Y APATIA"},
{ value: "R454",	text: "IRRITABILIDAD Y ENOJO"},
{ value: "R455",	text: "HOSTILIDAD"},
{ value: "R456",	text: "VIOLENCIA FISICA"},
{ value: "R457",	text: "TENSION Y ESTADO DE CHOQUE EMOCIONAL, NO ESPECIFICADO"},
{ value: "R458",	text: "OTROS SINTOMAS Y SIGNOS QUE INVOLUCRAN EL ESTADO EMOCIONAL"},
{ value: "R460",	text: "MUY BAJO NIVEL DE HIGIENE PERSONAL"},
{ value: "R461",	text: "APARIENCIA PERSONAL EXTRAÑA"},
{ value: "R462",	text: "CONDUCTA EXTRAÑA E INEXPLICABLE"},
{ value: "R463",	text: "HIPERACTIVIDAD"},
{ value: "R464",	text: "LENTITUD Y POBRE RESPUESTA"},
{ value: "R465",	text: "SUSPICACIA Y EVASIVIDAD MARCADAS"},
{ value: "R466",	text: "PREOCUPACION INDEBIDA POR SUCESOS QUE CAUSAN TENSION"},
{ value: "R467",	text: "VERBOSIDAD Y DETALLES CIRCUNSTANCIALES QUE OSCURECEN LA RAZON DE LA CONSULTA O EL CONTACTO"},
{ value: "R468",	text: "OTROS SINTOMAS Y SIGNOS QUE INVOLUCRAN LA APARIENCIA Y EL COMPORTAMIENTO"},
{ value: "R470",	text: "DISFASIA Y AFASIA"},
{ value: "R471",	text: "DISARTRIA Y ANARTRIA"},
{ value: "R478",	text: "OTRAS ALTERACIONES DEL HABLA Y LAS NO ESPECIFICADAS"},
{ value: "R480",	text: "DISLEXIA Y ALEXIA"},
{ value: "R481",	text: "AGNOSIA"},
{ value: "R482",	text: "APRAXIA"},
{ value: "R488",	text: "OTRAS DISFUNCIONES SIMBOLICAS Y LAS NO ESPECIFICADAS"},
{ value: "R490",	text: "DISFONIA"},
{ value: "R491",	text: "AFONIA"},
{ value: "R492",	text: "HIPERNASALIDAD E HIPONASALIDAD"},
{ value: "R498",	text: "OTRAS ALTERACIONES DE LA VOZ Y LAS NO ESPECIFICADAS"},
{ value: "R500",	text: "FIEBRE CON ESCALOFRIO"},
{ value: "R501",	text: "FIEBRE PERSISTENTE"},
{ value: "R509",	text: "FIEBRE, NO ESPECIFICADA"},
{ value: "R51X",	text: "CEFALEA"},
{ value: "R520",	text: "DOLOR AGUDO"},
{ value: "R521",	text: "DOLOR CRONICO INTRATABLE"},
{ value: "R522",	text: "OTRO DOLOR CRONICO"},
{ value: "R529",	text: "DOLOR, NO ESPECIFICADO"},
{ value: "R53X",	text: "MALESTAR Y FATIGA"},
{ value: "R54X",	text: "SENILIDAD"},
{ value: "R55X",	text: "SINCOPE Y COLAPSO"},
{ value: "R560",	text: "CONVULSIONES FEBRILES"},
{ value: "R568",	text: "OTRAS CONVULSIONES Y LAS NO ESPECIFICADAS"},
{ value: "R570",	text: "CHOQUE CARDIOGENICO"},
{ value: "R571",	text: "CHOQUE HIPOVOLEMICO"},
{ value: "R578",	text: "OTRAS FORMAS DE CHOQUE"},
{ value: "R579",	text: "CHOQUE , NO ESPECIFICADO"},
{ value: "R58X",	text: "HEMORRAGIA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "R590",	text: "ADENOMEGALIA LOCALIZADA"},
{ value: "R591",	text: "ADENOMEGALIA GENERALIZADA"},
{ value: "R599",	text: "ADENOMEGALIA, NO ESPECIFICADA"},
{ value: "R600",	text: "EDEMA LOCALIZADO"},
{ value: "R601",	text: "EDEMA GENERALIZADO"},
{ value: "R609",	text: "EDEMA, NO ESPECIFICADO"},
{ value: "R610",	text: "HIPERHIDROSIS LOCALIZADA"},
{ value: "R611",	text: "HIPERHIDROSIS GENERALIZADA"},
{ value: "R619",	text: "HIPERHIDROSIS, NO ESPECIFICADA"},
{ value: "R620",	text: "RETARDO EN DESARROLLO"},
{ value: "R628",	text: "OTRAS FALTAS DEL DESARROLLO FISIOLOGICO NORMAL ESPERADO"},
{ value: "R629",	text: "FALTA DEL DESARROLLO FISIOLOGICO NORMAL ESPERADO SIN OTRA ESPECIFICACION"},
{ value: "R630",	text: "ANOREXIA"},
{ value: "R631",	text: "POLIDIPSIA"},
{ value: "R632",	text: "POLIFAGIA"},
{ value: "R633",	text: "DIFICULTADES Y MALA ADMINISTRACION DE LA ALIMENTACION"},
{ value: "R634",	text: "PERDIDA ANORMAL DE PESO"},
{ value: "R635",	text: "AUMENTO ANORMAL DE PESO"},
{ value: "R638",	text: "OTROS SINTOMAS Y SIGNOS CONCERNIENTES A LA ALIMENTACION Y A LA INGESTION DE LIQUIDOS"},
{ value: "R64X",	text: "CAQUEXIA"},
{ value: "R680",	text: "HIPOTERMIA NO ASOCIADA CON BAJA TEMPERATURA DEL AMBIENTE"},
{ value: "R681",	text: "SINTOMAS NO ESPECIFICOS PROPIOS DE LA INFANCIA"},
{ value: "R682",	text: "BOCA SECA, NO ESPECIFICADA"},
{ value: "R683",	text: "DEDOS DE LA MANO DEFORMES"},
{ value: "R688",	text: "OTROS SINTOMAS Y SIGNOS GENERALES ESPECIFICADOS"},
{ value: "R69X",	text: "CAUSAS DE MORBILIDAD DESCONOCIDAS Y NO ESPECIFICADAS"},
{ value: "R700",	text: "VELOCIDAD DE ERITROSEDIMENTACION ELEVADA"},
{ value: "R701",	text: "VISCOSIDAD PLASMATICA ANORMAL"},
{ value: "R71X",	text: "ANORMALIDAD DE LOS ERITROCITOS"},
{ value: "R72X",	text: "ANORMALIDADES DE LOS LEUCOCITOS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "R730",	text: "ANORMALIDADES EN LA PRUEBA DE TOLERANCIA A LA GLUCOSA"},
{ value: "R739",	text: "HIPERGLICEMIA, NO ESPECIFICADA"},
{ value: "R740",	text: "ELEVACION DE LOS NIVELES DE TRANSAMINASAS O DESHIDROGENASA LACTICA [DHL]"},
{ value: "R748",	text: "NIVELES ANORMALES DE OTRAS ENZIMAS EN SUERO"},
{ value: "R749",	text: "NIVEL ANORMAL DE ENZIMAS EN SUERO, NO ESPECIFICADO"},
{ value: "R75X",	text: "EVIDENCIAS DE LABORATORIO DEL VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]"},
{ value: "R760",	text: "TITULACION ELEVADA DE ANTICUERPOS"},
{ value: "R761",	text: "REACCION ANORMAL A LA PRUEBA CON TUBERCULINA"},
{ value: "R762",	text: "FALSO POSITIVO EN LA PRUEBA SEROLOGICA PARA SIFILIS"},
{ value: "R768",	text: "OTROS HALLAZGOS INMUNOLOGICOS ANORMALES ESPECIFICADOS EN SUERO"},
{ value: "R769",	text: "HALLAZGOS INMUNOLOGICOS ANORMALES ESPECIFICADOS EN SUERO"},
{ value: "R770",	text: "ANORMALIDAD DE LA ALBUMINA"},
{ value: "R771",	text: "ANORMALIDAD DE LA GLOBULINA"},
{ value: "R772",	text: "ANORMALIDAD DE LA ALFAFETOPROTEINA"},
{ value: "R778",	text: "OTRAS ANORMALIDADES ESPECIFICADAS DE LAS PROTEINAS PLASMATICAS"},
{ value: "R779",	text: "ANORMALIDADES NO ESPECIFICADAS DE LAS PROTEINAS PLASMATICAS"},
{ value: "R780",	text: "HALLAZGO DE ALCOHOL EN LA SANGRE"},
{ value: "R781",	text: "HALLAZGO DE DROGAS OPIACEAS EN LA SANGRE"},
{ value: "R782",	text: "HALLAZGO DE COCAINA EN LA SANGRE"},
{ value: "R783",	text: "HALLAZGO DE ALUCINOGENOS EN LA SANGRE"},
{ value: "R784",	text: "HALLAZGO DE OTRAS DROGAS POTENCIALMENTE ADICTIVAS EN LA SANGRE"},
{ value: "R785",	text: "HALLAZGO DE DROGAS PSICOTROPICAS EN LA SANGRE"},
{ value: "R786",	text: "HALLAZGO DE AGENTES ESTEROIDES EN LA SANGRE"},
{ value: "R787",	text: "HALLAZGO DE NIVELES ANORMALES DE METALES PESADOS EN LA SANGRE"},
{ value: "R788",	text: "HALLAZGO DE OTRAS SUSTANCIAS ESPECIFICAS QUE NORMALMENTE NO SE ENCUENTRAN EN LA SANGRE"},
{ value: "R789",	text: "HALLAZGO DE SUSTANCIA NO ESPECIFICA QUE NORMALMENTE NO SE ENCUENTRAN EN LA SANGRE"},
{ value: "R790",	text: "NIVEL ANORMAL DE MINERAL EN LA SANGRE"},
{ value: "R798",	text: "OTROS HALLAZGOS ANORMALES ESPECIFICADOS EN LA QUIMICA SANGUINEA"},
{ value: "R799",	text: "HALLAZGO ANORMAL EN LA QUIMICA SANGUINEA, SIN OTRA ESPECIFICACION"},
{ value: "R80X",	text: "PROTEINURIA AISLADA"},
{ value: "R81X",	text: "GLUCOSURIA"},
{ value: "R820",	text: "QUILURIA"},
{ value: "R821",	text: "MIOGLOBINURIA"},
{ value: "R822",	text: "BILIURIA"},
{ value: "R823",	text: "HEMOGLOBINURIA"},
{ value: "R824",	text: "ACETONURIA"},
{ value: "R825",	text: "ELEVACION DE LOS NIVELES DE DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS EN LA ORINA"},
{ value: "R826",	text: "NIVELES ANORMALES EN LA ORINA DE SUSTANCIAS DE ORIGEN PRINCIPALMENTE NO MEDICINAL"},
{ value: "R827",	text: "HALLAZGOS ANORMALES EN EL EXAMEN MICROBIOLOGICO DE LA ORINA"},
{ value: "R828",	text: "HALLAZGOS ANORMALES EN EL EXAMEN CITOLOGICO E HISTOLOGICO DE LA ORINA"},
{ value: "R829",	text: "OTROS HALLAZGOS ANORMALES EN LA ORINA Y LOS NO ESPECIFICADOS"},
{ value: "R830",	text: "HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO: NIVEL ANORMAL DE ENZIMAS"},
{ value: "R831",	text: "HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO: NIVEL ANORMAL DE HORMONAS"},
{ value: "R832",	text: "HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO: NIVEL ANORMAL DE OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS"},
{ value: "R833",	text: "HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO: NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL"},
{ value: "R834",	text: "HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO: HALLAZGOS INMUNOLOGICOS ANORMALES"},
{ value: "R835",	text: "HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO: HALLAZGOS MICROBIOLOGICOS ANORMALES HALLAZGOS POSITIVOS EN EL CULTIVO"},
{ value: "R836",	text: "HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO: HALLAZGOS CITOLOGICOS ANORMALES, FROTIS ANORMAL DE PAPANICOLAOU"},
{ value: "R837",	text: "HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO: HALLAZGOS HISTOLOGICOS ANORMALES"},
{ value: "R838",	text: "HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO: OTROS HALLAZGOS ANORMALES, HALLAZGOS CROMOSOMICOS ANORMALES"},
{ value: "R839",	text: "HALLAZGOS ANORMALES EN EL LIQUIDO CEFALORRAQUIDEO: HALLAZGOS ANORMALES, NO ESPECIFICADOS"},
{ value: "R840",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS: NIVEL ANORMAL DE ENZIMAS"},
{ value: "R841",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS: NIVEL ANORMAL DE HORMONAS"},
{ value: "R842",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS: NIVEL ANORMAL DE OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS"},
{ value: "R843",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS: NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL"},
{ value: "R844",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS: HALLAZGOS INMUNOLOGICOS ANORMALES"},
{ value: "R845",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS: HALLAZGOS MICROBIOLOGICOS ANORMALES, HALLAZGOS POSITIVOS EN EL CULTIVO"},
{ value: "R846",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS: HALLAZGOS CITOLOGICOS ANORMALES, FROTIS ANORMAL DE PAPANICOLAOU"},
{ value: "R847",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS: HALLAZGOS HISTOLOGICOS ANORMALES"},
{ value: "R848",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS: OTROS HALLAZGOS ANORMALES, HALLAZGOS CROMOSOMICOS ANORMALES"},
{ value: "R849",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS RESPIRATORIOS Y TORACICOS: HALLAZGOS ANORMALES, NO ESPECIFICADOS"},
{ value: "R850",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL: NIVEL ANORMAL DE ENZIMAS"},
{ value: "R851",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL: NIVEL ANORMAL DE HORMONAS"},
{ value: "R852",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL: NIVEL ANORMAL DE OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS"},
{ value: "R853",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL: NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL"},
{ value: "R854",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL: HALLAZGOS INMUNOLOGICOS ANORMALES"},
{ value: "R855",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL: HALLAZGOS MICROBIOLOGICOS ANORMALES, HALLAZGOS POSITIVOS EN EL CULTIVO"},
{ value: "R856",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL: HALLAZGOS CITOLOGICOS ANORMALES, FROTIS ANORMAL DE PAPANICOLAOU"},
{ value: "R857",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL: HALLAZGOS HISTOLOGICOS ANORMALES"},
{ value: "R858",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL: OTROS HALLAZGOS ANORMALES, HALLAZGOS CROMOSOMICOS ANORMALES"},
{ value: "R859",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS DIGESTIVOS Y DE LA CAVIDAD ABDOMINAL: HALLAZGOS ANORMALES, NO ESPECIFICADOS"},
{ value: "R860",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS: NIVEL ANORMAL DE ENZIMAS"},
{ value: "R861",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS: NIVEL ANORMAL DE HORMONAS"},
{ value: "R862",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS : NIVEL ANORMAL DE OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS"},
{ value: "R863",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS: NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL"},
{ value: "R864",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS: HALLAZGOS INMUNOLOGICOS ANORMALES"},
{ value: "R865",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS: HALLAZGOS MICROBIOLOGICOS ANORMALES, HALLAZGOS POSITIVOS EN EL CULTIVO"},
{ value: "R866",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS: HALLAZGOS CITOLOGICOS ANORMALES, FROTIS ANORMAL DE PAPANICOLAOU"},
{ value: "R867",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS: HALLAZGOS HISTOLOGICOS ANORMALES"},
{ value: "R868",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS: OTROS HALLAZGOS ANORMALES, HALLAZGOS CROMOSOMICOS ANORMALES"},
{ value: "R869",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES MASCULINOS: HALLAZGOS ANORMALES, NO ESPECIFICADOS"},
{ value: "R870",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS: NIVEL ANORMAL DE ENZIMAS"},
{ value: "R871",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS: NIVEL ANORMAL DE HORMONAS"},
{ value: "R872",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS: NIVEL ANORMAL DE OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS"},
{ value: "R873",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS: NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL"},
{ value: "R874",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS: HALLAZGOS INMUNOLOGICOS ANORMALES"},
{ value: "R875",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS: HALLAZGOS MICROBIOLOGICOS ANORMALES, HALLAZGOS POSITIVOS EN EL CULTIVO"},
{ value: "R876",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS: HALLAZGOS HISTOLOGICOS ANORMALES"},
{ value: "R877",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS: HALLAZGOS HISTOLOGICOS ANORMALES"},
{ value: "R878",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS: OTROS HALLAZGOS ANORMALES, HALLAZGOS CROMOSOMICOS ANORMALES"},
{ value: "R879",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE ORGANOS GENITALES FEMENINOS: HALLAZGOS ANORMALES, NO ESPECIFICADOS"},
{ value: "R890",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS: NIVEL ANORMAL DE ENZIMAS"},
{ value: "R891",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS: NIVEL ANORMAL DE HORMONAS"},
{ value: "R892",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS: NIVEL ANORMAL DE OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS"},
{ value: "R893",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS: NIVEL ANORMAL DE SUSTANCIAS DE ORIGEN FUNDAMENTALMENTE NO MEDICINAL"},
{ value: "R894",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS: HALLAZGOS INMUNOLOGICOS ANORMALES"},
{ value: "R895",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS: HALLAZGOS MICROBIOLOGICOS ANORMALES, HALLAZGOS POSITIVOS EN EL CULTIVO"},
{ value: "R896",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS: HALLAZGOS CITOLOGICOS ANORMALES, FROTIS ANORMAL DE PAPANICOLAOU"},
{ value: "R897",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS: HALLAZGOS HISTOLOGICOS ANORMALES"},
{ value: "R898",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS: OTROS HALLAZGOS ANORMALES, HALLAZGOS CROMOSOMICOS ANORMALES"},
{ value: "R899",	text: "HALLAZGOS ANORMALES EN MUESTRAS TOMADAS DE OTROS ORGANOS, SISTEMAS Y TEJIDOS: HALLAZGOS ANORMALES, NO ESPECIFICADOS"},
{ value: "R900",	text: "LESION QUE OCUPA EL ESPACIO INTRACRANEAL"},
{ value: "R908",	text: "OTROS HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN Y DEL SISTEMA NERVIOSO CENTRAL"},
{ value: "R91X",	text: "HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DEL PULMON"},
{ value: "R92X",	text: "HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DE LA MAMA"},
{ value: "R930",	text: "HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DEL CRANEO Y DE LA CABEZA, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "R931",	text: "HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DEL CORAZON Y DE LA CIRCULACION CORONARIA"},
{ value: "R932",	text: "HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DEL HIGADO Y DE LAS VIAS BILIARES"},
{ value: "R933",	text: "HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DE OTRAS PARTES DE LAS VIAS DIGESTIVAS"},
{ value: "R934",	text: "HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DE LOS ORGANOS URINARIOS"},
{ value: "R935",	text: "HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DE OTRAS REGIONES ABDOMINALES, INCLUIDO EL RETROPERITONEO"},
{ value: "R936",	text: "HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DE LOS MIEMBROS"},
{ value: "R937",	text: "HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DE OTRAS PARTES DEL SISTEMA OSTEOMUSCULAR"},
{ value: "R938",	text: "HALLAZGOS ANORMALES EN DIAGNOSTICO POR IMAGEN DE OTRAS ESTRUCTURAS ESPECIFICADAS DEL CUERPO"},
{ value: "R940",	text: "RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DEL SISTEMA NERVIOSO CENTRAL"},
{ value: "R941",	text: "RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DEL SISTEMA NERVIOSO PERIFERICO Y SENTIDOS ESPECIALES"},
{ value: "R942",	text: "RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DEL PULMON"},
{ value: "R943",	text: "RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES CARDIOVASCULARES"},
{ value: "R944",	text: "RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DEL RIÑON"},
{ value: "R945",	text: "RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DEL HIGADO"},
{ value: "R946",	text: "RESULTADOS ANORMALES EN ESTUDIOS FUNCIONALES DE LA TIROIDES"},
{ value: "R947",	text: "RESULTADOS ANORMALES EN OTROS ESTUDIOS FUNCIONALES ENDOCRINOS"},
{ value: "R948",	text: "RESULTADOS ANORMALES EN LOS ESTUDIOS FUNCIONALES DE OTROS ORGANOS Y SISTEMAS"},
{ value: "R95X",	text: "SINDROME DE LA MUERTE SUBITA INFANTIL"},
{ value: "R960",	text: "MUERTE INSTANTANEA"},
{ value: "R961",	text: "MUERTE QUE OCURRE EN MENOS DE 24 HORAS DEL INICIO DE LOS SINTOMAS, NO EXPLICADA DE OTRA FORMA"},
{ value: "R98X",	text: "MUERTE SIN ASISTENCIA"},
{ value: "R99X",	text: "OTRAS CAUSAS MAL DEFINIDAS Y LAS NO ESPECIFICADAS DE MORTALIDAD"},
{ value: "S000",	text: "TRAUMATISMO SUPERFICIAL DEL CUERO CABELLUDO"},
{ value: "S001",	text: "CONTUSION DE LOS PARPADOS Y DE LA REGION PERIOCULAR"},
{ value: "S002",	text: "OTROS TRAUMATISMOS SUPERFICIALES DEL PARPADO Y DE LA REGION PERIOCULAR"},
{ value: "S003",	text: "TRAUMATISMO SUPERFICIAL DE LA NARIZ"},
{ value: "S004",	text: "TRAUMATISMO SUPERFICIAL DEL OIDO"},
{ value: "S005",	text: "TRAUMATISMO SUPERFICIAL DEL LABIO Y DE LA CAVIDAD BUCAL"},
{ value: "S007",	text: "TRAUMATISMOS SUPERFICIALES MULTIPLES DE LA CABEZA"},
{ value: "S008",	text: "TRAUMATISMO SUPERFICIAL DE OTRAS PARTES DE LA CABEZA"},
{ value: "S009",	text: "TRAUMATISMO SUPERFICIAL DE LA CABEZA, PARTE NO ESPECIFICADA"},
{ value: "S010",	text: "HERIDA DEL CUERO CABELLUDO"},
{ value: "S011",	text: "HERIDA DEL PARPADO Y DE LA REGION PERIOCULAR"},
{ value: "S012",	text: "HERIDA DE LA NARIZ"},
{ value: "S013",	text: "HERIDA DEL OIDO"},
{ value: "S014",	text: "HERIDA DE LA MEJILLA Y DE LA REGION TEMPOROMANDIBULAR"},
{ value: "S015",	text: "HERIDA DEL LABIO Y DE LA CAVIDAD BUCAL"},
{ value: "S017",	text: "HERIDAS MULTIPLES DE LA CABEZA"},
{ value: "S018",	text: "HERIDA DE OTRAS PARTES DE LA CABEZA"},
{ value: "S019",	text: "HERIDA DE LA CABEZA, PARTE NO ESPECIFICADA"},
{ value: "S020",	text: "FRACTURA DE LA BOVEDA DEL CRANEO"},
{ value: "S021",	text: "FRACTURA DE LA BASE DEL CRANEO"},
{ value: "S022",	text: "FRACTURA DE LOS HUESOS DE LA NARIZ"},
{ value: "S023",	text: "FRACTURA DEL SUELO DE LA ORBITA"},
{ value: "S024",	text: "FRACTURA DEL MALAR Y DEL HUESO MAXILAR SUPERIOR"},
{ value: "S025",	text: "FRACTURA DE LOS DIENTES"},
{ value: "S026",	text: "FRACTURA DEL MAXILAR INFERIOR"},
{ value: "S027",	text: "FRACTURAS MULTIPLES QUE COMPROMETEN EL CRANEO Y LOS HUESOS DE LA CARA"},
{ value: "S028",	text: "FRACTURA DE OTROS HUESOS DEL CRANEO Y DE LA CARA"},
{ value: "S029",	text: "FRACTURA DEL CRANEO Y DE LOS HUESOS DE LA CARA, PARTE NO ESPECIFICADA"},
{ value: "S030",	text: "LUXACION DEL MAXILAR"},
{ value: "S031",	text: "LUXACION DEL CARTILAGO SEPTAL DE LA NARIZ"},
{ value: "S032",	text: "LUXACION DE DIENTE"},
{ value: "S033",	text: "LUXACION DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CABEZA"},
{ value: "S034",	text: "ESGUINCES Y TORCEDURAS DEL MAXILAR"},
{ value: "S035",	text: "ESGUINCES Y TORCEDURAS DE ARTICULACIONES Y LIGAMENTOS DE OTRAS PARTES Y LAS NO ESPECIFICADAS DE LA CABEZA"},
{ value: "S040",	text: "TRAUMATISMO DEL NERVIO OPTICO [II PAR] Y DE LAS VIAS OPTICAS"},
{ value: "S041",	text: "TRAUMATISMO DEL NERVIO MOTOR OCULAR COMUN [III PAR]"},
{ value: "S042",	text: "TRAUMATISMO DEL NERVIO PATETICO [IV PAR]"},
{ value: "S043",	text: "TRAUMATISMO DEL NERVIO TRIGEMINO [V PAR]"},
{ value: "S044",	text: "TRAUMATISMO DEL NERVIO MOTOR OCULAR EXTERNO [VI PAR]"},
{ value: "S045",	text: "TRAUMATISMO DEL NERVIO FACIAL [VII PAR]"},
{ value: "S046",	text: "TRAUMATISMO DEL NERVIO ACUSTICO [VIII PAR]"},
{ value: "S047",	text: "TRAUMATISMO DEL NERVIO ESPINAL [XI PAR]"},
{ value: "S048",	text: "TRAUMATISMO DE OTROS NERVIOS CRANEALES"},
{ value: "S049",	text: "TRAUMATISMO DEL NERVIOS CRANEALES, NO ESPECIFICADO"},
{ value: "S050",	text: "TRAUMATISMO DE LA CONJUNTIVA Y ABRASION CORNEAL SIN MENCION DE CUERPO EXTRAÑO"},
{ value: "S051",	text: "CONTUSION DEL GLOBO OCULAR Y DEL TEJIDO ORBITARIO"},
{ value: "S052",	text: "LACERACION Y RUPTURA OCULAR CON PROLAPSO O PERDIDA DEL TEJIDO INTRAOCULAR"},
{ value: "S053",	text: "LACERACION OCULAR SIN PROLAPSO O PERDIDA DEL TEJIDO INTRAOCULAR"},
{ value: "S054",	text: "HERIDA PENETRANTE DE LA ORBITA CON O SIN CUERPO EXTRAÑO"},
{ value: "S055",	text: "HERIDA PENETRANTE DEL GLOBO OCULAR CON CUERPO EXTRAÑO"},
{ value: "S056",	text: "HERIDA PENETRANTE DEL GLOBO OCULAR SIN CUERPO EXTRAÑO"},
{ value: "S057",	text: "AVULSION DEL OJO"},
{ value: "S058",	text: "OTROS TRAUMATISMOS DEL OJO Y DE LA ORBITA"},
{ value: "S059",	text: "TRAUMATISMO DEL OJO Y DE LA ORBITA, NO ESPECIFICADO"},
{ value: "S060",	text: "CONCUSION"},
{ value: "S061",	text: "EDEMA CEREBRAL TRAUMATICO"},
{ value: "S062",	text: "TRAUMATISMO CEREBRAL DIFUSO"},
{ value: "S063",	text: "TRAUMATISMO CEREBRAL FOCAL"},
{ value: "S064",	text: "HEMORRAGIA EPIDURAL"},
{ value: "S065",	text: "HEMORRAGIA SUBDURAL TRAUMATICA"},
{ value: "S066",	text: "HEMORRAGIA SUBARACNOIDEA TRAUMATICA"},
{ value: "S067",	text: "TRAUMATISMO INTRACRANEAL CON COMA PROLONGADO"},
{ value: "S068",	text: "OTROS TRAUMATISMOS INTRACRANEALES"},
{ value: "S069",	text: "TRAUMATISMO INTRACRANEAL, NO ESPECIFICADO"},
{ value: "S070",	text: "TRAUMATISMO POR APLASTAMIENTO DE LA CARA"},
{ value: "S071",	text: "TRAUMATISMO POR APLASTAMIENTO DEL CRANEO"},
{ value: "S078",	text: "TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES DE LA CABEZA"},
{ value: "S079",	text: "TRAUMATISMO POR APLASTAMIENTO DE LA CABEZA, PARTE NO ESPECIFICADA"},
{ value: "S080",	text: "AVULSION DEL CUERO CABELLUDO"},
{ value: "S081",	text: "AMPUTACION TRAUMATICA DE LA OREJA"},
{ value: "S088",	text: "AMPUTACION TRAUMATICA DE OTRAS PARTES DE LA CABEZA"},
{ value: "S089",	text: "AMPUTACION TRAUMATICA DE PARTE NO ESPECIFICADA DE LA CABEZA"},
{ value: "S090",	text: "TRAUMATISMO DE LOS VASOS SANGUINEOS DE LA CABEZA NO CLASIFICADOS EN OTRA PARTE"},
{ value: "S091",	text: "TRAUMATISMO DE TENDON Y MUSCULOS DE LA CABEZA"},
{ value: "S092",	text: "RUPTURA TRAUMATICA DEL TIMPANO DEL OIDO"},
{ value: "S097",	text: "TRAUMATISMOS MULTIPLES DE LA CABEZA"},
{ value: "S098",	text: "OTROS TRAUMATISMOS DE LA CABEZA, ESPECIFICADOS"},
{ value: "S099",	text: "TRAUMATISMO DE LA CABEZA, NO ESPECIFICADO"},
{ value: "S100",	text: "CONTUSION DE LA GARGANTA"},
{ value: "S101",	text: "OTROS TRAUMATISMOS SUPERFICIALES Y LOS NO ESPECIFICADOS DE LA GARGANTA"},
{ value: "S107",	text: "TRAUMATISMO SUPERFICIAL MULTIPLE DEL CUELLO"},
{ value: "S108",	text: "TRAUMATISMO SUPERFICIAL DE OTRAS PARTES DEL CUELLO"},
{ value: "S109",	text: "TRAUMATISMO SUPERFICIAL DEL CUELLO, PARTE NO ESPECIFICADA"},
{ value: "S110",	text: "HERIDA QUE COMPROMETE LA LARINGE Y LA TRAQUEA"},
{ value: "S111",	text: "HERIDA QUE COMPROMETE LA GLANDULA TIROIDES"},
{ value: "S112",	text: "HERIDA QUE COMPROMETE LA FARINGE Y EL ESOFAGO CERVICAL"},
{ value: "S117",	text: "HERIDAS MULTIPLES DEL CUELLO"},
{ value: "S118",	text: "HERIDAS DE OTRAS PARTES DEL CUELLO"},
{ value: "S119",	text: "HERIDA DE CUELLO, PARTE NO ESPECIFICADA"},
{ value: "S120",	text: "FRACTURA DE LA PRIMERA VERTEBRA CERVICAL"},
{ value: "S121",	text: "FRACTURA DE LA SEGUNDA VERTEBRA CERVICAL"},
{ value: "S122",	text: "FRACTURA DE OTRAS VERTEBRAS CERVICALES ESPECIFICADAS"},
{ value: "S127",	text: "FRACTURAS MULTIPLES DE COLUMNA CERVICAL"},
{ value: "S128",	text: "FRACTURA DE OTRAS PARTES DEL CUELLO"},
{ value: "S129",	text: "FRACTURA DEL CUELLO, PARTE NO ESPECIFICADA"},
{ value: "S130",	text: "RUPTURA TRAUMATICA DE DISCO CERVICAL INTERVERTEBRAL"},
{ value: "S131",	text: "LUXACION DE VERTEBRA CERVICAL"},
{ value: "S132",	text: "LUXACIONES DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL CUELLO"},
{ value: "S133",	text: "LUXACIONES MULTIPLES DEL CUELLO"},
{ value: "S134",	text: "ESGUINCES Y TORCEDURAS DE LA COLUMNA CERVICAL"},
{ value: "S135",	text: "ESGUINCES Y TORCEDURAS DE LA REGION TIROIDEA"},
{ value: "S136",	text: "ESGUINCES Y TORCEDURAS DE ARTICULACIONES Y LIGAMENTOS DE OTROS SITIOS ESPECIFICADOS Y DE LOS NO ESPECIFICADOS DEL CUELLO"},
{ value: "S140",	text: "CONCUSION Y EDEMA DE LA MEDULA ESPINAL CERVICAL"},
{ value: "S141",	text: "OTROS TRAUMATISMOS DE LA MEDULA ESPINAL CERVICAL Y LOS NO ESPECIFICADOS"},
{ value: "S142",	text: "TRAUMATISMO DE RAIZ NERVIOSA DE COLUMNA CERVICAL"},
{ value: "S143",	text: "TRAUMATISMO DE PLEXO BRAQUIAL"},
{ value: "S144",	text: "TRAUMATISMO DE NERVIOS PERIFERICOS DEL CUELLO"},
{ value: "S145",	text: "TRAUMATISMO DE NERVIOS CERVICALES SIMPATICOS"},
{ value: "S146",	text: "TRAUMATISMO DE OTROS NERVIOS Y DE LOS NO ESPECIFICADOS DEL CUELLO"},
{ value: "S150",	text: "TRAUMATISMO DE LA ARTERIA CAROTIDA"},
{ value: "S151",	text: "TRAUMATISMO DE LA ARTERIA VERTEBRAL"},
{ value: "S152",	text: "TRAUMATISMO DE LA VENA YUGULAR EXTERNA"},
{ value: "S153",	text: "TRAUMATISMO DE LA VENA YUGULAR INTERNA"},
{ value: "S157",	text: "TRAUMATISMO DE MULTIPLES VASOS SANGUINEOS A NIVEL DEL CUELLO"},
{ value: "S158",	text: "TRAUMATISMO DE OTROS VASOS SANGUINEOS A NIVEL DEL CUELLO"},
{ value: "S159",	text: "TRAUMATISMO DE VASOS SANGUINEOS NO ESPECIFICADOS A NIVEL DEL CUELLO"},
{ value: "S16X",	text: "TRAUMATISMO DE TENDON Y MUSCULOS A NIVEL DEL CUELLO"},
{ value: "S170",	text: "TRAUMATISMO POR APLASTAMIENTO DE LA LARINGE Y DE LA TRAQUEA"},
{ value: "S178",	text: "TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES DEL CUELLO"},
{ value: "S179",	text: "TRAUMATISMO POR APLASTAMIENTO DEL CUELLO, PARTE NO ESPECIFICADA"},
{ value: "S18X",	text: "AMPUTACION TRAUMATICA A NIVEL DEL CUELLO"},
{ value: "S197",	text: "TRAUMATISMOS MULTIPLES DEL CUELLO"},
{ value: "S198",	text: "OTROS TRAUMATISMOS DEL CUELLO, ESPECIFICADOS"},
{ value: "S199",	text: "TRAUMATISMO DEL CUELLO, NO ESPECIFICADO"},
{ value: "S200",	text: "CONTUSION DE LA MAMA"},
{ value: "S201",	text: "OTROS TRAUMATISMOS SUPERFICIALES Y LOS NO ESPECIFICADOS DE LA MAMA"},
{ value: "S202",	text: "CONTUSION DEL TORAX"},
{ value: "S203",	text: "OTROS TRAUMATISMOS SUPERFICIALES DE LA PARED ANTERIOR DEL TORAX"},
{ value: "S204",	text: "OTROS TRAUMATISMOS SUPERFICIALES DE LA PARED POSTERIOR DEL TORAX"},
{ value: "S207",	text: "TRAUMATISMOS SUPERFICIALES MULTIPLES DEL TORAX"},
{ value: "S208",	text: "TRAUMATISMO SUPERFICIAL DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL TORAX"},
{ value: "S210",	text: "HERIDA DE LA MAMA"},
{ value: "S211",	text: "HERIDA DE LA PARED ANTERIOR DEL TORAX"},
{ value: "S212",	text: "HERIDA DE LA PARED POSTERIOR DEL TORAX"},
{ value: "S217",	text: "HERIDA MULTIPLE DE LA PARED TORACICA"},
{ value: "S218",	text: "HERIDAS DE OTRAS PARTES DEL TORAX"},
{ value: "S219",	text: "HERIDAS DEL TORAX, PARTE NO ESPECIFICADA"},
{ value: "S220",	text: "FRACTURA DE VERTEBRA TORACICA"},
{ value: "S221",	text: "FRACTURAS MULTIPLES DE COLUMNA TORACICA"},
{ value: "S222",	text: "FRACTURA DE ESTERNON"},
{ value: "S223",	text: "FRACTURA DE COSTILLA"},
{ value: "S224",	text: "FRACTURAS MULTIPLES DE COSTILLA"},
{ value: "S225",	text: "TORAX AZOTADO"},
{ value: "S228",	text: "FRACTURA DE OTRAS PARTES DEL TORAX OSEO"},
{ value: "S229",	text: "FRACTURA DEL TORAX OSEO, PARTE NO ESPECIFICADA"},
{ value: "S230",	text: "RUPTURA TRAUMATICA DE DISCO INTERVERTEBRAL TORACICO"},
{ value: "S231",	text: "LUXACION DE VERTEBRA TORACICA"},
{ value: "S232",	text: "LUXACION DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL TORAX"},
{ value: "S233",	text: "ESGUINCES Y TORCEDURAS DE COLUMNA TORACICA"},
{ value: "S234",	text: "ESGUINCES Y TORCEDURAS DE COSTILLAS Y ESTERNON"},
{ value: "S235",	text: "ESGUINCES Y TORCEDURAS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL TORAX"},
{ value: "S240",	text: "CONCUSION Y EDEMA DE LA MEDULA ESPINAL TORACICA"},
{ value: "S241",	text: "OTROS TRAUMATISMOS Y LOS NO ESPECIFICADOS DE LA MEDULA ESPINAL TORACICA"},
{ value: "S242",	text: "TRAUMATISMO DE RAICES NERVIOSAS DE LA COLUMNA TORACICA"},
{ value: "S243",	text: "TRAUMATISMO DE NERVIOS PERIFERICOS DEL TORAX"},
{ value: "S244",	text: "TRAUMATISMO DE NERVIOS SIMPATICOS TORACICOS"},
{ value: "S245",	text: "TRAUMATISMO DE OTROS NERVIOS DEL TORAX"},
{ value: "S246",	text: "TRAUMATISMO DE NERVIO NO ESPECIFICADO DEL TORAX"},
{ value: "S250",	text: "TRAUMATISMO DE LA AORTA TORACICA"},
{ value: "S251",	text: "TRAUMATISMO DE LA ARTERIA INNOMINADA O SUBCLAVIA"},
{ value: "S252",	text: "TRAUMATISMO DE LA VENA CAVA SUPERIOR"},
{ value: "S253",	text: "TRAUMATISMO DE LA VENA INNOMINADA O SUBCLAVIA"},
{ value: "S254",	text: "TRAUMATISMO DE VASOS SANGUINEOS PULMONARES"},
{ value: "S255",	text: "TRAUMATISMO DE VASOS SANGUINEOS INTERCOSTALES"},
{ value: "S257",	text: "TRAUMATISMO DE MULTIPLES VASOS SANGUINEOS DEL TORAX"},
{ value: "S258",	text: "TRAUMATISMO DE OTROS VASOS SANGUINEOS DEL TORAX"},
{ value: "S259",	text: "TRAUMATISMO DE VASOS SANGUINEOS NO ESPECIFICADOS DEL TORAX"},
{ value: "S260",	text: "TRAUMATISMO DEL CORAZON CON HEMOPERICARDIO"},
{ value: "S268",	text: "OTROS TRAUMATISMOS DEL CORAZON"},
{ value: "S269",	text: "TRAUMATISMOS DEL CORAZON, NO ESPECIFICADO"},
{ value: "S270",	text: "NEUMOTORAX TRAUMATICO"},
{ value: "S271",	text: "HEMOTORAX TRAUMATICO"},
{ value: "S272",	text: "HEMONEUMOTORAX TRAUMATICO"},
{ value: "S273",	text: "OTROS TRAUMATISMOS DEL PULMON"},
{ value: "S274",	text: "TRAUMATISMO DE LOS BRONQUIOS"},
{ value: "S275",	text: "TRAUMATISMO DE LA TRAQUEA TORACICA"},
{ value: "S276",	text: "TRAUMATISMO DE LA PLEURA"},
{ value: "S277",	text: "TRAUMATISMO DE MULTIPLES DE ORGANOS INTRATORACICOS"},
{ value: "S278",	text: "TRAUMATISMO DE OTROS ORGANOS INTRATORACICOS, ESPECIFICADOS"},
{ value: "S279",	text: "TRAUMATISMO DE ORGANO INTRATORACICO, NO ESPECIFICADOS"},
{ value: "S280",	text: "APLASTAMIENTO DEL TORAX"},
{ value: "S281",	text: "AMPUTACION TRAUMATICA DE PARTE DEL TORAX"},
{ value: "S290",	text: "TRAUMATISMO DEL TENDON Y MUSCULOS A NIVEL DEL TORAX"},
{ value: "S297",	text: "TRAUMATISMOS MULTIPLES DEL TORAX"},
{ value: "S298",	text: "OTROS TRAUMATISMOS DEL TORAX, ESPECIFICADOS"},
{ value: "S299",	text: "TRAUMATISMO DEL TORAX, NO ESPECIFICADO"},
{ value: "S300",	text: "CONTUSION DE LA REGION LUMBOSACRA Y DE LA PELVIS"},
{ value: "S301",	text: "CONTUSION DE LA PARED ABDOMINAL"},
{ value: "S302",	text: "CONTUSION DE LOS ORGANOS GENITALES EXTERNOS"},
{ value: "S307",	text: "TRAUMATISMOS SUPERFICIALES MULTIPLES DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS"},
{ value: "S308",	text: "OTROS TRAUMATISMOS SUPERFICIALES DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS"},
{ value: "S309",	text: "TRAUMATISMO SUPERFICIAL DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS, PARTE NO ESPECIFICADA"},
{ value: "S310",	text: "HERIDA DE LA REGION LUMBOSACRA Y DE LA PELVIS"},
{ value: "S311",	text: "HERIDA DE LA PARED ABDOMINAL"},
{ value: "S312",	text: "HERIDA DEL PENE"},
{ value: "S313",	text: "HERIDA DEL ESCROTO Y DE LOS TESTICULOS"},
{ value: "S314",	text: "HERIDA DE LA VAGINA Y DE LA VULVA"},
{ value: "S315",	text: "HERIDA DE OTROS ORGANOS GENITALES EXTERNOS Y DE LOS NO ESPECIFICADOS"},
{ value: "S317",	text: "HERIDAS MULTIPLES DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS"},
{ value: "S318",	text: "HERIDAS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL ABDOMEN"},
{ value: "S320",	text: "FRACTURA DE VERTEBRA LUMBAR"},
{ value: "S321",	text: "FRACTURA DEL SACRO"},
{ value: "S322",	text: "FRACTURA DEL COCCIX"},
{ value: "S323",	text: "FRACTURA DEL HUESO ILIACO"},
{ value: "S324",	text: "FRACTURA DEL ACETABULO"},
{ value: "S325",	text: "FRACTURA DEL PUBIS"},
{ value: "S327",	text: "FRACTURAS MULTIPLES DE LA COLUMNA LUMBAR Y DE LA PELVIS"},
{ value: "S328",	text: "FRACTURA DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA COLUMNA LUMBAR Y DE LA PELVIS"},
{ value: "S330",	text: "RUPTURA TRAUMATICA DE DISCO INTERVERTEBRAL LUMBAR"},
{ value: "S331",	text: "LUXACION DE VERTEBRA LUMBAR"},
{ value: "S332",	text: "LUXACION DE ARTICULACION SACROCOCCIGEA Y SACROILIACA"},
{ value: "S333",	text: "LUXACION DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA COLUMNA LUMBAR Y DE LA PELVIS"},
{ value: "S334",	text: "RUPTURA TRAUMATICA DE LA SINFISIS DEL PUBIS"},
{ value: "S335",	text: "ESGUINCES Y TORCEDURAS DE LA COLUMNA LUMBAR"},
{ value: "S336",	text: "ESGUINCES Y TORCEDURAS DE LA ARTICULACION SACROILIACA"},
{ value: "S337",	text: "ESGUINCES Y TORCEDURAS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA COLUMNA LUMBAR Y DE LA PELVIS"},
{ value: "S340",	text: "CONCUSION Y EDEMA DE LA MEDULA ESPINAL LUMBAR"},
{ value: "S341",	text: "OTRO TRAUMATISMO DE LA MEDULA ESPINAL LUMBAR"},
{ value: "S342",	text: "TRAUMATISMO DE RAIZ NERVIOSA DE LA COLUMNA LUMBAR Y SACRA"},
{ value: "S343",	text: "TRAUMATISMO DE LA COLA DE CABALLO"},
{ value: "S344",	text: "TRAUMATISMO DEL PLEXO LUMBOSACRO"},
{ value: "S345",	text: "TRAUMATISMO DE NERVIO(S) SIMPATICO(S) LUMBAR(ES), SACRO(S) Y PELVICO(S)"},
{ value: "S346",	text: "TRAUMATISMO DE NERVIO(S) PERIFERICO(S) DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS"},
{ value: "S348",	text: "TRAUMATISMO DE OTROS NERVIOS A NIVEL DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS Y DE LOS NO ESPECIFICADOS"},
{ value: "S350",	text: "TRAUMATISMO DE LA AORTA ABDOMINAL"},
{ value: "S351",	text: "TRAUMATISMO DE LA VENA CAVA INFERIOR"},
{ value: "S352",	text: "TRAUMATISMO DE LAS ARTERIAS CELIACAS Y MESENTERICAS"},
{ value: "S353",	text: "TRAUMATISMO DE VENAS PORTA Y ESPLENICA"},
{ value: "S354",	text: "TRAUMATISMO DE VASOS SANGUINEOS RENALES"},
{ value: "S355",	text: "TRAUMATISMO DE VASOS SANGUINEOS ILIACOS"},
{ value: "S357",	text: "TRAUMATISMO DE MULTIPLES VASOS SANGUINEOS A NIVEL DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS"},
{ value: "S358",	text: "TRAUMATISMO DE OTROS VASOS SANGUINEOS A NIVEL DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS"},
{ value: "S359",	text: "TRAUMATISMO DE VASOS SANGUINEOS NO ESPECIFICADOS DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS"},
{ value: "S360",	text: "TRAUMATISMO DEL BAZO"},
{ value: "S361",	text: "TRAUMATISMO DEL HIGADO Y DE LA VESICULA BILIAR"},
{ value: "S362",	text: "TRAUMATISMO DEL PANCREAS"},
{ value: "S363",	text: "TRAUMATISMO DEL ESTOMAGO"},
{ value: "S364",	text: "TRAUMATISMO DEL INTESTINO DELGADO"},
{ value: "S365",	text: "TRAUMATISMO DEL COLON"},
{ value: "S366",	text: "TRAUMATISMO DEL RECTO"},
{ value: "S367",	text: "TRAUMATISMO DE MULTIPLES ORGANOS INTRAABDOMINALES"},
{ value: "S368",	text: "TRAUMATISMO DE OTROS ORGANOS INTRAABDOMINALES"},
{ value: "S369",	text: "TRAUMATISMO DE ORGANO INTRAABDOMINAL NO ESPECIFICADO"},
{ value: "S370",	text: "TRAUMATISMO DEL RIÑON"},
{ value: "S371",	text: "TRAUMATISMO DEL URETER"},
{ value: "S372",	text: "TRAUMATISMO DE LA VEJIGA"},
{ value: "S373",	text: "TRAUMATISMO DE LA URETRA"},
{ value: "S374",	text: "TRAUMATISMO DEL OVARIO"},
{ value: "S375",	text: "TRAUMATISMO DE LA TROMPA DE FALOPIO"},
{ value: "S376",	text: "TRAUMATISMO DEL UTERO"},
{ value: "S377",	text: "TRAUMATISMO DE MULTIPLES ORGANOS PELVICOS"},
{ value: "S378",	text: "TRAUMATISMO DE OTROS ORGANOS PELVICOS"},
{ value: "S379",	text: "TRAUMATISMO DE ORGANO PELVICO NO ESPECIFICADO"},
{ value: "S380",	text: "TRAUMATISMO POR APLASTAMIENTO DE ORGANOS GENITALES EXTERNOS"},
{ value: "S381",	text: "TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS"},
{ value: "S382",	text: "AMPUTACION TRAUMATICA DE ORGANOS GENITALES EXTERNOS"},
{ value: "S383",	text: "AMPUTACION TRAUMATICA DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL ABDOMEN, REGION LUMBOSACRA Y PELVIS"},
{ value: "S390",	text: "TRAUMATISMO DEL TENDON Y DE MUSCULOS DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS"},
{ value: "S396",	text: "TRAUMATISMO DE ORGANO(S) INTRAABDOMINAL(ES) CON ORGANO(S) PELVICO(S)"},
{ value: "S397",	text: "OTROS TRAUMATISMOS MULTIPLES DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS"},
{ value: "S398",	text: "OTROS TRAUMATISMOS ESPECIFICADOS DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS"},
{ value: "S399",	text: "TRAUMATISMO NO ESPECIFICADO DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS"},
{ value: "S400",	text: "CONTUSION DEL HOMBRO Y DEL BRAZO"},
{ value: "S407",	text: "TRAUMATISMOS SUPERFICIALES MULTIPLES DEL HOMBRO Y DEL BRAZO"},
{ value: "S408",	text: "OTROS TRAUMATISMOS SUPERFICIALES DEL HOMBRO Y DEL BRAZO"},
{ value: "S409",	text: "TRAUMATISMO SUPERFICIAL NO ESPECIFICADO DEL HOMBRO Y DEL BRAZO"},
{ value: "S410",	text: "HERIDA DEL HOMBRO"},
{ value: "S411",	text: "HERIDA DEL BRAZO"},
{ value: "S417",	text: "HERIDAS MULTIPLES DEL HOMBRO Y DEL BRAZO"},
{ value: "S418",	text: "HERIDAS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL HOMBRO Y DEL BRAZO"},
{ value: "S420",	text: "FRACTURA DE LA CLAVICULA"},
{ value: "S421",	text: "FRACTURA DEL OMOPLATO"},
{ value: "S422",	text: "FRACTURA DE LA EPIFISIS SUPERIOR DEL HUMERO"},
{ value: "S423",	text: "FRACTURA DE LA DIAFISIS DEL HUMERO"},
{ value: "S424",	text: "FRACTURA DE LA EPIFISIS INFERIOR DEL HUMERO"},
{ value: "S427",	text: "FRACTURAS MULTIPLES DE LA CLAVICULA, DEL OMOPLATO Y DEL HUMERO"},
{ value: "S428",	text: "FRACTURA DE OTRAS PARTES DEL HOMBRO Y DEL BRAZO"},
{ value: "S429",	text: "FRACTURA DEL HOMBRO Y DEL BRAZO, PARTE NO ESPECIFICADA"},
{ value: "S430",	text: "LUXACION DE LA ARTICULACION DEL HOMBRO"},
{ value: "S431",	text: "LUXACION DE LA ARTICULACION ACROMIOCLAVICULAR"},
{ value: "S432",	text: "LUXACION DE LA ARTICULACION ESTERNOCLAVICULAR"},
{ value: "S433",	text: "LUXACION DE OTRAS PARTES DE LA CINTURA ESCAPULAR Y DE LAS NO ESPECIFICADAS"},
{ value: "S434",	text: "ESGUINCES Y TORCEDURAS DE LA ARTICULACION DEL HOMBRO"},
{ value: "S435",	text: "ESGUINCES Y TORCEDURAS DE LA ARTICULACION ACROMIOCLAVICULAR"},
{ value: "S436",	text: "ESGUINCES Y TORCEDURAS DE LA ARTICULACION ESTERNOCLAVICULAR"},
{ value: "S437",	text: "ESGUINCES Y TORCEDURAS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CINTURA ESCAPULAR"},
{ value: "S440",	text: "TRAUMATISMO DEL NERVIO CUBITAL A NIVEL DEL BRAZO"},
{ value: "S441",	text: "TRAUMATISMO DEL NERVIO MEDIANO A NIVEL DEL BRAZO"},
{ value: "S442",	text: "TRAUMATISMO DEL NERVIO RADIAL A NIVEL DEL BRAZO"},
{ value: "S443",	text: "TRAUMATISMO DEL NERVIO AXILAR"},
{ value: "S444",	text: "TRAUMATISMO DEL NERVIO MUSCULOCUTANEO"},
{ value: "S445",	text: "TRAUMATISMO DEL NERVIO SENSITIVO CUTANEO A NIVEL DEL HOMBRO Y DEL BRAZO"},
{ value: "S447",	text: "TRAUMATISMO DEL MULTIPLES NERVIOS A NIVEL DEL HOMBRO Y DEL BRAZO"},
{ value: "S448",	text: "TRAUMATISMO DE OTROS NERVIOS A NIVEL DEL HOMBRO Y DEL BRAZO"},
{ value: "S449",	text: "TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DEL HOMBRO Y DEL BRAZO"},
{ value: "S450",	text: "TRAUMATISMO DE LA ARTERIA AXILAR"},
{ value: "S451",	text: "TRAUMATISMO DE LA ARTERIA BRAQUIAL"},
{ value: "S452",	text: "TRAUMATISMO DE LA VENA AXILAR O BRAQUIAL"},
{ value: "S453",	text: "TRAUMATISMO DE VENAS SUPERFICIAL A NIVEL DEL HOMBRO Y DEL BRAZO"},
{ value: "S457",	text: "TRAUMATISMO DE MULTIPLES VASOS SANGUINEOS A NIVEL DEL HOMBRO Y DEL BRAZO"},
{ value: "S458",	text: "TRAUMATISMO DE OTROS VASOS SANGUINEOS A NIVEL DEL HOMBRO Y DEL BRAZO"},
{ value: "S459",	text: "TRAUMATISMO DE VASO SANGUINEO NO ESPECIFICADO A NIVEL DEL HOMBRO Y DEL BRAZO"},
{ value: "S460",	text: "TRAUMATISMO DE TENDON DEL MANGUITO ROTATORIO DEL HOMBRO"},
{ value: "S461",	text: "TRAUMATISMO DE TENDON Y MUSCULO DE LA CABEZA LARGA DEL BICEPS"},
{ value: "S462",	text: "TRAUMATISMO DE TENDON Y MUSCULO DE OTRAS PARTES DEL BICEPS"},
{ value: "S463",	text: "TRAUMATISMO DE TENDON Y MUSCULO DEL TRICEPS"},
{ value: "S467",	text: "TRAUMATISMO DE MULTIPLES TENDONES Y MUSCULOS A NIVEL DEL HOMBRO Y DEL BRAZO"},
{ value: "S468",	text: "TRAUMATISMO DE OTROS TENDONES Y MUSCULOS A NIVEL DEL HOMBRO Y DEL BRAZO"},
{ value: "S469",	text: "TRAUMATISMO DE TENDON Y MUSCULO NO ESPECIFICADO NIVEL DEL HOMBRO Y DEL BRAZO"},
{ value: "S47X",	text: "TRAUMATISMO POR APLASTAMIENTO DEL HOMBRO Y DEL BRAZO"},
{ value: "S480",	text: "AMPUTACION TRAUMATICA EN LA ARTICULACION DEL HOMBRO Y DEL BRAZO"},
{ value: "S481",	text: "AMPUTACION TRAUMATICA A NIVEL ENTRE EL HOMBRO Y EL CODO"},
{ value: "S489",	text: "AMPUTACION TRAUMATICA DEL HOMBRO Y DEL BRAZO, NIVEL NO ESPECIFICADO"},
{ value: "S497",	text: "TRAUMATISMOS MULTIPLES DEL HOMBRO Y DEL BRAZO"},
{ value: "S498",	text: "OTROS TRAUMATISMOS ESPECIFICADOS DEL HOMBRO Y DEL BRAZO"},
{ value: "S499",	text: "TRAUMATISMOS NO ESPECIFICADOS DEL HOMBRO Y DEL BRAZO"},
{ value: "S500",	text: "CONTUSION DEL CODO"},
{ value: "S501",	text: "CONTUSION DE OTRAS PARTES DEL ANTEBRAZO Y DE LAS NO ESPECIFICADAS"},
{ value: "S507",	text: "TRAUMATISMOS SUPERFICIALES MULTIPLES DEL ANTEBRAZO"},
{ value: "S508",	text: "OTROS TRAUMATISMOS SUPERFICIALES DEL ANTEBRAZO"},
{ value: "S509",	text: "TRAUMATISMO SUPERFICIAL DEL ANTEBRAZO, NO ESPECIFICADO"},
{ value: "S510",	text: "HERIDA DEL CODO"},
{ value: "S517",	text: "HERIDAS MULTIPLES DEL ANTEBRAZO"},
{ value: "S518",	text: "HERIDAS DE OTRAS PARTES DEL ANTEBRAZO"},
{ value: "S519",	text: "HERIDAS DEL ANTEBRAZO, PARTE NO ESPECIFICADA"},
{ value: "S520",	text: "FRACTURA DE LA EPIFISIS SUPERIOR DEL CUBITO"},
{ value: "S521",	text: "FRACTURA DE LA EPIFISIS SUPERIOR DEL RADIO"},
{ value: "S522",	text: "FRACTURA DE LA DIAFISIS DEL CUBITO"},
{ value: "S523",	text: "FRACTURA DE LA DIAFISIS DEL RADIO"},
{ value: "S524",	text: "FRACTURA DE LA DIAFISIS DEL CUBITO Y DEL RADIO"},
{ value: "S525",	text: "FRACTURA DE LA EPIFISIS INFERIOR DEL RADIO"},
{ value: "S526",	text: "FRACTURA DE LA EPIFISIS INFERIOR DEL CUBITO Y DEL RADIO"},
{ value: "S527",	text: "FRACTURAS MULTIPLES DEL ANTEBRAZO"},
{ value: "S528",	text: "FRACTURA DE OTRAS PARTES DEL ANTEBRAZO"},
{ value: "S529",	text: "FRACTURA DEL ANTEBRAZO, PARTE NO ESPECIFICADA"},
{ value: "S530",	text: "LUXACION DE LA CABEZA DEL RADIO"},
{ value: "S531",	text: "LUXACION DEL CODO, NO ESPECIFICADA"},
{ value: "S532",	text: "RUPTURA TRAUMATICA DEL LIGAMENTO LATERAL DEL RADIO"},
{ value: "S533",	text: "RUPTURA TRAUMATICA DEL LIGAMENTO LATERAL DEL CUBITO"},
{ value: "S534",	text: "ESGUINCES Y TORCEDURAS DEL CODO"},
{ value: "S540",	text: "TRAUMATISMO DEL NERVIO CUBITAL A NIVEL DEL ANTEBRAZO"},
{ value: "S541",	text: "TRAUMATISMO DEL NERVIO MEDIANO A NIVEL DEL ANTEBRAZO"},
{ value: "S542",	text: "TRAUMATISMO DEL NERVIO RADIAL A NIVEL DEL ANTEBRAZO"},
{ value: "S543",	text: "TRAUMATISMO DEL NERVIO SENSORIAL CUTANEO A NIVEL DEL ANTEBRAZO"},
{ value: "S547",	text: "TRAUMATISMO DEL MULTIPLES NERVIOS A NIVEL DEL ANTEBRAZO"},
{ value: "S548",	text: "TRAUMATISMO DE OTROS NERVIOS A NIVEL DEL ANTEBRAZO"},
{ value: "S549",	text: "TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DEL ANTEBRAZO"},
{ value: "S550",	text: "TRAUMATISMO DE LA ARTERIA CUBITAL A NIVEL DEL ANTEBRAZO"},
{ value: "S551",	text: "TRAUMATISMO DE LA ARTERIA RADIAL A NIVEL DEL ANTEBRAZO"},
{ value: "S552",	text: "TRAUMATISMO DE VENA A NIVEL DEL ANTEBRAZO"},
{ value: "S557",	text: "TRAUMATISMO DE MULTIPLES VASOS SANGUINEOS A NIVEL DEL ANTEBRAZO"},
{ value: "S558",	text: "TRAUMATISMO DE OTROS VASOS SANGUINEOS A NIVEL DEL ANTEBRAZO"},
{ value: "S559",	text: "TRAUMATISMO DE VASO SANGUINEO NO ESPECIFICADO A NIVEL DEL ANTEBRAZO"},
{ value: "S560",	text: "TRAUMATISMO DEL TENDON Y MUSCULO FLEXOR DEL PULGAR A NIVEL DEL ANTEBRAZO"},
{ value: "S561",	text: "TRAUMATISMO DEL TENDON Y MUSCULO FLEXOR DE OTRO(S) DEDO(S) A NIVEL DEL ANTEBRAZO"},
{ value: "S562",	text: "TRAUMATISMO DEL OTRO TENDON Y MUSCULO FLEXOR A NIVEL DEL ANTEBRAZO"},
{ value: "S563",	text: "TRAUMATISMO DE TENDONES Y MUSCULOS ABDUCTORES Y EXTENSORES DEL PULGAR A NIVEL DEL ANTEBRAZO"},
{ value: "S564",	text: "TRAUMATISMO DEL TENDON Y MUSCULO EXTENSOR DE OTRO(S) DEDO(S) A NIVEL DEL ANTEBRAZO"},
{ value: "S565",	text: "TRAUMATISMO DE OTRO TENDON Y MUSCULO EXTENSOR A NIVEL DEL ANTEBRAZO"},
{ value: "S567",	text: "TRAUMATISMO DE MULTIPLES TENDONES Y MUSCULOS A NIVEL DEL ANTEBRAZO"},
{ value: "S568",	text: "TRAUMATISMO DE OTROS TENDONES Y MUSCULOS Y DE LOS NO ESPECIFICADOS, A NIVEL DEL ANTEBRAZO"},
{ value: "S570",	text: "TRAUMATISMO POR APLASTAMIENTO DEL CODO"},
{ value: "S578",	text: "TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES DEL ANTEBRAZO"},
{ value: "S579",	text: "TRAUMATISMO POR APLASTAMIENTO DEL ANTEBRAZO, PARTE NO ESPECIFICADA"},
{ value: "S580",	text: "AMPUTACION TRAUMATICA A NIVEL DEL CODO"},
{ value: "S581",	text: "AMPUTACION TRAUMATICA A NIVEL ENTRE EL CODO Y LA MUÑECA"},
{ value: "S589",	text: "AMPUTACION TRAUMATICA DEL ANTEBRAZO, NIVEL NO ESPECIFICADO"},
{ value: "S597",	text: "TRAUMATISMOS MULTIPLES DEL ANTEBRAZO"},
{ value: "S598",	text: "OTROS TRAUMATISMOS ESPECIFICADOS DEL ANTEBRAZO"},
{ value: "S599",	text: "TRAUMATISMOS NO ESPECIFICADOS DEL ANTEBRAZO"},
{ value: "S600",	text: "CONTUSION DE DEDO(S) DE LA MANO, SIN DAÑO DE LA(S) UÑA(S)"},
{ value: "S601",	text: "CONTUSION DE DEDO(S) DE LA MANO, CON DAÑO DE LA(S) UÑA(S)"},
{ value: "S602",	text: "CONTUSION DE OTRAS PARTES DE LA MUÑECA Y DE LA MANO"},
{ value: "S607",	text: "TRAUMATISMOS SUPERFICIALES MULTIPLES DE LA MUÑECA Y DE LA MANO"},
{ value: "S608",	text: "OTROS TRAUMATISMOS SUPERFICIALES DE LA MUÑECA Y DE LA MANO"},
{ value: "S609",	text: "TRAUMATISMO SUPERFICIAL DE LA MUÑECA Y DE LA MANO, NO ESPECIFICADO"},
{ value: "S610",	text: "HERIDA DE DEDO(S) DE LA MANO, SIN DAÑO DE LA(S) UÑA(S)"},
{ value: "S611",	text: "HERIDA DE DEDO(S) DE LA MANO, CON DAÑO DE LA(S) UÑA(S)"},
{ value: "S617",	text: "HERIDAS MULTIPLES DE LA MUÑECA Y DE LA MANO"},
{ value: "S618",	text: "HERIDAS DE OTRAS PARTES DE LA MUÑECA Y DE LA MANO"},
{ value: "S619",	text: "HERIDA DE LA MUÑECA Y DE LA MANO, PARTE NO ESPECIFICADA"},
{ value: "S620",	text: "FRACTURA DEL HUESO ESCAFOIDES [NAVICULAR] DE LA MANO"},
{ value: "S621",	text: "FRACTURA DE OTRO(S) HUESO(S) DEL CARPO"},
{ value: "S622",	text: "FRACTURA DEL PRIMER METACARPIANO"},
{ value: "S623",	text: "FRACTURA DE OTROS HUESOS METACARPIANOS"},
{ value: "S624",	text: "FRACTURAS MULTIPLES DE HUESOS METACARPIANOS"},
{ value: "S625",	text: "FRACTURA DEL PULGAR"},
{ value: "S626",	text: "FRACTURA DE OTRO DEDO DE LA MANO"},
{ value: "S627",	text: "FRACTURAS MULTIPLES DE LOS DEDOS DE LA MANO"},
{ value: "S628",	text: "FRACTURA DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA MUÑECA Y DE LA MANO"},
{ value: "S630",	text: "LUXACION DE LA MUÑECA"},
{ value: "S631",	text: "LUXACION DE DEDOS DE LA MANO"},
{ value: "S632",	text: "LUXACIONES MULTIPLES DE DEDOS DE LA MANO"},
{ value: "S633",	text: "RUPTURA TRAUMATICA DE LIGAMENTOS DE LA MUÑECA Y DEL CARPO"},
{ value: "S634",	text: "RUPTURA TRAUMATICA DE LIGAMENTOS DEL DEDO DE LA MANO EN LA(S) ARTICULACION(ES) METACARPOFALANGICA E INTERFALANGICA"},
{ value: "S635",	text: "ESGUINCES Y TORCEDURAS DE LA MUÑECA"},
{ value: "S636",	text: "ESGUINCES Y TORCEDURAS DE DEDO(S) DE LA MANO"},
{ value: "S637",	text: "ESGUINCES Y TORCEDURAS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA MUÑECA Y DE LA MANO"},
{ value: "S640",	text: "TRAUMATISMO DEL NERVIO CUBITAL A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S641",	text: "TRAUMATISMO DEL NERVIO MEDIANO A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S642",	text: "TRAUMATISMO DEL NERVIO RADIAL A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S643",	text: "TRAUMATISMO DEL NERVIO DIGITAL DEL PULGAR"},
{ value: "S644",	text: "TRAUMATISMO DEL NERVIO DIGITAL DE OTRO DEDO"},
{ value: "S647",	text: "TRAUMATISMO DE MULTIPLES NERVIOS A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S648",	text: "TRAUMATISMO DE OTROS NERVIOS A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S649",	text: "TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S650",	text: "TRAUMATISMO DE LA ARTERIA CUBITAL A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S651",	text: "TRAUMATISMO DE LA ARTERIA RADIAL A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S652",	text: "TRAUMATISMO DEL ARCO PALMAR SUPERFICIAL"},
{ value: "S653",	text: "TRAUMATISMO DEL ARCO PALMAR PROFUNDO"},
{ value: "S654",	text: "TRAUMATISMO DE VASO(S) SANGUINEO(S) DEL PULGAR"},
{ value: "S655",	text: "TRAUMATISMO DE VASO(S) SANGUINEO(S) DE OTRO DEDO"},
{ value: "S657",	text: "TRAUMATISMO DE MULTIPLES VASOS SANGUINEOS A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S658",	text: "TRAUMATISMO DE OTROS VASOS SANGUINEOS A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S659",	text: "TRAUMATISMO DE VASO SANGUINEO NO ESPECIFICADO A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S660",	text: "TRAUMATISMO DEL TENDON Y MUSCULO FLEXOR LARGO DEL PULGAR A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S661",	text: "TRAUMATISMO DEL TENDON Y MUSCULO FLEXOR DE OTRO DEDO A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S662",	text: "TRAUMATISMO DEL TENDON Y MUSCULO EXTENSOR DEL PULGAR A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S663",	text: "TRAUMATISMO DEL TENDON Y MUSCULO EXTENSOR DE OTRO(S) DEDO(S) A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S664",	text: "TRAUMATISMO DEL MUSCULO Y TENDON INTRINSECO DEL PULGAR A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S665",	text: "TRAUMATISMO DEL MUSCULO Y TENDON INTRINSECO DE OTRO(S) DEDO(S) A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S666",	text: "TRAUMATISMO DE MULTIPLES TENDONES Y MUSCULOS FLEXORES A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S667",	text: "TRAUMATISMO DE MULTIPLES TENDONES Y MUSCULOS EXTENSORES A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S668",	text: "TRAUMATISMO DE OTROS TENDONES Y MUSCULOS A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S669",	text: "TRAUMATISMO DE TENDON Y MUSCULO NO ESPECIFICADO, A NIVEL DE LA MUÑECA Y DE LA MANO"},
{ value: "S670",	text: "TRAUMATISMO POR APLASTAMIENTO DEL PULGAR Y OTRO(S) DEDO(S)"},
{ value: "S678",	text: "TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA MUÑECA Y DE LA MANO"},
{ value: "S680",	text: "AMPUTACION TRAUMATICA DEL PULGAR (COMPLETA) (PARCIAL)"},
{ value: "S681",	text: "AMPUTACION TRAUMATICA DE OTRO DEDO UNICO (COMPLETA) (PARCIAL)"},
{ value: "S682",	text: "AMPUTACION TRAUMATICA DE DOS O MAS DEDOS SOLAMENTE (COMPLETA) (PARCIAL)"},
{ value: "S683",	text: "AMPUTACION TRAUMATICA COMBINADA (DE PARTE) DE DEDO(S) CON OTRAS PARTES DE LA MUÑECA Y DE LA MANO"},
{ value: "S684",	text: "AMPUTACION TRAUMATICA DE LA MANO A NIVEL DE LA MUÑECA"},
{ value: "S688",	text: "AMPUTACION TRAUMATICA DE OTRAS PARTES DE LA MUÑECA Y DE LA MANO"},
{ value: "S689",	text: "AMPUTACION TRAUMATICA DE LA MUÑECA Y DE LA MANO, NIVEL NO ESPECIFICADO"},
{ value: "S697",	text: "TRAUMATISMOS MULTIPLES DE LA MUÑECA Y DE LA MANO"},
{ value: "S698",	text: "OTROS TRAUMATISMOS ESPECIFICADOS DE LA MUÑECA Y DE LA MANO"},
{ value: "S699",	text: "TRAUMATISMO NO ESPECIFICADO DE LA MUÑECA Y DE LA MANO"},
{ value: "S700",	text: "CONTUSION DE LA CADERA"},
{ value: "S701",	text: "CONTUSION DEL MUSLO"},
{ value: "S707",	text: "TRAUMATISMOS SUPERFICIALES MULTIPLES DE LA CADERA Y DEL MUSLO"},
{ value: "S708",	text: "OTROS TRAUMATISMOS SUPERFICIALES DE LA CADERA Y DEL MUSLO"},
{ value: "S709",	text: "TRAUMATISMO SUPERFICIAL DE LA CADERA Y DEL MUSLO, NO ESPECIFICADO"},
{ value: "S710",	text: "HERIDA DE LA CADERA"},
{ value: "S711",	text: "HERIDA DEL MUSLO"},
{ value: "S717",	text: "HERIDAS MULTIPLES DE LA CADERA Y DEL MUSLO"},
{ value: "S718",	text: "HERIDAS DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA CINTURA PELVICA"},
{ value: "S720",	text: "FRACTURA DEL CUELLO DEL FEMUR"},
{ value: "S721",	text: "FRACTURA PERTROCANTERIANA"},
{ value: "S722",	text: "FRACTURA SUBTROCANTERIANA"},
{ value: "S723",	text: "FRACTURA DE LA DIAFISIS DEL FEMUR"},
{ value: "S724",	text: "FRACTURA DE LA EPIFISIS INFERIOR DEL FEMUR"},
{ value: "S727",	text: "FRACTURAS MULTIPLES DEL FEMUR"},
{ value: "S728",	text: "FRACTURA DE OTRAS PARTES DEL FEMUR"},
{ value: "S729",	text: "FRACTURA DEL FEMUR, PARTE NO ESPECIFICADA"},
{ value: "S730",	text: "LUXACION DE CADERA"},
{ value: "S731",	text: "ESGUINCES Y TORCEDURAS DE LA CADERA"},
{ value: "S740",	text: "TRAUMATISMO DEL NERVIO CIATICO A NIVEL DE LA CADERA Y DEL MUSLO"},
{ value: "S741",	text: "TRAUMATISMO DEL NERVIO FEMOROCUTANEO A NIVEL DE LA CADERA Y DEL MUSLO"},
{ value: "S742",	text: "TRAUMATISMO DEL NERVIO SENSORIAL CUTANEO A NIVEL DE LA CADERA Y DEL MUSLO"},
{ value: "S747",	text: "TRAUMATISMO DE NERVIOS MULTIPLES A NIVEL DE LA CADERA Y DEL MUSLO"},
{ value: "S748",	text: "TRAUMATISMO DE OTROS NERVIOS A NIVEL DE LA CADERA Y DEL MUSLO"},
{ value: "S749",	text: "TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DE LA CADERA Y DEL MUSLO"},
{ value: "S750",	text: "TRAUMATISMO DE LA ARTERIA FEMORAL"},
{ value: "S751",	text: "TRAUMATISMO DE LA VENA FEMORAL A NIVEL DE LA CADERA Y DEL MUSLO"},
{ value: "S752",	text: "TRAUMATISMO DE LA GRAN VENA SAFENA A NIVEL DE LA CADERA Y DEL MUSLO"},
{ value: "S757",	text: "TRAUMATISMO DE MULTIPLES VASOS SANGUINEOS A NIVEL DE LA CADERA Y DEL MUSLO"},
{ value: "S758",	text: "TRAUMATISMO DE OTROS VASOS SANGUINEOS A NIVEL DE LA CADERA Y DEL MUSLO"},
{ value: "S759",	text: "TRAUMATISMO DE VASO SANGUINEO NO ESPECIFICADO A NIVEL DE LA CADERA Y DEL MUSLO"},
{ value: "S760",	text: "TRAUMATISMO DEL TENDON Y MUSCULO DE LA CADERA"},
{ value: "S761",	text: "TRAUMATISMO DEL TENDON Y MUSCULO CUADRICEPS"},
{ value: "S762",	text: "TRAUMATISMO DEL TENDON Y MUSCULO ADUCTOR MAYOR DEL MUSLO"},
{ value: "S763",	text: "TRAUMATISMO DEL TENDON Y MUSCULO DEL GRUPO MUSCULAR POSTERIOR A NIVEL DEL MUSLO"},
{ value: "S764",	text: "TRAUMATISMO DE OTROS TENDONES Y MUSCULOS Y LOS NO ESPECIFICADOS A NIVEL DEL MUSLO"},
{ value: "S767",	text: "TRAUMATISMO DE MULTIPLES TENDONES Y MUSCULOS Y LOS NO ESPECIFICADOS A NIVEL DE LA CADERA Y DEL MUSLO"},
{ value: "S770",	text: "TRAUMATISMO POR APLASTAMIENTO DE LA CADERA"},
{ value: "S771",	text: "TRAUMATISMO POR APLASTAMIENTO DEL MUSLO"},
{ value: "S772",	text: "TRAUMATISMO POR APLASTAMIENTO DE LA CADERA CON EL MUSLO"},
{ value: "S780",	text: "AMPUTACION TRAUMATICA DE LA ARTICULACION DE LA CADERA"},
{ value: "S781",	text: "AMPUTACION TRAUMATICA EN ALGUN NIVEL ENTRE LA CADERA Y LA RODILLA"},
{ value: "S789",	text: "AMPUTACION TRAUMATICA DE CADERA Y MUSLO, NIVEL NO ESPECIFICADO"},
{ value: "S797",	text: "TRAUMATISMOS MULTIPLES DE LA CADERA Y DEL MUSLO"},
{ value: "S798",	text: "OTROS TRAUMATISMOS ESPECIFICADOS DE LA CADERA Y DEL MUSLO"},
{ value: "S799",	text: "TRAUMATISMO NO ESPECIFICADO DE LA CADERA Y DEL MUSLO"},
{ value: "S800",	text: "CONTUSION DE LA RODILLA"},
{ value: "S801",	text: "CONTUSION DE OTRAS PARTES Y LAS NO ESPECIFICADAS DE LA PIERNA"},
{ value: "S807",	text: "TRAUMATISMOS SUPERFICIALES MULTIPLES DE LA PIERNA"},
{ value: "S808",	text: "OTROS TRAUMATISMOS SUPERFICIALES DE LA PIERNA"},
{ value: "S809",	text: "TRAUMATISMO SUPERFICIAL DE LA PIERNA, NO ESPECIFICADO"},
{ value: "S810",	text: "HERIDA DE LA RODILLA"},
{ value: "S817",	text: "HERIDAS MULTIPLES DE LA PIERNA"},
{ value: "S818",	text: "HERIDA DE OTRAS PARTES DE LA PIERNA"},
{ value: "S819",	text: "HERIDA DE LA PIERNA, PARTE NO ESPECIFICADA"},
{ value: "S820",	text: "FRACTURA DE LA ROTULA"},
{ value: "S821",	text: "FRACTURA DE LA EPIFISIS SUPERIOR DE LA TIBIA"},
{ value: "S822",	text: "FRACTURA DE LA DIAFISIS DE LA TIBIA"},
{ value: "S823",	text: "FRACTURA DE LA EPIFISIS INFERIOR DE LA TIBIA"},
{ value: "S824",	text: "FRACTURA DEL PERONE SOLAMENTE"},
{ value: "S825",	text: "FRACTURA DEL MALEOLO INTERNO"},
{ value: "S826",	text: "FRACTURA DEL MALEOLO EXTERNO"},
{ value: "S827",	text: "FRACTURAS MULTIPLES DE LA PIERNA"},
{ value: "S828",	text: "FRACTURA DE OTRAS PARTES DE LA PIERNA"},
{ value: "S829",	text: "FRACTURA DE LA PIERNA, PARTE NO ESPECIFICADA"},
{ value: "S830",	text: "LUXACION DE LA ROTULA"},
{ value: "S831",	text: "LUXACION DE LA RODILLA"},
{ value: "S832",	text: "DESGARRO DE MENISCOS, PRESENTE"},
{ value: "S833",	text: "DESGARRO DEL CARTILAGO ARTICULAR DE LA RODILLA, PRESENTE"},
{ value: "S834",	text: "ESGUINCES Y TORCEDURAS QUE COMPROMETEN LOS LIGAMENTOS LATERALES (EXTERNO) (INTERNO) DE LA RODILLA"},
{ value: "S835",	text: "ESGUINCES Y TORCEDURAS QUE COMPROMETEN EL LIGAMENTO CRUZADO (ANTERIOR) (POSTERIOR) DE LA RODILLA"},
{ value: "S836",	text: "ESGUINCES Y TORCEDURAS DE OTRAS PARTES Y LAS NO ESPECIFICADAS DE LA RODILLA"},
{ value: "S837",	text: "TRAUMATISMO DE ESTRUCTURAS MULTIPLES DE LA RODILLA"},
{ value: "S840",	text: "TRAUMATISMO DEL NERVIO TIBIAL A NIVEL DE LA PIERNA"},
{ value: "S841",	text: "TRAUMATISMO DEL NERVIO PERONEO A NIVEL DE LA PIERNA"},
{ value: "S842",	text: "TRAUMATISMO DEL NERVIO SENSORIAL CUTANEO A NIVEL DE LA PIERNA"},
{ value: "S847",	text: "TRAUMATISMO DE NERVIOS MULTIPLES A NIVEL DE LA PIERNA"},
{ value: "S848",	text: "TRAUMATISMO DE OTROS NERVIOS A NIVEL DE LA PIERNA"},
{ value: "S849",	text: "TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DE LA PIERNA"},
{ value: "S850",	text: "TRAUMATISMO DE LA ARTERIA POPLITEA"},
{ value: "S851",	text: "TRAUMATISMO DE LA ARTERIA TIBIAL (ANTERIOR) (POSTERIOR)"},
{ value: "S852",	text: "TRAUMATISMO DE LA ARTERIA PERONEA"},
{ value: "S853",	text: "TRAUMATISMO DE LA GRAN VENA SAFENA A NIVEL DE LA PIERNA"},
{ value: "S854",	text: "TRAUMATISMO DE LA VENA SAFENA EXTERNA NIVEL DE LA PIERNA"},
{ value: "S855",	text: "TRAUMATISMO DE LA VENA POPLITEA"},
{ value: "S857",	text: "TRAUMATISMO DE VASOS SANGUINEOS MULTIPLES A NIVEL DE LA PIERNA"},
{ value: "S858",	text: "TRAUMATISMO DE OTROS VASOS SANGUINEOS A NIVEL DE LA PIERNA"},
{ value: "S859",	text: "TRAUMATISMO DE VASO SANGUINEO NO ESPECIFICADO A NIVEL DE LA PIERNA"},
{ value: "S860",	text: "TRAUMATISMO DEL TENDON DE AQUILES"},
{ value: "S861",	text: "TRAUMATISMO DE OTRO TENDON(ES) Y MUSCULO(S) DEL GRUPO MUSCULAR POSTERIOR A NIVEL DE LA PIERNA"},
{ value: "S862",	text: "TRAUMATISMO DEL TENDON(ES) Y MUSCULO(S) DEL GRUPO MUSCULAR ANTERIOR A NIVEL DE LA PIERNA"},
{ value: "S863",	text: "TRAUMATISMO DEL TENDON(ES) Y MUSCULO(S) DEL GRUPO MUSCULAR PERONEO A NIVEL DE LA PIERNA"},
{ value: "S867",	text: "TRAUMATISMO DE MULTIPLES TENDONES Y MUSCULOS A NIVEL DE LA PIERNA"},
{ value: "S868",	text: "TRAUMATISMO DE OTROS TENDONES Y MUSCULOS A NIVEL DE LA PIERNA"},
{ value: "S869",	text: "TRAUMATISMO DE TENDON Y MUSCULO NO ESPECIFICADOS A NIVEL DE LA PIERNA"},
{ value: "S870",	text: "TRAUMATISMO POR APLASTAMIENTO DE LA RODILLA"},
{ value: "S878",	text: "TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DE LA PIERNA"},
{ value: "S880",	text: "AMPUTACION TRAUMATICA A NIVEL DE LA RODILLA"},
{ value: "S881",	text: "AMPUTACION TRAUMATICA EN ALGUN NIVEL ENTRE LA RODILLA Y EL TOBILLO"},
{ value: "S889",	text: "AMPUTACION TRAUMATICA DE LA PIERNA, NIVEL NO ESPECIFICADO"},
{ value: "S897",	text: "TRAUMATISMOS MULTIPLES DE LA PIERNA"},
{ value: "S898",	text: "OTROS TRAUMATISMOS DE LA PIERNA, ESPECIFICADOS"},
{ value: "S899",	text: "TRAUMATISMO DE LA PIERNA, NO ESPECIFICADO"},
{ value: "S900",	text: "CONTUSION DEL TOBILLO"},
{ value: "S901",	text: "CONTUSION DE DEDO(S) DEL PIE, SIN DAÑO DE LA(S) UÑA(S)"},
{ value: "S902",	text: "CONTUSION DE DEDO(S) DEL PIE, CON DAÑO DE LA(S) UÑA(S)"},
{ value: "S903",	text: "CONTUSION DE OTRAS PARTES Y DE LAS NO ESPECIFICADAS DEL PIE"},
{ value: "S907",	text: "TRAUMATISMOS SUPERFICIALES MULTIPLES DEL PIE Y DEL TOBILLO"},
{ value: "S908",	text: "OTROS TRAUMATISMOS SUPERFICIALES DEL PIE Y DEL TOBILLO"},
{ value: "S909",	text: "TRAUMATISMO SUPERFICIAL DEL PIE Y DEL TOBILLO, NO ESPECIFICADO"},
{ value: "S910",	text: "HERIDA DEL TOBILLO"},
{ value: "S911",	text: "HERIDA DE DEDO(S) DEL PIE SIN DAÑO DE LA(S) UÑA(S)"},
{ value: "S912",	text: "HERIDA DE DEDO(S) DEL PIE CON DAÑO DE LA(S) UÑA(S)"},
{ value: "S913",	text: "HERIDAS DE OTRAS PARTES DEL PIE"},
{ value: "S917",	text: "HERIDAS MULTIPLES DEL TOBILLO Y DEL PIE"},
{ value: "S920",	text: "FRACTURA DEL CALCANEO"},
{ value: "S921",	text: "FRACTURA DEL ASTRAGALO"},
{ value: "S922",	text: "FRACTURA DE OTRO(S) HUESO(S) DEL TARSO"},
{ value: "S923",	text: "FRACTURA DE HUESO DEL METATARSO"},
{ value: "S924",	text: "FRACTURA DE LOS HUESOS DEL DEDO GORDO DEL PIE"},
{ value: "S925",	text: "FRACTURA DE LOS HUESOS DE OTRO(S) DEDO(S) DEL PIE"},
{ value: "S927",	text: "FRACTURAS MULTIPLES DEL PIE"},
{ value: "S929",	text: "FRACTURA DEL PIE, NO ESPECIFICADA"},
{ value: "S930",	text: "LUXACION DE LA ARTICULACION DEL TOBILLO"},
{ value: "S931",	text: "LUXACION DE DEDO(S) DEL PIE"},
{ value: "S932",	text: "RUPTURA DE LIGAMENTOS A NIVEL DEL TOBILLO Y DEL PIE"},
{ value: "S933",	text: "LUXACION DE OTROS SITIOS Y LOS NO ESPECIFICADOS DEL PIE"},
{ value: "S934",	text: "ESGUINCES Y TORCEDURAS DEL TOBILLO"},
{ value: "S935",	text: "ESGUINCES Y TORCEDURAS DE DEDO(S) DEL PIE"},
{ value: "S936",	text: "ESGUINCES Y TORCEDURAS DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS DEL PIE"},
{ value: "S940",	text: "TRAUMATISMO DEL NERVIO PLANTAR EXTERNO"},
{ value: "S941",	text: "TRAUMATISMO DEL NERVIO PLANTAR INTERNO"},
{ value: "S942",	text: "TRAUMATISMO DEL NERVIO PERONEAL PROFUNDO A NIVEL DEL PIE Y DEL TOBILLO"},
{ value: "S943",	text: "TRAUMATISMO DEL NERVIO SENSORIAL CUTANEO A NIVEL DEL PIE Y DEL TOBILLO"},
{ value: "S947",	text: "TRAUMATISMO DE MULTIPLES NERVIOS A NIVEL DEL PIE Y DEL TOBILLO"},
{ value: "S948",	text: "TRAUMATISMO DE OTROS NERVIOS A NIVEL DEL PIE Y DEL TOBILLO"},
{ value: "S949",	text: "TRAUMATISMO DE NERVIO NO ESPECIFICADO A NIVEL DEL PIE Y DEL TOBILLO"},
{ value: "S950",	text: "TRAUMATISMO DE LA ARTERIA DORSAL DEL PIE"},
{ value: "S951",	text: "TRAUMATISMO DE LA ARTERIA PLANTAR DEL PIE"},
{ value: "S952",	text: "TRAUMATISMO DE LA VENA DORSAL DEL PIE"},
{ value: "S957",	text: "TRAUMATISMO DE MULTIPLES VASOS SANGUINEOS A NIVEL DEL PIE Y DEL TOBILLO"},
{ value: "S958",	text: "TRAUMATISMO DE OTROS VASOS SANGUINEOS A NIVEL DEL PIE Y DEL TOBILLO"},
{ value: "S959",	text: "TRAUMATISMO DE VASO SANGUINEO NO ESPECIFICADO A NIVEL DEL PIE Y DEL TOBILLO"},
{ value: "S960",	text: "TRAUMATISMO DEL TENDON Y MUSCULO DEL FLEXOR LARGO DEL DEDO A NIVEL DEL PIE Y DEL TOBILLO"},
{ value: "S961",	text: "TRAUMATISMO DEL TENDON Y MUSCULO DEL EXTENSOR LARGO DEL (DE LOS) DEDO(S) A NIVEL DEL PIE Y DEL TOBILLO"},
{ value: "S962",	text: "TRAUMATISMO DE TENDONES Y MUSCULOS INTRINSECOS A NIVEL DEL PIE Y DEL TOBILLO"},
{ value: "S967",	text: "TRAUMATISMO DE MULTIPLES TENDONES Y MUSCULOS A NIVEL DEL PIE Y DEL TOBILLO"},
{ value: "S968",	text: "TRAUMATISMO DE OTROS TENDONES Y MUSCULOS A NIVEL DEL PIE Y DEL TOBILLO"},
{ value: "S969",	text: "TRAUMATISMO DE TENDONES Y MUSCULOS NO ESPECIFICADOS A NIVEL DEL PIE Y DEL TOBILLO"},
{ value: "S970",	text: "TRAUMATISMO POR APLASTAMIENTO DEL TOBILLO"},
{ value: "S971",	text: "TRAUMATISMO POR APLASTAMIENTO DE DEDO(S) DEL PIE"},
{ value: "S978",	text: "TRAUMATISMO POR APLASTAMIENTO DE OTRAS PARTES DEL PIE Y DEL TOBILLO"},
{ value: "S980",	text: "AMPUTACION TRAUMATICA DEL PIE A NIVEL DEL TOBILLO"},
{ value: "S981",	text: "AMPUTACION TRAUMATICA DE UN DEDO DEL PIE"},
{ value: "S982",	text: "AMPUTACION TRAUMATICA DE DOS O MAS DEDOS DEL PIE"},
{ value: "S983",	text: "AMPUTACION TRAUMATICA DE OTRAS PARTES DEL PIE"},
{ value: "S984",	text: "AMPUTACION DEL PIE, NIVEL NO ESPECIFICADO"},
{ value: "S997",	text: "TRAUMATISMOS MULTIPLES DEL PIE Y DEL TOBILLO"},
{ value: "S998",	text: "OTROS TRAUMATISMOS DEL PIE Y DEL TOBILLO, ESPECIFICADOS"},
{ value: "S999",	text: "TRAUMATISMO DEL PIE Y DEL TOBILLO, NO ESPECIFICADO"},
{ value: "T000",	text: "TRAUMATISMOS SUPERFICIALES QUE AFECTAN LA CABEZA CON EL CUELLO"},
{ value: "T001",	text: "TRAUMATISMOS SUPERFICIALES QUE AFECTAN EL TORAX CON EL ABDOMEN, LA REGION LUMBOSACRA Y LA PELVIS"},
{ value: "T002",	text: "TRAUMATISMOS SUPERFICIALES QUE AFECTAN MULTIPLES REGIONES DEL(OS) MIEMBRO(S) SUPERIOR(ES)"},
{ value: "T003",	text: "TRAUMATISMOS SUPERFICIALES QUE AFECTAN MULTIPLES REGIONES DEL(OS) MIEMBRO(S) INFERIOR(ES)"},
{ value: "T006",	text: "TRAUMATISMOS SUPERFICIALES QUE AFECTAN MULTIPLES REGIONES DEL(OS) MIEMBRO(S) SUPERIOR(ES) CON MIEMBRO(S) INFERIOR(ES)"},
{ value: "T008",	text: "TRAUMATISMOS SUPERFICIALES QUE AFECTAN OTRAS COMBINACIONES DE REGIONES DEL CUERPO"},
{ value: "T009",	text: "TRAUMATISMOS SUPERFICIALES MULTIPLES, NO ESPECIFICADOS"},
{ value: "T010",	text: "HERIDAS QUE AFECTAN LA CABEZA CON EL CUELLO"},
{ value: "T011",	text: "HERIDAS QUE AFECTAN EL TORAX CON EL ABDOMEN, LA REGION LUMBOSACRA Y LA PELVIS"},
{ value: "T012",	text: "HERIDAS QUE AFECTAN MULTIPLES REGIONES DEL(DE LOS) MIEMBRO(S) SUPERIOR(ES)"},
{ value: "T013",	text: "HERIDAS QUE AFECTAN MULTIPLES REGIONES DEL(DE LOS) MIEMBRO(S) INFERIOR(ES)"},
{ value: "T016",	text: "HERIDAS QUE AFECTAN MULTIPLES REGIONES DEL(DE LOS) MIEMBRO(S) SUPERIOR(ES) CON MIEMBRO(S) INFERIOR(ES)"},
{ value: "T018",	text: "HERIDAS QUE AFECTAN OTRAS COMBINACIONES DE REGIONES DEL CUERPO"},
{ value: "T019",	text: "HERIDAS MULTIPLES, NO ESPECIFICADOS"},
{ value: "T020",	text: "FRACTURAS QUE AFECTAN LA CABEZA CON EL CUELLO"},
{ value: "T021",	text: "FRACTURAS QUE AFECTAN EL TORAX CON EL ABDOMEN, LA REGION LUMBOSACRA Y LA PELVIS"},
{ value: "T022",	text: "FRACTURAS QUE AFECTAN MULTIPLES REGIONES DE UN MIEMBRO SUPERIOR"},
{ value: "T023",	text: "FRACTURAS QUE AFECTAN MULTIPLES REGIONES DE UN MIEMBRO INFERIOR"},
{ value: "T024",	text: "FRACTURAS QUE AFECTAN MULTIPLES REGIONES DE AMBOS MIEMBROS SUPERIORES"},
{ value: "T025",	text: "FRACTURAS QUE AFECTAN MULTIPLES REGIONES DE AMBOS MIEMBROS INFERIORES"},
{ value: "T026",	text: "FRACTURAS QUE AFECTAN MULTIPLES REGIONES DE MIEMBRO(S) SUPERIOR(ES) CON MIEMBRO(S) INFERIOR(ES)"},
{ value: "T027",	text: "FRACTURAS QUE AFECTAN EL TORAX CON LA REGION LUMBOSACRA Y LA PELVIS CON MIEMBRO(S)"},
{ value: "T028",	text: "FRACTURAS QUE AFECTAN OTRAS COMBINACIONES DE REGIONES DEL CUERPO"},
{ value: "T029",	text: "FRACTURAS MULTIPLES, NO ESPECIFICADOS"},
{ value: "T030",	text: "LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN LA CABEZA CON EL CUELLO"},
{ value: "T031",	text: "LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN EL TORAX CON LA REGION LUMBOSACRA Y LA PELVIS"},
{ value: "T032",	text: "LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN MULTIPLES REGIONES DEL(DE LOS) MIEMBRO(S) SUPERIOR(ES)"},
{ value: "T033",	text: "LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN MULTIPLES REGIONES DEL(DE LOS) MIEMBRO(S) INFERIOR(ES)"},
{ value: "T034",	text: "LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN MULTIPLES REGIONES DEL(DE LOS) MIEMBRO(S) SUPERIOR(ES) CON MIEMBRO(S) INFERIOR(ES)"},
{ value: "T038",	text: "LUXACIONES, TORCEDURAS Y ESGUINCES QUE AFECTAN OTRAS COMBINACIONES DE REGIONES DEL CUERPO"},
{ value: "T039",	text: "LUXACIONES, TORCEDURAS Y ESGUINCES MULTIPLES, NO ESPECIFICADOS"},
{ value: "T040",	text: "TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN LA CABEZA CON EL CUELLO"},
{ value: "T041",	text: "TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN EL TORAX CON EL ABDOMEN, LA REGION LUMBOSACRA Y LA PELVIS"},
{ value: "T042",	text: "TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN MULTIPLES REGIONES DEL(DE LOS) MIEMBRO(S) SUPERIOR(ES)"},
{ value: "T043",	text: "TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN MULTIPLES REGIONES DEL(DE LOS) MIEMBRO(S) INFERIOR(ES)"},
{ value: "T044",	text: "TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN MULTIPLES REGIONES DEL(DE LOS) MIEMBRO(S) SUPERIOR(ES) CON MIEMBRO(S) INFERIOR(ES)"},
{ value: "T047",	text: "TRAUMATISMOS POR APLASTAMIENTO DEL TORAX CON EL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS CON MIEMBRO(S)"},
{ value: "T048",	text: "TRAUMATISMOS POR APLASTAMIENTO QUE AFECTAN OTRAS COMBINACIONES DE REGIONES DEL CUERPO"},
{ value: "T049",	text: "TRAUMATISMOS POR APLASTAMIENTO MULTIPLES, NO ESPECIFICADOS"},
{ value: "T050",	text: "AMPUTACION TRAUMATICA DE AMBAS MANOS"},
{ value: "T051",	text: "AMPUTACION TRAUMATICA DE UNA MANO Y EL OTRO BRAZO [CUALQUIER NIVEL, EXCEPTO MANO]"},
{ value: "T052",	text: "AMPUTACION TRAUMATICA DE AMBOS BRAZOS [CUALQUIER NIVEL]"},
{ value: "T053",	text: "AMPUTACION TRAUMATICA DE AMBOS PIES"},
{ value: "T054",	text: "AMPUTACION TRAUMATICA DE UN PIE Y LA OTRA PIERNA [CUALQUIER NIVEL, EXCEPTO PIE]"},
{ value: "T055",	text: "AMPUTACION TRAUMATICA DE AMBAS PIERNAS [CUALQUIER NIVEL]"},
{ value: "T056",	text: "AMPUTACION TRAUMATICA DE MIEMBROS SUPERIOR(ES) E INFERIOR(ES), CUALQUIER COMBINACION [CUALQUIER NIVEL]"},
{ value: "T058",	text: "AMPUTACION TRAUMATICA QUE AFECTA OTRAS COMBINACIONES DE REGIONES DEL CUERPO"},
{ value: "T059",	text: "AMPUTACION TRAUMATICA MULTIPLES, NO ESPECIFICADAS"},
{ value: "T060",	text: "TRAUMATISMOS DEL ENCEFALO Y DE LOS NERVIOS CRANEALES CON TRAUMATISMO DE NERVIOS Y MEDULA ESPINAL A NIVEL DEL CUELLO"},
{ value: "T061",	text: "TRAUMATISMOS DE NERVIOS Y MEDULA ESPINAL QUE AFECTAN OTRAS MULTIPLES REGIONES DEL CUERPO"},
{ value: "T062",	text: "TRAUMATISMOS DE NERVIOS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO"},
{ value: "T063",	text: "TRAUMATISMOS DE VASOS SANGUINEOS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO"},
{ value: "T064",	text: "TRAUMATISMOS DE TENDONES Y MUSCULOS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO"},
{ value: "T065",	text: "TRAUMATISMOS DE ORGANOS INTRATORACICOS CON ORGANOS INTRAABDOMINALES Y PELVICOS"},
{ value: "T068",	text: "OTROS TRAUMATISMOS ESPECIFICADOS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO"},
{ value: "T07X",	text: "TRAUMATISMOS MULTIPLES, NO ESPECIFICADOS"},
{ value: "T08X",	text: "FRACTURA DE LA COLUMNA VERTEBRAL, NIVEL NO ESPECIFICADO"},
{ value: "T090",	text: "TRAUMATISMOS SUPERFICIAL DEL TRONCO, NIVEL NO ESPECIFICADO"},
{ value: "T091",	text: "HERIDA DEL TRONCO, NIVEL NO ESPECIFICADO"},
{ value: "T092",	text: "LUXACION, ESGUINCE O TORCEDURA DE ARTICULACION Y LIGAMENTOS DEL TRONCO, NO ESPECIFICADO"},
{ value: "T093",	text: "TRAUMATISMO DE LA MEDULA ESPINAL, NIVEL NO ESPECIFICADO"},
{ value: "T094",	text: "TRAUMATISMO DE NERVIOS, RAIZ DE NERVIO ESPINAL Y PLEXOS DEL TRONCO NO ESPECIFICADOS"},
{ value: "T095",	text: "TRAUMATISMO DE TENDONES Y MUSCULOS DEL TRONCO NO ESPECIFICADOS"},
{ value: "T096",	text: "AMPUTACION TRAUMATICA DEL TRONCO, NIVEL NO ESPECIFICADO"},
{ value: "T098",	text: "OTROS TRAUMATISMOS ESPECIFICADOS DEL TRONCO, NIVEL NO ESPECIFICADO"},
{ value: "T099",	text: "TRAUMATISMO NO ESPECIFICADO DEL TRONCO, NIVEL NO ESPECIFICADO"},
{ value: "T10X",	text: "FRACTURA DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T110",	text: "TRAUMATISMOS SUPERFICIAL DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T111",	text: "HERIDA DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T112",	text: "LUXACION, ESGUINCE O TORCEDURA DE ARTICULACION O LIGAMENTO NO ESPECIFICADO DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T113",	text: "TRAUMATISMO DE NERVIO NO ESPECIFICADO DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T114",	text: "TRAUMATISMO DE VASOS SANGUINEOS NO ESPECIFICADOS DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T115",	text: "TRAUMATISMO DE TENDON Y MUSCULO NO ESPECIFICADOS DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T116",	text: "AMPUTACION TRAUMATICA DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T118",	text: "OTROS TRAUMATISMOS ESPECIFICADOS DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T119",	text: "TRAUMATISMO NO ESPECIFICADO DE MIEMBRO SUPERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T12X",	text: "FRACTURA DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T130",	text: "TRAUMATISMO SUPERFICIAL DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T131",	text: "HERIDA DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T132",	text: "LUXACION, ESGUINCE O TORCEDURA DE ARTICULACION Y LIGAMENTOS NO ESPECIFICADOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T133",	text: "TRAUMATISMO DE NERVIOS NO ESPECIFICADOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T134",	text: "TRAUMATISMO DE VASOS SANGUINEOS NO ESPECIFICADOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T135",	text: "TRAUMATISMO DE TENDONES Y MUSCULOS NO ESPECIFICADOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T136",	text: "AMPUTACION TRAUMATICA DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T138",	text: "OTROS TRAUMATISMOS ESPECIFICADOS DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T139",	text: "TRAUMATISMO NO ESPECIFICADO DE MIEMBRO INFERIOR, NIVEL NO ESPECIFICADO"},
{ value: "T140",	text: "TRAUMATISMOS SUPERFICIAL DE REGION NO ESPECIFICADA DEL CUERPO"},
{ value: "T141",	text: "HERIDA DE REGION NO ESPECIFICADA DEL CUERPO"},
{ value: "T142",	text: "FRACTURA DE REGION NO ESPECIFICADA DEL CUERPO"},
{ value: "T143",	text: "LUXACION, ESGUINCE O TORCEDURA DE REGION NO ESPECIFICADA DEL CUERPO"},
{ value: "T144",	text: "TRAUMATISMO DE NERVIO(S) DE REGION NO ESPECIFICADA DEL CUERPO"},
{ value: "T145",	text: "TRAUMATISMO DE VASO(S) SANGUINEO(S) DE REGION NO ESPECIFICADA DEL CUERPO"},
{ value: "T146",	text: "TRAUMATISMO DE TENDONES Y MUSCULOS DE REGION NO ESPECIFICADA DEL CUERPO"},
{ value: "T147",	text: "TRAUMATISMO POR APLASTAMIENTO Y AMPUTACION TRAUMATICA DE REGIONES NO ESPECIFICADAS DEL CUERPO"},
{ value: "T148",	text: "OTROS TRAUMATISMOS DE REGION NO ESPECIFICADA DEL CUERPO"},
{ value: "T149",	text: "TRAUMATISMO, NO ESPECIFICADO"},
{ value: "T150",	text: "CUERPO EXTRAÑO EN LA CORNEA"},
{ value: "T151",	text: "CUERPO EXTRAÑO EN EL SACO CONJUNTIVAL"},
{ value: "T158",	text: "CUERPO EXTRAÑO EN OTRAS Y EN MULTIPLES PARTES DE LA PARTE EXTERNA DEL OJO"},
{ value: "T159",	text: "CUERPO EXTRAÑO EN PARTE EXTERNA DEL OJO, SITIO NO ESPECIFICADO"},
{ value: "T16X",	text: "CUERPO EXTRAÑO EN EL OIDO"},
{ value: "T170",	text: "CUERPO EXTRAÑO EN SENO PARANASAL"},
{ value: "T171",	text: "CUERPO EXTRAÑO EN EL ORIFICIO NASAL"},
{ value: "T172",	text: "CUERPO EXTRAÑO EN LA FARINGE"},
{ value: "T173",	text: "CUERPO EXTRAÑO EN LA LARINGE"},
{ value: "T174",	text: "CUERPO EXTRAÑO EN LA TRAQUEA"},
{ value: "T175",	text: "CUERPO EXTRAÑO EN BRONQUIOS"},
{ value: "T178",	text: "CUERPO EXTRAÑO EN OTRAS Y EN MULTIPLES PARTES DE LAS VIAS RESPIRATORIAS"},
{ value: "T179",	text: "CUERPO EXTRAÑO EN LAS VIAS RESPIRATORIAS, PARTE NO ESPECIFICADA"},
{ value: "T180",	text: "CUERPO EXTRAÑO EN LA BOCA"},
{ value: "T181",	text: "CUERPO EXTRAÑO EN EL ESOFAGO"},
{ value: "T182",	text: "CUERPO EXTRAÑO EN EL ESTOMAGO"},
{ value: "T183",	text: "CUERPO EXTRAÑO EN EL INTESTINO DELGADO"},
{ value: "T184",	text: "CUERPO EXTRAÑO EN EL COLON"},
{ value: "T185",	text: "CUERPO EXTRAÑO EN EL ANO Y EN EL RECTO"},
{ value: "T188",	text: "CUERPO EXTRAÑO EN OTRAS Y EN MULTIPLES PARTES DEL TUBO DIGESTIVO"},
{ value: "T189",	text: "CUERPO EXTRAÑO EN EL TUBO DIGESTIVO, PARTE NO ESPECIFICADA"},
{ value: "T190",	text: "CUERPO EXTRAÑO EN LA URETRA"},
{ value: "T191",	text: "CUERPO EXTRAÑO EN LA VEJIGA"},
{ value: "T192",	text: "CUERPO EXTRAÑO EN LA VULVA Y EN LA VAGINA"},
{ value: "T193",	text: "CUERPO EXTRAÑO EN EL UTERO [CUALQUIER PARTE]"},
{ value: "T198",	text: "CUERPO EXTRAÑO EN OTRAS Y EN MULTIPLES PARTES DE LAS VIAS GENITOURINARIAS"},
{ value: "T199",	text: "CUERPO EXTRAÑO EN LAS VIAS GENITOURINARIAS, PARTE NO ESPECIFICADA"},
{ value: "T200",	text: "QUEMADURA DE LA CABEZA Y DEL CUELLO, GRADO NO ESPECIFICADO"},
{ value: "T201",	text: "QUEMADURA DE LA CABEZA Y DEL CUELLO, DE PRIMER GRADO"},
{ value: "T202",	text: "QUEMADURA DE LA CABEZA Y DEL CUELLO, DE SEGUNDO GRADO"},
{ value: "T203",	text: "QUEMADURA DE LA CABEZA Y DEL CUELLO, DE TERCER GRADO"},
{ value: "T204",	text: "CORROSION DE LA CABEZA Y DEL CUELLO, GRADO NO ESPECIFICADO"},
{ value: "T205",	text: "CORROSION DE LA CABEZA Y DEL CUELLO, DE PRIMER GRADO"},
{ value: "T206",	text: "CORROSION DE LA CABEZA Y DEL CUELLO, DE SEGUNDO GRADO"},
{ value: "T207",	text: "CORROSION DE LA CABEZA Y DEL CUELLO, DE TERCER GRADO"},
{ value: "T210",	text: "QUEMADURA DEL TRONCO, GRADO NO ESPECIFICADO"},
{ value: "T211",	text: "QUEMADURA DEL TRONCO, DE PRIMER GRADO"},
{ value: "T212",	text: "QUEMADURA DEL TRONCO, DE SEGUNDO GRADO"},
{ value: "T213",	text: "QUEMADURA DEL TRONCO, DE TERCER GRADO"},
{ value: "T214",	text: "CORROSION DEL TRONCO, GRADO NO ESPECIFICADO"},
{ value: "T215",	text: "CORROSION DEL TRONCO, DE PRIMER GRADO"},
{ value: "T216",	text: "CORROSION DEL TRONCO, DE SEGUNDO GRADO"},
{ value: "T217",	text: "CORROSION DEL TRONCO, DE TERCER GRADO"},
{ value: "T220",	text: "QUEMADURA DEL HOMBRO Y MIEMBRO SUPERIOR, GRADO NO ESPECIFICADO, EXCEPTO DE LA MUÑECA Y DE LA MANO"},
{ value: "T221",	text: "QUEMADURA DEL HOMBRO Y MIEMBRO SUPERIOR, DE PRIMER GRADO, EXCEPTO DE LA MUÑECA Y DE LA MANO"},
{ value: "T222",	text: "QUEMADURA DEL HOMBRO Y MIEMBRO SUPERIOR, DE SEGUNDO GRADO, EXCEPTO DE LA MUÑECA Y DE LA MANO"},
{ value: "T223",	text: "QUEMADURA DEL HOMBRO Y MIEMBRO SUPERIOR, DE TERCER GRADO, EXCEPTO DE LA MUÑECA Y DE LA MANO"},
{ value: "T224",	text: "CORROSION DEL HOMBRO Y MIEMBRO SUPERIOR, GRADO NO ESPECIFICADO, EXCEPTO DE LA MUÑECA Y DE LA MANO"},
{ value: "T225",	text: "CORROSION DEL HOMBRO Y MIEMBRO SUPERIOR, DE PRIMER GRADO, EXCEPTO DE LA MUÑECA Y DE LA MANO"},
{ value: "T226",	text: "CORROSION DEL HOMBRO Y MIEMBRO SUPERIOR, DE SEGUNDO GRADO, EXCEPTO DE LA MUÑECA Y DE LA MANO"},
{ value: "T227",	text: "CORROSION DEL HOMBRO Y MIEMBRO SUPERIOR, DE TERCER GRADO, EXCEPTO DE LA MUÑECA Y DE LA MANO"},
{ value: "T230",	text: "QUEMADURA DE LA MUÑECA Y DE LA MANO, GRADO NO ESPECIFICADO"},
{ value: "T231",	text: "QUEMADURA DE LA MUÑECA Y DE LA MANO, DE PRIMER GRADO"},
{ value: "T232",	text: "QUEMADURA DE LA MUÑECA Y DE LA MANO, DE SEGUNDO GRADO"},
{ value: "T233",	text: "QUEMADURA DE LA MUÑECA Y DE LA MANO, DE TERCER GRADO"},
{ value: "T234",	text: "CORROSION DE LA MUÑECA Y DE LA MANO, GRADO NO ESPECIFICADO"},
{ value: "T235",	text: "CORROSION DE LA MUÑECA Y DE LA MANO, DE PRIMER GRADO"},
{ value: "T236",	text: "CORROSION DE LA MUÑECA Y DE LA MANO, DE SEGUNDO GRADO"},
{ value: "T237",	text: "CORROSION DE LA MUÑECA Y DE LA MANO, DE TERCER GRADO"},
{ value: "T240",	text: "QUEMADURA DE LA CADERA Y DEL MIEMBRO INFERIOR, GRADO NO ESPECIFICADO, EXCEPTO TOBILLO Y PIE"},
{ value: "T241",	text: "QUEMADURA DE LA CADERA Y DEL MIEMBRO INFERIOR, DE PRIMER GRADO, EXCEPTO TOBILLO Y PIE"},
{ value: "T242",	text: "QUEMADURA DE LA CADERA Y DEL MIEMBRO INFERIOR, DE SEGUNDO GRADO, EXCEPTO TOBILLO Y PIE"},
{ value: "T243",	text: "QUEMADURA DE LA CADERA Y DEL MIEMBRO INFERIOR, DE TERCER GRADO, EXCEPTO TOBILLO Y PIE"},
{ value: "T244",	text: "CORROSION DE LA CADERA Y DEL MIEMBRO INFERIOR, GRADO NO ESPECIFICADO, EXCEPTO TOBILLO Y PIE"},
{ value: "T245",	text: "CORROSION DE LA CADERA Y DEL MIEMBRO INFERIOR, DE PRIMER GRADO, EXCEPTO TOBILLO Y PIE"},
{ value: "T246",	text: "CORROSION DE LA CADERA Y DEL MIEMBRO INFERIOR, DE SEGUNDO GRADO, EXCEPTO TOBILLO Y PIE"},
{ value: "T247",	text: "CORROSION DE LA CADERA Y DEL MIEMBRO INFERIOR, DE TERCER GRADO, EXCEPTO TOBILLO Y PIE"},
{ value: "T250",	text: "QUEMADURA DEL TOBILLO Y DEL PIE, GRADO NO ESPECIFICADO"},
{ value: "T251",	text: "QUEMADURA DEL TOBILLO Y DEL PIE, DE PRIMER GRADO"},
{ value: "T252",	text: "QUEMADURA DEL TOBILLO Y DEL PIE, DE SEGUNDO GRADO"},
{ value: "T253",	text: "QUEMADURA DEL TOBILLO Y DEL PIE, DE TERCER GRADO"},
{ value: "T254",	text: "CORROSION DEL TOBILLO Y DEL PIE, GRADO NO ESPECIFICADO"},
{ value: "T255",	text: "CORROSION DEL TOBILLO Y DEL PIE, DE PRIMER GRADO"},
{ value: "T256",	text: "CORROSION DEL TOBILLO Y DEL PIE, DE SEGUNDO GRADO"},
{ value: "T257",	text: "CORROSION DEL TOBILLO Y DEL PIE, DE TERCER GRADO"},
{ value: "T260",	text: "QUEMADURA DEL PARPADO Y AREA PERIOCULAR"},
{ value: "T261",	text: "QUEMADURA DE LA CORNEA Y SACO CONJUNTIVAL"},
{ value: "T262",	text: "QUEMADURA CON RUPTURA Y DESTRUCCION RESULTANTES DEL GLOBO OCULAR"},
{ value: "T263",	text: "QUEMADURA DE OTRAS PARTES DEL OJO Y SUS ANEXOS"},
{ value: "T264",	text: "QUEMADURA DEL OJO Y ANEXOS, PARTE NO ESPECIFICADA"},
{ value: "T265",	text: "CORROSION DEL PARPADO Y AREA PERIOCULAR"},
{ value: "T266",	text: "CORROSION DE LA CORNEA Y SACO CONJUNTIVAL"},
{ value: "T267",	text: "CORROSION CON RUPTURA Y DESTRUCCION RESULTANTES DEL GLOBO OCULAR"},
{ value: "T268",	text: "CORROSION DE OTRAS PARTES DEL OJO Y SUS ANEXOS"},
{ value: "T269",	text: "CORROSION DEL OJO Y ANEXOS, PARTE NO ESPECIFICADA"},
{ value: "T270",	text: "QUEMADURA DE LA LARINGE Y LA TRAQUEA"},
{ value: "T271",	text: "QUEMADURA QUE AFECTA LA LARINGE Y LA TRAQUEA CON EL PULMON"},
{ value: "T272",	text: "QUEMADURA DE OTRAS PARTES DE LAS VIAS RESPIRATORIAS"},
{ value: "T273",	text: "QUEMADURA DE LAS VIAS RESPIRATORIAS, PARTE NO ESPECIFICADA"},
{ value: "T274",	text: "CORROSION DE LA LARINGE Y DE LA TRAQUEA"},
{ value: "T275",	text: "CORROSION QUE AFECTA LA LARINGE Y LA TRAQUEA CON EL PULMON"},
{ value: "T276",	text: "CORROSION DE OTRAS PARTES DE LAS VIAS RESPIRATORIAS"},
{ value: "T277",	text: "CORROSION DE LAS VIAS RESPIRATORIAS, PARTE NO ESPECIFICADA"},
{ value: "T280",	text: "QUEMADURA DE LA BOCA Y DE LA FARINGE"},
{ value: "T281",	text: "QUEMADURA DEL ESOFAGO"},
{ value: "T282",	text: "QUEMADURA DE OTRAS PARTES DEL TUBO DIGESTIVO"},
{ value: "T283",	text: "QUEMADURA DE ORGANOS GENITOURINARIOS INTERNOS"},
{ value: "T284",	text: "QUEMADURA DE OTROS ORGANOS INTERNOS Y DE LOS NO ESPECIFICADOS"},
{ value: "T285",	text: "CORROSION DE LA BOCA Y DE LA FARINGE"},
{ value: "T286",	text: "CORROSION DEL ESOFAGO"},
{ value: "T287",	text: "CORROSION DE OTRAS PARTES DEL TUBO DIGESTIVO"},
{ value: "T288",	text: "CORROSION DE ORGANOS GENITOURINARIOS INTERNOS"},
{ value: "T289",	text: "CORROSION DE OTROS ORGANOS INTERNOS Y DE LOS NO ESPECIFICADOS"},
{ value: "T290",	text: "QUEMADURAS DE MULTIPLES REGIONES, GRADO NO ESPECIFICADO"},
{ value: "T291",	text: "QUEMADURAS DE MULTIPLES REGIONES, MENCIONADAS COMO DE NO MAS DE PRIMER GRADO"},
{ value: "T292",	text: "QUEMADURAS DE MULTIPLES REGIONES, MENCIONADAS COMO DE NO MAS DE SEGUNDO GRADO"},
{ value: "T293",	text: "QUEMADURAS DE MULTIPLES REGIONES, CON MENCION AL MENOS DE UNA QUEMADURA DE TERCER GRADO"},
{ value: "T294",	text: "CORROSIONES DE MULTIPLES REGIONES, GRADO NO ESPECIFICADO"},
{ value: "T295",	text: "CORROSIONES MULTIPLES, MENCIONADAS COMO DE NO MAS DE PRIMER GRADO"},
{ value: "T296",	text: "CORROSIONES MULTIPLES, MENCIONADAS COMO DE NO MAS DE SEGUNDO GRADO"},
{ value: "T297",	text: "CORROSIONES MULTIPLES, CON MENCION AL MENOS DE UNA QUEMADURA DE TERCER GRADO"},
{ value: "T300",	text: "QUEMADURA DE REGION DEL CUERPO Y GRADO NO ESPECIFICADOS"},
{ value: "T301",	text: "QUEMADURA DE PRIMER GRADO, REGION DEL CUERPO NO ESPECIFICADA"},
{ value: "T302",	text: "QUEMADURA DE SEGUNDO GRADO, REGION DEL CUERPO NO ESPECIFICADA"},
{ value: "T303",	text: "QUEMADURA DE TERCER GRADO, REGION DEL CUERPO NO ESPECIFICADA"},
{ value: "T304",	text: "CORROSION DE REGION DEL CUERPO Y GRADO NO ESPECIFICADOS"},
{ value: "T305",	text: "CORROSION DE PRIMER GRADO, REGION DEL CUERPO NO ESPECIFICADA"},
{ value: "T306",	text: "CORROSION DE SEGUNDO GRADO, REGION DEL CUERPO NO ESPECIFICADA"},
{ value: "T307",	text: "CORROSION DE TERCER GRADO, REGION DEL CUERPO NO ESPECIFICADA"},
{ value: "T310",	text: "QUEMADURAS QUE AFECTAN MENOS DEL 10% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T311",	text: "QUEMADURAS QUE AFECTAN DEL 10% AL 19% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T312",	text: "QUEMADURAS QUE AFECTAN DEL 20% AL 29% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T313",	text: "QUEMADURAS QUE AFECTAN DEL 30% AL 39% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T314",	text: "QUEMADURAS QUE AFECTAN DEL 40% AL 49% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T315",	text: "QUEMADURAS QUE AFECTAN DEL 50% AL 59% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T316",	text: "QUEMADURAS QUE AFECTAN DEL 60% AL 69% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T317",	text: "QUEMADURAS QUE AFECTAN DEL 70% AL 79% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T318",	text: "QUEMADURAS QUE AFECTAN DEL 80% AL 89% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T319",	text: "QUEMADURAS QUE AFECTAN DEL 90% O MAS DE LA SUPERFICIE DEL CUERPO"},
{ value: "T320",	text: "CORROSIONES QUE AFECTAN MENOS DEL 10% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T321",	text: "CORROSIONES QUE AFECTAN DEL 10% AL 19% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T322",	text: "CORROSIONES QUE AFECTAN DEL 20% AL 29% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T323",	text: "CORROSIONES QUE AFECTAN DEL 30% AL 39% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T324",	text: "CORROSIONES QUE AFECTAN DEL 40% AL 49% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T325",	text: "CORROSIONES QUE AFECTAN DEL 50% AL 59% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T326",	text: "CORROSIONES QUE AFECTAN DEL 60% AL 69% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T327",	text: "CORROSIONES QUE AFECTAN DEL 70% AL 79% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T328",	text: "CORROSIONES QUE AFECTAN DEL 80% AL 89% DE LA SUPERFICIE DEL CUERPO"},
{ value: "T329",	text: "CORROSIONES QUE AFECTAN DEL 90% O MAS DE LA SUPERFICIE DEL CUERPO"},
{ value: "T330",	text: "CONGELAMIENTO SUPERFICIAL DE LA CABEZA"},
{ value: "T331",	text: "CONGELAMIENTO SUPERFICIAL DEL CUELLO"},
{ value: "T332",	text: "CONGELAMIENTO SUPERFICIAL DEL TORAX"},
{ value: "T333",	text: "CONGELAMIENTO SUPERFICIAL DE LA PARED ABDOMINAL, REGION LUMBOSACRA Y PELVIS"},
{ value: "T334",	text: "CONGELAMIENTO SUPERFICIAL DEL BRAZO"},
{ value: "T335",	text: "CONGELAMIENTO SUPERFICIAL DE LA MUÑECA Y DE LA MANO"},
{ value: "T336",	text: "CONGELAMIENTO SUPERFICIAL DE LA CADERA Y DEL MUSLO"},
{ value: "T337",	text: "CONGELAMIENTO SUPERFICIAL DE LA RODILLA Y DE LA PIERNA"},
{ value: "T338",	text: "CONGELAMIENTO SUPERFICIAL DEL TOBILLO Y DEL PIE"},
{ value: "T339",	text: "CONGELAMIENTO SUPERFICIAL DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS"},
{ value: "T340",	text: "CONGELAMIENTO CON NECROSIS TISULAR DE LA CABEZA"},
{ value: "T341",	text: "CONGELAMIENTO CON NECROSIS TISULAR DEL CUELLO"},
{ value: "T342",	text: "CONGELAMIENTO CON NECROSIS TISULAR DEL TORAX"},
{ value: "T343",	text: "CONGELAMIENTO CON NECROSIS TISULAR DE LA PARED ABDOMINAL, REGION LUMBOSACRA Y PELVIS"},
{ value: "T344",	text: "CONGELAMIENTO CON NECROSIS TISULAR DEL BRAZO"},
{ value: "T345",	text: "CONGELAMIENTO CON NECROSIS TISULAR DE LA MUÑECA Y DE LA MANO"},
{ value: "T346",	text: "CONGELAMIENTO CON NECROSIS TISULAR DE LA CADERA Y DEL MUSLO"},
{ value: "T347",	text: "CONGELAMIENTO CON NECROSIS TISULAR DE LA RODILLA Y DE LA PIERNA"},
{ value: "T348",	text: "CONGELAMIENTO CON NECROSIS TISULAR DEL TOBILLO Y DEL PIE"},
{ value: "T349",	text: "CONGELAMIENTO CON NECROSIS TISULAR DE OTROS SITIOS Y DE LOS NO ESPECIFICADOS"},
{ value: "T350",	text: "CONGELAMIENTO SUPERFICIAL QUE AFECTA MULTIPLES REGIONES DEL CUERPO"},
{ value: "T351",	text: "CONGELAMIENTO CON NECROSIS TISULAR QUE AFECTA MULTIPLES REGIONES DEL CUERPO"},
{ value: "T352",	text: "CONGELAMIENTO NO ESPECIFICADO DE LA CABEZA Y DEL CUELLO"},
{ value: "T353",	text: "CONGELAMIENTO NO ESPECIFICADO DEL TORAX, DEL ABDOMEN, DE LA REGION LUMBOSACRA Y DE LA PELVIS"},
{ value: "T354",	text: "CONGELAMIENTO NO ESPECIFICADO DEL MIEMBRO SUPERIOR"},
{ value: "T355",	text: "CONGELAMIENTO NO ESPECIFICADO DEL MIEMBRO INFERIOR"},
{ value: "T356",	text: "CONGELAMIENTO NO ESPECIFICADO QUE AFECTA MULTIPLES REGIONES DEL CUERPO"},
{ value: "T357",	text: "CONGELAMIENTO NO ESPECIFICADO, DE SITIO NO ESPECIFICADO"},
{ value: "T360",	text: "ENVENENAMIENTO POR ANTIBIOTICOS SISTEMICOS: PENICILINAS"},
{ value: "T361",	text: "ENVENENAMIENTO POR ANTIBIOTICOS SISTEMICOS: CEFALOSPORINAS Y OTROS ANTIBIOTICOS BETA- LACTAMICOS"},
{ value: "T362",	text: "ENVENENAMIENTO POR ANTIBIOTICOS SISTEMICOS: GRUPO DEL CLORAMFENICOL"},
{ value: "T363",	text: "ENVENENAMIENTO POR ANTIBIOTICOS SISTEMICOS: MACROLIDOS"},
{ value: "T364",	text: "ENVENENAMIENTO POR ANTIBIOTICOS SISTEMICOS: TETRACICLINAS"},
{ value: "T365",	text: "ENVENENAMIENTO POR ANTIBIOTICOS SISTEMICOS: AMINOGLUCOSIDOS"},
{ value: "T366",	text: "ENVENENAMIENTO POR ANTIBIOTICOS SISTEMICOS: RIFAMICINAS"},
{ value: "T367",	text: "ENVENENAMIENTO POR ANTIBIOTICOS SISTEMICOS: ANTIBIOTICOS ANTIMICOTICOS USADOS SISTEMICAMENTE"},
{ value: "T368",	text: "ENVENENAMIENTO POR ANTIBIOTICOS SISTEMICOS: OTROS ANTIBIOTICOS SISTEMICOS"},
{ value: "T369",	text: "ENVENENAMIENTO POR ANTIBIOTICOS SISTEMICOS: ANTIBIOTICOS SISTEMICOS, NO ESPECIFICADOS"},
{ value: "T370",	text: "ENVENENAMIENTO POR OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS: SULFONAMIDAS"},
{ value: "T371",	text: "ENVENENAMIENTO POR OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS: DROGAS ANTIMICOBACTERIANAS"},
{ value: "T372",	text: "ENVENENAMIENTO POR OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS: ANTIPALUDICOS Y DROGAS DE ACCION CONTRA OTROS PROTOZOARIOS SANGUINEOS"},
{ value: "T373",	text: "ENVENENAMIENTO POR OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS: OTRAS DROGAS ANTIPROTOZOARIAS"},
{ value: "T374",	text: "ENVENENAMIENTO POR OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS: ANTIHELMINTICOS"},
{ value: "T375",	text: "ENVENENAMIENTO POR OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS: DROGAS ANTIVIRALES"},
{ value: "T378",	text: "ENVENENAMIENTO POR OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS: OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS ESPECIFICADOS"},
{ value: "T379",	text: "ENVENENAMIENTO POR OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS: OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS, NO ESPECIFICADOS"},
{ value: "T380",	text: "ENVENENAMIENTO POR HORMONAS Y SUS SUSTITUTOS Y ANTAGONISTAS SINTETICOS, NO CLASIFICADOS EN OTRA PARTE: GLUCOCORTICOIDES Y ANALOGOS SINTETICOS"},
{ value: "T381",	text: "ENVENENAMIENTO POR HORMONAS Y SUS SUSTITUTOS Y ANTAGONISTAS SINTETICOS, NO CLASIFICADOS EN OTRA PARTE: HORMONAS TIROIDEAS Y SUSTITUTOS"},
{ value: "T382",	text: "ENVENENAMIENTO POR HORMONAS Y SUS SUSTITUTOS Y ANTAGONISTAS SINTETICOS, NO CLASIFICADOS EN OTRA PARTE: DROGAS ANTITIROIDEAS"},
{ value: "T383",	text: "ENVENENAMIENTO POR HORMONAS Y SUS SUSTITUTOS Y ANTAGONISTAS SINTETICOS, NO CLASIFICADOS EN OTRA PARTE: INSULINA Y DROGAS HIPOGLUCEMIANTES ORALES [ANTIDIABETICAS]"},
{ value: "T384",	text: "ENVENENAMIENTO POR HORMONAS Y SUS SUSTITUTOS Y ANTAGONISTAS SINTETICOS, NO CLASIFICADOS EN OTRA PARTE: ANTICONCEPTIVOS ORALES"},
{ value: "T385",	text: "ENVENENAMIENTO POR HORMONAS Y SUS SUSTITUTOS Y ANTAGONISTAS SINTETICOS, NO CLASIFICADOS EN OTRA PARTE: OTROS ESTROGENOS Y PROGESTOGENOS"},
{ value: "T386",	text: "ENVENENAMIENTO POR HORMONAS Y SUS SUSTITUTOS Y ANTAGONISTAS SINTETICOS, NO CLASIFICADOS EN OTRA PARTE: ANTIGONADOTROFINAS, ANTIESTROGENOS Y ANTIANDROGENOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T387",	text: "ENVENENAMIENTO POR HORMONAS Y SUS SUSTITUTOS Y ANTAGONISTAS SINTETICOS, NO CLASIFICADOS EN OTRA PARTE: ANDROGENOS Y SUS CONGENERES ANABOLICOS"},
{ value: "T388",	text: "ENVENENAMIENTO POR HORMONAS Y SUS SUSTITUTOS Y ANTAGONISTAS SINTETICOS, NO CLASIFICADOS EN OTRA PARTE: OTRAS HORMONAS Y SUSTITUTOS SINTETICOS Y LOS NO ESPECIFICADOS"},
{ value: "T389",	text: "ENVENENAMIENTO POR HORMONAS Y SUS SUSTITUTOS Y ANTAGONISTAS SINTETICOS, NO CLASIFICADOS EN OTRA PARTE: OTROS ANTAGONISTAS DE LAS HORMONAS Y LOS NO ESPECIFICADOS"},
{ value: "T390",	text: "ENVENENAMIENTO POR ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: SALICILATOS"},
{ value: "T391",	text: "ENVENENAMIENTO POR ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: DERIVADOS DEL PARAAMINOFENOL"},
{ value: "T392",	text: "ENVENENAMIENTO POR ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: DERIVADOS DE LA PIRAZOLONA"},
{ value: "T393",	text: "ENVENENAMIENTO POR ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: OTRAS DROGAS ANTIINFLAMATORIAS NO ESTEROIDEAS [DAINE]"},
{ value: "T394",	text: "ENVENENAMIENTO POR ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: ANTIRREUMATICOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T398",	text: "ENVENENAMIENTO POR ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: OTROS ANALGESICOS NO NARCOTICOS Y ANTIPIRETICOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T399",	text: "ENVENENAMIENTO POR ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, NO ESPECIFICADOS"},
{ value: "T400",	text: "ENVENENAMIENTO POR NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS]: OPIO"},
{ value: "T401",	text: "ENVENENAMIENTO POR NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS]: HEROINA"},
{ value: "T402",	text: "ENVENENAMIENTO POR NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS]: OTROS OPIACEOS"},
{ value: "T403",	text: "ENVENENAMIENTO POR NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS]: METADONA"},
{ value: "T404",	text: "ENVENENAMIENTO POR NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS]: OTROS NARCOTICOS SINTETICOS"},
{ value: "T405",	text: "ENVENENAMIENTO POR NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS]: COCAINA"},
{ value: "T406",	text: "ENVENENAMIENTO POR NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS]: OTROS NARCOTICOS Y LOS NO ESPECIFICADOS"},
{ value: "T407",	text: "ENVENENAMIENTO POR NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS]: CANNABIS (DERIVADOS)"},
{ value: "T408",	text: "ENVENENAMIENTO POR NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS]: ACIDO LISERGICO [LSD]"},
{ value: "T409",	text: "ENVENENAMIENTO POR NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS]: OTROS PSICODISLEPTICOS Y LOS NO ESPECIFICADOS [ALUCINOGENOS]"},
{ value: "T410",	text: "ENVENENAMIENTO POR ANESTESICOS Y GASES TERAPEUTICOS: ANESTESICOS POR INHALACION"},
{ value: "T411",	text: "ENVENENAMIENTO POR ANESTESICOS Y GASES TERAPEUTICOS: ANESTESICOS INTRAVENOSOS"},
{ value: "T412",	text: "ENVENENAMIENTO POR ANESTESICOS Y GASES TERAPEUTICOS: OTROS ANESTESICOS GENERALES Y LOS NO ESPECIFICADOS"},
{ value: "T413",	text: "ENVENENAMIENTO POR ANESTESICOS Y GASES TERAPEUTICOS: ANESTESICOS LOCALES"},
{ value: "T414",	text: "ENVENENAMIENTO POR ANESTESICOS Y GASES TERAPEUTICOS: ANESTESICOS, NO ESPECIFICADOS"},
{ value: "T415",	text: "ENVENENAMIENTO POR ANESTESICOS Y GASES TERAPEUTICOS: GASES TERAPEUTICOS"},
{ value: "T420",	text: "ENVENENAMIENTO POR ANTIEPILEPTICOS, HIPNOTICOS- SEDANTES Y DROGAS ANTIPARKINSONIANAS: DERIVADOS DE LA HIDANTOINA"},
{ value: "T421",	text: "ENVENENAMIENTO POR ANTIEPILEPTICOS, HIPNOTICOS- SEDANTES Y DROGAS ANTIPARKINSONIANAS: IMINOSTILBENOS"},
{ value: "T422",	text: "ENVENENAMIENTO POR ANTIEPILEPTICOS, HIPNOTICOS- SEDANTES Y DROGAS ANTIPARKINSONIANAS: SUCCINAMIDAS Y DERIVADOS DE LA OXAZOLIDINA"},
{ value: "T423",	text: "ENVENENAMIENTO POR ANTIEPILEPTICOS, HIPNOTICOS- SEDANTES Y DROGAS ANTIPARKINSONIANAS: BARBITURICOS"},
{ value: "T424",	text: "ENVENENAMIENTO POR ANTIEPILEPTICOS, HIPNOTICOS- SEDANTES Y DROGAS ANTIPARKINSONIANAS: BENZODIAZEPINAS"},
{ value: "T425",	text: "ENVENENAMIENTO POR ANTIEPILEPTICOS, HIPNOTICOS- SEDANTES Y DROGAS ANTIPARKINSONIANAS: ANTIEPILEPTICOS MIXTOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T426",	text: "ENVENENAMIENTO POR ANTIEPILEPTICOS, HIPNOTICOS- SEDANTES Y DROGAS ANTIPARKINSONIANAS: OTROS ANTIEPILEPTICOS Y DROGAS HIPNOTICO SEDANTES"},
{ value: "T427",	text: "ENVENENAMIENTO POR ANTIEPILEPTICOS, HIPNOTICOS- SEDANTES Y DROGAS ANTIPARKINSONIANAS: ANTIEPILEPTICOS Y DROGAS HIPNOTICO - SEDANTES, NO ESPECIFICADOS"},
{ value: "T428",	text: "ENVENENAMIENTO POR ANTIEPILEPTICOS, HIPNOTICOS- SEDANTES Y DROGAS ANTIPARKINSONIANAS: DROGAS ANTIPARKINSONIANAS Y OTROS DEPRESORES DEL TONO MUSCULAR CENTRAL"},
{ value: "T430",	text: "ENVENENAMIENTO POR PSICOTROPICOS, NO CLASIFICADOS EN OTRA PARTE: ANTIDEPRESIVOS TRICICLICOS Y TETRACICLICOS"},
{ value: "T431",	text: "ENVENENAMIENTO POR PSICOTROPICOS, NO CLASIFICADOS EN OTRA PARTE: ANTIDEPRESIVOS INHIBIDORES DE LA MONOAMINOXIDASA"},
{ value: "T432",	text: "ENVENENAMIENTO POR PSICOTROPICOS, NO CLASIFICADOS EN OTRA PARTE: OTROS ANTIDEPRESIVOS Y LOS NO ESPECIFICADOS"},
{ value: "T433",	text: "ENVENENAMIENTO POR PSICOTROPICOS, NO CLASIFICADOS EN OTRA PARTE: ANTIPSICOTICOS Y NEUROLEPTICOS FENOTIACINICOS"},
{ value: "T434",	text: "ENVENENAMIENTO POR PSICOTROPICOS, NO CLASIFICADOS EN OTRA PARTE: BUTIROFENONA Y NEUROLEPTICOS TIOXANTENICOS"},
{ value: "T435",	text: "ENVENENAMIENTO POR PSICOTROPICOS, NO CLASIFICADOS EN OTRA PARTE: OTROS ANTIPSICOTICOS Y NEUROLEPTICOS Y LOS NO ESPECIFICADOS"},
{ value: "T436",	text: "ENVENENAMIENTO POR PSICOTROPICOS, NO CLASIFICADOS EN OTRA PARTE: PSICOESTIMULANTES CON ABUSO POTENCIAL"},
{ value: "T438",	text: "ENVENENAMIENTO POR PSICOTROPICOS, NO CLASIFICADOS EN OTRA PARTE: OTRAS DROGAS PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "T439",	text: "ENVENENAMIENTO POR PSICOTROPICOS, NO CLASIFICADOS EN OTRA PARTE: DROGA PSICOTROPICA NO ESPECIFICADA"},
{ value: "T440",	text: "ENVENENAMIENTO POR DROGAS QUE AFECTAN PRINCIPALMENTE EL SISTEMA NERVIOSO AUTONOMO: AGENTES ANTICOLINESTERASA"},
{ value: "T441",	text: "ENVENENAMIENTO POR DROGAS QUE AFECTAN PRINCIPALMENTE EL SISTEMA NERVIOSO AUTONOMO: OTROS PARASIMPATICOMIMETICOS [COLINERGICOS]"},
{ value: "T442",	text: "ENVENENAMIENTO POR DROGAS QUE AFECTAN PRINCIPALMENTE EL SISTEMA NERVIOSO AUTONOMO: DROGAS BLOQUEADORAS GANGLIONARES, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "T443",	text: "ENVENENAMIENTO POR DROGAS QUE AFECTAN PRINCIPALMENTE EL SISTEMA NERVIOSO AUTONOMO: OTROS PARASIMPATICOLITICOS [ANTICOLINERGICOS Y ANTIMUSCARINICOS] Y ESPASMOLITICOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T444",	text: "ENVENENAMIENTO POR DROGAS QUE AFECTAN PRINCIPALMENTE EL SISTEMA NERVIOSO AUTONOMO: AGONISTAS, PREDOMINANTEMENTE ALFA- ADRENERGICOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T445",	text: "ENVENENAMIENTO POR DROGAS QUE AFECTAN PRINCIPALMENTE EL SISTEMA NERVIOSO AUTONOMO: AGONISTAS, PREDOMINANTEMENTE BETA- ADRENERGICOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T446",	text: "ENVENENAMIENTO POR DROGAS QUE AFECTAN PRINCIPALMENTE EL SISTEMA NERVIOSO AUTONOMO: ANTAGONISTAS, ALFA-ADRENERGICOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T447",	text: "ENVENENAMIENTO POR DROGAS QUE AFECTAN PRINCIPALMENTE EL SISTEMA NERVIOSO AUTONOMO: ANTAGONISTAS, BETA-ADRENERGICOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T448",	text: "ENVENENAMIENTO POR DROGAS QUE AFECTAN PRINCIPALMENTE EL SISTEMA NERVIOSO AUTONOMO: AGENTES DE ACCION CENTRAL Y BLOQUEADORES NEURONALES ADRENERGICOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T449",	text: "ENVENENAMIENTO POR DROGAS QUE AFECTAN PRINCIPALMENTE EL SISTEMA NERVIOSO AUTONOMO: OTRAS DROGAS Y LAS NO ESPECIFICADAS QUE AFECTAN PRINCIPALMENTE EL SISTEMA NERVIOSO AUTONOMO"},
{ value: "T450",	text: "ENVENENAMIENTO POR AGENTES PRINCIPALMENTE SISTEMICOS Y HEMATOLOGICOS, NO CLASIFICADOS EN OTRA PARTE: DROGAS ANTIALERGICAS Y ANTIEMETICAS"},
{ value: "T451",	text: "ENVENENAMIENTO POR AGENTES PRINCIPALMENTE SISTEMICOS Y HEMATOLOGICOS, NO CLASIFICADOS EN OTRA PARTE: DROGAS ANTINEOPLASICAS E INMUNOSUPRESORAS"},
{ value: "T452",	text: "ENVENENAMIENTO POR AGENTES PRINCIPALMENTE SISTEMICOS Y HEMATOLOGICOS, NO CLASIFICADOS EN OTRA PARTE: VITAMINAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "T453",	text: "ENVENENAMIENTO POR AGENTES PRINCIPALMENTE SISTEMICOS Y HEMATOLOGICOS, NO CLASIFICADOS EN OTRA PARTE: ENZIMAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "T454",	text: "ENVENENAMIENTO POR AGENTES PRINCIPALMENTE SISTEMICOS Y HEMATOLOGICOS, NO CLASIFICADOS EN OTRA PARTE: HIERRO Y SUS COMPUESTOS"},
{ value: "T455",	text: "ENVENENAMIENTO POR AGENTES PRINCIPALMENTE SISTEMICOS Y HEMATOLOGICOS, NO CLASIFICADOS EN OTRA PARTE: ANTICOAGULANTES"},
{ value: "T456",	text: "ENVENENAMIENTO POR AGENTES PRINCIPALMENTE SISTEMICOS Y HEMATOLOGICOS, NO CLASIFICADOS EN OTRA PARTE: DROGAS QUE AFECTAN LA FIBRINOLISIS"},
{ value: "T457",	text: "ENVENENAMIENTO POR AGENTES PRINCIPALMENTE SISTEMICOS Y HEMATOLOGICOS, NO CLASIFICADOS EN OTRA PARTE: ANTAGONISTAS DE ANTICOAGULANTES, VITAMINA K Y OTROS COAGULANTES"},
{ value: "T458",	text: "ENVENENAMIENTO POR AGENTES PRINCIPALMENTE SISTEMICOS Y HEMATOLOGICOS, NO CLASIFICADOS EN OTRA PARTE: OTROS AGENTES PRINCIPALMENTE SISTEMICOS Y HEMATOLOGICOS"},
{ value: "T459",	text: "ENVENENAMIENTO POR AGENTES PRINCIPALMENTE SISTEMICOS Y HEMATOLOGICOS, NO CLASIFICADOS EN OTRA PARTE: AGENTES PRINCIPALMENTE SISTEMICOS Y HEMATOLOGICOS, NO ESPECIFICADOS"},
{ value: "T460",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA CARDIOVASCULAR: GLUCOSIDOS CARDIOTONICOS Y MEDICAMENTOS DE ACCION SIMILAR"},
{ value: "T461",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA CARDIOVASCULAR: BLOQUEADORES DEL CANAL DEL CALCIO"},
{ value: "T462",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA CARDIOVASCULAR: OTRAS DROGAS ANTIARRITMICAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "T463",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA CARDIOVASCULAR: VASODILATADORES CORONARIOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T464",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA CARDIOVASCULAR: INHIBIDORES DE LA ENZIMA CONVERTIDORA DE LA ANGIOTENSINA"},
{ value: "T465",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA CARDIOVASCULAR: OTRAS DROGAS ANTIHIPERTENSIVAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "T466",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA CARDIOVASCULAR: DROGAS ANTILIPEMICAS Y ANTIARTERIOSCLEROTICAS"},
{ value: "T467",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA CARDIOVASCULAR: VASODILATADORES PERIFERICOS"},
{ value: "T468",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA CARDIOVASCULAR: DROGAS ANTIVARICOSAS, INCLUSIVE AGENTES ESCLEROSANTES"},
{ value: "T469",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA CARDIOVASCULAR: OTROS AGENTES Y LOS NO ESPECIFICADOS QUE AFECTAN PRINCIPALMENTE EL SISTEMA CARDIOVASCULAR"},
{ value: "T470",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL: ANTAGONISTAS DEL RECEPTOR H2 DE HISTAMINA"},
{ value: "T471",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL: OTRAS DROGAS ANTIACIDAS Y QUE INHIBEN LA SECRECION GASTRICA"},
{ value: "T472",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL: LAXANTES ESTIMULANTES"},
{ value: "T473",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL: LAXANTES SALINOS Y OSMOTICOS"},
{ value: "T474",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL: OTROS LAXANTES"},
{ value: "T475",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL: DIGESTIVOS"},
{ value: "T476",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL: DROGAS ANTIDIARREICAS"},
{ value: "T477",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL: EMETICOS"},
{ value: "T478",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL: OTROS AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL"},
{ value: "T479",	text: "ENVENENAMIENTO POR AGENTES QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL: AGENTES NO ESPECIFICADOS QUE AFECTAN PRINCIPALMENTE EL SISTEMA GASTROINTESTINAL"},
{ value: "T480",	text: "ENVENENAMIENTO POR AGENTES CON ACCION PRINCIPAL SOBRE LOS MUSCULOS LISOS Y ESQUELETICOS Y SOBRE EL SISTEMA RESPIRATORIO: DROGAS OXITOCICAS"},
{ value: "T481",	text: "ENVENENAMIENTO POR AGENTES CON ACCION PRINCIPAL SOBRE LOS MUSCULOS LISOS Y ESQUELETICOS Y SOBRE EL SISTEMA RESPIRATORIO: RELAJANTES MUSCULOESQUELETICOS [AGENTES BLOQUEADORES NEUROMUSCULARES]"},
{ value: "T482",	text: "ENVENENAMIENTO POR AGENTES CON ACCION PRINCIPAL SOBRE LOS MUSCULOS LISOS Y ESQUELETICOS Y SOBRE EL SISTEMA RESPIRATORIO: OTROS MEDICAMENTOS Y LOS NO ESPECIFICADOS DE ACCION PRINCIPAL SOBRE LOS MUSCULOS"},
{ value: "T483",	text: "ENVENENAMIENTO POR AGENTES CON ACCION PRINCIPAL SOBRE LOS MUSCULOS LISOS Y ESQUELETICOS Y SOBRE EL SISTEMA RESPIRATORIO: ANTITUSIGENOS"},
{ value: "T484",	text: "ENVENENAMIENTO POR AGENTES CON ACCION PRINCIPAL SOBRE LOS MUSCULOS LISOS Y ESQUELETICOS Y SOBRE EL SISTEMA RESPIRATORIO: EXPECTORANTES"},
{ value: "T485",	text: "ENVENENAMIENTO POR AGENTES CON ACCION PRINCIPAL SOBRE LOS MUSCULOS LISOS Y ESQUELETICOS Y SOBRE EL SISTEMA RESPIRATORIO: DROGAS CONTRA EL CATARRO COMUN"},
{ value: "T486",	text: "ENVENENAMIENTO POR AGENTES CON ACCION PRINCIPAL SOBRE LOS MUSCULOS LISOS Y ESQUELETICOS Y SOBRE EL SISTEMA RESPIRATORIO: ANTIASMATICOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T487",	text: "ENVENENAMIENTO POR AGENTES CON ACCION PRINCIPAL SOBRE LOS MUSCULOS LISOS Y ESQUELETICOS Y SOBRE EL SISTEMA RESPIRATORIO: OTROS AGENTES Y LOS NO ESPECIFICADOS DE ACCION PRINCIPAL SOBRE EL SISTEMA RESPIRATORIO"},
{ value: "T490",	text: "ENVENENAMIENTO POR AGENTES TOPICOS QUE AFECTAN PRINCIPALMENTE LA PIEL Y LAS MEMBRANAS MUCOSAS Y POR DROGAS OFTALMOLOGICAS, OTORRINOLARINGOLOGICAS Y DENTALES: DROGAS LOCALES ANTIMICOTICAS, ANTIINFECCIOSAS Y ANTIINFLAMATORIAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "T491",	text: "ENVENENAMIENTO POR AGENTES TOPICOS QUE AFECTAN PRINCIPALMENTE LA PIEL Y LAS MEMBRANAS MUCOSAS Y POR DROGAS OFTALMOLOGICAS, OTORRINOLARINGOLOGICAS Y DENTALES: ANTIPRURITICOS"},
{ value: "T492",	text: "ENVENENAMIENTO POR AGENTES TOPICOS QUE AFECTAN PRINCIPALMENTE LA PIEL Y LAS MEMBRANAS MUCOSAS Y POR DROGAS OFTALMOLOGICAS, OTORRINOLARINGOLOGICAS Y DENTALES: ASTRINGENTES Y DETERGENTES LOCALES"},
{ value: "T493",	text: "ENVENENAMIENTO POR AGENTES TOPICOS QUE AFECTAN PRINCIPALMENTE LA PIEL Y LAS MEMBRANAS MUCOSAS Y POR DROGAS OFTALMOLOGICAS, OTORRINOLARINGOLOGICAS Y DENTALES: EMOLIENTES, DEMULCENTES Y PROTECTORES"},
{ value: "T494",	text: "ENVENENAMIENTO POR AGENTES TOPICOS QUE AFECTAN PRINCIPALMENTE LA PIEL Y LAS MEMBRANAS MUCOSAS Y POR DROGAS OFTALMOLOGICAS, OTORRINOLARINGOLOGICAS Y DENTALES: QUERATOLITICOS, QUERATOPLASTICOS, DROGAS Y OTRAS PREPARACIONES PARA EL TRATAMIENTO DEL CABELLO"},
{ value: "T495",	text: "ENVENENAMIENTO POR AGENTES TOPICOS QUE AFECTAN PRINCIPALMENTE LA PIEL Y LAS MEMBRANAS MUCOSAS Y POR DROGAS OFTALMOLOGICAS, OTORRINOLARINGOLOGICAS Y DENTALES: DROGAS Y PREPARACIONES OFTALMOLOGICAS"},
{ value: "T496",	text: "ENVENENAMIENTO POR AGENTES TOPICOS QUE AFECTAN PRINCIPALMENTE LA PIEL Y LAS MEMBRANAS MUCOSAS Y POR DROGAS OFTALMOLOGICAS, OTORRINOLARINGOLOGICAS Y DENTALES: DROGAS Y PREPARACIONES OTORRINOLARINGOLOGICAS"},
{ value: "T497",	text: "ENVENENAMIENTO POR AGENTES TOPICOS QUE AFECTAN PRINCIPALMENTE LA PIEL Y LAS MEMBRANAS MUCOSAS Y POR DROGAS OFTALMOLOGICAS, OTORRINOLARINGOLOGICAS Y DENTALES: DROGAS DENTALES, APLICADAS TOPICAMENTE"},
{ value: "T498",	text: "ENVENENAMIENTO POR AGENTES TOPICOS QUE AFECTAN PRINCIPALMENTE LA PIEL Y LAS MEMBRANAS MUCOSAS Y POR DROGAS OFTALMOLOGICAS, OTORRINOLARINGOLOGICAS Y DENTALES: OTROS AGENTES TOPICOS"},
{ value: "T499",	text: "ENVENENAMIENTO POR AGENTES TOPICOS QUE AFECTAN PRINCIPALMENTE LA PIEL Y LAS MEMBRANAS MUCOSAS Y POR DROGAS OFTALMOLOGICAS, OTORRINOLARINGOLOGICAS Y DENTALES: AGENTES TOPICOS, NO ESPECIFICADOS"},
{ value: "T500",	text: "ENVENENAMIENTO POR DIURETICOS Y OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS: MINERALOCORTICOIDES Y SUS ANTAGONISTAS"},
{ value: "T501",	text: "ENVENENAMIENTO POR DIURETICOS Y OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS: DIURETICOS DEL ASA [DINTEL ALTO]"},
{ value: "T502",	text: "ENVENENAMIENTO POR DIURETICOS Y OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS: INHIBIDORES DE LA ANHIDRASA DEL ACIDO CARBONICO, BENZOTIAZIDAS Y OTROS DIURETICOS"},
{ value: "T503",	text: "ENVENENAMIENTO POR DIURETICOS Y OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS: AGENTES DEL EQUILIBRIO HIDROELECTROLITICO, ELECTROLITICO Y CALORICO"},
{ value: "T504",	text: "ENVENENAMIENTO POR DIURETICOS Y OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS: DROGAS QUE AFECTAN EL METABOLISMO DEL ACIDO URICO"},
{ value: "T505",	text: "ENVENENAMIENTO POR DIURETICOS Y OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS: DEPRESORES DEL APETITO"},
{ value: "T506",	text: "ENVENENAMIENTO POR DIURETICOS Y OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS: ANTIDOTOS Y AGENTES QUELANTES, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T507",	text: "ENVENENAMIENTO POR DIURETICOS Y OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS: ANALEPTICOS Y ANTAGONISTAS DEL OPIO"},
{ value: "T508",	text: "ENVENENAMIENTO POR DIURETICOS Y OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS: AGENTES DIAGNOSTICOS"},
{ value: "T509",	text: "ENVENENAMIENTO POR DIURETICOS Y OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS: OTRAS DROGAS Y SUSTANCIAS BIOLOGICAS, Y LAS NO ESPECIFICADAS"},
{ value: "T510",	text: "EFECTO TOXICO DEL ALCOHOL: ETANOL"},
{ value: "T511",	text: "EFECTO TOXICO DEL ALCOHOL: METANOL"},
{ value: "T512",	text: "EFECTO TOXICO DEL ALCOHOL: PROPANOL-2"},
{ value: "T513",	text: "EFECTO TOXICO DEL ALCOHOL: LICOR DE ALCOHOL INSUFICIENTEMENTE DESTILADO"},
{ value: "T518",	text: "EFECTO TOXICO DEL ALCOHOL: OTROS ALCOHOLES"},
{ value: "T519",	text: "EFECTO TOXICO DEL ALCOHOL: ALCOHOL, NO ESPECIFICADO"},
{ value: "T520",	text: "EFECTO TOXICO DE DISOLVENTES ORGANICOS: PRODUCTOS DEL PETROLEO"},
{ value: "T521",	text: "EFECTO TOXICO DE DISOLVENTES ORGANICOS: BENCENO"},
{ value: "T522",	text: "EFECTO TOXICO DE DISOLVENTES ORGANICOS: HOMOLOGOS DEL BENCENO"},
{ value: "T523",	text: "EFECTO TOXICO DE DISOLVENTES ORGANICOS: GLICOLES"},
{ value: "T524",	text: "EFECTO TOXICO DE DISOLVENTES ORGANICOS: CETONAS"},
{ value: "T528",	text: "EFECTO TOXICO DE DISOLVENTES ORGANICOS: OTROS DISOLVENTES ORGANICOS"},
{ value: "T529",	text: "EFECTO TOXICO DE DISOLVENTES ORGANICOS: DISOLVENTES ORGANICOS, NO ESPECIFICADOS"},
{ value: "T530",	text: "EFECTO TOXICO DE LOS DERIVADOS HALOGENADOS DE LOS HIDROCARBUROS ALIFATICOS Y AROMATICOS: TETRACLORURO DE CARBONO"},
{ value: "T531",	text: "EFECTO TOXICO DE LOS DERIVADOS HALOGENADOS DE LOS HIDROCARBUROS ALIFATICOS Y AROMATICOS: CLOROFORMO"},
{ value: "T532",	text: "EFECTO TOXICO DE LOS DERIVADOS HALOGENADOS DE LOS HIDROCARBUROS ALIFATICOS Y AROMATICOS: TRICLOROETILENO"},
{ value: "T533",	text: "EFECTO TOXICO DE LOS DERIVADOS HALOGENADOS DE LOS HIDROCARBUROS ALIFATICOS Y AROMATICOS: TETRACLOROETILENO"},
{ value: "T534",	text: "EFECTO TOXICO DE LOS DERIVADOS HALOGENADOS DE LOS HIDROCARBUROS ALIFATICOS Y AROMATICOS: DICLOROETANO"},
{ value: "T535",	text: "EFECTO TOXICO DE LOS DERIVADOS HALOGENADOS DE LOS HIDROCARBUROS ALIFATICOS Y AROMATICOS: CLOROFLUORCARBUROS"},
{ value: "T536",	text: "EFECTO TOXICO DE LOS DERIVADOS HALOGENADOS DE LOS HIDROCARBUROS ALIFATICOS Y AROMATICOS: OTROS DERIVADOS HALOGENADOS DE LOS HIDROCARBUROS ALIFATICOS"},
{ value: "T537",	text: "EFECTO TOXICO DE LOS DERIVADOS HALOGENADOS DE LOS HIDROCARBUROS ALIFATICOS Y AROMATICOS: OTROS DERIVADOS HALOGENADOS DE LOS HIDROCARBUROS AROMATICOS"},
{ value: "T539",	text: "EFECTO TOXICO DE LOS DERIVADOS HALOGENADOS DE LOS HIDROCARBUROS ALIFATICOS Y AROMATICOS: DERIVADOS HALOGENADOS DE HIDROCARBUROS ALIFATICOS Y AROMATICOS, NO ESPECIFICADOS"},
{ value: "T540",	text: "EFECTO TOXICO DE SUSTANCIAS CORROSIVAS: FENOL Y HOMOLOGOS DEL FENOL"},
{ value: "T541",	text: "EFECTO TOXICO DE SUSTANCIAS CORROSIVAS: OTROS COMPUESTOS ORGANICOS CORROSIVOS"},
{ value: "T542",	text: "EFECTO TOXICO DE SUSTANCIAS CORROSIVAS: ACIDOS CORROSIVOS Y SUSTANCIAS ACIDAS SIMILARES"},
{ value: "T543",	text: "EFECTO TOXICO DE SUSTANCIAS CORROSIVAS: ALCALIS CAUSTICOS Y SUSTANCIAS ALCALINAS SIMILARES"},
{ value: "T549",	text: "EFECTO TOXICO DE SUSTANCIAS CORROSIVAS: EFECTO TOXICO DE SUSTANCIA CORROSIVA, NO ESPECIFICADA"},
{ value: "T55X",	text: "EFECTO TOXICO DE DETERGENTES Y JABONES"},
{ value: "T560",	text: "EFECTO TOXICO DE METALES: PLOMO Y SUS COMPUESTOS"},
{ value: "T561",	text: "EFECTO TOXICO DE METALES: MERCURIO Y SUS COMPUESTOS"},
{ value: "T562",	text: "EFECTO TOXICO DE METALES: CROMO Y SUS COMPUESTOS"},
{ value: "T563",	text: "EFECTO TOXICO DE METALES: CADMIO Y SUS COMPUESTOS"},
{ value: "T564",	text: "EFECTO TOXICO DE METALES: COBRE Y SUS COMPUESTOS"},
{ value: "T565",	text: "EFECTO TOXICO DE METALES: ZINC Y SUS COMPUESTOS"},
{ value: "T566",	text: "EFECTO TOXICO DE METALES: ESTAÑO Y SUS COMPUESTOS"},
{ value: "T567",	text: "EFECTO TOXICO DE METALES: BERILIO Y SUS COMPUESTOS"},
{ value: "T568",	text: "EFECTO TOXICO DE METALES: OTROS METALES"},
{ value: "T569",	text: "EFECTO TOXICO DE METALES: METAL, NO ESPECIFICADO"},
{ value: "T570",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS INORGANICAS: ARSENICO Y SUS COMPUESTOS"},
{ value: "T571",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS INORGANICAS: FOSFORO Y SUS COMPUESTOS"},
{ value: "T572",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS INORGANICAS: MANGANESO Y SUS COMPUESTOS"},
{ value: "T573",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS INORGANICAS: ACIDO CIANHIDRICO"},
{ value: "T578",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS INORGANICAS: OTRAS SUSTANCIAS INORGANICAS, ESPECIFICADAS"},
{ value: "T579",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS INORGANICAS: SUSTANCIA INORGANICAS, NO ESPECIFICADA"},
{ value: "T58X",	text: "EFECTO TOXICO DEL MONOXIDO DE CARBONO"},
{ value: "T590",	text: "EFECTO TOXICO DE GASES, HUMOS Y VAPORES: OXIDOS DE NITROGENO"},
{ value: "T591",	text: "EFECTO TOXICO DE GASES, HUMOS Y VAPORES: DIOXIDO DE SULFURO"},
{ value: "T592",	text: "EFECTO TOXICO DE GASES, HUMOS Y VAPORES: FORMALDEHIDO"},
{ value: "T593",	text: "EFECTO TOXICO DE GASES, HUMOS Y VAPORES: GAS LACRIMOGENO"},
{ value: "T594",	text: "EFECTO TOXICO DE GASES, HUMOS Y VAPORES: CLORO GASEOSO"},
{ value: "T595",	text: "EFECTO TOXICO DE GASES, HUMOS Y VAPORES: GAS DE FLUOR Y FLUORURO DE HIDROGENO"},
{ value: "T596",	text: "EFECTO TOXICO DE GASES, HUMOS Y VAPORES: SULFURO DE HIDROGENO"},
{ value: "T597",	text: "EFECTO TOXICO DE GASES, HUMOS Y VAPORES: DIOXIDO DE CARBONO"},
{ value: "T598",	text: "EFECTO TOXICO DE GASES, HUMOS Y VAPORES: OTROS GASES, HUMOS Y VAPORES ESPECIFICADOS"},
{ value: "T599",	text: "EFECTO TOXICO DE GASES, HUMOS Y VAPORES: GASES, HUMOS Y VAPORES NO ESPECIFICADOS"},
{ value: "T600",	text: "EFECTO TOXICO DE PLAGUICIDAS [PESTICIDAS]: INSECTICIDAS FOSFORADOS Y CARBAMATOS"},
{ value: "T601",	text: "EFECTO TOXICO DE PLAGUICIDAS [PESTICIDAS]: INSECTICIDAS HALOGENADOS"},
{ value: "T602",	text: "EFECTO TOXICO DE PLAGUICIDAS [PESTICIDAS]: OTROS INSECTICIDAS"},
{ value: "T603",	text: "EFECTO TOXICO DE PLAGUICIDAS [PESTICIDAS]: HERBICIDAS Y FUNGICIDAS"},
{ value: "T604",	text: "EFECTO TOXICO DE PLAGUICIDAS [PESTICIDAS]: RODENTICIDAS"},
{ value: "T608",	text: "EFECTO TOXICO DE PLAGUICIDAS [PESTICIDAS]: OTROS PLAGUICIDAS"},
{ value: "T609",	text: "EFECTO TOXICO DE PLAGUICIDAS [PESTICIDAS]: PLAGUICIDA NO ESPECIFICADO"},
{ value: "T610",	text: "EFECTO TOXICO DE SUSTANCIAS NOCIVAS INGERIDAS COMO ALIMENTOS MARINOS: ENVENENAMIENTO CIGUATERO POR PESCADO"},
{ value: "T611",	text: "EFECTO TOXICO DE SUSTANCIAS NOCIVAS INGERIDAS COMO ALIMENTOS MARINOS: ENVENENAMIENTO ESCOMBROIDEO POR PESCADO"},
{ value: "T612",	text: "EFECTO TOXICO DE SUSTANCIAS NOCIVAS INGERIDAS COMO ALIMENTOS MARINOS: OTROS ENVENENAMIENTOS POR PESCADO Y MARISCOS"},
{ value: "T618",	text: "EFECTO TOXICO DE SUSTANCIAS NOCIVAS INGERIDAS COMO ALIMENTOS MARINOS: EFECTO TOXICO DE OTROS ALIMENTOS MARINOS"},
{ value: "T619",	text: "EFECTO TOXICO DE SUSTANCIAS NOCIVAS INGERIDAS COMO ALIMENTOS MARINOS: EFECTO TOXICO DE ALIMENTOS MARINOS NO ESPECIFICADOS"},
{ value: "T620",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS NOCIVAS INGERIDAS COMO ALIMENTO: HONGOS INGERIDOS"},
{ value: "T621",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS NOCIVAS INGERIDAS COMO ALIMENTO: BAYAS INGERIDAS"},
{ value: "T622",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS NOCIVAS INGERIDAS COMO ALIMENTO: OTRA(S) (PARTES DE) PLANTA(S) INGERIDA(S)"},
{ value: "T628",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS NOCIVAS INGERIDAS COMO ALIMENTO: OTRAS SUSTANCIAS NOCIVAS INGERIDAS COMO ALIMENTO"},
{ value: "T629",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS NOCIVAS INGERIDAS COMO ALIMENTO: SUSTANCIA NOCIVA INGERIDA COMO ALIMENTO, NO ESPECIFICADA"},
{ value: "T630",	text: "EFECTO TOXICO DEL CONTACTO CON ANIMALES VENENOSOS: VENENO DE SERPIENTE"},
{ value: "T631",	text: "EFECTO TOXICO DEL CONTACTO CON ANIMALES VENENOSOS: VENENO DE OTROS REPTILES"},
{ value: "T632",	text: "EFECTO TOXICO DEL CONTACTO CON ANIMALES VENENOSOS: VENENO DE ESCORPION"},
{ value: "T633",	text: "EFECTO TOXICO DEL CONTACTO CON ANIMALES VENENOSOS: VENENO DE ARAÑAS"},
{ value: "T634",	text: "EFECTO TOXICO DEL CONTACTO CON ANIMALES VENENOSOS: VENENO DE OTROS ARTROPODOS"},
{ value: "T635",	text: "EFECTO TOXICO DEL CONTACTO CON ANIMALES VENENOSOS: EFECTO TOXICO DEL CONTACTO CON PECES"},
{ value: "T636",	text: "EFECTO TOXICO DEL CONTACTO CON ANIMALES VENENOSOS: EFECTO TOXICO DEL CONTACTO CON OTROS ANIMALES MARINOS"},
{ value: "T638",	text: "EFECTO TOXICO DEL CONTACTO CON ANIMALES VENENOSOS: EFECTO TOXICO DEL CONTACTO CON OTROS ANIMALES VENENOSOS"},
{ value: "T639",	text: "EFECTO TOXICO DEL CONTACTO CON ANIMALES VENENOSOS: EFECTO TOXICO DEL CONTACTO CON ANIMAL VENENOSO NO ESPECIFICADO"},
{ value: "T64X",	text: "EFECTO TOXICO DE AFLATOXINA Y OTRAS MICOTOXINAS CONTAMINANTES DE ALIMENTOS"},
{ value: "T650",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS Y LAS NO ESPECIFICADAS: CIANURO"},
{ value: "T651",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS Y LAS NO ESPECIFICADAS: ESTRICNINA Y SUS SALES"},
{ value: "T652",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS Y LAS NO ESPECIFICADAS: TABACO Y NICOTINA"},
{ value: "T653",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS Y LAS NO ESPECIFICADAS: NITRODERIVADOS Y AMINODERIVADOS DEL BENCENO Y SUS HOMOLOGOS"},
{ value: "T654",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS Y LAS NO ESPECIFICADAS: BISULFURO DE CARBONO"},
{ value: "T655",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS Y LAS NO ESPECIFICADAS: NITROGLICERINA Y OTROS ACIDOS Y ESTERES NITRICOS"},
{ value: "T656",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS Y LAS NO ESPECIFICADAS: PINTURAS Y COLORANTES, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T658",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS Y LAS NO ESPECIFICADAS: EFECTOS TOXICOS DE OTRAS SUSTANCIAS ESPECIFICADAS"},
{ value: "T659",	text: "EFECTO TOXICO DE OTRAS SUSTANCIAS Y LAS NO ESPECIFICADAS: EFECTO TOXICO DE SUSTANCIA NO ESPECIFICADA"},
{ value: "T66X",	text: "EFECTOS NO ESPECIFICADOS DE LA RADIACION"},
{ value: "T670",	text: "GOLPE DE CALOR E INSOLACION"},
{ value: "T671",	text: "SINCOPE DE CALOR"},
{ value: "T672",	text: "CALAMBRE POR CALOR"},
{ value: "T673",	text: "AGOTAMIENTO POR CALOR, ANHIDROTICO"},
{ value: "T674",	text: "AGOTAMIENTO POR CALOR DEBIDO A DEPLECION DE SAL"},
{ value: "T675",	text: "AGOTAMIENTO POR CALOR NO ESPECIFICADO"},
{ value: "T676",	text: "FATIGA POR CALOR, TRANSITORIA"},
{ value: "T677",	text: "EDEMA POR CALOR"},
{ value: "T678",	text: "OTROS EFECTOS DEL CALOR Y DE LA LUZ"},
{ value: "T679",	text: "EFECTO DEL CALOR Y DE LA LUZ, NO ESPECIFICADO"},
{ value: "T68X",	text: "HIPOTERMIA"},
{ value: "T690",	text: "MANO Y PIE DE INMERSION"},
{ value: "T691",	text: "SABAÑON(ES)"},
{ value: "T698",	text: "OTROS EFECTOS ESPECIFICADOS DE LA REDUCCION DE LA TEMPERATURA"},
{ value: "T699",	text: "EFECTO DE LA REDUCCION DE LA TEMPERATURA, NO ESPECIFICADO"},
{ value: "T700",	text: "BAROTRAUMA OTITICO"},
{ value: "T701",	text: "BAROTRAUMA SINUSAL"},
{ value: "T702",	text: "OTROS EFECTOS Y LOS NO ESPECIFICADOS DE LA GRAN ALTITUD"},
{ value: "T703",	text: "ENFERMEDAD POR DESCOMPRESION [DE LOS CAJONES SUMERGIDOS]"},
{ value: "T704",	text: "EFECTOS DE LIQUIDOS CON ALTA PRESION"},
{ value: "T708",	text: "OTROS EFECTOS DE LA PRESION DEL AIRE Y DEL AGUA"},
{ value: "T709",	text: "EFECTO DE LA PRESION DEL AIRE Y DEL AGUA, NO ESPECIFICADO"},
{ value: "T71X",	text: "ASFIXIA"},
{ value: "T730",	text: "EFECTOS DEL HAMBRE"},
{ value: "T731",	text: "EFECTOS DE LA SED"},
{ value: "T732",	text: "AGOTAMIENTO DEBIDO A EXPOSICION A LA INTEMPERIE"},
{ value: "T733",	text: "AGOTAMIENTO DEBIDO A ESFUERZO EXCESIVO"},
{ value: "T738",	text: "OTROS EFECTOS DE PRIVACION"},
{ value: "T739",	text: "EFECTOS DE PRIVACION, NO ESPECIFICADOS"},
{ value: "T740",	text: "NEGLIGENCIA O ABANDONO"},
{ value: "T741",	text: "ABUSO FISICO"},
{ value: "T742",	text: "ABUSO SEXUAL"},
{ value: "T743",	text: "ABUSO PSICOLOGICO"},
{ value: "T748",	text: "OTROS SINDROMES DE MALTRATO"},
{ value: "T749",	text: "SINDROME DE MALTRATO, NO ESPECIFICADO"},
{ value: "T750",	text: "EFECTOS DEL RAYO"},
{ value: "T751",	text: "AHOGAMIENTO Y SUMERSION NO MORTAL"},
{ value: "T752",	text: "EFECTOS DE LA VIBRACION"},
{ value: "T753",	text: "MAL DEL MOVIMIENTO"},
{ value: "T754",	text: "EFECTOS DE LA CORRIENTE ELECTRICA"},
{ value: "T758",	text: "OTROS EFECTOS ESPECIFICADOS DE CAUSAS EXTERNAS"},
{ value: "T780",	text: "CHOQUE ANAFILACTICO DEBIDO A REACCION ADVERSA A ALIMENTOS"},
{ value: "T781",	text: "OTRA REACCION ADVERSA A ALIMENTOS, NO CLASIFICADA EN OTRA PARTE"},
{ value: "T782",	text: "CHOQUE ANAFILACTICO, NO ESPECIFICADO"},
{ value: "T783",	text: "EDEMA ANGIONEUROTICO"},
{ value: "T784",	text: "ALERGIA NO ESPECIFICADA"},
{ value: "T788",	text: "OTROS EFECTOS ADVERSOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T789",	text: "EFECTOS ADVERSOS NO ESPECIFICADO"},
{ value: "T790",	text: "EMBOLIA GASEOSA (TRAUMATICA)"},
{ value: "T791",	text: "EMBOLIA GRASA (TRAUMATICA)"},
{ value: "T792",	text: "HEMORRAGIA TRAUMATICA SECUNDARIA Y RECURRENTE"},
{ value: "T793",	text: "INFECCION POSTRAUMATICA DE HERIDA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "T794",	text: "CHOQUE TRAUMATICO"},
{ value: "T795",	text: "ANURIA TRAUMATICA"},
{ value: "T796",	text: "ISQUEMIA TRAUMATICA DEL MUSCULO"},
{ value: "T797",	text: "ENFISEMA SUBCUTANEO TRAUMATICO"},
{ value: "T798",	text: "OTRAS COMPLICACIONES PRECOCES DE LOS TRAUMATISMOS"},
{ value: "T799",	text: "COMPLICACIONES PRECOCES NO ESPECIFICADAS DE LOS TRAUMATISMOS"},
{ value: "T800",	text: "EMBOLIA GASEOSA CONSECUTIVAS A INFUSION, TRANSFUSION E INYECCION TERAPEUTICA"},
{ value: "T801",	text: "COMPLICACIONES VASCULARES CONSECUTIVAS A INFUSION, TRANSFUSION E INYECCION TERAPEUTICA"},
{ value: "T802",	text: "INFECCIONES CONSECUTIVAS A INFUSION, TRANSFUSION E INYECCION TERAPEUTICA"},
{ value: "T803",	text: "REACCION DE INCOMPATIBILIDAD AL GRUPO ABO"},
{ value: "T804",	text: "REACCION DE INCOMPATIBILIDAD A Rh"},
{ value: "T805",	text: "CHOQUE ANAFILACTICO DEBIDO A SUERO"},
{ value: "T806",	text: "OTRAS REACCIONES AL SUERO"},
{ value: "T808",	text: "OTRAS COMPLICACIONES CONSECUTIVAS A INFUSION, TRANSFUSION E INYECCION TERAPEUTICA"},
{ value: "T809",	text: "COMPLICACIONES NO ESPECIFICADAS CONSECUTIVAS A INFUSION, TRANSFUSION E INYECCION TERAPEUTICA"},
{ value: "T810",	text: "HEMORRAGIA Y HEMATOMA QUE COMPLICAN UN PROCEDIMIENTO, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T811",	text: "CHOQUE DURANTE O RESULTANTE DE UN PROCEDIMIENTO, NO CLASIFICADO EN OTRA PARTE"},
{ value: "T812",	text: "PUNCION O LACERACION ACCIDENTAL DURANTE UN PROCEDIMIENTO, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "T813",	text: "DESGARRO DE HERIDA OPERATORIA, NO CLASIFICADO EN OTRA PARTE"},
{ value: "T814",	text: "INFECCION CONSECUTIVA A PROCEDIMIENTO, NO CLASIFICADA EN OTRA PARTE"},
{ value: "T815",	text: "CUERPO EXTRAÑO DEJADO ACCIDENTALMENTE EN CAVIDAD CORPORAL O EN HERIDA OPERATORIA CONSECUTIVA A PROCEDIMIENTO"},
{ value: "T816",	text: "REACCIÓN AGUDA A SUSTANCIA EXTRAÑA DEJADA ACCIDENTALMENTE DURANTE UN PROCEDIMIENTO"},
{ value: "T817",	text: "COMPLICACIONES VASCULARES CONSECUTIVAS A PROCEDIMIENTOS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "T818",	text: "OTRAS COMPLICACIONES DE PROCEDIMIENTOS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "T819",	text: "COMPLICACIONES DE PROCEDIMIENTOS, NO ESPECIFICADA"},
{ value: "T820",	text: "COMPLICACION MECANICA DE PROTESIS DE VALVULA CARDIACA"},
{ value: "T821",	text: "COMPLICACION MECANICA DE DISPOSITIVO ELECTRONICO CARDIACO"},
{ value: "T822",	text: "COMPLICACION MECANICA DE DERIVACION DE ARTERIA CORONARIA E INJERTO VASCULAR"},
{ value: "T823",	text: "COMPLICACION MECANICA DE OTROS INJERTOS VASCULARES"},
{ value: "T824",	text: "COMPLICACION MECANICA DE CATETER PARA DIALISIS VASCULAR"},
{ value: "T825",	text: "COMPLICACION MECANICA DE OTROS DISPOSITIVOS E IMPLANTES CARDIOVASCULARES"},
{ value: "T826",	text: "INFECCION Y REACCION INFLAMATORIA DEBIDAS A PROTESIS DE VALVULA CARDIACA"},
{ value: "T827",	text: "INFECCION Y REACCION INFLAMATORIA DEBIDAS A OTROS DISPOSITIVOS, IMPLANTES E INJERTOS CARDIOVASCULARES"},
{ value: "T828",	text: "OTRAS COMPLICACIONES DE DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS CARDIOVASCULARES"},
{ value: "T829",	text: "COMPLICACION NO ESPECIFICADA DE DISPOSITIVO PROTESICO, IMPLANTE E INJERTO CARDIOVASCULAR"},
{ value: "T830",	text: "COMPLICACION MECANICA DE CATETER URINARIO (FIJO)"},
{ value: "T831",	text: "COMPLICACION MECANICA DE OTROS DISPOSITIVOS E IMPLANTES URINARIOS"},
{ value: "T832",	text: "COMPLICACION MECANICA DE INJERTO EN ORGANO URINARIO"},
{ value: "T833",	text: "COMPLICACION MECANICA DE DISPOSITIVO ANTICONCEPTIVO INTRAUTERINO"},
{ value: "T834",	text: "COMPLICACION MECANICA DE OTROS DISPOSITIVOS, IMPLANTES E INJERTOS EN EL TRACTO GENITAL"},
{ value: "T835",	text: "INFECCION Y REACCION INFLAMATORIA DEBIDAS A DISPOSITIVO PROTESICO, IMPLANTE E INJERTO EN EL SISTEMA URINARIO"},
{ value: "T836",	text: "INFECCION Y REACCION INFLAMATORIA DEBIDAS A DISPOSITIVO PROTESICO, IMPLANTE E INJERTO EN EL TRACTO GENITAL"},
{ value: "T838",	text: "OTRAS COMPLICACIONES DE DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS GENITOURINARIOS:"},
{ value: "T839",	text: "COMPLICACION NO ESPECIFICADA DE DISPOSITIVO PROTESICO, IMPLANTE E INJERTO GENITOURINARIO"},
{ value: "T840",	text: "COMPLICACION MECANICA DE PROTESIS ARTICULAR INTERNA"},
{ value: "T841",	text: "COMPLICACION MECANICA DE DISPOSITIVO DE FIJACION INTERNA DE HUESOS DE UN MIEMBRO"},
{ value: "T842",	text: "COMPLICACION MECANICA DE DISPOSITIVO DE FIJACION INTERNA DE OTROS HUESOS"},
{ value: "T843",	text: "COMPLICACION MECANICA DE OTROS DISPOSITIVOS OSEOS, IMPLANTES E INJERTOS"},
{ value: "T844",	text: "COMPLICACION MECANICA DE OTROS DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS ORTOPEDICOS INTERNOS"},
{ value: "T845",	text: "INFECCION Y REACCION INFLAMATORIA DEBIDAS A PROTESIS ARTICULAR INTERNA"},
{ value: "T846",	text: "INFECCION Y REACCION INFLAMATORIA DEBIDAS A DISPOSITIVO DE FIJACION INTERNA (CUALQUIER SITIO)"},
{ value: "T847",	text: "INFECCION Y REACCION INFLAMATORIA DEBIDAS A OTROS DISPOSITIVO DE FIJACION INTERNA (CUALQUIER SITIO) DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS ORTOPEDICOS INTERNOS"},
{ value: "T848",	text: "OTRAS COMPLICACIONES DE DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS ORTOPEDICOS INTERNOS"},
{ value: "T849",	text: "COMPLICACIONES NO ESPECIFICADAS DE DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS ORTOPEDICOS INTERNOS"},
{ value: "T850",	text: "COMPLICACION MECANICA DE DERIVACION (ANASTOMOTICA) VENTRICULAR INTRACRANEAL"},
{ value: "T851",	text: "COMPLICACION MECANICA DE IMPLANTE DE ESTIMULADOR ELECTRONICO DEL SISTEMA NERVIOSO"},
{ value: "T852",	text: "COMPLICACION MECANICA DE LENTES INTRAOCULARES"},
{ value: "T853",	text: "COMPLICACION MECANICA DE OTROS DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS OCULARES"},
{ value: "T854",	text: "COMPLICACION MECANICA DE PROTESIS E IMPLANTE DE MAMA"},
{ value: "T855",	text: "COMPLICACION MECANICA DE DISPOSITIVO PROTESICO, IMPLANTE E INJERTO GASTROINTESTINAL"},
{ value: "T856",	text: "COMPLICACION MECANICA DE OTROS DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS INTERNOS ESPECIFICADOS"},
{ value: "T857",	text: "INFECCION Y REACCION INFLAMATORIA DEBIDAS A OTROS DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS INTERNOS"},
{ value: "T858",	text: "OTRAS COMPLICACIONES DE OTROS DISPOSITIVOS PROTESICOS, IMPLANTES E INJERTOS INTERNOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "T859",	text: "COMPLICACION NO ESPECIFICADA DE DISPOSITIVO PROTESICO, IMPLANTE E INJERTO INTERNO"},
{ value: "T860",	text: "RECHAZO DE TRASPLANTE DE MEDULA OSEA"},
{ value: "T861",	text: "FALLA Y RECHAZO DE TRASPLANTE DE RIÑON"},
{ value: "T862",	text: "FALLA Y RECHAZO DE TRASPLANTE DE CORAZON"},
{ value: "T863",	text: "FALLA Y RECHAZO DE TRASPLANTE DE PULMON-CORAZON"},
{ value: "T864",	text: "FALLA Y RECHAZO DE TRASPLANTE DE HIGADO"},
{ value: "T868",	text: "FALLA Y RECHAZO DE OTROS ORGANOS Y TEJIDOS TRASPLANTADOS"},
{ value: "T869",	text: "FALLA Y RECHAZO DEL TRASPLANTE DE ORGANOS Y TEJIDOS NO ESPECIFICADO"},
{ value: "T870",	text: "COMPLICACIONES DE LA REINSERCION (DE PARTE) DE EXTREMIDAD SUPERIOR"},
{ value: "T871",	text: "COMPLICACIONES DE LA REINSERCION (DE PARTE) DE EXTREMIDAD INFERIOR"},
{ value: "T872",	text: "COMPLICACIONES DE OTRAS PARTES DEL CUERPO REINSERTADAS"},
{ value: "T873",	text: "NEUROMA DE MUÑON DE AMPUTACION"},
{ value: "T874",	text: "INFECCION DE MUÑON DE AMPUTACION"},
{ value: "T875",	text: "NECROSIS DE MUÑON DE AMPUTACION"},
{ value: "T876",	text: "OTRAS COMPLICACIONES Y LAS NO ESPECIFICADAS DE MUÑON DE AMPUTACION"},
{ value: "T880",	text: "INFECCION CONSECUTIVA A INMUNIZACION"},
{ value: "T881",	text: "OTRAS COMPLICACIONES CONSECUTIVAS A INMUNIZACION, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "T882",	text: "CHOQUE DEBIDA A ANESTESIA"},
{ value: "T883",	text: "HIPERTERMIA MALIGNA DEBIDA A ANESTESIA"},
{ value: "T884",	text: "FALLA O DIFICULTAD DE LA INTUBACION"},
{ value: "T885",	text: "OTRAS COMPLICACIONES DE LA ANESTESIA"},
{ value: "T886",	text: "CHOQUE ANAFILACTICO DEBIDO A EFECTO ADVERSO DE DROGA O MEDICAMENTO CORRECTO ADMINISTRADO APROPIADAMENTE"},
{ value: "T887",	text: "EFECTO ADVERSO NO ESPECIFICADO DE DROGA O MEDICAMENTO"},
{ value: "T888",	text: "OTRAS COMPLICACIONES ESPECIFICADAS DE LA ATENCION MEDICA Y QUIRURGICA, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "T889",	text: "COMPLICACIONES NO ESPECIFICADAS DE LA ATENCION MEDICA Y QUIRURGICA"},
{ value: "T900",	text: "SECUELAS DE TRAUMATISMO SUPERFICIAL DE LA CABEZA"},
{ value: "T901",	text: "SECUELAS DE HERIDA DE LA CABEZA"},
{ value: "T902",	text: "SECUELAS DE FRACTURA DEL CRANEO Y DE HUESOS FACIALES"},
{ value: "T903",	text: "SECUELAS DE TRAUMATISMO DE NERVIOS CRANEALES"},
{ value: "T904",	text: "SECUELAS DE TRAUMATISMO DEL OJO Y DE LA ORBITA"},
{ value: "T905",	text: "SECUELAS DE TRAUMATISMO INTRACRANEAL"},
{ value: "T908",	text: "SECUELAS DE OTROS TRAUMATISMOS ESPECIFICADOS DE LA CABEZA"},
{ value: "T909",	text: "SECUELAS DE TRAUMATISMO NO ESPECIFICADO DE LA CABEZA"},
{ value: "T910",	text: "SECUELAS DE TRAUMATISMO SUPERFICIAL Y HERIDAS DEL CUELLO Y DEL TRONCO"},
{ value: "T911",	text: "SECUELAS DE FRACTURA DE LA COLUMNA VERTEBRAL"},
{ value: "T912",	text: "SECUELAS DE OTRA FRACTURA DEL TORAX Y DE LA PELVIS"},
{ value: "T913",	text: "SECUELAS DE TRAUMATISMO DE LA MEDULA ESPINAL"},
{ value: "T914",	text: "SECUELAS DE TRAUMATISMO DE ORGANOS INTRATORACICOS"},
{ value: "T915",	text: "SECUELAS DE TRAUMATISMO DE ORGANOS INTRAABDOMINALES Y PELVICOS"},
{ value: "T918",	text: "SECUELAS DE OTROS TRAUMATISMOS ESPECIFICADOS DEL CUELLO Y DEL TRONCO"},
{ value: "T919",	text: "SECUELAS DE TRAUMATISMO NO ESPECIFICADOS DEL CUELLO Y DEL TRONCO"},
{ value: "T920",	text: "SECUELAS DE HERIDA DE MIEMBRO SUPERIOR"},
{ value: "T921",	text: "SECUELAS DE FRACTURA DEL BRAZO"},
{ value: "T922",	text: "SECUELAS DE FRACTURA DE LA MUÑECA Y DE LA MANO"},
{ value: "T923",	text: "SECUELAS DE LUXACION, TORCEDURA Y ESGUINCE DE MIEMBRO SUPERIOR"},
{ value: "T924",	text: "SECUELAS DE TRAUMATISMO DE NERVIO DE MIEMBRO SUPERIOR"},
{ value: "T925",	text: "SECUELAS DE TRAUMATISMO DE TENDON Y MUSCULO DE MIEMBRO SUPERIOR"},
{ value: "T926",	text: "SECUELAS DE APLASTAMIENTO Y AMPUTACION TRAUMATICAS DE MIEMBRO SUPERIOR"},
{ value: "T928",	text: "SECUELAS DE OTROS TRAUMATISMOS ESPECIFICADOS DE MIEMBRO SUPERIOR"},
{ value: "T929",	text: "SECUELAS DE TRAUMATISMO NO ESPECIFICADO DE MIEMBRO SUPERIOR"},
{ value: "T930",	text: "SECUELAS DE HERIDA DE MIEMBRO INFERIOR"},
{ value: "T931",	text: "SECUELAS DE FRACTURA DE FEMUR"},
{ value: "T932",	text: "SECUELAS DE OTRAS FRACTURAS DE MIEMBRO INFERIOR"},
{ value: "T933",	text: "SECUELAS DE LUXACION, TORCEDURA Y ESGUINCE DE MIEMBRO INFERIOR"},
{ value: "T934",	text: "SECUELAS DE TRAUMATISMO DE NERVIO DE MIEMBRO INFERIOR"},
{ value: "T935",	text: "SECUELAS DE TRAUMATISMO DE TENDON Y MUSCULO DE MIEMBRO INFERIOR"},
{ value: "T936",	text: "SECUELAS DE APLASTAMIENTO Y AMPUTACION TRAUMATICAS DE MIEMBRO INFERIOR"},
{ value: "T938",	text: "SECUELAS DE OTROS TRAUMATISMOS ESPECIFICADOS DE MIEMBRO INFERIOR"},
{ value: "T939",	text: "SECUELAS DE TRAUMATISMO NO ESPECIFICADO DE MIEMBRO INFERIOR"},
{ value: "T940",	text: "SECUELAS DE TRAUMATISMOS QUE AFECTAN MULTIPLES REGIONES DEL CUERPO"},
{ value: "T941",	text: "SECUELAS DE TRAUMATISMOS DE REGIONES NO ESPECIFICADAS DEL CUERPO"},
{ value: "T950",	text: "SECUELAS DE QUEMADURA, CORROSION Y CONGELAMIENTO DE LA CABEZA Y DEL CUELLO"},
{ value: "T951",	text: "SECUELAS DE QUEMADURA, CORROSION Y CONGELAMIENTO DEL TRONCO"},
{ value: "T952",	text: "SECUELAS DE QUEMADURA, CORROSION Y CONGELAMIENTO DE MIEMBRO SUPERIOR"},
{ value: "T953",	text: "SECUELAS DE QUEMADURA, CORROSION Y CONGELAMIENTO DE MIEMBRO INFERIOR"},
{ value: "T954",	text: "SECUELAS DE QUEMADURA Y CORROSION CLASIFICABLES SOLO DE ACUERDO CON LA EXTENSION DE LA SUPERFICIE DEL CUERPO AFECTADA"},
{ value: "T958",	text: "SECUELAS DE OTRAS QUEMADURAS, CORROSIONES Y CONGELAMIENTOS ESPECIFICADOS"},
{ value: "T959",	text: "SECUELAS DE QUEMADURA, CORROSION Y CONGELAMIENTO NO ESPECIFICADOS"},
{ value: "T96X",	text: "SECUELAS DE ENVENENAMIENTOS POR DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS"},
{ value: "T97X",	text: "SECUELAS DE EFECTOS TOXICOS DE SUSTANCIAS DE PROCEDENCIA PRINCIPALMENTE NO MEDICINAL"},
{ value: "T980",	text: "SECUELAS DE EFECTOS DE CUERPOS EXTRAÑOS QUE PENETRAN EN ORIFICIOS NATURALES"},
{ value: "T981",	text: "SECUELAS DE OTROS EFECTOS Y LOS NO ESPECIFICADOS DE CAUSAS EXTERNAS"},
{ value: "T982",	text: "SECUELAS DE CIERTAS COMPLICACIONES PRECOCES DE LOS TRAUMATISMOS"},
{ value: "T983",	text: "SECUELAS DE COMPLICACIONES DE LA ATENCION MEDICA Y QUIRURGICA, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "V010",	text: "PEATON LESIONADO POR COLISION CON VEHICULO DE PEDAL: ACCIDENTE NO DE TRANSITO"},
{ value: "V011",	text: "PEATON LESIONADO POR COLISION CON VEHICULO DE PEDAL: ACCIDENTE DE TRANSITO"},
{ value: "V019",	text: "PEATON LESIONADO POR COLISION CON VEHICULO DE PEDAL: ACCIDENTE NO ESPECIFICADO COMO DE TRANSITO O NO DE TRANSITO"},
{ value: "V020",	text: "PEATON LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: ACCIDENTE NO DE TRANSITO"},
{ value: "V021",	text: "PEATON LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: ACCIDENTE DE TRANSITO"},
{ value: "V029",	text: "PEATON LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: ACCIDENTE NO ESPECIFICADO COMO DE TRANSITO O NO DE TRANSITO"},
{ value: "V030",	text: "PEATON LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: ACCIDENTE NO DE TRANSITO"},
{ value: "V031",	text: "PEATON LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: ACCIDENTE DE TRANSITO"},
{ value: "V039",	text: "PEATON LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: NO ESPECIFICADO COMO DE TRANSITO O NO DE TRANSITO"},
{ value: "V040",	text: "PEATON LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: ACCIDENTE NO DE TRANSITO"},
{ value: "V041",	text: "PEATON LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: ACCIDENTE DE TRANSITO"},
{ value: "V049",	text: "PEATON LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: ACCIDENTE NO ESPECIFICADO COMO DE TRANSITO O NO DE TRANSITO"},
{ value: "V050",	text: "PEATON LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: ACCIDENTE NO DE TRANSITO"},
{ value: "V051",	text: "PEATON LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: ACCIDENTE DE TRANSITO"},
{ value: "V059",	text: "PEATON LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: ACCIDENTE NO ESPECIFICADO COMO DE TRANSITO O NO DE TRANSITO"},
{ value: "V060",	text: "PEATON LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: ACCIDENTE NO DE TRANSITO"},
{ value: "V061",	text: "PEATON LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: ACCIDENTE DE TRANSITO"},
{ value: "V069",	text: "PEATON LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: ACCIDENTE NO ESPECIFICADO COMO DE TRANSITO O NO DE TRANSITO"},
{ value: "V090",	text: "PEATON LESIONADO EN ACCIDENTE NO DE TRANSITO QUE INVOLUCRA OTROS VEHICULOS DE MOTOR, Y LOS NO ESPECIFICADOS"},
{ value: "V091",	text: "PEATON LESIONADO EN ACCIDENTE NO DE TRANSITO NO ESPECIFICADO"},
{ value: "V092",	text: "PEATON LESIONADO EN ACCIDENTE DE TRANSITO QUE INVOLUCRA OTROS VEHICULOS DE MOTOR, Y LOS NO ESPECIFICADOS"},
{ value: "V093",	text: "PEATON LESIONADO EN ACCIDENTE DE TRANSITO NO ESPECIFICADO"},
{ value: "V099",	text: "PEATON LESIONADO EN ACCIDENTE DE TRANSPORTE NO ESPECIFICADO"},
{ value: "V100",	text: "CICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V101",	text: "CICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V102",	text: "CICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V103",	text: "CICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V104",	text: "CICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V105",	text: "CICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V109",	text: "CICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V110",	text: "CICLISTA LESIONADO POR COLISION CON OTRO CICLISTA: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V111",	text: "CICLISTA LESIONADO POR COLISION CON OTRO CICLISTA: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V112",	text: "CICLISTA LESIONADO POR COLISION CON OTRO CICLISTA: NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V113",	text: "CICLISTA LESIONADO POR COLISION CON OTRO CICLISTA: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V114",	text: "CICLISTA LESIONADO POR COLISION CON OTRO CICLISTA: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V115",	text: "CICLISTA LESIONADO POR COLISION CON OTRO CICLISTA: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V119",	text: "CICLISTA LESIONADO POR COLISION CON OTRO CICLISTA: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V120",	text: "CICLISTA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V121",	text: "CICLISTA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V122",	text: "CICLISTA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V123",	text: "CICLISTA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V124",	text: "CICLISTA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V125",	text: "CICLISTA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V129",	text: "CICLISTA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V130",	text: "CICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V131",	text: "CICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V132",	text: "CICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V133",	text: "CICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V134",	text: "CICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V135",	text: "CICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V139",	text: "CICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V140",	text: "CICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V141",	text: "CICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V142",	text: "CICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V143",	text: "CICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V144",	text: "CICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V145",	text: "CICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V149",	text: "CICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V150",	text: "CICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V151",	text: "CICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V152",	text: "CICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V153",	text: "CICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V154",	text: "CICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V155",	text: "CICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V159",	text: "CICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V160",	text: "CICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V161",	text: "CICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V162",	text: "CICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V163",	text: "CICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V164",	text: "CICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V165",	text: "CICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V169",	text: "CICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V170",	text: "CICLISTA LESIONADO POR COLISION CON OBJETO ESTACIONADO O FIJO: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V171",	text: "CICLISTA LESIONADO POR COLISION CON OBJETO ESTACIONADO O FIJO: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V172",	text: "CICLISTA LESIONADO POR COLISION CON OBJETO ESTACIONADO O FIJO: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V173",	text: "CICLISTA LESIONADO POR COLISION CON OBJETO ESTACIONADO O FIJO: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V174",	text: "CICLISTA LESIONADO POR COLISION CON OBJETO ESTACIONADO O FIJO CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V175",	text: "CICLISTA LESIONADO POR COLISION CON OBJETO ESTACIONADO O FIJO: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V179",	text: "CICLISTA LESIONADO POR COLISION CON OBJETO ESTACIONADO O FIJO: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V180",	text: "CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V181",	text: "CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V182",	text: "CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V183",	text: "CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V184",	text: "CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V185",	text: "CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V189",	text: "CICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: CICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V190",	text: "CONDUCTOR DE VEHICULO DE PEDAL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V191",	text: "PASAJERO DE VEHICULO DE PEDAL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V192",	text: "CICLISTA NO ESPECIFICADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V193",	text: "CICLISTA [CUALQUIERA] LESIONADO EN ACCIDENTE NO DE TRANSITO, NO ESPECIFICADO"},
{ value: "V194",	text: "CONDUCTOR DE VEHICULO DE PEDAL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V195",	text: "PASAJERO DE VEHICULO DE PEDAL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V196",	text: "CICLISTA NO ESPECIFICADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V198",	text: "CICLISTA [CUALQUIERA] LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS"},
{ value: "V199",	text: "CICLISTA [CUALQUIERA] LESIONADO EN ACCIDENTE DE TRANSITO NO ESPECIFICADO"},
{ value: "V200",	text: "MOTOCICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V201",	text: "MOTOCICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V202",	text: "MOTOCICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V203",	text: "MOTOCICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V204",	text: "MOTOCICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V205",	text: "MOTOCICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V209",	text: "MOTOCICLISTA LESIONADO POR COLISION CON PEATON O ANIMAL: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V210",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE PEDAL: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V211",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE PEDAL: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V212",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE PEDAL: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V213",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE PEDAL: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V214",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE PEDAL: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V215",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE PEDAL: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V219",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE PEDAL: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V220",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V221",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V222",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V223",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V224",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V225",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V229",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V230",	text: "MOTOCICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V231",	text: "MOTOCICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V232",	text: "MOTOCICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V233",	text: "MOTOCICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V234",	text: "MOTOCICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V235",	text: "MOTOCICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V239",	text: "MOTOCICLISTA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V240",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V241",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V242",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V243",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V244",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V245",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V249",	text: "MOTOCICLISTA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V250",	text: "MOTOCICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V251",	text: "MOTOCICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V252",	text: "MOTOCICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V253",	text: "MOTOCICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V254",	text: "MOTOCICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V255",	text: "MOTOCICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V259",	text: "MOTOCICLISTA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V260",	text: "MOTOCICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V261",	text: "MOTOCICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V262",	text: "MOTOCICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V263",	text: "MOTOCICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V264",	text: "MOTOCICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V265",	text: "MOTOCICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V269",	text: "MOTOCICLISTA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V270",	text: "MOTOCICLISTA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V271",	text: "MOTOCICLISTA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V272",	text: "MOTOCICLISTA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V273",	text: "MOTOCICLISTA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V274",	text: "MOTOCICLISTA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V275",	text: "MOTOCICLISTA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V279",	text: "MOTOCICLISTA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V280",	text: "MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V281",	text: "MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V282",	text: "MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V283",	text: "MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V284",	text: "MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V285",	text: "MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V289",	text: "MOTOCICLISTA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: MOTOCICLISTA NO ESPECIFICADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V290",	text: "CONDUCTOR DE MOTOCICLETA LESIONADO POR COLISIÓN CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V291",	text: "PASAJERO DE MOTOCICLETA LESIONADO POR COLISIÓN CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V292",	text: "MOTOCICLISTA NO ESPECIFICADO LESIONADO POR COLISIÓN CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V293",	text: "MOTOCICLISTA [CUALQUIERA] LESIONADO EN ACCIDENTE NO DE TRANSITO, NO ESPECIFICADO"},
{ value: "V294",	text: "CONDUCTOR DE MOTOCICLETA LESIONADO POR COLISION CON OTROS VEHICULO DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V295",	text: "PASAJERO DE MOTOCICLETA LESIONADO POR COLISION CON OTROS VEHICULO DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V296",	text: "MOTOCICLISTA NO ESPECIFICADO LESIONADO POR COLISION CON OTROS VEHICULO DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V298",	text: "MOTOCICLISTA [CUALQUIERA] LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS"},
{ value: "V299",	text: "MOTOCICLISTA [CUALQUIERA] LESIONADO EN ACCIDENTE DE TRANSITO NO ESPECIFICADO"},
{ value: "V300",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V301",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V302",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V303",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V304",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V305",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V306",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V307",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V309",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON PEATON O ANIMAL: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V310",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V311",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V312",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V313",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V314",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V315",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V316",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V317",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V319",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE PEDAL: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V320",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO DE MOTOR DE DOS O TRES RUEDAS: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V321",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V322",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V323",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO DE MOTOR DE DOS O TRES RUEDAS: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V324",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V325",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO DE MOTOR DE DOS O TRES RUEDAS: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V326",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V327",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V329",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTRO VEHICULO DE MOTOR DE DOS O TRES RUEDAS: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V330",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V331",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V332",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V333",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V334",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V335",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V336",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V337",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V339",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V340",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V341",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V342",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V343",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V344",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V345",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V346",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V347",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V349",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V350",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V351",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V352",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V353",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V354",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V355",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V356",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V357",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V359",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V360",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V361",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V362",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V363",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V364",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V365",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V366",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V367",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V369",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V370",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V371",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V372",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V373",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V374",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V375",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V376",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V377",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V379",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V380",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V381",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V382",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V383",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V384",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V385",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V386",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V387",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V389",	text: "OCUPANTE DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V390",	text: "CONDUCTOR DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V391",	text: "PASAJERO DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V392",	text: "OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V393",	text: "OCUPANTE [CUALQUIERA] DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE NO DE TRANSITO, NO ESPECIFICADO"},
{ value: "V394",	text: "CONDUCTOR DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V395",	text: "PASAJERO DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V396",	text: "OCUPANTE NO ESPECIFICADO DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V398",	text: "OCUPANTE [CUALQUIERA] DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS"},
{ value: "V399",	text: "OCUPANTE [CUALQUIERA] DE VEHICULO DE MOTOR DE TRES RUEDAS LESIONADO EN ACCIDENTE DE TRANSITO, NO ESPECIFICADO"},
{ value: "V400",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V401",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V402",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V403",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V404",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V405",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V406",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V407",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V409",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON PEATON O ANIMAL: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V410",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V411",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V412",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V413",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V414",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V415",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V416",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V417",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V419",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE PEDAL: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V420",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V421",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V422",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V423",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V424",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V425",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V426",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V427",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V429",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V430",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V431",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V432",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V433",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V434",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V435",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V436",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V437",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V439",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTRO AUTOMOVIL, CAMIONETA O FURGONETA: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V440",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V441",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V442",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V443",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V444",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V445",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V446",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V447",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V449",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V450",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V451",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V452",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V453",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V454",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V455",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V456",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V457",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V459",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V460",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V461",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V462",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V463",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V464",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V465",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V466",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V467",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V469",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V470",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V471",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V472",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V473",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V474",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V475",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V476",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V477",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V479",	text: "OCUPANTE DE AUTOMOVIL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V480",	text: "OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V481",	text: "OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V482",	text: "OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V483",	text: "OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V484",	text: "OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V485",	text: "OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V486",	text: "OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V487",	text: "OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V489",	text: "OCUPANTE DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: OCUPANTE NO ESPECIFICADO DE AUTOMOVIL, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V490",	text: "CONDUCTOR DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V491",	text: "PASAJERO DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V492",	text: "OCUPANTE NO ESPECIFICADO DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V493",	text: "OCUPANTE [CUALQUIERA] DE AUTOMOVIL LESIONADO EN ACCIDENTE NO DE TRANSITO, NO ESPECIFICADO"},
{ value: "V494",	text: "CONDUCTOR DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V495",	text: "PASAJERO DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V496",	text: "OCUPANTE NO ESPECIFICADO DE AUTOMOVIL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V498",	text: "OCUPANTE [CUALQUIERA]) DE AUTOMOVIL LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS"},
{ value: "V499",	text: "OCUPANTE [CUALQUIERA] DE AUTOMOVIL LESIONADO EN ACCIDENTE DE TRANSITO NO ESPECIFICADO"},
{ value: "V500",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V501",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V502",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V503",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V504",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V505",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V506",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V507",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V509",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON PEATON O ANIMAL: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V510",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V511",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V512",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V513",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V514",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V515",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V516",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V517",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V519",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE PEDAL: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V520",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V521",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V522",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V523",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V524",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V525",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V526",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V527",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V529",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V530",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V531",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V532",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V533",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V534",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V535",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V536",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V537",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V539",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V540",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V541",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V542",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V543",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V544",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V545",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V546",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V547",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V549",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V550",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V551",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V552",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V553",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V554",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V555",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V556",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V557",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V559",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V560",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V561",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V562",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V563",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V564",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V565",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V566",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V567",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V569",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V570",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V571",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V572",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V573",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V574",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V575",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V576",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V577",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V579",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V580",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V581",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V582",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V583",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V584",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V585",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V586",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V587",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V589",	text: "OCUPANTE DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V590",	text: "CONDUCTOR DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V591",	text: "PASAJERO DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V592",	text: "OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V593",	text: "OCUPANTE [CUALQUIERA] DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE NO DE TRANSITO, NO ESPECIFICADO"},
{ value: "V594",	text: "CONDUCTOR DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V595",	text: "PASAJERO DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V596",	text: "OCUPANTE NO ESPECIFICADO DE CAMIONETA O FURGONETA LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V598",	text: "OCUPANTE [CUALQUIERA] DE CAMIONETA O FURGONETA LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS"},
{ value: "V599",	text: "OCUPANTE [CUALQUIERA] DE CAMIONETA O FURGONETA LESIONADO EN ACCIDENTE DE TRANSITO NO ESPECIFICADO"},
{ value: "V600",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V601",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V602",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V603",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V604",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V605",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V606",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V607",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V609",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON PEATON O ANIMAL: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V610",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V611",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V612",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V613",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V614",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V615",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V616",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V617",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V619",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE PEDAL: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V620",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V621",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V622",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V623",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V624",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V625",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V626",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V627",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V629",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V630",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V631",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V632",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V633",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V634",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V635",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V636",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V637",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V639",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V640",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO DE TRANSPORTE PESADO O AUTOBUS: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V641",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V642",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V643",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO DE TRANSPORTE PESADO O AUTOBUS: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V644",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V645",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO DE TRANSPORTE PESADO O AUTOBUS: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V646",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V647",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V649",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTRO VEHICULO DE TRANSPORTE PESADO O AUTOBUS: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V650",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V651",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V652",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V653",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V654",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V655",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V656",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V657",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V659",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V660",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V661",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V662",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V663",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V664",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V665",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V666",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V667",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V669",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V670",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V671",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V672",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V673",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V674",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V675",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V676",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V677",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V679",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V680",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V681",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V682",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V683",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V684",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V685",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V686",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V687",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V689",	text: "OCUPANTE DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V690",	text: "CONDUCTOR DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V691",	text: "PASAJERO DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V692",	text: "OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V693",	text: "OCUPANTE [CUALQUIERA] DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE NO DE TRANSITO, NO ESPECIFICADO"},
{ value: "V694",	text: "CONDUCTOR DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V695",	text: "PASAJERO DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V696",	text: "OCUPANTE NO ESPECIFICADO DE VEHICULO DE TRANSPORTE PESADO LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V698",	text: "OCUPANTE [CUALQUIERA] DE VEHICULO DE TRANSPORTE PESADO LESIONADO  EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS"},
{ value: "V699",	text: "OCUPANTE [CUALQUIERA] DE VEHICULO DE TRANSPORTE PESADO LESIONADO EN ACCIDENTE DE TRANSITO NO ESPECIFICADO"},
{ value: "V700",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V701",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V702",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V703",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V704",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V705",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V706",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V707",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V709",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON PEATON O ANIMAL: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V710",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V711",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V712",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V713",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V714",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V715",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V716",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V717",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V719",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE PEDAL: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V720",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V721",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V722",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V723",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V724",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V725",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V726",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V727",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V729",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V730",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V731",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V732",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V733",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V734",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V735",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V736",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V737",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V739",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V740",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V741",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V742",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V743",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V744",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V745",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V746",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V747",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V749",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON VEHICULO DE TRANSPORTE PESADO O AUTOBUS: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V750",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V751",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V752",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V753",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V754",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V755",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V756",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V757",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V759",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V760",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V761",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V762",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V763",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V764",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V765",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V766",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V767",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V769",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR: OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS SIN MOTOR"},
{ value: "V770",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V771",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V772",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V773",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V774",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V775",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V776",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V777",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V779",	text: "OCUPANTE DE AUTOBUS LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V780",	text: "OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: CONDUCTOR LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V781",	text: "OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PASAJERO LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V782",	text: "OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V783",	text: "OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V784",	text: "OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO"},
{ value: "V785",	text: "OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: CONDUCTOR LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V786",	text: "OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PASAJERO LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V787",	text: "OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: PERSONA QUE VIAJA FUERA DEL VEHICULO, LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V789",	text: "OCUPANTE DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSPORTE SIN COLISION: OCUPANTE NO ESPECIFICADO DE AUTOBUS, LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V790",	text: "CONDUCTOR DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V791",	text: "PASAJERO DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V792",	text: "OCUPANTE NO ESPECIFICADO DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V793",	text: "OCUPANTE [CUALQUIERA] DE AUTOBUS LESIONADO EN ACCIDENTE NO DE TRANSITO, NO ESPECIFICADO"},
{ value: "V794",	text: "CONDUCTOR DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V795",	text: "PASAJERO DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V796",	text: "OCUPANTE NO ESPECIFICADO DE AUTOBUS LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR, Y CON LOS NO ESPECIFICADOS, EN ACCIDENTE DE TRANSITO"},
{ value: "V798",	text: "OCUPANTE [CUALQUIERA] DE AUTOBUS LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS"},
{ value: "V799",	text: "OCUPANTE [CUALQUIERA] DE AUTOBUS LESIONADO EN ACCIDENTE DE TRANSITO NO ESPECIFICADO"},
{ value: "V800",	text: "JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR CAIDA (O POR SER DESPEDIDO) DEL ANIMAL O DEL VEHICULO DE TRACCION ANIMAL, EN ACCIDENTE SIN COLISION"},
{ value: "V801",	text: "JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR COLISION CON PEATON O ANIMAL"},
{ value: "V802",	text: "JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR COLISION CON VEHICULO DE PEDAL"},
{ value: "V803",	text: "JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR COLISION CON VEHICULO DE MOTOR DE DOS O TRES RUEDAS"},
{ value: "V804",	text: "JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR COLISION CON AUTOMOVIL, CAMIONETA O FURGONETA, VEHICULO DE TRANSPORTE PESADO, O AUTOBUS"},
{ value: "V805",	text: "JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR COLISION CON OTROS VEHICULOS DE MOTOR ESPECIFICADOS"},
{ value: "V806",	text: "JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR COLISION CON TREN O VEHICULO DE RIELES"},
{ value: "V807",	text: "JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR COLISION CON VEHICULOS SIN MOTOR"},
{ value: "V808",	text: "JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO POR COLISION CON OBJETO FIJO O ESTACIONADO"},
{ value: "V809",	text: "JINETE U OCUPANTE DE VEHICULO DE TRACCION ANIMAL LESIONADO EN OTROS ACCIDENTES DE TRANSPORTE, Y EN LOS NO ESPECIFICADOS"},
{ value: "V810",	text: "OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO POR COLISION CON VEHÍCULO DE MOTOR, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V811",	text: "OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO POR COLISION CON VEHÍCULO DE MOTOR, EN ACCIDENTE DE TRANSITO"},
{ value: "V812",	text: "OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO POR COLISION CON, O GOLPEADO POR VAGON"},
{ value: "V813",	text: "OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO POR COLISION CON OTROS OBJETOS"},
{ value: "V814",	text: "PERSONA LESIONADA AL SUBIR O BAJAR DE TREN O VEHICULO DE RIELES"},
{ value: "V815",	text: "OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO POR CAIDA DENTRO DEL TREN O VEHICULO DE RIELES"},
{ value: "V816",	text: "OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO POR CAIDA DESDE EL TREN O VEHICULO DE RIELES"},
{ value: "V817",	text: "OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO EN DESCARRILAMIENTO SIN COLISION ANTERIOR"},
{ value: "V818",	text: "OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO EN OTROS ACCIDENTES FERROVIARIOS ESPECIFICADOS"},
{ value: "V819",	text: "OCUPANTE DE TREN O VEHICULO DE RIELES LESIONADO EN ACCIDENTE FERROVIARIO NO ESPECIFICADO"},
{ value: "V820",	text: "OCUPANTE DE TRANVIA LESIONADO POR COLISION CON VEHICULO DE MOTOR, EN ACCIDENTE NO DE TRANSITO"},
{ value: "V821",	text: "OCUPANTE DE TRANVIA LESIONADO POR COLISION CON VEHICULO DE MOTOR, EN ACCIDENTE DE TRANSITO"},
{ value: "V822",	text: "OCUPANTE DE TRANVIA LESIONADO POR COLISION CON, O GOLPEADO POR VAGON"},
{ value: "V823",	text: "OCUPANTE DE TRANVIA LESIONADO POR COLISION CON OTROS OBJETOS"},
{ value: "V824",	text: "PERSONA LESIONADA AL SUBIR O BAJAR DEL TRANVIA"},
{ value: "V825",	text: "OCUPANTE DE TRANVIA LESIONADO POR CAIDA DENTRO DEL TRANVIA"},
{ value: "V826",	text: "OCUPANTE DE TRANVIA LESIONADO POR CAIDA DESDE DEL TRANVIA"},
{ value: "V827",	text: "OCUPANTE DE TRANVIA LESIONADO POR DESCARRILAMIENTO, SIN COLISION ANTERIOR"},
{ value: "V828",	text: "OCUPANTE DE TRANVIA LESIONADO OTROS ACCIDENTES DE TRANSPORTE, ESPECIFICADOS"},
{ value: "V829",	text: "OCUPANTE DE TRANVIA LESIONADO EN ACCIDENTE DE TRANSITO NO ESPECIFICADO"},
{ value: "V830",	text: "CONDUCTOR DE VEHICULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V831",	text: "PASAJERO DE VEHICULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V832",	text: "PERSONA QUE VIAJA FUERA DE VEHICULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V833",	text: "OCUPANTE NO ESPECIFICADO DE VEHICULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V834",	text: "PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO INDUSTRIAL ESPECIAL"},
{ value: "V835",	text: "CONDUCTOR DE VEHICULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V836",	text: "PASAJERO DE VEHICULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V837",	text: "PERSONA QUE VIAJA FUERA DE VEHICULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V839",	text: "OCUPANTE NO ESPECIFICADO DE VEHICULO INDUSTRIAL ESPECIAL LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V840",	text: "CONDUCTOR DE VEHICULO AGRICOLA ESPECIAL LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V841",	text: "PASAJERO DE VEHICULO AGRICOLA ESPECIAL LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V842",	text: "PERSONA QUE VIAJA FUERA DEL VEHICULO AGRICOLA ESPECIAL LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V843",	text: "OCUPANTE NO ESPECIFICADO DE VEHICULO AGRICOLA ESPECIAL LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V844",	text: "PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO AGRICOLA ESPECIAL"},
{ value: "V845",	text: "CONDUCTOR DE VEHICULO AGRICOLA ESPECIAL LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V846",	text: "PASAJERO DE VEHICULO AGRICOLA ESPECIAL LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V847",	text: "PERSONA QUE VIAJA FUERA DEL VEHICULO AGRICOLA ESPECIAL LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V849",	text: "OCUPANTE NO ESPECIFICADO DE VEHICULO AGRICOLA ESPECIAL LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V850",	text: "CONDUCTOR DE VEHICULO ESPECIAL PARA CONSTRUCCION LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V851",	text: "PASAJERO DE VEHICULO ESPECIAL PARA CONSTRUCCION LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V852",	text: "PERSONA QUE VIAJA FUERA DEL VEHICULO ESPECIAL PARA CONSTRUCCIÓN LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V853",	text: "OCUPANTE NO ESPECIFICADO DE VEHICULO ESPECIAL PARA CONSTRUCCION LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V854",	text: "PERSONA LESIONADA AL SUBIR O BAJAR DEL VEHICULO ESPECIAL PARA CONSTRUCCION"},
{ value: "V855",	text: "CONDUCTOR DE VEHICULO ESPECIAL PARA CONSTRUCCION LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V856",	text: "PASAJERO DE VEHICULO ESPECIAL PARA CONSTRUCCION LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V857",	text: "PERSONA QUE VIAJA FUERA DEL VEHICULO ESPECIAL PARA CONSTRUCCION LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V859",	text: "OCUPANTE NO ESPECIFICADO DE VEHICULO ESPECIAL PARA CONSTRUCCION LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V860",	text: "CONDUCTOR DE VEHICULO PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V861",	text: "PASAJERO DE VEHICULO PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V862",	text: "PERSONA QUE VIAJA FUERA DEL VEHICULO PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADA EN ACCIDENTE DE TRANSITO"},
{ value: "V863",	text: "OCUPANTE NO ESPECIFICADO DE VEHICULO PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADO EN ACCIDENTE DE TRANSITO"},
{ value: "V864",	text: "PERSONA LESIONADA EN ACCIDENTE DE TRANSITO AL SUBIR O BAJAR DE VEHICULO PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE LA CARRETERA"},
{ value: "V865",	text: "CONDUCTOR DE VEHICULO PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V866",	text: "PASAJERO DE VEHICULO PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V867",	text: "PERSONA QUE VIAJA FUERA DEL VEHICULO PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADA EN ACCIDENTE NO DE TRANSITO"},
{ value: "V869",	text: "OCUPANTE NO ESPECIFICADO DEL VEHICULO PARA TODO TERRENO O DE OTRO VEHICULO DE MOTOR PARA USO FUERA DE LA CARRETERA LESIONADO EN ACCIDENTE NO DE TRANSITO"},
{ value: "V870",	text: "PERSONA LESIONADA POR COLISION ENTRE AUTOMOVIL Y VEHICULO DE MOTOR DE DOS O TRES RUEDAS (TRANSITO)"},
{ value: "V871",	text: "PERSONA LESIONADA POR COLISION ENTRE OTROS VEHICULOS DE MOTOR Y UN VEHICULO DE MOTOR DE DOS O TRES RUEDAS (TRANSITO)"},
{ value: "V872",	text: "PERSONA LESIONADA POR COLISION ENTRE AUTOMOVIL Y CAMIONETA O FURGONETA (TRANSITO)"},
{ value: "V873",	text: "PERSONA LESIONADA POR COLISION ENTRE AUTOMOVIL Y AUTOBUS (TRANSITO)"},
{ value: "V874",	text: "PERSONA LESIONADA POR COLISION ENTRE AUTOMOVIL Y VEHICULO DE MOTOR DE TRANSPORTE PESADO (TRANSITO)"},
{ value: "V875",	text: "PERSONA LESIONADA POR COLISION ENTRE VEHICULO DE TRANSPORTE PESADO Y AUTOBUS (TRANSITO)"},
{ value: "V876",	text: "PERSONA LESIONADA POR COLISION ENTRE TREN O VEHICULO DE RIELES Y AUTOMOVIL (TRANSITO)"},
{ value: "V877",	text: "PERSONA LESIONADA POR COLISION ENTRE OTROS VEHICULOS DE MOTOR ESPECIFICADOS (TRANSITO)"},
{ value: "V878",	text: "PERSONA LESIONADA EN OTROS ACCIDENTES ESPECIFICADOS DE TRANSPORTE DE VEHICULO DE MOTOR SIN COLISION (TRANSITO)"},
{ value: "V879",	text: "PERSONA LESIONADA EN OTROS ACCIDENTES ESPECIFICADOS DE TRANSPORTE DE VEHICULO SIN MOTOR (CON COLISION) (SIN COLISION) (TRANSITO)"},
{ value: "V880",	text: "PERSONA LESIONADA POR COLISION ENTRE AUTOMOVIL Y VEHICULO DE MOTOR DE DOS O TRES RUEDAS, NO DE TRANSITO"},
{ value: "V881",	text: "PERSONA LESIONADA POR COLISION ENTRE OTROS VEHICULOS DE MOTOR Y VEHICULO DE MOTOR DE DOS O TRES RUEDAS, NO DE TRANSITO"},
{ value: "V882",	text: "PERSONA LESIONADA POR COLISION ENTRE AUTOMOVIL Y CAMIONETA O FURGONETA, NO DE TRANSITO"},
{ value: "V883",	text: "PERSONA LESIONADA POR COLISION ENTRE AUTOMOVIL Y AUTOBUS, NO DE TRANSITO"},
{ value: "V884",	text: "PERSONA LESIONADA POR COLISION ENTRE AUTOMOVIL Y VEHICULO DE TRANSPORTE PESADO, NO DE TRANSITO"},
{ value: "V885",	text: "PERSONA LESIONADA POR COLISION ENTRE VEHICULO DE TRANSPORTE PESADO Y AUTOBUS, NO DE TRANSITO"},
{ value: "V886",	text: "PERSONA LESIONADA POR COLISION ENTRE TREN O VEHICULO DE RIELES O AUTOMOVIL, NO DE TRANSITO"},
{ value: "V887",	text: "PERSONA LESIONADA POR COLISION ENTRE OTROS VEHICULOS DE MOTOR ESPECIFICADOS, NO DE TRANSITO"},
{ value: "V888",	text: "PERSONA LESIONADA EN OTROS ACCIDENTES ESPECIFICADOS DE TRANSPORTE DE VEHICULO DE MOTOR SIN COLISION, NO DE TRANSITO"},
{ value: "V889",	text: "PERSONA LESIONADA EN OTROS ACCIDENTES ESPECIFICADOS DE TRANSPORTE DE VEHICULO SIN MOTOR (CON COLISION) (SIN COLISION), NO DE TRANSITO"},
{ value: "V890",	text: "PERSONA LESIONADA EN ACCIDENTE NO DE TRANSITO, DE VEHICULO DE MOTOR NO ESPECIFICADO"},
{ value: "V891",	text: "PERSONA LESIONADA EN ACCIDENTE NO DE TRANSITO, DE VEHICULO SIN MOTOR NO ESPECIFICADO"},
{ value: "V892",	text: "PERSONA LESIONADA EN ACCIDENTE DE TRANSITO, DE VEHICULO DE MOTOR NO ESPECIFICADO"},
{ value: "V893",	text: "PERSONA LESIONADA EN ACCIDENTE DE TRANSITO, DE VEHICULO SIN MOTOR NO ESPECIFICADO"},
{ value: "V899",	text: "PERSONA LESIONADA EN ACCIDENTE DE VEHICULO NO ESPECIFICADO"},
{ value: "V900",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION: BARCO MERCANTE"},
{ value: "V901",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION: BARCO DE PASAJEROS"},
{ value: "V902",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION: BOTE DE PESCA"},
{ value: "V903",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION: OTRO VEHICULO ACUATICO CON MOTOR"},
{ value: "V904",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION: VELERO"},
{ value: "V905",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION: CANOA O KAYAK"},
{ value: "V906",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION: BALSA INFLABLE (SIN MOTOR)"},
{ value: "V907",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION: ESQUI ACUATICO"},
{ value: "V908",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION: OTRO VEHICULO ACUATICO SIN MOTOR"},
{ value: "V909",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA AHOGAMIENTO Y SUMERSION: VEHICULO ACUATICO NO ESPECIFICADO"},
{ value: "V910",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO: BARCO MERCANTE"},
{ value: "V911",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO: BARCO DE PASAJEROS"},
{ value: "V912",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO: BOTE DE PESCA"},
{ value: "V913",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO: OTRO VEHICULO ACUATICO CON MOTOR"},
{ value: "V914",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO: VELERO"},
{ value: "V915",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO: CANOA O KAYAK"},
{ value: "V916",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO: BALSA INFLABLE (SIN MOTOR)"},
{ value: "V917",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO: ESQUI ACUATICO"},
{ value: "V918",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO: OTRO VEHICULO ACUATICO SIN MOTOR"},
{ value: "V919",	text: "ACCIDENTE DE EMBARCACION QUE CAUSA OTROS TIPOS DE TRAUMATISMO: VEHICULO ACUATICO NO ESPECIFICADO"},
{ value: "V920",	text: "AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION: BARCO MERCANTE"},
{ value: "V921",	text: "AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION: BARCO DE PASAJEROS"},
{ value: "V922",	text: "AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION: BOTE DE PESCA"},
{ value: "V923",	text: "AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION: OTRO VEHICULO ACUATICO CON MOTOR"},
{ value: "V924",	text: "AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION: VELERO"},
{ value: "V925",	text: "AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION: CANOA O KAYAK"},
{ value: "V926",	text: "AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION: BALSA INFLABLE (SIN MOTOR)"},
{ value: "V927",	text: "AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION: ESQUI ACUATICO"},
{ value: "V928",	text: "AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION: OTRO VEHICULO ACUATICO SIN MOTOR"},
{ value: "V929",	text: "AHOGAMIENTO Y SUMERSION RELACIONADOS CON TRANSPORTE POR AGUA, SIN ACCIDENTE A LA EMBARCACION: VEHICULO ACUATICO NO ESPECIFICADO"},
{ value: "V930",	text: "ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE NO CAUSA AHOGAMIENTO O SUMERSION: BARCO MERCANTE"},
{ value: "V931",	text: "ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE NO CAUSA AHOGAMIENTO O SUMERSION: BARCO DE PASAJEROS"},
{ value: "V932",	text: "ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE NO CAUSA AHOGAMIENTO O SUMERSION: BOTE DE PESCA"},
{ value: "V933",	text: "ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE NO CAUSA AHOGAMIENTO O SUMERSION: OTRO VEHICULO ACUATICO CON MOTOR"},
{ value: "V934",	text: "ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE NO CAUSA AHOGAMIENTO O SUMERSION: VELERO"},
{ value: "V935",	text: "ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE NO CAUSA AHOGAMIENTO O SUMERSION: CANOA O KAYAK"},
{ value: "V936",	text: "ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE NO CAUSA AHOGAMIENTO O SUMERSION: BALSA INFLABLE (SIN MOTOR)"},
{ value: "V937",	text: "ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE NO CAUSA AHOGAMIENTO O SUMERSION: ESQUI ACUATICO"},
{ value: "V938",	text: "ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE NO CAUSA AHOGAMIENTO O SUMERSION: OTRO VEHICULO ACUATICO SIN MOTOR"},
{ value: "V939",	text: "ACCIDENTE EN UNA EMBARCACION, SIN ACCIDENTE A LA EMBARCACION, QUE NO CAUSA AHOGAMIENTO O SUMERSION: VEHICULO ACUATICO NO ESPECIFICADO"},
{ value: "V940",	text: "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS: BARCO MERCANTE"},
{ value: "V941",	text: "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS: BARCO DE PASAJEROS"},
{ value: "V942",	text: "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS: BOTE DE PESCA"},
{ value: "V943",	text: "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS: OTRO VEHICULO ACUATICO CON MOTOR"},
{ value: "V944",	text: "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS: VELERO"},
{ value: "V945",	text: "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS: CANOA O KAYAK"},
{ value: "V946",	text: "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS: BALSA INFLABLE (SIN MOTOR)"},
{ value: "V947",	text: "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS: ESQUI ACUATICO"},
{ value: "V948",	text: "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS. OTRO VEHICULO ACUATICO SIN MOTOR"},
{ value: "V949",	text: "OTROS ACCIDENTES DE TRANSPORTE POR AGUA, Y LOS NO ESPECIFICADOS: VEHICULO ACUATICO NO ESPECIFICADO"},
{ value: "V950",	text: "ACCIDENTE DE HELICOPTERO CON OCUPANTE LESIONADO"},
{ value: "V951",	text: "ACCIDENTE DE PLANEADOR ULTRA LIVIANO, MICRO LIVIANO O MOTORIZADO, CON OCUPANTE LESIONADO"},
{ value: "V952",	text: "ACCIDENTE DE OTROS VEHICULOS AEREOS DE ALAS FIJAS, PRIVADOS, CON OCUPANTE LESIONADO"},
{ value: "V953",	text: "ACCIDENTE DE VEHICULO AEREO DE ALAS FIJAS, COMERCIAL, CON OCUPANTE LESIONADO"},
{ value: "V954",	text: "ACCIDENTE DE NAVE ESPACIAL, CON OCUPANTE LESIONADO"},
{ value: "V958",	text: "ACCIDENTE DE OTRAS AERONAVES, CON OCUPANTE LESIONADO"},
{ value: "V959",	text: "ACCIDENTE DE AERONAVE NO ESPECIFICADA, CON OCUPANTE LESIONADO"},
{ value: "V960",	text: "ACCIDENTE DE GLOBO AEROSTATICO, CON OCUPANTE LESIONADO"},
{ value: "V961",	text: "ACCIDENTE DE ALA DELTA, CON OCUPANTE LESIONADO"},
{ value: "V962",	text: "ACCIDENTE DE PLANEADOR (SIN MOTOR), CON OCUPANTE LESIONADO"},
{ value: "V968",	text: "ACCIDENTE DE OTRAS AERONAVES SIN MOTOR, CON OCUPANTE LESIONADO"},
{ value: "V969",	text: "ACCIDENTE DE AERONAVE SIN MOTOR NO ESPECIFICADA, CON OCUPANTE LESIONADO"},
{ value: "V970",	text: "OCUPANTE DE AERONAVE LESIONADO EN OTROS ACCIDENTES ESPECIFICADOS DE TRANSPORTE AEREO"},
{ value: "V971",	text: "PERSONA LESIONADA AL SUBIR O BAJAR DE UNA AERONAVE"},
{ value: "V972",	text: "PARACAIDISTA LESIONADO EN ACCIDENTE DE TRANSPORTE AEREO"},
{ value: "V973",	text: "PERSONA EN TIERRA LESIONADA POR ACCIDENTE DE TRANSPORTE AEREO"},
{ value: "V978",	text: "OTROS ACCIDENTES DE TRANSPORTE AEREO, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "V98X",	text: "OTROS ACCIDENTES DE TRANSPORTE ESPECIFICADOS"},
{ value: "V99X",	text: "ACCIDENTE DE TRANSPORTE NO ESPECIFICADO"},
{ value: "W000",	text: "CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE: VIVIENDA"},
{ value: "W001",	text: "CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE: INSTITUCION RESIDENCIAL"},
{ value: "W002",	text: "CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W003",	text: "CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W004",	text: "CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE: CALLES Y CARRETERAS"},
{ value: "W005",	text: "CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W006",	text: "CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W007",	text: "CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE: GRANJA"},
{ value: "W008",	text: "CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE: OTRO LUGAR ESPECIFICADO"},
{ value: "W009",	text: "CAIDA EN EL MISMO NIVEL POR HIELO O NIEVE: LUGAR NO ESPECIFICADO"},
{ value: "W010",	text: "CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE: VIVIENDA"},
{ value: "W011",	text: "CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE: INSTITUCION RESIDENCIAL"},
{ value: "W012",	text: "CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W013",	text: "CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W014",	text: "CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE: CALLES Y CARRETERAS"},
{ value: "W015",	text: "CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W016",	text: "CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W017",	text: "CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE: GRANJA"},
{ value: "W018",	text: "CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE: OTRO LUGAR ESPECIFICADO"},
{ value: "W019",	text: "CAIDA EN EL MISMO NIVEL POR DESLIZAMIENTO, TROPEZON Y TRASPIE: LUGAR NO ESPECIFICADO"},
{ value: "W020",	text: "CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA: VIVIENDA"},
{ value: "W021",	text: "CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA: INSTITUCION RESIDENCIAL"},
{ value: "W022",	text: "CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W023",	text: "CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W024",	text: "CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA: CALLES Y CARRETERAS"},
{ value: "W025",	text: "CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W026",	text: "CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W027",	text: "CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA: GRANJA"},
{ value: "W028",	text: "CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA: OTRO LUGAR ESPECIFICADO"},
{ value: "W029",	text: "CAIDA POR PATINES PARA HIELO, ESQUIS, PATINES DE RUEDAS O PATINETA: LUGAR NO ESPECIFICADO"},
{ value: "W030",	text: "OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA: VIVIENDA"},
{ value: "W031",	text: "OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA: INSTITUCION RESIDENCIAL"},
{ value: "W032",	text: "OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W033",	text: "OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W034",	text: "OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA: CALLES Y CARRETERAS"},
{ value: "W035",	text: "OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W036",	text: "OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W037",	text: "OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA: GRANJA"},
{ value: "W038",	text: "OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA: OTRO LUGAR ESPECIFICADO"},
{ value: "W039",	text: "OTRAS CAIDAS EN EL MISMO NIVEL POR COLISION CON O POR EMPUJON DE OTRA PERSONA: LUGAR NO ESPECIFICADO"},
{ value: "W040",	text: "CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS: VIVIENDA"},
{ value: "W041",	text: "CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS: INSTITUCION RESIDENCIAL"},
{ value: "W042",	text: "CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W043",	text: "CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W044",	text: "CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS: CALLES Y CARRETERAS"},
{ value: "W045",	text: "CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W046",	text: "CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W047",	text: "CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS: GRANJA"},
{ value: "W048",	text: "CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS: OTRO LUGAR ESPECIFICADO"},
{ value: "W049",	text: "CAIDA AL SER TRASLADADO O SOSTENIDO POR OTRAS PERSONAS: LUGAR NO ESPECIFICADO"},
{ value: "W050",	text: "CAIDA QUE IMPLICA SILLA DE RUEDAS: VIVIENDA"},
{ value: "W051",	text: "CAIDA QUE IMPLICA SILLA DE RUEDAS: INSTITUCION RESIDENCIAL"},
{ value: "W052",	text: "CAIDA QUE IMPLICA SILLA DE RUEDAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W053",	text: "CAIDA QUE IMPLICA SILLA DE RUEDAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W054",	text: "CAIDA QUE IMPLICA SILLA DE RUEDAS: CALLES Y CARRETERAS"},
{ value: "W055",	text: "CAIDA QUE IMPLICA SILLA DE RUEDAS: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W056",	text: "CAIDA QUE IMPLICA SILLA DE RUEDAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W057",	text: "CAIDA QUE IMPLICA SILLA DE RUEDAS: GRANJA"},
{ value: "W058",	text: "CAIDA QUE IMPLICA SILLA DE RUEDAS: OTRO LUGAR ESPECIFICADO"},
{ value: "W059",	text: "CAIDA QUE IMPLICA SILLA DE RUEDAS: LUGAR NO ESPECIFICADO"},
{ value: "W060",	text: "CAIDA QUE IMPLICA CAMA: VIVIENDA"},
{ value: "W061",	text: "CAIDA QUE IMPLICA CAMA: INSTITUCION RESIDENCIAL"},
{ value: "W062",	text: "CAIDA QUE IMPLICA CAMA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W063",	text: "CAIDA QUE IMPLICA CAMA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W064",	text: "CAIDA QUE IMPLICA CAMA: CALLES Y CARRETERAS"},
{ value: "W065",	text: "CAIDA QUE IMPLICA CAMA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W066",	text: "CAIDA QUE IMPLICA CAMA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W067",	text: "CAIDA QUE IMPLICA CAMA: GRANJA"},
{ value: "W068",	text: "CAIDA QUE IMPLICA CAMA: OTRO LUGAR ESPECIFICADO"},
{ value: "W069",	text: "CAIDA QUE IMPLICA CAMA: LUGAR NO ESPECIFICADO"},
{ value: "W070",	text: "CAIDA QUE IMPLICA SILLA: VIVIENDA"},
{ value: "W071",	text: "CAIDA QUE IMPLICA SILLA: INSTITUCION RESIDENCIAL"},
{ value: "W072",	text: "CAIDA QUE IMPLICA SILLA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W073",	text: "CAIDA QUE IMPLICA SILLA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W074",	text: "CAIDA QUE IMPLICA SILLA: CALLES Y CARRETERAS"},
{ value: "W075",	text: "CAIDA QUE IMPLICA SILLA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W076",	text: "CAIDA QUE IMPLICA SILLA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W077",	text: "CAIDA QUE IMPLICA SILLA: GRANJA"},
{ value: "W078",	text: "CAIDA QUE IMPLICA SILLA: OTRO LUGAR ESPECIFICADO"},
{ value: "W079",	text: "CAIDA QUE IMPLICA SILLA: LUGAR NO ESPECIFICADO"},
{ value: "W080",	text: "CAIDA QUE IMPLICA OTRO MUEBLE: VIVIENDA"},
{ value: "W081",	text: "CAIDA QUE IMPLICA OTRO MUEBLE: INSTITUCION RESIDENCIAL"},
{ value: "W082",	text: "CAIDA QUE IMPLICA OTRO MUEBLE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W083",	text: "CAIDA QUE IMPLICA OTRO MUEBLE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W084",	text: "CAIDA QUE IMPLICA OTRO MUEBLE: CALLES Y CARRETERAS"},
{ value: "W085",	text: "CAIDA QUE IMPLICA OTRO MUEBLE: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W086",	text: "CAIDA QUE IMPLICA OTRO MUEBLE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W087",	text: "CAIDA QUE IMPLICA OTRO MUEBLE: GRANJA"},
{ value: "W088",	text: "CAIDA QUE IMPLICA OTRO MUEBLE: OTRO LUGAR ESPECIFICADO"},
{ value: "W089",	text: "CAIDA QUE IMPLICA OTRO MUEBLE: LUGAR NO ESPECIFICADO"},
{ value: "W090",	text: "CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES: VIVIENDA"},
{ value: "W091",	text: "CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES: INSTITUCION RESIDENCIAL"},
{ value: "W092",	text: "CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W093",	text: "CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W094",	text: "CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES: CALLES Y CARRETERAS"},
{ value: "W095",	text: "CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W096",	text: "CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W097",	text: "CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES: GRANJA"},
{ value: "W098",	text: "CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES: OTRO LUGAR ESPECIFICADO"},
{ value: "W099",	text: "CAIDA QUE IMPLICA EQUIPOS PARA JUEGOS INFANTILES: LUGAR NO ESPECIFICADO"},
{ value: "W100",	text: "CAIDA EN O DESDE ESCALERA Y ESCALONES: VIVIENDA"},
{ value: "W101",	text: "CAIDA EN O DESDE ESCALERA Y ESCALONES: INSTITUCION RESIDENCIAL"},
{ value: "W102",	text: "CAIDA EN O DESDE ESCALERA Y ESCALONES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W103",	text: "CAIDA EN O DESDE ESCALERA Y ESCALONES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W104",	text: "CAIDA EN O DESDE ESCALERA Y ESCALONES: CALLES Y CARRETERAS"},
{ value: "W105",	text: "CAIDA EN O DESDE ESCALERA Y ESCALONES: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W106",	text: "CAIDA EN O DESDE ESCALERA Y ESCALONES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W107",	text: "CAIDA EN O DESDE ESCALERA Y ESCALONES: GRANJA"},
{ value: "W108",	text: "CAIDA EN O DESDE ESCALERA Y ESCALONES: OTRO LUGAR ESPECIFICADO"},
{ value: "W109",	text: "CAIDA EN O DESDE ESCALERA Y ESCALONES: LUGAR NO ESPECIFICADO"},
{ value: "W110",	text: "CAIDA EN O DESDE ESCALERAS MANUALES: VIVIENDA"},
{ value: "W111",	text: "CAIDA EN O DESDE ESCALERAS MANUALES: INSTITUCION RESIDENCIAL"},
{ value: "W112",	text: "CAIDA EN O DESDE ESCALERAS MANUALES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W113",	text: "CAIDA EN O DESDE ESCALERAS MANUALES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W114",	text: "CAIDA EN O DESDE ESCALERAS MANUALES: CALLES Y CARRETERAS"},
{ value: "W115",	text: "CAIDA EN O DESDE ESCALERAS MANUALES: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W116",	text: "CAIDA EN O DESDE ESCALERAS MANUALES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W117",	text: "CAIDA EN O DESDE ESCALERAS MANUALES: GRANJA"},
{ value: "W118",	text: "CAIDA EN O DESDE ESCALERAS MANUALES: OTRO LUGAR ESPECIFICADO"},
{ value: "W119",	text: "CAIDA EN O DESDE ESCALERAS MANUALES: LUGAR NO ESPECIFICADO"},
{ value: "W120",	text: "CAIDA EN O DESDE ANDAMIO: VIVIENDA"},
{ value: "W121",	text: "CAIDA EN O DESDE ANDAMIO: INSTITUCION RESIDENCIAL"},
{ value: "W122",	text: "CAIDA EN O DESDE ANDAMIO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W123",	text: "CAIDA EN O DESDE ANDAMIO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W124",	text: "CAIDA EN O DESDE ANDAMIO: CALLES Y CARRETERAS"},
{ value: "W125",	text: "CAIDA EN O DESDE ANDAMIO: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W126",	text: "CAIDA EN O DESDE ANDAMIO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W127",	text: "CAIDA EN O DESDE ANDAMIO: GRANJA"},
{ value: "W128",	text: "CAIDA EN O DESDE ANDAMIO: OTRO LUGAR ESPECIFICADO"},
{ value: "W129",	text: "CAIDA EN O DESDE ANDAMIO: LUGAR NO ESPECIFICADO"},
{ value: "W130",	text: "CAIDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCION: VIVIENDA"},
{ value: "W131",	text: "CAIDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCION: INSTITUCION RESIDENCIAL"},
{ value: "W132",	text: "CAIDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCION: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W133",	text: "CAIDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCION: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W134",	text: "CAIDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCION: CALLES Y CARRETERAS"},
{ value: "W135",	text: "CAIDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCION: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W136",	text: "CAIDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCION: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W137",	text: "CAIDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCION: GRANJA"},
{ value: "W138",	text: "CAIDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCION: OTRO LUGAR ESPECIFICADO"},
{ value: "W139",	text: "CAIDA DESDE, FUERA O A TRAVÉS DE UN EDIFICIO U OTRA CONSTRUCCION: LUGAR NO ESPECIFICADO"},
{ value: "W140",	text: "CAIDA DESDE UN ARBOL: VIVIENDA"},
{ value: "W141",	text: "CAIDA DESDE UN ARBOL: INSTITUCION RESIDENCIAL"},
{ value: "W142",	text: "CAIDA DESDE UN ARBOL: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W143",	text: "CAIDA DESDE UN ARBOL: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W144",	text: "CAIDA DESDE UN ARBOL: CALLES Y CARRETERAS"},
{ value: "W145",	text: "CAIDA DESDE UN ARBOL: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W146",	text: "CAIDA DESDE UN ARBOL: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W147",	text: "CAIDA DESDE UN ARBOL: GRANJA"},
{ value: "W148",	text: "CAIDA DESDE UN ARBOL: OTRO LUGAR ESPECIFICADO"},
{ value: "W149",	text: "CAIDA DESDE UN ARBOL: LUGAR NO ESPECIFICADO"},
{ value: "W150",	text: "CAIDA DESDE PEÑASCO: VIVIENDA"},
{ value: "W151",	text: "CAIDA DESDE PEÑASCO: INSTITUCION RESIDENCIAL"},
{ value: "W152",	text: "CAIDA DESDE PEÑASCO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W153",	text: "CAIDA DESDE PEÑASCO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W154",	text: "CAIDA DESDE PEÑASCO: CALLES Y CARRETERAS"},
{ value: "W155",	text: "CAIDA DESDE PEÑASCO: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W156",	text: "CAIDA DESDE PEÑASCO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W157",	text: "CAIDA DESDE PEÑASCO: GRANJA"},
{ value: "W158",	text: "CAIDA DESDE PEÑASCO: OTRO LUGAR ESPECIFICADO"},
{ value: "W159",	text: "CAIDA DESDE PEÑASCO: LUGAR NO ESPECIFICADO"},
{ value: "W160",	text: "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO: VIVIENDA"},
{ value: "W161",	text: "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO: INSTITUCION RESIDENCIAL"},
{ value: "W162",	text: "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W163",	text: "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W164",	text: "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO: CALLES Y CARRETERAS"},
{ value: "W165",	text: "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W166",	text: "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W167",	text: "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO: GRANJA"},
{ value: "W168",	text: "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO: OTRO LUGAR ESPECIFICADO"},
{ value: "W169",	text: "SALTO O ZAMBULLIDA DENTRO DEL AGUA QUE CAUSA OTRO TRAUMATISMO SIN SUMERSION O AHOGAMIENTO: LUGAR NO ESPECIFICADO"},
{ value: "W170",	text: "OTRAS CAIDAS DE UN NIVEL A OTRO: VIVIENDA"},
{ value: "W171",	text: "OTRAS CAIDAS DE UN NIVEL A OTRO: INSTITUCION RESIDENCIAL"},
{ value: "W172",	text: "OTRAS CAIDAS DE UN NIVEL A OTRO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W173",	text: "OTRAS CAIDAS DE UN NIVEL A OTRO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W174",	text: "OTRAS CAIDAS DE UN NIVEL A OTRO: CALLES Y CARRETERAS"},
{ value: "W175",	text: "OTRAS CAIDAS DE UN NIVEL A OTRO: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W176",	text: "OTRAS CAIDAS DE UN NIVEL A OTRO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W177",	text: "OTRAS CAIDAS DE UN NIVEL A OTRO: GRANJA"},
{ value: "W178",	text: "OTRAS CAIDAS DE UN NIVEL A OTRO: OTRO LUGAR ESPECIFICADO"},
{ value: "W179",	text: "OTRAS CAIDAS DE UN NIVEL A OTRO: LUGAR NO ESPECIFICADO"},
{ value: "W180",	text: "OTRAS CAIDAS EN EL MISMO NIVEL: VIVIENDA"},
{ value: "W181",	text: "OTRAS CAIDAS EN EL MISMO NIVEL: INSTITUCION RESIDENCIAL"},
{ value: "W182",	text: "OTRAS CAIDAS EN EL MISMO NIVEL: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W183",	text: "OTRAS CAIDAS EN EL MISMO NIVEL: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W184",	text: "OTRAS CAIDAS EN EL MISMO NIVEL: CALLES Y CARRETERAS"},
{ value: "W185",	text: "OTRAS CAIDAS EN EL MISMO NIVEL: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W186",	text: "OTRAS CAIDAS EN EL MISMO NIVEL: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W187",	text: "OTRAS CAIDAS EN EL MISMO NIVEL: GRANJA"},
{ value: "W188",	text: "OTRAS CAIDAS EN EL MISMO NIVEL: OTRO LUGAR ESPECIFICADO"},
{ value: "W189",	text: "OTRAS CAIDAS EN EL MISMO NIVEL: LUGAR NO ESPECIFICADO"},
{ value: "W190",	text: "CAIDA NO ESPECIFICADA: VIVIENDA"},
{ value: "W191",	text: "CAIDA NO ESPECIFICADA: INSTITUCIÓN RESIDENCIAL"},
{ value: "W192",	text: "CAIDA NO ESPECIFICADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W193",	text: "CAIDA NO ESPECIFICADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W194",	text: "CAIDA NO ESPECIFICADA: CALLES Y CARRETERAS"},
{ value: "W195",	text: "CAIDA NO ESPECIFICADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W196",	text: "CAIDA NO ESPECIFICADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W197",	text: "CAIDA NO ESPECIFICADA: GRANJA"},
{ value: "W198",	text: "CAIDA NO ESPECIFICADA: OTRO LUGAR ESPECIFICADO"},
{ value: "W199",	text: "CAIDA NO ESPECIFICADA: LUGAR NO ESPECIFICADO"},
{ value: "W200",	text: "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE: VIVIENDA"},
{ value: "W201",	text: "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE: INSTITUCION RESIDENCIAL"},
{ value: "W202",	text: "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W203",	text: "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W204",	text: "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE: CALLES Y CARRETERAS"},
{ value: "W205",	text: "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W206",	text: "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W207",	text: "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE: GRANJA"},
{ value: "W208",	text: "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE: OTRO LUGAR ESPECIFICADO"},
{ value: "W209",	text: "GOLPE POR OBJETO ARROJADO, PROYECTADO O QUE CAE: LUGAR NO ESPECIFICADO"},
{ value: "W210",	text: "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES: VIVIENDA"},
{ value: "W211",	text: "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES: INSTITUCION RESIDENCIAL"},
{ value: "W212",	text: "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W213",	text: "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W214",	text: "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES: CALLES Y CARRETERAS"},
{ value: "W215",	text: "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W216",	text: "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W217",	text: "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES: GRANJA"},
{ value: "W218",	text: "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES: OTRO LUGAR ESPECIFICADO"},
{ value: "W219",	text: "GOLPE CONTRA O GOLPEADO POR EQUIPO PARA DEPORTES: LUGAR NO ESPECIFICADO"},
{ value: "W220",	text: "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS: VIVIENDA"},
{ value: "W221",	text: "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS: INSTITUCION RESIDENCIAL"},
{ value: "W222",	text: "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W223",	text: "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W224",	text: "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS: CALLES Y CARRETERAS"},
{ value: "W225",	text: "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W226",	text: "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W227",	text: "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS: GRANJA"},
{ value: "W228",	text: "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS: OTRO LUGAR ESPECIFICADO"},
{ value: "W229",	text: "GOLPE CONTRA O GOLPEADO POR OTROS OBJETOS: LUGAR NO ESPECIFICADO"},
{ value: "W230",	text: "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS: VIVIENDA"},
{ value: "W231",	text: "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS: INSTITUCION RESIDENCIAL"},
{ value: "W232",	text: "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W233",	text: "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W234",	text: "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS: CALLES Y CARRETERAS"},
{ value: "W235",	text: "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W236",	text: "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W237",	text: "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS: GRANJA"},
{ value: "W238",	text: "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS: OTRO LUGAR ESPECIFICADO"},
{ value: "W239",	text: "ATRAPADO, APLASTADO, TRABADO O APRETADO EN O ENTRE OBJETOS: LUGAR NO ESPECIFICADO"},
{ value: "W240",	text: "CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION, NO CLASIFICADOS EN OTRA PARTE: VIVIENDA"},
{ value: "W241",	text: "CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION, NO CLASIFICADOS EN OTRA PARTE: INSTITUCION RESIDENCIAL"},
{ value: "W242",	text: "CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION, NO CLASIFICADOS EN OTRA PARTE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W243",	text: "CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION, NO CLASIFICADOS EN OTRA PARTE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W244",	text: "CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION, NO CLASIFICADOS EN OTRA PARTE: CALLES Y CARRETERAS"},
{ value: "W245",	text: "CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION, NO CLASIFICADOS EN OTRA PARTE: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W246",	text: "CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION, NO CLASIFICADOS EN OTRA PARTE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W247",	text: "CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION, NO CLASIFICADOS EN OTRA PARTE: GRANJA"},
{ value: "W248",	text: "CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION, NO CLASIFICADOS EN OTRA PARTE: OTRO LUGAR ESPECIFICADO"},
{ value: "W249",	text: "CONTACTO TRAUMATICO CON DISPOSITIVOS DE ELEVACION Y TRANSMISION, NO CLASIFICADOS EN OTRA PARTE: LUGAR NO ESPECIFICADO"},
{ value: "W250",	text: "CONTACTO TRAUMATICO CON VIDRIO CORTANTE: VIVIENDA"},
{ value: "W251",	text: "CONTACTO TRAUMATICO CON VIDRIO CORTANTE: INSTITUCION RESIDENCIAL"},
{ value: "W252",	text: "CONTACTO TRAUMATICO CON VIDRIO CORTANTE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W253",	text: "CONTACTO TRAUMATICO CON VIDRIO CORTANTE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W254",	text: "CONTACTO TRAUMATICO CON VIDRIO CORTANTE: CALLES Y CARRETERAS"},
{ value: "W255",	text: "CONTACTO TRAUMATICO CON VIDRIO CORTANTE: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W256",	text: "CONTACTO TRAUMATICO CON VIDRIO CORTANTE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W257",	text: "CONTACTO TRAUMATICO CON VIDRIO CORTANTE: GRANJA"},
{ value: "W258",	text: "CONTACTO TRAUMATICO CON VIDRIO CORTANTE: OTRO LUGAR ESPECIFICADO"},
{ value: "W259",	text: "CONTACTO TRAUMATICO CON VIDRIO CORTANTE: LUGAR NO ESPECIFICADO"},
{ value: "W260",	text: "CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PUÑAL: VIVIENDA"},
{ value: "W261",	text: "CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PUÑAL: INSTITUCION RESIDENCIAL"},
{ value: "W262",	text: "CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PUÑAL: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W263",	text: "CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PUÑAL: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W264",	text: "CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PUÑAL: CALLES Y CARRETERAS"},
{ value: "W265",	text: "CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PUÑAL: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W266",	text: "CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PUÑAL: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W267",	text: "CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PUÑAL: GRANJA"},
{ value: "W268",	text: "CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PUÑAL: OTRO LUGAR ESPECIFICADO"},
{ value: "W269",	text: "CONTACTO TRAUMATICO CON CUCHILLO, ESPADA, DAGA O PUÑAL: LUGAR NO ESPECIFICADO"},
{ value: "W270",	text: "CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR: VIVIENDA"},
{ value: "W271",	text: "CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR: INSTITUCION RESIDENCIAL"},
{ value: "W272",	text: "CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W273",	text: "CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W274",	text: "CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR: CALLES Y CARRETERAS"},
{ value: "W275",	text: "CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W276",	text: "CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W277",	text: "CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR: GRANJA"},
{ value: "W278",	text: "CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR: OTRO LUGAR ESPECIFICADO"},
{ value: "W279",	text: "CONTACTO TRAUMATICO CON HERRAMIENTAS MANUALES SIN MOTOR: LUGAR NO ESPECIFICADO"},
{ value: "W280",	text: "CONTACTO TRAUMATICO CON CORTADORA DE CESPED, CON MOTOR: VIVIENDA"},
{ value: "W281",	text: "CONTACTO TRAUMATICO CON CORTADORA DE CESPED, CON MOTOR: INSTITUCION RESIDENCIAL"},
{ value: "W282",	text: "CONTACTO TRAUMATICO CON CORTADORA DE CESPED, CON MOTOR: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W283",	text: "CONTACTO TRAUMATICO CON CORTADORA DE CESPED, CON MOTOR: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W284",	text: "CONTACTO TRAUMATICO CON CORTADORA DE CESPED, CON MOTOR: CALLES Y CARRETERAS"},
{ value: "W285",	text: "CONTACTO TRAUMATICO CON CORTADORA DE CESPED, CON MOTOR: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W286",	text: "CONTACTO TRAUMATICO CON CORTADORA DE CESPED, CON MOTOR: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W287",	text: "CONTACTO TRAUMATICO CON CORTADORA DE CESPED, CON MOTOR: GRANJA"},
{ value: "W288",	text: "CONTACTO TRAUMATICO CON CORTADORA DE CESPED, CON MOTOR: OTRO LUGAR ESPECIFICADO"},
{ value: "W289",	text: "CONTACTO TRAUMATICO CON CORTADORA DE CESPED, CON MOTOR: LUGAR NO ESPECIFICADO"},
{ value: "W290",	text: "CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR: VIVIENDA"},
{ value: "W291",	text: "CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR: INSTITUCION RESIDENCIAL"},
{ value: "W292",	text: "CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W293",	text: "CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W294",	text: "CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR: CALLES Y CARRETERAS"},
{ value: "W295",	text: "CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W296",	text: "CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W297",	text: "CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR: GRANJA"},
{ value: "W298",	text: "CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR: OTRO LUGAR ESPECIFICADO"},
{ value: "W299",	text: "CONTACTO TRAUMATICO CON OTRAS HERRAMIENTAS MANUALES Y ARTEFACTOS DEL HOGAR, CON MOTOR: LUGAR NO ESPECIFICADO"},
{ value: "W300",	text: "CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA: VIVIENDA"},
{ value: "W301",	text: "CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA: INSTITUCION RESIDENCIAL"},
{ value: "W302",	text: "CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W303",	text: "CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W304",	text: "CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA: CALLES Y CARRETERAS"},
{ value: "W305",	text: "CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W306",	text: "CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W307",	text: "CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA: GRANJA"},
{ value: "W308",	text: "CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA: OTRO LUGAR ESPECIFICADO"},
{ value: "W309",	text: "CONTACTO TRAUMATICO CON MAQUINARIA AGRICOLA: LUGAR NO ESPECIFICADO"},
{ value: "W310",	text: "CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS Y LAS NO ESPECIFICADAS: VIVIENDA"},
{ value: "W311",	text: "CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS Y LAS NO ESPECIFICADAS: INSTITUCION RESIDENCIAL"},
{ value: "W312",	text: "CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS Y LAS NO ESPECIFICADAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W313",	text: "CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS Y LAS NO ESPECIFICADAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W314",	text: "CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS Y LAS NO ESPECIFICADAS: CALLES Y CARRETERAS"},
{ value: "W315",	text: "CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS Y LAS NO ESPECIFICADAS: COMERCIO Y AREA DE SERVICIOS"},
{ value: "W316",	text: "CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS Y LAS NO ESPECIFICADAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W317",	text: "CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS Y LAS NO ESPECIFICADAS: GRANJA"},
{ value: "W318",	text: "CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS Y LAS NO ESPECIFICADAS: OTRO LUGAR ESPECIFICADO"},
{ value: "W319",	text: "CONTACTO TRAUMATICO CON OTRAS MAQUINARIAS Y LAS NO ESPECIFICADAS: LUGAR NO ESPECIFICADO"},
{ value: "W320",	text: "DISPARO DE ARMA CORTA: VIVIENDA"},
{ value: "W321",	text: "DISPARO DE ARMA CORTA: INSTITUCION RESIDENCIAL"},
{ value: "W322",	text: "DISPARO DE ARMA CORTA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W323",	text: "DISPARO DE ARMA CORTA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W324",	text: "DISPARO DE ARMA CORTA: CALLES Y CARRETERAS"},
{ value: "W325",	text: "DISPARO DE ARMA CORTA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W326",	text: "DISPARO DE ARMA CORTA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W327",	text: "DISPARO DE ARMA CORTA: GRANJA"},
{ value: "W328",	text: "DISPARO DE ARMA CORTA: OTRO LUGAR ESPECIFICADO"},
{ value: "W329",	text: "DISPARO DE ARMA CORTA: LUGAR NO ESPECIFICADO"},
{ value: "W330",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: VIVIENDA"},
{ value: "W331",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: INSTITUCION RESIDENCIAL"},
{ value: "W332",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W333",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W334",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: CALLES Y CARRETERAS"},
{ value: "W335",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W336",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W337",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: GRANJA"},
{ value: "W338",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: OTRO LUGAR ESPECIFICADO"},
{ value: "W339",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: LUGAR NO ESPECIFICADO"},
{ value: "W340",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: VIVIENDA"},
{ value: "W341",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: INSTITUCION RESIDENCIAL"},
{ value: "W342",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W343",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W344",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: CALLES Y CARRETERAS"},
{ value: "W345",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W346",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W347",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: GRANJA"},
{ value: "W348",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: OTRO LUGAR ESPECIFICADO"},
{ value: "W349",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: LUGAR NO ESPECIFICADO"},
{ value: "W350",	text: "EXPLOSION Y ROTURA DE CALDERA: VIVIENDA"},
{ value: "W351",	text: "EXPLOSION Y ROTURA DE CALDERA: INSTITUCION RESIDENCIAL"},
{ value: "W352",	text: "EXPLOSION Y ROTURA DE CALDERA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W353",	text: "EXPLOSION Y ROTURA DE CALDERA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W354",	text: "EXPLOSION Y ROTURA DE CALDERA: CALLES Y CARRETERAS"},
{ value: "W355",	text: "EXPLOSION Y ROTURA DE CALDERA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W356",	text: "EXPLOSION Y ROTURA DE CALDERA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W357",	text: "EXPLOSION Y ROTURA DE CALDERA: GRANJA"},
{ value: "W358",	text: "EXPLOSION Y ROTURA DE CALDERA: OTRO LUGAR ESPECIFICADO"},
{ value: "W359",	text: "EXPLOSION Y ROTURA DE CALDERA: LUGAR NO ESPECIFICADO"},
{ value: "W360",	text: "EXPLOSION Y ROTURA DE CILINDRO CON GAS: VIVIENDA"},
{ value: "W361",	text: "EXPLOSION Y ROTURA DE CILINDRO CON GAS: INSTITUCION RESIDENCIAL"},
{ value: "W362",	text: "EXPLOSION Y ROTURA DE CILINDRO CON GAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W363",	text: "EXPLOSION Y ROTURA DE CILINDRO CON GAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W364",	text: "EXPLOSION Y ROTURA DE CILINDRO CON GAS: CALLES Y CARRETERAS"},
{ value: "W365",	text: "EXPLOSION Y ROTURA DE CILINDRO CON GAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W366",	text: "EXPLOSION Y ROTURA DE CILINDRO CON GAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W367",	text: "EXPLOSION Y ROTURA DE CILINDRO CON GAS: GRANJA"},
{ value: "W368",	text: "EXPLOSION Y ROTURA DE CILINDRO CON GAS: OTRO LUGAR ESPECIFICADO"},
{ value: "W369",	text: "EXPLOSION Y ROTURA DE CILINDRO CON GAS: LUGAR NO ESPECIFICADO"},
{ value: "W370",	text: "EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA: VIVIENDA"},
{ value: "W371",	text: "EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA: INSTITUCION RESIDENCIAL"},
{ value: "W372",	text: "EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W373",	text: "EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W374",	text: "EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA: CALLES Y CARRETERAS"},
{ value: "W375",	text: "EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W376",	text: "EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W377",	text: "EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA: GRANJA"},
{ value: "W378",	text: "EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA: OTRO LUGAR ESPECIFICADO"},
{ value: "W379",	text: "EXPLOSION Y ROTURA DE NEUMATICO, TUBO O MANGUERA DE GOMA PRESURIZADA: LUGAR NO ESPECIFICADO"},
{ value: "W380",	text: "EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS: VIVIENDA"},
{ value: "W381",	text: "EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "W382",	text: "EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W383",	text: "EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W384",	text: "EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "W385",	text: "EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W386",	text: "EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W387",	text: "EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS: GRANJA"},
{ value: "W388",	text: "EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "W389",	text: "EXPLOSION Y ROTURA DE OTROS DISPOSITIVOS PRESURIZADOS ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "W390",	text: "EXPLOSION DE FUEGOS ARTIFICIALES: VIVIENDA"},
{ value: "W391",	text: "EXPLOSION DE FUEGOS ARTIFICIALES: INSTITUCION RESIDENCIAL"},
{ value: "W392",	text: "EXPLOSION DE FUEGOS ARTIFICIALES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W393",	text: "EXPLOSION DE FUEGOS ARTIFICIALES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W394",	text: "EXPLOSION DE FUEGOS ARTIFICIALES: CALLES Y CARRETERAS"},
{ value: "W395",	text: "EXPLOSION DE FUEGOS ARTIFICIALES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W396",	text: "EXPLOSION DE FUEGOS ARTIFICIALES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W397",	text: "EXPLOSION DE FUEGOS ARTIFICIALES: GRANJA"},
{ value: "W398",	text: "EXPLOSION DE FUEGOS ARTIFICIALES: OTRO LUGAR ESPECIFICADO"},
{ value: "W399",	text: "EXPLOSION DE FUEGOS ARTIFICIALES: LUGAR NO ESPECIFICADO"},
{ value: "W400",	text: "EXPLOSION DE OTROS MATERIALES: VIVIENDA"},
{ value: "W401",	text: "EXPLOSION DE OTROS MATERIALES: INSTITUCION RESIDENCIAL"},
{ value: "W402",	text: "EXPLOSION DE OTROS MATERIALES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W403",	text: "EXPLOSION DE OTROS MATERIALES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W404",	text: "EXPLOSION DE OTROS MATERIALES: CALLES Y CARRETERAS"},
{ value: "W405",	text: "EXPLOSION DE OTROS MATERIALES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W406",	text: "EXPLOSION DE OTROS MATERIALES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W407",	text: "EXPLOSION DE OTROS MATERIALES: GRANJA"},
{ value: "W408",	text: "EXPLOSION DE OTROS MATERIALES: OTRO LUGAR ESPECIFICADO"},
{ value: "W409",	text: "EXPLOSION DE OTROS MATERIALES: LUGAR NO ESPECIFICADO"},
{ value: "W410",	text: "EXPOSICION A CHORRO DE ALTA PRESION: VIVIENDA"},
{ value: "W411",	text: "EXPOSICION A CHORRO DE ALTA PRESION: INSTITUCION RESIDENCIAL"},
{ value: "W412",	text: "EXPOSICION A CHORRO DE ALTA PRESION: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W413",	text: "EXPOSICION A CHORRO DE ALTA PRESION: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W414",	text: "EXPOSICION A CHORRO DE ALTA PRESION: CALLES Y CARRETERAS"},
{ value: "W415",	text: "EXPOSICION A CHORRO DE ALTA PRESION: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W416",	text: "EXPOSICION A CHORRO DE ALTA PRESION: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W417",	text: "EXPOSICION A CHORRO DE ALTA PRESION: GRANJA"},
{ value: "W418",	text: "EXPOSICION A CHORRO DE ALTA PRESION: OTRO LUGAR ESPECIFICADO"},
{ value: "W419",	text: "EXPOSICION A CHORRO DE ALTA PRESION: LUGAR NO ESPECIFICADO"},
{ value: "W420",	text: "EXPOSICION AL RUIDO: VIVIENDA"},
{ value: "W421",	text: "EXPOSICION AL RUIDO: INSTITUCION RESIDENCIAL"},
{ value: "W422",	text: "EXPOSICION AL RUIDO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W423",	text: "EXPOSICION AL RUIDO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W424",	text: "EXPOSICION AL RUIDO: CALLES Y CARRETERAS"},
{ value: "W425",	text: "EXPOSICION AL RUIDO: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W426",	text: "EXPOSICION AL RUIDO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W427",	text: "EXPOSICION AL RUIDO: GRANJA"},
{ value: "W428",	text: "EXPOSICION AL RUIDO: OTRO LUGAR ESPECIFICADO"},
{ value: "W429",	text: "EXPOSICION AL RUIDO: LUGAR NO ESPECIFICADO"},
{ value: "W430",	text: "EXPOSICION A VIBRACIONES: VIVIENDA"},
{ value: "W431",	text: "EXPOSICION A VIBRACIONES: INSTITUCION RESIDENCIAL"},
{ value: "W432",	text: "EXPOSICION A VIBRACIONES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W433",	text: "EXPOSICION A VIBRACIONES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W434",	text: "EXPOSICION A VIBRACIONES: CALLES Y CARRETERAS"},
{ value: "W435",	text: "EXPOSICION A VIBRACIONES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W436",	text: "EXPOSICION A VIBRACIONES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W437",	text: "EXPOSICION A VIBRACIONES: GRANJA"},
{ value: "W438",	text: "EXPOSICION A VIBRACIONES: OTRO LUGAR ESPECIFICADO"},
{ value: "W439",	text: "EXPOSICION A VIBRACIONES: LUGAR NO ESPECIFICADO"},
{ value: "W440",	text: "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL: VIVIENDA"},
{ value: "W441",	text: "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL: INSTITUCION RESIDENCIAL"},
{ value: "W442",	text: "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W443",	text: "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W444",	text: "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL: CALLES Y CARRETERAS"},
{ value: "W445",	text: "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W446",	text: "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W447",	text: "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL: GRANJA"},
{ value: "W448",	text: "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL: OTRO LUGAR ESPECIFICADO"},
{ value: "W449",	text: "CUERPO EXTRAÑO QUE PENETRA POR EL OJO U ORIFICIO NATURAL: LUGAR NO ESPECIFICADO"},
{ value: "W450",	text: "CUERPO EXTRAÑO QUE PENETRA A TRAVES DE LA PIEL: VIVIENDA"},
{ value: "W451",	text: "CUERPO EXTRAÑO QUE PENETRA A TRAVES DE LA PIEL: INSTITUCION RESIDENCIAL"},
{ value: "W452",	text: "CUERPO EXTRAÑO QUE PENETRA A TRAVES DE LA PIEL: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W453",	text: "CUERPO EXTRAÑO QUE PENETRA A TRAVES DE LA PIEL: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W454",	text: "CUERPO EXTRAÑO QUE PENETRA A TRAVES DE LA PIEL: CALLES Y CARRETERAS"},
{ value: "W455",	text: "CUERPO EXTRAÑO QUE PENETRA A TRAVES DE LA PIEL: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W456",	text: "CUERPO EXTRAÑO QUE PENETRA A TRAVES DE LA PIEL: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W457",	text: "CUERPO EXTRAÑO QUE PENETRA A TRAVES DE LA PIEL: GRANJA"},
{ value: "W458",	text: "CUERPO EXTRAÑO QUE PENETRA A TRAVES DE LA PIEL: OTRO LUGAR ESPECIFICADO"},
{ value: "W459",	text: "CUERPO EXTRAÑO QUE PENETRA A TRAVES DE LA PIEL: LUGAR NO ESPECIFICADO"},
{ value: "W490",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS: VIVIENDA"},
{ value: "W491",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS: INSTITUCION RESIDENCIAL"},
{ value: "W492",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W493",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W494",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS: CALLES Y CARRETERAS"},
{ value: "W495",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W496",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W497",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS: GRANJA"},
{ value: "W498",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS: OTRO LUGAR ESPECIFICADO"},
{ value: "W499",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS INANIMADAS, Y LAS NO ESPECIFICADAS: LUGAR NO ESPECIFICADO"},
{ value: "W500",	text: "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA: VIVIENDA"},
{ value: "W501",	text: "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA: INSTITUCION RESIDENCIAL"},
{ value: "W502",	text: "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W503",	text: "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W504",	text: "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA: CALLES Y CARRETERAS"},
{ value: "W505",	text: "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W506",	text: "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W507",	text: "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA: GRANJA"},
{ value: "W508",	text: "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA: OTRO LUGAR ESPECIFICADO"},
{ value: "W509",	text: "APORREO, GOLPE, MORDEDURA, PATADA, RASGUÑO O TORCEDURA INFLIGIDOS POR OTRA PERSONA: LUGAR NO ESPECIFICADO"},
{ value: "W510",	text: "CHOQUE O EMPELLON CONTRA OTRA PERSONA: VIVIENDA"},
{ value: "W511",	text: "CHOQUE O EMPELLON CONTRA OTRA PERSONA: INSTITUCION RESIDENCIAL"},
{ value: "W512",	text: "CHOQUE O EMPELLON CONTRA OTRA PERSONA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W513",	text: "CHOQUE O EMPELLON CONTRA OTRA PERSONA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W514",	text: "CHOQUE O EMPELLON CONTRA OTRA PERSONA: CALLES Y CARRETERAS"},
{ value: "W515",	text: "CHOQUE O EMPELLON CONTRA OTRA PERSONA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W516",	text: "CHOQUE O EMPELLON CONTRA OTRA PERSONA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W517",	text: "CHOQUE O EMPELLON CONTRA OTRA PERSONA: GRANJA"},
{ value: "W518",	text: "CHOQUE O EMPELLON CONTRA OTRA PERSONA: OTRO LUGAR ESPECIFICADO"},
{ value: "W519",	text: "CHOQUE O EMPELLON CONTRA OTRA PERSONA: LUGAR NO ESPECIFICADO"},
{ value: "W520",	text: "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA: VIVIENDA"},
{ value: "W521",	text: "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA: INSTITUCION RESIDENCIAL"},
{ value: "W522",	text: "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W523",	text: "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W524",	text: "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA: CALLES Y CARRETERAS"},
{ value: "W525",	text: "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W526",	text: "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W527",	text: "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA: GRANJA"},
{ value: "W528",	text: "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA: OTRO LUGAR ESPECIFICADO"},
{ value: "W529",	text: "PERSONA APLASTADA, EMPUJADA O PISOTEADA POR UNA MULTITUD O ESTAMPIDA HUMANA: LUGAR NO ESPECIFICADO"},
{ value: "W530",	text: "MORDEDURA DE RATA: VIVIENDA"},
{ value: "W531",	text: "MORDEDURA DE RATA: INSTITUCION RESIDENCIAL"},
{ value: "W532",	text: "MORDEDURA DE RATA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W533",	text: "MORDEDURA DE RATA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W534",	text: "MORDEDURA DE RATA: CALLES Y CARRETERAS"},
{ value: "W535",	text: "MORDEDURA DE RATA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W536",	text: "MORDEDURA DE RATA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W537",	text: "MORDEDURA DE RATA: GRANJA"},
{ value: "W538",	text: "MORDEDURA DE RATA: OTRO LUGAR ESPECIFICADO"},
{ value: "W539",	text: "MORDEDURA DE RATA: LUGAR NO ESPECIFICADO"},
{ value: "W540",	text: "MORDEDURA O ATAQUE DE PERRO: VIVIENDA"},
{ value: "W541",	text: "MORDEDURA O ATAQUE DE PERRO: INSTITUCION RESIDENCIAL"},
{ value: "W542",	text: "MORDEDURA O ATAQUE DE PERRO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W543",	text: "MORDEDURA O ATAQUE DE PERRO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W544",	text: "MORDEDURA O ATAQUE DE PERRO: CALLES Y CARRETERAS"},
{ value: "W545",	text: "MORDEDURA O ATAQUE DE PERRO: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W546",	text: "MORDEDURA O ATAQUE DE PERRO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W547",	text: "MORDEDURA O ATAQUE DE PERRO: GRANJA"},
{ value: "W548",	text: "MORDEDURA O ATAQUE DE PERRO: OTRO LUGAR ESPECIFICADO"},
{ value: "W549",	text: "MORDEDURA O ATAQUE DE PERRO: LUGAR NO ESPECIFICADO"},
{ value: "W550",	text: "MORDEDURA O ATAQUE DE OTROS MAMIFEROS: VIVIENDA"},
{ value: "W551",	text: "MORDEDURA O ATAQUE DE OTROS MAMIFEROS: INSTITUCION RESIDENCIAL"},
{ value: "W552",	text: "MORDEDURA O ATAQUE DE OTROS MAMIFEROS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W553",	text: "MORDEDURA O ATAQUE DE OTROS MAMIFEROS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W554",	text: "MORDEDURA O ATAQUE DE OTROS MAMIFEROS: CALLES Y CARRETERAS"},
{ value: "W555",	text: "MORDEDURA O ATAQUE DE OTROS MAMIFEROS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W556",	text: "MORDEDURA O ATAQUE DE OTROS MAMIFEROS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W557",	text: "MORDEDURA O ATAQUE DE OTROS MAMIFEROS: GRANJA"},
{ value: "W558",	text: "MORDEDURA O ATAQUE DE OTROS MAMIFEROS: OTRO LUGAR ESPECIFICADO"},
{ value: "W559",	text: "MORDEDURA O ATAQUE DE OTROS MAMIFEROS: LUGAR NO ESPECIFICADO"},
{ value: "W560",	text: "CONTACTO TRAUMATICO CON ANIMALES MARINOS: VIVIENDA"},
{ value: "W561",	text: "CONTACTO TRAUMATICO CON ANIMALES MARINOS: INSTITUCION RESIDENCIAL"},
{ value: "W562",	text: "CONTACTO TRAUMATICO CON ANIMALES MARINOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W563",	text: "CONTACTO TRAUMATICO CON ANIMALES MARINOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W564",	text: "CONTACTO TRAUMATICO CON ANIMALES MARINOS: CALLES Y CARRETERAS"},
{ value: "W565",	text: "CONTACTO TRAUMATICO CON ANIMALES MARINOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W566",	text: "CONTACTO TRAUMATICO CON ANIMALES MARINOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W567",	text: "CONTACTO TRAUMATICO CON ANIMALES MARINOS: GRANJA"},
{ value: "W568",	text: "CONTACTO TRAUMATICO CON ANIMALES MARINOS: OTRO LUGAR ESPECIFICADO"},
{ value: "W569",	text: "CONTACTO TRAUMATICO CON ANIMALES MARINOS: LUGAR NO ESPECIFICADO"},
{ value: "W570",	text: "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS: VIVIENDA"},
{ value: "W571",	text: "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS: INSTITUCION RESIDENCIAL"},
{ value: "W572",	text: "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W573",	text: "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W574",	text: "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS: CALLES Y CARRETERAS"},
{ value: "W575",	text: "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W576",	text: "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W577",	text: "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS: GRANJA"},
{ value: "W578",	text: "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS: OTRO LUGAR ESPECIFICADO"},
{ value: "W579",	text: "MORDEDURA O PICADURA DE INSECTOS Y OTROS ARTROPODOS NO VENENOSOS: LUGAR NO ESPECIFICADO"},
{ value: "W580",	text: "MORDEDURA O ATAQUE DE COCODRILO O CAIMAN: VIVIENDA"},
{ value: "W581",	text: "MORDEDURA O ATAQUE DE COCODRILO O CAIMAN: INSTITUCION RESIDENCIAL"},
{ value: "W582",	text: "MORDEDURA O ATAQUE DE COCODRILO O CAIMAN: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W583",	text: "MORDEDURA O ATAQUE DE COCODRILO O CAIMAN: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W584",	text: "MORDEDURA O ATAQUE DE COCODRILO O CAIMAN: CALLES Y CARRETERAS"},
{ value: "W585",	text: "MORDEDURA O ATAQUE DE COCODRILO O CAIMAN: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W586",	text: "MORDEDURA O ATAQUE DE COCODRILO O CAIMAN: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W587",	text: "MORDEDURA O ATAQUE DE COCODRILO O CAIMAN: GRANJA"},
{ value: "W588",	text: "MORDEDURA O ATAQUE DE COCODRILO O CAIMAN: OTRO LUGAR ESPECIFICADO"},
{ value: "W589",	text: "MORDEDURA O ATAQUE DE COCODRILO O CAIMAN: LUGAR NO ESPECIFICADO"},
{ value: "W590",	text: "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES: VIVIENDA"},
{ value: "W591",	text: "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES: INSTITUCION RESIDENCIAL"},
{ value: "W592",	text: "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W593",	text: "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W594",	text: "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES: CALLES Y CARRETERAS"},
{ value: "W595",	text: "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W596",	text: "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W597",	text: "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES: GRANJA"},
{ value: "W598",	text: "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES: OTRO LUGAR ESPECIFICADO"},
{ value: "W599",	text: "MORDEDURA O APLASTAMIENTO POR OTROS REPTILES: LUGAR NO ESPECIFICADO"},
{ value: "W600",	text: "CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS: VIVIENDA"},
{ value: "W601",	text: "CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS: INSTITUCION RESIDENCIAL"},
{ value: "W602",	text: "CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W603",	text: "CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W604",	text: "CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS: CALLES Y CARRETERAS"},
{ value: "W605",	text: "CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W606",	text: "CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W607",	text: "CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS: GRANJA"},
{ value: "W608",	text: "CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS: OTRO LUGAR ESPECIFICADO"},
{ value: "W609",	text: "CONTACTO TRAUMATICO CON AGUIJONES, ESPINAS U HOJAS CORTANTES DE PLANTAS: LUGAR NO ESPECIFICADO"},
{ value: "W640",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS: VIVIENDA"},
{ value: "W641",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS: INSTITUCION RESIDENCIAL"},
{ value: "W642",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W643",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W644",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS: CALLES Y CARRETERAS"},
{ value: "W645",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W646",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W647",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS: GRANJA"},
{ value: "W648",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS: OTRO LUGAR ESPECIFICADO"},
{ value: "W649",	text: "EXPOSICION A OTRAS FUERZAS MECANICAS ANIMADAS, Y LAS NO ESPECIFICADAS: LUGAR NO ESPECIFICADO"},
{ value: "W650",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BAÑERA: VIVIENDA"},
{ value: "W651",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BAÑERA: INSTITUCION RESIDENCIAL"},
{ value: "W652",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BAÑERA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W653",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BAÑERA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W654",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BAÑERA: CALLES Y CARRETERAS"},
{ value: "W655",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BAÑERA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W656",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BAÑERA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W657",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BAÑERA: GRANJA"},
{ value: "W658",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BAÑERA: OTRO LUGAR ESPECIFICADO"},
{ value: "W659",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN LA BAÑERA: LUGAR NO ESPECIFICADO"},
{ value: "W660",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BAÑERA: VIVIENDA"},
{ value: "W661",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BAÑERA: INSTITUCION RESIDENCIAL"},
{ value: "W662",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BAÑERA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W663",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BAÑERA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W664",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BAÑERA: CALLES Y CARRETERAS"},
{ value: "W665",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BAÑERA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W666",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BAÑERA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W667",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BAÑERA: GRANJA"},
{ value: "W668",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BAÑERA: OTRO LUGAR ESPECIFICADO"},
{ value: "W669",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN LA BAÑERA: LUGAR NO ESPECIFICADO"},
{ value: "W670",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UNA PISCINA: VIVIENDA"},
{ value: "W671",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UNA PISCINA: INSTITUCION RESIDENCIAL"},
{ value: "W672",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UNA PISCINA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W673",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UNA PISCINA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W674",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UNA PISCINA: CALLES Y CARRETERAS"},
{ value: "W675",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UNA PISCINA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W676",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UNA PISCINA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W677",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UNA PISCINA: GRANJA"},
{ value: "W678",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UNA PISCINA: OTRO LUGAR ESPECIFICADO"},
{ value: "W679",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN UNA PISCINA: LUGAR NO ESPECIFICADO"},
{ value: "W680",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA: VIVIENDA"},
{ value: "W681",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA: INSTITUCION RESIDENCIAL"},
{ value: "W682",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W683",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W684",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA: CALLES Y CARRETERAS"},
{ value: "W685",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W686",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W687",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA: GRANJA"},
{ value: "W688",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA: OTRO LUGAR ESPECIFICADO"},
{ value: "W689",	text: "AHOGAMIENTO Y SUMERSION CONSECUTIVOS A CAIDA EN UNA PISCINA: LUGAR NO ESPECIFICADO"},
{ value: "W690",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES: VIVIENDA"},
{ value: "W691",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES: INSTITUCION RESIDENCIAL"},
{ value: "W692",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W693",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W694",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES: CALLES Y CARRETERAS"},
{ value: "W695",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W696",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W697",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES: GRANJA"},
{ value: "W698",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES: OTRO LUGAR ESPECIFICADO"},
{ value: "W699",	text: "AHOGAMIENTO Y SUMERSION MIENTRAS SE ESTA EN AGUAS NATURALES: LUGAR NO ESPECIFICADO"},
{ value: "W700",	text: "AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES: VIVIENDA"},
{ value: "W701",	text: "AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES: INSTITUCION RESIDENCIAL"},
{ value: "W702",	text: "AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W703",	text: "AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W704",	text: "AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES: CALLES Y CARRETERAS"},
{ value: "W705",	text: "AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W706",	text: "AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W707",	text: "AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES: GRANJA"},
{ value: "W708",	text: "AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES: OTRO LUGAR ESPECIFICADO"},
{ value: "W709",	text: "AHOGAMIENTO Y SUMERSION POSTERIOR A CAIDA EN AGUAS NATURALES: LUGAR NO ESPECIFICADO"},
{ value: "W730",	text: "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS: VIVIENDA"},
{ value: "W731",	text: "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "W732",	text: "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W733",	text: "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W734",	text: "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "W735",	text: "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W736",	text: "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W737",	text: "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS: GRANJA"},
{ value: "W738",	text: "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "W739",	text: "OTROS AHOGAMIENTOS Y SUMERSIONES ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "W740",	text: "AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS: VIVIENDA"},
{ value: "W741",	text: "AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "W742",	text: "AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W743",	text: "AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W744",	text: "AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "W745",	text: "AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W746",	text: "AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W747",	text: "AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS: GRANJA"},
{ value: "W748",	text: "AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "W749",	text: "AHOGAMIENTO Y SUMERSION NO ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "W750",	text: "SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA: VIVIENDA"},
{ value: "W751",	text: "SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA: INSTITUCION RESIDENCIAL"},
{ value: "W752",	text: "SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W753",	text: "SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W754",	text: "SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA: CALLES Y CARRETERAS"},
{ value: "W755",	text: "SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W756",	text: "SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W757",	text: "SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA: GRANJA"},
{ value: "W758",	text: "SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA: OTRO LUGAR ESPECIFICADO"},
{ value: "W759",	text: "SOFOCACION Y ESTRANGULAMIENTO ACCIDENTAL EN LA CAMA: LUGAR NO ESPECIFICADO"},
{ value: "W760",	text: "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES: VIVIENDA"},
{ value: "W761",	text: "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES: INSTITUCION RESIDENCIAL"},
{ value: "W762",	text: "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W763",	text: "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W764",	text: "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES: CALLES Y CARRETERAS"},
{ value: "W765",	text: "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W766",	text: "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W767",	text: "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES: GRANJA"},
{ value: "W768",	text: "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES: OTRO LUGAR ESPECIFICADO"},
{ value: "W769",	text: "OTROS ESTRANGULAMIENTOS Y AHORCAMIENTOS ACCIDENTALES: LUGAR NO ESPECIFICADO"},
{ value: "W770",	text: "OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS: VIVIENDA"},
{ value: "W771",	text: "OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS: INSTITUCION RESIDENCIAL"},
{ value: "W772",	text: "OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W773",	text: "OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W774",	text: "OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS: CALLES Y CARRETERAS"},
{ value: "W775",	text: "OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W776",	text: "OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W777",	text: "OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS: GRANJA"},
{ value: "W778",	text: "OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS: OTRO LUGAR ESPECIFICADO"},
{ value: "W779",	text: "OBSTRUCCION DE LA RESPIRACION DEBIDA A HUNDIMIENTO, CAIDA DE TIERRA U OTRAS SUSTANCIAS: LUGAR NO ESPECIFICADO"},
{ value: "W780",	text: "INHALACION DE CONTENIDOS GASTRICOS: VIVIENDA"},
{ value: "W781",	text: "INHALACION DE CONTENIDOS GASTRICOS: INSTITUCION RESIDENCIAL"},
{ value: "W782",	text: "INHALACION DE CONTENIDOS GASTRICOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W783",	text: "INHALACION DE CONTENIDOS GASTRICOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W784",	text: "INHALACION DE CONTENIDOS GASTRICOS: CALLES Y CARRETERAS"},
{ value: "W785",	text: "INHALACION DE CONTENIDOS GASTRICOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W786",	text: "INHALACION DE CONTENIDOS GASTRICOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W787",	text: "INHALACION DE CONTENIDOS GASTRICOS: GRANJA"},
{ value: "W788",	text: "INHALACION DE CONTENIDOS GASTRICOS: OTRO LUGAR ESPECIFICADO"},
{ value: "W789",	text: "INHALACION DE CONTENIDOS GASTRICOS: LUGAR NO ESPECIFICADO"},
{ value: "W790",	text: "INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION DE LAS VIAS RESPIRATORIAS: VIVIENDA"},
{ value: "W791",	text: "INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION DE LAS VIAS RESPIRATORIAS: INSTITUCION RESIDENCIAL"},
{ value: "W792",	text: "INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION DE LAS VIAS RESPIRATORIAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W793",	text: "INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION DE LAS VIAS RESPIRATORIAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W794",	text: "INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION DE LAS VIAS RESPIRATORIAS: CALLES Y CARRETERAS"},
{ value: "W795",	text: "INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION DE LAS VIAS RESPIRATORIAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W796",	text: "INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION DE LAS VIAS RESPIRATORIAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W797",	text: "INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION DE LAS VIAS RESPIRATORIAS: GRANJA"},
{ value: "W798",	text: "INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION DE LAS VIAS RESPIRATORIAS: OTRO LUGAR ESPECIFICADO"},
{ value: "W799",	text: "INHALACION E INGESTION DE ALIMENTO QUE CAUSA OBSTRUCCION DE LAS VIAS RESPIRATORIAS: LUGAR NO ESPECIFICADO"},
{ value: "W800",	text: "INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION DE LAS VIAS RESPIRATORIAS: VIVIENDA"},
{ value: "W801",	text: "INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION DE LAS VIAS RESPIRATORIAS: INSTITUCION RESIDENCIAL"},
{ value: "W802",	text: "INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION DE LAS VIAS RESPIRATORIAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W803",	text: "INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION DE LAS VIAS RESPIRATORIAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W804",	text: "INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION DE LAS VIAS RESPIRATORIAS: CALLES Y CARRETERAS"},
{ value: "W805",	text: "INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION DE LAS VIAS RESPIRATORIAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W806",	text: "INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION DE LAS VIAS RESPIRATORIAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W807",	text: "INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION DE LAS VIAS RESPIRATORIAS: GRANJA"},
{ value: "W808",	text: "INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION DE LAS VIAS RESPIRATORIAS: OTRO LUGAR ESPECIFICADO"},
{ value: "W809",	text: "INHALACION E INGESTION DE OTROS OBJETOS QUE CAUSAN OBSTRUCCION DE LAS VIAS RESPIRATORIAS: LUGAR NO ESPECIFICADO"},
{ value: "W810",	text: "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO: VIVIENDA"},
{ value: "W811",	text: "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO: INSTITUCION RESIDENCIAL"},
{ value: "W812",	text: "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W813",	text: "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W814",	text: "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO: CALLES Y CARRETERAS"},
{ value: "W815",	text: "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W816",	text: "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W817",	text: "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO: GRANJA"},
{ value: "W818",	text: "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO: OTRO LUGAR ESPECIFICADO"},
{ value: "W819",	text: "CONFINADO O ATRAPADO EN UN AMBIENTE CON BAJO CONTENIDO DE OXIGENO: LUGAR NO ESPECIFICADO"},
{ value: "W830",	text: "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION: VIVIENDA"},
{ value: "W831",	text: "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION: INSTITUCION RESIDENCIAL"},
{ value: "W832",	text: "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W833",	text: "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W834",	text: "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION: CALLES Y CARRETERAS"},
{ value: "W835",	text: "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W836",	text: "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W837",	text: "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION: GRANJA"},
{ value: "W838",	text: "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION: OTRO LUGAR ESPECIFICADO"},
{ value: "W839",	text: "OTRAS OBSTRUCCIONES ESPECIFICADAS DE LA RESPIRACION: LUGAR NO ESPECIFICADO"},
{ value: "W840",	text: "OBSTRUCCIONES NO ESPECIFICADA DE LA RESPIRACION: VIVIENDA"},
{ value: "W841",	text: "OBSTRUCCIONES NO ESPECIFICADA DE LA RESPIRACION: INSTITUCION RESIDENCIAL"},
{ value: "W842",	text: "OBSTRUCCIONES NO ESPECIFICADA DE LA RESPIRACION: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W843",	text: "OBSTRUCCIONES NO ESPECIFICADA DE LA RESPIRACION: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W844",	text: "OBSTRUCCIONES NO ESPECIFICADA DE LA RESPIRACION: CALLES Y CARRETERAS"},
{ value: "W845",	text: "OBSTRUCCIONES NO ESPECIFICADA DE LA RESPIRACION: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W846",	text: "OBSTRUCCIONES NO ESPECIFICADA DE LA RESPIRACION: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W847",	text: "OBSTRUCCIONES NO ESPECIFICADA DE LA RESPIRACION: GRANJA"},
{ value: "W848",	text: "OBSTRUCCIONES NO ESPECIFICADA DE LA RESPIRACION: OTRO LUGAR ESPECIFICADO"},
{ value: "W849",	text: "OBSTRUCCIONES NO ESPECIFICADA DE LA RESPIRACION: LUGAR NO ESPECIFICADO"},
{ value: "W850",	text: "EXPOSICION A LINEAS DE TRANSMISION ELECTRICA: VIVIENDA"},
{ value: "W851",	text: "EXPOSICION A LINEAS DE TRANSMISION ELECTRICA: INSTITUCION RESIDENCIAL"},
{ value: "W852",	text: "EXPOSICION A LINEAS DE TRANSMISION ELECTRICA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W853",	text: "EXPOSICION A LINEAS DE TRANSMISION ELECTRICA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W854",	text: "EXPOSICION A LINEAS DE TRANSMISION ELECTRICA: CALLES Y CARRETERAS"},
{ value: "W855",	text: "EXPOSICION A LINEAS DE TRANSMISION ELECTRICA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W856",	text: "EXPOSICION A LINEAS DE TRANSMISION ELECTRICA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W857",	text: "EXPOSICION A LINEAS DE TRANSMISION ELECTRICA: GRANJA"},
{ value: "W858",	text: "EXPOSICION A LINEAS DE TRANSMISION ELECTRICA: OTRO LUGAR ESPECIFICADO"},
{ value: "W859",	text: "EXPOSICION A LINEAS DE TRANSMISION ELECTRICA: LUGAR NO ESPECIFICADO"},
{ value: "W860",	text: "EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS: VIVIENDA"},
{ value: "W861",	text: "EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS: INSTITUCION RESIDENCIAL"},
{ value: "W862",	text: "EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W863",	text: "EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W864",	text: "EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS: CALLES Y CARRETERAS"},
{ value: "W865",	text: "EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W866",	text: "EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W867",	text: "EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS: GRANJA"},
{ value: "W868",	text: "EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS: OTRO LUGAR ESPECIFICADO"},
{ value: "W869",	text: "EXPOSICION A OTRAS CORRIENTES ELECTRICAS ESPECIFICADAS: LUGAR NO ESPECIFICADO"},
{ value: "W870",	text: "EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA: VIVIENDA"},
{ value: "W871",	text: "EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA: INSTITUCION RESIDENCIAL"},
{ value: "W872",	text: "EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W873",	text: "EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W874",	text: "EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA: CALLES Y CARRETERAS"},
{ value: "W875",	text: "EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W876",	text: "EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W877",	text: "EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA: GRANJA"},
{ value: "W878",	text: "EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA: OTRO LUGAR ESPECIFICADO"},
{ value: "W879",	text: "EXPOSICION A CORRIENTE ELECTRICA NO ESPECIFICADA: LUGAR NO ESPECIFICADO"},
{ value: "W880",	text: "EXPOSICION A RADIACION IONIZANTE: VIVIENDA"},
{ value: "W881",	text: "EXPOSICION A RADIACION IONIZANTE: INSTITUCION RESIDENCIAL"},
{ value: "W882",	text: "EXPOSICION A RADIACION IONIZANTE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W883",	text: "EXPOSICION A RADIACION IONIZANTE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W884",	text: "EXPOSICION A RADIACION IONIZANTE: CALLES Y CARRETERAS"},
{ value: "W885",	text: "EXPOSICION A RADIACION IONIZANTE: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W886",	text: "EXPOSICION A RADIACION IONIZANTE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W887",	text: "EXPOSICION A RADIACION IONIZANTE: GRANJA"},
{ value: "W888",	text: "EXPOSICION A RADIACION IONIZANTE: OTRO LUGAR ESPECIFICADO"},
{ value: "W889",	text: "EXPOSICION A RADIACION IONIZANTE: LUGAR NO ESPECIFICADO"},
{ value: "W890",	text: "EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL: VIVIENDA"},
{ value: "W891",	text: "EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL: INSTITUCION RESIDENCIAL"},
{ value: "W892",	text: "EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W893",	text: "EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W894",	text: "EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL: CALLES Y CARRETERAS"},
{ value: "W895",	text: "EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W896",	text: "EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W897",	text: "EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL: GRANJA"},
{ value: "W898",	text: "EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL: OTRO LUGAR ESPECIFICADO"},
{ value: "W899",	text: "EXPOSICION A FUENTE DE LUZ VISIBLE Y ULTRAVIOLETA, DE ORIGEN ARTIFICIAL: LUGAR NO ESPECIFICADO"},
{ value: "W900",	text: "EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE: VIVIENDA"},
{ value: "W901",	text: "EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE: INSTITUCION RESIDENCIAL"},
{ value: "W902",	text: "EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W903",	text: "EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W904",	text: "EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE: CALLES Y CARRETERAS"},
{ value: "W905",	text: "EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W906",	text: "EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W907",	text: "EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE: GRANJA"},
{ value: "W908",	text: "EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE: OTRO LUGAR ESPECIFICADO"},
{ value: "W909",	text: "EXPOSICION A OTROS TIPOS DE RADIACION NO IONIZANTE: LUGAR NO ESPECIFICADO"},
{ value: "W910",	text: "EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO: VIVIENDA"},
{ value: "W911",	text: "EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO: INSTITUCION RESIDENCIAL"},
{ value: "W912",	text: "EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W913",	text: "EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W914",	text: "EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO: CALLES Y CARRETERAS"},
{ value: "W915",	text: "EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W916",	text: "EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W917",	text: "EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO: GRANJA"},
{ value: "W918",	text: "EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO: OTRO LUGAR ESPECIFICADO"},
{ value: "W919",	text: "EXPOSICION A RADIACION DE TIPO NO ESPECIFICADO: LUGAR NO ESPECIFICADO"},
{ value: "W920",	text: "EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL: VIVIENDA"},
{ value: "W921",	text: "EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL: INSTITUCION RESIDENCIAL"},
{ value: "W922",	text: "EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W923",	text: "EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W924",	text: "EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL: CALLES Y CARRETERAS"},
{ value: "W925",	text: "EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W926",	text: "EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W927",	text: "EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL: GRANJA"},
{ value: "W928",	text: "EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL: OTRO LUGAR ESPECIFICADO"},
{ value: "W929",	text: "EXPOSICION A CALOR EXCESIVO DE ORIGEN ARTIFICIAL: LUGAR NO ESPECIFICADO"},
{ value: "W930",	text: "EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL: VIVIENDA"},
{ value: "W931",	text: "EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL: INSTITUCION RESIDENCIAL"},
{ value: "W932",	text: "EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W933",	text: "EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W934",	text: "EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL: CALLES Y CARRETERAS"},
{ value: "W935",	text: "EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W936",	text: "EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W937",	text: "EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL: GRANJA"},
{ value: "W938",	text: "EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL: OTRO LUGAR ESPECIFICADO"},
{ value: "W939",	text: "EXPOSICION A FRIO EXCESIVO DE ORIGEN ARTIFICIAL: LUGAR NO ESPECIFICADO"},
{ value: "W940",	text: "EXPOSICION A PRESION DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE: VIVIENDA"},
{ value: "W941",	text: "EXPOSICION A PRESION DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE: INSTITUCION RESIDENCIAL"},
{ value: "W942",	text: "EXPOSICION A PRESION DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W943",	text: "EXPOSICION A PRESION DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W944",	text: "EXPOSICION A PRESION DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE: CALLES Y CARRETERAS"},
{ value: "W945",	text: "EXPOSICION A PRESION DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W946",	text: "EXPOSICION A PRESION DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W947",	text: "EXPOSICION A PRESION DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE: GRANJA"},
{ value: "W948",	text: "EXPOSICION A PRESION DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE: OTRO LUGAR ESPECIFICADO"},
{ value: "W949",	text: "EXPOSICION A PRESION DE AIRE ALTA Y BAJA Y A CAMBIOS EN LA PRESION DEL AIRE: LUGAR NO ESPECIFICADO"},
{ value: "W990",	text: "EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL: VIVIENDA"},
{ value: "W991",	text: "EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL: INSTITUCION RESIDENCIAL"},
{ value: "W992",	text: "EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "W993",	text: "EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "W994",	text: "EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL: CALLES Y CARRETERAS"},
{ value: "W995",	text: "EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL: COMERCIO Y AREAS DE SERVICIO"},
{ value: "W996",	text: "EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "W997",	text: "EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL: GRANJA"},
{ value: "W998",	text: "EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL: OTRO LUGAR ESPECIFICADO"},
{ value: "W999",	text: "EXPOSICION A OTROS FACTORES AMBIENTALES Y A LOS NO ESPECIFICADOS, DE ORIGEN ARTIFICIAL: LUGAR NO ESPECIFICADO"},
{ value: "X000",	text: "EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: VIVIENDA"},
{ value: "X001",	text: "EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: INSTITUCION RESIDENCIAL"},
{ value: "X002",	text: "EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X003",	text: "EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X004",	text: "EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: CALLES Y CARRETERAS"},
{ value: "X005",	text: "EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X006",	text: "EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X007",	text: "EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: GRANJA"},
{ value: "X008",	text: "EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: OTRO LUGAR ESPECIFICADO"},
{ value: "X009",	text: "EXPOSICION A FUEGO NO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: LUGAR NO ESPECIFICADO"},
{ value: "X010",	text: "EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: VIVIENDA"},
{ value: "X011",	text: "EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: INSTITUCION RESIDENCIAL"},
{ value: "X012",	text: "EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X013",	text: "EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X014",	text: "EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: CALLES Y CARRETERAS"},
{ value: "X015",	text: "EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X016",	text: "EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: AREAS INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X017",	text: "EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: GRANJA"},
{ value: "X018",	text: "EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: OTRO LUGAR ESPECIFICADO"},
{ value: "X019",	text: "EXPOSICION A FUEGO NO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: LUGAR NO ESPECIFICADO"},
{ value: "X020",	text: "EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: VIVIENDA"},
{ value: "X021",	text: "EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: INSTITUCION RESIDENCIAL"},
{ value: "X022",	text: "EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X023",	text: "EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X024",	text: "EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: CALLES Y CARRETERAS"},
{ value: "X025",	text: "EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: COMERCIO Y AREA DE SERVICIO"},
{ value: "X026",	text: "EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X027",	text: "EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: GRANJA"},
{ value: "X028",	text: "EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: OTRO LUGAR ESPECIFICADO"},
{ value: "X029",	text: "EXPOSICION A FUEGO CONTROLADO EN EDIFICIO U OTRA CONSTRUCCION: LUGAR NO ESPECIFICADO"},
{ value: "X030",	text: "EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: VIVIENDA"},
{ value: "X031",	text: "EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: INSTITUCION RESIDENCIAL"},
{ value: "X032",	text: "EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: ESCUELAS, OTRAS INSTITUCIONES AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X033",	text: "EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X034",	text: "EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: CALLES Y CARRETERAS"},
{ value: "X035",	text: "EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X036",	text: "EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X037",	text: "EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: GRANJA"},
{ value: "X038",	text: "EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: OTRO LUGAR ESPECIFICADO"},
{ value: "X039",	text: "EXPOSICION A FUEGO CONTROLADO EN LUGAR QUE NO ES EDIFICIO U OTRA CONSTRUCCION: LUGAR NO ESPECIFICADO"},
{ value: "X040",	text: "EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE: VIVIENDA"},
{ value: "X041",	text: "EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE: INSTITUCION RESIDENCIAL"},
{ value: "X042",	text: "EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X043",	text: "EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X044",	text: "EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE: CALLES Y CARRETERAS"},
{ value: "X045",	text: "EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X046",	text: "EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X047",	text: "EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE: GRANJA"},
{ value: "X048",	text: "EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE: OTRO LUGAR ESPECIFICADO"},
{ value: "X049",	text: "EXPOSICION A IGNICION DE MATERIAL ALTAMENTE INFLAMABLE: LUGAR NO ESPECIFICADO"},
{ value: "X050",	text: "EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR: VIVIENDA"},
{ value: "X051",	text: "EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR: INSTITUCION RESIDENCIAL"},
{ value: "X052",	text: "EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X053",	text: "EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X054",	text: "EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR: CALLES Y CARRETERAS"},
{ value: "X055",	text: "EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR: COMERCIO Y AREA DE SERVICIOS"},
{ value: "X056",	text: "EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X057",	text: "EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR: GRANJA"},
{ value: "X058",	text: "EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR: OTRO LUGAR ESPECIFICADO"},
{ value: "X059",	text: "EXPOSICION A IGNICION O FUSION DE ROPAS DE DORMIR: LUGAR NO ESPECIFICADO"},
{ value: "X060",	text: "EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS: VIVIENDA"},
{ value: "X061",	text: "EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS: INSTITUCION RESIDENCIAL"},
{ value: "X062",	text: "EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X063",	text: "EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X064",	text: "EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS: CALLES Y CARRETERAS"},
{ value: "X065",	text: "EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X066",	text: "EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X067",	text: "EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS: GRANJA"},
{ value: "X068",	text: "EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X069",	text: "EXPOSICION A IGNICION O FUSION DE OTRAS ROPAS Y ACCESORIOS: LUGAR NO ESPECIFICADO"},
{ value: "X080",	text: "EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS: VIVIENDA"},
{ value: "X081",	text: "EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "X082",	text: "EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X083",	text: "EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X084",	text: "EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "X085",	text: "EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X086",	text: "EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X087",	text: "EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS: GRANJA"},
{ value: "X088",	text: "EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X089",	text: "EXPOSICION A OTROS HUMOS, FUEGOS O LLAMAS ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "X090",	text: "EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS: VIVIENDA"},
{ value: "X091",	text: "EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "X092",	text: "EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X093",	text: "EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X094",	text: "EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "X095",	text: "EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X096",	text: "EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X097",	text: "EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS: GRANJA"},
{ value: "X098",	text: "EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X099",	text: "EXPOSICION A HUMOS, FUEGOS O LLAMAS NO ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "X100",	text: "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES: VIVIENDA"},
{ value: "X101",	text: "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES: INSTITUCION RESIDENCIAL"},
{ value: "X102",	text: "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X103",	text: "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X104",	text: "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES: CALLES Y CARRETERAS"},
{ value: "X105",	text: "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X106",	text: "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X107",	text: "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES: GRANJA"},
{ value: "X108",	text: "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES: OTRO LUGAR ESPECIFICADO"},
{ value: "X109",	text: "CONTACTO CON BEBIDAS, ALIMENTOS, GRASAS Y ACEITES PARA COCINAR, CALIENTES: LUGAR NO ESPECIFICADO"},
{ value: "X110",	text: "CONTACTO CON AGUA CALIENTE CORRIENTE: VIVIENDA"},
{ value: "X111",	text: "CONTACTO CON AGUA CALIENTE CORRIENTE: INSTITUCION RESIDENCIAL"},
{ value: "X112",	text: "CONTACTO CON AGUA CALIENTE CORRIENTE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X113",	text: "CONTACTO CON AGUA CALIENTE CORRIENTE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X114",	text: "CONTACTO CON AGUA CALIENTE CORRIENTE: CALLES Y CARRETERAS"},
{ value: "X115",	text: "CONTACTO CON AGUA CALIENTE CORRIENTE: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X116",	text: "CONTACTO CON AGUA CALIENTE CORRIENTE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X117",	text: "CONTACTO CON AGUA CALIENTE CORRIENTE: GRANJA"},
{ value: "X118",	text: "CONTACTO CON AGUA CALIENTE CORRIENTE: OTRO LUGAR ESPECIFICADO"},
{ value: "X119",	text: "CONTACTO CON AGUA CALIENTE CORRIENTE: LUGAR NO ESPECIFICADO"},
{ value: "X120",	text: "CONTACTO CON OTROS LIQUIDOS CALIENTES: VIVIENDA"},
{ value: "X121",	text: "CONTACTO CON OTROS LIQUIDOS CALIENTES: INSTITUCION RESIDENCIAL"},
{ value: "X122",	text: "CONTACTO CON OTROS LIQUIDOS CALIENTES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X123",	text: "CONTACTO CON OTROS LIQUIDOS CALIENTES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X124",	text: "CONTACTO CON OTROS LIQUIDOS CALIENTES: CALLES Y CARRETERAS"},
{ value: "X125",	text: "CONTACTO CON OTROS LIQUIDOS CALIENTES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X126",	text: "CONTACTO CON OTROS LIQUIDOS CALIENTES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X127",	text: "CONTACTO CON OTROS LIQUIDOS CALIENTES: GRANJA"},
{ value: "X128",	text: "CONTACTO CON OTROS LIQUIDOS CALIENTES: OTRO LUGAR ESPECIFICADO"},
{ value: "X129",	text: "CONTACTO CON OTROS LIQUIDOS CALIENTES: LUGAR NO ESPECIFICADO"},
{ value: "X130",	text: "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES: VIVIENDA"},
{ value: "X131",	text: "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES: INSTITUCION RESIDENCIAL"},
{ value: "X132",	text: "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X133",	text: "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X134",	text: "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES: CALLES Y CARRETERAS"},
{ value: "X135",	text: "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X136",	text: "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X137",	text: "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES: GRANJA"},
{ value: "X138",	text: "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES: OTRO LUGAR ESPECIFICADO"},
{ value: "X139",	text: "CONTACTO CON VAPOR DE AGUA Y OTROS VAPORES CALIENTES: LUGAR NO ESPECIFICADO"},
{ value: "X140",	text: "CONTACTO CON AIRE Y GASES CALIENTES: VIVIENDA"},
{ value: "X141",	text: "CONTACTO CON AIRE Y GASES CALIENTES: INSTITUCION RESIDENCIAL"},
{ value: "X142",	text: "CONTACTO CON AIRE Y GASES CALIENTES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X143",	text: "CONTACTO CON AIRE Y GASES CALIENTES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X144",	text: "CONTACTO CON AIRE Y GASES CALIENTES: CALLES Y CARRETERAS"},
{ value: "X145",	text: "CONTACTO CON AIRE Y GASES CALIENTES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X146",	text: "CONTACTO CON AIRE Y GASES CALIENTES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X147",	text: "CONTACTO CON AIRE Y GASES CALIENTES: GRANJA"},
{ value: "X148",	text: "CONTACTO CON AIRE Y GASES CALIENTES: OTRO LUGAR ESPECIFICADO"},
{ value: "X149",	text: "CONTACTO CON AIRE Y GASES CALIENTES: LUGAR NO ESPECIFICADO"},
{ value: "X150",	text: "CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES: VIVIENDA"},
{ value: "X151",	text: "CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES: INSTITUCION RESIDENCIAL"},
{ value: "X152",	text: "CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X153",	text: "CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X154",	text: "CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES: CALLES Y CARRETERAS"},
{ value: "X155",	text: "CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X156",	text: "CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X157",	text: "CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES: GRANJA"},
{ value: "X158",	text: "CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES OTRO LUGAR ESPECIFICADO:"},
{ value: "X159",	text: "CONTACTO CON UTENSILIOS DOMESTICOS CALIENTES: LUGAR NO ESPECIFICADO"},
{ value: "X160",	text: "CONTACTO CON RADIADORES, CAÑERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES: VIVIENDA"},
{ value: "X161",	text: "CONTACTO CON RADIADORES, CAÑERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES: INSTITUCION RESIDENCIAL"},
{ value: "X162",	text: "CONTACTO CON RADIADORES, CAÑERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X163",	text: "CONTACTO CON RADIADORES, CAÑERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X164",	text: "CONTACTO CON RADIADORES, CAÑERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES: CALLES Y CARRETERAS"},
{ value: "X165",	text: "CONTACTO CON RADIADORES, CAÑERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X166",	text: "CONTACTO CON RADIADORES, CAÑERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X167",	text: "CONTACTO CON RADIADORES, CAÑERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES: GRANJA"},
{ value: "X168",	text: "CONTACTO CON RADIADORES, CAÑERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES: OTRO LUGAR ESPECIFICADO"},
{ value: "X169",	text: "CONTACTO CON RADIADORES, CAÑERIAS Y ARTEFACTOS PARA CALEFACCION, CALIENTES: LUGAR NO ESPECIFICADO"},
{ value: "X170",	text: "CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES: VIVIENDA"},
{ value: "X171",	text: "CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES: INSTITUCION RESIDENCIAL"},
{ value: "X172",	text: "CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X173",	text: "CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X174",	text: "CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES: CALLES Y CARRETERAS"},
{ value: "X175",	text: "CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X176",	text: "CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X177",	text: "CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES: GRANJA"},
{ value: "X178",	text: "CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES: OTRO LUGAR ESPECIFICADO"},
{ value: "X179",	text: "CONTACTO CON MAQUINAS, MOTORES Y HERRAMIENTAS CALIENTES: LUGAR NO ESPECIFICADO"},
{ value: "X180",	text: "CONTACTO CON OTROS METALES CALIENTES: VIVIENDA"},
{ value: "X181",	text: "CONTACTO CON OTROS METALES CALIENTES: INSTITUCION RESIDENCIAL"},
{ value: "X182",	text: "CONTACTO CON OTROS METALES CALIENTES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X183",	text: "CONTACTO CON OTROS METALES CALIENTES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X184",	text: "CONTACTO CON OTROS METALES CALIENTES: CALLES Y CARRETERAS"},
{ value: "X185",	text: "CONTACTO CON OTROS METALES CALIENTES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X186",	text: "CONTACTO CON OTROS METALES CALIENTES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X187",	text: "CONTACTO CON OTROS METALES CALIENTES: GRANJA"},
{ value: "X188",	text: "CONTACTO CON OTROS METALES CALIENTES: OTRO LUGAR ESPECIFICADO"},
{ value: "X189",	text: "CONTACTO CON OTROS METALES CALIENTES: LUGAR NO ESPECIFICADO"},
{ value: "X190",	text: "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS: VIVIENDA"},
{ value: "X191",	text: "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS: INSTITUCION RESIDENCIAL"},
{ value: "X192",	text: "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X193",	text: "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X194",	text: "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS: CALLES Y CARRETERAS"},
{ value: "X195",	text: "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X196",	text: "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X197",	text: "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS: GRANJA"},
{ value: "X198",	text: "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS: OTRO LUGAR ESPECIFICADO"},
{ value: "X199",	text: "CONTACTO CON OTRAS SUSTANCIAS CALIENTES, Y LAS NO ESPECIFICADAS: LUGAR NO ESPECIFICADO"},
{ value: "X200",	text: "CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS: VIVIENDA"},
{ value: "X201",	text: "CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS: INSTITUCION RESIDENCIAL"},
{ value: "X202",	text: "CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X203",	text: "CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X204",	text: "CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS: CALLES Y CARRETERAS"},
{ value: "X205",	text: "CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X206",	text: "CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X207",	text: "CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS: GRANJA"},
{ value: "X208",	text: "CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X209",	text: "CONTACTO TRAUMATICO CON SERPIENTES Y LAGARTOS VENENOSOS: LUGAR NO ESPECIFICADO"},
{ value: "X210",	text: "CONTACTO TRAUMATICO CON ARAÑAS VENENOSAS: VIVIENDA"},
{ value: "X211",	text: "CONTACTO TRAUMATICO CON ARAÑAS VENENOSAS: INSTITUCION RESIDENCIAL"},
{ value: "X212",	text: "CONTACTO TRAUMATICO CON ARAÑAS VENENOSAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X213",	text: "CONTACTO TRAUMATICO CON ARAÑAS VENENOSAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X214",	text: "CONTACTO TRAUMATICO CON ARAÑAS VENENOSAS: CALLES Y CARRETERAS"},
{ value: "X215",	text: "CONTACTO TRAUMATICO CON ARAÑAS VENENOSAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X216",	text: "CONTACTO TRAUMATICO CON ARAÑAS VENENOSAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X217",	text: "CONTACTO TRAUMATICO CON ARAÑAS VENENOSAS: GRANJA"},
{ value: "X218",	text: "CONTACTO TRAUMATICO CON ARAÑAS VENENOSAS: OTRO LUGAR ESPECIFICADO"},
{ value: "X219",	text: "CONTACTO TRAUMATICO CON ARAÑAS VENENOSAS: LUGAR NO ESPECIFICADO"},
{ value: "X220",	text: "CONTACTO TRAUMATICO CON ESCORPION: VIVIENDA"},
{ value: "X221",	text: "CONTACTO TRAUMATICO CON ESCORPION: INSTITUCION RESIDENCIAL"},
{ value: "X222",	text: "CONTACTO TRAUMATICO CON ESCORPION: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X223",	text: "CONTACTO TRAUMATICO CON ESCORPION: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X224",	text: "CONTACTO TRAUMATICO CON ESCORPION: CALLES Y CARRETERAS"},
{ value: "X225",	text: "CONTACTO TRAUMATICO CON ESCORPION: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X226",	text: "CONTACTO TRAUMATICO CON ESCORPION: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X227",	text: "CONTACTO TRAUMATICO CON ESCORPION: GRANJA"},
{ value: "X228",	text: "CONTACTO TRAUMATICO CON ESCORPION: OTRO LUGAR ESPECIFICADO"},
{ value: "X229",	text: "CONTACTO TRAUMATICO CON ESCORPION: LUGAR NO ESPECIFICADO"},
{ value: "X230",	text: "CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS: VIVIENDA"},
{ value: "X231",	text: "CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS: INSTITUCION RESIDENCIAL"},
{ value: "X232",	text: "CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X233",	text: "CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X234",	text: "CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS: CALLES Y CARRETERAS"},
{ value: "X235",	text: "CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X236",	text: "CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X237",	text: "CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS: GRANJA"},
{ value: "X238",	text: "CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS: OTRO LUGAR ESPECIFICADO"},
{ value: "X239",	text: "CONTACTO TRAUMATICO CON AVISPONES, AVISPAS Y ABEJAS: LUGAR NO ESPECIFICADO"},
{ value: "X240",	text: "CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES): VIVIENDA"},
{ value: "X241",	text: "CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES): INSTITUCION RESIDENCIAL"},
{ value: "X242",	text: "CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES): ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X243",	text: "CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES): AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X244",	text: "CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES): CALLES Y CARRETERAS"},
{ value: "X245",	text: "CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES): COMERCIO Y AREAS DE SERVICIO"},
{ value: "X246",	text: "CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES): AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X247",	text: "CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES): GRANJA"},
{ value: "X248",	text: "CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES): OTRO LUGAR ESPECIFICADO"},
{ value: "X249",	text: "CONTACTO TRAUMATICO CON CENTIPODOS Y MIRIAPODOS VENENOSOS (TROPICALES): LUGAR NO ESPECIFICADO"},
{ value: "X250",	text: "CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS: VIVIENDA"},
{ value: "X251",	text: "CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "X252",	text: "CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X253",	text: "CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X254",	text: "CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "X255",	text: "CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X256",	text: "CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X257",	text: "CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS: GRANJA"},
{ value: "X258",	text: "CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X259",	text: "CONTACTO TRAUMATICO CON OTROS ARTROPODOS VENENOSOS ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "X260",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS: VIVIENDA"},
{ value: "X261",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS: INSTITUCION RESIDENCIAL"},
{ value: "X262",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X263",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X264",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS: CALLES Y CARRETERAS"},
{ value: "X265",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X266",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X267",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS: GRANJA"},
{ value: "X268",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X269",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS MARINAS VENENOSOS: LUGAR NO ESPECIFICADO"},
{ value: "X270",	text: "CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS: VIVIENDA"},
{ value: "X271",	text: "CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "X272",	text: "CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X273",	text: "CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X274",	text: "CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "X275",	text: "CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X276",	text: "CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X277",	text: "CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS: GRANJA"},
{ value: "X278",	text: "CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X279",	text: "CONTACTO TRAUMATICO CON OTROS ANIMALES VENENOSOS ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "X280",	text: "CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS: VIVIENDA"},
{ value: "X281",	text: "CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS: INSTITUCION RESIDENCIAL"},
{ value: "X282",	text: "CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X283",	text: "CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X284",	text: "CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS: CALLES Y CARRETERAS"},
{ value: "X285",	text: "CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X286",	text: "CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X287",	text: "CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS: GRANJA"},
{ value: "X288",	text: "CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS: OTRO LUGAR ESPECIFICADO"},
{ value: "X289",	text: "CONTACTO TRAUMATICO CON OTRAS PLANTAS VENENOSAS ESPECIFICADAS: LUGAR NO ESPECIFICADO"},
{ value: "X290",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS: VIVIENDA"},
{ value: "X291",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "X292",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X293",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X294",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "X295",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X296",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X297",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS: GRANJA"},
{ value: "X298",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X299",	text: "CONTACTO TRAUMATICO CON ANIMALES Y PLANTAS VENENOSOS NO ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "X300",	text: "EXPOSICION AL CALOR NATURAL EXCESIVO: VIVIENDA"},
{ value: "X301",	text: "EXPOSICION AL CALOR NATURAL EXCESIVO: INSTITUCION RESIDENCIAL"},
{ value: "X302",	text: "EXPOSICION AL CALOR NATURAL EXCESIVO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X303",	text: "EXPOSICION AL CALOR NATURAL EXCESIVO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X304",	text: "EXPOSICION AL CALOR NATURAL EXCESIVO: CALLES Y CARRETERAS"},
{ value: "X305",	text: "EXPOSICION AL CALOR NATURAL EXCESIVO: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X306",	text: "EXPOSICION AL CALOR NATURAL EXCESIVO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X307",	text: "EXPOSICION AL CALOR NATURAL EXCESIVO: GRANJA"},
{ value: "X308",	text: "EXPOSICION AL CALOR NATURAL EXCESIVO: OTRO LUGAR ESPECIFICADO"},
{ value: "X309",	text: "EXPOSICION AL CALOR NATURAL EXCESIVO: LUGAR NO ESPECIFICADO"},
{ value: "X310",	text: "EXPOSICION AL FRIO NATURAL EXCESIVO: VIVIENDA"},
{ value: "X311",	text: "EXPOSICION AL FRIO NATURAL EXCESIVO: INSTITUCION RESIDENCIAL"},
{ value: "X312",	text: "EXPOSICION AL FRIO NATURAL EXCESIVO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X313",	text: "EXPOSICION AL FRIO NATURAL EXCESIVO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X314",	text: "EXPOSICION AL FRIO NATURAL EXCESIVO: CALLES Y CARRETERAS"},
{ value: "X315",	text: "EXPOSICION AL FRIO NATURAL EXCESIVO: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X316",	text: "EXPOSICION AL FRIO NATURAL EXCESIVO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X317",	text: "EXPOSICION AL FRIO NATURAL EXCESIVO: GRANJA"},
{ value: "X318",	text: "EXPOSICION AL FRIO NATURAL EXCESIVO: OTRO LUGAR ESPECIFICADO"},
{ value: "X319",	text: "EXPOSICION AL FRIO NATURAL EXCESIVO: LUGAR NO ESPECIFICADO"},
{ value: "X320",	text: "EXPOSICION A RAYOS SOLARES: VIVIENDA"},
{ value: "X321",	text: "EXPOSICION A RAYOS SOLARES: INSTITUCION RESIDENCIAL"},
{ value: "X322",	text: "EXPOSICION A RAYOS SOLARES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X323",	text: "EXPOSICION A RAYOS SOLARES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X324",	text: "EXPOSICION A RAYOS SOLARES: CALLES Y CARRETERAS"},
{ value: "X325",	text: "EXPOSICION A RAYOS SOLARES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X326",	text: "EXPOSICION A RAYOS SOLARES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X327",	text: "EXPOSICION A RAYOS SOLARES: GRANJA"},
{ value: "X328",	text: "EXPOSICION A RAYOS SOLARES: OTRO LUGAR ESPECIFICADO"},
{ value: "X329",	text: "EXPOSICION A RAYOS SOLARES: LUGAR NO ESPECIFICADO"},
{ value: "X330",	text: "VICTIMA DE RAYO: VIVIENDA"},
{ value: "X331",	text: "VICTIMA DE RAYO: INSTITUCION RESIDENCIAL"},
{ value: "X332",	text: "VICTIMA DE RAYO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X333",	text: "VICTIMA DE RAYO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X334",	text: "VICTIMA DE RAYO: CALLES Y CARRETERAS"},
{ value: "X335",	text: "VICTIMA DE RAYO: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X336",	text: "VICTIMA DE RAYO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X337",	text: "VICTIMA DE RAYO: GRANJA"},
{ value: "X338",	text: "VICTIMA DE RAYO: OTRO LUGAR ESPECIFICADO"},
{ value: "X339",	text: "VICTIMA DE RAYO: LUGAR NO ESPECIFICADO"},
{ value: "X340",	text: "VICTIMA DE TERREMOTO: VIVIENDA"},
{ value: "X341",	text: "VICTIMA DE TERREMOTO: INSTITUCION RESIDENCIAL"},
{ value: "X342",	text: "VICTIMA DE TERREMOTO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X343",	text: "VICTIMA DE TERREMOTO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X344",	text: "VICTIMA DE TERREMOTO: CALLES Y CARRETERAS"},
{ value: "X345",	text: "VICTIMA DE TERREMOTO: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X346",	text: "VICTIMA DE TERREMOTO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X347",	text: "VICTIMA DE TERREMOTO: GRANJA"},
{ value: "X348",	text: "VICTIMA DE TERREMOTO: OTRO LUGAR ESPECIFICADO"},
{ value: "X349",	text: "VICTIMA DE TERREMOTO: LUGAR NO ESPECIFICADO"},
{ value: "X350",	text: "VICTIMA DE ERUPCION VOLCANICA: VIVIENDA"},
{ value: "X351",	text: "VICTIMA DE ERUPCION VOLCANICA: INSTITUCION RESIDENCIAL"},
{ value: "X352",	text: "VICTIMA DE ERUPCION VOLCANICA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X353",	text: "VICTIMA DE ERUPCION VOLCANICA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X354",	text: "VICTIMA DE ERUPCION VOLCANICA: CALLES Y CARRETERAS"},
{ value: "X355",	text: "VICTIMA DE ERUPCION VOLCANICA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X356",	text: "VICTIMA DE ERUPCION VOLCANICA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X357",	text: "VICTIMA DE ERUPCION VOLCANICA: GRANJA"},
{ value: "X358",	text: "VICTIMA DE ERUPCION VOLCANICA: OTRO LUGAR ESPECIFICADO"},
{ value: "X359",	text: "VICTIMA DE ERUPCION VOLCANICA: LUGAR NO ESPECIFICADO"},
{ value: "X360",	text: "VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA: VIVIENDA"},
{ value: "X361",	text: "VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA: INSTITUCION RESIDENCIAL"},
{ value: "X362",	text: "VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X363",	text: "VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X364",	text: "VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA: CALLES Y CARRETERAS"},
{ value: "X365",	text: "VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X366",	text: "VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X367",	text: "VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA: GRANJA"},
{ value: "X368",	text: "VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA: OTRO LUGAR ESPECIFICADO"},
{ value: "X369",	text: "VICTIMA DE AVALANCHA, DERRUMBE Y OTROS MOVIMIENTOS DE TIERRA: LUGAR NO ESPECIFICADO"},
{ value: "X370",	text: "VICTIMA DE TORMENTA CATACLISMICA: VIVIENDA"},
{ value: "X371",	text: "VICTIMA DE TORMENTA CATACLISMICA: INSTITUCION RESIDENCIAL"},
{ value: "X372",	text: "VICTIMA DE TORMENTA CATACLISMICA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X373",	text: "VICTIMA DE TORMENTA CATACLISMICA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X374",	text: "VICTIMA DE TORMENTA CATACLISMICA: CALLES Y CARRETERAS"},
{ value: "X375",	text: "VICTIMA DE TORMENTA CATACLISMICA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X376",	text: "VICTIMA DE TORMENTA CATACLISMICA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X377",	text: "VICTIMA DE TORMENTA CATACLISMICA: GRANJA"},
{ value: "X378",	text: "VICTIMA DE TORMENTA CATACLISMICA: OTRO LUGAR ESPECIFICADO"},
{ value: "X379",	text: "VICTIMA DE TORMENTA CATACLISMICA: LUGAR NO ESPECIFICADO"},
{ value: "X380",	text: "VICTIMA DE INUNDACION: VIVIENDA"},
{ value: "X381",	text: "VICTIMA DE INUNDACION: INSTITUCION RESIDENCIAL"},
{ value: "X382",	text: "VICTIMA DE INUNDACION: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X383",	text: "VICTIMA DE INUNDACION: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X384",	text: "VICTIMA DE INUNDACION: CALLES Y CARRETERAS"},
{ value: "X385",	text: "VICTIMA DE INUNDACION: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X386",	text: "VICTIMA DE INUNDACION: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X387",	text: "VICTIMA DE INUNDACION: GRANJA"},
{ value: "X388",	text: "VICTIMA DE INUNDACION: OTRO LUGAR ESPECIFICADO"},
{ value: "X389",	text: "VICTIMA DE INUNDACION: LUGAR NO ESPECIFICADO"},
{ value: "X390",	text: "EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS: VIVIENDA"},
{ value: "X391",	text: "EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS: INSTITUCION RESIDENCIAL"},
{ value: "X392",	text: "EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X393",	text: "EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X394",	text: "EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS: CALLES Y CARRETERAS"},
{ value: "X395",	text: "EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X396",	text: "EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X397",	text: "EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS: GRANJA"},
{ value: "X398",	text: "EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS: OTRO LUGAR ESPECIFICADO"},
{ value: "X399",	text: "EXPOSICION A OTRAS FUERZAS DE LA NATURALEZA, Y LAS NO ESPECIFICADAS: LUGAR NO ESPECIFICADO"},
{ value: "X400",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: VIVIENDA"},
{ value: "X401",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: INSTITUCION RESIDENCIAL"},
{ value: "X402",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X403",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X404",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: CALLES Y CARRETERAS"},
{ value: "X405",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X406",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X407",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: GRANJA"},
{ value: "X408",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X409",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: LUGAR NO ESPECIFICADO"},
{ value: "X410",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: VIVIENDA"},
{ value: "X411",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: INSTITUCION RESIDENCIAL"},
{ value: "X412",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X413",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X414",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: CALLES Y CARRETERAS"},
{ value: "X415",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: COMERCIO Y AREA DE SERVICIO"},
{ value: "X416",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X417",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: GRANJA"},
{ value: "X418",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: OTRO LUGAR ESPECIFICADO"},
{ value: "X419",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: LUGAR NO ESPECIFICADO"},
{ value: "X420",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS EN OTRA PARTE: VIVIENDA"},
{ value: "X421",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS EN OTRA PARTE: INSTITUCION RESIDENCIAL"},
{ value: "X422",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS EN OTRA PARTE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X423",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS EN OTRA PARTE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X424",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS EN OTRA PARTE: CALLES Y CARRETERAS"},
{ value: "X425",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS EN OTRA PARTE: COMERCIO Y AREA DE SERVICIOS"},
{ value: "X426",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS EN OTRA PARTE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X427",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS EN OTRA PARTE: GRANJA"},
{ value: "X428",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS EN OTRA PARTE: OTRO LUGAR ESPECIFICADO"},
{ value: "X429",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS EN OTRA PARTE: LUGAR NO ESPECIFICADO"},
{ value: "X430",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: VIVIENDA"},
{ value: "X431",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: INSTITUCION RESIDENCIAL"},
{ value: "X432",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X433",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X434",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: CALLES Y CARRETERAS"},
{ value: "X435",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: COMERCIO Y AREA DE SERVICIO"},
{ value: "X436",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X437",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: GRANJA"},
{ value: "X438",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: OTRO LUGAR ESPECIFICADO"},
{ value: "X439",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: LUGAR NO ESPECIFICADO"},
{ value: "X440",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: VIVIENDA"},
{ value: "X441",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "X442",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X443",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X444",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "X445",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: COMERCIO Y AREA DE SERVICIOS"},
{ value: "X446",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X447",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: GRANJA"},
{ value: "X448",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X449",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "X450",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL: VIVIENDA"},
{ value: "X451",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL: INSTITUCION RESIDENCIAL"},
{ value: "X452",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X453",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X454",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL: CALLES Y CARRETERAS"},
{ value: "X455",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X456",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X457",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL: GRANJA"},
{ value: "X458",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL: OTRO LUGAR ESPECIFICADO"},
{ value: "X459",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION AL ALCOHOL: LUGAR NO ESPECIFICADO"},
{ value: "X460",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: VIVIENDA"},
{ value: "X461",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: INSTITUCION RESIDENCIAL"},
{ value: "X462",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: ESCUELAS OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X463",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X464",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: CALLES Y CARRETERAS"},
{ value: "X465",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: COMERCIO Y AREA DE SERVICIOS"},
{ value: "X466",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X467",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: GRANJA"},
{ value: "X468",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: OTRO LUGAR ESPECIFICADO"},
{ value: "X469",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: LUGAR NO ESPECIFICADO"},
{ value: "X470",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES: VIVIENDA"},
{ value: "X471",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES: INSTITUCION RESIDENCIAL"},
{ value: "X472",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X473",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X474",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES: CALLES Y CARRETERAS"},
{ value: "X475",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES: COMERCIO Y AREA DE SERVICIOS"},
{ value: "X476",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X477",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES: GRANJA"},
{ value: "X478",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES: OTRO LUGAR ESPECIFICADO"},
{ value: "X479",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS GASES Y VAPORES: LUGAR NO ESPECIFICADO"},
{ value: "X480",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS: VIVIENDA"},
{ value: "X481",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS: INSTITUCION RESIDENCIAL"},
{ value: "X482",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X483",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X484",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS: CALLES Y CARRETERA"},
{ value: "X485",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS: COMERCIO Y AREA DE SERVICIOS"},
{ value: "X486",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X487",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS: GRANJA"},
{ value: "X488",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS: OTRO LUGAR ESPECIFICADO"},
{ value: "X489",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A PLAGUICIDAS: LUGAR NO ESPECIFICADO"},
{ value: "X490",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: VIVIENDA"},
{ value: "X491",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "X492",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X493",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X494",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "X495",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: COMERCIO Y AREA DE SERVICIOS"},
{ value: "X496",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X497",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: GRANJA"},
{ value: "X498",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X499",	text: "ENVENENAMIENTO ACCIDENTAL POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "X500",	text: "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS: VIVIENDA"},
{ value: "X501",	text: "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS: INSTITUCION RESIDENCIAL"},
{ value: "X502",	text: "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X503",	text: "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X504",	text: "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS: CALLES Y CARRETERAS"},
{ value: "X505",	text: "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X506",	text: "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X507",	text: "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS: GRANJA"},
{ value: "X508",	text: "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X509",	text: "EXCESO DE ESFUERZO Y MOVIMIENTOS EXTENUANTES Y REPETITIVOS: LUGAR NO ESPECIFICADO"},
{ value: "X510",	text: "VIAJES Y DESPLAZAMIENTOS: VIVIENDA"},
{ value: "X511",	text: "VIAJES Y DESPLAZAMIENTOS: INSTITUCION RESIDENCIAL"},
{ value: "X512",	text: "VIAJES Y DESPLAZAMIENTOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X513",	text: "VIAJES Y DESPLAZAMIENTOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X514",	text: "VIAJES Y DESPLAZAMIENTOS: CALLES Y CARRETERAS"},
{ value: "X515",	text: "VIAJES Y DESPLAZAMIENTOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X516",	text: "VIAJES Y DESPLAZAMIENTOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X517",	text: "VIAJES Y DESPLAZAMIENTOS: GRANJA"},
{ value: "X518",	text: "VIAJES Y DESPLAZAMIENTOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X519",	text: "VIAJES Y DESPLAZAMIENTOS: LUGAR NO ESPECIFICADO"},
{ value: "X520",	text: "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD: VIVIENDA"},
{ value: "X521",	text: "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD: INSTITUCION RESIDENCIAL"},
{ value: "X522",	text: "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X523",	text: "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X524",	text: "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD: CALLES Y CARRETERAS"},
{ value: "X525",	text: "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X526",	text: "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X527",	text: "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD: GRANJA"},
{ value: "X528",	text: "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD: OTRO LUGAR ESPECIFICADO"},
{ value: "X529",	text: "PERMANENCIA PROLONGADA EN AMBIENTE SIN GRAVEDAD: LUGAR NO ESPECIFICADO"},
{ value: "X530",	text: "PRIVACION DE ALIMENTOS: VIVIENDA"},
{ value: "X531",	text: "PRIVACION DE ALIMENTOS: INSTITUCION RESIDENCIAL"},
{ value: "X532",	text: "PRIVACION DE ALIMENTOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X533",	text: "PRIVACION DE ALIMENTOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X534",	text: "PRIVACION DE ALIMENTOS: CALLES Y CARRETERAS"},
{ value: "X535",	text: "PRIVACION DE ALIMENTOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X536",	text: "PRIVACION DE ALIMENTOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X537",	text: "PRIVACION DE ALIMENTOS: GRANJA"},
{ value: "X538",	text: "PRIVACION DE ALIMENTOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X539",	text: "PRIVACION DE ALIMENTOS: LUGAR NO ESPECIFICADO"},
{ value: "X540",	text: "PRIVACION DE AGUA: VIVIENDA"},
{ value: "X541",	text: "PRIVACION DE AGUA: INSTITUCION RESIDENCIAL"},
{ value: "X542",	text: "PRIVACION DE AGUA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X543",	text: "PRIVACION DE AGUA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X544",	text: "PRIVACION DE AGUA: CALLES Y CARRETERAS"},
{ value: "X545",	text: "PRIVACION DE AGUA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X546",	text: "PRIVACION DE AGUA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X547",	text: "PRIVACION DE AGUA: GRANJA"},
{ value: "X548",	text: "PRIVACION DE AGUA: OTRO LUGAR ESPECIFICADO"},
{ value: "X549",	text: "PRIVACION DE AGUA: LUGAR NO ESPECIFICADO"},
{ value: "X570",	text: "PRIVACION NO ESPECIFICADA: VIVIENDA"},
{ value: "X571",	text: "PRIVACION NO ESPECIFICADA: INSTITUCION RESIDENCIAL"},
{ value: "X572",	text: "PRIVACION NO ESPECIFICADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X573",	text: "PRIVACION NO ESPECIFICADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X574",	text: "PRIVACION NO ESPECIFICADA: CALLES Y CARRETERAS"},
{ value: "X575",	text: "PRIVACION NO ESPECIFICADA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X576",	text: "PRIVACION NO ESPECIFICADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X577",	text: "PRIVACION NO ESPECIFICADA: GRANJA"},
{ value: "X578",	text: "PRIVACION NO ESPECIFICADA: OTRO LUGAR ESPECIFICADO"},
{ value: "X579",	text: "PRIVACION NO ESPECIFICADA: LUGAR NO ESPECIFICADO"},
{ value: "X580",	text: "EXPOSICION A FACTORES ESPECIFICADOS: VIVIENDA"},
{ value: "X581",	text: "EXPOSICION A FACTORES ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "X582",	text: "EXPOSICION A FACTORES ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X583",	text: "EXPOSICION A FACTORES ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X584",	text: "EXPOSICION A FACTORES ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "X585",	text: "EXPOSICION A FACTORES ESPECIFICADOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X586",	text: "EXPOSICION A FACTORES ESPECIFICADOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X587",	text: "EXPOSICION A FACTORES ESPECIFICADOS: GRANJA"},
{ value: "X588",	text: "EXPOSICION A FACTORES ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X589",	text: "EXPOSICION A FACTORES ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "X590",	text: "EXPOSICION A FACTORES NO ESPECIFICADOS: VIVIENDA"},
{ value: "X591",	text: "EXPOSICION A FACTORES NO ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "X592",	text: "EXPOSICION A FACTORES NO ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X593",	text: "EXPOSICION A FACTORES NO ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X594",	text: "EXPOSICION A FACTORES NO ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "X595",	text: "EXPOSICION A FACTORES NO ESPECIFICADOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X596",	text: "EXPOSICION A FACTORES NO ESPECIFICADOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X597",	text: "EXPOSICION A FACTORES NO ESPECIFICADOS: GRANJA"},
{ value: "X598",	text: "EXPOSICION A FACTORES NO ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X599",	text: "EXPOSICION A FACTORES NO ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "X600",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: VIVIENDA"},
{ value: "X601",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: INSTITUCION RESIDENCIAL"},
{ value: "X602",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X603",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X604",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: CALLES Y CARRETERAS"},
{ value: "X605",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X606",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X607",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: GRANJA"},
{ value: "X608",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X609",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS: LUGAR NO ESPECIFICADO"},
{ value: "X610",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: VIVIENDA"},
{ value: "X611",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: INSTITUCION RESIDENCIAL"},
{ value: "X612",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X613",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X614",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: CALLES Y CARRETERAS"},
{ value: "X615",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X616",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X617",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: GRANJA"},
{ value: "X618",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: OTRO LUGAR ESPECIFICADO"},
{ value: "X619",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE: LUGAR NO ESPECIFICADO"},
{ value: "X620",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS  EN OTRA PARTE: VIVIENDA"},
{ value: "X621",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS  EN OTRA PARTE: INSTITUCION RESIDENCIAL"},
{ value: "X622",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS  EN OTRA PARTE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X623",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS  EN OTRA PARTE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X624",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS  EN OTRA PARTE: CALLES Y CARRETERAS"},
{ value: "X625",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS  EN OTRA PARTE: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X626",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS  EN OTRA PARTE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X627",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS  EN OTRA PARTE: GRANJA"},
{ value: "X628",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS  EN OTRA PARTE: OTRO LUGAR ESPECIFICADO"},
{ value: "X629",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADOS  EN OTRA PARTE: LUGAR NO ESPECIFICADO"},
{ value: "X630",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: VIVIENDA"},
{ value: "X631",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: INSTITUCION RESIDENCIAL"},
{ value: "X632",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X633",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X634",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: CALLES Y CARRETERAS"},
{ value: "X635",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X636",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X637",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: GRANJA"},
{ value: "X638",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: OTRO LUGAR ESPECIFICADO"},
{ value: "X639",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO: LUGAR NO ESPECIFICADO"},
{ value: "X640",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: VIVIENDA"},
{ value: "X641",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "X642",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X643",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X644",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "X645",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X646",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: AREA INDUSTRIAL Y  DE LA CONSTRUCCION"},
{ value: "X647",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: GRANJA"},
{ value: "X648",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X649",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTRAS DROGAS MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LOS NO ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "X650",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL: VIVIENDA"},
{ value: "X651",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL: INSTITUCION RESIDENCIAL"},
{ value: "X652",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X653",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X654",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL: CALLES Y CARRETERAS"},
{ value: "X655",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X656",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X657",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL: GRANJA"},
{ value: "X658",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL: OTRO LUGAR ESPECIFICADO"},
{ value: "X659",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION AL ALCOHOL: LUGAR NO ESPECIFICADO"},
{ value: "X660",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: VIVIENDA"},
{ value: "X661",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: INSTITUCION RESIDENCIAL"},
{ value: "X662",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X663",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X664",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: CALLES Y CARRETERAS"},
{ value: "X665",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X666",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X667",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: GRANJA"},
{ value: "X668",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: OTRO LUGAR ESPECIFICADO"},
{ value: "X669",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES: LUGAR NO ESPECIFICADO"},
{ value: "X670",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES: VIVIENDA"},
{ value: "X671",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES: INSTITUCION RESIDENCIAL"},
{ value: "X672",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X673",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X674",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES: CALLES Y CARRETERAS"},
{ value: "X675",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X676",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X677",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES: GRANJA"},
{ value: "X678",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES: OTRO LUGAR ESPECIFICADO"},
{ value: "X679",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS GASES Y VAPORES: LUGAR NO ESPECIFICADO"},
{ value: "X680",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS: VIVIENDA"},
{ value: "X681",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS: INSTITUCION RESIDENCIAL"},
{ value: "X682",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X683",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X684",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS: CALLES Y CARRETERAS"},
{ value: "X685",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X686",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X687",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS: GRANJA"},
{ value: "X688",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS: OTRO LUGAR ESPECIFICADO"},
{ value: "X689",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A PLAGUICIDAS: LUGAR NO ESPECIFICADO"},
{ value: "X690",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: VIVIENDA"},
{ value: "X691",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "X692",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X693",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X694",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "X695",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X696",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: AREA INDUSTRIAL Y  DE LA CONSTRUCCION"},
{ value: "X697",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: GRANJA"},
{ value: "X698",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X699",	text: "ENVENENAMIENTO AUTOINFLIGIDO INTENCIONALMENTE POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "X700",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION: VIVIENDA"},
{ value: "X701",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION: INSTITUCION RESIDENCIAL"},
{ value: "X702",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X703",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X704",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION: CALLES Y CARRETERAS"},
{ value: "X705",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X706",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X707",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION: GRANJA"},
{ value: "X708",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION: OTRO LUGAR ESPECIFICADO"},
{ value: "X709",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHORCAMIENTO, ESTRANGULAMIENTO O SOFOCACION: LUGAR NO ESPECIFICADO"},
{ value: "X710",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION: VIVIENDA"},
{ value: "X711",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION: INSTITUCION RESIDENCIAL"},
{ value: "X712",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X713",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X714",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION: CALLES Y CARRETERAS"},
{ value: "X715",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X716",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X717",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION: GRANJA"},
{ value: "X718",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION: OTRO LUGAR ESPECIFICADO"},
{ value: "X719",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR AHOGAMIENTO Y SUMERSION: LUGAR NO ESPECIFICADO"},
{ value: "X720",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA: VIVIENDA"},
{ value: "X721",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA: INSTITUCION RESIDENCIAL"},
{ value: "X722",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X723",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X724",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA: CALLES Y CARRETERAS"},
{ value: "X725",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X726",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X727",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA: GRANJA"},
{ value: "X728",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA: OTRO LUGAR ESPECIFICADO"},
{ value: "X729",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE ARMA CORTA: LUGAR NO ESPECIFICADO"},
{ value: "X730",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: VIVIENDA"},
{ value: "X731",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: INSTITUCION RESIDENCIAL"},
{ value: "X732",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X733",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X734",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: CALLES Y CARRETERAS"},
{ value: "X735",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X736",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X737",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: GRANJA"},
{ value: "X738",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: OTRO LUGAR ESPECIFICADO"},
{ value: "X739",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: LUGAR NO ESPECIFICADO"},
{ value: "X740",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: VIVIENDA"},
{ value: "X741",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: INSTITUCION RESIDENCIAL"},
{ value: "X742",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X743",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X744",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: CALLES Y CARRETERAS"},
{ value: "X745",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X746",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X747",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: GRANJA"},
{ value: "X748",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: OTRO LUGAR ESPECIFICADO"},
{ value: "X749",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: LUGAR NO ESPECIFICADO"},
{ value: "X750",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO: VIVIENDA"},
{ value: "X751",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO: INSTITUCION RESIDENCIAL"},
{ value: "X752",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X753",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X754",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO: CALLES Y CARRETERAS"},
{ value: "X755",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X756",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X757",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO: GRANJA"},
{ value: "X758",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO: OTRO LUGAR ESPECIFICADO"},
{ value: "X759",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MATERIAL EXPLOSIVO: LUGAR NO ESPECIFICADO"},
{ value: "X760",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS: VIVIENDA"},
{ value: "X761",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS: INSTITUCION RESIDENCIAL"},
{ value: "X762",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X763",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X764",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS: CALLES Y CARRETERAS"},
{ value: "X765",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X766",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X767",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS: GRANJA"},
{ value: "X768",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS: OTRO LUGAR ESPECIFICADO"},
{ value: "X769",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR HUMO, FUEGO Y LLAMAS: LUGAR NO ESPECIFICADO"},
{ value: "X770",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: VIVIENDA"},
{ value: "X771",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: INSTITUCION RESIDENCIAL"},
{ value: "X772",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X773",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X774",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: CALLES Y CARRETERAS"},
{ value: "X775",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X776",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X777",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: GRANJA"},
{ value: "X778",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: OTRO LUGAR ESPECIFICADO"},
{ value: "X779",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: LUGAR NO ESPECIFICADO"},
{ value: "X780",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE: VIVIENDA"},
{ value: "X781",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE: INSTITUCION RESIDENCIAL"},
{ value: "X782",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X783",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X784",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE: CALLES Y CARRETERAS"},
{ value: "X785",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X786",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X787",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE: GRANJA"},
{ value: "X788",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE: OTRO LUGAR ESPECIFICADO"},
{ value: "X789",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO CORTANTE: LUGAR NO ESPECIFICADO"},
{ value: "X790",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO: VIVIENDA"},
{ value: "X791",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO: INSTITUCION RESIDENCIAL"},
{ value: "X792",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X793",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X794",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO: CALLES Y CARRETERAS"},
{ value: "X795",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X796",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X797",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO: GRANJA"},
{ value: "X798",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO: OTRO LUGAR ESPECIFICADO"},
{ value: "X799",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OBJETO ROMO O SIN FILO: LUGAR NO ESPECIFICADO"},
{ value: "X800",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO: VIVIENDA"},
{ value: "X801",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO: INSTITUCION RESIDENCIAL"},
{ value: "X802",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X803",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X804",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO: CALLES Y CARRETERAS"},
{ value: "X805",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X806",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X807",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO: GRANJA"},
{ value: "X808",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO: OTRO LUGAR ESPECIFICADO"},
{ value: "X809",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE AL SALTAR DESDE UN LUGAR ELEVADO: LUGAR NO ESPECIFICADO"},
{ value: "X810",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO: VIVIENDA"},
{ value: "X811",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO: INSTITUCION RESIDENCIAL"},
{ value: "X812",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X813",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X814",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO: CALLES Y CARRETERAS"},
{ value: "X815",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X816",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X817",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO: GRANJA"},
{ value: "X818",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO: OTRO LUGAR ESPECIFICADO"},
{ value: "X819",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR ARROJARSE O COLOCARSE DELANTE DE OBJETO EN MOVIMIENTO: LUGAR NO ESPECIFICADO"},
{ value: "X820",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO DE MOTOR: VIVIENDA"},
{ value: "X821",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO DE MOTOR: INSTITUCION RESIDENCIAL"},
{ value: "X822",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO DE MOTOR: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X823",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO DE MOTOR: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X824",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO DE MOTOR: CALLES Y CARRETERAS"},
{ value: "X825",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO DE MOTOR: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X826",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO DE MOTOR: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X827",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO DE MOTOR: GRANJA"},
{ value: "X828",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO DE MOTOR: OTRO LUGAR ESPECIFICADO"},
{ value: "X829",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR COLISION DE VEHICULO DE MOTOR: LUGAR NO ESPECIFICADO"},
{ value: "X830",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS: VIVIENDA"},
{ value: "X831",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "X832",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X833",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X834",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "X835",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X836",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X837",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS: GRANJA"},
{ value: "X838",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X839",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR OTROS MEDIOS ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "X840",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS: VIVIENDA"},
{ value: "X841",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "X842",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X843",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X844",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "X845",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X846",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X847",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS: GRANJA"},
{ value: "X848",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "X849",	text: "LESION AUTOINFLIGIDA INTENCIONALMENTE POR MEDIOS NO ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "X850",	text: "AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS: VIVIENDA"},
{ value: "X851",	text: "AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS: INSTITUCION RESIDENCIAL"},
{ value: "X852",	text: "AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X853",	text: "AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X854",	text: "AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS: CALLES Y CARRETERAS"},
{ value: "X855",	text: "AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X856",	text: "AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X857",	text: "AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS: GRANJA"},
{ value: "X858",	text: "AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS: OTRO LUGAR ESPECIFICADO"},
{ value: "X859",	text: "AGRESION CON DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS: LUGAR NO ESPECIFICADO"},
{ value: "X860",	text: "AGRESION CON SUSTANCIA CORROSIVA: VIVIENDA"},
{ value: "X861",	text: "AGRESION CON SUSTANCIACORROSIVA: INSTITUCION RESIDENCIAL"},
{ value: "X862",	text: "AGRESION CON SUSTANCIA CORROSIVA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X863",	text: "AGRESION CON SUSTANCIACORROSIVA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X864",	text: "AGRESION CON SUSTANCIA CORROSIVA: CALLES Y CARRETERAS"},
{ value: "X865",	text: "AGRESION CON SUSTANCIA CORROSIVA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X866",	text: "AGRESION CON SUSTANCIA CORROSIVA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X867",	text: "AGRESION CON SUSTANCIA CORROSIVA: GRANJA"},
{ value: "X868",	text: "AGRESION CON SUSTANCIACORROSIVA: OTRO LUGAR ESPECIFICADO"},
{ value: "X869",	text: "AGRESION CON SUSTANCIA CORROSIVA: LUGAR NO ESPECIFICADO"},
{ value: "X870",	text: "AGRESION CON PLAGUICIDAS: VIVIENDA"},
{ value: "X871",	text: "AGRESION CON PLAGUICIDAS: INSTITUCION RESIDENCIAL"},
{ value: "X872",	text: "AGRESION CON PLAGUICIDAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X873",	text: "AGRESION CON PLAGUICIDAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X874",	text: "AGRESION CON PLAGUICIDAS: CALLES Y CARRETERAS"},
{ value: "X875",	text: "AGRESION CON PLAGUICIDAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X876",	text: "AGRESION CON PLAGUICIDAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X877",	text: "AGRESION CON PLAGUICIDAS: GRANJA"},
{ value: "X878",	text: "AGRESION CON PLAGUICIDAS: OTRO LUGAR ESPECIFICADO"},
{ value: "X879",	text: "AGRESION CON PLAGUICIDAS: LUGAR NO ESPECIFICADO"},
{ value: "X880",	text: "AGRESION CON GASES Y VAPORES: VIVIENDA"},
{ value: "X881",	text: "AGRESION CON GASES Y VAPORES: INSTITUCION RESIDENCIAL"},
{ value: "X882",	text: "AGRESION CON GASES Y VAPORES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X883",	text: "AGRESION CON GASES Y VAPORES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X884",	text: "AGRESION CON GASES Y VAPORES: CALLES Y CARRETERAS"},
{ value: "X885",	text: "AGRESION CON GASES Y VAPORES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X886",	text: "AGRESION CON GASES Y VAPORES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X887",	text: "AGRESION CON GASES Y VAPORES: GRANJA"},
{ value: "X888",	text: "AGRESION CON GASES Y VAPORES: OTRO LUGAR ESPECIFICADO"},
{ value: "X889",	text: "AGRESION CON GASES Y VAPORES: LUGAR NO ESPECIFICADO"},
{ value: "X890",	text: "AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS: VIVIENDA"},
{ value: "X891",	text: "AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS: INSTITUCION RESIDENCIAL"},
{ value: "X892",	text: "AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X893",	text: "AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X894",	text: "AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS: CALLES Y CARRETERAS"},
{ value: "X895",	text: "AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X896",	text: "AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X897",	text: "AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS: GRANJA"},
{ value: "X898",	text: "AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS: OTRO LUGAR ESPECIFICADO"},
{ value: "X899",	text: "AGRESION CON OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS ESPECIFICADAS: LUGAR NO ESPECIFICADO"},
{ value: "X900",	text: "AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS: VIVIENDA"},
{ value: "X901",	text: "AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS: INSTITUCION RESIDENCIAL"},
{ value: "X902",	text: "AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X903",	text: "AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X904",	text: "AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS: CALLES Y CARRETERAS"},
{ value: "X905",	text: "AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X906",	text: "AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X907",	text: "AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS: GRANJA"},
{ value: "X908",	text: "AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS: OTRO LUGAR ESPECIFICADO"},
{ value: "X909",	text: "AGRESION CON PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS NO ESPECIFICADAS: LUGAR NO ESPECIFICADO"},
{ value: "X910",	text: "AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION: VIVIENDA"},
{ value: "X911",	text: "AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION: INSTITUCION RESIDENCIAL"},
{ value: "X912",	text: "AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X913",	text: "AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X914",	text: "AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION: CALLES Y CARRETERAS"},
{ value: "X915",	text: "AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X916",	text: "AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X917",	text: "AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION: GRANJA"},
{ value: "X918",	text: "AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION: OTRO LUGAR ESPECIFICADO"},
{ value: "X919",	text: "AGRESION POR AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION: LUGAR NO ESPECIFICADO"},
{ value: "X920",	text: "AGRESION POR AHOGAMIENTO Y SUMERSION: VIVIENDA"},
{ value: "X921",	text: "AGRESION POR AHOGAMIENTO Y SUMERSION: INSTITUCION RESIDENCIAL"},
{ value: "X922",	text: "AGRESION POR AHOGAMIENTO Y SUMERSION: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X923",	text: "AGRESION POR AHOGAMIENTO Y SUMERSION: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X924",	text: "AGRESION POR AHOGAMIENTO Y SUMERSION: CALLES Y CARRETERAS"},
{ value: "X925",	text: "AGRESION POR AHOGAMIENTO Y SUMERSION: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X926",	text: "AGRESION POR AHOGAMIENTO Y SUMERSION: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X927",	text: "AGRESION POR AHOGAMIENTO Y SUMERSION: GRANJA"},
{ value: "X928",	text: "AGRESION POR AHOGAMIENTO Y SUMERSION: OTRO LUGAR ESPECIFICADO"},
{ value: "X929",	text: "AGRESION POR AHOGAMIENTO Y SUMERSION: LUGAR NO ESPECIFICADO"},
{ value: "X930",	text: "AGRESION CON DISPARO DE ARMA CORTA: VIVIENDA"},
{ value: "X931",	text: "AGRESION CON DISPARO DE ARMA CORTA: INSTITUCION RESIDENCIAL"},
{ value: "X932",	text: "AGRESION CON DISPARO DE ARMA CORTA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X933",	text: "AGRESION CON DISPARO DE ARMA CORTA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X934",	text: "AGRESION CON DISPARO DE ARMA CORTA: CALLES Y CARRETERAS"},
{ value: "X935",	text: "AGRESION CON DISPARO DE ARMA CORTA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X936",	text: "AGRESION CON DISPARO DE ARMA CORTA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X937",	text: "AGRESION CON DISPARO DE ARMA CORTA: GRANJA"},
{ value: "X938",	text: "AGRESION CON DISPARO DE ARMA CORTA: OTRO LUGAR ESPECIFICADO"},
{ value: "X939",	text: "AGRESION CON DISPARO DE ARMA CORTA: LUGAR NO ESPECIFICADO"},
{ value: "X940",	text: "AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: VIVIENDA"},
{ value: "X941",	text: "AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: INSTITUCION RESIDENCIAL"},
{ value: "X942",	text: "AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X943",	text: "AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X944",	text: "AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: CALLES Y CARRETERAS"},
{ value: "X945",	text: "AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X946",	text: "AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X947",	text: "AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: GRANJA"},
{ value: "X948",	text: "AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: OTRO LUGAR ESPECIFICADO"},
{ value: "X949",	text: "AGRESION CON DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA: LUGAR NO ESPECIFICADO"},
{ value: "X950",	text: "AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: VIVIENDA"},
{ value: "X951",	text: "AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: INSTITUCION RESIDENCIAL"},
{ value: "X952",	text: "AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X953",	text: "AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X954",	text: "AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: CALLES Y CARRETERAS"},
{ value: "X955",	text: "AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X956",	text: "AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X957",	text: "AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: GRANJA"},
{ value: "X958",	text: "AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: OTRO LUGAR ESPECIFICADO"},
{ value: "X959",	text: "AGRESION CON DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS: LUGAR NO ESPECIFICADO"},
{ value: "X960",	text: "AGRESION CON MATERIAL EXPLOSIVO: VIVIENDA"},
{ value: "X961",	text: "AGRESION CON MATERIAL EXPLOSIVO: INSTITUCION RESIDENCIAL"},
{ value: "X962",	text: "AGRESION CON MATERIAL EXPLOSIVO: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X963",	text: "AGRESION CON MATERIAL EXPLOSIVO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X964",	text: "AGRESION CON MATERIAL EXPLOSIVO: CALLES Y CARRETERAS"},
{ value: "X965",	text: "AGRESION CON MATERIAL EXPLOSIVO: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X966",	text: "AGRESION CON MATERIAL EXPLOSIVO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X967",	text: "AGRESION CON MATERIAL EXPLOSIVO: GRANJA"},
{ value: "X968",	text: "AGRESION CON MATERIAL EXPLOSIVO: OTRO LUGAR ESPECIFICADO"},
{ value: "X969",	text: "AGRESION CON MATERIAL EXPLOSIVO: LUGAR NO ESPECIFICADO"},
{ value: "X970",	text: "AGRESION CON HUMO, FUEGO Y LLAMAS: VIVIENDA"},
{ value: "X971",	text: "AGRESION CON HUMO, FUEGO Y LLAMAS: INSTITUCION RESIDENCIAL"},
{ value: "X972",	text: "AGRESION CON HUMO, FUEGO Y LLAMAS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X973",	text: "AGRESION CON HUMO, FUEGO Y LLAMAS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X974",	text: "AGRESION CON HUMO, FUEGO Y LLAMAS: CALLES Y CARRETERAS"},
{ value: "X975",	text: "AGRESION CON HUMO, FUEGO Y LLAMAS: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X976",	text: "AGRESION CON HUMO, FUEGO Y LLAMAS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X977",	text: "AGRESION CON HUMO, FUEGO Y LLAMAS: GRANJA"},
{ value: "X978",	text: "AGRESION CON HUMO, FUEGO Y LLAMAS: OTRO LUGAR ESPECIFICADO"},
{ value: "X979",	text: "AGRESION CON HUMO, FUEGO Y LLAMAS: LUGAR NO ESPECIFICADO"},
{ value: "X980",	text: "AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: VIVIENDA"},
{ value: "X981",	text: "AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: INSTITUCION RESIDENCIAL"},
{ value: "X982",	text: "AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X983",	text: "AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X984",	text: "AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: CALLES Y CARRETERAS"},
{ value: "X985",	text: "AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X986",	text: "AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X987",	text: "AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: GRANJA"},
{ value: "X988",	text: "AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: OTRO LUGAR ESPECIFICADO"},
{ value: "X989",	text: "AGRESION CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES: LUGAR NO ESPECIFICADO"},
{ value: "X990",	text: "AGRESION CON OBJETO CORTANTE: VIVIENDA"},
{ value: "X991",	text: "AGRESION CON OBJETO CORTANTE: INSTITUCION RESIDENCIAL"},
{ value: "X992",	text: "AGRESION CON OBJETO CORTANTE: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS PUBLICAS"},
{ value: "X993",	text: "AGRESION CON OBJETO CORTANTE: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "X994",	text: "AGRESION CON OBJETO CORTANTE: CALLES Y CARRETERAS"},
{ value: "X995",	text: "AGRESION CON OBJETO CORTANTE: COMERCIO Y AREAS DE SERVICIO"},
{ value: "X996",	text: "AGRESION CON OBJETO CORTANTE: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "X997",	text: "AGRESION CON OBJETO CORTANTE: GRANJA"},
{ value: "X998",	text: "AGRESION CON OBJETO CORTANTE: OTRO LUGAR ESPECIFICADO"},
{ value: "X999",	text: "AGRESION CON OBJETO CORTANTE: LUGAR NO ESPECIFICADO"},
{ value: "Y000",	text: "AGRESION CON OBJETO ROMO O SIN FILO: VIVIENDA"},
{ value: "Y001",	text: "AGRESION CON OBJETO ROMO O SIN FILO: INSTITUCION RESIDENCIAL"},
{ value: "Y002",	text: "AGRESION CON OBJETO ROMO O SIN FILO: ESCUELAS OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y003",	text: "AGRESION CON OBJETO ROMO O SIN FILO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y004",	text: "AGRESION CON OBJETO ROMO O SIN FILO: CALLES Y CARRETERAS"},
{ value: "Y005",	text: "AGRESION CON OBJETO ROMO O SIN FILO: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y006",	text: "AGRESION CON OBJETO ROMO O SIN FILO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y007",	text: "AGRESION CON OBJETO ROMO O SIN FILO: GRANJA"},
{ value: "Y008",	text: "AGRESION CON OBJETO ROMO O SIN FILO: OTRO LUGAR ESPECIFICADO"},
{ value: "Y009",	text: "AGRESION CON OBJETO ROMO O SIN FILO: LUGAR NO ESPECIFICADO"},
{ value: "Y010",	text: "AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO: VIVIENDA"},
{ value: "Y011",	text: "AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO: INSTITUCION RESIDENCIAL"},
{ value: "Y012",	text: "AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO: ESCUELAS OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y013",	text: "AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y014",	text: "AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO: CALLES Y CARRETERAS"},
{ value: "Y015",	text: "AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y016",	text: "AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y017",	text: "AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO: GRANJA"},
{ value: "Y018",	text: "AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO: OTRO LUGAR ESPECIFICADO"},
{ value: "Y019",	text: "AGRESION POR EMPUJON DESDE UN LUGAR ELEVADO: LUGAR NO ESPECIFICADO"},
{ value: "Y020",	text: "AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO: VIVIENDA"},
{ value: "Y021",	text: "AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO: INSTITUCION RESIDENCIAL"},
{ value: "Y022",	text: "AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO: ESCUELAS OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y023",	text: "AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y024",	text: "AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO: CALLES Y CARRETERAS"},
{ value: "Y025",	text: "AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y026",	text: "AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y027",	text: "AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO: GRANJA"},
{ value: "Y028",	text: "AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO: OTRO LUGAR ESPECIFICADO"},
{ value: "Y029",	text: "AGRESION POR EMPUJAR O COLOCAR A LA VICTIMA DELANTE DE OBJETO EN MOVIMIENTO: LUGAR NO ESPECIFICADO"},
{ value: "Y030",	text: "AGRESION POR COLISION DE VEHICULO DE MOTOR: VIVIENDA"},
{ value: "Y031",	text: "AGRESION POR COLISION DE VEHICULO DE MOTOR: INSTITUCION RESIDENCIAL"},
{ value: "Y032",	text: "AGRESION POR COLISION DE VEHICULO DE MOTOR: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y033",	text: "AGRESION POR COLISION DE VEHICULO DE MOTOR: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y034",	text: "AGRESION POR COLISION DE VEHICULO DE MOTOR: CALLES Y CARRETERAS"},
{ value: "Y035",	text: "AGRESION POR COLISION DE VEHICULO DE MOTOR: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y036",	text: "AGRESION POR COLISION DE VEHICULO DE MOTOR: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y037",	text: "AGRESION POR COLISION DE VEHICULO DE MOTOR: GRANJA"},
{ value: "Y038",	text: "AGRESION POR COLISION DE VEHICULO DE MOTOR: OTRO LUGAR ESPECIFICADO"},
{ value: "Y039",	text: "AGRESION POR COLISION DE VEHICULO DE MOTOR: LUGAR NO ESPECIFICADO"},
{ value: "Y040",	text: "AGRESION CON FUERZA CORPORAL: VIVIENDA"},
{ value: "Y041",	text: "AGRESION CON FUERZA CORPORAL: INSTITUCION RESIDENCIAL"},
{ value: "Y042",	text: "AGRESION CON FUERZA CORPORAL: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y043",	text: "AGRESION CON FUERZA CORPORAL: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y044",	text: "AGRESION CON FUERZA CORPORAL: CALLES Y CARRETERAS"},
{ value: "Y045",	text: "AGRESION CON FUERZA CORPORAL: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y046",	text: "AGRESION CON FUERZA CORPORAL: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y047",	text: "AGRESION CON FUERZA CORPORAL: GRANJA"},
{ value: "Y048",	text: "AGRESION CON FUERZA CORPORAL: OTRO LUGAR ESPECIFICADO"},
{ value: "Y049",	text: "AGRESION CON FUERZA CORPORAL: LUGAR NO ESPECIFICADO"},
{ value: "Y050",	text: "AGRESION SEXUAL CON FUERZA CORPORAL: VIVIENDA"},
{ value: "Y051",	text: "AGRESION SEXUAL CON FUERZA CORPORAL: INSTITUCION RESIDENCIAL"},
{ value: "Y052",	text: "AGRESION SEXUAL CON FUERZA CORPORAL: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y053",	text: "AGRESION SEXUAL CON FUERZA CORPORAL: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y054",	text: "AGRESION SEXUAL CON FUERZA CORPORAL: CALLES Y CARRETERAS"},
{ value: "Y055",	text: "AGRESION SEXUAL CON FUERZA CORPORAL: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y056",	text: "AGRESION SEXUAL CON FUERZA CORPORAL: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y057",	text: "AGRESION SEXUAL CON FUERZA CORPORAL: GRANJA"},
{ value: "Y058",	text: "AGRESION SEXUAL CON FUERZA CORPORAL: OTRO LUGAR ESPECIFICADO"},
{ value: "Y059",	text: "AGRESION SEXUAL CON FUERZA CORPORAL: LUGAR NO ESPECIFICADO"},
{ value: "Y060",	text: "NEGLICENCIA Y ABANDONO: POR ESPOSO O PAREJA"},
{ value: "Y061",	text: "NEGLICENCIA Y ABANDONO: POR PADRE O MADRE"},
{ value: "Y062",	text: "NEGLICENCIA Y ABANDONO: POR CONOCIDO O AMIGO"},
{ value: "Y068",	text: "NEGLICENCIA Y ABANDONO: POR OTRA PERSONA ESPECIFICADA"},
{ value: "Y069",	text: "NEGLICENCIA Y ABANDONO: POR PERSONA NO ESPECIFICADA"},
{ value: "Y070",	text: "OTROS SINDROMES DE MALTRATO: POR ESPOSO O PAREJA"},
{ value: "Y071",	text: "OTROS SINDROMES DE MALTRATO: POR PADRE O MADRE"},
{ value: "Y072",	text: "OTROS SINDROMES DE MALTRATO: POR CONOCIDO O AMIGO"},
{ value: "Y073",	text: "OTROS SINDROMES DE MALTRATO: POR AUTORIDADES OFICIALES"},
{ value: "Y078",	text: "OTROS SINDROMES DE MALTRATO: POR OTRA PERSONA ESPECIFICADA"},
{ value: "Y079",	text: "OTROS SINDROMES DE MALTRATO: POR PERSONA NO ESPECIFICADA"},
{ value: "Y080",	text: "AGRESION POR OTROS MEDIOS ESPECIFICADOS: VIVIENDA"},
{ value: "Y081",	text: "AGRESION POR OTROS MEDIOS ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "Y082",	text: "AGRESION POR OTROS MEDIOS ESPECIFICADOS: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y083",	text: "AGRESION POR OTROS MEDIOS ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y084",	text: "AGRESION POR OTROS MEDIOS ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "Y085",	text: "AGRESION POR OTROS MEDIOS ESPECIFICADOS: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y086",	text: "AGRESION POR OTROS MEDIOS ESPECIFICADOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y087",	text: "AGRESION POR OTROS MEDIOS ESPECIFICADOS: GRANJA"},
{ value: "Y088",	text: "AGRESION POR OTROS MEDIOS ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "Y089",	text: "AGRESION POR OTROS MEDIOS ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "Y090",	text: "AGRESION POR MEDIOS NO ESPECIFICADOS: VIVIENDA"},
{ value: "Y091",	text: "AGRESION POR MEDIOS NO ESPECIFICADOS: INSTITUCION RESIDENCIAL"},
{ value: "Y092",	text: "AGRESION POR MEDIOS NO ESPECIFICADOS: ESCUELAS OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y093",	text: "AGRESION POR MEDIOS NO ESPECIFICADOS: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y094",	text: "AGRESION POR MEDIOS NO ESPECIFICADOS: CALLES Y CARRETERAS"},
{ value: "Y095",	text: "AGRESION POR MEDIOS NO ESPECIFICADOS: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y096",	text: "AGRESION POR MEDIOS NO ESPECIFICADOS: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y097",	text: "AGRESION POR MEDIOS NO ESPECIFICADOS: GRANJA"},
{ value: "Y098",	text: "AGRESION POR MEDIOS NO ESPECIFICADOS: OTRO LUGAR ESPECIFICADO"},
{ value: "Y099",	text: "AGRESION POR MEDIOS NO ESPECIFICADOS: LUGAR NO ESPECIFICADO"},
{ value: "Y100",	text: "ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y101",	text: "ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y102",	text: "ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, DE INTENCION NO DETERMINADA: ESCUELAS OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y103",	text: "ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y104",	text: "ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y105",	text: "ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y106",	text: "ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y107",	text: "ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y108",	text: "ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y109",	text: "ENVENENAMIENTO POR, Y EXPOSICION A ANALGESICOS NO NARCOTICOS, ANTIPIRETICOS Y ANTIRREUMATICOS, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y110",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y111",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y112",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: ESCUELAS OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y113",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y114",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y115",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y116",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y117",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y118",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y119",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DROGAS ANTIEPILEPTICAS, SEDANTES, HIPNOTICAS, ANTIPARKINSONIANAS Y PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y120",	text: "ENVENENAMIENTO POR, Y EXPOSICION  A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y121",	text: "ENVENENAMIENTO POR, Y EXPOSICION  A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y122",	text: "ENVENENAMIENTO POR, Y EXPOSICION  A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: ESCUELAS OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y123",	text: "ENVENENAMIENTO POR, Y EXPOSICION  A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y124",	text: "ENVENENAMIENTO POR, Y EXPOSICION  A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y125",	text: "ENVENENAMIENTO POR, Y EXPOSICION  A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y126",	text: "ENVENENAMIENTO POR, Y EXPOSICION  A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y127",	text: "ENVENENAMIENTO POR, Y EXPOSICION  A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y128",	text: "ENVENENAMIENTO POR, Y EXPOSICION  A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y129",	text: "ENVENENAMIENTO POR, Y EXPOSICION  A NARCOTICOS Y PSICODISLEPTICOS [ALUCINOGENOS], NO CLASIFICADAS EN OTRA PARTE, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y130",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y131",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y132",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA: ESCUELAS OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y133",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y134",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y135",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y136",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y137",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y138",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y139",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS QUE ACTUAN SOBRE EL SISTEMA NERVIOSO AUTONOMO, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y140",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y141",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y142",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: ESCUELAS OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y143",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y144",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y145",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y146",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y147",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y148",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y149",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y150",	text: "ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL , DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y151",	text: "ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL , DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y152",	text: "ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL , DE INTENCION NO DETERMINADA: ESCUELAS OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y153",	text: "ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL , DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y154",	text: "ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL , DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y155",	text: "ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL , DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y156",	text: "ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL , DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y157",	text: "ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL , DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y158",	text: "ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL , DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y159",	text: "ENVENENAMIENTO POR, Y EXPOSICION AL ALCOHOL , DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y160",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y161",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y162",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETERMINADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y163",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y164",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y165",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y166",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y167",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y168",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y169",	text: "ENVENENAMIENTO POR, Y EXPOSICION A DISOLVENTES ORGANICOS E HIDROCARBUROS HALOGENADOS Y SUS VAPORES, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y170",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y171",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y172",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y173",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y174",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y175",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y176",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y177",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y178",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y179",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS GASES Y VAPORES, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y180",	text: "ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y181",	text: "ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y182",	text: "ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA: ESCUELAS OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y183",	text: "ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y184",	text: "ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y185",	text: "ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y186",	text: "ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y187",	text: "ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y188",	text: "ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y189",	text: "ENVENENAMIENTO POR, Y EXPOSICION A PLAGUICIDAS, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y190",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y191",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y192",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA: ESCUELAS OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y193",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y194",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y195",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y196",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y197",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y198",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y199",	text: "ENVENENAMIENTO POR, Y EXPOSICION A OTROS PRODUCTOS QUIMICOS Y SUSTANCIAS NOCIVAS, Y LOS NO ESPECIFICADOS, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y200",	text: "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y201",	text: "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y202",	text: "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA: ESCUELAS OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y203",	text: "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y204",	text: "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y205",	text: "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y206",	text: "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y207",	text: "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y208",	text: "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y209",	text: "AHORCAMIENTO, ESTRANGULAMIENTO Y SOFOCACION, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y210",	text: "AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y211",	text: "AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y212",	text: "AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA: ESCUELAS OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y213",	text: "AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y214",	text: "AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y215",	text: "AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y216",	text: "AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y217",	text: "AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y218",	text: "AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y219",	text: "AHOGAMIENTO Y SUMERSION, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y220",	text: "DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y221",	text: "DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y222",	text: "DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y223",	text: "DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y224",	text: "DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y225",	text: "DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y226",	text: "DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y227",	text: "DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y228",	text: "DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y229",	text: "DISPARO DE ARMA CORTA, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y230",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y231",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y232",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y233",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y234",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y235",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y236",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y237",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y238",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y239",	text: "DISPARO DE RIFLE, ESCOPETA Y ARMA LARGA, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y240",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y241",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y242",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y243",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y244",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y245",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y246",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y247",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y248",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y249",	text: "DISPARO DE OTRAS ARMAS DE FUEGO, Y LAS NO ESPECIFICADAS, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y250",	text: "CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y251",	text: "CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y252",	text: "CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTENCION NO DETERMINADA: ESCUELAS OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y253",	text: "CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y254",	text: "CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y255",	text: "CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y256",	text: "CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y257",	text: "CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y258",	text: "CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y259",	text: "CONTACTO TRAUMATICO CON MATERIAL EXPLOSIVO, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y260",	text: "EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y261",	text: "EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y262",	text: "EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y263",	text: "EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y264",	text: "EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y265",	text: "EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y266",	text: "EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y267",	text: "EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y268",	text: "EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y269",	text: "EXPOSICION AL HUMO, FUEGO Y LLAMAS, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y270",	text: "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y271",	text: "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y272",	text: "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y273",	text: "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y274",	text: "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y275",	text: "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y276",	text: "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y277",	text: "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y278",	text: "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y279",	text: "CONTACTO CON VAPOR DE AGUA, VAPORES Y OBJETOS CALIENTES, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y280",	text: "CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y281",	text: "CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y282",	text: "CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y283",	text: "CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y284",	text: "CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y285",	text: "CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y286",	text: "CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y287",	text: "CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y288",	text: "CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y289",	text: "CONTACTO TRAUMATICO CON OBJETO CORTANTE, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y290",	text: "CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y291",	text: "CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y292",	text: "CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y293",	text: "CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y294",	text: "CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y295",	text: "CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y296",	text: "CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y297",	text: "CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y298",	text: "CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y299",	text: "CONTACTO TRAUMATICO CON OBJETO ROMO O SIN FILO, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y300",	text: "CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y301",	text: "CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y302",	text: "CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y303",	text: "CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y304",	text: "CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y305",	text: "CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y306",	text: "CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y307",	text: "CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y308",	text: "CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y309",	text: "CAIDA, SALTO O EMPUJON DESDE LUGAR ELEVADO, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y310",	text: "CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y311",	text: "CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y312",	text: "CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y313",	text: "CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y314",	text: "CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y315",	text: "CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y316",	text: "CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y317",	text: "CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y318",	text: "CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y319",	text: "CAIDA, PERMANENCIA O CARRERA DELANTE O HACIA OBJETO EN MOVIMIENTO, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y320",	text: "COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y321",	text: "COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y322",	text: "COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y323",	text: "COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y324",	text: "COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y325",	text: "COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y326",	text: "COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y327",	text: "COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y328",	text: "COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y329",	text: "COLISION DE VEHICULO DE MOTOR, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y330",	text: "OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y331",	text: "OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y332",	text: "OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y333",	text: "OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y334",	text: "OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y335",	text: "OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y336",	text: "OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y337",	text: "OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y338",	text: "OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y339",	text: "OTROS EVENTOS ESPECIFICADOS, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y340",	text: "EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA: VIVIENDA"},
{ value: "Y341",	text: "EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA: INSTITUCION RESIDENCIAL"},
{ value: "Y342",	text: "EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA: ESCUELAS, OTRAS INSTITUCIONES Y AREAS ADMINISTRATIVAS"},
{ value: "Y343",	text: "EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA: AREAS DE DEPORTE Y ATLETISMO"},
{ value: "Y344",	text: "EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA: CALLES Y CARRETERAS"},
{ value: "Y345",	text: "EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA: COMERCIO Y AREA DE SERVICIOS"},
{ value: "Y346",	text: "EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA: AREA INDUSTRIAL Y DE LA CONSTRUCCION"},
{ value: "Y347",	text: "EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA: GRANJA"},
{ value: "Y348",	text: "EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA: OTRO LUGAR ESPECIFICADO"},
{ value: "Y349",	text: "EVENTO NO ESPECIFICADO, DE INTENCION NO DETERMINADA: LUGAR NO ESPECIFICADO"},
{ value: "Y350",	text: "INTERVENCION LEGAL CON DISPARO DE ARMA DE FUEGO"},
{ value: "Y351",	text: "INTERVENCION LEGAL CON EXPLOSIVOS"},
{ value: "Y352",	text: "INTERVENCION LEGAL CON GAS"},
{ value: "Y353",	text: "INTERVENCION LEGAL CON OBJETOS ROMOS O SIN FILO"},
{ value: "Y354",	text: "INTERVENCION LEGAL CON OBJETOS CORTANTES"},
{ value: "Y355",	text: "EJECUCION LEGAL"},
{ value: "Y356",	text: "INTERVENCION LEGAL CON OTROS MEDIOS ESPECIFICADOS"},
{ value: "Y357",	text: "INTERVENCION LEGAL, MEDIOS NO ESPECIFICADOS"},
{ value: "Y360",	text: "OPERACIONES DE GUERRA CON EXPLOSION DE ARMAMENTO NAVAL"},
{ value: "Y361",	text: "OPERACIONES DE GUERRA CON DESTRUCCION DE AERONAVE"},
{ value: "Y362",	text: "OPERACIONES DE GUERRA CON OTRAS EXPLOSIONES Y ESQUIRLAS"},
{ value: "Y363",	text: "OPERACIONES DE GUERRA CON FUEGO Y SUSTANCIAS INCENDIARIAS Y CALIENTES"},
{ value: "Y364",	text: "OPERACIONES DE GUERRA CON DISPARO DE ARMA DE FUEGO Y OTRAS FORMAS DE GUERRA CONVENCIONAL"},
{ value: "Y365",	text: "OPERACIONES DE GUERRA CON ARMAS NUCLEARES"},
{ value: "Y366",	text: "OPERACIONES DE GUERRA CON ARMAS BIOLOGICAS"},
{ value: "Y367",	text: "OPERACIONES DE GUERRA CON ARMAS QUIMICAS Y OTRAS FORMAS DE GUERRA NO CONVENCIONAL"},
{ value: "Y368",	text: "OPERACIONES DE GUERRA QUE OCURREN DESPUES DEL CESE DE HOSTILIDADES"},
{ value: "Y369",	text: "OPERACION DE GUERRA NO ESPECIFICADA"},
{ value: "Y400",	text: "EFECTOS ADVERSOS DE PENICILINAS"},
{ value: "Y401",	text: "EFECTOS ADVERSOS DE CEFALOSPORINAS Y OTROS ANTIBIOTICOS BETALACTAMICOS"},
{ value: "Y402",	text: "EFECTOS ADVERSOS DEL GRUPO DE CLORAMFENICOL"},
{ value: "Y403",	text: "EFECTOS ADVERSOS DE LOS MACROLIDOS"},
{ value: "Y404",	text: "EFECTOS ADVERSOS DE TETRACICLINAS"},
{ value: "Y405",	text: "EFECTOS ADVERSOS DE AMINOGLICOSIDOS"},
{ value: "Y406",	text: "EFECTOS ADVERSOS DE RIFAMICINAS"},
{ value: "Y407",	text: "EFECTOS ADVERSOS DE ANTIBIOTICOS ANTIMICOTICOS USADOS SISTEMATICAMENTE"},
{ value: "Y408",	text: "EFECTOS ADVERSOS DE OTROS ANTIBIOTICOS SISTEMICOS"},
{ value: "Y409",	text: "EFECTOS ADVERSOS DE ANTIBIOTICO SISTEMICO NO ESPECIFICADO"},
{ value: "Y410",	text: "EFECTOS ADVERSOS DE SULFONAMIDAS"},
{ value: "Y411",	text: "EFECTOS ADVERSOS DE DROGAS ANTIMICOBACTERIANAS"},
{ value: "Y412",	text: "EFECTOS ADVERSOS DE DROGAS ANTIPALUDICAS Y AGENTES QUE ACTUAN SOBRE OTROS PROTOZOARIOS DE LA SANGRE"},
{ value: "Y413",	text: "EFECTOS ADVERSOS DE OTRAS DROGAS ANTIPROTOZOARIAS"},
{ value: "Y414",	text: "EFECTOS ADVERSOS DE ANTIHELMINTICOS"},
{ value: "Y415",	text: "EFECTOS ADVERSOS DE DROGAS ANTIVIRALES"},
{ value: "Y418",	text: "EFECTOS ADVERSOS DE OTROS ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS ESPECIFICADOS"},
{ value: "Y419",	text: "EFECTOS ADVERSOS DE ANTIINFECCIOSOS Y ANTIPARASITARIOS SISTEMICOS NO ESPECIFICADOS"},
{ value: "Y420",	text: "EFECTOS ADVERSOS DE GLUCOCORTICOIDES Y ANALOGOS SINTETICOS"},
{ value: "Y421",	text: "EFECTOS ADVERSOS DE HORMONAS TIROIDEAS Y SUSTITUTOS"},
{ value: "Y422",	text: "EFECTOS ADVERSOS DE DROGAS ANTITIROIDEAS"},
{ value: "Y423",	text: "EFECTOS ADVERSOS DE DROGAS HIPOGLUCEMIANTES ORALES E INSULINA [ANTIDIABETICAS]"},
{ value: "Y424",	text: "EFECTOS ADVERSOS DE ANTICONCEPTIVOS ORALES"},
{ value: "Y425",	text: "EFECTOS ADVERSOS DE OTROS ESTROGENOS Y PROGESTAGENOS"},
{ value: "Y426",	text: "EFECTOS ADVERSOS DE ANTIGONADOTROPINAS, ANTIESTROGENOS Y ANTIANDROGENOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y427",	text: "EFECTOS ADVERSOS DE ANDROGENOS Y CONGENERES ANABOLICOS"},
{ value: "Y428",	text: "EFECTOS ADVERSOS DE OTRAS HORMONAS Y SUS SUSTITUTOS SINTETICOS, Y LAS NO ESPECIFICADAS"},
{ value: "Y429",	text: "EFECTOS ADVERSOS DE OTRAS HORMONAS ANTAGONISTAS, Y LAS NO ESPECIFICADAS"},
{ value: "Y430",	text: "EFECTOS ADVERSOS DE ANTIALERGICOS Y ANTIEMETICOS"},
{ value: "Y431",	text: "EFECTOS ADVERSOS DE ANTIMETABOLITOS ANTINEOPLASICOS"},
{ value: "Y432",	text: "EFECTOS ADVERSOS DE PRODUCTOS NATURALES ANTINEOPLASICOS"},
{ value: "Y433",	text: "EFECTOS ADVERSOS DE OTRAS DROGAS ANTINEOPLASICAS"},
{ value: "Y434",	text: "EFECTOS ADVERSOS DE AGENTES INMUNOSUPRESORES"},
{ value: "Y435",	text: "EFECTOS ADVERSOS DE AGENTES ACIDIFICANTES Y ALCALINIZANTES"},
{ value: "Y436",	text: "EFECTOS ADVERSOS DE ENZIMAS NO CLASIFICADAS EN OTRA PARTE"},
{ value: "Y438",	text: "EFECTOS ADVERSOS DE OTROS AGENTES SISTEMICOS PRIMARIOS NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y439",	text: "EFECTOS ADVERSOS DE AGENTE SISTEMICO PRIMARIO NO ESPECIFICADO"},
{ value: "Y440",	text: "EFECTOS ADVERSOS DE PREPARACIONES CON HIERRO Y OTROS PREPARADOS CONTRA LA ANEMIA HIPOCROMICA"},
{ value: "Y441",	text: "EFECTOS ADVERSOS DE VITAMINA B12, ACIDO FOLICO Y OTROS PREPARADOS CONTRA LA ANEMIA MEGALOBLASTICA"},
{ value: "Y442",	text: "EFECTOS ADVERSOS DE ANTICOAGULANTES"},
{ value: "Y443",	text: "EFECTOS ADVERSOS DE ANTAGONISTAS DE ANTICOAGULANTES, VITAMINA K Y OTROS COAGULANTES"},
{ value: "Y444",	text: "EFECTOS ADVERSOS DE DROGAS ANTITROMBOTICAS [INHIBIDORAS DE LA AGREGACION PLAQUETARIA]"},
{ value: "Y445",	text: "EFECTOS ADVERSOS DE DROGAS TROMBOLITICAS"},
{ value: "Y446",	text: "EFECTOS ADVERSOS DE SANGRE NATURAL Y PRODUCTOS SANGUINEOS"},
{ value: "Y447",	text: "EFECTOS ADVERSOS DE LOS SUSTITUTOS DEL PLASMA"},
{ value: "Y449",	text: "EFECTOS ADVERSOS DE OTROS AGENTES QUE AFECTAN LOS CONSTITUYENTES DE LA SANGRE, Y LOS NO ESPECIFICADOS"},
{ value: "Y450",	text: "EFECTOS ADVERSOS DE OPIACEOS Y ANALGESICOS RELACIONADOS"},
{ value: "Y451",	text: "EFECTOS ADVERSOS DE SALICILATOS"},
{ value: "Y452",	text: "EFECTOS ADVERSOS DE DERIVADOS DEL ACIDO PROPIONICO"},
{ value: "Y453",	text: "EFECTOS ADVERSOS DE OTRAS DROGAS ANTIINFLAMATORIAS NO ESTEROIDES [DAINE]"},
{ value: "Y454",	text: "EFECTOS ADVERSOS DE LOS ANTIRREUMATICOS"},
{ value: "Y455",	text: "EFECTOS ADVERSOS DE LOS DERIVADOS DEL 4-AMINOFENOL"},
{ value: "Y458",	text: "EFECTOS ADVERSOS DE OTROS ANALGESICOS Y ANTIPIRETICOS"},
{ value: "Y459",	text: "EFECTOS ADVERSOS DE DROGAS ANALGESICAS, ANTIPIRETICAS Y ANTIINFLAMATORIAS NO ESPECIFICADAS"},
{ value: "Y460",	text: "EFECTOS ADVERSOS DE SUCCINAMIDAS"},
{ value: "Y461",	text: "EFECTOS ADVERSOS DE OXAZOLIDINADIONAS"},
{ value: "Y462",	text: "EFECTOS ADVERSOS DE DERIVADOS DE LA HIDANTOINA"},
{ value: "Y463",	text: "EFECTOS ADVERSOS DE DESOXIBARBITURICOS"},
{ value: "Y464",	text: "EFECTOS ADVERSOS DE IMINOESTILBENOS"},
{ value: "Y465",	text: "EFECTOS ADVERSOS DEL ACIDO VALPROICO"},
{ value: "Y466",	text: "EFECTOS ADVERSOS DE OTROS ANTIEPILEPTICOS, Y LOS NO ESPECIFICADOS"},
{ value: "Y467",	text: "EFECTOS ADVERSOS DE DROGAS ANTIPARKINSONIANAS"},
{ value: "Y468",	text: "EFECTOS ADVERSOS DE DROGAS ANTIESPASTICAS"},
{ value: "Y470",	text: "EFECTOS ADVERSOS DE BARBITURICOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y471",	text: "EFECTOS ADVERSOS DE BENZODIAZEPINAS"},
{ value: "Y472",	text: "EFECTOS ADVERSOS DE DERIVADOS CLORALES"},
{ value: "Y473",	text: "EFECTOS ADVERSOS DE PARALDEHIDO"},
{ value: "Y474",	text: "EFECTOS ADVERSOS DE COMPUESTOS DE BROMO"},
{ value: "Y475",	text: "EFECTOS ADVERSOS DE MEZCLAS SEDANTES E HIPNOTICAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "Y478",	text: "EFECTOS ADVERSOS DE OTRAS DROGAS SEDANTES, HIPNOTICAS Y ANSIOLITICAS"},
{ value: "Y479",	text: "EFECTOS ADVERSOS DE DROGAS SEDANTES, HIPNOTICAS Y ANSIOLITICAS NO ESPECIFICADAS"},
{ value: "Y480",	text: "EFECTOS ADVERSOS DE GASES ANESTESICOS POR INHALACION"},
{ value: "Y481",	text: "EFECTOS ADVERSOS DE GASES ANESTESICOS PARENTERALES"},
{ value: "Y482",	text: "EFECTOS ADVERSOS DE OTROS GASES ANESTESICOS GENERALES, Y LOS NO ESPECIFICADOS"},
{ value: "Y483",	text: "EFECTOS ADVERSOS DE GASES ANESTESICOS LOCALES"},
{ value: "Y484",	text: "EFECTOS ADVERSOS DE ANESTESICOS NO ESPECIFICADOS"},
{ value: "Y485",	text: "EFECTOS ADVERSOS DE GASES TERAPEUTICOS"},
{ value: "Y490",	text: "EFECTOS ADVERSOS DE ANTIDEPRESIVOS TRICICLICOS Y TETRACICLICOS"},
{ value: "Y491",	text: "EFECTOS ADVERSOS DE ANTIDEPRESIVOS INHIBIDORES DE LA MONOAMINOOXIDASA"},
{ value: "Y492",	text: "EFECTOS ADVERSOS DE OTROS ANTIDEPRESIVOS Y LOS NO ESPECIFICADOS"},
{ value: "Y493",	text: "EFECTOS ADVERSOS DE ANTIPSICOTICOS Y NEUROLEPTICOS FENOTIAZINICOS"},
{ value: "Y494",	text: "EFECTOS ADVERSOS DE NEUROLEPTICOS DE LA BUTIROFENONA Y TIOXANTINA"},
{ value: "Y495",	text: "EFECTOS ADVERSOS DE OTROS ANTIPSICOTICOS Y NEUROLEPTICOS"},
{ value: "Y496",	text: "EFECTOS ADVERSOS DE PSICODISLEPTICOS [ALUCINOGENOS]"},
{ value: "Y497",	text: "EFECTOS ADVERSOS DE PSICOESTIMULANTES CON ABUSO POTENCIAL"},
{ value: "Y498",	text: "EFECTOS ADVERSOS DE OTRAS DROGAS PSICOTROPICAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "Y499",	text: "EFECTOS ADVERSOS DE DROGAS PSICOTROPICAS NO ESPECIFICADAS"},
{ value: "Y500",	text: "EFECTOS ADVERSOS DE ANALEPTICOS"},
{ value: "Y501",	text: "EFECTOS ADVERSOS DE ANTAGONISTAS DE OPIACEOS"},
{ value: "Y502",	text: "EFECTOS ADVERSOS DE METILXANTINAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "Y508",	text: "EFECTOS ADVERSOS DE OTROS ESTIMULANTES DEL SISTEMA NERVIOSO CENTRAL"},
{ value: "Y509",	text: "EFECTOS ADVERSOS DE ESTIMULANTE NO ESPECIFICADO DEL SISTEMA NERVIOSO CENTRAL"},
{ value: "Y510",	text: "EFECTOS ADVERSOS DE AGENTES ANTICOLINESTERASA"},
{ value: "Y511",	text: "EFECTOS ADVERSOS DE OTROS PARASIMPATICOMIMETICOS [COLINERGICOS]"},
{ value: "Y512",	text: "EFECTOS ADVERSOS DE DROGAS BLOQUEADORAS GANGLIONARES, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "Y513",	text: "EFECTOS ADVERSOS DE OTROS PARASIMPATICOLITICOS [ANTICOLINERGICOS Y ANTIMUSCARINICOS] Y ESPASMOLITICOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y514",	text: "EFECTOS ADVERSOS DE AGONISTAS [ESTIMULANTES] PREDOMINANTEMENTE ALFA-ADRENERGICOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y515",	text: "EFECTOS ADVERSOS DE AGONISTAS [ESTIMULANTES] PREDOMINANTEMENTE BETA-ADRENERGICOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y516",	text: "EFECTOS ADVERSOS DE ANTAGONISTAS [BLOQUEADORES] ALFA- ADRENERGICOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y517",	text: "EFECTOS ADVERSOS DE ANTAGONISTAS [BLOQUEADORES] BETA- ADRENERGICOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y518",	text: "EFECTOS ADVERSOS DE AGENTES BLOQUEADORES NEURO-ADRENERGICOS QUE ACTUAN CENTRALMENTE, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y519",	text: "EFECTOS ADVERSOS DE OTRAS DROGAS QUE AFECTAN PRIMARIAMENTE EL SISTEMA NERVIOSO AUTONOMO, Y LAS NO ESPECIFICADAS"},
{ value: "Y520",	text: "EFECTOS ADVERSOS DE GLUCOSIDOS CARDIOTONICOS Y DROGAS DE ACCION SIMILAR"},
{ value: "Y521",	text: "EFECTOS ADVERSOS DE BLOQUEADORES DEL CANAL DEL CALCIO"},
{ value: "Y522",	text: "EFECTOS ADVERSOS DE OTRAS DROGAS ANTIARRITMICAS, NO CLASIFICADAS EN OTRAS PARTES"},
{ value: "Y523",	text: "EFECTOS ADVERSOS DE VASODILATADORES CORONARIOS, NO CLASIFICADOS EN OTRAS PARTES"},
{ value: "Y524",	text: "EFECTOS ADVERSOS DE INHIBIDORES DE LA ENZIMA CONVERTIDORA DE ANGIOTENSINA"},
{ value: "Y525",	text: "EFECTOS ADVERSOS DE OTRAS DROGAS ANTIHIPERTENSIVAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "Y526",	text: "EFECTOS ADVERSOS DE DROGAS ANTIHIPERLIPIDEMICAS Y ANTIARTERIOSCLEROTICAS"},
{ value: "Y527",	text: "EFECTOS ADVERSOS DE VASODILATADORES PERIFERICOS"},
{ value: "Y528",	text: "EFECTOS ADVERSOS DE DROGAS ANTIVARICOSAS, INCLUSIVE AGENTES ESCLEROSANTES"},
{ value: "Y529",	text: "EFECTOS ADVERSOS DE OTROS AGENTES QUE AFECTAN PRIMARIAMENTE EL SISTEMA CARDIOVASCULAR, Y LOS NO ESPECIFICADOS"},
{ value: "Y530",	text: "EFECTOS ADVERSOS DE BLOQUEADORES DE LOS RECEPTORES H2 DE HISTAMINA"},
{ value: "Y531",	text: "EFECTOS ADVERSOS DE OTRAS DROGAS ANTIACIDAS E INHIBIDORAS DE LA SECRECION GASTRICA"},
{ value: "Y532",	text: "EFECTOS ADVERSOS DE LAXANTES ESTIMULANTES"},
{ value: "Y533",	text: "EFECTOS ADVERSOS DE LAXANTES SALINOS Y OSMOTICOS"},
{ value: "Y534",	text: "EFECTOS ADVERSOS DE OTROS LAXANTES"},
{ value: "Y535",	text: "EFECTOS ADVERSOS DE DIGESTIVOS"},
{ value: "Y536",	text: "EFECTOS ADVERSOS DE DROGAS ANTIDIARREICAS"},
{ value: "Y537",	text: "EFECTOS ADVERSOS DE EMETICOS"},
{ value: "Y538",	text: "EFECTOS ADVERSOS DE OTROS AGENTES QUE AFECTAN PRIMARIAMENTE EL SISTEMA GASTROINTESTINAL"},
{ value: "Y539",	text: "EFECTOS ADVERSOS DE AGENTES QUE AFECTAN PRIMARIAMENTE EL SISTEMA GASTROINTESTINAL, NO ESPECIFICADOS"},
{ value: "Y540",	text: "EFECTOS ADVERSOS DE MINERALOCORTICOIDES"},
{ value: "Y541",	text: "EFECTOS ADVERSOS DE LOS BLOQUEADORES DE MINERALOCORTICOIDES [ANTAGONISTAS DE LA ALDOSTERONA]"},
{ value: "Y542",	text: "EFECTOS ADVERSOS DE LOS INHIBIDORES DE LA ANHIDRASA CARBONICA"},
{ value: "Y543",	text: "EFECTOS ADVERSOS DE LOS DERIVADOS DE LA BENZOTIADIAZINA"},
{ value: "Y544",	text: "EFECTOS ADVERSOS DE DIURETICOS DE ASA ['HIGH-CEILING']"},
{ value: "Y545",	text: "EFECTOS ADVERSOS DE OTROS DIURETICOS"},
{ value: "Y546",	text: "EFECTOS ADVERSOS DE AGENTES ELECTROLITICOS, CALORICOS Y DEL EQUILIBRIO HIDRICO"},
{ value: "Y547",	text: "EFECTOS ADVERSOS DE AGENTES QUE AFECTAN LA CALCIFICACION"},
{ value: "Y548",	text: "EFECTOS ADVERSOS DE AGENTES QUE AFECTAN EL METABOLISMO DEL ACIDO URICO"},
{ value: "Y549",	text: "EFECTOS ADVERSOS DE SALES MINERALES NO CLASIFICADAS EN OTRA PARTE"},
{ value: "Y550",	text: "EFECTOS ADVERSOS DE DROGAS OXITOCICAS"},
{ value: "Y551",	text: "EFECTOS ADVERSOS DE RELAJANTES DE LOS MUSCULOS ESTRIADOS [AGENTES BLOQUEADORES NEUROMUSCULARES]"},
{ value: "Y552",	text: "EFECTOS ADVERSOS DE OTROS AGENTES QUE ACTUAN PRIMARIAMENTE SOBRE LOS MUSCULOS, Y LOS NO ESPECIFICADOS"},
{ value: "Y553",	text: "EFECTOS ADVERSOS DE ANTITUSIGENOS"},
{ value: "Y554",	text: "EFECTOS ADVERSOS DE EXPECTORANTES"},
{ value: "Y555",	text: "EFECTOS ADVERSOS DE DROGAS CONTRA EL RESFRIADO COMUN"},
{ value: "Y556",	text: "EFECTOS ADVERSOS DE ANTIASMATICOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y557",	text: "EFECTOS ADVERSOS DE OTROS AGENTES QUE ACTUAN PRIMARIAMENTE SOBRE EL SISTEMA RESPIRATORIO, Y LOS NO ESPECIFICADOS"},
{ value: "Y560",	text: "EFECTOS ADVERSOS DE DROGAS ANTIMICOTICAS, ANTIINFECCIOSAS Y ANTIINFLAMATORIAS DE USO LOCAL, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "Y561",	text: "EFECTOS ADVERSOS DE ANTIPRURIGINOSOS"},
{ value: "Y562",	text: "EFECTOS ADVERSOS DE ASTRINGENTES Y DETERGENTES LOCALES"},
{ value: "Y563",	text: "EFECTOS ADVERSOS DE EMOLIENTES, DEMULCENTES Y PROTECTORES"},
{ value: "Y564",	text: "EFECTOS ADVERSOS DE DROGAS Y PREPARADOS QUERATOLITICOS, QUERATOPLASTICOS Y OTROS PARA EL TRATAMIENTO DEL CABELLO"},
{ value: "Y565",	text: "EFECTOS ADVERSOS DE DROGAS Y PREPARADOS OFTALMOLOGICOS"},
{ value: "Y566",	text: "EFECTOS ADVERSOS DE DROGAS Y PREPARADOS OTORRINOLARINGOLOGICOS"},
{ value: "Y567",	text: "EFECTOS ADVERSOS DE DROGAS DENTALES, DE APLICACIÓN TOPICA"},
{ value: "Y568",	text: "EFECTOS ADVERSOS DE OTROS AGENTES TOPICOS"},
{ value: "Y569",	text: "EFECTOS ADVERSOS DE OTROS AGENTES TOPICOS NO ESPECIFICADOS"},
{ value: "Y570",	text: "EFECTOS ADVERSOS DE DEPRESORES DEL APETITO [ANOREXICOS]"},
{ value: "Y571",	text: "EFECTOS ADVERSOS DE DROGAS LIPOTROPICAS"},
{ value: "Y572",	text: "EFECTOS ADVERSOS DE ANTIDOTOS Y AGENTES QUELANTES, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y573",	text: "EFECTOS ADVERSOS DE DISUASIVOS DEL ALCOHOL"},
{ value: "Y574",	text: "EFECTOS ADVERSOS DE EXCIPIENTES FARMACEUTICOS"},
{ value: "Y575",	text: "EFECTOS ADVERSOS DE MEDIOS DE CONTRASTE PARA RAYOS X"},
{ value: "Y576",	text: "EFECTOS ADVERSOS DE OTROS AGENTES DIAGNOSTICOS"},
{ value: "Y577",	text: "EFECTOS ADVERSOS DE VITAMINAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "Y578",	text: "EFECTOS ADVERSOS DE OTRAS DROGAS Y MEDICAMENTOS"},
{ value: "Y579",	text: "EFECTOS ADVERSOS DE DROGAS Y MEDICAMENTOS NO ESPECIFICADOS"},
{ value: "Y580",	text: "EFECTOS ADVERSOS DE LA VACUNA BCG"},
{ value: "Y581",	text: "EFECTOS ADVERSOS DE LA VACUNA TIFOIDEA Y PARATIFOIDEA"},
{ value: "Y582",	text: "EFECTOS ADVERSOS DE LA VACUNA CONTRA EL COLERA"},
{ value: "Y583",	text: "EFECTOS ADVERSOS DE LA VACUNA CONTRA LA PESTE"},
{ value: "Y584",	text: "EFECTOS ADVERSOS DE LA VACUNA CONTRA EL TETANOS"},
{ value: "Y585",	text: "EFECTOS ADVERSOS DE LA VACUNA CONTRA LA DIFTERIA"},
{ value: "Y586",	text: "EFECTOS ADVERSOS DE LA VACUNA CONTRA LA TOS FERINA, INCLUSIVE COMBINACIONES CON UN COMPONENTE PERTUSIS"},
{ value: "Y588",	text: "EFECTOS ADVERSOS DE VACUNAS BACTERIANAS MIXTAS, EXCEPTO COMBINACIONES CON UN COMPONENTE PERTUSIS"},
{ value: "Y589",	text: "EFECTOS ADVERSOS DE OTRAS VACUNAS BACTERIANAS, Y LAS NO ESPECIFICADAS"},
{ value: "Y590",	text: "EFECTOS ADVERSOS DE VACUNAS VIRALES"},
{ value: "Y591",	text: "EFECTOS ADVERSOS DE VACUNAS CONTRA RICKETTSIAS"},
{ value: "Y592",	text: "EFECTOS ADVERSOS DE VACUNAS ANTIPROTOZOARIAS"},
{ value: "Y593",	text: "EFECTOS ADVERSOS DE LA INMUNOGLOBULINA"},
{ value: "Y598",	text: "EFECTOS ADVERSOS DE OTRAS VACUNAS Y SUSTANCIAS BIOLOGICAS ESPECIFICADAS"},
{ value: "Y599",	text: "EFECTOS ADVERSOS DE OTRAS VACUNAS O SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS"},
{ value: "Y600",	text: "INCIDENTE DURANTE OPERACIÓN QUIRURGICA"},
{ value: "Y601",	text: "INCIDENTE DURANTE INFUSION O TRANSFUSION"},
{ value: "Y602",	text: "INCIDENTE DURANTE DIALISIS RENAL U OTRA PERFUSION"},
{ value: "Y603",	text: "INCIDENTE DURANTE INYECCION O INMUNIZACION"},
{ value: "Y604",	text: "INCIDENTE DURANTE EXAMEN ENDOSCOPICO"},
{ value: "Y605",	text: "INCIDENTE DURANTE CATETERIZACION CARDIACA"},
{ value: "Y606",	text: "INCIDENTE DURANTE ASPIRACION, PUNCION Y OTRA CATETERIZACION"},
{ value: "Y607",	text: "INCIDENTE DURANTE ADMINISTRACION DE ENEMA"},
{ value: "Y608",	text: "INCIDENTE DURANTE OTRAS ATENCIONES MEDICAS Y QUIRURGICAS"},
{ value: "Y609",	text: "INCIDENTE DURANTE ATENCION MEDICA Y QUIRURGICA NO ESPECIFICADA"},
{ value: "Y610",	text: "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE OPERACIÓN QUIRURGICA"},
{ value: "Y611",	text: "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE INFUSION O TRANSFUSION"},
{ value: "Y612",	text: "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE DIALISIS RENAL U OTRA PERFUSION"},
{ value: "Y613",	text: "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE INYECCION O INMUNIZACION"},
{ value: "Y614",	text: "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE EXAMEN ENDOSCOPICO"},
{ value: "Y615",	text: "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE CATETERIZACION CARDIACA"},
{ value: "Y616",	text: "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE ASPIRACION, PUNCION Y OTRA CATETERIZACION"},
{ value: "Y617",	text: "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE REMOCION DE CATETER O TAPONAMIENTO"},
{ value: "Y618",	text: "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE OTRAS ATENCIONES MEDICAS Y QUIRURGICAS"},
{ value: "Y619",	text: "OBJETO EXTRAÑO DEJADO ACCIDENTALMENTE EN EL CUERPO DURANTE ATENCION MEDICA Y QUIRURGICA NO ESPECIFICADA"},
{ value: "Y620",	text: "FALLAS EN LA ESTERILIZACION DURANTE OPERACIÓN QUIRURGICA"},
{ value: "Y621",	text: "FALLAS EN LA ESTERILIZACION DURANTE INFUSION O TRANSFUSION"},
{ value: "Y622",	text: "FALLAS EN LA ESTERILIZACION DURANTE DIALISIS RENAL U OTRA PERFUSION"},
{ value: "Y623",	text: "FALLAS EN LA ESTERILIZACION DURANTE INYECCION O INMUNIZACION"},
{ value: "Y624",	text: "FALLAS EN LA ESTERILIZACION DURANTE EXAMEN ENDOSCOPICO"},
{ value: "Y625",	text: "FALLAS EN LA ESTERILIZACION DURANTE CATETERIZACION CARDIACA"},
{ value: "Y626",	text: "FALLAS EN LA ESTERILIZACION DURANTE ASPIRACION, PUNCION Y OTRA CATETERIZACION"},
{ value: "Y628",	text: "FALLAS EN LA ESTERILIZACION DURANTE OTRAS ATENCIONES MEDICAS Y QUIRURGICAS"},
{ value: "Y629",	text: "FALLAS EN LA ESTERILIZACION DURANTE ATENCION MEDICA Y QUIRURGICA NO ESPECIFICADA"},
{ value: "Y630",	text: "EXCESIVA CANTIDAD DE SANGRE U OTRO LIQUIDO ADMINISTRADO DURANTE UNA INFUSION O TRANSFUSION"},
{ value: "Y631",	text: "DILUCION INCORRECTA DE LIQUIDO DURANTE UNA INFUSION"},
{ value: "Y632",	text: "SOBREDOSIS DE RADIACION ADMINISTRADA DURANTE TERAPIA"},
{ value: "Y633",	text: "EXPOSICION INADVERTIDA DEL PACIENTE A RADIACION DURANTE LA ATENCION MEDICA"},
{ value: "Y634",	text: "FALLA EN LA DOSIFICACION EN ELECTROCHOQUE O EN CHOQUE INSULINICO"},
{ value: "Y635",	text: "FALLA EN EL CONTROL DE LA TEMPERATURA, EN TAPONAMIENTOS Y APLICACIONES LOCALES"},
{ value: "Y636",	text: "NO ADMINISTRACION DE DROGAS, MEDICAMENTOS O SUSTANCIAS BIOLOGICAS NECESARIAS"},
{ value: "Y638",	text: "FALLA EN LA DOSIFICACION DURANTE OTRAS ATENCIONES MEDICAS Y QUIRURGICAS"},
{ value: "Y639",	text: "FALLA EN LA DOSIFICACION DURANTE ATENCION MEDICA Y QUIRURGICA NO ESPECIFICADA"},
{ value: "Y640",	text: "MEDICAMENTO O SUSTANCIA BIOLOGICA CONTAMINADO EN INFUSION O TRANSFUSION"},
{ value: "Y641",	text: "MEDICAMENTO O SUSTANCIA BIOLOGICA CONTAMINADO, INYECTADO O USADO PARA INMUNIZACION"},
{ value: "Y648",	text: "MEDICAMENTO O SUSTANCIA BIOLOGICA CONTAMINADO, ADMINISTRADO POR OTROS MEDIOS"},
{ value: "Y649",	text: "MEDICAMENTO O SUSTANCIA BIOLOGICA CONTAMINADO, ADMINISTRADO POR MEDIOS NO ESPECIFICADOS"},
{ value: "Y650",	text: "SANGRE INCOMPATIBLE USADA EN TRANSFUSION"},
{ value: "Y651",	text: "LIQUIDO ERRONEO USADO EN INFUSION"},
{ value: "Y652",	text: "FALLA EN LA SUTURA O LIGADURA DURANTE OPERACIÓN QUIRURGICA"},
{ value: "Y653",	text: "TUBO ENDOTRAQUEAL COLOCADO ERRONEAMENTE DURANTE PROCEDIMIENTO ANESTESICO"},
{ value: "Y654",	text: "FALLA EN LA INTRODUCCION O REMOCION DE OTRO TUBO O INSTRUMENTO"},
{ value: "Y655",	text: "REALIZACION DE UNA OPERACIÓN INADECUADA"},
{ value: "Y658",	text: "OTROS INCIDENTES ESPECIFICADOS DURANTE LA ATENCION MEDICA Y QUIRURGICA"},
{ value: "Y66X",	text: "NO ADMINISTRACION DE LA ATENCION MEDICA Y QUIRURGICA"},
{ value: "Y69X",	text: "INCIDENTES NO ESPECIFICADOS DURANTE LA ATENCION MEDICA Y QUIRURGICA"},
{ value: "Y700",	text: "DISPOSITIVOS DE ANESTESIOLOGIA ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO"},
{ value: "Y701",	text: "DISPOSITIVOS DE ANESTESIOLOGIA ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION"},
{ value: "Y702",	text: "DISPOSITIVOS DE ANESTESIOLOGIA ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"},
{ value: "Y703",	text: "DISPOSITIVOS DE ANESTESIOLOGIA ASOCIADOS CON INCIDENTES ADVERSOS: INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"},
{ value: "Y708",	text: "DISPOSITIVOS DE ANESTESIOLOGIA ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y710",	text: "DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO"},
{ value: "Y711",	text: "DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION"},
{ value: "Y712",	text: "DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"},
{ value: "Y713",	text: "DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS: INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"},
{ value: "Y718",	text: "DISPOSITIVOS CARDIOVASCULARES ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y720",	text: "DISPOSITIVOS OTORRINOLARINGOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO"},
{ value: "Y721",	text: "DISPOSITIVOS OTORRINOLARINGOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION"},
{ value: "Y722",	text: "DISPOSITIVOS OTORRINOLARINGOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"},
{ value: "Y723",	text: "DISPOSITIVOS OTORRINOLARINGOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS: INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"},
{ value: "Y728",	text: "DISPOSITIVOS OTORRINOLARINGOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y730",	text: "DISPOSITIVOS DE GASTROENTEROLOGIA Y UROLOGIA ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO"},
{ value: "Y731",	text: "DISPOSITIVOS DE GASTROENTEROLOGIA Y UROLOGIA ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION"},
{ value: "Y732",	text: "DISPOSITIVOS DE GASTROENTEROLOGIA Y UROLOGIA ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"},
{ value: "Y733",	text: "DISPOSITIVOS DE GASTROENTEROLOGIA Y UROLOGIA ASOCIADOS CON INCIDENTES ADVERSOS: INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"},
{ value: "Y738",	text: "DISPOSITIVOS DE GASTROENTEROLOGIA Y UROLOGIA ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y740",	text: "DISPOSITIVOS PARA USO HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO"},
{ value: "Y741",	text: "DISPOSITIVOS PARA USO  HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION"},
{ value: "Y742",	text: "DISPOSITIVOS PARA USO HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"},
{ value: "Y743",	text: "DISPOSITIVOS PARA USO HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS: INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"},
{ value: "Y748",	text: "DISPOSITIVOS PARA USO HOSPITALARIO GENERAL Y PERSONAL ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y750",	text: "DISPOSITIVOS NEUROLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO"},
{ value: "Y751",	text: "DISPOSITIVOS NEUROLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION"},
{ value: "Y752",	text: "DISPOSITIVOS NEUROLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"},
{ value: "Y753",	text: "DISPOSITIVOS NEUROLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS: INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"},
{ value: "Y758",	text: "DISPOSITIVOS NEUROLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y760",	text: "DISPOSITIVOS GINECOLOGICOS Y OBSTETRICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO"},
{ value: "Y761",	text: "DISPOSITIVOS GINECOLOGICOS Y OBSTETRICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION"},
{ value: "Y762",	text: "DISPOSITIVOS GINECOLOGICOS Y OBSTETRICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"},
{ value: "Y763",	text: "DISPOSITIVOS GINECOLOGICOS Y OBSTETRICOS ASOCIADOS CON INCIDENTES ADVERSOS: INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"},
{ value: "Y768",	text: "DISPOSITIVOS GINECOLOGICOS Y OBSTETRICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y770",	text: "DISPOSITIVOS OFTALMICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO"},
{ value: "Y771",	text: "DISPOSITIVOS OFTALMICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION"},
{ value: "Y772",	text: "DISPOSITIVOS OFTALMICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"},
{ value: "Y773",	text: "DISPOSITIVOS OFTALMICOS ASOCIADOS CON INCIDENTES ADVERSOS: INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"},
{ value: "Y778",	text: "DISPOSITIVOS OFTALMICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y780",	text: "APARATOS RADIOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO"},
{ value: "Y781",	text: "APARATOS RADIOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION"},
{ value: "Y782",	text: "APARATOS RADIOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"},
{ value: "Y783",	text: "APARATOS RADIOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS: INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"},
{ value: "Y788",	text: "APARATOS RADIOLOGICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y790",	text: "DISPOSITIVOS ORTOPEDICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO"},
{ value: "Y791",	text: "DISPOSITIVOS ORTOPEDICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION"},
{ value: "Y792",	text: "DISPOSITIVOS ORTOPEDICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"},
{ value: "Y793",	text: "DISPOSITIVOS ORTOPEDICOS ASOCIADOS CON INCIDENTES ADVERSOS: INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"},
{ value: "Y798",	text: "DISPOSITIVOS ORTOPEDICOS ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y800",	text: "APARATOS DE MEDICINA FISICA ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO"},
{ value: "Y801",	text: "APARATOS DE MEDICINA FISICA ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION"},
{ value: "Y802",	text: "APARATOS DE MEDICINA FISICA ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"},
{ value: "Y803",	text: "APARATOS DE MEDICINA FISICA ASOCIADOS CON INCIDENTES ADVERSOS: INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"},
{ value: "Y808",	text: "APARATOS DE MEDICINA FISICA ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y810",	text: "DISPOSITIVOS DE CIRUGIA GENERAL Y PLASTICA ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO"},
{ value: "Y811",	text: "DISPOSITIVOS DE CIRUGIA GENERAL Y PLASTICA ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION"},
{ value: "Y812",	text: "DISPOSITIVOS DE CIRUGIA GENERAL Y PLASTICA ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"},
{ value: "Y813",	text: "DISPOSITIVOS DE CIRUGIA GENERAL Y PLASTICA ASOCIADOS CON INCIDENTES ADVERSOS: INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"},
{ value: "Y818",	text: "DISPOSITIVOS DE CIRUGIA GENERAL Y PLASTICA ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y820",	text: "OTROS DISPOSITIVOS MEDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DE DIAGNOSTICO Y MONITOREO"},
{ value: "Y821",	text: "OTROS DISPOSITIVOS MEDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS TERAPEUTICOS (NO QUIRURGICOS) Y DE REHABILITACION"},
{ value: "Y822",	text: "OTROS DISPOSITIVOS MEDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS PROTESICOS Y OTROS IMPLANTES, MATERIALES Y ACCESORIOS"},
{ value: "Y823",	text: "OTROS DISPOSITIVOS MEDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS: INSTRUMENTOS QUIRURGICOS, DISPOSITIVOS Y MATERIALES (INCLUSIVE SUTURAS)"},
{ value: "Y828",	text: "OTROS DISPOSITIVOS MEDICOS, Y LOS NO ESPECIFICADOS, ASOCIADOS CON INCIDENTES ADVERSOS: DISPOSITIVOS DIVERSOS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Y830",	text: "OPERACIÓN QUIRURGICA CON TRASPLANTE DE UN ORGANO COMPLETO"},
{ value: "Y831",	text: "OPERACIÓN QUIRURGICA CON IMPLANTE DE UN DISPOSITIVO ARTIFICIAL INTERNO"},
{ value: "Y832",	text: "OPERACIÓN QUIRURGICA CON ANASTOMOSIS, DERIVACION O INJERTO"},
{ value: "Y833",	text: "OPERACIÓN QUIRURGICA CON FORMACION DE ESTOMA EXTERNO"},
{ value: "Y834",	text: "OTRA CIRUGIA RECONSTRUCTIVA"},
{ value: "Y835",	text: "AMPUTACION DE MIEMBRO(S)"},
{ value: "Y836",	text: "REMOCION DE OTRO ORGANO (PARCIAL) (TOTAL)"},
{ value: "Y838",	text: "OTROS PROCEDIMIENTOS QUIRURGICOS"},
{ value: "Y839",	text: "PROCEDIMIENTO QUIRURGICO NO ESPECIFICADO"},
{ value: "Y840",	text: "CATETERIZACION CARDIACA"},
{ value: "Y841",	text: "DIALISIS RENAL"},
{ value: "Y842",	text: "PROCEDIMIENTO RADIOLOGICO Y RADIOTERAPIA"},
{ value: "Y843",	text: "TERAPIA POR CHOQUE"},
{ value: "Y844",	text: "ASPIRACION DE LIQUIDOS"},
{ value: "Y845",	text: "INSERCION DE SONDA GASTRICA O DUODENAL"},
{ value: "Y846",	text: "CATETERIZACION URINARIA"},
{ value: "Y847",	text: "MUESTRA DE SANGRE"},
{ value: "Y848",	text: "OTROS PROCEDIMIENTOS MEDICOS"},
{ value: "Y849",	text: "PROCEDIMIENTO MEDICO NO ESPECIFICADO"},
{ value: "Y850",	text: "SECUELAS DE ACCIDENTE DE VEHICULO DE MOTOR"},
{ value: "Y859",	text: "SECUELAS DE OTROS ACCIDENTES DE TRANSPORTE, Y LOS NO ESPECIFICADOS"},
{ value: "Y86X",	text: "SECUELAS DE OTROS ACCIDENTES"},
{ value: "Y870",	text: "SECUELAS DE LESIONES AUTOINFLIGIDAS"},
{ value: "Y871",	text: "SECUELAS DE AGRESIONES"},
{ value: "Y872",	text: "SECUELAS DE EVENTOS DE INTENCION NO DETERMINADA"},
{ value: "Y880",	text: "SECUELAS DE EFECTOS ADVERSOS CAUSADOS POR DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS EN SU USO TERAPEUTICO"},
{ value: "Y881",	text: "SECUELAS DE INCIDENTES OCURRIDOS AL PACIENTE DURANTE PROCEDIMIENTOS MEDICOS Y QUIRURGICOS"},
{ value: "Y882",	text: "SECUELAS DE INCIDENTES ADVERSOS ASOCIADOS CON DISPOSITIVOS MEDICOS EN USO DIAGNOSTICO Y TERAPEUTICO"},
{ value: "Y883",	text: "SECUELAS DE PROCEDIMIENTOS MEDICOS Y QUIRURGICOS COMO LA CAUSA DE REACCION ANORMAL DEL PACIENTE O DE COMPLICACION POSTERIOR, SIN MENCION DE INCIDENTE EN EL MOMENTO DE EFECTUAR EL PROCEDIMIENTO"},
{ value: "Y890",	text: "SECUELAS DE INTERVENCION LEGAL"},
{ value: "Y891",	text: "SECUELAS DE OPERACIONES DE GUERRA"},
{ value: "Y899",	text: "SECUELAS DE CAUSA EXTERNA NO ESPECIFICADA"},
{ value: "Y900",	text: "NIVEL DE ALCOHOL EN LA SANGRE MENOR DE 20 mg/100 ml"},
{ value: "Y901",	text: "NIVEL DE ALCOHOL EN LA SANGRE DE 20 A 39 mg/100 ml"},
{ value: "Y902",	text: "NIVEL DE ALCOHOL EN LA SANGRE DE 40 A 59 mg/100 ml"},
{ value: "Y903",	text: "NIVEL DE ALCOHOL EN LA SANGRE DE 60 A 79 mg/100 ml"},
{ value: "Y904",	text: "NIVEL DE ALCOHOL EN LA SANGRE DE 80 A 99 mg/100 ml"},
{ value: "Y905",	text: "NIVEL DE ALCOHOL EN LA SANGRE DE 100 A 119 mg/100 ml"},
{ value: "Y906",	text: "NIVEL DE ALCOHOL EN LA SANGRE DE 120 A 199 mg/100 ml"},
{ value: "Y907",	text: "NIVEL DE ALCOHOL EN LA SANGRE DE 200 A 239 mg/100 ml"},
{ value: "Y908",	text: "NIVEL DE ALCOHOL EN LA SANGRE DE 240 mg/100 ml O MAS"},
{ value: "Y909",	text: "PRESENCIA DE ALCOHOL EN LA SANGRE, NIVEL NO ESPECIFICADO"},
{ value: "Y910",	text: "INTOXICACION ALCOHOLICA LEVE"},
{ value: "Y911",	text: "INTOXICACION ALCOHOLICA MODERADA"},
{ value: "Y912",	text: "INTOXICACION ALCOHOLICA SEVERA"},
{ value: "Y913",	text: "INTOXICACION ALCOHOLICA MUY SEVERA"},
{ value: "Y919",	text: "ALCOHOLISMO, NIVEL DE INTOXICACION NO ESPECIFICADO"},
{ value: "Y95X",	text: "AFECCION NOSOCOMIAL"},
{ value: "Y96X",	text: "AFECCION RELACIONADA CON EL TRABAJO"},
{ value: "Y97X",	text: "AFECCION RELACIONADA CON LA CONTAMINACION AMBIENTAL"},
{ value: "Y98X",	text: "AFECCION RELACIONADA CON ESTILO DE VIDA"},
{ value: "Z000",	text: "EXAMEN MEDICO GENERAL"},
{ value: "Z001",	text: "CONTROL DE SALUD DE RUTINA DEL NIÑO"},
{ value: "Z002",	text: "EXAMEN DURANTE EL PERIODO DE CRECIMIENTO RAPIDO EN LA INFANCIA"},
{ value: "Z003",	text: "EXAMEN DEL ESTADO DE DESARROLLO DEL ADOLESCENTE"},
{ value: "Z004",	text: "EXAMEN PSIQUIATRICO GENERAL, NO CLASIFICADO EN OTRA PARTE"},
{ value: "Z005",	text: "EXAMEN DE DONANTE POTENCIAL DE ORGANO O TEJIDO"},
{ value: "Z006",	text: "EXAMEN PARA COMPARACION Y CONTROL NORMALES EN PROGRAMA DE INVESTIGACION CLINICA"},
{ value: "Z008",	text: "OTROS EXAMENES GENERALES"},
{ value: "Z010",	text: "EXAMEN DE OJOS Y DE LA VISION"},
{ value: "Z011",	text: "EXAMEN DE OIDOS Y DE LA AUDICION"},
{ value: "Z012",	text: "EXAMEN ODONTOLOGICO"},
{ value: "Z013",	text: "EXAMEN DE LA PRESION SANGUINEA"},
{ value: "Z014",	text: "EXAMEN GINECOLOGICO (GENERAL) (DE RUTINA)"},
{ value: "Z015",	text: "PRUEBAS DE SENSIBILIZACION Y DIAGNOSTICO CUTANEO"},
{ value: "Z016",	text: "EXAMEN RADIOLOGICO, NO CLASIFICADO EN OTRA PARTE"},
{ value: "Z017",	text: "EXAMEN DE LABORATORIO"},
{ value: "Z018",	text: "OTROS EXAMENES ESPECIALES ESPECIFICADOS"},
{ value: "Z019",	text: "EXAMEN ESPECIAL NO ESPECIFICADO"},
{ value: "Z020",	text: "EXAMEN PARA ADMISION A INSTITUCIONES EDUCATIVAS"},
{ value: "Z021",	text: "EXAMEN PREEMPLEO"},
{ value: "Z022",	text: "EXAMEN PARA ADMISION A INSTITUCIONES RESIDENCIALES"},
{ value: "Z023",	text: "EXAMEN PARA RECLUTAMIENTO EN LAS FUERZAS ARMADAS"},
{ value: "Z024",	text: "EXAMEN PARA OBTENCION DE LICENCIA DE CONDUCIR"},
{ value: "Z025",	text: "EXAMEN PARA PARTICIPACION EN COMPETENCIAS DEPORTIVAS"},
{ value: "Z026",	text: "EXAMEN PARA FINES DE SEGUROS"},
{ value: "Z027",	text: "EXTENSION DE CERTIFICADO MEDICO"},
{ value: "Z028",	text: "OTROS EXAMENES PARA FINES ADMINISTRATIVOS"},
{ value: "Z029",	text: "EXAMEN PARA FINES ADMINISTRATIVOS, NO ESPECIFICADO"},
{ value: "Z030",	text: "OBSERVACION POR SOSPECHA DE TUBERCULOSIS"},
{ value: "Z031",	text: "OBSERVACION POR SOSPECHA DE TUMOR MALIGNO"},
{ value: "Z032",	text: "OBSERVACION POR SOSPECHA DE TRASTORNO MENTAL Y DEL COMPORTAMIENTO"},
{ value: "Z033",	text: "OBSERVACION POR SOSPECHA DE TRASTORNO DEL SISTEMA NERVIOSO"},
{ value: "Z034",	text: "OBSERVACION POR SOSPECHA DE INFARTO DE MIOCARDIO"},
{ value: "Z035",	text: "OBSERVACION POR SOSPECHA DE OTRAS ENFERMEDADES CARDIOVASCULARES"},
{ value: "Z036",	text: "OBSERVACION POR SOSPECHA DE EFECTOS TOXICOS DE SUSTANCIAS INGERIDAS"},
{ value: "Z038",	text: "OBSERVACION POR SOSPECHA DE OTRAS ENFERMEDADES Y AFECCIONES"},
{ value: "Z039",	text: "OBSERVACION POR SOSPECHA DE ENFERMEDAD O AFECCION NO ESPECIFICADA"},
{ value: "Z040",	text: "PRUEBA DE ALCOHOL O DROGAS EN LA SANGRE"},
{ value: "Z041",	text: "EXAMEN Y OBSERVACION CONSECUTIVOS A ACCIDENTE DE TRANSPORTE"},
{ value: "Z042",	text: "EXAMEN Y OBSERVACION CONSECUTIVOS A ACCIDENTE DE TRABAJO"},
{ value: "Z043",	text: "EXAMEN Y OBSERVACION CONSECUTIVOS A OTRO ACCIDENTE"},
{ value: "Z044",	text: "EXAMEN Y OBSERVACION CONSECUTIVOS A DENUNCIA DE VIOLACION Y SEDUCCION"},
{ value: "Z045",	text: "EXAMEN Y OBSERVACION CONSECUTIVOS A OTRA LESION INFLIGIDA"},
{ value: "Z046",	text: "EXAMEN PSIQUIATRICO GENERAL, SOLICITADO POR UNA AUTORIDAD"},
{ value: "Z048",	text: "EXAMEN Y OBSERVACION POR OTRAS RAZONES ESPECIFICADAS"},
{ value: "Z049",	text: "EXAMEN Y OBSERVACION POR RAZONES NO ESPECIFICADAS"},
{ value: "Z080",	text: "EXAMEN DE SEGUIMIENTO CONSECUTIVO A CIRUGIA POR TUMOR MALIGNO"},
{ value: "Z081",	text: "EXAMEN DE SEGUIMIENTO CONSECUTIVO A RADIOTERAPIA POR TUMOR MALIGNO"},
{ value: "Z082",	text: "EXAMEN DE SEGUIMIENTO CONSECUTIVO A QUIMIOTERAPIA POR TUMOR MALIGNO"},
{ value: "Z087",	text: "EXAMEN DE SEGUIMIENTO CONSECUTIVO A TRATAMIENTO COMBINADO POR TUMOR MALIGNO"},
{ value: "Z088",	text: "EXAMEN DE SEGUIMIENTO CONSECUTIVO A OTRO TRATAMIENTO POR TUMOR MALIGNO"},
{ value: "Z089",	text: "EXAMEN DE SEGUIMIENTO CONSECUTIVO A OTRO TRATAMIENTO NO ESPECIFICADO POR TUMOR MALIGNO"},
{ value: "Z090",	text: "EXAMEN DE SEGUIMIENTO CONSECUTIVO A CIRUGIA POR OTRAS AFECCIONES"},
{ value: "Z091",	text: "EXAMEN DE SEGUIMIENTO CONSECUTIVO A RADIOTERAPIA POR OTRAS AFECCIONES"},
{ value: "Z092",	text: "EXAMEN DE SEGUIMIENTO CONSECUTIVO A QUIMIOTERAPIA POR OTRAS AFECCIONES"},
{ value: "Z093",	text: "EXAMEN DE SEGUIMIENTO CONSECUTIVO A PSICOTERAPIA"},
{ value: "Z094",	text: "EXAMEN DE SEGUIMIENTO CONSECUTIVO A TRATAMIENTO DE FRACTURA"},
{ value: "Z097",	text: "EXAMEN DE SEGUIMIENTO CONSECUTIVO A TRATAMIENTO COMBINADO POR OTRAS AFECCIONES"},
{ value: "Z098",	text: "EXAMEN DE SEGUIMIENTO CONSECUTIVO A OTRO TRATAMIENTO POR OTRAS AFECCIONES"},
{ value: "Z099",	text: "EXAMEN DE SEGUIMIENTO CONSECUTIVO A TRATAMIENTO NO ESPECIFICADO POR OTRAS AFECCIONES"},
{ value: "Z100",	text: "EXAMEN DE SALUD OCUPACIONAL"},
{ value: "Z101",	text: "CONTROL GENERAL DE SALUD DE RUTINA DE RESIDENTES DE INSTITUCIONES"},
{ value: "Z102",	text: "CONTROL GENERAL DE SALUD DE RUTINA A MIEMBROS DE LAS FUERZAS ARMADAS"},
{ value: "Z103",	text: "CONTROL GENERAL DE SALUD DE RUTINA A INTEGRANTES DE EQUIPOS DEPORTIVOS"},
{ value: "Z108",	text: "OTROS CONTROLES GENERALES DE SALUD DE RUTINA DE OTRAS SUBPOBLACIONES DEFINIDAS"},
{ value: "Z110",	text: "EXAMEN DE PESQUISA ESPECIAL PARA ENFERMEDADES INFECCIOSAS INTESTINALES"},
{ value: "Z111",	text: "EXAMEN DE PESQUISA ESPECIAL PARA TUBERCULOSIS RESPIRATORIA"},
{ value: "Z112",	text: "EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES BACTERIANAS"},
{ value: "Z113",	text: "EXAMEN DE PESQUISA ESPECIAL PARA INFECCIONES DE TRANSMISION PREDOMINANTEMENTE SEXUAL"},
{ value: "Z114",	text: "EXAMEN DE PESQUISA ESPECIAL PARA EL VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]"},
{ value: "Z115",	text: "EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES VIRALES"},
{ value: "Z116",	text: "EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES DEBIDAS A PROTOZOARIOS Y HELMINTOS"},
{ value: "Z118",	text: "EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS ESPECIFICADAS"},
{ value: "Z119",	text: "EXAMEN DE PESQUISA ESPECIAL PARA ENFERMEDADES INFECCIOSAS Y PARASITARIAS NO ESPECIFICADAS"},
{ value: "Z120",	text: "EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DE ESTOMAGO"},
{ value: "Z121",	text: "EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DE INTESTINO"},
{ value: "Z122",	text: "EXAMEN DE PESQUISA ESPECIAL PARA TUMORES DE ORGANOS RESPIRATORIOS"},
{ value: "Z123",	text: "EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DE LA MAMA"},
{ value: "Z124",	text: "EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DEL CUELLO UTERINO"},
{ value: "Z125",	text: "EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DE LA PROSTATA"},
{ value: "Z126",	text: "EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DE LA VEJIGA"},
{ value: "Z128",	text: "EXAMEN DE PESQUISA ESPECIAL PARA TUMORES DE OTROS SITIOS"},
{ value: "Z129",	text: "EXAMEN DE PESQUISA ESPECIAL PARA TUMOR DE SITIO NO ESPECIFICADO"},
{ value: "Z130",	text: "EXAMEN DE PESQUISA ESPECIAL PARA ENFERMEDADES DE LA SANGRE Y ORGANOS HEMATOPOYETICOS Y CIERTOS TRASTORNOS DEL MECANISMO DE LA INMUNIDAD"},
{ value: "Z131",	text: "EXAMEN DE PESQUISA ESPECIAL PARA DIABETES MELLITUS"},
{ value: "Z132",	text: "EXAMEN DE PESQUISA ESPECIAL PARA TRASTORNOS DE LA NUTRICION"},
{ value: "Z133",	text: "EXAMEN DE PESQUISA ESPECIAL PARA TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"},
{ value: "Z134",	text: "EXAMEN DE PESQUISA ESPECIAL PARA CIERTOS TRASTORNOS DEL DESARROLLO EN EL NIÑO"},
{ value: "Z135",	text: "EXAMEN DE PESQUISA ESPECIAL PARA TRASTORNOS DEL OJO Y DEL OIDO"},
{ value: "Z136",	text: "EXAMEN DE PESQUISA ESPECIAL PARA TRASTORNOS CARDIOVASCULARES"},
{ value: "Z137",	text: "EXAMEN DE PESQUISA ESPECIAL PARA MALFORMACIONES CONGENITAS, DEFORMIDADES Y ANOMALIAS CROMOSOMICAS"},
{ value: "Z138",	text: "EXAMEN DE PESQUISA ESPECIAL PARA OTRAS ENFERMEDADES Y TRASTORNOS ESPECIFICADOS"},
{ value: "Z139",	text: "EXAMEN DE PESQUISA ESPECIAL, NO ESPECIFICADO"},
{ value: "Z200",	text: "CONTACTO CON Y EXPOSICION A ENFERMEDADES INFECCIOSAS INTESTINALES"},
{ value: "Z201",	text: "CONTACTO CON Y EXPOSICION A TUBERCULOSIS"},
{ value: "Z202",	text: "CONTACTO CON Y EXPOSICION A ENFERMEDADES INFECCIOSAS CON UN MODO DE TRANSMISION PREDOMINANTEMENTE SEXUAL"},
{ value: "Z203",	text: "CONTACTO CON Y EXPOSICION A RABIA"},
{ value: "Z204",	text: "CONTACTO CON Y EXPOSICION A RUBEOLA"},
{ value: "Z205",	text: "CONTACTO CON Y EXPOSICION A HEPATITIS VIRAL"},
{ value: "Z206",	text: "CONTACTO CON Y EXPOSICION AL VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]"},
{ value: "Z207",	text: "CONTACTO CON Y EXPOSICION A PEDICULOSIS, ACARIASIS Y OTRAS INFESTACIONES"},
{ value: "Z208",	text: "CONTACTO CON Y EXPOSICION A OTRAS ENFERMEDADES TRANSMISIBLES"},
{ value: "Z209",	text: "CONTACTO CON Y EXPOSICION A ENFERMEDADES TRANSMISIBLES NO ESPECIFICADAS"},
{ value: "Z21X",	text: "ESTADO DE INFECCION ASINTOMATICA POR EL VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]"},
{ value: "Z220",	text: "PORTADOR DE FIEBRE TIFOIDEA"},
{ value: "Z221",	text: "PORTADOR DE OTRAS ENFERMEDADES INFECCIOSAS INTESTINALES"},
{ value: "Z222",	text: "PORTADOR DE DIFTERIA"},
{ value: "Z223",	text: "PORTADOR DE OTRAS ENFERMEDADES BACTERIANAS ESPECIFICADAS"},
{ value: "Z224",	text: "PORTADOR DE ENFERMEDADES INFECCIOSAS CON UN MODO DE TRANSMISION PREDOMINANTEMENTE SEXUAL"},
{ value: "Z225",	text: "PORTADOR DE HEPATITIS VIRAL"},
{ value: "Z226",	text: "PORTADOR DE ENFERMEDAD INFECCIOSA DEBIDA AL VIRUS HUMANO T-LINFOTROPICO TIPO 1 [VHTL-1]"},
{ value: "Z228",	text: "PORTADOR DE OTRAS ENFERMEDADES INFECCIOSAS"},
{ value: "Z229",	text: "PORTADOR DE ENFERMEDAD INFECCIOSA NO ESPECIFICADA"},
{ value: "Z230",	text: "NECESIDAD DE INMUNIZACION SOLO CONTRA EL COLERA"},
{ value: "Z231",	text: "NECESIDAD DE INMUNIZACION SOLO CONTRA LA TIFOIDEA - PARATIFOIDEA [TAB]"},
{ value: "Z232",	text: "NECESIDAD DE INMUNIZACION CONTRA LA TUBERCULOSIS [BCG]"},
{ value: "Z233",	text: "NECESIDAD DE INMUNIZACION CONTRA LA PESTE"},
{ value: "Z234",	text: "NECESIDAD DE INMUNIZACION CONTRA LA TULAREMIA"},
{ value: "Z235",	text: "NECESIDAD DE INMUNIZACION SOLO CONTRA EL TETANOS"},
{ value: "Z236",	text: "NECESIDAD DE INMUNIZACION SOLO CONTRA LA DIFTERIA"},
{ value: "Z237",	text: "NECESIDAD DE INMUNIZACION SOLO CONTRA LA TOS FERINA"},
{ value: "Z238",	text: "NECESIDAD DE INMUNIZACION SOLO CONTRA OTRA ENFERMEDAD BACTERIANA"},
{ value: "Z240",	text: "NECESIDAD DE INMUNIZACION CONTRA LA POLIOMIELITIS"},
{ value: "Z241",	text: "NECESIDAD DE INMUNIZACION CONTRA LA ENCEFALITIS VIRAL TRANSMITIDA POR ARTROPODOS"},
{ value: "Z242",	text: "NECESIDAD DE INMUNIZACION CONTRA LA RABIA"},
{ value: "Z243",	text: "NECESIDAD DE INMUNIZACION CONTRA LA FIEBRE AMARILLA"},
{ value: "Z244",	text: "NECESIDAD DE INMUNIZACION SOLO CONTRA EL SARAMPION"},
{ value: "Z245",	text: "NECESIDAD DE INMUNIZACION SOLO CONTRA LA RUBEOLA"},
{ value: "Z246",	text: "NECESIDAD DE INMUNIZACION CONTRA LA HEPATITIS VIRAL"},
{ value: "Z250",	text: "NECESIDAD DE INMUNIZACION SOLO CONTRA LA PAROTIDITIS"},
{ value: "Z251",	text: "NECESIDAD DE INMUNIZACION CONTRA LA INFLUENZA [GRIPE]"},
{ value: "Z258",	text: "NECESIDAD DE INMUNIZACION CONTRA OTRAS ENFERMEDADES VIRALES UNICAS ESPECIFICADAS"},
{ value: "Z260",	text: "NECESIDAD DE INMUNIZACION CONTRA LA LEISHMANIASIS"},
{ value: "Z268",	text: "NECESIDAD DE INMUNIZACION CONTRA OTRAS ENFERMEDADES INFECCIOSAS UNICAS ESPECIFICADAS"},
{ value: "Z269",	text: "NECESIDAD DE INMUNIZACION CONTRA ENFERMEDAD INFECCIOSA NO ESPECIFICADAS"},
{ value: "Z270",	text: "NECESIDAD DE INMUNIZACION CONTRA EL COLERA Y LA TIFOIDEA - PARATIFOIDEA [COLERA + TAB]"},
{ value: "Z271",	text: "NECESIDAD DE INMUNIZACION CONTRA DIFTERIA - PERTUSSIS - TETANOS COMBINADOS [DPT]"},
{ value: "Z272",	text: "NECESIDAD DE INMUNIZACION CONTRA DIFTERIA - PERTUSSIS - TETANOS Y TIFOIDEA - PARATIFOIDEA [DPT + TAB]"},
{ value: "Z273",	text: "NECESIDAD DE INMUNIZACION CONTRA DIFTERIA - PERTUSSIS - TETANOS Y POLIOMIELITIS [DPT + POLIO]]"},
{ value: "Z274",	text: "NECESIDAD DE INMUNIZACION CONTRA SARAMPION - PAROTIDITIS - RUBEOLA [SPR] [MMR]"},
{ value: "Z278",	text: "NECESIDAD DE INMUNIZACION CONTRA OTRAS COMBINACIONES DE ENFERMEDADES INFECCIOSAS"},
{ value: "Z279",	text: "NECESIDAD DE INMUNIZACION CONTRA COMBINACIONES NO ESPECIFICADAS DE ENFERMEDADES INFECCIOSAS"},
{ value: "Z280",	text: "INMUNIZACION NO REALIZADA POR CONTRAINDICACION"},
{ value: "Z281",	text: "INMUNIZACION NO REALIZADA POR DECISION DEL PACIENTE, POR MOTIVOS DE CREENCIA O PRESION DEL GRUPO"},
{ value: "Z282",	text: "INMUNIZACION NO REALIZADA POR DECISION DEL PACIENTE, POR OTRAS RAZONES Y LAS NO ESPECIFICADAS"},
{ value: "Z288",	text: "INMUNIZACION NO REALIZADA POR OTRAS RAZONES"},
{ value: "Z289",	text: "INMUNIZACION NO REALIZADA POR RAZON NO ESPECIFICADA"},
{ value: "Z290",	text: "AISLAMIENTO"},
{ value: "Z291",	text: "INMUNOTERAPIA PROFILACTICA"},
{ value: "Z292",	text: "OTRA QUIMIOTERAPIA PROFILACTICA"},
{ value: "Z298",	text: "OTRAS MEDIDAS PROFILACTICAS ESPECIFICADAS"},
{ value: "Z299",	text: "MEDIDA PROFILACTICA NO ESPECIFICADA"},
{ value: "Z300",	text: "CONSEJO Y ASESORAMIENTO GENERAL SOBRE LA ANTICONCEPCION"},
{ value: "Z301",	text: "INSERCION DE DISPOSITIVO ANTICONCEPTIVO (INTRAUTERINO)"},
{ value: "Z302",	text: "ESTERILIZACION"},
{ value: "Z303",	text: "EXTRACCION MENSTRUAL"},
{ value: "Z304",	text: "SUPERVISION DEL USO DE DROGAS ANTICONCEPTIVAS"},
{ value: "Z305",	text: "SUPERVISION DEL USO DE DISPOSITIVO ANTICONCEPTIVO (INTRAUTERINO)"},
{ value: "Z308",	text: "OTRAS ATENCIONES ESPECIFICADAS PARA LA ANTICONCEPCION"},
{ value: "Z309",	text: "ASISTENCIA PARA LA ANTICONCEPCION, NO ESPECIFICADA"},
{ value: "Z310",	text: "TUBOPLASTIA O VASOPLASTIA POSTERIOR A ESTERILIZACION"},
{ value: "Z311",	text: "INSEMINACION ARTIFICIAL"},
{ value: "Z312",	text: "FECUNDACION IN VITRO"},
{ value: "Z313",	text: "OTROS METODOS DE ATENCION PARA LA FECUNDACION"},
{ value: "Z314",	text: "INVESTIGACION Y PRUEBA PARA LA PROCREACION"},
{ value: "Z315",	text: "ASESORAMIENTO GENETICO"},
{ value: "Z316",	text: "CONSEJO Y ASESORAMIENTO GENERAL SOBRE LA PROCREACION"},
{ value: "Z318",	text: "OTRA ATENCION ESPECIFICADA PARA LA PROCREACION"},
{ value: "Z319",	text: "ATENCION NO ESPECIFICADA RELACIONADA CON LA PROCREACION"},
{ value: "Z320",	text: "EMBARAZO (AUN) NO CONFIRMADO"},
{ value: "Z321",	text: "EMBARAZO CONFIRMADO"},
{ value: "Z33X",	text: "ESTADO DE EMBARAZO, INCIDENTAL"},
{ value: "Z340",	text: "SUPERVISION DE PRIMER EMBARAZO NORMAL"},
{ value: "Z348",	text: "SUPERVISION DE OTROS EMBARAZOS NORMALES"},
{ value: "Z349",	text: "SUPERVISION DE EMBARAZO NORMAL NO ESPECIFICADO"},
{ value: "Z350",	text: "SUPERVISION DE EMBARAZO CON HISTORIA DE ESTERILIDAD"},
{ value: "Z351",	text: "SUPERVISION DE EMBARAZO CON HISTORIA DE ABORTO"},
{ value: "Z352",	text: "SUPERVISION DE EMBARAZO CON OTRO RIESGO EN LA HISTORIA OBSTETRICA O REPRODUCTIVA"},
{ value: "Z353",	text: "SUPERVISION DE EMBARAZO CON HISTORIA DE INSUFICIENTE ATENCION PRENATAL"},
{ value: "Z354",	text: "SUPERVISION DE EMBARAZO CON GRAN MULTIPARIDAD"},
{ value: "Z355",	text: "SUPERVISION DE PRIMIGESTA AÑOSA"},
{ value: "Z356",	text: "SUPERVISION DE PRIMIGESTA MUY JOVEN"},
{ value: "Z357",	text: "SUPERVISION DE EMBARAZO DE ALTO RIESGO DEBIDO A PROBLEMAS SOCIALES"},
{ value: "Z358",	text: "SUPERVISION DE OTROS EMBARAZOS DE ALTO RIESGO"},
{ value: "Z359",	text: "SUPERVISION DE EMBARAZO DE ALTO RIESGO, SIN OTRA ESPECIFICACION"},
{ value: "Z360",	text: "PESQUISA PRENATAL PARA ANOMALIAS CROMOSOMICAS"},
{ value: "Z361",	text: "PESQUISA PRENATAL PARA MEDIR NIVELES ELEVADOS DE ALFAFETOPROTEINAS"},
{ value: "Z362",	text: "OTRAS PESQUISAS PRENATALES BASADAS EN AMNIOCENTESIS"},
{ value: "Z363",	text: "PESQUISA PRENATAL DE MALFORMACIONES USANDO ALTRASONIDO Y OTROS METODOS FISICOS"},
{ value: "Z364",	text: "PESQUISA PRENATAL DEL RETARDO DEL CRECIMIENTO FETAL USANDO ULTRASONIDO Y OTROS METODOS FISICOS"},
{ value: "Z365",	text: "PESQUISA PRENATAL PARA ISOINMUNIZACION"},
{ value: "Z368",	text: "OTRAS PESQUISAS PRENATALES ESPECIFICAS"},
{ value: "Z369",	text: "PESQUISA PRENATAL, SIN OTRA ESPECIFICACION"},
{ value: "Z370",	text: "NACIDO VIVO, UNICO"},
{ value: "Z371",	text: "NACIDO MUERTO, UNICO"},
{ value: "Z372",	text: "GEMELOS, AMBOS NACIDOS VIVOS"},
{ value: "Z373",	text: "GEMELOS, UN NACIDO VIVO Y UN NACIDO MUERTO"},
{ value: "Z374",	text: "GEMELOS, AMBOS NACIDOS MUERTOS"},
{ value: "Z375",	text: "OTROS NACIMIENTOS MULTIPLES, TODOS NACIDOS VIVOS"},
{ value: "Z376",	text: "OTROS NACIMIENTOS MULTIPLES, ALGUNOS NACIDOS VIVOS"},
{ value: "Z377",	text: "OTROS NACIMIENTOS MULTIPLES, TODOS NACIDOS MUERTOS"},
{ value: "Z379",	text: "PRODUCTO DEL PARTO NO ESPECIFICADO"},
{ value: "Z380",	text: "PRODUCTO UNICO, NACIDO EN HOSPITAL"},
{ value: "Z381",	text: "PRODUCTO UNICO, NACIDO FUERA DE HOSPITAL"},
{ value: "Z382",	text: "PRODUCTO UNICO, LUGAR DE NACIMIENTO NO ESPECIFICADO"},
{ value: "Z383",	text: "GEMELOS, NACIDOS EN HOSPITAL"},
{ value: "Z384",	text: "GEMELOS, NACIDOS FUERA DE HOSPITAL"},
{ value: "Z385",	text: "GEMELOS, LUGAR DE NACIMIENTO NO ESPECIFICADO"},
{ value: "Z386",	text: "OTROS NACIMIENTOS MULTIPLES, EN HOSPITAL"},
{ value: "Z387",	text: "OTROS NACIMIENTOS MULTIPLES, FUERA DEL HOSPITAL"},
{ value: "Z388",	text: "OTROS NACIMIENTOS MULTIPLES, LUGAR DE NACIMIENTO NO ESPECIFICADO"},
{ value: "Z390",	text: "ATENCION Y EXAMEN INMEDIATAMENTE DESPUES DEL PARTO"},
{ value: "Z391",	text: "ATENCION Y EXAMEN DE MADRE EN PERIODO DE LACTANCIA"},
{ value: "Z392",	text: "SEGUIMIENTO POSTPARTO, DE RUTINA"},
{ value: "Z400",	text: "CIRUGIA PROFILACTICA POR FACTORES DE RIESGO RELACIONADOS CON TUMORES MALIGNOS"},
{ value: "Z408",	text: "OTRA CIRUGIA PROFILACTICA"},
{ value: "Z409",	text: "CIRUGIA PROFILACTICA NO ESPECIFICADA"},
{ value: "Z410",	text: "TRASPLANTE DE PELO"},
{ value: "Z411",	text: "OTRAS CIRUGIAS PLASTICAS POR RAZONES ESTETICAS"},
{ value: "Z412",	text: "CIRCUNCISION RITUAL O DE RUTINA"},
{ value: "Z413",	text: "PERFORACION DE LA OREJA"},
{ value: "Z418",	text: "OTROS PROCEDIMIENTOS PARA OTROS PROPOSITOS QUE NO SEAN LOS DE MEJORAR EL ESTADO DE SALUD"},
{ value: "Z419",	text: "PROCEDIMIENTOS NO ESPECIFICADO PARA OTROS PROPOSITOS QUE NO SEAN LOS DE MEJORAR EL ESTADO DE SALUD"},
{ value: "Z420",	text: "CUIDADOS POSTERIORES A LA CIRUGIA PLASTICA DE LA CABEZA Y DEL CUELLO"},
{ value: "Z421",	text: "CUIDADOS POSTERIORES A LA CIRUGIA PLASTICA DE LA MAMA"},
{ value: "Z422",	text: "CUIDADOS POSTERIORES A LA CIRUGIA PLASTICA DE OTRAS PARTES ESPECIFICADAS DEL TRONCO"},
{ value: "Z423",	text: "CUIDADOS POSTERIORES A LA CIRUGIA PLASTICA DE LAS EXTREMIDADES SUPERIORES"},
{ value: "Z424",	text: "CUIDADOS POSTERIORES A LA CIRUGIA PLASTICA DE LAS EXTREMIDADES INFERIORES"},
{ value: "Z428",	text: "CUIDADOS POSTERIORES A LA CIRUGIA PLASTICA DE OTRAS PARTES ESPECIFICADAS DEL CUERPO"},
{ value: "Z429",	text: "CUIDADOS POSTERIORES A LA CIRUGIA PLASTICA NO ESPECIFICADA"},
{ value: "Z430",	text: "ATENCION DE TRAQUEOSTOMIA"},
{ value: "Z431",	text: "ATENCION DE GASTROSTOMIA"},
{ value: "Z432",	text: "ATENCION DE ILEOSTOMIA"},
{ value: "Z433",	text: "ATENCION DE COLOSTOMIA"},
{ value: "Z434",	text: "ATENCION DE OTROS ORIFICIOS ARTIFICIALES DE LAS VIAS DIGESTIVAS"},
{ value: "Z435",	text: "ATENCION DE CISTOSTOMIA"},
{ value: "Z436",	text: "ATENCION DE OTROS ORIFICIOS ARTIFICIALES DE LAS VIAS URINARIAS"},
{ value: "Z437",	text: "ATENCION DE VAGINA ARTIFICIAL"},
{ value: "Z438",	text: "ATENCION DE OTROS ORIFICIOS ARTIFICIALES"},
{ value: "Z439",	text: "ATENCION DE ORIFICIO ARTIFICIAL NO ESPECIFICADO"},
{ value: "Z440",	text: "PRUEBA Y AJUSTE DE BRAZO ARTIFICIAL (COMPLETO) (PARCIAL)"},
{ value: "Z441",	text: "PRUEBA Y AJUSTE DE PIERNA ARTIFICIAL (COMPLETA) (PARCIAL)"},
{ value: "Z442",	text: "PRUEBA Y AJUSTE DE OJO ARTIFICIAL"},
{ value: "Z443",	text: "PRUEBA Y AJUSTE DE PROTESIS MAMARIA EXTERNA"},
{ value: "Z448",	text: "PRUEBA Y AJUSTE DE OTROS DISPOSITIVOS PROTESICOS EXTERNOS"},
{ value: "Z449",	text: "PRUEBA Y AJUSTE DE DISPOSITIVO PROTESICO EXTERNO NO ESPECIFICADO"},
{ value: "Z450",	text: "ASISTENCIA Y AJUSTE DE MARCAPASO CARDIACO"},
{ value: "Z451",	text: "ASISTENCIA Y AJUSTE DE BOMBA DE INFUSION"},
{ value: "Z452",	text: "ASISTENCIA Y AJUSTE DE DISPOSITIVOS DE ACCESO VASCULAR"},
{ value: "Z453",	text: "ASISTENCIA Y AJUSTE DE DISPOSITIVO AUDITIVO IMPLANTADO"},
{ value: "Z458",	text: "ASISTENCIA Y AJUSTE DE OTROS DISPOSITIVOS IMPLANTADOS"},
{ value: "Z459",	text: "ASISTENCIA Y AJUSTE DE DISPOSITIVO IMPLANTADO NO ESPECIFICADO"},
{ value: "Z460",	text: "PRUEBA Y AJUSTE DE ANTEOJOS Y LENTES DE CONTACTO"},
{ value: "Z461",	text: "PRUEBA Y AJUSTE DE AUDIFONOS"},
{ value: "Z462",	text: "PRUEBA Y AJUSTE DE OTROS DISPOSITIVOS RELACIONADOS CON EL SISTEMA NERVIOSO Y LOS SENTIDOS ESPECIALES"},
{ value: "Z463",	text: "PRUEBA Y AJUSTE DE PROTESIS DENTAL"},
{ value: "Z464",	text: "PRUEBA Y AJUSTE DE DISPOSITIVO ORTODONCICO"},
{ value: "Z465",	text: "PRUEBA Y AJUSTE DE ILEOSTOMIA U OTRO DISPOSITIVO INTESTINAL"},
{ value: "Z466",	text: "PRUEBA Y AJUSTE DE DISPOSITIVO URINARIO"},
{ value: "Z467",	text: "PRUEBA Y AJUSTE DE DISPOSITIVO ORTOPEDICO"},
{ value: "Z468",	text: "PRUEBA Y AJUSTE DE OTROS DISPOSITIVOS ESPECIFICADOS"},
{ value: "Z469",	text: "PRUEBA Y AJUSTE DE DISPOSITIVO NO ESPECIFICADO"},
{ value: "Z470",	text: "CUIDADOS POSTERIORES A LA EXTRACCION DE PLACA U OTRO DISPOSITIVO DE FIJACION INTERNA EN FRACTURA"},
{ value: "Z478",	text: "OTROS CUIDADOS ESPECIFICADOS POSTERIORES A LA ORTOPEDIA"},
{ value: "Z479",	text: "CUIDADO POSTERIOR A LA ORTOPEDIA, NO ESPECIFICADO"},
{ value: "Z480",	text: "ATENCION DE LOS APOSITOS Y SUTURAS"},
{ value: "Z488",	text: "OTROS CUIDADOS ESPECIFICADOS POSTERIORES A LA CIRUGIA"},
{ value: "Z489",	text: "CUIDADO POSTERIOR A LA CIRUGIA, NO ESPECIFICADO"},
{ value: "Z490",	text: "CUIDADOS PREOPERATORIOS PARA DIALISIS"},
{ value: "Z491",	text: "DIALISIS EXTRACORPOREA"},
{ value: "Z492",	text: "OTRAS DIALISIS"},
{ value: "Z500",	text: "REHABILITACION CARDIACA"},
{ value: "Z501",	text: "OTRAS TERAPIAS FISICAS"},
{ value: "Z502",	text: "REHABILITACION DEL ALCOHOLICO"},
{ value: "Z503",	text: "REHABILITACION DEL DROGADICTO"},
{ value: "Z504",	text: "PSICOTERAPIA, NO CLASIFICADA EN OTRA PARTE"},
{ value: "Z505",	text: "TERAPIA DEL LENGUAJE"},
{ value: "Z506",	text: "ADIESTRAMIENTO ORTOPTICO"},
{ value: "Z507",	text: "TERAPIA OCUPACIONAL Y REHABILITACION VOCACIONAL, NO CLASIFICADA EN OTRA PARTE"},
{ value: "Z508",	text: "ATENCION POR OTROS PROCEDIMIENTOS DE REHABILITACION"},
{ value: "Z509",	text: "ATENCION POR PROCEDIMIENTO DE REHABILITACION, NO ESPECIFICADA"},
{ value: "Z510",	text: "SESION DE RADIOTERAPIA"},
{ value: "Z511",	text: "SESION DE QUIMIOTERAPIA POR TUMOR"},
{ value: "Z512",	text: "OTRA QUIMIOTERAPIA"},
{ value: "Z513",	text: "TRANSFUSION DE SANGRE, SIN DIAGNOSTICO INFORMADO"},
{ value: "Z514",	text: "ATENCION PREPARATORIA PARA TRATAMIENTO SUBSECUENTE, NO CLASIFICADO EN OTRA PARTE"},
{ value: "Z515",	text: "ATENCION PALIATIVA"},
{ value: "Z516",	text: "DESENSIBILIZACION A ALERGENOS"},
{ value: "Z518",	text: "OTRAS ATENCIONES MEDICAS ESPECIFICADAS"},
{ value: "Z519",	text: "ATENCION MEDICA, NO ESPECIFICADA"},
{ value: "Z520",	text: "DONANTE DE SANGRE"},
{ value: "Z521",	text: "DONANTE DE PIEL"},
{ value: "Z522",	text: "DONANTE DE HUESO"},
{ value: "Z523",	text: "DONANTE DE MEDULA OSEA"},
{ value: "Z524",	text: "DONANTE DE RIÑON"},
{ value: "Z525",	text: "DONANTE DE CORNEA"},
{ value: "Z526",	text: "DONANTE DE HIGADO"},
{ value: "Z527",	text: "DONANTE DE CORAZON"},
{ value: "Z528",	text: "DONANTE DE OTROS ORGANOS O TEJIDOS"},
{ value: "Z529",	text: "DONANTE DE ORGANO O TEJIDO NO ESPECIFICADO"},
{ value: "Z530",	text: "PROCEDIMIENTO NO REALIZADO POR CONTRAINDICACION"},
{ value: "Z531",	text: "PROCEDIMIENTO NO REALIZADO POR DECISION DEL PACIENTE, POR RAZONES DE CREENCIA O PRESION DEL GRUPO"},
{ value: "Z532",	text: "PROCEDIMIENTO NO REALIZADO POR DECISION DEL PACIENTE, POR OTRAS RAZONES Y LAS NO ESPECIFICADAS"},
{ value: "Z538",	text: "PROCEDIMIENTO NO REALIZADO POR OTRAS RAZONES"},
{ value: "Z539",	text: "PROCEDIMIENTO NO REALIZADO POR RAZON NO ESPECIFICADA"},
{ value: "Z540",	text: "CONVALECENCIA CONSECUTIVA A CIRUGIA"},
{ value: "Z541",	text: "CONVALECENCIA CONSECUTIVA A RADIOTERAPIA"},
{ value: "Z542",	text: "CONVALECENCIA CONSECUTIVA A QUIMIOTERAPIA"},
{ value: "Z543",	text: "CONVALECENCIA CONSECUTIVA A PSICOTERAPIA"},
{ value: "Z544",	text: "CONVALECENCIA CONSECUTIVA A TRATAMIENTO DE FRACTURA"},
{ value: "Z547",	text: "CONVALECENCIA CONSECUTIVA A TRATAMIENTO COMBINADO"},
{ value: "Z548",	text: "CONVALECENCIA CONSECUTIVA A OTROS TRATAMIENTOS"},
{ value: "Z549",	text: "CONVALECENCIA CONSECUTIVA A TRATAMIENTO NO ESPECIFICADO"},
{ value: "Z550",	text: "PROBLEMAS RELACIONADOS CON EL ANALFABETISMO O BAJO NIVEL DE INSTRUCCIÓN"},
{ value: "Z551",	text: "PROBLEMAS RELACIONADOS CON LA EDUCACION NO DISPONIBLE O INACCESIBLE"},
{ value: "Z552",	text: "PROBLEMAS RELACIONADOS CON LA FALLA EN LOS EXAMENES"},
{ value: "Z553",	text: "PROBLEMAS RELACIONADOS CON EL BAJO RENDIMIENTO ESCOLAR"},
{ value: "Z554",	text: "PROBLEMAS RELACIONADOS CON LA INADAPTACION EDUCACIONAL Y DESAVENENCIAS CON MAESTROS Y COMPAÑEROS"},
{ value: "Z558",	text: "OTROS PROBLEMAS RELACIONADOS CON LA EDUCACION Y LA ALFABETIZACION"},
{ value: "Z559",	text: "PROBLEMA NO ESPECIFICADO RELACIONADO CON LA EDUCACION Y LA ALFABETIZACION"},
{ value: "Z560",	text: "PROBLEMAS RELACIONADOS CON EL DESEMPLEO, NO ESPECIFICADOS"},
{ value: "Z561",	text: "PROBLEMAS RELACIONADOS CON EL CAMBIO DE EMPLEO"},
{ value: "Z562",	text: "PROBLEMAS RELACIONADOS CON AMENAZA DE PERDIDA DEL EMPLEO"},
{ value: "Z563",	text: "PROBLEMAS RELACIONADOS CON HORARIO ESTRESANTE DE TRABAJO"},
{ value: "Z564",	text: "PROBLEMAS RELACIONADOS CON DESAVENENCIAS CON EL JEFE Y LOS COMPAÑEROS DE TRABAJO"},
{ value: "Z565",	text: "PROBLEMAS RELACIONADOS CON EL TRABAJO INCOMPATIBLE"},
{ value: "Z566",	text: "OTROS PROBLEMAS DE TENSION FISICA O MENTAL RELACIONADAS CON EL TRABAJO"},
{ value: "Z567",	text: "OTROS PROBLEMAS Y LOS NO ESPECIFICADOS RELACIONADOS CON EL EMPLEO"},
{ value: "Z570",	text: "EXPOSICION OCUPACIONAL AL RUIDO"},
{ value: "Z571",	text: "EXPOSICION OCUPACIONAL A LA RADIACION"},
{ value: "Z572",	text: "EXPOSICION OCUPACIONAL AL POLVO"},
{ value: "Z573",	text: "EXPOSICION OCUPACIONAL A OTRO CONTAMINANTE DEL AIRE"},
{ value: "Z574",	text: "EXPOSICION OCUPACIONAL A AGENTES TOXICOS EN AGRICULTURA"},
{ value: "Z575",	text: "EXPOSICION OCUPACIONAL A AGENTES TOXICOS EN OTRAS INDUSTRIAS"},
{ value: "Z576",	text: "EXPOSICION OCUPACIONAL A TEMPERATURA EXTREMA"},
{ value: "Z577",	text: "EXPOSICION OCUPACIONAL A LA VIBRACION"},
{ value: "Z578",	text: "EXPOSICION OCUPACIONAL A OTROS FACTORES DE RIESGO"},
{ value: "Z579",	text: "EXPOSICION OCUPACIONAL A FACTOR DE RIESGO NO ESPECIFICADO"},
{ value: "Z580",	text: "EXPOSICION AL RUIDO"},
{ value: "Z581",	text: "EXPOSICION AL AIRE CONTAMINADO"},
{ value: "Z582",	text: "EXPOSICION AL AGUA CONTAMINADA"},
{ value: "Z583",	text: "EXPOSICION AL SUELO CONTAMINADO"},
{ value: "Z584",	text: "EXPOSICION A LA RADIACION"},
{ value: "Z585",	text: "EXPOSICION A OTRAS CONTAMINACIONES DEL AMBIENTE FISICO"},
{ value: "Z586",	text: "SUMINISTRO INADECUADO DE AGUA POTABLE"},
{ value: "Z588",	text: "OTROS PROBLEMAS RELACIONADOS CON EL AMBIENTE FISICO"},
{ value: "Z589",	text: "PROBLEMA NO ESPECIFICADO RELACIONADO CON EL AMBIENTE FISICO"},
{ value: "Z590",	text: "PROBLEMAS RELACIONADOS CON LA FALTA DE VIVIENDA"},
{ value: "Z591",	text: "PROBLEMAS RELACIONADOS CON VIVIENDA INADECUADA"},
{ value: "Z592",	text: "PROBLEMAS CASEROS Y CON VECINOS E INQUILINOS"},
{ value: "Z593",	text: "PROBLEMAS RELACIONADOS CON PERSONA QUE RESIDE EN UNA INSTITUCION"},
{ value: "Z594",	text: "PROBLEMAS RELACIONADOS CON FALTA DE ALIMENTOS ADECUADOS"},
{ value: "Z595",	text: "PROBLEMAS RELACIONADOS CON POBREZA EXTREMA"},
{ value: "Z596",	text: "PROBLEMAS RELACIONADOS CON BAJOS INGRESOS"},
{ value: "Z597",	text: "PROBLEMAS RELACIONADOS CON SEGURIDAD SOCIAL Y SOSTENIMIENTO INSUFICIENTES PARA EL BIENESTAR"},
{ value: "Z598",	text: "OTROS PROBLEMAS RELACIONADOS CON LA VIVIENDA Y LAS CIRCUNSTANCIAS ECONOMICAS"},
{ value: "Z599",	text: "PROBLEMAS NO ESPECIFICADOS RELACIONADOS CON LA VIVIENDA Y LAS CIRCUNSTANCIAS ECONOMICAS"},
{ value: "Z600",	text: "PROBLEMAS RELACIONADOS CON EL AJUSTE A LAS TRANSICIONES DEL CICLO VITAL"},
{ value: "Z601",	text: "PROBLEMAS RELACIONADOS CON SITUACION FAMILIAR ATIPICA"},
{ value: "Z602",	text: "PROBLEMAS RELACIONADOS CON PERSONA QUE VIVE SOLA"},
{ value: "Z603",	text: "PROBLEMAS RELACIONADOS CON LA ADAPTACION CULTURAL"},
{ value: "Z604",	text: "PROBLEMAS RELACIONADOS CON EXCLUSION Y RECHAZO SOCIAL"},
{ value: "Z605",	text: "PROBLEMAS RELACIONADOS CON LA DISCRIMINACION Y PERSECUCION PERCIBIDAS"},
{ value: "Z608",	text: "OTROS PROBLEMAS RELACIONADOS CON EL AMBIENTE SOCIAL"},
{ value: "Z609",	text: "PROBLEMA NO ESPECIFICADO RELACIONADO CON EL AMBIENTE SOCIAL"},
{ value: "Z610",	text: "PROBLEMAS RELACIONADOS CON LA PERDIDA DE RELACION AFECTIVA EN LA INFANCIA"},
{ value: "Z611",	text: "PROBLEMAS RELACIONADOS CON EL ALEJAMIENTO DEL HOGAR EN LA INFANCIA"},
{ value: "Z612",	text: "PROBLEMAS RELACIONADOS CON ALTERACION EN EL PATRON DE LA RELACION FAMILIAR EN LA INFANCIA"},
{ value: "Z613",	text: "PROBLEMAS RELACIONADOS CON EVENTOS QUE LLEVARON A LA PERDIDA DE LA AUTOESTIMA EN LA INFANCIA"},
{ value: "Z614",	text: "PROBLEMAS RELACIONADOS CON EL ABUSO SEXUAL DEL NIÑO POR PERSONA DENTRO DEL GRUPO DE APOYO PRIMARIO"},
{ value: "Z615",	text: "PROBLEMAS RELACIONADOS CON EL ABUSO SEXUAL DEL NIÑO POR PERSONA AJENA AL GRUPO DE APOYO PRIMARIO"},
{ value: "Z616",	text: "PROBLEMAS RELACIONADOS CON ABUSO FISICO DEL NIÑO"},
{ value: "Z617",	text: "PROBLEMAS RELACIONADOS CON EXPERIENCIAS PERSONALES ATEMORIZANTES EN LA INFANCIA"},
{ value: "Z618",	text: "PROBLEMAS RELACIONADOS CON OTRAS EXPERIENCIAS NEGATIVAS EN LA INFANCIA"},
{ value: "Z619",	text: "PROBLEMAS RELACIONADOS CON EXPERIENCIA NEGATIVA NO ESPECIFICADA EN LA INFANCIA"},
{ value: "Z620",	text: "PROBLEMAS RELACIONADOS CON LA SUPERVISION O EL CONTROL INADECUADOS DE LOS PADRES"},
{ value: "Z621",	text: "PROBLEMAS RELACIONADOS CON LA SOBREPROTECCION DE LOS PADRES"},
{ value: "Z622",	text: "PROBLEMAS RELACIONADOS CON LA CRIANZA EN INSTITUCION"},
{ value: "Z623",	text: "PROBLEMAS RELACIONADOS CON HOSTILIDAD Y REPROBACION AL NIÑO"},
{ value: "Z624",	text: "PROBLEMAS RELACIONADOS CON EL ABANDONO EMOCIONAL DEL NIÑO"},
{ value: "Z625",	text: "OTROS PROBLEMAS RELACIONADOS CON NEGLIGENCIA EN LA CRIANZA DEL NIÑO"},
{ value: "Z626",	text: "PROBLEMAS RELACIONADOS CON PRESIONES INAPROPIADAS DE LOS PADRES Y OTRAS ANORMALIDADES EN LA CALIDAD DE LA CRIANZA"},
{ value: "Z628",	text: "OTROS PROBLEMAS ESPECIFICADOS Y RELACIONADOS CON LA CRIANZA DEL NIÑO"},
{ value: "Z629",	text: "PROBLEMA NO ESPECIFICADO RELACIONADO CON LA CRIANZA DEL NIÑO"},
{ value: "Z630",	text: "PROBLEMAS EN LA RELACION ENTRE ESPOSOS O PAREJA"},
{ value: "Z631",	text: "PROBLEMAS EN LA RELACION CON LOS PADRES Y LOS FAMILIARES POLITICOS"},
{ value: "Z632",	text: "PROBLEMAS RELACIONADOS CON EL APOYO FAMILIAR INADECUADO"},
{ value: "Z633",	text: "PROBLEMAS RELACIONADOS CON LA AUSENCIA DE UN MIEMBRO DE LA FAMILIA"},
{ value: "Z634",	text: "PROBLEMAS RELACIONADOS CON LA DESAPARICION O MUERTE DE UN MIEMBRO DE LA FAMILIA"},
{ value: "Z635",	text: "PROBLEMAS RELACIONADOS CON LA RUPTURA FAMILIAR POR SEPARACION O DIVORCIO"},
{ value: "Z636",	text: "PROBLEMAS RELACIONADOS CON FAMILIAR DEPENDIENTE, NECESITADO DE CUIDADO EN LA CASA"},
{ value: "Z637",	text: "PROBLEMAS RELACIONADOS CON OTROS HECHOS ESTRESANTES QUE AFECTAN A LA FAMILIA Y AL HOGAR"},
{ value: "Z638",	text: "OTROS PROBLEMAS ESPECIFICADOS RELACIONADOS CON EL GRUPO PRIMARIO DE APOYO"},
{ value: "Z639",	text: "PROBLEMA NO ESPECIFICADO RELACIONADO CON EL GRUPO PRIMARIO DE APOYO"},
{ value: "Z640",	text: "PROBLEMAS RELACIONADOS CON EMBARAZO NO DESEADO"},
{ value: "Z641",	text: "PROBLEMAS RELACIONADOS CON LA MULTIPARIDAD"},
{ value: "Z642",	text: "PROBLEMAS RELACIONADOS CON LA SOLICITUD O ACEPTACION DE INTERVENCIONES FISICAS, NUTRICIONALES Y QUIMICAS, CONOCIENDO SU RIESGO Y PELIGRO"},
{ value: "Z643",	text: "PROBLEMAS RELACIONADOS CON LA SOLICITUD O ACEPTACION DE INTERVENCIONES PSICOLOGICAS O DE LA CONDUCTA, CONOCIENDO SU RIESGO Y PELIGRO"},
{ value: "Z644",	text: "PROBLEMAS RELACIONADOS CON EL DESACUERDO CON CONSEJEROS"},
{ value: "Z650",	text: "PROBLEMAS RELACIONADOS CON CULPABILIDAD EN PROCEDIMIENTOS CIVILES O CRIMINALES SIN PRISION"},
{ value: "Z651",	text: "PROBLEMAS RELACIONADOS CON PRISION Y OTRO ENCARCELAMIENTO"},
{ value: "Z652",	text: "PROBLEMAS RELACIONADOS CON LA LIBERACION DE LA PRISION"},
{ value: "Z653",	text: "PROBLEMAS RELACIONADOS CON OTRAS CIRCUNSTANCIAS LEGALES"},
{ value: "Z654",	text: "PROBLEMAS RELACIONADOS CON VICTIMA DE CRIMEN O TERRORISMO"},
{ value: "Z655",	text: "PROBLEMAS RELACIONADOS CON LA EXPOSICION A DESASTRE, GUERRA U OTRAS HOSTILIDADES"},
{ value: "Z658",	text: "OTROS PROBLEMAS ESPECIFICADOS RELACIONADOS CON CIRCUNSTANCIAS PSICOSOCIALES"},
{ value: "Z659",	text: "PROBLEMAS RELACIONADOS CON CIRCUNSTANCIAS PSICOSOCIALES NO ESPECIFICADAS"},
{ value: "Z700",	text: "CONSULTA RELACIONADA CON LA ACTITUD SEXUAL"},
{ value: "Z701",	text: "CONSULTA RELACIONADA CON LA ORIENTACION Y CONDUCTA SEXUAL DEL PACIENTE"},
{ value: "Z702",	text: "CONSULTA RELACIONADA CON LA ORIENTACION Y CONDUCTA SEXUAL DE UNA TERCERA PERSONA"},
{ value: "Z703",	text: "CONSULTA RELACIONADA CON PREOCUPACIONES COMBINADAS SOBRE LA ACTITUD, LA CONDUCTA Y LA ORIENTACION SEXUALES"},
{ value: "Z708",	text: "OTRAS CONSULTAS SEXUALES ESPECIFICAS"},
{ value: "Z709",	text: "CONSULTA SEXUAL, NO ESPECIFICADA"},
{ value: "Z710",	text: "PERSONA QUE CONSULTA EN NOMBRE DE OTRA PERSONA"},
{ value: "Z711",	text: "PERSONA QUE TEME ESTAR ENFERMA, A QUIEN NO SE HACE DIAGNOSTICO"},
{ value: "Z712",	text: "PERSONA QUE CONSULTA PARA LA EXPLICACION DE HALLAZGOS DE INVESTIGACION"},
{ value: "Z713",	text: "CONSULTA PARA INSTRUCCIÓN Y VIGILANCIA DE LA DIETA"},
{ value: "Z714",	text: "CONSULTA PARA ASESORIA Y VIGILANCIA POR ABUSO DE ALCOHOL"},
{ value: "Z715",	text: "CONSULTA PARA ASESORIA Y VIGILANCIA POR ABUSO DE DROGAS"},
{ value: "Z716",	text: "CONSULTA PARA ASESORIA POR ABUSO DE TABACO"},
{ value: "Z717",	text: "CONSULTA PARA ASESORIA SOBRE EL VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]"},
{ value: "Z718",	text: "OTRAS CONSULTAS ESPECIFICADAS"},
{ value: "Z719",	text: "CONSULTA, NO ESPECIFICADA"},
{ value: "Z720",	text: "PROBLEMAS RELACIONADOS CON EL USO DE TABACO"},
{ value: "Z721",	text: "PROBLEMAS RELACIONADOS CON EL USO DE ALCOHOL"},
{ value: "Z722",	text: "PROBLEMAS RELACIONADOS CON EL USO DE DROGAS"},
{ value: "Z723",	text: "PROBLEMAS RELACIONADOS CON LA FALTA DE EJERCICIO FISICO"},
{ value: "Z724",	text: "PROBLEMAS RELACIONADOS CON LA DIETA Y HABITOS ALIMENTARIOS INAPROPIADOS"},
{ value: "Z725",	text: "PROBLEMAS RELACIONADOS CON LA CONDUCTA SEXUAL DE ALTO RIESGO"},
{ value: "Z726",	text: "PROBLEMAS RELACIONADOS CON EL JUEGO Y LAS APUESTAS"},
{ value: "Z728",	text: "OTROS PROBLEMAS RELACIONADOS CON EL ESTILO DE VIDA"},
{ value: "Z729",	text: "PROBLEMA NO ESPECIFICADO RELACIONADO CON EL ESTILO DE VIDA"},
{ value: "Z730",	text: "PROBLEMAS RELACIONADOS CON LA ENFERMEDAD CONSUNTIVA"},
{ value: "Z731",	text: "PROBLEMAS RELACIONADOS CON LA ACENTUACION DE RASGOS DE LA PERSONALIDAD"},
{ value: "Z732",	text: "PROBLEMAS RELACIONADOS CON LA FALTA DE RELAJACION Y DESCANSO"},
{ value: "Z733",	text: "PROBLEMAS RELACIONADOS CON EL ESTRÉS, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Z734",	text: "PROBLEMAS RELACIONADOS CON HABILIDADES SOCIALES INADECUADAS, NO CLASIFICADAS EN OTRA PARTE"},
{ value: "Z735",	text: "PROBLEMAS RELACIONADOS CON EL CONFLICTO DEL ROL SOCIAL, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Z736",	text: "PROBLEMAS RELACIONADOS CON LA LIMITACION DE LAS ACTIVIDADES DEBIDO A DISCAPACIDAD"},
{ value: "Z738",	text: "OTROS PROBLEMAS RELACIONADOS CON DIFICULTADES CON EL MODO DE VIDA"},
{ value: "Z739",	text: "PROBLEMAS NO ESPECIFICADOS RELACIONADOS CON DIFICULTADES CON EL MODO DE VIDA"},
{ value: "Z740",	text: "PROBLEMAS RELACIONADOS CON MOVILIDAD REDUCIDA"},
{ value: "Z741",	text: "PROBLEMAS RELACIONADOS CON LA NECESIDAD DE AYUDA PARA EL CUIDADO PERSONAL"},
{ value: "Z742",	text: "PROBLEMAS RELACIONADOS CON LA NECESIDAD DE ASISTENCIA DOMICILIARIA Y QUE NINGUN OTRO MIEMBRO DEL HOGAR PUEDE PROPORCIONAR"},
{ value: "Z743",	text: "PROBLEMAS RELACIONADOS CON LA NECESIDAD DE SUPERVISION CONTINUA"},
{ value: "Z748",	text: "OTROS PROBLEMAS RELACIONADOS CON DEPENDENCIA DEL PRESTADOR DE SERVICIOS"},
{ value: "Z749",	text: "PROBLEMA NO ESPECIFICADO RELACIONADO CON DEPENDENCIA DEL PRESTADOR DE SERVICIOS"},
{ value: "Z750",	text: "PROBLEMAS RELACIONADOS CON SERVICIO MEDICO NO DISPONIBLE EN EL DOMICILIO"},
{ value: "Z751",	text: "PROBLEMAS RELACIONADOS CON PERSONA ESPERANDO ADMISION EN UNA INSTITUCION APROPIADA EN OTRO LUGAR"},
{ value: "Z752",	text: "PROBLEMAS RELACIONADOS CON PERSONA EN OTRO PERIODO DE ESPERA PARA INVESTIGACION Y TRATAMIENTO"},
{ value: "Z753",	text: "PROBLEMAS RELACIONADOS CON ATENCION DE SALUD NO DISPONIBLE O INACCESIBLE"},
{ value: "Z754",	text: "PROBLEMAS RELACIONADOS CON OTROS SERVICIOS ASISTENCIALES NO DISPONIBLES O INACCESIBLES"},
{ value: "Z755",	text: "PROBLEMAS RELACIONADOS CON LA ATENCION DURANTE VACACIONES DE LA FAMILIA"},
{ value: "Z758",	text: "OTROS PROBLEMAS RELACIONADOS CON SERVICIOS MEDICOS Y DE SALUD"},
{ value: "Z759",	text: "PROBLEMA NO ESPECIFICADO RELACIONADO CON SERVICIOS MEDICOS Y DE SALUD"},
{ value: "Z760",	text: "CONSULTA PARA REPETICION DE RECETA"},
{ value: "Z761",	text: "CONSULTA PARA ATENCION Y SUPERVISION DE LA SALUD DEL NIÑO"},
{ value: "Z762",	text: "CONSULTA PARA ATENCION Y SUPERVISION DE LA SALUD DE OTROS NIÑOS O LACTANTES SANOS"},
{ value: "Z763",	text: "PERSONA SANA QUE ACOMPAÑA AL ENFERMO"},
{ value: "Z764",	text: "OTRO HUESPED EN SERVICIOS DE SALUD"},
{ value: "Z765",	text: "PERSONA QUE CONSULTA CON SIMULACION CONSCIENTE [SIMULADOR]"},
{ value: "Z768",	text: "PERSONA EN CONTACTO CON LOS SERVICIOS DE SALUD EN OTRAS CIRCUNSTANCIAS ESPECIFICADAS"},
{ value: "Z769",	text: "PERSONA EN CONTACTO CON LOS SERVICIOS DE SALUD EN CIRCUNSTANCIAS NO ESPECIFICADAS"},
{ value: "Z800",	text: "HISTORIA FAMILIAR DE TUMOR MALIGNO DE ORGANOS DIGESTIVOS"},
{ value: "Z801",	text: "HISTORIA FAMILIAR DE TUMOR MALIGNO DE TRAQUEA, BRONQUIOS Y PULMON"},
{ value: "Z802",	text: "HISTORIA FAMILIAR DE TUMOR MALIGNO DE OTROS ORGANOS RESPIRATORIOS E INTRATORACICOS"},
{ value: "Z803",	text: "HISTORIA FAMILIAR DE TUMOR MALIGNO DE MAMA"},
{ value: "Z804",	text: "HISTORIA FAMILIAR DE TUMOR MALIGNO DE ORGANOS GENITALES"},
{ value: "Z805",	text: "HISTORIA FAMILIAR DE TUMOR MALIGNO DE VIAS URINARIAS"},
{ value: "Z806",	text: "HISTORIA FAMILIAR DE LEUCEMIA"},
{ value: "Z807",	text: "HISTORIA FAMILIAR DE OTROS TUMORES MALIGNOS DEL TEJIDO LINFOIDE, HEMATOPOYETICO Y TEJIDOS RELACIONADOS"},
{ value: "Z808",	text: "HISTORIA FAMILIAR DE TUMOR MALIGNO DE OTROS ORGANOS O SISTEMAS ESPECIFICADOS"},
{ value: "Z809",	text: "HISTORIA FAMILIAR DE TUMOR MALIGNO, DE SITIO NO ESPECIFICADO"},
{ value: "Z810",	text: "HISTORIA FAMILIAR DE RETARDO MENTAL"},
{ value: "Z811",	text: "HISTORIA FAMILIAR DE ABUSO DE ALCOHOL"},
{ value: "Z812",	text: "HISTORIA FAMILIAR DE ABUSO DE TABACO"},
{ value: "Z813",	text: "HISTORIA FAMILIAR DE ABUSO DE OTRAS SUSTANCIA PSICOACTIVAS"},
{ value: "Z814",	text: "HISTORIA FAMILIAR DE ABUSO DE OTRAS SUSTANCIAS"},
{ value: "Z818",	text: "HISTORIA FAMILIAR DE OTROS TRASTORNOS MENTALES Y DEL COMPORTAMIENTO"},
{ value: "Z820",	text: "HISTORIA FAMILIAR DE EPILEPSIA Y DE OTRAS ENFERMEDADES DEL SISTEMA NERVIOSO"},
{ value: "Z821",	text: "HISTORIA FAMILIAR DE CEGUERA O PERDIDA DE LA VISION"},
{ value: "Z822",	text: "HISTORIA FAMILIAR DE SORDERA O PERDIDA DE LA AUDICION"},
{ value: "Z823",	text: "HISTORIA FAMILIAR DE APOPLEJIA"},
{ value: "Z824",	text: "HISTORIA FAMILIAR DE ENFERMEDAD ISQUEMICA DEL CORAZON Y OTRAS ENFERMEDADES DEL SISTEMA CIRCULATORIO"},
{ value: "Z825",	text: "HISTORIA FAMILIAR DE ASMA Y DE OTRAS ENFERMEDADES CRONICAS DE LAS VIAS RESPIRATORIAS INFERIORES"},
{ value: "Z826",	text: "HISTORIA FAMILIAR DE ARTRITIS Y OTRAS ENFERMEDADES DEL SISTEMA OSTEOMUSCULAR Y TEJIDO CONJUNTIVO"},
{ value: "Z827",	text: "HISTORIA FAMILIAR DE MALFORMACIONES CONGENITAS, DEFORMIDADES Y OTRAS ANOMALIAS CROMOSOMICAS"},
{ value: "Z828",	text: "HISTORIA FAMILIAR DE OTRAS DISCAPACIDADES Y ENFERMEDADES CRONICAS INCAPACITANTES NO CLASIFICADAS EN OTRA PARTE"},
{ value: "Z830",	text: "HISTORIA FAMILIAR DE INFECCION POR EL VIRUS DE LA INMUNODEFICIENCIA HUMANA [VIH]"},
{ value: "Z831",	text: "HISTORIA FAMILIAR DE OTRAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS"},
{ value: "Z832",	text: "HISTORIA FAMILIAR DE ENFERMEDADES DE LA SANGRE Y DE LOS ORGANOS HEMATOPOYETICOS Y DE CIERTOS TRASTORNOS DEL MECANISMO INMUNOLOGICO"},
{ value: "Z833",	text: "HISTORIA FAMILIAR DE DIABETES MELLITUS"},
{ value: "Z834",	text: "HISTORIA FAMILIAR DE OTRAS ENFERMEDADES ENDOCRINAS, NUTRICIONALES Y METABOLICAS"},
{ value: "Z835",	text: "HISTORIA FAMILIAR DE TRASTORNOS DE LOS OJOS Y DE LOS OIDOS"},
{ value: "Z836",	text: "HISTORIA FAMILIAR DE ENFERMEDADES DEL SISTEMA RESPIRATORIO"},
{ value: "Z837",	text: "HISTORIA FAMILIAR DE ENFERMEDADES DEL SISTEMA DIGESTIVO"},
{ value: "Z840",	text: "HISTORIA FAMILIAR DE ENFERMEDADES DE LA PIEL Y DEL TEJIDO SUBCUTANEO"},
{ value: "Z841",	text: "HISTORIA FAMILIAR DE TRASTORNOS DEL RIÑON Y DEL URETER"},
{ value: "Z842",	text: "HISTORIA FAMILIAR DE OTRAS ENFERMEDADES DEL SISTEMA GENITOURINARIO"},
{ value: "Z843",	text: "HISTORIA FAMILIAR DE CONSANGUINIDAD"},
{ value: "Z848",	text: "HISTORIA FAMILIAR DE OTRAS AFECCIONES ESPECIFICADAS"},
{ value: "Z850",	text: "HISTORIA PERSONAL DE TUMOR MALIGNO DE ORGANOS DIGESTIVOS"},
{ value: "Z851",	text: "HISTORIA PERSONAL DE TUMOR MALIGNO DE TRAQUEA, BRONQUIOS Y PULMON"},
{ value: "Z852",	text: "HISTORIA PERSONAL DE TUMOR MALIGNO DE OTROS ORGANOS RESPIRATORIOS E INTRATORACICOS"},
{ value: "Z853",	text: "HISTORIA PERSONAL DE TUMOR MALIGNO DE MAMA"},
{ value: "Z854",	text: "HISTORIA PERSONAL DE TUMOR MALIGNO DE ORGANOS GENITALES"},
{ value: "Z855",	text: "HISTORIA PERSONAL DE TUMOR MALIGNO DE VIAS URINARIAS"},
{ value: "Z856",	text: "HISTORIA PERSONAL DE LEUCEMIA"},
{ value: "Z857",	text: "HISTORIA PERSONAL DE OTROS TUMORES MALIGNOS DEL TEJIDO LINFOIDE, HEMATOPOYETICO Y TEJIDOS RELACIONADOS"},
{ value: "Z858",	text: "HISTORIA PERSONAL DE TUMOR MALIGNO DE OTROS ORGANOS Y SISTEMAS"},
{ value: "Z859",	text: "HISTORIA PERSONAL DE TUMOR MALIGNO, DE SITIO NO ESPECIFICADO"},
{ value: "Z860",	text: "HISTORIA PERSONAL DE OTROS TUMORES"},
{ value: "Z861",	text: "HISTORIA PERSONAL DE ENFERMEDADES INFECCIOSAS Y PARASITARIAS"},
{ value: "Z862",	text: "HISTORIA PERSONAL DE ENFERMEDADES DE LA SANGRE Y DE LOS ORGANOS HEMATOPOYETICOS Y DE CIERTOS TRASTORNOS DEL MECANISMO INMUNOLOGICO"},
{ value: "Z863",	text: "HISTORIA PERSONAL DE ENFERMEDADES ENDOCRINAS, NUTRICIONALES Y METABOLICAS"},
{ value: "Z864",	text: "HISTORIA PERSONAL DE ABUSO DE SUSTANCIAS PSICOACTIVAS"},
{ value: "Z865",	text: "HISTORIA PERSONAL DE OTROS TRASTORNOS MENTALES O DEL COMPORTAMIENTO"},
{ value: "Z866",	text: "HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA NERVIOSO Y DE LOS ORGANOS DE LOS SENTIDOS"},
{ value: "Z867",	text: "HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA CIRCULATORIO"},
{ value: "Z870",	text: "HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA RESPIRATORIO"},
{ value: "Z871",	text: "HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA DIGESTIVO"},
{ value: "Z872",	text: "HISTORIA PERSONAL DE ENFERMEDADES DE LA PIEL Y DEL TEJIDO SUBCUTANEO"},
{ value: "Z873",	text: "HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA OSTEOMUSCULAR Y DEL TEJIDO CONJUNTIVO"},
{ value: "Z874",	text: "HISTORIA PERSONAL DE ENFERMEDADES DEL SISTEMA GENITOURINARIO"},
{ value: "Z875",	text: "HISTORIA PERSONAL DE COMPLICACIONES DEL EMBARAZO, DEL PARTO Y DEL PUERPERIO"},
{ value: "Z876",	text: "HISTORIA PERSONAL DE CIERTAS AFECCIONES ORIGINADAS EN EL PERIODO PERINATAL"},
{ value: "Z877",	text: "HISTORIA PERSONAL DE MALFORMACIONES CONGENITAS, DEFORMIDADES Y ANOMALIAS CROMOSOMICAS"},
{ value: "Z878",	text: "HISTORIA PERSONAL DE OTRAS AFECCIONES ESPECIFICADAS"},
{ value: "Z880",	text: "HISTORIA PERSONAL DE ALERGIA A PENICILINA"},
{ value: "Z881",	text: "HISTORIA PERSONAL DE ALERGIA A OTROS AGENTES ANTIBIOTICOS"},
{ value: "Z882",	text: "HISTORIA PERSONAL DE ALERGIA A SULFONAMIDAS"},
{ value: "Z883",	text: "HISTORIA PERSONAL DE ALERGIA A OTROS AGENTES ANTIINFECCIOSOS"},
{ value: "Z884",	text: "HISTORIA PERSONAL DE ALERGIA A AGENTE ANESTESICO"},
{ value: "Z885",	text: "HISTORIA PERSONAL DE ALERGIA A AGENTE NARCOTICO"},
{ value: "Z886",	text: "HISTORIA PERSONAL DE ALERGIA A AGENTE ANALGESICO"},
{ value: "Z887",	text: "HISTORIA PERSONAL DE ALERGIA A SUERO O VACUNA"},
{ value: "Z888",	text: "HISTORIA PERSONAL DE ALERGIA A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS"},
{ value: "Z889",	text: "HISTORIA PERSONAL DE ALERGIA A OTRAS DROGAS, MEDICAMENTOS Y SUSTANCIAS BIOLOGICAS NO ESPECIFICADAS"},
{ value: "Z890",	text: "AUSENCIA ADQUIRIDA DE DEDO(S), [INCLUIDO EL PULGAR], UNILATERAL"},
{ value: "Z891",	text: "AUSENCIA ADQUIRIDA DE MANO Y MUÑECA"},
{ value: "Z892",	text: "AUSENCIA ADQUIRIDA DE MIEMBRO SUPERIOR POR ARRIBA DE LA MUÑECA"},
{ value: "Z893",	text: "AUSENCIA ADQUIRIDA DE AMBOS MIEMBROS SUPERIORES [CUALQUIER NIVEL]"},
{ value: "Z894",	text: "AUSENCIA ADQUIRIDA DE PIE Y TOBILLO"},
{ value: "Z895",	text: "AUSENCIA ADQUIRIDA DE PIERNA A NIVEL DE O DEBAJO DE LA RODILLA"},
{ value: "Z896",	text: "AUSENCIA ADQUIRIDA DE PIERNA POR ARRIBA DE LA RODILLA"},
{ value: "Z897",	text: "AUSENCIA ADQUIRIDA DE AMBOS MIEMBROS INFERIORES [CUALQUIER NIVEL, EXCEPTO DEDOS DEL PIE SOLAMENTE]"},
{ value: "Z898",	text: "AUSENCIA ADQUIRIDA DE MIEMBROS SUPERIORES E INFERIORES [CUALQUIER NIVEL]"},
{ value: "Z899",	text: "AUSENCIA ADQUIRIDA DE MIEMBROS NO ESPECIFICADOS"},
{ value: "Z900",	text: "AUSENCIA ADQUIRIDA DE PARTE DE LA CABEZA Y DEL CUELLO"},
{ value: "Z901",	text: "AUSENCIA ADQUIRIDA DE MAMA(S)"},
{ value: "Z902",	text: "AUSENCIA ADQUIRIDA (DE PARTE) DEL PULMON"},
{ value: "Z903",	text: "AUSENCIA ADQUIRIDA DE PARTE DEL ESTOMAGO"},
{ value: "Z904",	text: "AUSENCIA ADQUIRIDA DE OTRAS PARTES DEL TUBO DIGESTIVO"},
{ value: "Z905",	text: "AUSENCIA ADQUIRIDA DE RIÑON"},
{ value: "Z906",	text: "AUSENCIA ADQUIRIDA DE OTRAS PARTES DE LAS VIAS URINARIAS"},
{ value: "Z907",	text: "AUSENCIA ADQUIRIDA DE ORGANO(S) GENITAL(ES)"},
{ value: "Z908",	text: "AUSENCIA ADQUIRIDA DE OTROS ORGANOS"},
{ value: "Z910",	text: "HISTORIA PERSONAL DE ALERGIA, NO DEBIDA A DROGAS NI A SUSTANCIAS BIOLOGICAS"},
{ value: "Z911",	text: "HISTORIA PERSONAL DE INCUMPLIMIENTO DEL REGIMEN O TRATAMIENTO MEDICO"},
{ value: "Z912",	text: "HISTORIA PERSONAL DE HIGIENE PERSONAL DEFICIENTE"},
{ value: "Z913",	text: "HISTORIA PERSONAL DEL CICLO SUEÑO - VIGILIA NO SALUDABLE"},
{ value: "Z914",	text: "HISTORIA PERSONAL DE TRAUMA PSICOLOGICO, NO CLASIFICADO EN OTRA PARTE"},
{ value: "Z915",	text: "HISTORIA PERSONAL DE LESION AUTOINFLIGIDA INTENCIONALMENTE"},
{ value: "Z916",	text: "HISTORIA PERSONAL DE OTRO TRAUMA FISICO"},
{ value: "Z918",	text: "HISTORIA PERSONAL DE OTROS FACTORES DE RIESGO, NO CLASIFICADOS EN OTRA PARTE"},
{ value: "Z920",	text: "HISTORIA PERSONAL DE ANTICONCEPCIÓN"},
{ value: "Z921",	text: "HISTORIA PERSONAL DE USO (PRESENTE) DE ANTICOAGULANTES POR LARGO TIEMPO"},
{ value: "Z922",	text: "HISTORIA PERSONAL DE USO (PRESENTE) DE OTROS MEDICAMENTOS POR LARGO TIEMPO"},
{ value: "Z923",	text: "HISTORIA PERSONAL DE IRRADIACION"},
{ value: "Z924",	text: "HISTORIA PERSONAL DE CIRUGIA MAYOR, NO CLASIFICADA EN OTRA PARTE"},
{ value: "Z925",	text: "HISTORIA PERSONAL DE MEDIDAS DE REHABILITACION"},
{ value: "Z928",	text: "HISTORIA PERSONAL DE OTROS TRATAMIENTOS MEDICOS"},
{ value: "Z929",	text: "HISTORIA PERSONAL DE TRATAMIENTO MEDICO NO ESPECIFICADO"},
{ value: "Z930",	text: "TRAQUEOSTOMIA"},
{ value: "Z931",	text: "GASTROSTOMIA"},
{ value: "Z932",	text: "ILEOSTOMIA"},
{ value: "Z933",	text: "COLOSTOMIA"},
{ value: "Z934",	text: "OTROS ORIFICIOS ARTIFICIALES DEL TUBO GASTROINTESTINAL"},
{ value: "Z935",	text: "CISTOSTOMIA"},
{ value: "Z936",	text: "OTROS ORIFICIOS ARTIFICIALES DE LAS VIAS URINARIAS"},
{ value: "Z938",	text: "OTRAS ABERTURAS ARTIFICIALES"},
{ value: "Z939",	text: "ABERTURA ARTIFICIAL, NO ESPECIFICADA"},
{ value: "Z940",	text: "TRASPLANTE DE RIÑON"},
{ value: "Z941",	text: "TRASPLANTE DE CORAZON"},
{ value: "Z942",	text: "TRASPLANTE DE PULMON"},
{ value: "Z943",	text: "TRASPLANTE DE CORAZON Y PULMONES"},
{ value: "Z944",	text: "TRASPLANTE DE HIGADO"},
{ value: "Z945",	text: "TRASPLANTE DE PIEL"},
{ value: "Z946",	text: "TRASPLANTE DE HUESO"},
{ value: "Z947",	text: "TRASPLANTE DE CORNEA"},
{ value: "Z948",	text: "OTROS ORGANOS Y TEJIDOS TRASPLANTADOS"},
{ value: "Z949",	text: "ORGANO Y TEJIDO TRASPLANTADO NO ESPECIFICADO"},
{ value: "Z950",	text: "PRESENCIA DE MARCAPASO CARDIACO"},
{ value: "Z951",	text: "PRESENCIA DE DERIVACION AORTOCORONARIA"},
{ value: "Z952",	text: "PRESENCIA DE VALVULA CARDIACA PROTESICA"},
{ value: "Z953",	text: "PRESENCIA DE VALVULA CARDIACA XENOGENICA"},
{ value: "Z954",	text: "PRESENCIA DE OTROS REEMPLAZOS DE VALVULA CARDIACA"},
{ value: "Z955",	text: "PRESENCIA DE ANGIOPLASTIA, INJERTOS Y PROTESIS CARDIOVASCULARES"},
{ value: "Z958",	text: "PRESENCIA DE OTROS INJERTOS Y PROTESIS CARDIOVASCULARES"},
{ value: "Z959",	text: "PRESENCIA DE INJERTOS E IMPLANTES CARDIOVASCULARES NO ESPECIFICADOS"},
{ value: "Z960",	text: "PRESENCIA DE IMPLANTE UROGENITAL"},
{ value: "Z961",	text: "PRESENCIA DE LENTES INTRAOCULARES"},
{ value: "Z962",	text: "PRESENCIA DE IMPLANTES OTICOS Y AUDITIVOS"},
{ value: "Z963",	text: "PRESENCIA DE LARINGE ARTIFICIAL"},
{ value: "Z964",	text: "PRESENCIA DE IMPLANTES ENDOCRINOS"},
{ value: "Z965",	text: "PRESENCIA DE IMPLANTES DE RAIZ DE DIENTE Y DE MANDIBULA"},
{ value: "Z966",	text: "PRESENCIA DE IMPLANTE ORTOPEDICO ARTICULAR"},
{ value: "Z967",	text: "PRESENCIA DE OTROS IMPLANTES DE TENDONES Y HUESOS"},
{ value: "Z968",	text: "PRESENCIA DE OTROS IMPLANTES FUNCIONALES ESPECIFICADOS"},
{ value: "Z969",	text: "PRESENCIA DE IMPLANTES FUNCIONALES NO ESPECIFICADOS"},
{ value: "Z970",	text: "PRESENCIA DE OJO ARTIFICIAL"},
{ value: "Z971",	text: "PRESENCIA DE MIEMBRO ARTIFICIAL (COMPLETO) (PARCIAL)"},
{ value: "Z972",	text: "PRESENCIA DE DISPOSITIVO PROTESICO DENTAL (COMPLETO) (PARCIAL)"},
{ value: "Z973",	text: "PRESENCIA DE ANTEOJOS Y LENTES DE CONTACTO"},
{ value: "Z974",	text: "PRESENCIA DE AUDIFONO EXTERNO"},
{ value: "Z975",	text: "PRESENCIA DE DISPOSITIVO ANTICONCEPTIVO (INTRAUTERINO)"},
{ value: "Z978",	text: "PRESENCIA DE OTROS DISPOSITIVOS ESPECIFICADOS"},
{ value: "Z980",	text: "ESTADO DE DERIVACION INTESTINAL O ANASTOMOSIS"},
{ value: "Z981",	text: "ESTADO DE ARTRODESIS"},
{ value: "Z982",	text: "PRESENCIA DE DISPOSITIVO PARA DRENAJE DE LIQUIDO CEFALORRAQUIDEO"},
{ value: "Z988",	text: "OTROS ESTADOS POSTQUIRURGICOS ESPECIFICADOS"},
{ value: "Z990",	text: "DEPENDENCIA DE ASPIRADOR"},
{ value: "Z991",	text: "DEPENDENCIA DE RESPIRADOR"},
{ value: "Z992",	text: "DEPENDENCIA DE DIALISIS RENAL"},
{ value: "Z993",	text: "DEPENDENCIA DE SILLA DE RUEDAS"},
{ value: "Z998",	text: "DEPENDENCIA DE OTRAS MAQUINAS Y DISPOSITIVOS CAPACITANTES"},
{ value: "Z999",	text: "DEPENDENCIA DE MAQUINA Y DISPOSITIVO CAPACITANTE, NO ESPECIFICADA"},
]