
<script>
import codigosCIE10 from './optionsCodigoCIE10'
const Swal = require('sweetalert2')

export default {
    async mounted() {
        this.formDataBase.codigosCIE10 = codigosCIE10
        this.$store.dispatch('get', { path: `Peligro` })
        .then( result => {
            
            result.forEach( r => {
                this.formDataBase.peligros.forEach( p => {
                    if (r.tipo == p.value) {
                        p.value = r.id
                    }
                })
            })

        })

    },
    data() {
        return {
            worker: {
                searching: false,
                imputSearchWorker: null,
                stateErrorWorker: null,
                about: null,
            },
            hasForm: false,
            formDataBase: {
                codigosCIE10: null,
                booleanSelect: [
                    { value: null, text: 'Seleccionar' },
                    { value: 'si', text: 'si' },
                    { value: 'no', text: 'no' }
                ],
                peligros: [
                    { value: null, text: 'Seleccionar' },
                    { value: 'mecanico', text: 'Mecánico' },
                    { value: 'electrico', text: 'Eléctrico' },
                    { value: 'locativo', text: 'Locativo' },
                    { value: 'antropicoODeOrdenPublico', text: 'Antropico o de orden público' },
                    { value: 'radioacionesNoIonizantes', text: 'Radioaciones no ionizantes' },
                    { value: 'biologico', text: 'Biológico' },
                    { value: 'biomecanico', text: 'Biomecánico' },
                    { value: 'psicosocial', text: 'Psicosocial' },
                ]
            },
            newForm: {},
            enfermedadesLaborales: [],


        }
    },
    methods: {
        async searchingWorker() {
            this.worker.searching = true

            this.$store.dispatch('get', { path: `Persona/GetPersonaCC/${this.worker.imputSearchWorker}` })
                .then(result => {
                    return new Promise((resolve, reject) => {
                        result = result.traceId ? null : result
                        if (result) {
                            resolve(result)
                        } else {
                            reject('Trabajador no encontrado')
                        }
                    })
                })
                .then( async result => {
 

                    this.worker.stateErrorWorker = null
                    this.worker.about = result

                    this.initEnfermedadesLaborales( result.id )
                })
                .catch(error => {
                    this.worker.stateErrorWorker = false
                    this.worker.about = null
                    console.error(error)
                })
                .finally(() => {
                    this.worker.searching = false
                })

        },

        async initEnfermedadesLaborales( personaId ) {
            let result = await this.$store.dispatch('get', { path: `EnfermedadLaboral/Persona/${personaId}` })
            result.forEach( enfermedad => {
                enfermedad.peligro = this.formDataBase.peligros.find( p => p.value == enfermedad.origenDelPeligroId).text
            })
            this.enfermedadesLaborales = result
        },

        async addNewForm() {

            // regresar a estados iniciales
            this.$refs['modal-confirm-enfermedad'].hide()
            this.hasForm = false
            
            // Recargar datos del backend
            const model  = {
                PersonaId: this.worker.about.id,
                OrigenDelPeligroId: this.newForm.origenDelPeligro,
                FechaDeNotificacion: this.newForm.fechaDeNotificacion,
                FechaDeCalificacion: this.newForm.fechaDeCalificacion,
                FechaInicio: this.newForm.fechaInicio,
                FechaFinal: this.newForm.fechaFinal,
                PCL: this.newForm.pcl,
                CodigoCIE10: this.newForm.codigoCIE10,
                Descripcion: this.newForm.descripcion,
                Investigacion: this.newForm.investigacion,
                Observaciones: this.newForm.observaciones,
            }
            
            
            this.$store.dispatch('post', {path: 'EnfermedadLaboral', data: model })
            .then( () => {
                this.initEnfermedadesLaborales( this.worker.about.id )
                Swal.fire({
                    icon: 'success',
                    title: 'Enfermedad laboral agregada',
                    showConfirmButton: false,
                    timer: 1500
                })

            })
            
        },
        showModal() {
            this.$refs['modal-confirm-enfermedad'].show()
        },

        initNewForm() {
            this.newForm = {
                fechaDeNotificacion: null, // FECHA NOTIFICACIÓN
                fechaDeCalificacion: null, // FECHA DE CALIFICACION DE LA ENFERMEDAD
                fechaInicio: null, // FECHA DE INICIO DE INCAPCIDAD
                fechaFinal: null, // FECHA DE FINAL DE INCAPCIDAD
                pcl: null, // % (PCL) PERDIDA CAPACIDAD LABORAL 
                codigoCIE10: null, // CÓDIGO CIE-10
                descripcion: null, // DESCRIPCIÓN DE LA CATEGORÍA DE LA CONDICIÓN DE SALUD
                origenDelPeligro: null, // ORIGEN DEL PELIGRO/RIESGO
                investigacion: null, // INVESTIGACIÓN (SI/NO)
                observaciones: null, // OBSERVACIONES        
            }
        },
        createForm() {
            this.hasForm = true
            this.initNewForm()
        },
        cancelForm() {
            this.hasForm = false
        },
  
    },
    filters: {
        formatDate(value) {
            //formate fecha de la forma 2020-12-31T00:00:00 a 31/12/2020
            return value.split('T')[0].split('-').reverse().join('/')
            
        }
    }


}
</script>

<template>
    <b-container fluid>
        <b-row>
            <b-col sm="12">


                <card>
                    <template v-slot:headerTitle>
                        <h4 class="text-white"><b>Registro de enfermedades</b></h4>
                    </template>
                    <div class="card-body">
                        <h4 class="card-title text-white mb-3"><b>Trabajador</b></h4>

                        <b-form @submit.prevent="searchingWorker">
                            <!-- Busqueda por CC, nombre y apellidos -->
                            <b-form-group label-for="imputSearchWorker" label="Cédula del trabajador"
                                invalid-feedback="Trabajador no encontrado"
                                description="Cédula de ciudadanía / Nombres y apellidos" :state="worker.stateErrorWorker">
                                <b-form-input id="imputSearchWorker" v-model="worker.imputSearchWorker"
                                    :state="worker.stateErrorWorker" type="text"
                                    placeholder="Agregar informacion de busqueda" required></b-form-input>
                            </b-form-group>
                            <b-button type="submit" variant="primary" :disabled="worker.searching">Buscar</b-button>
                        </b-form>

                        <!-- Si existe el trabajador se mostrara la siguiente informacion -->
                        <article v-if="worker.about" class="mt-4">


                            <h4 class="my-3">Información del trabajador</h4>
                            <b-list-group class="info-worker my-3">
                                <b-list-group-item class="flex-column align-items-start">
                                    <div class="d-flex w-100 justify-content-between">
                                        <h4 class="mb-1">{{ worker.about.apellidos }} {{ worker.about.nombres }}</h4>
                                    </div>
                                    <p><strong>Número de enfermedades laborales: </strong>{{ enfermedadesLaborales.length }}</p>
                                    
                                    <!-- <div>
                                        <p><strong>Departamento: </strong>{{ worker.about.departamento }}</p>
                                        <p><strong>Ciudad: </strong>{{ worker.about.ciudad }}</p>
                                        <p><strong>Tipo de vinculación: </strong>{{ worker.about.tipoDeVinculacion }}</p>
                                        <p><strong>Sucursal: </strong>{{ worker.about.sucursal }}</p>
                                        <p><strong>Sede: </strong>{{ worker.about.sede }}</p>
                                        <p><strong>Centro de trabajo: </strong>{{ worker.about.centroDeTrabajo }}</p>
                                        <p><strong>Tiempo en el cargo: </strong>{{ worker.about.tiempoEnElCargo }}</p>
                                    </div>  -->

                                </b-list-group-item>
                            </b-list-group>



                            <div class="mt-4" v-if="enfermedadesLaborales.length">
                                <h4 class="mb-3">
                                    Registro de enfermedades laborales
                                </h4>
                                <b-list-group>
                                    <b-list-group-item v-for="(enfermedad, index) in enfermedadesLaborales"
                                        :key="enfermedad.id">
                                        <h4 class="mb-3">Enfermedad - {{ index + 1 }}</h4>
                                        <p><strong>Código CIE10:</strong> {{ enfermedad.codigoCIE10 }}</p>
                                        <p><strong>Descripción:</strong> {{ enfermedad.descripcion }}</p>
                                        <p><strong>Fecha de calificación:</strong> {{ enfermedad.fechaDeCalificacion | formatDate }}</p>
                                        <p><strong>Fecha de notificación:</strong> {{ enfermedad.fechaDeNotificacion | formatDate}}</p>
                                        <p><strong>Fecha final:</strong> {{ enfermedad.fechaFinal | formatDate}}</p>
                                        <p><strong>Fecha inicio:</strong> {{ enfermedad.fechaInicio | formatDate}}</p>
                                        <p><strong>Investigación:</strong> {{ enfermedad.investigacion }}</p>
                                        <p><strong>Observaciones:</strong> {{ enfermedad.observaciones }}</p>
                                        <p><strong>Orígen del peligro:</strong> {{ enfermedad.peligro }}</p>
                                        <p><strong>Pérdida de capacidad laboral:</strong> {{ enfermedad.pcl }}</p>
                                    </b-list-group-item>
                                </b-list-group>
                            </div>
                        </article>

                    </div>
                </card>
                <card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title text-white"><b>Registrar enfermedad laboral</b></h4>
                    </template>
                    <div class="card-body">
                        <article class="nueva-incapacidad">
                            <b-button v-show="!hasForm" @click="createForm" :disabled="!worker.about" block
                                variant="primary">Agregar nueva enfermedad laboral</b-button>
                            <b-button v-show="hasForm" @click="can | lForm" block variant="secondary">Cancelar</b-button>


                        </article>
                        <b-form v-if="hasForm" @submit.prevent="showModal" class="my-4">
                            <b-modal ref="modal-confirm-enfermedad" @ok="addNewForm" >
                                Una vez guarde los registros no se podrán modificar, ¿Desea continuar?
                            </b-modal>
                            <b-form-group label-for="fechaDeNotificacion" label="Fecha de notificación">
                                <b-form-input type="date" id="fechaDeNotificacion" v-model="newForm.fechaDeNotificacion"
                                    required></b-form-input>
                            </b-form-group>

                            <b-form-group label-for="fechaDeCalificacion" label="Fecha de calificación">
                                <b-form-input type="date" id="fechaDeCalificacion" v-model="newForm.fechaDeCalificacion"
                                    required></b-form-input>
                            </b-form-group>
                            <b-row>
                                <b-col>
                                    <b-form-group label-for="fechaInicio" label="Fecha incial de la enfermedad">
                                        <b-form-input type="date" id="fechaInicio" :max="newForm.fechaFinal"
                                            v-model="newForm.fechaInicio" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label-for="fechaFinal" label="Fecha final de la enfermedad">
                                        <b-form-input type="date" id="fechaFinal" :min="newForm.fechaInicio"
                                            v-model="newForm.fechaFinal" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-form-group label-for="origenDelPeligro" label="Origen del peligro">
                                <b-form-select id="origenDelPeligro" v-model="newForm.origenDelPeligro"
                                    :options="formDataBase.peligros" required></b-form-select>
                            </b-form-group>
                            <b-form-group label-for="pcl" label="Porcentaje de perdida capcidad laboral">
                                <b-form-input type="number" max="100" min="0" id="pcl" v-model="newForm.pcl" required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group label-for="codigoCIE10" label="Código CIE 10">
                                <b-form-input type="text" list="codigoCIE10List" id="codigoCIE10"
                                    v-model="newForm.codigoCIE10" required></b-form-input>
                                <b-form-datalist id="codigoCIE10List"
                                    :options="formDataBase.codigosCIE10"></b-form-datalist>
                            </b-form-group>
                            <b-form-group label-for="descripcion" label="Descripción de la categoría de salud">
                                <b-form-textarea id="descripcion" placeholder="Agregar descripción"
                                    v-model="newForm.descripcion" rows="4" max-rows="4" required></b-form-textarea>
                            </b-form-group>

                            <b-form-group label-for="investigacion" label="Investigación">
                                <b-form-select id="investigacion" v-model="newForm.investigacion"
                                    :options="formDataBase.booleanSelect" required></b-form-select>
                            </b-form-group>

                            <b-form-group label-for="observaciones" label="Observaciones">
                                <b-form-textarea id="observaciones" placeholder="Agregar observaciones"
                                    v-model="newForm.observaciones" rows="4" max-rows="4" required></b-form-textarea>
                            </b-form-group>
                            <b-button type="submit" variant="primary">Agregar</b-button>
                        </b-form>
                    </div>
                </card>
            </b-col>
        </b-row>
    </b-container>
</template>

